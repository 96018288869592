import React, { Component } from "react";
import { Icon, IconButton } from "@material-ui/core";

class TopSearchBox extends Component {
  state = {
    open: false
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.open && (
          <IconButton onClick={this.toggle} className="btn-white">
            <Icon>search</Icon>
          </IconButton>
        )}

        {this.state.open && (
          <div
            className="d-flex align-items-center h-100 top-search-box"
          >
            <input
              className="px-16 search-box"
              type="text"
              placeholder="Type here"
              autoFocus
            />
            <IconButton onClick={this.toggle} className="btn-white">
              <Icon>close</Icon>
            </IconButton>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default TopSearchBox;
