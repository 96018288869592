import React, { Component } from "react";
import { Fab,Grid, withStyles,Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import ProjectProfileFrame from "../Projects/ProjectProfileFrame";
import CreateNewProjectDialog from "../Projects/CreateNewProjectDialog";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink,withRouter } from "react-router-dom";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
const formatCreateLabel = (value) => `Create "${value}"`;
const isValidNewOption = (value) => value != "";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);
const filter = createFilterOptions();


class Project extends Component {
  state = {
        attachment: null,
        drawerOpenProject: false,
        showAddProject: false,
        project_id: "",
        projectListArray: [],
        allProjectList: [],
        createNewprojectName:"",
        filterdata:[],
        removeProjectPopup: false,
        shouldOpenCreateProjectDialog: false,
        FullCreateProjectDialog: false,
        minimizeProjectDialog: false,
        type: "projects",
        recordtype: "",
        newProjectData: {},
    };

    componentDidMount() {
        this.props.getProjectList(this.props.apolloClient.client);

        if(this.props.recordId){
            this.setState({
                recordId:this.props.recordId,
                recordtype:this.props.recordtype,
            },()=>{
                this.forceUpdate()
                //this.props.getOpportunitiesCompanyList(this.props.apolloClient.client, this.state.recordId);
                var req ={
                  id:this.props.recordId,
                  type:this.state.type,
                  recordtype:this.props.recordtype,
                }
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
    }
    componentWillReceiveProps(props) {
        if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
            this.setState({
                recordId:props.recordId,
                recordtype:props.recordtype,
            },()=>{
                this.forceUpdate()
                //this.props.getOpportunitiesCompanyList(this.props.apolloClient.client, this.state.recordId);
                var req ={
                  id:props.recordId,
                  type:this.state.type,
                  recordtype:props.recordtype,
                }
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
        if (props.projectReducer.projectFieldData.data && props.projectReducer.projectFieldData.is_loaded) {
            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
        }
         
         
        if (props.settingReducer.relatedDataProject.data) {
            props.settingReducer.relatedDataProject.is_loaded = false
            this.setState({
                projectListArray:props.settingReducer.relatedDataProject.data.data,
            },()=>this.forceUpdate());
        }

         if (props.settingReducer.projectRecList && props.settingReducer.projectRecList.data && props.settingReducer.projectRecList.data.data){
          let projectOptions = [];
          props.settingReducer.projectRecList.data.data.map((opt)=>{
            projectOptions.push({ label: opt.name, value: opt.project_id});
          })
          this.setState({ allProjectList: projectOptions});
        }
         
    }

  toggleProject = () => {
      this.setState(oldStateProject => ({ isOpenedProject: !oldStateProject.isOpenedProject}));
      if(this.state.isOpenedProject){
          this.setState({
              showAddProject: false
          },()=>this.forceUpdate());
      }
  }

  /* START cmp dialoge */

    handleProjectDialogClose = () => {
        this.setState({ shouldOpenCreateProjectDialog: false, FullCreateProjectDialog: false, minimizeProjectDialog: false });
    };

    handleProjectDialogMinimize = () => {
        this.setState(oldStateminimizeProjectDialog => ({ minimizeProjectDialog: !oldStateminimizeProjectDialog.minimizeProjectDialog }));
        this.setState({ FullCreateProjectDialog: false })
    };

    handleProjectDialogFull = () => {
        this.setState(oldStateFullCreateProjectDialog => ({ FullCreateProjectDialog: !oldStateFullCreateProjectDialog.FullCreateProjectDialog }));
        this.setState({ minimizeProjectDialog: false })

    };
    
    /* END cmp dialoge */
    closeDrawerRight = (e) => {
        //e.preventDefault()
        this.setState({
          drawerOpenProject: false,
          drawerOpenProject: false,
        });
    };
    toggleAddProject = () => {
        this.setState({ 
            isOpenedProject: true,
            showAddProject: !this.state.showAddProject
        });
    }
    handleDrawerProjectRight = (project_id) => {
      if (this.state.project_id && (this.state.project_id === project_id)) { } else {
            this.setState({
                project_id: project_id,
                isDataLoading: true,
            }, () => {
                this.forceUpdate()
            })
        }

        setTimeout(() => {
            this.setState({
                drawerOpenProject: true,
            }, () => {
                this.forceUpdate()
            })
        }, 2000);

    };
    handleProjectLaunch = (project_id) => {
        this.props.history.push('/project/projects/'+project_id)
    };
    handleRemoveProject = (project_id) => {
          this.setState({
                removeProjectPopup: true,
                deleteProjectId: project_id,
          }, () => {
              this.forceUpdate()
          })
        
    };
    toggleRemoveProjectPopup = () => {
          if(this.state.removeProjectPopup){
            this.setState({
                deleteProjectId: "",
            }, () => {
                this.forceUpdate()
            })

          }
          this.setState({
                removeProjectPopup: !this.state.removeProjectPopup,
          }, () => {
              this.forceUpdate()
          })
        
    };
    confirmremoveProjectPopup = () => {
        if(this.state.deleteProjectId){
          if(this.state.recordtype == "opportunities"){
            var req = {
                projectid:parseInt(this.state.deleteProjectId),
                opportunitiesid:parseInt(this.state.recordId),
            }
            this.props.deleteProjectOpportunities(this.props.apolloClient.client,req)
          }
          if(this.state.recordtype == "contact"){
            var req = {
                projectid:parseInt(this.state.deleteProjectId),
                contactid:parseInt(this.state.recordId),
            }
            this.props.deleteProjectContact(this.props.apolloClient.client,req)
          }
          if(this.state.recordtype == "company"){
            var req = {
                projectid:parseInt(this.state.deleteProjectId),
                companyid:parseInt(this.state.recordId),
            }
            this.props.deleteProjectCompany(this.props.apolloClient.client,req)
          }

          this.setState({
              deleteProjectId: "",
              removeProjectPopup: false,
          }, () => {
              this.forceUpdate()
          })
        }
        
    };

    handleProjectChange = (val , newValue) => {
              
      if(newValue){
          let id =  (newValue.__isNew__) ? "0" : newValue.value;
          if(id == "0"){
            var data = {
              recordtype: this.state.recordtype,
              type: this.state.type,
              id: parseInt(this.state.recordId),
            }

           
            this.setState({
                newProjectData: data,
                createNewprojectName: newValue.label 
            }, () => {
                this.forceUpdate()
                this.setState({
                  shouldOpenCreateProjectDialog: true ,
                })
            })
          }else{
            var isAlreadyAdded  = this.state.projectListArray.filter(function(element) {
                if(element.project.id == id){
                  return element
                }
            });
              
            if(isAlreadyAdded.length > 0){
              this.setState({ showAddProject: false});
              return
            }
            let data = {srno: "1", id: id, value: newValue.label};
            if(this.state.recordtype == "opportunities"){
              var req = {
                opportunitiesdetail:{id:parseInt(this.state.recordId)},
                projectid:parseInt(id),
              }
                //console.log("req", req);return
              this.props.addProjectOpportunities(this.props.apolloClient.client,req)
            }
            if(this.state.recordtype == "contact"){
              var req = {
                // contactdetail:data,
                // projectid:parseInt(this.state.recordId),
                contactdetail:{id:parseInt(this.state.recordId)},
                projectid:data.id,

              }
              this.props.addProjectContact(this.props.apolloClient.client,req)
            }
            if(this.state.recordtype == "company"){
              var req = {
                companydetail:{id:parseInt(this.state.recordId)},
                projectid:parseInt(id),
              }
              this.props.addProjectCompany(this.props.apolloClient.client,req)
            }


          }
          this.setState({ showAddProject: false});

      }
    };

  findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
  render() {
    let { drawerOpenProject,isOpenedProject } = this.state;
    /*STRAT project Display*/
        let displayProjectList = []
        {
          this.state.projectListArray.map((item, key) => {
            var projectimg = ""
            if(item.project.image){
                projectimg = item.project.image
            }
            if(item.project.__projectdetail__.length >0){
                var cName = ""
                var relatedToID = ""
                var relatedToType = ""
                var relatedToValue = ""
                item.project.__projectdetail__.map((data, key1) => {
                    if(data.__field__.id == "67"){//name
                        cName =  data.fieldvalue;
                    }
                    if(data.__field__.id == "68" && data.values.length > 0){//related to
                      relatedToID = data.values[0].id
                      relatedToType = data.values[0].type
                      relatedToValue = data.values[0].value
                    }
                     
                })
                displayProjectList.push(
                        <div className="box_portlet mt-10 crm-calendar-event">
                                <div className="box_body px-10 py-24">
                                    <div className="lead_pl" style={{paddingLeft: '50px'}}>
                                        <div className="icon_left">
                                            <Fab
                                                  size="small"
                                                  aria-label="Task"
                                                  onClick={()=>this.handleDrawerProjectRight(item.project.id)}
                                                  className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                                              >
                                                 
                                                <Icon className="activity_icon_normal">business_center</Icon>
                                                  
                                              </Fab>
                                        </div>
                                        <a onClick={()=>this.handleDrawerProjectRight(item.project.id)} className={"cursor_poiter"}>
                                        <Grid container justify="space-between">
                                            <h6 className="m-0" style={{width:'50%'}}>{cName}</h6>
                                        </Grid>
                                        </a>
                                        <span className="related-text">{relatedToID && relatedToType && <Link to={"/"+relatedToType+"/"+relatedToType+"/"+relatedToID }>{relatedToValue}</Link>}</span>
                                        <span className="related-text">&nbsp;</span>
                                        <div className="hover_show" style={{top:'-25px',right:'-7px'}}>
                                            <IconButton aria-label="Launch" size="small" onClick={()=>this.handleProjectLaunch(item.project.id)}>
                                                <IconSmall style={{fontSize:'18px'}}>launch</IconSmall>
                                            </IconButton>
                                            <IconButton aria-label="Delete" size="small" onClick={()=>this.handleRemoveProject(item.project.id)}>
                                                <IconSmall style={{fontSize:'18px'}}>close</IconSmall>
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                         </div>
                        
                        /*<div className="box_portlet mt-10">
                                <div className="box_body px-10 py-24">
                                    <div className="lead_pl">
                                        <a onClick={()=>this.handleDrawerProjectRight(item.project.id)} className={"cursor_poiter"}>
                                        <div className="icon_left" style={{left: '-4px'}}>
                                            {!projectimg && <Icon className="">account_circle</Icon>}
                                            {projectimg && <img className="people_position" style={{height: '2.0rem',maxWidth:'85%'}} src={projectimg} alt="user" /> }
                                        </div>
                                        </a>
                                        <a onClick={()=>this.handleDrawerProjectRight(item.project.id)} className={"cursor_poiter"}>
                                        <Grid container justify="space-between">
                                            <h6 className="m-0" style={{width:'50%'}}>{cName}</h6>
                                        </Grid>
                                        </a>
                                        
                                        <div className="hover_show" style={{top:'-25px',right:'-7px'}}>
                                            <IconButton aria-label="Launch" size="small" onClick={()=>this.handleProjectLaunch(item.project.id)}>
                                                <IconSmall style={{fontSize:'18px'}}>launch</IconSmall>
                                            </IconButton>
                                            <IconButton aria-label="Delete" size="small" onClick={()=>this.handleRemoveProject(item.project.id)}>
                                                <IconSmall style={{fontSize:'18px'}}>close</IconSmall>
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                         </div>*/
                         
                )
            }
            
          })
        }
        /*END project Display*/
    
    return (
      <Card elevation={3} className="p-16 mt-16" style={{overflow: 'initial'}}>
        <Grid container justify="space-between">
            <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleProject}>Projects ({displayProjectList.length}) <Icon className="vert-middle">{isOpenedProject ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
            <IconButton aria-label="Add" size="small">
                <IconSmall onClick={()=>this.toggleAddProject()} className={this.state.showAddProject?"plusIconTransform":""}>add</IconSmall>
            </IconButton>
        </Grid>
        {isOpenedProject &&
                      <>
                          {this.state.showAddProject && 
                              <FormControl className="w-100 input_bx" style={{zIndex:10000}}>
                                  <SingleSelect
                                    SelectProps={{
                                      isCreatable: true,
                                      formatCreateLabel,
                                      isValidNewOption,
                                    }}
                                    placeholder="Add project"
                                    options={this.state.allProjectList}
                                    onChange={this.handleProjectChange}
                                  />
                              </FormControl>

                          }
                          {(!this.state.showAddProject &&  displayProjectList.length == 0) && 
                            <label className="text-hint">
                              <IconButton className="mr-8" component="span">
                                <Icon onClick={()=>this.toggleAddProject()} >business_center</Icon>
                              </IconButton>
                                Project
                            </label>
                          }
                          {displayProjectList}

                      </>
                    }

                    {this.state.shouldOpenCreateProjectDialog && (
                        <CreateNewProjectDialog
                            handleClose={this.handleProjectDialogClose}
                            open={this.state.shouldOpenCreateProjectDialog}
                            handleMinimize={this.handleProjectDialogMinimize}
                            handleFull={this.handleProjectDialogFull}
                            minimizeScreen={this.state.minimizeProjectDialog}
                            fullScreen={this.state.FullCreateProjectDialog}
                            name={this.state.createNewprojectName}
                            // opportunitiesid={this.state.opportunities_id}
                            dataFromSidebar={this.state.newProjectData}
                            // allstates={this.state}
                        />
                    )}

                    <Dialog
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={this.state.removeProjectPopup}
                        onClose={this.toggleRemoveProjectPopup}
                        disableBackdropClick
                        disableEscapeKeyDown
                        aria-labelledby="ar-remove-dialog-title"
                    >
                        <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the associated project ?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.toggleRemoveProjectPopup} color="primary">
                                Cancel
                                          </Button>
                            <Button type="Button" onClick={() => this.confirmremoveProjectPopup()} className="btn_Primary">
                                Ok
                                          </Button>

                        </DialogActions>
                    </Dialog>
                        <Drawer
                        width={"100px"}
                        variant="temporary"
                        anchor={"right"}
                        className="drawer_right"
                        open={drawerOpenProject}
                        onClose={this.closeDrawerRight}
                        ModalProps={{
                            keepMounted: true
                        }}
                    >
                    {this.state.project_id && <ProjectProfileFrame project_id={parseInt(this.state.project_id)}  handleClose={this.closeDrawerRight} />}
                    </Drawer>
                    {this.state.isDataLoading &&
                      <Snackbar
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          open={this.state.isDataLoading}
                          TransitionComponent={Fade}
                          message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                          variant={'success'}
                      />
                    }
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  projectReducer: state.projectReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    getProjectList: (client) => { dispatch(actionCreators.getProjectList(client)) },
    addProjectOpportunities: (client,req) => { dispatch(actionCreators.addProjectOpportunities(client,req)) },
    deleteProjectOpportunities: (client,req) => { dispatch(actionCreators.deleteProjectOpportunities(client,req)) },
    getRecordRelatedData: (client,req) => { dispatch(actionCreators.getRecordRelatedData(client,req)) },
    addProjectContact: (client,req) => { dispatch(actionCreators.addProjectContact(client,req)) },
    deleteProjectContact: (client,req) => { dispatch(actionCreators.deleteProjectContact(client,req)) },
    addProjectCompany: (client,req) => { dispatch(actionCreators.addProjectCompany(client,req)) },
    deleteProjectCompany: (client,req) => { dispatch(actionCreators.deleteProjectCompany(client,req)) },
  }
}


export default (connect(mapStateToProps,mapDispatchToProps)(withRouter(Project)));
