import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, MenuItem,  DialogActions } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';

class CreateNewLead extends Component {
  state = {
    workWebsiteDropdown: null,
    workWebsiteSelected: 0,
    workWebsiteoptions: [
      "Work Website",
      "Personal Website",
      "Other Website"
    ],
    socialmediaDropdown: null,
    socialmediaSelected: 0,
    socialmediaoptions: [
      "LinkedIn",
      "Twitter",
      "Google+",
      "Facebook",
      "YouTube",
      "Quora",
      "Foursquare",
      "Klout",
      "Gravatar",
      "Other"
    ],
    workEmailDropdown: null,
    workEmailSelected: 0,
    workEmailoptions: [
      "Work Email",
      "Personal Email",
      "Other Email"
    ],
    workPhoneDropdown: null,
    workPhoneSelected: 0,
    workPhoneoptions: [
      "Work Phone",
      "Mobile Phone",
      "Home Phone",
      "Other Phone"
    ],
    firstName: '',
    middleName: '',
    lastName: '',
    namePrefix: '',
    nameSuffix: '',
    company: '',
    owner: '',
    title: '',
    value: '',
    status: '',
    source: '',
    visibility: '',
    workwebsite: '',
    socialmedia: '',
    workemail: '',
    workphone: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    country: '',
    description: '',
    tags: '',
    focus: false,
    focusValue: false,
    currency: '1',

    name: "",
    email: "",
    phone: "",
    balance: "",
    age: "",
    isActive: false,
    checkbox_system_admin: 0,
    checkbox_google_sync: 0,
    checkbox_data_export: 0,
    checkbox_dropbox: 0,
    select_team_value: '',
    select_role_value: '',
    uid: 0,
    isDisabled: false
  };

  componentDidMount() {
    let teamSuggestions = [
      { label: "Team1" },
      { label: "Team2" },
      { label: "Team3" }
    ].map(teamSuggestions => ({
      value: teamSuggestions.label,
      label: teamSuggestions.label
    }));

    let roleSuggestions = [
      { label: "Role1" },
      { label: "Role2" },
      { label: "Role3" }
    ].map(teamSuggestions => ({
      value: teamSuggestions.label,
      label: teamSuggestions.label
    }));
    this.setState({ select_team_data: teamSuggestions })

    this.setState({ select_role_data: roleSuggestions })

    ValidatorForm.addValidationRule('nameTrim', (value) => {
      if (!value || !value.trim()) {
        return false;
      }
      return true;
    });

  }

  handleChange = (event, source) => {
    
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleCheckBoxChange = (event, name) => {
    event.persist();
    this.setState({ [name]: event.target.checked });
  };

  handleBlur = (event,ref) => {
    console.log("this[ref]", this[ref]);
    
    this[ref].validate(event.target.value);
}
  handleFormSubmit = () => {


    let error = [];
    if (!this.state.name || !this.state.name.trim()) {
      error.push("name")
    }

    if (!this.state.email || !this.state.email.trim()) {
      error.push("email")
    }

    if (error.length > 0) {
      this.setState({
        error
      })
      return error;
    }

   

    let session_data = JSON.parse(localStorage.getItem('user_data_session'));
    let googlesync = 0;
    let systemadmin = 0;
    let dataexport = 0;
    let dropbox = 0;
    if (this.state.checkbox_system_admin == true) {
      systemadmin = 1;
    }
    if (this.state.checkbox_google_sync == true) {
      googlesync = 1;
    }
    if (this.state.checkbox_data_export == true) {
      dataexport = 1;
    }
    if (this.state.checkbox_dropbox == true) {
      dropbox = 1;
    }
    const send_data = ({
      name: this.state.name,
      email: this.state.email,
      googlesync: googlesync,
      systemadmin: systemadmin,
      dataexport: dataexport,
      dropbox: dropbox,
      companyid: session_data.companyId
    });
    if (this.props.uid) {
      let edit_user_id = ({ userid: this.props.uid });
      let newstate = Object.assign(send_data, edit_user_id);
      this.props.saveEditUser(newstate);
    } else {
      this.props.settingAddUser(send_data);
    }
    this.props.handleClose();
  };

  componentWillMount() {
    this.setState({ isDisabled: false })
    if (this.props.uid) {
      let send_data = ({ userid: this.props.uid, type: "user" });
      this.props.getUserById(send_data);
      this.setState({ isDisabled: true })
    }
  }

  
  componentWillReceiveProps(props) {
    if ((this.props.uid && !this.props.open) || !this.state.email) {
      this.setState({
        email: props.settingReducer.userListById.data.email,
        name: props.settingReducer.userListById.data.name,
        checkbox_data_export: props.settingReducer.userListById.data.dataexport,
        checkbox_dropbox: props.settingReducer.userListById.data.dropbox,
        checkbox_google_sync: props.settingReducer.userListById.data.googlesync,
        checkbox_system_admin: props.settingReducer.userListById.data.systemadmin,
      })
    }
  }



  // handleSubmit = event => {
  //   console.log("submitted");
  //   console.log(event);
  // };

  // handleChange = event => {
  //   // event.persist();
  //   this.setState({ [event.target.name]: event.target.value });
  // };
  // handleFormSubmit = () => {
  //   console.log('submited')
  // };
  // onFocus = () => {
  //   this.setState({ focus: true })
  // }
  // onFocusValue = () => {
  //   this.setState({ focusValue: true })
  // }
  // componentDidMount() {
  //   document.addEventListener('mousedown', this.handleClickOutside);
  // }
  // componentWillUnmount() {
  //   document.removeEventListener('mousedown', this.handleClickOutside);
  // }
  // setWrapperRef = (node) => {
  //   this.wrapperRef = node;
  // }
  // handleClickOutside = (event) => {
  //   if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
  //     this.setState({ focus: false })
  //   }
  // }
  // handleClickworkWebsite = event => {
  //   this.setState({ workWebsiteDropdown: event.currentTarget });
  // };
  // handleCloseworkWebsite = () => {
  //   this.setState({ workWebsiteDropdown: null });
  // };
  // handleMenuItemworkWebsite = (event, index) => {
  //   this.setState({ workWebsiteDropdown: null, workWebsiteSelected: index });
  // };
  // handleClicksocialmedia = event => {
  //   this.setState({ socialmediaDropdown: event.currentTarget });
  // };
  // handleClosesocialmedia = () => {
  //   this.setState({ socialmediaDropdown: null });
  // };
  // handleMenuItemsocialmedia = (event, index) => {
  //   this.setState({ socialmediaDropdown: null, socialmediaSelected: index });
  // };
  // handleClickworkEmail = event => {
  //   this.setState({ workEmailDropdown: event.currentTarget });
  // };
  // handleCloseworkEmail = () => {
  //   this.setState({ workEmailDropdown: null });
  // };
  // handleMenuItemworkEmail = (event, index) => {
  //   this.setState({ workEmailDropdown: null, workEmailSelected: index });
  // };
  // handleClickworkPhone = event => {
  //   this.setState({ workPhoneDropdown: event.currentTarget });
  // };
  // handleCloseworkPhone = () => {
  //   this.setState({ workPhoneDropdown: null });
  // };
  // handleMenuItemworkPhone = (event, index) => {
  //   this.setState({ workPhoneDropdown: null, workPhoneSelected: index });
  // };
  render() {
    let {
      name,
      email
    } = this.state;
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Edit User</h4>
          <div className="d-flex">
            {/* <Tooltip title="Minimize Screen">
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">remove</Icon>
              </IconButton>
            </Tooltip> */}
            {/* <Tooltip title="Full Screen">
              <IconButton onClick={handleFull}>
                <Icon className="text-white rotate-45">height</Icon>
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
          <div className="dialog-body">
            <Grid container spacing={4}>
              <div className="p-24">
                <ValidatorForm ref="form"  instantValidate={false} onSubmit={this.handleFormSubmit} noValidate>
                  <Grid className="mb-16" container spacing={4}>
                    <Grid item sm={6} xs={12}>
                      <TextValidator
                        className="w-100 mb-16"
                        required={true}
                        label="Name"
                        ref={ref => this['name'] = ref}
                        onBlur={(event)=>this.handleBlur(event,'name')}
                        onChange={this.handleChange}
                        type="text"
                        name="name"
                        value={name}
                        validators={["required", "nameTrim"]}
                        errorMessages={["this field is required", "this field is required"]}
                        autoComplete='off'
                      />
                      <FormControl className="w-100 input_bx">
                        <InputLabel>Team Size</InputLabel>
                        <Select
                         ref={ref => this['select_team_value'] = ref}
                          name="select_team_value"
                          value={this.state.select_team_value}
                          onChange={this.handleChange}
                        >
                          {this.state.select_team_data && this.state.select_team_data.map((opt) => {
                            return <MenuItem className="select-menu-item" value={opt}>{opt.label}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextValidator
                          ref={ref => this['email'] = ref}
                          onBlur={(event)=>this.handleBlur(event,'email')}
   
                        required={true}
                        className="w-100 mb-16"
                        label="Email"
                        onChange={this.handleChange}
                        type="email"
                        name="email"
                        value={email}
                        validators={["required", 'isEmail']}
                        errorMessages={["this field is required", "email is not valid"]}
                        autoComplete='off'
                        disabled={this.state.isDisabled}
                      />
                      <FormControl className="w-100 input_bx">
                        <InputLabel>select role</InputLabel>
                        <Select
                          ref={ref => this['select_role_value'] = ref}
                          name="select_role_value"
                          value={this.state.select_role_value}
                          onChange={this.handleChange}
                        >
                          {this.state.strengthData && this.state.strengthData.map((opt) => {
                            return <MenuItem className="select-menu-item" value={opt}>{opt.value}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      Note:users not added to team will have access to all entities,reports and dashboards and will only be able to see records owned by themselves.
                    </Grid>
                    <Grid item xs={12}>
                      <h5>Privileges</h5>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checkbox_system_admin}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_system_admin')}
                            value="checkbox_system_admin"
                            color="primary"
                          />
                        }
                        label="system admin"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checkbox_google_sync}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_google_sync')}
                            value="checkbox_google_sync"
                            color="primary"
                          />
                        }
                        label="google sync"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checkbox_data_export}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_data_export')}
                            value="checkbox_data_export"
                            color="primary"
                          />
                        }
                        label="Data export"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checkbox_dropbox}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_dropbox')}
                            value=""
                            color="primary"
                          />
                        }
                        label="Drop Box"
                      />
                    </Grid>
                  </Grid>
                  <Grid>
                  </Grid>
                  <DialogActions className="padding__0">
                    <Button onClick={() => this.props.handleClose()} variant="contained">Cancel</Button>
                    <Button variant="contained" type="submit" color="primary">Save</Button>
                  </DialogActions>
                </ValidatorForm>
              </div>
            </Grid>
          </div>
      </Dialog>
    );
  }
}
const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
});
const mapDispatchToProps = dispatch => {
  return {
    settingAddUser: (data) => dispatch(actionCreators.settingAddUser(data)),
    getUserById: (id) => dispatch(actionCreators.getUserById(id)),
    saveEditUser: (data) => dispatch(actionCreators.saveEditUser(data))
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewLead);
