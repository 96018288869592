import React, { Component } from "react";
import { Fab, Grid, FormControl, Select, MenuItem, Chip, TextField, InputLabel } from "@material-ui/core";
import { TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SingleSelect } from "react-select-material-ui";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";

export const createContactDynamicFields = (data) => {

  var elements = data.state.fieldsList
  var fieldsArray = [];
  const formatCreateLabel = (value) => `Create "${value}"`;
  const isValidNewOption = (value) => value != "";
  elements.map((v, i) => {
    if (v.fieldstatus === true) {
      if (v.fieldtype.id == '1' && ((v.fieldkey == "websites" || v.fieldkey == "social" || v.fieldkey == "phones" || v.fieldkey == "email") && v.is_default)) { //website ,social, phone, email
        {
          let tempFieldArray = []
          data.state.fieldsObj[v.fieldkey].values && data.state.fieldsObj[v.fieldkey].values.map((row, index) => {
            if (!row.id) {
              data.handleAddMoreField("id", v.fieldkey, index, v.options[0].id)
            }

            if (v.fieldkey === "email") {//for email validation
              tempFieldArray.push(
                <>
                  <Select
                    name={v.fieldkey}
                    id={v.id}
                    value={row.id}
                    disableUnderline
                    onChange={(event) => data.handleAddMoreField("id", v.fieldkey, index, event.target.value)}
                    SelectDisplayProps={{ className: "multiple-text-dropdown" }}
                  >
                    {v.options && v.options.map((item, key) =>
                      <MenuItem key={item.id} value={item.id}>{item.optionvalue}</MenuItem>
                    )}
                  </Select>
                  <span style={{ color: '#0000008a' }}>{(v.recordcreationdefaultvalue == 2) ? "*" : ""}</span>
                  <TextValidator
                    ref={ref => data[v.fieldkey] = ref}
                    onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                    type="text"
                    id={v.id}
                    placeholder={"Add " + v.fieldkey}
                    className="w-100 input_bx"
                    onChange={(event) => data.handleAddMoreField("value", v.fieldkey, index, event.target.value)}
                    name={v.fieldkey}
                    value={row.value}
                    disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                    validators={(v.recordcreationdefaultvalue == 2) ? ["required", "isEmail"] : ["isEmail"]}
                    errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required", "enter valid email address."] : ["enter valid email address."]}
                  />
                </>
              )
            } else {
              tempFieldArray.push(
                <>
                  <Select
                    name={v.fieldkey}
                    id={v.id}
                    value={row.id}
                    disableUnderline
                    onChange={(event) => data.handleAddMoreField("id", v.fieldkey, index, event.target.value)}
                    SelectDisplayProps={{ className: "multiple-text-dropdown" }}
                  >
                    {v.options && v.options.map((item, key) =>
                      <MenuItem key={item.id} value={item.id}>{item.optionvalue}</MenuItem>
                    )}
                  </Select>
                  <span style={{ color: '#0000008a' }}>{(v.recordcreationdefaultvalue == 2) ? "*" : ""}</span>
                  <TextValidator
                    ref={ref => data[v.fieldkey] = ref}
                    onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                    type="text"
                    id={v.id}
                    placeholder={"Add " + v.fieldkey}
                    className="w-100 input_bx"
                    onChange={(event) => data.handleAddMoreField("value", v.fieldkey, index, event.target.value)}
                    name={v.fieldkey}
                    value={row.value}
                    disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                    validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                    errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
                  />
                  {v.fieldkey != "email" && row.value && index == (data.state.fieldsObj[v.fieldkey].values.length - 1) &&
                    <span className="add-more-field" type="button" onClick={() => { data.addNewField(v.fieldkey, v.options[0]) }}>Add more</span>
                  }
                </>
              )
            }
          })
          fieldsArray.push(
            <Grid item xs={6}>
              {tempFieldArray}
            </Grid>
          )
        }

      } else if (v.fieldtype.id == '1' && v.id == '16') { // name

        var valTag = "";
        if (data.state.fieldsObj[v.fieldkey]) {
          valTag = data.state.fieldsObj[v.fieldkey].contactvalue
          console.log("valTag", valTag);
        }
        

        fieldsArray.push(<>
          {/* <Grid item xs={6}>
            <TextValidator
              ref={ref => data[v.fieldkey] = ref}
              onBlur={(event)=>data.handleBlur(event,v.fieldkey)}
              required={(v.recordcreationdefaultvalue == 2) ? true : false}
              type="text"
              id={v.id}
              disabled={v.recordcreationdefaultvalue == 3?true:false}
              className="w-100 input_bx"
              label={"First Name"}
              onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
              name={v.fieldkey}
              value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
              validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
              errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
            />
          </Grid> */}
          <Grid item xs={6}>
            <FormControl className="w-100 input_bx">
              <Autocomplete
                
                freeSolo
                forcePopupIcon={false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                name={v.fieldkey}
                onChange={(event, values, fieldkey) => data.handleNameChange(event, values, v.fieldkey)}
                id={v.fieldkey}
                options={data.state.gmailSuggestion}
                // getOptionLabel={option => option.label}
                renderOption={(option) =>
                  (
                    <>
                      <Grid container className="mb-10 mt-10 crm-custom-suggestion-autocomplete" spacing={1}>
                        <Grid item md={2}>
                          {option.label ?
                            <Fab className="small-size overflow-hidden custom-avatar" size="small">
                              <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (option.label).charAt(0)}>{(option.label).charAt(0).toUpperCase()}</h5>
                            </Fab>
                            :
                            <Fab className="small-size overflow-hidden custom-avatar" size="small">
                              <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (option.email).charAt(0)}>{(option.email).charAt(0).toUpperCase()}</h5>
                            </Fab>
                          }
                        </Grid>
                        <Grid className="text_ellipsis pt-5" item md={10}>
                          {option.label &&
                            <>
                              <span className="custom-label font-weight-500">{option.label}</span>
                              <br />
                            </>
                          }
                          <span className="custom-email text-hint">{option.email}</span>
                        </Grid>
                      </Grid>
                    </>
                  )
                }
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
                // value={data.state.fieldsObj[v.fieldkey] ? { label: data.state.fieldsObj[v.fieldkey].contactvalue, value: 0 } : {}}
                renderInput={params => (
                  <TextValidator
                    {...params}
                    variant="standard"
                    label={"First Name"}
                    fullWidth
                    ref={ref => data[v.fieldkey] = ref}
                    onChange={(event, fieldkey) => data.getChangeSuggestions(event, v.fieldkey)}
                    onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                    name={v.fieldkey}
                    value={valTag}
                    validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                    errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              type="text"
              id="lastname"
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              className="w-100 input_bx"
              label={"Last Name"}
              onChange={(event) => data.handleNormalFields(event)}
              name={"lastname"}
              value={data.state.lastname}
            />
          </Grid></>
        )
      } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') { //tags
        var valTag = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          valTag = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={6}>
          <Autocomplete
            disableOpenOnFocus
            multiple
            value={valTag}
            filterSelectedOptions
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            name={v.fieldkey}
            id="tagInputId1"
            options={data.state.systemTagsList.map(option => option.tagname)}
            freeSolo
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option.charAt(0).toUpperCase() + option.slice(1)} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}

                {...params}
                label="Tags"
                placeholder="Add tag"
                name={v.fieldkey}

                value={valTag && valTag.length ? 1 : null}
                className="w-100 input_bx"
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                validators={(v.recordcreationdefaultvalue == 2 && (valTag == null || valTag.length == 0)) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2 && (valTag == null || valTag.length == 0)) ? ["this field is required"] : []}
                onFocus={() =>
                  setTimeout(() => {
                    document.getElementById("tagInputId1").value = "";
                  }, 0)
                }
              />
            )}
          />
        </Grid>)
      } else if (v.fieldtype.id == '1' && v.id == '20') {  // owner
        var nameOwner = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          nameOwner = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={6}>
          <FormControl className="w-100 input_bx">
            <Autocomplete
              disableOpenOnFocus
              forcePopupIcon={false}
              name={v.fieldkey}
              options={data.state.usersListArray}
              getOptionLabel={option => option.name}
              value={nameOwner}
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              name={v.fieldkey}
              onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
              id={v.id}
              renderInput={params => (
                <TextValidator
                  {...params}
                  variant="standard"
                  label={"Owner"}
                  fullWidth
                  ref={ref => data[v.fieldkey] = ref}
                  onBlur={(event) => data.handleBlur(event, v.fieldkey)}

                  name={v.fieldkey}
                  value={nameOwner && nameOwner.length ? 1 : null}
                  required={(v.recordcreationdefaultvalue == 2) ? true : false}
                  validators={(v.recordcreationdefaultvalue == 2 && (nameOwner == null || nameOwner.length == 0)) ? ["required"] : []}
                  errorMessages={(v.recordcreationdefaultvalue == 2 && (nameOwner == null || nameOwner.length == 0)) ? ["this field is required"] : []}
                />
              )}
            />
          </FormControl>
        </Grid>)
      } else if (v.fieldtype.id == '1' || v.fieldtype.id == '9') {//TextField 
        fieldsArray.push(<Grid item xs={6}>
          <TextValidator
            type="text"
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            id={v.id}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
          />
        </Grid>)
      }
      else if (v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7') {// Number, Currency
        fieldsArray.push(<Grid item xs={6}>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}


            type="number"
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            id={v.id}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '2' && v.id == "25") { // Text Area , Address
        var countryValidation = true;
        if (data.state.country != "" && data.state.country != null) {
          countryValidation = false;
        }
        fieldsArray.push(
          <>
            <Grid item xs={12}>
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}

                multiline
                type="text"
                id={v.id}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                label={v.fieldlabel}
                onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
                name={v.fieldkey}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                type="text"
                id={"city"}
                ref={ref => data['city'] = ref}
                onBlur={(event) => data.handleBlur(event, 'city')}

                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                label={"City"}
                onChange={(event) => data.handleNormalFields(event)}
                name={"city"}
                value={data.state.city}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                ref={ref => data['addressState'] = ref}
                onBlur={(event) => data.handleBlur(event, 'addressState')}

                type="text"
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                id={"state"}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                label={"State"}
                onChange={(event) => data.handleNormalFields(event)}
                name={"addressState"}
                value={data.state.addressState}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                ref={ref => data['zipcode'] = ref}
                onBlur={(event) => data.handleBlur(event, 'zipcode')}

                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                type="text"
                id={"zipcode"}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                label={"Zip code"}
                onChange={(event) => data.handleNormalFields(event)}
                name={"zipcode"}
                value={data.state.zipcode}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className="w-100 input_bx">
                <Autocomplete
                  disableOpenOnFocus
                  forcePopupIcon={false}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  name={"country"}
                  onChange={data.handleCountryChange}
                  id={"country"}
                  options={data.state.countryListArray}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextValidator
                      {...params}
                      variant="standard"
                      label={"Country"}
                      ref={ref => data['country'] = ref}
                      onBlur={(event) => data.handleBlur(event, 'country')}

                      fullWidth
                      name={v.fieldkey}
                      value={countryValidation ? null : 1}
                      required={(v.recordcreationdefaultvalue == 2) ? true : false}
                      validators={(v.recordcreationdefaultvalue == 2 && countryValidation) ? ["required"] : []}
                      errorMessages={(v.recordcreationdefaultvalue == 2 && countryValidation) ? ["this field is required"] : []}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </>
        )
      } else if (v.fieldtype.id == '2') { // Text Area
        fieldsArray.push(<Grid item xs={6}>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}

            multiline
            type="text"
            id={v.id}
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '4') { // Drop Down
        if (v.id === "26" || v.id === "19") {  // Visibility, contacttype
          fieldsArray.push(<Grid item xs={6}>
            <FormControl className="w-100 input_bx">
              {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
              <SelectValidator
                ref={ref => data[v.fieldkey] = ref}

                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].values : ""}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey)}
                name={v.fieldlabel}
                id={v.id}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                label={v.fieldlabel}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
              >
                {v.options && v.options.map((item, key) =>
                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.name}</MenuItem>
                )}
                {v.options && v.id == "19" &&
                  <MenuItem className="select-menu-item item-with-link" value={5}><Link to="/settings/contact-types">Customize Contact Types</Link></MenuItem>
                }
              </SelectValidator>
            </FormControl>
          </Grid>
          )
        } else if (v.id === "17") { // company

          fieldsArray.push(<Grid item xs={6}>
            <FormControl className="w-100 input_bx " style={{ zIndex: 20 }}>
              <InputLabel
                style={{ fontSize: "15px", marginTop: "-6px" }}
                shrink={
                  data.state.comp_value || data.state.fcus1 ? true : false
                }
              >
                {" "}
                {v.fieldlabel}
              </InputLabel>
              <SingleSelect

                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={data.state.comp_value}
                SelectProps={{ isCreatable: true, formatCreateLabel, isValidNewOption }}
                placeholder=""
                options={data.state.comp_options}
                onChange={data.handleCompanyChange}
                onFocus={() => data.setState({ fcus1: true })}
                onBlur={() => data.setState({ fcus1: false })}

                onCreateOption={data.handleCompanyCreate}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
              />
            </FormControl>
          </Grid>
          )
        }
        else {
          fieldsArray.push(<Grid item xs={6}>
            <FormControl className="w-100 input_bx">
              {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
              <SelectValidator
                ref={ref => data[v.fieldkey] = ref}

                label={v.fieldlabel}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].values : ""}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey)}
                name={v.fieldlabel}
                id={v.id}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
              >

                {v.options && v.options.map((item, key) =>
                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.optionvalue}</MenuItem>
                )}

              </SelectValidator>
            </FormControl>
          </Grid>
          )
        }
      }
      if (v.fieldtype.id == '5') { // Multi Drop Down
        if (data.state.fieldsObj[v.fieldkey]) {
          var valDetail = [];
          valDetail = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={6}>
          <FormControl className="w-100 input_bx">
            <Autocomplete
              multiple
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              name={v.fieldkey}
              onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
              id={v.id}
              options={v.options}
              getOptionLabel={option => option.optionvalue}
              renderInput={params => (
                <TextValidator
                  ref={ref => data[v.fieldkey] = ref}
                  onBlur={(event) => data.handleBlur(event, v.fieldkey)}

                  {...params}
                  variant="standard"
                  label={v.fieldlabel}
                  fullWidth
                  name={v.fieldkey}
                  value={valDetail && valDetail.length ? 1 : null}
                  required={(v.recordcreationdefaultvalue == 2) ? true : false}
                  validators={(v.recordcreationdefaultvalue == 2 && (valDetail == null || valDetail.length == 0)) ? ["required"] : []}
                  errorMessages={(v.recordcreationdefaultvalue == 2 && (valDetail == null || valDetail.length == 0)) ? ["this field is required"] : []}
                />
              )}
            />
          </FormControl>
        </Grid>
        )
      }
      if (v.fieldtype.id == '3') { //Checkbox
        fieldsArray.push(<Grid item xs={6} className="checkbox-margin-18">
          <FormControl className="w-100 input_bx">
            <FormControlLabel
              control={
                <Checkbox
                  name={v.fieldkey}
                  id={v.id}
                  checked={data.state.checkedB}
                  onChange={(event, value) => data.handleChange({ fieldid: v.id, contactvalue: value ? "1" : "0" }, v.fieldkey)}
                  value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
                  color="primary"
                />
              }
              label={v.fieldlabel}
            />
          </FormControl>
        </Grid>
        )
      }
      if (v.fieldtype.id == '8') {//datepicker field
        var dtFormat = "MM/dd/yyyy";
        var selectedDate = data.state.selectedDate;
        if (data.state.fieldsObj[v.fieldkey]) {
          selectedDate = data.state.fieldsObj[v.fieldkey].values
        }

        if (data.state.userSettings != "") {
          dtFormat = data.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }
        fieldsArray.push(<Grid item xs={6} >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className="w-100 input_bx">
              <KeyboardDatePicker
                label={v.fieldlabel}
                format={dtFormat}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                placeholder={dtFormat}
                error={(v.recordcreationdefaultvalue == 2 && selectedDate == null || selectedDate == "Invalid Date") ? true : false}
                value={selectedDate}
                onChange={(event, value) => data.handleChange({ fieldid: v.id, values: event == "Invalid Date" ? "Invalid Date" : event }, v.fieldkey)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                helperText={(v.recordcreationdefaultvalue == 2 && selectedDate == null) ? "this field is required" : (selectedDate == "Invalid Date") ? "enter valid date." : ""}

              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid>
        )
      }

    }
  })

  return fieldsArray;
}