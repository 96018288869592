import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Icon, Grid, MenuItem } from "@material-ui/core";
import DropdownDefault from "../Layout/DropdownDefault";

class NoteDialog extends Component {
    state = {
        content: "",
        attachment: null
    };

    handleSubmit = event => {
        console.log(this.state);
    };

    handleChange = event => {
        event.persist();
        this.setState({ [event.target.name]: event.target.value });
    };

    handleContentChange = contentHtml => {
        this.setState({
            content: contentHtml
        });
    };

    handleAttachmentSelection = event => {
        this.setState({
            attachment: event.target.files[0]
        });
    };
    render() {
        let { attachment } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                onClose={handleClose}
                open={open}
                maxWidth="md"
                className="dialog_popup"
                aria-labelledby="note-dialog-title"
            >
                <DialogTitle id="note-dialog-title" className="dialog_header">
                    <Grid container justify="space-between">
                        <Grid className="flex-auto">Note</Grid>
                        <Grid>
                            <IconButton size="small" onClick={() => this.props.handleClose()}>
                                <Icon>close</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="dialog_body">
                    {/* <RichTextEditor
                        content={content}
                        handleContentChange={this.handleContentChange}
                        placeholder="Start typing to leave a note..."
                   />*/}
                    <DropdownDefault menuButton={<p>Associated with 2 records <Icon className="vert-middle">keyboard_arrow_down</Icon></p>}>
                        <MenuItem className="flex flex-middle">
                            <Icon className="mr-16">reply</Icon> Reply
                        </MenuItem>
                        <MenuItem className="flex flex-middle">
                            <Icon className="mr-16">archive</Icon> Archive
                        </MenuItem>
                        <MenuItem className="flex flex-middle">
                            <Icon className="mr-16">delete</Icon> Delete
                        </MenuItem>
                    </DropdownDefault>
                </DialogContent>
                <DialogActions className="flex flex-wrap flex-space-between dialog_footer mb-10">
                    {attachment && <p className="mr-24">{attachment.name}</p>}
                    <label htmlFor="attachment">
                        <IconButton className="mr-8" component="span" size="small">
                            <Icon style={{ transform: "rotate(-90deg)" }}>attachment</Icon>
                        </IconButton>
                    </label>
                    <input
                        onChange={this.handleAttachmentSelection}
                        className="d-none"
                        id="attachment"
                        type="file"
                    />
                    <div className="flex flex-middle">
                        <Button onClick={() => this.props.handleClose()} variant="contained">Cancel</Button>
                        <Button variant="contained" color="secondary" onClick={() => this.props.handleClose()} className="ml-10">Save note</Button>
                    </div>
                </DialogActions>
            </Dialog >
        );
    }
}
export default NoteDialog;
