import React, {
    Component
} from "react";
import LeadTableComponent from './LeadTableComponent';
import LeadCreationRule from './LeadCreationRule'
import {
    Icon,
    Switch,
    FormControlLabel,
    IconButton,
    withStyles,
    Button
} from "@material-ui/core";
import {
    Link
} from "react-router-dom";
import CreateNewFieldLeadDialog from "./CreateNewFieldLeadDialog";
import * as actions from '../../store/actions/index';
import {
    connect
} from "react-redux";
import {
    compose
} from 'redux';
import FontAwesome from 'react-fontawesome'

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class SettingLead extends Component {

    constructor(props) {
        super(props);

        this.state = {
            leaddata: [],
            leadFieldIds: [],
            leadOrderIds: [],
            activeleaddatas: [],
            inactiveleaddatas: [],
            shouldOpenCreateFieldLeadDialog: false,
        };
    }

    handleChange = (event) => {
        let val = {
            typeId: 1,
            fieldstatus: event.target.checked,
            generatedfieldid: event.target.value
        }

        this.props.updateCustomFieldStatus(this.props.apolloClient.client, val);
    }
    toCamelCase = (s) => {
        return s.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
    };

    componentDidMount() {
        let val = {
            typeId: 1
        }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.customFieldTypeList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.customFieldList.data && props.settingReducer.customFieldTypeList.data) {
            const customFields = props.settingReducer.customFieldList.data;

            let activeleaddatas = [];
            let inactiveleaddatas = [];
            let leadFieldIds = [];
            let leadOrderIds = [];

            customFields.map((fieldData, index) => {
                var iconStr = this.toCamelCase(fieldData.fieldicon)
                if (fieldData.fieldstatus === true) {
                    leadFieldIds.push(parseFloat(fieldData.id));
                    leadOrderIds.push(parseFloat(fieldData.orderno));

                    let fieldlicon = (fieldData.fieldicon === 'fa-dollar-sign') ? 'fa-usd' : fieldData.fieldicon;

                    activeleaddatas.push({
                        drag: < Link className = "drag-handle"
                        leadOrderIds = {
                            leadOrderIds
                        }
                        to = "#" > < Icon > drag_handle < /Icon></Link > ,
                        key: fieldData.id,
                        name: (<div>
                                <FontAwesome className={'crm-fontawsome-icon ' + fieldlicon} ></FontAwesome> 
                                <label className="crm-custom-field-label">{fieldData.fieldlabel}</label>
                            </div>
                        ),
                        fieldType: fieldData.fieldtype.name,
                        creationRule: (fieldData.recordcreationrules_editable) ?
                            <
                            LeadCreationRule dropName = "create"
                        fieldId = {
                            index
                        }
                        options = {
                            fieldData.recordcreationrulesvalue
                        }
                        disabled = {
                            false
                        }
                        defaultChecked = {
                            fieldData.recordcreationdefaultvalue
                        }
                        />:
                            <
                            LeadCreationRule dropName = "create"
                        fieldId = {
                            index
                        }
                        options = {
                            fieldData.recordcreationrulesvalue
                        }
                        disabled = {
                            true
                        }
                        defaultChecked = {
                            fieldData.recordcreationdefaultvalue
                        }
                        />,
                        editRule: (fieldData.recordeditrules_editable) ?
                            <
                            LeadCreationRule dropName = "edit"
                        fieldId = {
                            index
                        }
                        options = {
                            fieldData.recordeditrulesvalue
                        }
                        disabled = {
                            false
                        }
                        defaultChecked = {
                            fieldData.recordeditdefaultvalue
                        }
                        />:
                            <
                            LeadCreationRule dropName = "edit"
                        fieldId = {
                            index
                        }
                        options = {
                            fieldData.recordeditrulesvalue
                        }
                        disabled = {
                            true
                        }
                        defaultChecked = {
                            fieldData.recordeditdefaultvalue
                        }
                        />,
                        action: < React.Fragment >

                            <
                            FormControlLabel
                        value = {
                            fieldData.generatedfieldid
                        }
                        control = {
                            (fieldData.fieldstatus) ? < Switch onChange = {
                                this.handleChange
                            }
                            color = "primary"
                            defaultChecked disabled = {!fieldData.isdiactivated
                            }
                            /> : <Switch onChange={this.handleChange} color="primary" disabled={!fieldData.isdiactivated} / >
                        }
                        labelPlacement = "start"
                        className = "ml-0 mr-10" /
                        >
                        <
                        /React.Fragment>
                    })
                }

                if (fieldData.fieldstatus === false) {
                    inactiveleaddatas.push({
                        key: fieldData.id,
                        name: fieldData.fieldlabel,
                        action: < React.Fragment >
                            <
                            FormControlLabel
                        value = {
                            fieldData.generatedfieldid
                        }
                        control = {
                            (fieldData.fieldstatus) ? < Switch onChange = {
                                this.handleChange
                            }
                            color = "primary"
                            defaultChecked disabled = {!fieldData.isdiactivated
                            }
                            /> : <Switch onChange={this.handleChange} color="primary" disabled={!fieldData.isdiactivated} / >
                        }
                        labelPlacement = "start"
                        className = "ml-0 mr-10" /
                        >
                        <
                        /React.Fragment>
                    })
                }
            })

            this.setState({
                activeleaddatas: activeleaddatas,
                inactiveleaddatas: inactiveleaddatas,
                leadFieldIds: leadFieldIds,
                leadOrderIds: leadOrderIds
            }, () => this.forceUpdate());
        }
    }
    closePopUp = () => {
        this.setState({
            shouldOpenCreateFieldLeadDialog: false
        });
    }
    updateFieldsOrder = (fromIndex, toIndex) => {

        let leadFieldIds = [];

        const leaddata = this.state.activeleaddatas;
        const item = leaddata.splice(fromIndex, 1)[0];

        leaddata.splice(toIndex, 0, item);

        this.setState({
            leaddata
        })
        const leadOrderIds = this.state.leadOrderIds;
        leaddata.map((value, index) => {
            leadFieldIds.push(parseFloat(value.key));
        });

        let val1 = {
            typeId: 1,
            orderno: leadOrderIds,
            fieldid: leadFieldIds
        }
        this.props.updateCustomFieldOrder(this.props.apolloClient.client, val1);
    }
    handleLeadDialogClose = () => {
        this.setState({
            shouldOpenCreateFieldLeadDialog: false
        });
    };
    render() {
        let {
            shouldOpenCreateFieldLeadDialog
        } = this.state;
        return ( <
            >
            <
            div className = "d-flex justify-content-end mb-16" >
            <
            Button variant = "contained"
            color = "primary"
            onClick = {
                () => this.setState({
                    shouldOpenCreateFieldLeadDialog: true
                })
            }
            size = "small" > Add field < /Button> {
                shouldOpenCreateFieldLeadDialog && ( <
                    CreateNewFieldLeadDialog handleClose = {
                        this.handleLeadDialogClose
                    }
                    open = {
                        shouldOpenCreateFieldLeadDialog
                    }
                    allData = {
                        this
                    }
                    />
                )
            } <
            /div> <
            LeadTableComponent activeleaddata = {
                this.state.activeleaddatas
            }
            inActiveleaddata = {
                this.state.inactiveleaddatas
            }
            allData = {
                this
            }
            /> <
            />
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        customFieldList: (client, request) => {
            dispatch(actions.customFieldList(client, request))
        },
        customFieldTypeList: (client) => {
            dispatch(actions.customFieldTypeList(client))
        },
        updateCustomFieldStatus: (client, request) => {
            dispatch(actions.updateCustomFieldStatus(client, request))
        },
        updateCustomFieldOrder: (client, request) => {
            dispatch(actions.updateCustomFieldOrder(client, request))
        },

    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SettingLead);