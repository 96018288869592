import React, { Component } from "react";
import {
  Dialog,
  IconButton,
  Button,
  Icon,
  Tooltip,
  Grid,
  DialogActions,
  FormControl,
  MenuItem,
  TextField,
  Checkbox,
  InputLabel,
  FormControlLabel,
} from "@material-ui/core";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Redirect, Route } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { lastDayOfDecade } from "date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from 'moment';
class ConvertLeadDialog extends Component {
  state = {
    lead_id: "",
    dialogTitle: "",
    contactTypes: [],
    comp_options: [],
    firstname: "",
    lastname: "",
    comp_value: "",
    company: {},
    contacttype: "",
    fieldsObj: {},
    dateformat: "MM/dd/yyyy",
    selectedDate: new Date(),
    timeZone: "America/New_York",
    userSettings: [],
    selectedOwner: [],
    valuefield: 0,
    redirectToLeads: false,
    companyCurrencyData:[],
    fieldsObjOpportunity: {
        
    },
    uSetting:"",
    pipelineListArray: [],
  };

  componentWillMount() {
    var closeDate = new Date(); // Now
    closeDate.setDate(closeDate.getDate() + 30); // Set now + 30 days as the new date
    this.setState({
        fieldsObjOpportunity: {
          ...this.state.fieldsObjOpportunity,
          ["closedate"]: {fieldid: "43",values:closeDate},
        },
        selectedDate:closeDate,
      })

    this.props.getPipelines(this.props.apolloClient.client);
    this.props.getContactType(this.props.apolloClient.client);
    this.props.getCompanyRecList(this.props.apolloClient.client);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    if (this.props.states) {
      let stateData = this.props.states;

      if (stateData.leadFieldData) {
        let value = ""
        var valuekey = this.findWithAttr(stateData.leadFieldData, 'fieldkey', 'value');
        let v = stateData.leadFieldData[valuekey];
        if(v.leaddetail.length > 0 && v.leaddetail[0].values.length > 0){
          value = stateData.leadFieldData[valuekey].leaddetail[0].values[0].value
        }

        let firstname = this.props.states.firstname
          ? this.props.states.firstname
          : "";
        let lastname = this.props.states.lastname
          ? this.props.states.lastname
          : "";
        let title =
          "Convert " +
          stateData.firstname +
          " " +
          stateData.lastname +
          " to Person ?";

        this.setState({
          firstname: firstname,
          lastname: lastname,
          dialogTitle: title,
          fieldsObjOpportunity: {
            ...this.state.fieldsObjOpportunity,
            ["name"]: {fieldid: "40",opportunitiesvalue:firstname+' '+lastname+' -'},
            ["value"]: {fieldid: "50",opportunitiesvalue:value},
          }
        });
      }
      if (stateData.lead_id) {
        this.setState({ lead_id: stateData.lead_id });
      }
    }
  }

  componentWillReceiveProps(props) {
    //Pipelines list array
    if (props.settingReducer.pipelineListArray.data) {
        let pipesList = props.settingReducer.pipelineListArray.data
        var pipeline = this.findWithAttr(pipesList, 'id', "1");
        var pipelineArray = pipesList[pipeline];

        this.setState({ 
            pipelineListArray: pipesList,
            fieldsObjOpportunity: {
              ...this.state.fieldsObjOpportunity,
              ["pipeline"]: { fieldid: "41", values:pipelineArray},
              //["stage"]: { fieldid: "47", values:pipelineArray.stage[0]},
              //["winpercentage"]: { fieldid: "52", opportunitiesvalue:pipelineArray.stage[0].probability},
            }
        },()=>{
            this.forceUpdate()
        })

    }
    if (props.settingReducer.companyCurrencyData.data != null) {
        var cData = props.settingReducer.companyCurrencyData.data;
        this.setState({companyCurrencyData:cData[0]});
    }
    if (
      props.contactReducer.contactTypeList &&
      props.contactReducer.contactTypeList.data &&
      this.state.contactTypes.length == 0
    ) {
      this.setState({
        contactTypes: props.contactReducer.contactTypeList.data,
        contacttype: props.contactReducer.contactTypeList.data[0],
      });
    }

    if (
      props.settingReducer.userSettingArray &&
      props.settingReducer.userSettingArray.data
    ) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      // this.setState({ dateformat: format, timeZone: timezone })
      this.setState({
          uSetting:usersetting,
      })
    }

    if (
      props.states.leadFieldData &&
      props.settingReducer.usersListArray &&
      props.settingReducer.usersListArray.data
    ) {
      const usersListArray = props.settingReducer.usersListArray.data;
      let arr_key = this.findWithAttr(
        props.states.leadFieldData,
        "fieldkey",
        "owner"
      );

      if (
        props.states.leadFieldData[arr_key].leaddetail &&
        props.states.leadFieldData[arr_key].leaddetail.length > 0
      ) {
        if (
          props.states.leadFieldData[arr_key].leaddetail[0].values.length > 0
        ) {
          let ownerName =
            props.states.leadFieldData[arr_key].leaddetail[0].values;
          var companyOwnerKey = this.findWithAttr(
            usersListArray,
            "id",
            ownerName[0].id
          );
          if (companyOwnerKey !== -1) {
            let values = usersListArray[companyOwnerKey];
            this.setState({ selectedOwner: values });
          }
        }
      }
      this.setState({ userSettings: usersListArray });
    }

    if (
      props.settingReducer.companyRecList &&
      props.settingReducer.companyRecList.data &&
      props.settingReducer.companyRecList.data.data
    ) {
      let companyOptions = [];
      props.settingReducer.companyRecList.data.data.map((compOpt) => {
        companyOptions.push({ label: compOpt.name, value: compOpt.company_id });
      });

      if (this.props.states.companyName != "") {
        companyOptions.push({
          label: this.props.states.companyName,
          value: "0",
        });
        this.setState({ comp_value: "0" });
      } else {
        companyOptions.push({
          label: this.props.states.firstname + "'s company",
          value: "0",
        });
        this.setState({ comp_value: "0" });
      }
      this.setState({ comp_options: companyOptions });
    } else {
      // console.log(this.props.states.companyName,'this.props.states.companyNamethis.props.states.companyNamethis.props.states.companyName');

      let companyOptions = [];
      if (this.props.states.companyName) {
        companyOptions.push({
          label: this.props.states.companyName,
          value: "0",
        });
        this.setState({ comp_value: "0" });
      } else {
        companyOptions.push({
          label: this.props.states.firstname + "'s company",
          value: "0",
        });
        this.setState({ comp_value: "0" });
      }
      this.setState({ comp_options: companyOptions });
    }
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };

  handleCompanyChange = (val, newValue) => {
    let id = newValue.__isNew__ ? "0" : newValue.value;

    let compopt = this.state.comp_options;
    if (newValue.__isNew__) {
      let key = this.findWithAttr(compopt, "value", "0");

      if (key != -1) compopt.splice(key, 1);

      compopt.push({ label: newValue.label, value: id });
    }

    let comp_values = { id: id, name: newValue.label };
    this.setState({
      comp_value: id,
      company: comp_values,
      comp_options: compopt,
    });
  };

  handleOwnerChange = (owner) => {
    this.setState({ selectedOwner: owner });
  };

  handleChangefieldObj = (val, name) => {
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val,
      },
    });
  };

  handleDateChange = (event) => {
    this.setState({ selectedDate: event });
  };

  handleChange = (event, source) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCheckChange = (event) => {
    this.setState({
      showOportunities: event.target.checked,
    });
  };

  handleFormSubmit = () => {
    let submitData = [];

    if (this.props.states.leadFieldData) {
      let allfieldsData = this.props.states.leadFieldData;
      allfieldsData.map((fieldData, key) => {
        if (
          fieldData.fieldkey != "name" &&
          fieldData.fieldkey != "owner" &&
          fieldData.fieldkey != "status" &&
          fieldData.fieldkey != "source" &&
          fieldData.fieldkey != "value" &&
          fieldData.fieldkey != "company"
        ) {
          if (
            fieldData.leaddetail &&
            fieldData.leaddetail[0] &&
            fieldData.leaddetail[0].values
          ) {
            if (fieldData.fieldkey == "email") {
              submitData.push({
                fieldid: "21",
                values: fieldData.leaddetail[0].values,
              });
            }
            if (fieldData.fieldkey == "visibility") {
              submitData.push({
                fieldid: "26",
                values: fieldData.leaddetail[0].values,
              });
            }
            if (fieldData.fieldkey == "phones") {
              submitData.push({
                fieldid: "22",
                values: fieldData.leaddetail[0].values,
              });
            }
            if (fieldData.fieldkey == "address") {
              submitData.push({
                fieldid: "25",
                values: fieldData.leaddetail[0].values,
              });
            }
            if (fieldData.fieldkey == "description") {
              submitData.push({
                fieldid: "27",
                contactvalue: fieldData.leaddetail[0].fieldvalue,
              });
            }
            if (fieldData.fieldkey == "social") {
              submitData.push({
                fieldid: "24",
                values: fieldData.leaddetail[0].values,
              });
            }
            if (fieldData.fieldkey == "tags") {
              submitData.push({
                fieldid: "28",
                values: fieldData.leaddetail[0].values,
              });
            }
            if (fieldData.fieldkey == "websites") {
              submitData.push({
                fieldid: "23",
                values: fieldData.leaddetail[0].values,
              });
            }
            if (fieldData.fieldkey == "title") {
              submitData.push({
                fieldid: "18",
                contactvalue: fieldData.leaddetail[0].fieldvalue,
              });
            }
          }
        }
      });
    }

    let value = "";
    if (this.state.comp_options) {
      let comp_key = this.findWithAttr(
        this.state.comp_options,
        "value",
        this.state.comp_value
      );
      value = this.state.comp_options[comp_key];
    }
    let ownerVal = [];
    ownerVal =
      this.state.selectedOwner && this.state.selectedOwner.email
        ? [
            {
              srno: "1",
              id: this.state.selectedOwner.id,
              value: this.state.selectedOwner.email,
              name: this.state.selectedOwner.name,
            },
          ]
        : [];
    submitData.push({ fieldid: "20", values: ownerVal });
    this.state.firstname.length > 0 &&
      submitData.push({
        fieldid: "16",
        names: {
          firstname: this.state.firstname,
          lastname: this.state.lastname,
        },
      });
    value &&
      value.value &&
      submitData.push({
        fieldid: "17",
        values: [{ srno: "1", id: value.value, value: value.label }],
      });
    this.state.contacttype &&
      submitData.push({
        fieldid: "19",
        values: [
          {
            srno: "1",
            id: this.state.contacttype.id,
            value: this.state.contacttype.name,
          },
        ],
      });

    let list = {
      filterdata: [],
      list: {
        limit: 10,
        pageno: 1,
        searchtext: "",
        sorttype: "",
        type: "list",
        fieldId: 0,
        columnname: "",
      },
      filterid: 1,
    };

    var opportunitiesdata=[]//for create opportunity if checkboc check 
    if(this.state.showOportunities){
      let submitDataOpp = JSON.parse(JSON.stringify(this.state.fieldsObjOpportunity));
      submitDataOpp = Object.values(submitDataOpp).map((row)=>{
        if(row.fieldid == "50"){//Value fiels
          row.values = [{id:this.state.companyCurrencyData.currencyid.toString(),srno:"1",value:row.opportunitiesvalue}]
          delete row['opportunitiesvalue'];
        }
        
        
        if(row.fieldid == "41" || row.fieldid == "47"){
          row.values = [{srno:"1", id:row.values.id,value:row.values.name}]
        }
         
        //closedate
        if(row.fieldid == "43"){//date field
          if(row.values == null){
            return false
          }else if(row.values == "Invalid Date"){
            return false
          }else{
            //row.opportunitiesvalue = moment(row.values).format("X");
            row.opportunitiesvalue =  moment(row.values).format('YYYY-MM-DD');
            delete row.values;
          }
        }
        return row;
      })
      
      //not to submit if any field is false
      var allowToSubmitOpp = true
      submitDataOpp.map((row)=>{
        if(!row){
          allowToSubmitOpp = false
        }
      })
      opportunitiesdata = submitDataOpp;
    }

    let req = {
      listParams: list,
      contactdata: submitData,
      leadid: parseFloat(this.state.lead_id),
      opportunitiesdata: opportunitiesdata,
    };
    //console.log("req", req);return

    this.props.saveContactDetail(this.props.apolloClient.client, req);
    setTimeout(() => this.setState({ redirectToLeads: true }), 2000);
  };

  focusInValue = () => {
    this.setState({ focusValue: true })
  };

  focusOutValue = () => {
    this.setState({ focusValue: false })
  };

  handleChangeOppoField = (val, name) => {
    if(val.fieldid === "41"){//pipeline dorpdown change
      this.setState({
        stageArray: val.values.stage,
        pipeline_id_createpopup: val.values.id,
      },()=>{
        this.forceUpdate()
        this.setState({
          fieldsObjOpportunity: {
            ...this.state.fieldsObjOpportunity,
            ["stage"]: { fieldid: "47", values:val.values.stage[0]},
            ["winpercentage"]: { fieldid: "52", opportunitiesvalue:val.values.stage[0].probability},
          }
        })
      })
    }else if(val.fieldid === "47"){//stage dorpdown change
      this.setState({
        fieldsObjOpportunity: {
          ...this.state.fieldsObjOpportunity,
          ["winpercentage"]: { fieldid: "52", opportunitiesvalue:val.values.probability},
        }
      })
    }
    this.setState({
      fieldsObjOpportunity: {
        ...this.state.fieldsObjOpportunity,
        [name]: val
      }
    })


  };

  render() {    
    var dtFormat = "MM/dd/yyyy";
    var selectedDate = this.state.selectedDate;
    if (this.state.fieldsObjOpportunity["closedate"]) {
      selectedDate = this.state.fieldsObjOpportunity["closedate"].values;
    }else{
      var data = {
          fieldid:"43",
          values:selectedDate
        }
        this.setState({
          fieldsObjOpportunity: {
            ...this.state.fieldsObjOpportunity,
            ["closedate"]: data
          }
        })
    }

    if (this.state.uSetting != "") {
      dtFormat = this.state.uSetting.dateformat;
      dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
    }

    let {
      open,
      handleClose,
      handleMinimize,
      handleFull,
      fullScreen,
      minimizeScreen,
      lead_id,
    } = this.props;

    const formatCreateLabel = (value) => `Create "${value}"`;
    const isValidNewOption = (value) => value != "";
    return this.state.redirectToLeads ? (
      <Redirect to="/contact/contact-list" />
    ) : (
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="md"
        fullWidth={true}
        className={`custom_dialog ${
          minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
        } ${minimizeScreen ? "alignitemsend" : ""} ${
          fullScreen ? "fullscreen" : ""
        }`}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className="crm-custom-bg-primary">
          <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
            <h4 className="m-0 text-white">{this.state.dialogTitle}</h4>
            <div className="d-flex">
              <Tooltip
                title={minimizeScreen ? "Normal Screen" : "Minimize Screen"}
              >
                <IconButton onClick={handleMinimize}>
                  <Icon className="text-white">
                    {minimizeScreen ? "add" : "remove"}
                  </Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title={fullScreen ? "Exit Full Screen" : "Full Screen"}>
                <IconButton onClick={handleFull}>
                  <Icon className="text-white">
                    {fullScreen ? "fullscreen_exit" : "fullscreen"}
                  </Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton onClick={handleClose}>
                  <Icon className="text-white">clear</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        <ValidatorForm
          noValidate
          debounceTime={500}
          onSubmit={this.handleFormSubmit}
        >
          <div className="dialog-body m-20">
            <Grid className="mb-16" container>
              <Grid className="mr-10" container xs={6} spacing={4}>
                {/* firstname */}
                <Grid item xs={12}>
                  <TextValidator
                    required={true}
                    type="text"
                    className="w-100 input_bx"
                    label={"First Name"}
                    onChange={this.handleChange}
                    name={"firstname"}
                    value={this.state.firstname}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                </Grid>
                {/* lastname */}
                <Grid item xs={12}>
                  <TextValidator
                    type="text"
                    className="w-100 input_bx"
                    label={"last Name"}
                    onChange={this.handleChange}
                    name={"lastname"}
                    value={this.state.lastname}
                  />
                </Grid>
                {/* company */}
                <Grid item xs={12}>
                  <FormControl
                    className="w-100 input_bx "
                    style={{ zIndex: 20 }}
                  >
                    <InputLabel
                      style={{ fontSize: "15px", marginTop: "-6px" }}
                      shrink={
                        this.state.comp_value || this.state.fcus1 ? true : false
                      }
                    >
                      {" "}
                      
                      {"Company"}
                    </InputLabel>
                    <SingleSelect
                      value={this.state.comp_value}
                      SelectProps={{
                        isCreatable: true,
                        formatCreateLabel,
                        isValidNewOption,
                      }}
                      placeholder=""
                      options={this.state.comp_options}
                      onFocus={() => this.setState({ fcus1: true })}
                      onBlur={() => this.setState({ fcus1: false })}
                      onChange={this.handleCompanyChange}
                      onCreateOption={this.handleCompanyCreate}
                    />
                  </FormControl>
                </Grid>
                {/* contacttype */}
                <Grid item xs={12}>
                  <FormControl className="w-100 input_bx">
                    <SelectValidator
                      value={this.state.contacttype}
                      onChange={this.handleChange}
                      name={"contacttype"}
                      id={"contacttype"}
                      label={"Contact Type"}
                    >
                      {this.state.contactTypes &&
                        this.state.contactTypes.length &&
                        this.state.contactTypes.map((item, key) => (
                          <MenuItem
                            className="select-menu-item w-100 input_bx"
                            key={item.id}
                            value={item}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </FormControl>
                </Grid>
                {/* owner */}
                <Grid item xs={12}>
                  <FormControl className="w-100 input_bx">
                    <Autocomplete
                      disableOpenOnFocus
                      forcePopupIcon={false}
                      name={"owner"}
                      options={this.state.userSettings}
                      getOptionLabel={(option) => option.name}
                      value={this.state.selectedOwner}
                      name={"owner"}
                      onChange={(event, value) => this.handleOwnerChange(value)}
                      id={"owner"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={"Owner"}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid className="ml-10" container xs={6} spacing={4}>
                <Grid item xs={12}>
                  <FormControl className="w-100 input_bx">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={"showOportunities"}
                          checked={this.state.showOportunities}
                          onChange={this.handleCheckChange}
                          name="showOportunities"
                          value={this.state.showOportunities}
                          color="primary"
                        />
                      }
                      label={"Create Opportunity for this person"}
                    />
                  </FormControl>
                </Grid>

                {this.state.showOportunities && (
                  <>
                    <Grid item xs={12}>
                      {" "}
                      {/* oportunity */}
                      <TextValidator
                        required={true}
                        value={
                            this.state.fieldsObjOpportunity["name"]
                              ? this.state.fieldsObjOpportunity["name"].opportunitiesvalue
                              : ""
                          }
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        type="text"
                        className="w-100 input_bx"
                        label={"Opportunity Name"}
                        onChange={(event) =>
                          this.handleChangeOppoField(
                            { fieldid: "40", opportunitiesvalue: event.target.value },
                            "name"
                          )
                        }
                        name={"name"}
                      /> 
                    </Grid>
                    <Grid item xs={12}>
                     <FormControl className="w-100 input_bx">
                        <SelectValidator
                          label={"Pipeline"}
                          value={
                            this.state.fieldsObjOpportunity["pipeline"]
                              ? this.state.fieldsObjOpportunity["pipeline"].values
                              : ""
                          }
                          onChange={(event) =>
                            this.handleChangeOppoField(
                              { fieldid: "41", values: event.target.value },
                              "pipeline"
                            )
                          }
                          name={"Pipeline"}
                          id={"41"}
                          validators={
                            ["required"]
                          }
                          errorMessages={
                            ["this field is required"]
                          }
                        >
                          {this.state.pipelineListArray.length > 0 &&
                            this.state.pipelineListArray.map((item, key) => (
                              <MenuItem
                                className="select-menu-item w-100 input_bx"
                                key={item.id}
                                value={item}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl className="w-100 input_bx">
                          <KeyboardDatePicker
                            label={"CLose Date"}
                            format={dtFormat}
                            required={true}
                            placeholder={dtFormat}
                            error={
                              (selectedDate == null) ||
                              selectedDate == "Invalid Date"
                                ? true
                                : false
                            }
                            value={selectedDate}
                            onChange={(event, value) =>
                              this.handleChangeOppoField(
                                {
                                  fieldid: "43",
                                  values:
                                    event == "Invalid Date" ? "Invalid Date" : event,
                                },
                                "closedate"
                              )
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            helperText={
                                selectedDate == null
                                ? "this field is required"
                                : selectedDate == "Invalid Date"
                                ? "enter valid date."
                                : ""
                            }
                          />
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {this.state.focusValue && (
                      <Grid item xs={4}>
                        <FormControl className="w-100 input_bx">
                          <SelectValidator
                            label={"Value"}
                            value={this.state.companyCurrencyData.currencyid}
                            name={"currency"}
                            id={"currency"}
                            SelectProps={{ onClose: this.currencyDropdownClose }}
                          >
                            <MenuItem
                              className="select-menu-item item-with-link"
                              value={this.state.companyCurrencyData.currencyid}
                            >
                              {this.state.companyCurrencyData.detail.code}
                            </MenuItem>
                          </SelectValidator>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={this.state.focusValue ? 8 : 12}
                      row
                      className={this.state.focusValue ? "checkbox-margin-18" : ""}
                    >
                      <TextValidator
                        type="number"
                        id={'value'}
                        pattern="[0-9]*"
                        className="w-100 input_bx"
                        onFocus={this.focusInValue}
                        label={!this.state.focusValue ? "Value" : ""}
                        onChange={(event) =>
                          this.handleChangeOppoField(
                            { fieldid: "50", opportunitiesvalue: event.target.value },
                            'value'
                          )
                        }
                        name={'value'}
                        placeholder={"Add value"}
                        value={
                          this.state.fieldsObjOpportunity['value']
                            ? this.state.fieldsObjOpportunity['value'].opportunitiesvalue
                            : ""
                        }
                       
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {this.state.companyCurrencyData.symbol}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <DialogActions className="dialog-action">
            <Button
              onClick={() => this.props.handleClose()}
              variant="contained"
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit" color="primary">
              Convert
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
const mapStateToProps = (state) => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  leadReducer: state.leadReducer,
  contactReducer: state.contactReducer,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyRecList: (client) => {
      dispatch(actionCreators.getCompanyRecList(client));
    },
    getContactType: (client) => dispatch(actionCreators.getContactType(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    saveContactDetail: (client, request) => {
      dispatch(actionCreators.saveContactDetail(client, request));
    },
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),

    // getLeadField: (client, leadId) => dispatch(actionCreators.getLeadField(client, leadId)),
    // loadLeadStatues: (client) => dispatch(actionCreators.loadLeadStatues(client)),
    // AddLeadStatus: (c, req) => dispatch(actionCreators.AddLeadStatus(c, req)),
    // updateLeadStatusRecord: (c, req) => dispatch(actionCreators.updateLeadStatusRecord(c, req)),
    // clearNotification: () => dispatch(actionCreators.clearNotification()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConvertLeadDialog);
