import React from 'react';
import PropTypes from 'prop-types';
import EmailPreferances from "./EmailPreferances";
import MyTemplates from "./MyTemplates";
import SharedTemplates from "./SharedTemplates";
import { Tabs, Tab, Box, Typography, Card } from "@material-ui/core";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={2}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}


export default function RelatedSettingTab() {
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <Card elevation={3} className="h-100">
            <h3 className="ml-16 mt-16 mb-16">Email Settings & Templates</h3>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className="settings_tabs"
                aria-label="scrollable auto tabs"
            >
                <Tab label="Preferances" {...a11yProps(0)} />
                <Tab label="My Templates" {...a11yProps(1)} />
                <Tab label="Shared Templates" {...a11yProps(2)} />
            </Tabs>
            <div className="v_scroll position-relative">
                <TabPanel value={value} index={0} className="h-100"><EmailPreferances /></TabPanel>
                <TabPanel value={value} index={1} className="h-100"><MyTemplates /></TabPanel>
                <TabPanel value={value} index={2} className="h-100"><SharedTemplates /></TabPanel>
            </div>
        </Card>
    );
}
