import React, { Component } from "react";
import { Icon, Badge, Card, Button, IconButton, Drawer, } from "@material-ui/core";
import { Link } from "react-router-dom";

class NotificationBar extends Component {
  state = {
    panelOpen: false,
    notifications: [
      {
        id: 1,
        heading: "Message",
        icon: {
          name: "chat",
          color: "primary"
        },
        timestamp: 1570702802573,
        title: "New message from Devid",
        subtitle: "Hello, Let's chit chat...",
        path: "chat"
      },
      {
        id: 2,
        heading: "Alert",
        icon: {
          name: "notifications",
          color: "error"
        },
        timestamp: 1570702702573,
        title: "Server overloaded",
        subtitle: "Traffice reached 2M",
        path: "page-layouts/user-profile"
      },
      {
        id: 3,
        heading: "Message",
        icon: {
          name: "chat",
          color: "primary"
        },
        timestamp: 1570502502573,
        title: "New message from Goustove",
        subtitle: "Hello, Let's chit chat...",
        path: "chat"
      }
    ]
  };

  handleDrawerToggle = (e) => {
    e.preventDefault()
    this.setState({ panelOpen: !this.state.panelOpen });
  };
  deleteNotification = (index) => {
    let data = this.state.notifications.filter((val, ind) => index !== ind);
    this.setState({
      notifications: data
    })
  }
  deleteAllNotification = () => {
    this.setState({
      notifications: []
    })
  }
  render() {
    let { panelOpen } = this.state;
    return (
      <React.Fragment>
        <IconButton className="btn-white" onClick={this.handleDrawerToggle}>
          <Badge color="secondary" badgeContent={5}>
            <Icon>notifications</Icon>
          </Badge>
        </IconButton>
        <Drawer
          width={"100px"}
          variant="temporary"
          anchor={"right"}
          open={panelOpen}
          onClose={this.handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
        >
          <div className="notification">
            <div className="notification__topbar d-flex align-items-center p-16 mb-24">
              <Icon color="primary" className="text-primary">notifications</Icon>
              <h5 className="ml-8 my-0 font-weight-500">Notifications</h5>
            </div>

            {this.state.notifications.map((val, index) => {

              return (
                <div className="notification__card position-relative" key={index}>
                  <IconButton
                    size="small"
                    className="delete-button bg-light-gray mr-24"
                    onClick={() => this.deleteNotification(index)}
                  >
                    <Icon className="text-muted" fontSize="small">
                      clear
                </Icon>
                  </IconButton>
                  <Link onClick={this.handleDrawerToggle} to="/">
                    <Card className="mx-16 mb-24" elevation={3}>
                      <div className="card__topbar d-flex align-items-center justify-content-between p-8 bg-light-gray">
                        <div className="d-flex">
                          <div className="card__topbar__button">
                            <Icon
                              className={`card__topbar__icon text-${val.icon.color}`}
                              fontSize="small"
                            >
                              {val.icon.name}
                            </Icon>
                          </div>
                          <span className="ml-4 font-weight-500 text-muted">
                            {val.heading}
                          </span>
                        </div>
                        <small className="card__topbar__time text-muted">
                          3 months ago
                    </small>
                      </div>
                      <div className="px-16 pt-8 pb-16">
                        <p className="m-0">{val.title}</p>
                        <small className="text-muted">
                          {val.subtitle}
                        </small>
                      </div>
                    </Card>
                  </Link>
                </div>)
            })
            }

            <div className="text-center">
              <Button onClick={this.deleteAllNotification}>Clear Notifications</Button>
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
}
export default NotificationBar;

