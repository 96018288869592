import React, { Component } from "react";
import ProfileLeft from "./ProfileLeft";
import ProfileCenter from "./ProfileCenter";
import ProfileRight from "./ProfileRight";

class Project extends Component {
	state={
	    task_id:""
	}
  	componentWillMount() {
	    this.setState({
	      task_id:parseInt(this.props.match.params.id)
	    })
  	}
  	render() {
	    return (
	      <React.Fragment>
	        <ProfileLeft />
	        <ProfileCenter />
	        <ProfileRight/>
	      </React.Fragment>
	    );
  	}
}

export default Project;
