import React, { Component } from "react";
import { Button, Icon, TextField, InputAdornment, IconButton, withStyles } from "@material-ui/core";
import { default as MaterialGrid } from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import CreationRule from './CreationRule';
import {
    Grid,
    TableHeaderRow,
    Toolbar,
    VirtualTable,
    TableSelection,
    SearchPanel,
    Table,
    TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    SelectionState,
    IntegratedSelection,
} from '@devexpress/dx-react-grid';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);
class ActionButton extends React.Component {

    render() {
        return (
            <Link to="#"><IconButton size="small"><IconSmall>delete</IconSmall></IconButton></Link>
        )
    }
}

class Details extends Component {
    state = {
        selection: [],
        page: this.props.team,
    };

    setSelection = (val) => {
        this.setState({
            selection: val
        })
    }
    render() {
        const tableColumnExtensions = [{}];
        const columns = [
            {
                name: "username",
                title: "User name"
            },
            {
                name: "role",
                title: "Role"
            },
            {
                name: "action",
                title: "Action"
            },
        ];

        const rows = [
            {
                username: "John",
                role: <CreationRule />,
                action: <ActionButton teamId="1" />,
            },
            {
                username: "Justin",
                role: <CreationRule />,
                action: <ActionButton teamId="2" />,
            },
        ];
        const toolbarTemplate = params => {
            return (
                <div className="d-flex mt-16 mb-8 ml-16 mr-16">
                    <MaterialGrid container alignItems="center" justify="space-between">
                        <MaterialGrid>
                            <MaterialGrid container justify="space-between">
                                <MaterialGrid>
                                    <label className="text-lable">Team Name</label>
                                    <TextField
                                        className="ml-16"
                                        placeholder="Team Name"
                                        size="small"
                                        defaultValue={this.state.page}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="edit" size="small">
                                                        <IconSmall>edit</IconSmall>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </MaterialGrid>
                            </MaterialGrid>
                        </MaterialGrid>
                        <MaterialGrid>
                        </MaterialGrid>
                    </MaterialGrid>
                    {params.children}
                    {this.state.selection.length ?
                        <MaterialGrid className="flex flex-middle ml-10">
                            <Button variant="contained" color="secondary" size="small">
                                Delete
                                    </Button>
                        </MaterialGrid>
                        :
                        ''
                    }
                </div>
            );
        };
        return (
            <div className="v_scroll position-relative">
                <div className="leads_list_table">
                    <Grid rows={rows} columns={columns}>
                        <SelectionState
                            selection={this.state.selection}
                            onSelectionChange={this.setSelection}
                        />
                        <IntegratedSelection />
                        <VirtualTable />
                        <SearchState defaultValue="" />

                        <SortingState />
                        <IntegratedSorting />
                        <Table columnExtensions={tableColumnExtensions} />
                        <TableSelection showSelectAll highlightRow />

                        <TableHeaderRow showSortingControls />

                        <TableFixedColumns />
                        <Toolbar rootComponent={toolbarTemplate} />
                        <SearchPanel />
                    </Grid>
                </div>
                <Link to="/settings/user-management/"><Button variant="contained" className="m-16" size="small" color="primary" startIcon={<Icon>arrow_back</Icon>}>Back to Teams List</Button></Link>
            </div>
        );
    }
}

export default Details;
