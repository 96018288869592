import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, DialogActions, Avatar, Grid, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import IntegrationSelect from '../Select';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import Server from "../../Common/Server"

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
class UserProfileDialog extends Component {
    state = {
        uid: '',
        country: "",
        imagePreview:'',
        select_country_data: [
            { label: "Afghanistan", value: "Afghanistan" },
            { label: "Aland Islands", value: "Islands" },
            { label: "Albania", value: "Albania" },
            { label: "Algeria", value: "Algeria" },
            { label: "American Samoa", value: "Samoa" },
            { label: "Andorra", value: "Andorra" },
            { label: "Angola", value: "Angola" },
            { label: "Anguilla", value: "Anguilla" },
            { label: "Antarctica", value: "Antarctica" },
            { label: "Antigua and Barbuda", value: "Barbuda" },
            { label: "Argentina", value: "Argentina" },
            { label: "Armenia", value: "Armenia" },
            { label: "Aruba", value: "Aruba" }
        ]
    };
    handleChange = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleCheckBoxChange = (event, name) => {
        event.persist();
        this.setState({ [name]: event.target.checked });
    };
    handleFormSubmit = () => {

        let sendData = {
            userid: this.state.uid,
            name: this.state.name,
            title: this.state.title,
            phoneno: this.state.phoneno,
            address: this.state.address,
            country: this.state.country,
            state: this.state.state,
            pincode: this.state.pincode,
            city: this.state.city
        }
        this.props.updateUser(sendData);

    };

    componentWillMount() {
        let sessionData = JSON.parse(localStorage.getItem('user_data_session'))
        this.props.getUserById(({ userid: sessionData.id, type: "profile" }))
        this.props.countryList({ userid: sessionData.id });

        
        let val = { userid: sessionData.id }
        this.props.getUserSetting(this.props.apolloClient.client, val);
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let user_datas = props.settingReducer.userSettingArray.data;
            let profile_image = user_datas.profileimage ? user_datas.profileimage : null;
            this.setState({ imagePreview: profile_image })
        }

        if(props.notification && props.notification.status === true){
            this.props.handleClose();
            this.props.clearNotification();
        }
        let userData = props.settingReducer.userListById;
        if (userData.data !== undefined) {
            this.setState({
                uid: userData.data.id,
                userName: userData.data.name,
                name: userData.data.name,
                email: userData.data.email,
                phoneno: userData.data.phoneno,
                address: userData.data.address,
                pincode: userData.data.pincode,
                country: userData.data.country,
                state: userData.data.state,
                title: capitalize(userData.data.title),
                city: userData.data.city,
                // countryValue: userData.data.country,
                profileImage: Server.API + "/uploads/" + userData.data.profileimage
            })
        }

        if (props.settingReducer.countryList) {
            let select_country_data = props.settingReducer.countryList.data.map(val => {
                return { label: val.name, value: val.name }
            })
            this.setState({
                select_country_data
            })
        }
    }
    render() {
        let { name, email, title, phoneno, pincode, state, address, country, city } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth={true} disableBackdropClick disableEscapeKeyDown>
                <ValidatorForm ref="form" onSubmit={this.handleFormSubmit} noValidate>
                    <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
                        <h4 className="m-0 text-white">{this.state.userName}'s profile</h4>
                        <div className="d-flex">
                            <Tooltip title="Close">
                                <IconButton onClick={handleClose}>
                                    <Icon className="text-white">clear</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="dialog-body pt-16">
                        <Grid className="mb-16" container spacing={1} alignItems="center">
                            <Grid item>
                                {/* <Avatar alt="Cindy Baker" src={this.state.profileImage} /> */}
                                <Avatar alt="Cindy Baker" src={this.state.imagePreview} />

                                {/* <img
                                    className="mx-8 text-middle circular-image-small cursor-pointer"
                                    src={this.state.imagePreview}
                                    alt="user"
                                /> */}
                            </Grid>
                            <Grid item>
                                <h6 className="mb-0">{this.state.userName}</h6>
                                <p className="m-0 text-muted">{this.state.title}</p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    required={true}
                                    type="text"
                                    id="name"
                                    className="w-100 input_bx"
                                    label="Name"
                                    onChange={this.handleChange}
                                    name="name"
                                    value={name ? name : ''}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    required={true}
                                    label="Phone No"
                                    className="w-100 mb-16"
                                    placeholder="PhoneNo"
                                    onChange={this.handleChange}
                                    type="text"
                                    name="phoneno"
                                    value={phoneno ? phoneno : ''}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    autoComplete='off'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} >
                                <TextValidator
                                    required={true}
                                    className="w-100 mb-16"
                                    placeholder="Email"
                                    onChange={this.handleChange}
                                    type="email"
                                    name="email"
                                    label="Email"
                                    value={email ? email: ''}
                                    validators={["required", 'isEmail']}
                                    errorMessages={["this field is required", "email is not valid"]}
                                    autoComplete='off'
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    required={true}
                                    className="w-100 mb-16"
                                    placeholder="Title"
                                    onChange={this.handleChange}
                                    type="text"
                                    label="Title"
                                    name="title"
                                    value={title ? title : ''}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    autoComplete='off'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    required={true}
                                    className="w-100 mb-16"
                                    placeholder="address"
                                    label="address"
                                    onChange={this.handleChange}
                                    type="text"
                                    name="address"
                                    value={address ? address : ''}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    autoComplete='off'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    required={true}
                                    className="w-100 mb-16"
                                    label="city"
                                    placeholder="city"
                                    onChange={this.handleChange}
                                    type="text"
                                    name="city"
                                    value={city ? city : ''}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    autoComplete='off'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    required={true}
                                    className="w-100"
                                    label="state"
                                    placeholder="state"
                                    onChange={this.handleChange}
                                    type="text"
                                    name="state"
                                    value={state ? state : ''}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    autoComplete='off'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    required={true}
                                    className="w-100"
                                    label="pincode"
                                    placeholder="pincode"
                                    onChange={this.handleChange}
                                    type="text"
                                    name="pincode"
                                    value={pincode ? pincode : ''}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    autoComplete='off'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <FormControl className="w-100 input_bx mb-16">
                                    <InputLabel>Country</InputLabel>
                                    <Select
                                        name="country"
                                        value={country}
                                        onChange={this.handleChange}
                                        MenuProps={{ className: 'choose_field_menu' }}
                                    >
                                        {this.state.select_country_data && this.state.select_country_data.map((opt) => {
                                            return <MenuItem className="select-menu-item" value={opt.value}>{opt.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </div>
                    <DialogActions className="dialog-action">
                        <Button variant="contained" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="primary" type="submit">Save</Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    notification: state.notification,
    apolloClient: state.apolloClient,
});
const mapDispatchToProps = dispatch => {
    return {
        //settingAddUser:(data)=>dispatch(actionCreators.settingAddUser(data)),
        getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
        getUserById: (id) => dispatch(actionCreators.getUserById(id)),
        updateUser: (data) => dispatch(actionCreators.updateUser(data)),
        countryList: (data) => dispatch(actionCreators.countryList(data)),
        clearNotification: () => dispatch(actionCreators.clearNotification()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileDialog);

