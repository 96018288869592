import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, 
  FormControl, Select, InputLabel, Menu, MenuItem, List, 
  ListItem, ListItemText, DialogActions, 
  Chip, TextField ,CircularProgress} from "@material-ui/core";
import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { createDynamicFields } from './CreateFieldsDynamic'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

class CreateNewOpportunity extends Component {
  state = {
    countryListArray:[],
    companyCurrencyData:[],
    focusValue:false,
    fieldsList: [],
    fieldsObj: {},
    tags: [],
    systemTagsList: [],
    usersListArray:[],
    selectedDate: new Date(),
    userSettings:"",
    isDataLoading:true,
    pipelineListArray: [],
    stageArray: [],
    comp_options:[],
    comp_value:'',
    comp_isLoading:false,
    primarycontact_value:"",
    primarycontact_options:[],
    pipeline_id_createpopup:"",
  };

  componentWillMount() {
    let val = { typeId: 4 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getPipelines(this.props.apolloClient.client);
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    this.props.getCompanyRecList(this.props.apolloClient.client);
    this.props.getContactRecList(this.props.apolloClient.client);

    if (this.props.name && (this.props.contactsid || this.props.companysid || this.props.projectid)) {
      this.setState({
        datafromsidebar: this.props.dataFromSidebar,
        comp_value: this.props.companysid,
        primarycontact_value: this.props.contactsid,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["name"]: { fieldid: "40", opportunitiesvalue: this.props.name }
        }
      })
    }
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.companyRecList && props.settingReducer.companyRecList.data && props.settingReducer.companyRecList.data.data){
      let companyOptions = [];
      props.settingReducer.companyRecList.data.data.map((compOpt)=>{
        companyOptions.push({ label: compOpt.name, value: compOpt.company_id});
      })
      this.setState({ comp_options: companyOptions }, () => {
        if (this.props.companysid) {
          let compSelectkey = this.findWithAttr(companyOptions, 'value', this.props.companysid);
          let comp_values = { srno: "1", id: companyOptions[compSelectkey].value, value: companyOptions[compSelectkey].label };

          this.setState({
            comp_value: this.props.companysid, 
            fieldsObj: {
              ...this.state.fieldsObj,
              ["company"]: { fieldid: "44", values: [comp_values] }
            },
          });
        }
      });
    }
    if (props.settingReducer.contactRecList && props.settingReducer.contactRecList.is_loaded){
      props.settingReducer.contactRecList.is_loaded = false
      let contactOptions = [];
      props.settingReducer.contactRecList.data.data.map((conOpt)=>{
        contactOptions.push({ label: conOpt.name, value: conOpt.contact_id});
      })
      this.setState({ primarycontact_options: contactOptions},()=>{
        if (this.props.contactsid) {
          let oppSelectkey = this.findWithAttr(this.state.primarycontact_options, 'value', this.props.contactsid);
          let contact_values = { srno: "1", id: this.state.primarycontact_options[oppSelectkey].value, value: this.state.primarycontact_options[oppSelectkey].label };
          this.handleChange({ fieldid: "42", values: [contact_values] }, 'primarycontact');
          // this.handleChange({ fieldid: "42", values: { id: primarycontact_options[oppSelectkey].value, name: primarycontact_options[oppSelectkey].label } }, 'company');
          this.setState({ primarycontact_value: this.props.contactsid });
        }
      });
    }
    //Pipelines list array
    if (props.settingReducer.pipelineListArray.data) {
        let pipesList = props.settingReducer.pipelineListArray.data
        var selectedPipelineId = localStorage.getItem('selected_pipeline_id');
        var pipeline;
        if(selectedPipelineId){
          pipeline = this.findWithAttr(pipesList, 'id', selectedPipelineId);
        }else{
          pipeline = this.findWithAttr(pipesList, 'id', "1");
        }

        var pipelineArray = pipesList[pipeline];

        this.setState({ 
            pipelineListArray: pipesList,
            stageArray: pipelineArray.stage,
            pipeline_id_createpopup: selectedPipelineId,
            fieldsObj: {
              ...this.state.fieldsObj,
              ["pipeline"]: { fieldid: "41", values:pipelineArray},
              ["stage"]: { fieldid: "47", values:pipelineArray.stage[0]},
              ["winpercentage"]: { fieldid: "52", opportunitiesvalue:pipelineArray.stage[0].probability},
            }
        },()=>{
            this.forceUpdate()
        })

    }

    //storing user setting data in state
    if(props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
        const settingData = props.settingReducer.userSettingArray.data;
        this.setState({
            userSettings:settingData,
        })
    }

    if (props.settingReducer.opportunitiesCustomFieldList.data && props.settingReducer.opportunitiesCustomFieldList.is_loaded) {
      props.settingReducer.opportunitiesCustomFieldList.is_loaded = false;
      const customFields = props.settingReducer.opportunitiesCustomFieldList.data;
      this.setState({ fieldsList: customFields,isDataLoading:false})
      var statuskey = this.findWithAttr(customFields, 'id', "45");
      var prioritykey = this.findWithAttr(customFields, 'id', "51");
      var defaultKeyStatus = this.findWithAttr(customFields[statuskey].options, 'id', 4);
      
      var selectedPipelineId = localStorage.getItem('selected_pipeline_id');
      var elements = customFields.filter(function(element) {
        if(element.piplineid[0] == 0 || (parseInt(selectedPipelineId) === element.piplineid[0])){
          return element
        }
      });

      var date = new Date(); // Now
      date.setDate(date.getDate() + 30); // Set now + 30 days as the new date


      //setting selected date in date picker
      elements.map((v, i) => {
          if(v.fieldtype.id === "8"){
            var data = {
              fieldid:v.id,
              values:this.state.selectedDate
            }
            this.setState({
              fieldsObj: {
                ...this.state.fieldsObj,
                [v.fieldkey]: data
              }
            })
          }
      })

      //setting default object for status
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["status"]: {fieldid: "45",values:customFields[statuskey].options[defaultKeyStatus]},
          ["priority"]: {fieldid: "51",values:customFields[prioritykey].options[0]},
          ["closedate"]: {fieldid: "43",values:date},
          ["created"]: {fieldid: "49",values:this.state.selectedDate},
        }
      },()=>{
        this.forceUpdate()
      })
      
    }
    if (props.settingReducer.countryListGql.data) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["owner"]: { fieldid: "46", values:usersListArray[companyOwnerKey]}
        }
      })
    }
    if(props.notification && props.notification.status === true){
        // props.handleClose();
        props.clearNotification();
    }

    if (props.settingReducer.systemTagsList.data) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList : tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null) {
        var cData = props.settingReducer.companyCurrencyData.data;
        this.setState({companyCurrencyData:cData[0]});
    }
  }

  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    }, () => this.forceUpdate())
  }

  handleNormalFields = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCountryChange = (event, values) => {
    this.setState({country: values});
  }

  focusInValue = () => {
    this.setState({ focusValue: true })
  };

  focusOutValue = () => {
    this.setState({ focusValue: false })
  };
  
  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name) => {
    var winVal = ""
    if(val.fieldid === "41"){//pipeline dorpdown change
      this.setState({
        stageArray: val.values.stage,
        pipeline_id_createpopup: val.values.id,
      },()=>{
        this.forceUpdate()
        this.setState({
          fieldsObj: {
            ...this.state.fieldsObj,
            ["stage"]: { fieldid: "47", values:val.values.stage[0]},
            ["winpercentage"]: { fieldid: "52", opportunitiesvalue:val.values.stage[0].probability},
          }
        })
      })
    }else if(val.fieldid === "47"){//stage dorpdown change
      winVal = { fieldid: "52", opportunitiesvalue:val.values.probability}
    }
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if(key != -1 && this.state.fieldsList[key].fieldtype.id != "4"  && this.state.fieldsList[key].fieldtype.id != "8"){
      if(this[name]){
        this[name].validate(val.value);
      }
    }
        console.log("winVal", winVal);
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val,
        ['winpercentage']:winVal?winVal:(val.fieldid == "52")?val:this.state.fieldsObj['winpercentage']
      }
    })


  };

  handleFormSubmit = () => {
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
    submitData = Object.values(submitData).map((row)=>{
      if(row.fieldid == "50"){//Value fiels
        row.values = [{id:this.state.companyCurrencyData.currencyid.toString(),srno:"1",value:row.opportunitiesvalue}]
        delete row['opportunitiesvalue'];
      }
      
      if(row.fieldid === "48" || row.fieldid === "45" || row.fieldid === "51" || row.fieldid === "54" || row.fieldid === "41" || row.fieldid === "47"){//54-visibility,48-source,45-status,51-Priority
        row.values = [{srno:"1", id:row.values.id,value:row.values.name}]
      }
      var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
      if(this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false){
        row.values = [{srno:"1",id:row.values.id,value:row.values.optionvalue}] 
      }
      if(this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false){
        if(row.values.length >0){
          var ar = [];
          row.values.map((val,i)=>{
            ar.push({srno:(i+1).toString(),id:val.id, value:val.optionvalue})
          })
        }
        row.values = ar
      }
      
      //validating date fiels
      if(this.state.fieldsList[key].fieldtype.id == "8"){//date field
        if(this.state.fieldsList[key].recordcreationdefaultvalue == 2 && row.values == null){
          return false
        }else if(this.state.fieldsList[key].recordcreationdefaultvalue != 2 && row.values == null){
          row.opportunitiesvalue = "";
          delete row.values;
        }else if(row.values == "Invalid Date"){
          return false
        }else{
          // row.opportunitiesvalue = moment(row.values).format("X");

          // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          // row.opportunitiesvalue = moment.utc(logdate).format('X');
          var dt = moment(row.values).format('YYYY-MM-DD')
          row.opportunitiesvalue = dt;

          delete row.values;
        }
      }

      if(row.fieldid == "56"){
        if(row.values.length > 0){
          var objArr = [];
          row.values.map((val,k)=>{
            var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
            var tagId = "0";
            if(foundKey !== -1){
              tagId = this.state.systemTagsList[foundKey].id
            }
            console.log("val", val);
            objArr.push({"srno":(k+1).toString(),value:val.toLowerCase(),id:tagId})
          })  
          row.values = objArr  
        }
      }

      if(row.fieldid == "46"){//owner value set
        if(row.values){
          row.values = [{"srno":"1",id:row.values.id,value:row.values.email,name:row.values.name}];
        }else{
          row.values = [];
        }
        delete row.opportunitiesvalue;
      }
      return row;
    })

    
    //not to submit if any field is false
    var allowToSubmit = true
    submitData.map((row)=>{
      if(!row){
        allowToSubmit = false
      }
    })
    console.log("submitData", submitData);
    console.log("allowToSubmit", allowToSubmit);
    // return
    if(allowToSubmit){
      let req = {
        opportunitiesdata:submitData,
        sidebarParams: this.state.datafromsidebar,
      }
      this.props.handleClose();
      this.props.saveOpportunitiesDetail(this.props.apolloClient.client,req);
    }
  };
  handleBlur = (event,ref) => {
    console.log("this[ref]", this[ref]);
    if(this[ref].props.id == "tagInputId1"){
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      },10)
    }
    this[ref].validate(event.target.value);
}
  handleCompanyChange = (val , newValue) => {
    let id =  (newValue.__isNew__) ? "0" : newValue.value;
    let comp_values = {srno: "1", id: id, value: newValue.label };
    this.handleChange({ fieldid: "44", values: [comp_values] }, 'company');
    this.setState({ comp_value: id });
  };
  handlePrimaryContactChange = (val , newValue) => {
    let id =  (newValue.__isNew__) ? "0" : newValue.value;
    console.log("id", id);
    let contact_values = {srno: "1", id: id, value: newValue.label };
    this.handleChange({ fieldid: "42", values: [contact_values] }, 'primarycontact');
    this.setState({ primarycontact_value: id });
  };

  handleDateChange = date => {
      this.setState({ selectedDate: date });
  };

  arrDifference = (arr1, arr2) =>{
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
       if(arr2.indexOf(arr1[i]) === -1)
       arr.push(arr1[i]);
    }
    // for array2
    for(i in arr2) {
       if(arr1.indexOf(arr2[i]) === -1)
       arr.push(arr2[i]);
    }
    return arr.sort((x,y) => x-y);
  }

  findWithAttr = (array, attr, value) => {
      for(var i = 0; i < array.length; i += 1) {
          if(array[i][attr] === value) {
              return i;
          }
      }
      return -1;
  }
  render() {
    console.log(this.state.fieldsObj);
    
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create New Opportunity</h4>
          <div className="d-flex">
            <Tooltip title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <ValidatorForm noValidate debounceTime={500}   onSubmit={this.handleFormSubmit}>
          <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`}>
            <Grid container spacing={2}>
              {this.state.isDataLoading && <CircularProgress size={25} className="loaderPopupCenter"/>}
              {!this.state.isDataLoading && createDynamicFields(this)}
            </Grid>
          </div>
           
          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button disabled={this.state.isDataLoading} variant="contained" type="submit" color="primary">Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  contactReducer: state.contactReducer,
  notification: state.notification,
});

const mapDispatchToProps = dispatch => {
  return {
    getCompanyRecList: (client) => { dispatch(actionCreators.getCompanyRecList(client)) },
    getContactRecList: (client) => { dispatch(actionCreators.getContactRecList(client)) },
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    saveOpportunitiesDetail: (client, request) => { dispatch(actionCreators.saveOpportunitiesDetail(client, request)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewOpportunity);
