import React, { Component } from "react";
import {Paper,Chip,Checkbox,Select,Drawer, RadioGroup,Radio,Tooltip,Dialog,DialogTitle,Divider,DialogContent,FormControl,InputLabel,
Input,FormHelperText,DialogActions,CircularProgress,Grid, Button, IconButton, Icon, Card, TextField, LinearProgress, Switch, FormControlLabel, MenuItem, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { Link } from "react-router-dom";
import Board from 'react-trello';
import DropdownDefault from "../Layout/DropdownDefault";
import "../../styles/_opportunities.scss"
import { withRouter } from "react-router-dom";
import CreateNewOpportunityDialog from "./CreateNewOpportunityDialog";
import OpportunityProfileFrame from "./OpportunityProfileFrame";
import { connect } from "react-redux";  
import * as actionCreators from '../../store/actions/index';
import AddNewPipeline from '../Settings/AddNewPipeline'
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { FilterComponent } from './FilterComponent'
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";
import TaskProfileFrame from "../Tasks/TaskProfileFrame";
import FontAwesome from "react-fontawesome";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
let eventBus = undefined
const setEventBus = (handle) => {
  eventBus = handle
}

const LanesColorArray = ['primary','secondary','yellow','green','red','blue','black','orange','purple','gray']
let clickFlag = false;
class OpportunitiesCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            systemTagsList: [],
            systemCityListArray: [],
            systemStateListArray: [],
            systemZipcodeListArray: [],
            countryListArray: [],
            usersListArray:[],
            pageSize: 1000,
            column_name:"name",
            sorttype:"asc",
            selectedSortByValue:"name",
            selectedSortByName:"Name",
            selectedSortByFieldId:40,
            fieldId:40,
            filterdata:[],
            searchText:'',

            isOpenedPipelines: false,
            isOpenedFilters: true,
            isOpenedCustomFilters: false,
            isOpenedRange: false,
            isOpenedPipline: false,
            PiplinecardId: null,
            switchView: true,

            shouldOpenCreateOpportunityDialog: false,
            FullCreateOpportunityDialog: false,
            minimizeOpportunityDialog: false,
            pipelineListArray: [],

            /*START For Add Pipeline Dialog*/
            shouldOpenNewPipelineDialog: false,
            FullNewPipelineDialog: false,
            minimizePipelineDialog: false,
            isLoadingPipelineList: true,
            selectedPipelineId: 1,
            /*END For Add Pipeline Dialog*/

            lanesData:{ 
                "lanes": [
                ]
            },
            isDataLoading:true,
            userSettings:"",
            companyCurrencyData:[],
            totalWeightedSum:0,
            potentialTotalValue:0,
            openOpportunityCount:0,

            /* STRAT won/lost/abandoned */
            isOpenWonPopup:false,
            isOpenLostPopup:false,
            isOpenAbandonedPopup:false,

            fullScreenLostDialog: true,
            minimizeLostDialog: false,

            fullScreenWonDialog: true,
            minimizeWonDialog: false,

            fullScreenAbandonedDialog: true,
            minimizeAbandonedDialog: false,
            /* END won/lost/abandoned */

            isOpenedFiltersDefault:false,
            fieldDisplay: [],
            filterRangeArray:[],
            dropdownhide:[],
            filterSaveDialoge:false,
            fitertype:"1",
            filtername:"",
            recordtypeid:4,
            filternameError:false,
            opportunityFilterListArray:[],
            displaySelectedFilterName:"All Opportunity",
            filterUpdateId:"",
            allowStateUpdateFilter:true,
            filterdata:[],
            activityquery:"any",
            tagsquery:"any",
            dateformat:'MM/DD/YYYY',
            timeZone:'America/New_York',
            AllOpportunityFilterKey:"",
            filterObject:{},
            updateOpportunityId:"",
            isDropSubmit:false,
            clickedCardId:"",
            masterActivityTypesList: [],
            loassReasonList: [],
            lossdropdownVal:"0",
            changeDate:true,
            opportunityContactListArray: [],
            opportunityCompanyListArray: [],
            contactTypeList: [],
            contactTypeObj: [],
            contactTypeObjCmp: [],
            optionalNoteWon:"",
            optionalNoteLost:"",
            optionalNoteAbandoned:"",

            //Task popup 
            addTaskOpportunityId:"",
            taskDueDate:new Date(),
            taskName:"",
            shouldOpenCreateTaskDialog: false,
            FullCreateTaskDialog: false,
            minimizeTaskDialog: false,
            showTskpopup: false,
            isLoadingRelatedTask: false,
            taskListArray:[],
            drawerOpenTask: false,
            task_id: "",
            //Task popup
        };
    }

    componentDidMount() {
        window.addEventListener('mouseup', this.mouseKeyUp, false);
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let userprefparams = { userid: userData.id }
        let val1 = {typeId: 4}
        this.props.getPipelines(this.props.apolloClient.client);
        this.props.getActivityTypes(this.props.apolloClient.client);
        this.props.customFieldList(this.props.apolloClient.client, val1);
        this.props.getFilterListOpportunity(this.props.apolloClient.client,4);
        this.props.getUserSetting(this.props.apolloClient.client, userprefparams);
        this.props.getCityList(this.props.apolloClient.client);
        this.props.getStateList(this.props.apolloClient.client);
        this.props.getZipcodeList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getCompanyCurrency(this.props.apolloClient.client);
        this.props.getTagsList(this.props.apolloClient.client);

        var pipelineId = this.state.selectedPipelineId;
        if(localStorage.getItem('selected_pipeline_id')){
          console.log("A");
            pipelineId = localStorage.getItem('selected_pipeline_id')
        }else{
          console.log("B");
            localStorage.setItem('selected_pipeline_id',this.state.selectedPipelineId)
            pipelineId = localStorage.getItem('selected_pipeline_id')
        }
        console.log("pipelineId", pipelineId);

        let val = { 
            filterdata: this.state.filterdata,
            list: { 
                limit: 1, 
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype, 
                type: "sort", 
                fieldId: this.state.fieldId,
                columnname:this.state.column_name,
                islimited:false
            },
            piplineid:parseFloat(pipelineId),
            filterid:1
        }
        this.getListing(val)
        this.props.getLossReasonList(this.props.apolloClient.client);
        this.props.getContactType(this.props.apolloClient.client);
    }

    mouseKeyUp = () =>{
        //this.closeTaskPopup()
        this.setState({
            isOpenedPipline:false,
        });
    }
    callOpportunityList = () =>{
      let val = { 
            filterdata: this.state.filterdata,
            list: { 
                limit: 1, 
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype, 
                type: "list", 
                fieldId: this.state.fieldId,
                columnname:this.state.column_name,
                islimited:false
            },
            piplineid:parseFloat(this.state.selectedPipelineId),
            filterid:1
        }
        this.getListing(val)
        this.props.getPipelines(this.props.apolloClient.client);
    }


    getListing = (val)=>{
        this.props.getOpportunitiesFilterList(this.props.apolloClient.client, val);
    }

    componentWillReceiveProps(props){
        if (props.taskReducer.taskFieldData.data && props.taskReducer.taskFieldData.is_loaded) {
            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
        }
        if (props.settingReducer.relatedDataTask.data) {
            props.settingReducer.relatedDataTask.is_loaded = false
            this.setState({
                taskListArray:props.settingReducer.relatedDataTask.data.data,
                isLoadingRelatedTask: false,
            },()=>this.forceUpdate());
        }
        if (props.contactReducer.contactTypeList.data && props.contactReducer.contactTypeList.is_loaded) {
            props.contactReducer.contactTypeList.is_loaded = false
            this.setState({
                contactTypeList:props.contactReducer.contactTypeList.data,
            },()=>this.forceUpdate());
        }
        if (props.opportunityReducer.opportunityContactList.data && props.opportunityReducer.opportunityContactList.is_loaded) {
            props.opportunityReducer.opportunityContactList.is_loaded = false
            var cData = props.opportunityReducer.opportunityContactList.data
            this.setState({
                opportunityContactListArray:cData,
            },()=>this.forceUpdate());

            var arra = []
            var promise = new Promise((resolve, reject) => {
              cData.map((v,k)=>{
                var obj = {}
                obj.checked = true
                obj.contacttype = "2"
                obj.id = v.contact.id
                obj.type = "contact"
                arra.push(obj)
              })
              resolve(1)
            })
            promise.then(result => {
                this.setState({
                    contactTypeObj:arra,
                },()=>this.forceUpdate());
            });

        }
        if (props.opportunityReducer.opportunityCompanyList.data && props.opportunityReducer.opportunityCompanyList.is_loaded) {
            props.opportunityReducer.opportunityCompanyList.is_loaded = false
            var cmpData = props.opportunityReducer.opportunityCompanyList.data
            var arra = []
            var promise = new Promise((resolve, reject) => {
              cmpData.map((v,k)=>{
                var obj = {}
                obj.checked = true
                obj.contacttype = "2"
                obj.id = v.company.id
                obj.type = "company"
                arra.push(obj)
              })
              resolve(1)
            })
            promise.then(result => {
                this.setState({
                    contactTypeObjCmp:arra,
                },()=>this.forceUpdate());
            });
            this.setState({
                opportunityCompanyListArray:cmpData,
            },()=>this.forceUpdate());
        }
        if (props.settingReducer.loassReasonList.data != null) {
            var dataList = props.settingReducer.loassReasonList.data;
            this.setState({ loassReasonList: dataList});
        }

        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count  = dataList.filter(function(element) {
                if(element.is_disable == true){
                  return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }
        if (props.opportunityReducer.opportunityFieldData.data && props.opportunityReducer.opportunityFieldData.is_loaded) {
          clickFlag = false
          this.setState({
            fieldsObj: {},
            comp_value:'',
            primarycontact_value:"",
          },() => {
              let fieldsObj = this.state.fieldsObj;
              this.setState({opportunityFieldData:props.opportunityReducer.opportunityFieldData.data})
              let country = "";
              const opportunityDataForEdit = props.opportunityReducer.opportunityFieldData.data;
              var promise = new Promise((resolve, reject) => {
                    let tempStages = []
                  opportunityDataForEdit.map((v, i) => {
                    if (v.id == '44' ||  v.id == '42') {
                      if (v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0) {
                        var optId = v.opportunitiesdetail[0].values[0].id;
                        if(v.id == '42'){//primary contact
                          this.setState({ primarycontact_value: optId});
                        }else if(v.id == '44'){//company
                          this.setState({ comp_value: optId});
                        }
                      }
                    }else if (v.fieldtype.id == '1' && v.id == '40') {//for first name
                      if(v.opportunitiesdetail.length > 0){
                        var data = {
                          fieldid: v.id,
                          opportunitiesvalue: v.opportunitiesdetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }
                    else if (v.fieldtype.id == '1' && v.id == '46') {//for owner field        
                      if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length && v.opportunitiesdetail[0].values[0].id){
                        var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id',v.opportunitiesdetail[0].values[0].id);
                        if(companyOwnerKey !== -1){
                          var ownerData = {
                            fieldid: v.id,
                            values: this.state.usersListArray[companyOwnerKey]
                          }
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: ownerData,
                          }
                        }
                      }
                    }else if (v.fieldtype.id == '1' && v.fieldkey=='tags') {//for tag input
                      var tagArray = []
                      if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                        v.opportunitiesdetail[0].values.map((v,i)=>{
                          tagArray.push(v.value);
                        })
                      }
                      var data = {
                        fieldid: v.id,
                        values: tagArray
                      }
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: data,
                      }
                    }else if (v.fieldtype.id == '1' && v.id == '50') {//For value with currency Field
                      if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                        var data = {
                          fieldid: v.id,
                          opportunitiesvalue: v.opportunitiesdetail[0].values[0].value
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }else if ((v.fieldtype.id == '1' && v.id != '56' && v.id != '46' && v.id != '44' && v.id != '42') || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
                      if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail.length > 0){
                        var data = {
                          fieldid: v.id,
                          opportunitiesvalue: v.opportunitiesdetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }

                   if (v.fieldtype.id == '2') {//TextArea
                      if(v.opportunitiesdetail.length > 0){
                        var data = {
                          fieldid: v.id,
                          opportunitiesvalue: v.opportunitiesdetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }

                    if(v.fieldtype.id == "3"){//checkboxes
                      if(v.opportunitiesdetail.length > 0){
                        var data = {
                          fieldid: v.id,
                          opportunitiesvalue: v.opportunitiesdetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }
                    //if(this.state.fieldsList && this.state.fieldsList.length >0){
                      if (v.fieldtype.id === '4') {//dropdown
                        //11-source/3-status/5-visibility
                        if(v.fieldid === "54" || v.fieldid === "48" || v.fieldid === "45" || v.fieldid === "51"){//54-visibility,48-source,45-status,51-Priority
                          if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                            var optId = this.findWithAttr(v.options, 'id',v.opportunitiesdetail[0].values[0].id);
                            fieldsObj = {
                              ...fieldsObj,
                              [v.fieldkey]: {fieldid: v.id,values:v.options[optId]},
                            }
                          }
                        }else if(v.id === "41"){//pipeline
                          if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                            var optId = this.findWithAttr(v.options, 'id',parseInt(v.opportunitiesdetail[0].values[0].id));
                            fieldsObj = {
                              ...fieldsObj,
                              [v.fieldkey]: {fieldid: v.id,values:v.options[optId]},
                            }
                            this.setState({
                              stageArray:v.options[optId].stage
                            },() => {
                                this.forceUpdate()
                                tempStages = v.options[optId].stage
                            })
                          }
                        }else if(v.id === "47"){//stage
                          if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                                fieldsObj = {
                                  ...fieldsObj,
                                  [v.fieldkey]: {fieldid: v.id,values:v.opportunitiesdetail[0].values[0]},
                                }
                          }
                        }else{
                          if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                            var optId = this.findWithAttr(v.options, 'id',parseInt(v.opportunitiesdetail[0].values[0].id));
                            fieldsObj = {
                              ...fieldsObj,
                              [v.fieldkey]: {fieldid: v.id,values:v.options[optId]},
                            }
                          }
                        }
                      }
                      if (v.fieldtype.id === '5') {//multi dropdown
                        if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: {fieldid: v.id,values:v.opportunitiesdetail[0].values},
                          }
                        }
                      }
                      if (v.fieldtype.id === '8') {//datepicker
                        if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].fieldvalue != ""){
                          //var dt = moment.unix(v.opportunitiesdetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                           var dt = new Date(moment(v.opportunitiesdetail[0].fieldvalue).format('YYYY-MM-DD'))
                           dt = new Date(dt)
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: {fieldid: v.id,values:dt},
                          }
                        }else if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].fieldvalue == ""){
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: {fieldid: v.id,values:null},
                          }
                        }
                      }
                    //}
                  })

                  this.setState({
                    fieldsObj,
                    country,
                    isUpdate:false,
                    isAnyValueChange:false,
                    isDataLoading:false,
                  },() => {
                    resolve(true)     
                  })
              });

              promise.then( result => {
                props.opportunityReducer.opportunityFieldData.is_loaded = false;
                this.setState({
                 isAnyValueChange:false
                },() => {
                    setTimeout(() => {
                      if(this.state.isDropSubmit){
                        this.handleFormSubmit()
                         this.setState({ 
                              isDataLoading:false,
                          },()=>{
                              this.forceUpdate()
                          })
                      }
                    },0);
                })
              });
          })
        }

        if(props.settingReducer.opportunitiesCustomFieldList.data) {
          const customFields = props.settingReducer.opportunitiesCustomFieldList.data;
          this.setState({ fieldsList: customFields })
        }
        if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
          const tagsData = props.settingReducer.systemTagsList.data;
          this.setState({ systemTagsList: tagsData })
        }
        if (props.settingReducer.usersListArray.data) {
          const usersListArray = props.settingReducer.usersListArray.data;
          var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
          this.setState({
                //taskOwner:usersListArray[companyOwnerKey]
          })
          this.setState({ usersListArray: usersListArray })
        }
        if (props.settingReducer.companyCurrencyData.data != null) {
            var cData = props.settingReducer.companyCurrencyData.data;
            this.setState({companyCurrencyData:cData[0]});
        }
        //storing user setting data in state
        if(props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
            const settingData = props.settingReducer.userSettingArray.data;
            this.setState({
                userSettings:settingData,
            })
            let format = settingData.dateformat;
            let timezone = settingData.timezone;

            this.setState({ dateformat: format, timeZone: timezone })
        }

        if(props.opportunityReducer.opportunityFilterListArray.data && this.state.allowStateUpdateFilter || props.opportunityReducer.opportunityFilterListArray.is_loaded) {
            const opportunityFilterListArray = props.opportunityReducer.opportunityFilterListArray.data;
            props.opportunityReducer.opportunityFilterListArray.is_loaded = false
            this.setState({ opportunityFilterListArray: opportunityFilterListArray,allowStateUpdateFilter:false})
            
            var AllfilterIdKey = this.findWithAttr(opportunityFilterListArray, 'id', "11");
            if(AllfilterIdKey != -1){
               this.setState({ AllOpportunityFilterKey:AllfilterIdKey}) 
            }
                

            //Aplying default filter selected  STRAT
            
            let defaultFilter;
            //checkdefault filter  in localStorage
            var localStorageFilterId = localStorage.getItem('selected_opportunity_filter_id');
            
            if(localStorageFilterId){
                var localId = this.findWithAttr(opportunityFilterListArray, 'id', localStorageFilterId);
                defaultFilter = opportunityFilterListArray[localId];
            }else{
                var defaultKeyStatus = this.findWithAttr(opportunityFilterListArray, 'id', "11");
                defaultFilter = opportunityFilterListArray[defaultKeyStatus];
                localStorage.setItem('selected_opportunity_filter_id',defaultFilter.id);
            }

            //my open oppoertunity
            if(defaultFilter.id == "12"){
                let udata = JSON.parse(localStorage.getItem('user_data_session'))
                defaultFilter.filteroption = [
                    {
                        fieldid: "46", name: "owner", type: "value", value:[udata.email]
                    },
                    {
                        fieldid: "45", name: "status", type: "value",value: ["Open"]
                    }
                ]
            }

            //Opportunities Im Following
            if(defaultFilter.id == "14"){
                defaultFilter.filteroption = [
                    {
                        fieldid: "0",
                        name: "followed",
                        type: "followed",
                        value: [true],
                        chktype: "follow",
                    }
                ]
            }

            //won opportunity
            if(defaultFilter.id == "15"){
                let udata = JSON.parse(localStorage.getItem('user_data_session'))
                defaultFilter.filteroption = [
                    {
                        fieldid: "45", name: "status", type: "value",value: ["Won"]
                    }
                ]
            }
            //Open opportunity
            if(defaultFilter.id == "13"){
                let udata = JSON.parse(localStorage.getItem('user_data_session'))
                defaultFilter.filteroption = [
                    {
                        fieldid: "45", name: "status", type: "value",value: ["Open"]
                    }
                ]
            }

            //updateFilter name to display and menudisplay array set
            this.setState({
                displaySelectedFilterName:this.state.newSavedFilterName?this.state.newSavedFilterName:defaultFilter.filtername,
                fieldDisplay:[],
                dropdownhide:[],
            })

            if(defaultFilter.filteroption.length > 0){
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    defaultFilter.filteroption.map((val,k)=>{
                        if (val.name == "activitytype") {
                            this.setState({ activityquery: val.query })
                            if(val.value.length > 0){
                                var arr = []
                                val.value.map(v=>{
                                    var k = this.findWithAttr(this.state.masterActivityTypesList, 'id',v)
                                    if(k != -1){
                                        arr.push(this.state.masterActivityTypesList[k])
                                    }
                                })
                                val.value = arr;

                            }
                        }

                        filterObject = {
                            ...filterObject,
                            [val.name]:val
                        }
                        
                        if(val.name == "tags"){
                            this.setState({tagsquery:val.query})
                        }
                         
                        if(val.type === "range"){
                            filterRangeArray[val.name] = ["",""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({filterRangeArray})
                            displayField.push(val.name)
                        }
                        if(val.type === "date"){
                            filterRangeArray[val.name] = ["",""];
                            filterRangeArray[val.name][0] = val.value[0]==""?null:val.value[0];
                            filterRangeArray[val.name][1] = val.value[1]==""?null:val.value[1];
                            this.setState({filterRangeArray})
                            if(val.value[0] != "" && val.value[1] != ""){
                                displayField.push(val.name)
                            }else if(val.value[0] != "" || val.value[1] != ""){
                                displayField.push(val.name)
                            }
                        }
                        if(val.name == "zipcode" || val.name == "city" || val.name == "state" || val.name == "country"){//hideshow ENABLE state/city/country/state
                            dropdownhideShow.push(val.name)
                        }else{
                            dropdownhideShow.push(val.name)
                        }
                    })
                    this.setState({
                        filterObject,
                        fieldDisplay:displayField,
                        dropdownhide:dropdownhideShow,
                        newSavedFilterName:"",
                    },()=>this.forceUpdate())
                    resolve(true)     
                });

                promise.then( result => {
                    if(localStorage.getItem('selected_pipeline_id')){
                        this.setState({
                            selectedPipelineId:localStorage.getItem('selected_pipeline_id'),
                        }, () => this.forceUpdate())
                    }
                    this.handleFilterSubmit();
                });
            }
            //Aplying default filter selected  END
        }
        
        if (props.opportunityReducer.opportunityList.data && props.opportunityReducer.opportunityList.is_loaded) {
            let oppoList = props.opportunityReducer.opportunityList.data;            
            //Pipelines list array
            if (props.settingReducer.pipelineListArray.data) {
                var pipeData = props.settingReducer.pipelineListArray.data
                //Apply pipeline id to localstorage
                var selectedPipelineId = localStorage.getItem('selected_pipeline_id');
                if(!selectedPipelineId){
                    localStorage.setItem('selected_pipeline_id',"1");
                }else{
                    selectedPipelineId = localStorage.getItem('selected_pipeline_id');
                }
                selectedPipelineId = localStorage.getItem('selected_pipeline_id');
                var promise = new Promise((resolve, reject) => {
                    var pipeLanes = this.state.lanesData.lanes
                    var blankArray = []
                    eventBus.publish({type: 'UPDATE_LANES', lanes: []})
                    var pipeKey = this.findWithAttr(pipeData, 'id', selectedPipelineId);
                    if(pipeKey != -1){
                        var colorKey = 0
                        pipeData[pipeKey].stage.map((row,key)=>{
                            if(colorKey == 10){
                                colorKey=0
                            }
                            var existingKey = this.findWithAttr(pipeLanes, 'id', row.id.toString());
                            const count = oppoList.data.filter((obj) => obj.stage[0].id == row.id).length
                            //const LanesColorArray = ['primary','secondary','yellow','green','red','blue','black','orange','purple','gray']
                            var obj =  {
                                "id": row.id.toString(),
                                "title": row.name,
                                "label": count,
                                "current": row.probability,
                                "target": LanesColorArray[colorKey],
                                "style": {
                                    "width": 280
                                },
                                "cards": []
                            }
                            colorKey++;

                            
                            if(existingKey == -1){
                                pipeLanes.push(obj)
                                eventBus.publish({type: 'UPDATE_LANES', lanes: pipeLanes})
                                this.setState({ 
                                    ...this.state.lanesData,
                                    ["lanes"]:pipeLanes,
                                },()=>{
                                    this.forceUpdate()
                                    resolve(1)
                                })
                            }else{
                                eventBus.publish({type: 'UPDATE_LANES', lanes: []})
                                blankArray.push(obj)
                                eventBus.publish({type: 'UPDATE_LANES', lanes: blankArray})
                                this.setState({ 
                                    ...this.state.lanesData,
                                    ["lanes"]:blankArray,
                                },()=>{
                                    this.forceUpdate()
                                    resolve(1)
                                })
                            }
                            
                        })
                        
                    }

                })

                promise.then( result => {
                    var potentialTotalValue = 0;
                    var totalWeightedSum = 0;
                    var openOpportunityCount = 0;

                    props.opportunityReducer.opportunityList.is_loaded = false;
                    oppoList.data.map((row,k)=>{
                        if(selectedPipelineId == row.pipeline[0].id){
                            var closeDate = ""
                            var valueField = ""
                            var companyOwnerNames = ""
                            var currecySymbol = this.state.companyCurrencyData.symbol
                            if(row.value && row.value.length > 0){
                                valueField = "Value : "+currecySymbol+ row.value[0].value
                            }
                             
                            if(row.closedate){
                                //var dt = moment.unix(row.closedate).format(this.state.userSettings.dateformat).toString()
                                var dt = moment(row.closedate).format(this.state.userSettings.dateformat)
                                closeDate = 'Closed date : '+dt;
                            }
                            if(row.company && row.company.length > 0){
                                companyOwnerNames = row.company[0].value + " : "
                            }
                            if(row.owner && row.owner.length > 0){
                                companyOwnerNames += row.owner[0].name
                            }
                            if(row.status && row.status.length > 0){
                                if(row.status[0].id == 4){
                                    openOpportunityCount = openOpportunityCount+1;
                                    if(row.value && row.value.length > 0){
                                        var valData = parseInt(row.value[0].value);
                                        //var probability = parseInt(row.stage[0].probability);
                                        var probability = 0;
                                        if(row.winpercentage && row.winpercentage != ""){
                                            probability = parseInt(row.winpercentage);
                                        }
                                        potentialTotalValue += valData;
                                        var weighted = (valData * probability)/100;
                                        totalWeightedSum +=weighted

                                    }
                                }
                            }
                            
                            let star_icon = row.isfavourite ? "star" : "star_border";
                            var chipClass = ""
                            if(row.status && row.status.length > 0){
                              if(row.status[0].id == '1'){
                                  chipClass="chip_won_card";
                              }
                              if(row.status[0].id == '2'){
                                  chipClass="chip_abandoned_card";
                              }
                              if(row.status[0].id == '3'){
                                  chipClass="chip_lost_card";
                              }
                          }
                            var eleId = "tsk_popup_"+row.opportunities_id
                            var cardObj = {
                                "laneId": row.stage[0].id.toString(),
                                "id": row.opportunities_id.toString(),
                                "title": <h4><Link to={"/opportunities/opportunities/" + row.opportunities_id} className="opportunityNameHoverr text_ellipsis">{row.name}</Link> <Icon onClick={(lead, status) => this.handleFavorite(row.opportunities_id,row.isfavourite)} className="filled_star" size="small">{star_icon}</Icon></h4>,
                                "label": <div id={eleId} onClick={(e,id) => this.openTaskPopup(e,row.opportunities_id,row.name)} className="circle-bar br-green"></div>,
                                "description": <div className="card-description">
                                    <div className="card-value">{valueField}</div>
                                    <p>{companyOwnerNames}</p>
                                    <p className="font-size-12 text-hint">{closeDate?closeDate:""}</p>
                                    {chipClass != "" && <Chip  label={row.status[0].value} className={chipClass}/>}
                                </div>
                            }
                            eventBus.publish({type: 'ADD_CARD', laneId: row.stage[0].id.toString(), card:cardObj})
                        }
                    })

                    this.setState({ 
                        totalWeightedSum : totalWeightedSum,
                        openOpportunityCount: openOpportunityCount,
                        potentialTotalValue : potentialTotalValue,
                    },()=>{
                        this.forceUpdate()
                    })
                });

                this.setState({ 
                    pipelineListArray: pipeData,
                    isLoadingPipelineList: false,
                    selectedPipelineId: selectedPipelineId,
                    isDataLoading:false,
                },()=>{
                    this.forceUpdate()
                    props.settingReducer.pipelineListArray.is_loaded = false
                })
            }
            
        }
        
    }

    addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;
    var keyName = key;
    var keyVal = data;
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      },
      isAnyValueChange:true
    },() => {
      // setTimeout(() => {
      //   //dont upadte lead if first name is empty
      //   if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].opportunitiesvalue == ""){
      //     return false
      //   }
      //   //dont allow to update if value length 1 and value is blank
      //   if(keyVal.values.length == 1 && keyVal.values[0].value == ""){
      //     return false
      //   }
      //   if (this.state.fieldsObj[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 2500);
    })
  }

  handleNormalFields = (event) => {
    var keyName = event.target.name;
    var keyVal = event.target.value;
    this.setState({
      [event.target.name]: event.target.value,
      isAnyValueChange:true
    },() => {
      //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].opportunitiesvalue == ""){
      //   return false
      // }
      // setTimeout(() => {
      //   if (this.state[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 1500);
    })
  };

  handleCountryChange = (event, values) => {
    this.setState({
      country: values,
      isAnyValueChange:true
    },() => {
      //dont upadte lead if first name is empty
      if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].opportunitiesvalue == ""){
        return false
      }
      setTimeout(() => {
        if (this.state.country == values) {
          this.handleFormSubmit()
        }
      }, 500);
    })
  }

  focusInValue = () => {
    this.setState({
      focusValue: true,
    }, () => this.forceUpdate())
  };


  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleCompanyChange = (val , newValue) => {
    let id =  (newValue.__isNew__) ? "0" : newValue.value;
    let comp_values = {srno: "1", id: id, value: newValue.label };
    this.handleChange({ fieldid: "44", values: [comp_values] }, 'company','allowSubmitChange');
    this.setState({ comp_value: id });
  };
  handlePrimaryContactChange = (val , newValue) => {
    let id =  (newValue.__isNew__) ? "0" : newValue.value;
    let contact_values = {srno: "1", id: id, value: newValue.label };
    this.handleChange({ fieldid: "42", values: [contact_values] }, 'primarycontact','allowSubmitChange');
    this.setState({ primarycontact_value: id });
  };

  handleChangeVals = (event, source) => {
      if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
      } else {
          this.setState({ [event.target.name]: event.target.value });
      }
  };

  handleChange = (val, name,isSubmit) => {
    if(val.fieldid === "41"){//pipeline dorpdown change
      this.setState({
        stageArray: val.values.stage,
        pipeline_id_createpopup: val.values.id,
      },()=>{
        this.forceUpdate()
        this.setState({
          fieldsObj: {
            ...this.state.fieldsObj,
            ["stage"]: { fieldid: "47", values:val.values.stage[0]},
            ["winpercentage"]: { fieldid: "52", opportunitiesvalue:val.values.stage[0].probability},
          }
        })
      })
    }else if(val.fieldid === "47"){//stage dorpdown change
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["winpercentage"]: { fieldid: "52", opportunitiesvalue:val.values.probability},
        }
      })
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      },
      isAnyValueChange:true
    },() => {
      
      if(!isSubmit){
        return false
      }
      // if(name === "name" && val.opportunitiesvalue ===""){
      //   this.setState({ fnameError: true })
      //   return false
      // }else{
      //   this.setState({ fnameError: false })
      // }
      // //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'].opportunitiesvalue == ""){
      //   return false
      // }
      setTimeout(() => {
        if (this.state.fieldsObj[name] == val) {
          this.handleFormSubmit()
        }
      }, 500);
    })
  };

  focusOutSubmitForm = () => {
    setTimeout(() => {
      if (this.state.isAnyValueChange) {
        this.handleFormSubmit()
      }
    }, 0);
  }


    handleFollowedCheckbox = (val, name) => {
        this.setState({
          filterObject: {
            ...this.state.filterObject,
            [name]:val
          }
        },() => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })

    }
    handleChangeFilter = (val, name) => {
        this.setState({
          filterObject: {
            ...this.state.filterObject,
            [name]:val
          }
        },() => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    };

    rangeClick = (key,id)=>{
        var rangeData = this.state.filterRangeArray[key];
        if(this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == ""){
            rangeData = []
        }

        var value = { fieldid: id,name:key,type:"range",value:rangeData};
        this.setState({
          filterObject: {
            ...this.state.filterObject,
            [key]:value
          }
        },() => {
          this.forceUpdate()
          setTimeout(() => {
            if (this.state.filterObject[key] == value) {
              this.handleFilterSubmit()
            }
          }, 1500);
        })
    }
    rangeFilter = (rval,rname)=>{
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({ filterRangeArray })
    }

    rangeClickDate = (key,id)=>{
        var rangeData = this.state.filterRangeArray[key];
        if(this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == ""){
            rangeData = []
        }

        var value = { fieldid: id,name:key,type:"date",value:rangeData};
        this.setState({
          filterObject: {
            ...this.state.filterObject,
            [key]:value
          }
        },() => {
          this.forceUpdate()
          setTimeout(() => {
            if (this.state.filterObject[key] == value) {
              this.handleFilterSubmit()
            }
          }, 1500);
        })
    }

    rangeFilterDate = (rval,rname)=>{
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({
          filterRangeArray
        },() => {
          this.forceUpdate()
          setTimeout(() => {
            this.rangeClickDate(rname,rval.fieldid)
          },100);
        })
    }
    handleToggleFieldDisplay = (name)=>{
        var exists = this.state.fieldDisplay.includes(name);
        var joined = [];
        let filterRangeArray = this.state.filterRangeArray;

        if(exists){
            joined = this.state.fieldDisplay.filter((item)=> item != name)
            if(filterRangeArray[name]){
                //delete filterRangeArray[name];
            }
        }else{
            joined = this.state.fieldDisplay.concat(name);
            if(!filterRangeArray[name]){
                filterRangeArray[name] = ["",""];
            }
        }
        
        this.setState({ fieldDisplay: joined, filterRangeArray })
    }

    dropdownMenu = (fkey)=>{
        var exists = this.state.dropdownhide.includes(fkey);
        var joined = [];
        if(exists){
            joined = this.state.dropdownhide.filter((item)=> item != fkey)
        }else{
            joined = this.state.dropdownhide.concat(fkey);
        }
        this.setState({ dropdownhide: joined})
    }

    handleFilterSubmit = ()=>{
        var allowToFilter = true
        let copyObj = JSON.parse(JSON.stringify(this.state.filterObject));
        var filterValuesArray = Object.keys(copyObj);
        var finalFilterData = filterValuesArray.map((k,i)=>{
            let v = copyObj[k];
            
            if(v.value.length > 0){
                var valuesArray = [];
                v.value.map((val,k)=>{
                    if(v.type === "date"){
                        if(val == "Invalid Date"){
                            allowToFilter = false
                        }else if(val == ""){
                            valuesArray.push(val)
                        }else if(val == null){
                            valuesArray.push("")
                        }else if(val != null){
                           if(v.fieldid != "0"){
                                 val = moment(val).format('YYYY-MM-DD');
                            }else{
                                //val = moment(val).format("X");
                                val = moment(val).format('MM/DD/YYYY');
                            }
                            valuesArray.push(val)
                        }
                    }else if(v.type == "log"){
                        valuesArray.push(val.id);
                    }else if(v.fieldid == '46' && val.email){
                        valuesArray.push(val.email)
                    }else if(v.fieldid != '46' &&  val.name){
                        valuesArray.push(val.name)
                    }else if(val.optionvalue){
                        valuesArray.push(val.optionvalue)
                    }else if(v.fieldid === "56" || v.type ==="range" ){//tag array and value
                        valuesArray.push(val);
                    }else{
                       valuesArray.push(val); 
                    }
                })
                
                
                let checkBoxKey =-1
                if(this.state.fieldsList != undefined){
                    checkBoxKey = this.findWithAttr(this.state.fieldsList, 'id', v.fieldid);
                }


                
                v.value = valuesArray;
                if(v.type=="followed"){//follow/not follow filter only value is true
                    if(v.value[0] === true){
                        return v
                    }
                }else if(checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3" && v.value[0] == "1"){
                    return v
                }else if(checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id != "3"){
                    return v
                }else if(checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3"){
                }else{
                    return v
                }
            }
        })
        
        //filter null and undefined object 
        finalFilterData = finalFilterData.filter(function(element) {
           return element !== undefined || (element !== undefined && element.value.length != 0);
        });
        console.log("allowToFilter", allowToFilter);
        console.log("finalFilterData", finalFilterData);
        if(allowToFilter){
            //saveFilter Data for save in future use.
            this.setState({
                filterdata:finalFilterData,
                isDataLoading:true,
            })

            let val = {filterdata:finalFilterData,
                list: { 
                    limit: 1, 
                    pageno: 1,
                    searchtext: this.state.searchText,
                    sorttype: this.state.sorttype, 
                    type: "list", 
                    fieldId: this.state.fieldId,
                    columnname:this.state.column_name,
                    islimited:false
                },
                piplineid:parseFloat(this.state.selectedPipelineId),
                filterid:1
            }
            this.getListing(val)
            this.props.settingReducer.pipelineListArray.is_loaded = true
        }
    }

    handleSavefilter = () =>{
        if(this.state.filtername == ""){
            this.setState({filternameError:true})
            return false
        }
        if(this.state.filterdata && this.state.filterdata.length > 0){
            let req = {
                filterdata: this.state.filterdata,
                fitertype: this.state.fitertype,
                filtername: this.state.filtername,
                recordtypeid: this.state.recordtypeid,
            }
            
            this.props.saveFilterDetailOpportunity(this.props.apolloClient.client, req);
            this.handleSavefilterDialogeClose()
            this.setState({
                displaySelectedFilterName:this.state.filtername,
                newSavedFilterName:this.state.filtername,
            })
        }else{
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
        }
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter:true,
            })
        },2000);
    }
    updateOpportunityFilter = (data) =>{
        this.setState({
            filterSaveDialoge:true,
            filterUpdateId:data.id,
            filtername:data.filtername,
            fitertype:data.fitertype.toString(),
            oldFilterOption:data.filteroption,
        })
    }
    handleUpdatefilter = () =>{
        if(this.state.filtername == ""){
            this.setState({filternameError:true})
            return false
        }
        let req = {
            filterdata: this.state.oldFilterOption,
            fitertype: this.state.fitertype,
            filtername: this.state.filtername,
            filterid: parseInt(this.state.filterUpdateId),
        }
        //return
        this.props.updateFilterDetailOpportunity(this.props.apolloClient.client, req);
        let updateDataInState = this.findWithAttr(this.state.opportunityFilterListArray, 'id', this.state.filterUpdateId);
        if(updateDataInState !== -1){
            this.state.opportunityFilterListArray[updateDataInState].filtername = this.state.filtername;
            this.state.opportunityFilterListArray[updateDataInState].fitertype = this.state.fitertype;
        }
        this.handleSavefilterDialogeClose()
    }
    updateFilterOptions = () =>{
        let key = this.findWithAttr(this.props.opportunityReducer.opportunityFilterListArray.data, 'id', localStorage.getItem('selected_opportunity_filter_id'));
        var data = ""
        if(key !== -1){
            data = this.props.opportunityReducer.opportunityFilterListArray.data[key];
            if(this.state.filterdata && this.state.filterdata.length > 0){
                let req = {
                    filterdata: this.state.filterdata,
                    fitertype: data.fitertype.toString(),
                    filtername: data.filtername,
                    filterid: parseInt(data.id),
                }
                this.props.updateFilterDetailOpportunity(this.props.apolloClient.client, req);
                this.handleSavefilterDialogeClose()
            }else{
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
            }
            setTimeout(() => {
                this.setState({
                    allowStateUpdateFilter:true,
                })
            },1500);
        }
    }
    confirmDeleteFilter = () =>{
        this.props.deleteFilterOpportunity(this.props.apolloClient.client, parseInt(this.state.filterDeleteId));
        let removeFilterObjKey = this.findWithAttr(this.props.opportunityReducer.opportunityFilterListArray.data, 'id', this.state.filterDeleteId);
        if(removeFilterObjKey !== -1){
            if(!this.props.opportunityReducer.opportunityFilterListArray.data[removeFilterObjKey].isdefault == true){
                this.props.opportunityReducer.opportunityFilterListArray.data.splice(removeFilterObjKey,1);
            }
        }
        this.filterRemoveDialoge()
    }
    filterRemoveDialoge = () =>{
        this.setState({isDeleteFilter:false,filterDeleteId:"",allowStateUpdateFilter:true})
    }
    handleSavefilterDialoge = () =>{
        this.setState({filterSaveDialoge:true})
    }
    handleSavefilterDialogeClose = () =>{
        this.setState({
            filterSaveDialoge:false,
            filternameError:false,
            fitertype:"1",
            filterUpdateId:"",
            oldFilterOption:"",
            filtername:"",
        })
    }
    
    updateFilterDefaultOpportunity = (data) =>{
        this.props.updateFilterDefaultOpportunity(this.props.apolloClient.client, parseInt(data.id));
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter:true,
            })
        },2000);
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
          if (array[i][attr] === value) {
            return i;
          }
        }
        return -1;
    } 

    handleFilterChange = (filterdata) =>{

        //my open oppoertunity
        if(filterdata.id == "12"){
            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            filterdata.filteroption = [
                {
                    fieldid: "46", name: "owner", type: "value", value:[udata.email]
                },
                {
                    fieldid: "45", name: "status", type: "value",value: ["Open"]
                }
            ]
        }

        //Opportunities Im Following
        if(filterdata.id == "14"){
            filterdata.filteroption = [
                {
                    fieldid: "0",
                    name: "followed",
                    type: "followed",
                    value: [true],
                    chktype: "follow",
                }
            ]
        }

        //won opportunity
        if(filterdata.id == "15"){
            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            filterdata.filteroption = [
                {
                    fieldid: "45", name: "status", type: "value",value: ["Won"]
                }
            ]
        }
        //Open opportunity
        if(filterdata.id == "13"){
            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            filterdata.filteroption = [
                {
                    fieldid: "45", name: "status", type: "value",value: ["Open"]
                }
            ]
        }
        this.setState({
            filterObject:{},
            filterRangeArray:[],
            fieldDisplay:[],
            dropdownhide:[],
        },()=>{
            this.forceUpdate()
            this.setState({displaySelectedFilterName:filterdata.filtername,currentPage:0})
            if(filterdata.filteroption.length > 0){
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    filterdata.filteroption.map((val,k)=>{
                        if (val.name == "activitytype") {
                            this.setState({ activityquery: val.query })
                            if(val.value.length > 0){
                                var arr = []
                                val.value.map(v=>{
                                    var k = this.findWithAttr(this.state.masterActivityTypesList, 'id',v)
                                    if(k != -1){
                                        arr.push(this.state.masterActivityTypesList[k])
                                    }
                                })
                                val.value = arr;

                            }
                        }  

                        filterObject = {
                            ...filterObject,
                            [val.name]:val
                        }
                        if(val.name == "tags"){
                            this.setState({tagsquery:val.query})
                        }

                        if(val.type === "range"){
                            filterRangeArray[val.name] = ["",""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({filterRangeArray})
                            displayField.push(val.name)
                        }

                        if(val.type == "date"){
                            filterRangeArray[val.name] = ["",""];
                            filterRangeArray[val.name][0] = val.value[0]==""?null:val.value[0];
                            filterRangeArray[val.name][1] = val.value[1]==""?null:val.value[1];
                            this.setState({filterRangeArray})
                            if(val.value[0] != "" && val.value[1] != ""){
                                displayField.push(val.name)
                            }else if(val.value[0] != "" || val.value[1] != ""){
                                displayField.push(val.name)
                            }
                        }

                    })
                    this.setState({
                        filterObject,
                        fieldDisplay:displayField,
                        dropdownhide:dropdownhideShow,
                    },()=>this.forceUpdate())
                    resolve(true)     
                });

                promise.then( result => {
                    this.handleFilterSubmit();
                    localStorage.setItem('selected_opportunity_filter_id',filterdata.id)
                });
            }else{
                let val = {filterdata:[],
                    list: { 
                        limit: 1, 
                        pageno: 1,
                        searchtext: this.state.searchText,
                        sorttype: this.state.sorttype, 
                        type: "list", 
                        fieldId: this.state.fieldId,
                        columnname:this.state.column_name,
                        islimited:false
                    },
                    piplineid:parseFloat(this.state.selectedPipelineId),
                    filterid:1
                }
                localStorage.setItem('selected_opportunity_filter_id',filterdata.id)
                this.handleFilterSubmit();
            }
        })
        
    }

    handlechangeActivityQuery = (event) =>{        
        this.setState({ 
            activityquery: event.target.value
        },() => {
          setTimeout(() => {
            if(this.state.filterObject['activitytype'] && this.state.filterObject['activitytype'].value.length > 0){
                this.state.filterObject['activitytype'].query = event.target.value;
                this.handleFilterSubmit();
            }
          }, 1500);
        })
    }
    handlechangeTagsQuery = (event) =>{        
        this.setState({ 
            tagsquery: event.target.value
        },() => {
          setTimeout(() => {
            if(this.state.filterObject['tags'] && this.state.filterObject['tags'].value.length > 0){
                this.state.filterObject['tags'].query = event.target.value;
                this.handleFilterSubmit();
            }
          }, 1500);
        })
    }
    handleClearFilterData = () =>{        
        this.setState({
            fieldDisplay: [],
            filterRangeArray:[],
            dropdownhide:[],
            filterObject:{},
            filterdata:[],
            activityquery:"any",
            tagsquery:"any", 
        },() => {
          setTimeout(() => {
            // if(this.state.filterObject['tags'] && this.state.filterObject['tags'].value.length > 0){
            //     this.state.filterObject['tags'].query = event.target.value;
            //     this.handleFilterSubmit();
            // }
          }, 1500);
        })
    }

    handlePipelineADDDialogClose = () => {
        this.setState({ shouldOpenNewPipelineDialog: false, FullNewPipelineDialog: false, minimizePipelineDialog: false ,editPiplineData:'',});
    };
    handlePipelineADDDialogMinimize = () => {
        this.setState(oldStateminimizePipelineDialog => ({ minimizePipelineDialog: !oldStateminimizePipelineDialog.minimizePipelineDialog }));
        this.setState({FullNewPipelineDialog: false})
    };
    handlePipelineADDDialogFull = () => {
        this.setState(oldStateFullNewPipelineDialog => ({ FullNewPipelineDialog: !oldStateFullNewPipelineDialog.FullNewPipelineDialog }));
        this.setState({minimizePipelineDialog: false})

    }

    togglePipelines = () => {
        this.setState(oldStatePipelines => ({ isOpenedPipelines: !oldStatePipelines.isOpenedPipelines }));
    }

    toggleFilters = () => {
        this.setState(oldStateFilters => ({ isOpenedFilters: !oldStateFilters.isOpenedFilters }));
    }
    toggleFiltersDefault = () => {
        this.setState(oldStateLeadList => ({ isOpenedFiltersDefault: !oldStateLeadList.isOpenedFiltersDefault }));
    }

    toggleCustomFilters = () => {
        this.setState(oldStateCustomFilters => ({ isOpenedCustomFilters: !oldStateCustomFilters.isOpenedCustomFilters }));
    }

    toggleRange = (e) => {
        e.preventDefault();
        this.setState(oldStateRange => ({ isOpenedRange: !oldStateRange.isOpenedRange }));
    }

    handleDragStart = (cardId, laneId) => {
        this.setState({ isOpenedPipline: true ,PiplinecardId: cardId});
    }

    handleDragEnd = (cardId, laneId ,targetLaneId, position, cardDetails) => {
      
        var selected_pipeline_id = localStorage.getItem('selected_pipeline_id');
        var pKey = this.findWithAttr(this.state.pipelineListArray, 'id', selected_pipeline_id.toString());
        
        var sKey = this.findWithAttr(this.state.pipelineListArray[pKey].stage, 'id', targetLaneId);


        this.props.getOpportunitiesField(this.props.apolloClient.client,parseInt(cardId));
        this.setState({
            isOpenedPipline:false,
            opportunities_id:parseInt(cardId),
            isDropSubmit:true,
            updateStageObj:this.state.pipelineListArray[pKey].stage[sKey]
        });
    }
    closeDrawerRight = (e) => {
        // e.preventDefault()
        this.setState({ drawerOpen: false });
    };
    handleDrawerRight = (opportunities_id, metadata, laneId) => {
        if(!clickFlag){
          if (this.state.opportunities_id && (this.state.opportunities_id === opportunities_id)) { } else {
              this.setState({
                  opportunities_id: opportunities_id,
                  isDataLoading: true,
              }, () => {
                  this.forceUpdate()
              })
          }

          setTimeout(() => {
              this.setState({
                  drawerOpen: true,
                  isDataLoading: false,
              }, () => {
                  this.forceUpdate()
              })
          }, 2000);
        }

    };

    handleFavorite = (opportunity,status) => {
        clickFlag = true
        let setFavoriteParams = [];
        setFavoriteParams['favorite'] = { status: !status, opportunitiesid: opportunity };
        setFavoriteParams['listval'] = {
            filterdata: this.state.filterdata,
            list: {
                limit: 1,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name,
                islimited:false
            },
            piplineid: parseFloat(this.state.selectedPipelineId),
            filterid: 1
        };


        this.props.addFavouriteOpportunities(this.props.apolloClient.client, setFavoriteParams);
    }
    handleFormSubmit = (dragSubmit) => {
        var allowToSubmit = true
        let logData = [];
        let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
        submitData = Object.values(submitData).map((row)=>{
          if(row.fieldid == "50"){
            row.values = [{id:this.state.companyCurrencyData.currencyid.toString(),srno:"1",value:row.opportunitiesvalue}]
            delete row['opportunitiesvalue'];
            if(row.values[0].value == ""){
              row.values = []
            }
          }
          
           
          if(row.fieldid === "54" || row.fieldid === "48" || row.fieldid === "45" || row.fieldid === "51" || row.fieldid === "41"){//54-visibility,48-source,45-status,51-Priority
            row.values = [{srno:"1", id:row.values.id,value:row.values.name}]
          }
          if(this.state.isDropSubmit){
            if(row.fieldid === "47"){//stage 
              if(row.values.id == this.state.updateStageObj.id){
                allowToSubmit = false
              }
              if(row.values.name){
                row.values = [{srno:"1", id:row.values.id,value:row.values.name}]
              }else{
                row.values = [{srno:"1", id:this.state.updateStageObj.id,value:this.state.updateStageObj.name}]
              }
              this.setState({ isDropSubmit:false});                                                        
            }
          }
          var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
          if(this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false){
            row.values = [{srno:"1",id:row.values.id,value:row.values.optionvalue}]
          }
          if(this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false){
            var ar = [];
            if(row.values.length >0){
              row.values.map((val,i)=>{
                var optName;
                if(val.optionvalue){
                  optName = val.optionvalue
                }else{
                  optName = val.value
                }
                ar.push({srno:(i+1).toString(),id:val.id, value:optName})
              })
            }
            row.values = ar
          }

          //validating date fiels
          if(this.state.fieldsList[key].fieldtype.id == "8"){//date field
            if(row.values == null){
              row.opportunitiesvalue = "";
              delete row.values;
            }else if(row.values == "Invalid Date"){
              return false
            }else{
              //row.opportunitiesvalue = moment(row.values).format("X");
              var dt = moment(row.values).format('YYYY-MM-DD')
                row.opportunitiesvalue = dt;
              delete row.values;
            }
          }

          if(row.fieldid == "56"){
            if(row.values.length > 0){
              var objArr = [];
              row.values.map((val,k)=>{
                var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
                var tagId = "0";
                if(foundKey !== -1){
                  tagId = this.state.systemTagsList[foundKey].id
                }
                objArr.push({"srno":(k+1).toString(),value:val.toLowerCase(),id:tagId,isdeleted:"0"})
              })

              //add tags from props value which is removed   with id deleted id "1"
              var tagsKeyFromProps = this.findWithAttr(this.props.opportunityReducer.opportunityFieldData.data, 'id', "56");
              if(this.props.opportunityReducer.opportunityFieldData.data[tagsKeyFromProps].opportunitiesdetail.length>0){
                var oldTagsValues = this.props.opportunityReducer.opportunityFieldData.data[tagsKeyFromProps].opportunitiesdetail[0].values;
                if(oldTagsValues.length > 0){
                  oldTagsValues.map((v,k)=>{
                    var checkKey = this.findWithAttr(objArr, 'value', v.value);
                    if(checkKey === -1){
                      v.isdeleted = "1";
                      v.srno = (objArr.length + 1).toString();
                      objArr.push(v);
                    }
                  })
                }
              }

              row.values = objArr
              if(this.props.opportunityReducer.opportunityFieldData.data[tagsKeyFromProps].opportunitiesdetail.length>0){
                this.props.opportunityReducer.opportunityFieldData.data[tagsKeyFromProps].opportunitiesdetail[0].values = objArr;  
              }
            }
          }

          if(row.fieldid == "8"){//address field
            row.opportunitiesvalue = {
              street:row.opportunitiesvalue,
              city:this.state.city,
              state:this.state.state,
              zipcode:this.state.zipcode,
              country:(this.state.country && this.state.country.name)?this.state.country.name:""
            }
          }
          if(row.fieldid == "46"){//owner value set  
           if(row.values){
              row.values = [{"srno":"1",id:row.values.id,value:row.values.email,name:row.values.name}];
            }else{
              row.values = [];
            }
            delete row.opportunitiesvalue;
          }
          return row;
        })

        //adding  opportunitiesdetailid for update object from this.state.opportunityFieldData
        submitData = submitData.map((row,i)=>{
          var key = this.findWithAttr(this.state.opportunityFieldData, 'id', row.fieldid);
          if(key !== -1){
            if(this.state.opportunityFieldData[key].opportunitiesdetail.length > 0){
              row.opportunitiesdetailid = this.state.opportunityFieldData[key].opportunitiesdetail[0].id;
            }else{
              row.opportunitiesdetailid = ""
            }
          }


          return row
        })

        //filter null and undefined object 
        submitData = submitData.filter(function(element) {
           return element !== undefined;
        });
        
        //not to submit if any field is false
        
        submitData.map((row)=>{
          if(!row){
            allowToSubmit = false
          }
        })
        console.log("submitData", submitData);
        console.log("allowToSubmit", allowToSubmit);
        //return
        
        if(this.state.opportunities_id && allowToSubmit){
          // update lead field data after update.... (For log)
          this.setState({ isUpdate: true });
          // -- ---------------

          let req = {
            listParams:{ 
                filterdata: this.state.filterdata,
                list: { 
                    limit: 1, 
                    pageno: 1,
                    searchtext: this.state.searchText,
                    sorttype: this.state.sorttype, 
                    type: "list", 
                    fieldId: this.state.fieldId,
                    columnname:this.state.column_name,
                    islimited:false
                },
                piplineid:parseFloat(this.state.selectedPipelineId),
                filterid:1
            },
            opportunitiesdata:submitData,
            opportunitiesid:this.state.opportunities_id,
          }
          this.props.updateOpportunitiesDetail(this.props.apolloClient.client,req);
        }
    };

    handleMouseUpStaticLane = (type) => {
        if(type === "won"){
            this.setState({
              isOpenWonPopup:true,
              opportunityContactListArray:[],
              opportunityCompanyListArray:[],
            });
            this.props.getOpportunitiesContactList(this.props.apolloClient.client, parseInt(this.state.PiplinecardId));
            this.props.getOpportunitiesCompanyList(this.props.apolloClient.client, parseInt(this.state.PiplinecardId));
        }else if(type === "lost"){
            this.setState({ isOpenLostPopup: true});
        }else if(type === "abandoned"){
            this.setState({ isOpenAbandonedPopup: true});    
        }
        this.setState({ isOpenedPipline: false});
    }

    handleOpportunityDialogClose = () => {
        this.setState({ shouldOpenCreateOpportunityDialog: false, FullCreateOpportunityDialog: false, minimizeOpportunityDialog: false });
    };

    handleOpportunityDialogMinimize = () => {
        this.setState(oldStateminimizeOpportunityDialog => ({ minimizeOpportunityDialog: !oldStateminimizeOpportunityDialog.minimizeOpportunityDialog }));
        this.setState({ FullCreateOpportunityDialog: false })
    };
    
    handleOpportunityDialogFull = () => {
        this.setState(oldStateFullCreateOpportunityDialog => ({ FullCreateOpportunityDialog: !oldStateFullCreateOpportunityDialog.FullCreateOpportunityDialog }));
        this.setState({ minimizeOpportunityDialog: false })

    };
    handlePipeLineChange = (pipelineData) => {
        if(this.state.selectedPipelineId != pipelineData.id){
            localStorage.setItem('selected_pipeline_id',pipelineData.id);
            
            this.setState({
                selectedPipelineId: pipelineData.id,
                isDataLoading:true,
                lanesData:{ 
                    "lanes": [
                    ]
                },
            }, () => {
                this.forceUpdate()
                let val = { 
                    filterdata: this.state.filterdata,
                    list: { 
                        limit: 1, 
                        pageno: 1,
                        searchtext: this.state.searchText,
                        sorttype: this.state.sorttype, 
                        type: "list", 
                        fieldId: this.state.fieldId,
                        columnname:this.state.column_name,
                        islimited:false
                    },
                    piplineid:parseFloat(pipelineData.id),
                    filterid:1
                }
                this.getListing(val)
                this.props.getPipelines(this.props.apolloClient.client);
            })

        }

    };

    handleChange = event => {
        event.persist();
        if (event.target.name === 'switchView') {
            let path = `/opportunities/opportunities-list`;
            this.props.history.push(path);
        }
        else {
          this.setState({ [event.target.name]: event.target.value });
        }
    }
    changeSortOption = (fieldkey,name,fieldid) => {
      
        this.setState({
            selectedSortByValue:fieldkey,
            selectedSortByName:name,
        });
        let val = { 
            filterdata: this.state.filterdata,
            list: { 
                limit: 1, 
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "sort", 
                fieldId: fieldid,
                columnname:fieldkey,
                islimited:false
            },
            piplineid:parseFloat(this.state.selectedPipelineId),
            filterid:1
        }
        this.getListing(val)
        this.props.getPipelines(this.props.apolloClient.client);
    }
    changeSortType = () => {
        var type = this.state.sorttype
        if(type == 'asc'){
          type = 'desc';
        }else if(type == 'desc'){
          type = 'asc';
        }

        this.setState({
            sorttype: type,
            isDataLoading: true,
        }, () => {
            this.forceUpdate()
            let val = { 
                filterdata: this.state.filterdata,
                list: { 
                    limit: 1, 
                    pageno: 1,
                    searchtext: this.state.searchText,
                    sorttype: this.state.sorttype,
                    type: "sort", 
                    fieldId: this.state.selectedSortByFieldId,
                    columnname:this.state.selectedSortByValue,
                    islimited:false
                },
                piplineid:parseFloat(this.state.selectedPipelineId),
                filterid:1
            }
            this.getListing(val)
            this.props.getPipelines(this.props.apolloClient.client);
        })
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
          if (array[i][attr] == value) {
            return i;
          }
        }
        return -1;
    }

    /*START WON LOST POPUP COCE*/

        handleLostPopupClose = () =>{
            this.setState({
                isOpenLostPopup:false,
                PiplinecardId:null,
                changeDate:true,
                optionalNoteLost:"",
                lossdropdownVal:"0",
            })
        }
        handleSaveLossPopup = () =>{
            var req = {
                changedate:this.state.changeDate,
                notes:this.state.optionalNoteLost,
                lossreasonid:parseInt(this.state.lossdropdownVal),
                opportunitiesid:parseInt(this.state.PiplinecardId),
                type:"lost",
            }
            this.props.opportunitiesLost(this.props.apolloClient.client,req);
            setTimeout(() => {
                this.callOpportunityList()
            },3000);
            
            this.handleLostPopupClose()
        }

        handleLostDialogMinimize = () => {
            this.setState(oldStateminimizeLostDialog => ({ minimizeLostDialog: !oldStateminimizeLostDialog.minimizeLostDialog }));
            this.setState({fullScreenLostDialog: false})
        }

        handleLostDialogFull = () => {
            this.setState(oldStatefullScreenLostDialog => ({ fullScreenLostDialog: !oldStatefullScreenLostDialog.fullScreenLostDialog }));
            this.setState({minimizeLostDialog: false})
        }

        handleWonPopupClose = () =>{
            this.setState({
                isOpenWonPopup:false,
                PiplinecardId:null,
                contactTypeObj:[],
                contactTypeObjCmp:[],
                changeDate:true,
                optionalNoteWon:"",
            })
        }
        handleSaveWonPopup = () =>{
          var contactdata = []
            var promise = new Promise((resolve, reject) => {
              this.state.contactTypeObj.map((v,k)=>{
                if(v.checked == true){
                  var obj = {}
                  obj.type = "contact"
                  obj.typeid = v.contacttype
                  obj.id = v.id
                  
                  var nmKey = this.findWithAttr(this.state.contactTypeList,"id",v.contacttype)
                  obj.typevalue = this.state.contactTypeList[nmKey].name
                  
                  contactdata.push(obj)
                }
              })
              this.state.contactTypeObjCmp.map((v,k)=>{
                if(v.checked == true){
                  var obj = {}
                  obj.type = "company"
                  obj.typeid = v.contacttype
                  obj.id = v.id
                  
                  var nmKey = this.findWithAttr(this.state.contactTypeList,"id",v.contacttype)
                  obj.typevalue = this.state.contactTypeList[nmKey].name
                  
                  contactdata.push(obj)
                }
              })
              resolve(1)
            })
            promise.then(result => {
                  var req = {
                      notes:this.state.optionalNoteWon,
                      changedate:this.state.changeDate,
                      contactdata:contactdata,
                      opportunitiesid:parseInt(this.state.PiplinecardId),
                  }
                  
                  this.props.opportunitiesWin(this.props.apolloClient.client,req);
                  setTimeout(() => {
                    this.callOpportunityList()
                  },3000);
                  this.handleWonPopupClose()
            });
        }

        handleWonDialogMinimize = () => {
            this.setState(oldStateminimizeWonDialog => ({ minimizeWonDialog: !oldStateminimizeWonDialog.minimizeWonDialog }));
            this.setState({fullScreenWonDialog: false})
        }

        handleWonDialogFull = () => {
            this.setState(oldStatefullScreenWonDialog => ({ fullScreenWonDialog: !oldStatefullScreenWonDialog.fullScreenWonDialog }));
            this.setState({minimizeWonDialog: false})
        }

        handleAbandonedPopupClose = () =>{
            this.setState({
                isOpenAbandonedPopup:false,
                PiplinecardId:null,
                changeDate:true,
                optionalNoteAbandoned:"",
            })
        }
        handleSaveAbandonedPopup = () =>{
            var req = {
                changedate:this.state.changeDate,
                notes:this.state.optionalNoteAbandoned,
                lossreasonid:0,
                opportunitiesid:parseInt(this.state.PiplinecardId),
                type:"abandoned",
            }
            this.props.opportunitiesLost(this.props.apolloClient.client,req);
            setTimeout(() => {
                this.callOpportunityList()
            },3000);
            this.handleAbandonedPopupClose()
        }

        handleAbandonedDialogMinimize = () => {
            this.setState(oldStateminimizeAbandonedDialog => ({ minimizeAbandonedDialog: !oldStateminimizeAbandonedDialog.minimizeAbandonedDialog }));
            this.setState({fullScreenAbandonedDialog: false})
        }

        handleAbandonedDialogFull = () => {
            this.setState(oldStatefullScreenAbandonedDialog => ({ fullScreenAbandonedDialog: !oldStatefullScreenAbandonedDialog.fullScreenAbandonedDialog }));
            this.setState({minimizeAbandonedDialog: false})
        }

    /*END WON LOST POPUP COCE*/
      handleChangeContactTypeCheckBox = (event,key,Value,type) => {
        if(type == "contact"){
          var newData = this.state.contactTypeObj
          newData[key].checked = event.target.checked
          this.setState({
              contactTypeObj: newData,
          }, () => {
              this.forceUpdate()
          })
        }else{
          var newData = this.state.contactTypeObjCmp
          newData[key].checked = event.target.checked
          this.setState({
              contactTypeObjCmp: newData,
          }, () => {
              this.forceUpdate()
          })
        }
      }
      handleChangeContactTypeDropdown = (event,key,Value,type) => {
        if(type == "contact"){
          var newData = this.state.contactTypeObj
          newData[key].contacttype = event.target.value
          this.setState({
              contactTypeObj: newData,
          }, () => {
              this.forceUpdate()
          })
        }else{
          var newData = this.state.contactTypeObjCmp
          newData[key].contacttype = event.target.value
          this.setState({
              contactTypeObjCmp: newData,
          }, () => {
              this.forceUpdate()
          })
        }
      }

      handleSearch = event => {
        this.setState({
              [event.target.name]: event.target.value,
              isDataLoading:true,
        }, () => {
              this.forceUpdate()
              setTimeout(() => {
                //this.callOpportunityList()
                let val = { 
                    filterdata: this.state.filterdata,
                    list: { 
                        limit: 1, 
                        pageno: 1,
                        searchtext: this.state.searchtext,
                        sorttype: this.state.sorttype, 
                        type: "list", 
                        fieldId: this.state.fieldId,
                        columnname:this.state.column_name,
                        islimited:false
                    },
                    piplineid:parseFloat(this.state.selectedPipelineId),
                    filterid:1
                }
                this.getListing(val)
                this.props.getPipelines(this.props.apolloClient.client);
              },300);
        })

    }

    openTaskPopup = (e,opportunities_id,oppo_name) => {
        clickFlag = true
        var task_popup = document.querySelector("#task_popup");
        var ele = document.getElementById("tsk_popup_"+opportunities_id)
        this.CancelTaskPopup();
        this.setState({
            addTaskOpportunityId:opportunities_id,
            oppo_name:oppo_name,
            isLoadingRelatedTask: true,
            displayCompletedTask:false,
        }, () => {
            var req ={
                id:parseInt(opportunities_id),
                type:"tasks",
                recordtype:"opportunities",
                taskListArray:[],
            }
            this.props.getRecordRelatedData(this.props.apolloClient.client, req);
        })


        task_popup.style.left   =  e.pageX  + "px";
        task_popup.style.top    =  e.pageY + "px";
        task_popup.style.display =  "block";
    }
    closeTaskPopup = () => {
        clickFlag = false
        this.setState({
            addTaskOpportunityId:"",
            oppo_name:"",
            taskDueDate:new Date(),
            taskName:"",
        })
        var task_popup = document.querySelector("#task_popup");
        task_popup.style.display =  "none";
    }

    handleChangeTaskField = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDtChange = (val) => {
         this.setState({taskDueDate: val });
    };
    handleFormSubmitAddTask = () => {
        var dDate = ""
        var repeatObj = {type: "never", repeatdays: 0, repeattype: "", days: 0};
        if(this.state.taskDueDate == "Invalid Date"){
            return false;
        }

        if(this.state.taskDueDate == null){
            let date_test = moment(new Date()).format('YYYY-MM-DD HH:mm');
            var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
            dDate = moment.utc(logdate).format('X'); 
        }else{
            let date_test = moment(this.state.taskDueDate).format('YYYY-MM-DD HH:mm');
            var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
            dDate = moment.utc(logdate).format('X');
        }
        var taskData = [
            {fieldid: "57", taskvalue: this.state.taskName},
            {fieldid: "60", taskvalue: dDate},
            {fieldid: "62", values:[{type: 0, date: "", minitue: 0}]},
            {fieldid: "59", values:[{srno: "1", id: parseInt(this.state.addTaskOpportunityId), value: this.state.oppo_name, type: "opportunities"}]},
            {fieldid: "61", values:[{srno: "1", id: this.state.taskOwner.id.toString(), value: this.state.taskOwner.email, name: this.state.taskOwner.name}]},
        ]
        let req = {
            taskdata: taskData,
            repeat: repeatObj,
            sidebarParams: {
                id:parseInt(this.state.addTaskOpportunityId),
                type:"tasks",
                recordtype:"opportunities",
            },
        }
        this.props.saveTaskDetail(this.props.apolloClient.client, req);
        
        this.CancelTaskPopup()

    };
    handleBlur = (event,ref) => {
        this[ref].validate(event.target.value);
    }
    handleChangeTaskOwner = (val, name) => {
        this.setState({
           taskOwner:val.values,
        })
    }

    handleTaskDialogClose = () => {
        this.setState({ shouldOpenCreateTaskDialog: false, FullCreateTaskDialog: false, minimizeTaskDialog: false });
    };

    handleTaskDialogMinimize = () => {
        this.setState(oldStateminimizeTaskDialog => ({ minimizeTaskDialog: !oldStateminimizeTaskDialog.minimizeTaskDialog }));
        this.setState({ FullCreateTaskDialog: false })
    };

    handleTaskDialogFull = () => {
        this.setState(oldStateFullCreateTaskDialog => ({ FullCreateTaskDialog: !oldStateFullCreateTaskDialog.FullCreateTaskDialog }));
        this.setState({ minimizeTaskDialog: false })

    };
    openFullTaskPopup = () => {
        this.setState({
              newTaskData:{
                id:parseInt(this.state.addTaskOpportunityId),
                type:"tasks",
                recordtype:"opportunities",
              },
              showTskpopup:false,
        }, () => {
                this.setState({
                    shouldOpenCreateTaskDialog:true
                }, () => {
                    setTimeout(() => {
                        this.makeTAskFieldBlank();
                    }, 2000);
                })
        })
    };

    makeTAskFieldBlank = ()=>{
        this.setState({
            oppo_name:"",
            taskDueDate:new Date(),
            taskName:"",
            taskOwner:"",
        })
    }

    toggleTaskpopup = () => {
        this.setState({ showTskpopup: !this.state.showTskpopup});
    };
    toggleCompletedTask = () => {
        this.setState({ displayCompletedTask: !this.state.displayCompletedTask});
    };
    CancelTaskPopup = () => {
        this.setState({
            showTskpopup:false,
        })
        this.makeTAskFieldBlank();
    };

    handleCompleteTask = (task1, status1) => {
        let taskCompleteParams = [];
        taskCompleteParams['complete'] = { status: !status1, taskid: parseFloat(task1) };
        taskCompleteParams['sidebarParams'] = {
            id:parseInt(this.state.addTaskOpportunityId),
            type:"tasks",
            recordtype:"opportunities",
        };
        this.props.taskComplete(this.props.apolloClient.client, taskCompleteParams);
        this.setState({ isDataLoading: true });
        setTimeout(() => {
            this.setState({ isDataLoading: false });
        },1000);
    }

    closeDrawerRightTask = (e) => {
        this.setState({
          drawerOpenTask: false,
        });
    };

    handleDrawerTaskRight = (task_id) => {
        if (this.state.task_id && (this.state.task_id === task_id)) { } else {
            this.setState({
                task_id: task_id,
                isDataLoading: true,
            }, () => {
                this.forceUpdate()
            })
        }

        setTimeout(() => {
            this.setState({
                drawerOpenTask: true,
            }, () => {
                this.forceUpdate()
            })
        }, 2000);

    }; 
    
    render() {
        let {drawerOpenTask,isOpenedFiltersDefault, isOpenedPipelines, isOpenedFilters, isOpenedCustomFilters, isOpenedRange, isOpenedPipline, switchView ,drawerOpen} = this.state;
        let applyPipelineId = localStorage.getItem('selected_pipeline_id');
        var localStorageFilter = localStorage.getItem('selected_opportunity_filter_id');
        let updateSaveFilterBtnDisabled = (this.state.filterdata == undefined || !this.state.filterdata.length) ? ((this.state.oldFilterOption == undefined || !this.state.oldFilterOption.length)?true:false):false
         /*STRAT contact Display*/
        let displayContactListWon = []
        {
          this.state.opportunityContactListArray.map((item, key) => {
            let flagToPush = true
            if(item.contact.contactdetail.length >0){
                var contactName = ""
                var contactType = ""
                item.contact.contactdetail.map((data, key1) => {
                    if(data.field.id == "16"){//name
                        contactName =  data.fieldvalue;
                    }
                    if(data.field.id == "19" &&  data.values.length > 0){//contacttype
                        console.log("data.values", data.values);
                        contactType =  data.values[0].value;
                        if(data.values[0].id == "2"){//do not push if already current customer
                            flagToPush=false
                        }
                    }
                                         
                })
                if(flagToPush){
                    displayContactListWon.push(
                            <Grid item xs={12}>
                                <FormControl className="input_bx">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        id={item.contact.id}
                                        checked={this.state.contactTypeObj[key]?this.state.contactTypeObj[key].checked:false}
                                        onChange={(event, value) => this.handleChangeContactTypeCheckBox(event,key,value,'contact')}
                                        value={this.state.contactTypeObj[key]?this.state.contactTypeObj[key].checked:false}
                                        color="primary"
                                      />
                                    }
                                    label={<div style={{fontSize:"14px"}}>
                                      <span>Turn &nbsp;</span>
                                      <span style={{fontWeight:'bold'}}>{contactName}&nbsp;</span>
                                      <span>From &nbsp;</span>
                                      <span style={{fontWeight:'bold'}}>{contactType}&nbsp;</span>
                                      <span>to &nbsp;</span>
                                    </div>}
                                  />
                                </FormControl>
                                <Select
                                      id={item.contact.id}
                                      style={{top:'8px',left:'-16px'}}
                                      value={this.state.contactTypeObj[key]?this.state.contactTypeObj[key].contacttype:""}
                                      onChange={(event, value) => this.handleChangeContactTypeDropdown(event,key,value,'contact')}
                                      SelectDisplayProps={{ className: "multiple-text-dropdown" }}
                                    >
                                      {this.state.contactTypeList.length > 0 && this.state.contactTypeList.map((item, key) =>
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                      )}
                                    </Select>
                              </Grid>                         
                    )
                }
            }
            
          })
        }
        /*END contact Display*/

        /*STRAT company Display*/
        let displayCompanyListWon = []
        {
          this.state.opportunityCompanyListArray.map((item, key) => {
            let flagToPush = true
            if(item.company.companydetail.length >0){
                var companyName = ""
                var companyType = ""
                item.company.companydetail.map((data, key1) => {
                    if(data.field.id == "29"){//name
                        companyName =  data.fieldvalue;
                    }
                    if(data.field.id == "33" &&  data.values.length > 0){//contacttype
                        companyType =  data.values[0].value;
                        if(data.values[0].id == "2"){//do not push if already current customer
                            flagToPush=false
                        }
                    }
                                         
                })
                if(flagToPush){
                    displayCompanyListWon.push(
                            <Grid item xs={12}>
                                <FormControl className="input_bx">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        id={item.company.id}
                                        checked={this.state.contactTypeObjCmp[key]?this.state.contactTypeObjCmp[key].checked:false}
                                        onChange={(event, value) => this.handleChangeContactTypeCheckBox(event,key,value,'company')}
                                        value={this.state.contactTypeObjCmp[key]?this.state.contactTypeObjCmp[key].checked:false}
                                        color="primary"
                                      />
                                    }
                                    label={<div style={{fontSize:"14px"}}>
                                      <span>Turn company &nbsp;</span>
                                      <span style={{fontWeight:'bold'}}>{companyName}&nbsp;</span>
                                      <span>From &nbsp;</span>
                                      <span style={{fontWeight:'bold'}}>{companyType}&nbsp;</span>
                                      <span>to &nbsp;</span>
                                    </div>}
                                  />
                                </FormControl>
                                <Select
                                      id={item.company.id}
                                      style={{top:'8px',left:'-16px'}}
                                      value={this.state.contactTypeObjCmp[key]?this.state.contactTypeObjCmp[key].contacttype:""}
                                      onChange={(event, value) => this.handleChangeContactTypeDropdown(event,key,value,'company')}
                                      SelectDisplayProps={{ className: "multiple-text-dropdown" }}
                                    >
                                      {this.state.contactTypeList.length > 0 && this.state.contactTypeList.map((item, key) =>
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                      )}
                                    </Select>
                              </Grid>                         
                    )
                }
            }
            
          })
        }
        /*END company Display*/

        const CustomLaneHeader = ({ label, title, current, target }) => {
            return (
                <React.Fragment>
                    <header className="trello-header">
                        <div className="d-flex justify-content-between">
                            <h4 className="pipline-name">{title}</h4>
                            <span className="card-count">{label}</span>
                        </div>
                        <LinearProgress variant="determinate" className={`trello-progress ${target}`} value={current} />
                    </header>
                </React.Fragment>
            )
        }

        var dtFormat = "MM/dd/yyyy";
        if (this.state.userSettings != "") {
          dtFormat = this.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }

        var selectedDate = this.state.taskDueDate;
         let completedTaskList = []
         let inCompleteTaskList = []
        {
          this.state.taskListArray.map((item, key) => {
            if(item.__taskdetail__.length >0){
                var cName = ""
                var relatedToID = ""
                var relatedToType = ""
                var relatedToValue = ""
                var owner = ""
                var activity_icon = "" 
                var dueDate = "" 
                
                item.__taskdetail__.map((data, key1) => {
                    if(data.__field__.id == "57"){//name
                        cName =  data.fieldvalue;
                    }
                    if(data.__field__.id == "59" && data.values.length > 0){//related to
                      relatedToID = data.values[0].id
                      relatedToType = data.values[0].type
                      relatedToValue = data.values[0].value
                    }
                    if(data.__field__.id == "61" && data.values.length > 0){//owner
                        owner = data.values[0].name
                    }
                    if(data.__field__.id == "58" && data.values.length > 0){//Activity type
                      activity_icon = data.values[0].icon;
                      var k = this.findWithAttr(this.state.masterActivityTypesList, 'id',data.values[0].id)
                      if(k !=-1){
                          activity_icon = this.state.masterActivityTypesList[k].icon;
                      }
                    }
                    if(data.__field__.id == "60" && data.fieldvalue){//due date
                       dueDate = moment.unix(data.fieldvalue).tz(this.state.timeZone).format(this.state.dateformat)
                    }
                })
                if(item.iscomplete == false){
                    completedTaskList.push(
                        <>
                            <div className="box_portlet mt-10" style={{border:'none'}}>
                                <div className="box_body" style={{padding:'0px'}}>
                                    <div className="lead_pl">
                                        <div className="icon_left" style={{left: '-4px'}}>
                                            <Icon onClick={(task1, status1) => this.handleCompleteTask(item.id, item.iscomplete)}  style={{cursor:'pointer',color:'#7467ef'}}>{(item.iscomplete == true) ? 'check_box' : 'check_box_outline_blank'}</Icon>
                                        </div>
                                        <Grid container justify="space-between">
                                            <a  className="m-0 taskNameHoverr" onClick={()=>this.handleDrawerTaskRight(item.id)} style={{cursor:'pointer',fontSize:'14px',color:'#000'}}>{cName}</a>
                                        </Grid>
                                        {relatedToID && relatedToType && <Link style={{cursor:'pointer',fontSize:'15px',color:'#7467ef'}} to={"/"+relatedToType+"/"+relatedToType+"/"+relatedToID }>{relatedToValue}</Link>}
                                        {owner && <> <Link style={{cursor:'pointer',fontSize:'15px',color:'#7467ef',marginRight:'15px'}}>| Owner :{owner}</Link></>}
                                         
                                    </div>
                                </div>
                         </div>
                        </>
                    )
                }else{
                    inCompleteTaskList.push(
                        <>
                            <div className="box_portlet mt-10" style={{border:'none'}}>
                                <div className="box_body" style={{padding:'0px'}}>
                                    <div className="lead_pl">
                                        <div className="icon_left" style={{left: '-4px'}}>
                                            <Icon onClick={(task1, status1) => this.handleCompleteTask(item.id, item.iscomplete)}  style={{cursor:'pointer',color:'#7467ef'}}>{(item.iscomplete == true) ? 'check_box' : 'check_box_outline_blank'}</Icon>
                                        </div>
                                        <Grid container justify="space-between">
                                            <a  className="m-0 taskNameHoverr" onClick={()=>this.handleDrawerTaskRight(item.id)} style={{cursor:'pointer',fontSize:'14px',color:'#000'}}>{cName}</a>
                                        </Grid>
                                        {relatedToID && relatedToType && <Link style={{cursor:'pointer',fontSize:'15px',color:'#7467ef'}} to={"/"+relatedToType+"/"+relatedToType+"/"+relatedToID }>{relatedToValue}</Link>}
                                        {owner && <> <Link style={{cursor:'pointer',fontSize:'15px',color:'#7467ef',marginRight:'15px'}}>| Owner :{owner}</Link></>}
                                         
                                    </div>
                                </div>
                         </div>
                        </>
                    )
                }
            }
            
          })
        }

        return (
            <div className="opportunities_card">
                <div className="task_popup" id="task_popup" style={{display:'none'}}>
                    <div className="nub" onClick={this.closeTaskPopup}>
                        <Icon>close</Icon>
                        <div className="border_cover"></div>
                    </div>
                    <div className="tasks_title">Tasks <Icon onClick={()=>this.toggleTaskpopup()} className={this.state.showTskpopup?"plusIconTransform add_task":"add_task"}>add</Icon></div>
                    {this.state.isLoadingRelatedTask && <CircularProgress style={{marginBottom:'15%',marginLeft:'48%',marginTop:'19%'}} size={20} />}
                    {!this.state.isLoadingRelatedTask && !this.state.showTskpopup && completedTaskList.length > 0 && 
                        <div className="empty_text" style={{textAlign:'inherit'}}>
                            {completedTaskList}
                        </div>
                    }
                    {!this.state.isLoadingRelatedTask && completedTaskList && completedTaskList.length == 0 && !this.state.showTskpopup && !this.state.isLoadingRelatedTask && 
                        <><div className="empty_text">
                            No scheduled Tasks
                            <div className="task_button">
                                <Button className="btn_Primary px-12" onClick={()=>this.toggleTaskpopup()} size="small">Add Task</Button>
                            </div>
                        </div></>
                    }
                    {!this.state.isLoadingRelatedTask && inCompleteTaskList.length > 0 && 
                        <>
                            {!this.state.showTskpopup && !this.state.displayCompletedTask && <a style={{padding:'10px',cursor:'pointer',fontSize:'15px',color:'#7467ef'}} here="#" onClick={()=>this.toggleCompletedTask()}>{inCompleteTaskList.length} completed tasks <FontAwesome className="fa-angle-down"/></a>}
                            {!this.state.showTskpopup && this.state.displayCompletedTask && <a style={{padding:'10px',cursor:'pointer',fontSize:'15px',color:'#7467ef'}} here="#" onClick={()=>this.toggleCompletedTask()}>hide completed tasks <FontAwesome className="fa-angle-up"/></a>}
                        </>
                    }
                    {!this.state.showTskpopup &&  !this.state.isLoadingRelatedTask && this.state.displayCompletedTask && inCompleteTaskList.length > 0 && 
                        <div className="empty_text" style={{textAlign:'inherit'}}>
                            {inCompleteTaskList}
                        </div>
                    }
                    {this.state.showTskpopup && 
                        <div className="empty_text" style={{marginBottom:'4px'}}>
                            <ValidatorForm noValidate  instantValidate={false}  onSubmit={this.handleFormSubmitAddTask}>
                                <Grid container spacing={2}>
                                    <TextValidator
                                        ref={ref => this['taskName'] = ref}
                                        onBlur={(event)=>this.handleBlur(event,'taskName')}
                                        type="text"
                                        id={"taskName"}
                                        required={true}
                                        className="w-100 input_bx"
                                        placeholder={"Task Name"}
                                        onChange={this.handleChangeTaskField}
                                        name={"taskName"}
                                        value={this.state.taskName}
                                        validators={["required"]}
                                        errorMessages={["this field is required"]}
                                      />
                                        
                                        <Grid container spacing={3} style={{marginTop:'0px'}}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid item xs={6}>
                                                  <FormControl className="w-100 input_bx">
                                                      <KeyboardDatePicker
                                                        name={"taskDueDate"}
                                                        format={dtFormat}
                                                        placeholder={dtFormat}
                                                        error={(selectedDate == "Invalid Date") ? true : false}
                                                        value={this.state.taskDueDate}
                                                        onChange={this.handleDtChange}
                                                        KeyboardButtonProps={{
                                                          'aria-label': 'change date',
                                                        }}
                                                        helperText={(selectedDate == "Invalid Date") ? "this field is required" : (selectedDate == "Invalid Date") ? "enter valid date." : ""}

                                                      />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                  <KeyboardTimePicker
                                                        format="hh:mm a"
                                                        placeholder={"hh:mm a"}
                                                        error={(selectedDate == "Invalid Date") ? true : false}
                                                        value={this.state.taskDueDate}
                                                        onChange={this.handleDtChange}
                                                        KeyboardButtonProps={{
                                                          'aria-label': 'change date',
                                                        }}
                                                        helperText={(selectedDate == "Invalid Date") ? "this field is required" : (selectedDate == "Invalid Date") ? "enter valid date." : ""}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <FormControl className="w-100 input_bx">
                                            <Autocomplete
                                              disableOpenOnFocus
                                              forcePopupIcon={false}
                                              name={"taskOwner"}
                                              options={this.state.usersListArray}
                                              getOptionLabel={option => option.name}
                                              value={this.state.taskOwner}
                                              name={"taskOwner"}
                                              onChange={(event, value) => this.handleChangeTaskOwner({ fieldid: "id", values: value }, "taskOwner")}
                                              id={"taskOwner"}
                                              renderInput={params => (
                                                <TextValidator
                                                  ref={ref => this["taskOwner"] = ref}
                                                  onBlur={(event)=>this.handleBlur(event,"taskOwner")}
                                                  {...params}
                                                  variant="standard"
                                                  placeholder={"Owner"}
                                                  fullWidth
                                                  name={"taskOwner"}
                                                  value={this.state.taskOwner && this.state.taskOwner.length ? 1 : null}
                                                  required={true}
                                                  validators={((this.state.taskOwner == null || this.state.taskOwner.length == 0)) ? ["required"] : []}
                                                  errorMessages={((this.state.taskOwner == null || this.state.taskOwner.length == 0)) ? ["this field is required"] : []}
                                                />
                                              )}
                                            />
                                        </FormControl>
                                </Grid>
                                <div style={{marginTop:'24px',textAlign:'end'}}>
                                    <a  style={{cursor:'pointer',fontSize:'15px',color:'#7467ef',marginRight:'15px'}} here="#" onClick={()=>this.openFullTaskPopup()}>edit full details</a>
                                    <Button  onClick={()=>this.CancelTaskPopup()} variant="contained">Cancel</Button>
                                    <Button style={{margin:'5px'}} variant="contained" type="submit" color="primary">Save</Button>
                                </div>
                            </ValidatorForm>
                        </div>
                    }
                </div>
                <aside className={(isOpenedPipline == true) ? "aside_left bottom-left" : "aside_left"}  style={{overflowY: 'scroll',overflow: 'auto'}}>
                    <Card elevation={3}>
                        <h5 onClick={this.togglePipelines} className="cursor_poiter d-flex justify-content-between">Pipelines <Icon className="vert-middle flex-end">{isOpenedPipelines ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
                        {isOpenedPipelines &&
                            <div className="filter_menu">
                                <ul>
                                    {this.state.isLoadingPipelineList && <li className="text-center"><CircularProgress size={20}/></li>}
                                    {this.state.pipelineListArray.length > 0 && this.state.pipelineListArray.map((item, key) =>
                                        <li onClick={()=>this.handlePipeLineChange(item)}><Link className={(this.state.selectedPipelineId == item.id)?"pipline-selected-class":""}>{item.name}</Link></li>
                                    )}
                                    <li><Link onClick={() => this.setState({ shouldOpenNewPipelineDialog: true })}>+ Create New Pipeline</Link></li>
                                </ul>
                            </div>
                        }
                        <Link to="/opportunities/progression-report"><h5 className="cursor_poiter">Reports</h5></Link>
                        <h5 onClick={this.toggleFiltersDefault} className="cursor_poiter d-flex justify-content-between">Filters                            <Tooltip title={this.state.displaySelectedFilterName }>
 <div className="text_ellipsis" style={{maxWidth:'130px',fontSize:'13px',margin:'3px 0px 0px 0px',fontWeight:'400'}}>({this.state.displaySelectedFilterName})</div></Tooltip><Icon className="vert-middle flex-end">{isOpenedFiltersDefault ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
                        {isOpenedFiltersDefault &&
                            <div className="filter_menu_custom">
                                <h6 className="mt-8 font-size-14 mb-0 pl-16 pr-16">Private Filters </h6>
                                <ul>
                                    {this.state.opportunityFilterListArray.length > 0 && this.state.opportunityFilterListArray.map((item, key) =>
                                        {
                                            let star_icon = item.isdefault ? "star" : "star_border" ;
                                            if(item.fitertype == 1 && item.id != localStorage.getItem('selected_opportunity_filter_id')){
                                                return  <li key={item.key}>
                                                            <div onClick={()=>this.handleFilterChange(item)}>{item.filtername}</div>
                                                            <><IconButton  className="text-secondary ml-4" size="small">
                                                                <Icon onClick={()=>this.updateOpportunityFilter(item)} style={{marginTop:'5px',display:'contents',fontSize: '18px'}}>{'settings'}</Icon>
                                                            </IconButton>
                                                            <IconButton  className="text-secondary ml-4" size="small">
                                                                <Icon onClick={()=>this.setState({isDeleteFilter:true,filterDeleteId:item.id})} style={{marginTop:'5px',display:'contents',fontSize: '18px'}}>{'delete'}</Icon>
                                                            </IconButton>
                                                            <IconButton  className="text-secondary ml-4" size="small">
                                                                <Icon onClick={()=>this.updateFilterDefaultOpportunity(item)} style={{marginTop:'5px',display:'contents',fontSize: '18px'}}>{star_icon}</Icon>
                                                            </IconButton>
                                                            </>
                                                        </li>
                                            }
                                        }
                                    )}
                                </ul>
                                <h6 className="mt-8 font-size-14 mb-0 mt-16 pl-16 pr-16">Public Filters</h6>
                                <ul>
                                    {this.state.opportunityFilterListArray.length > 0 && this.state.opportunityFilterListArray.map((items, key) =>
                                        {
                                            let star_icon = items.isdefault ? "star" : "star_border" ;
                                            if(items.fitertype == 0 && items.id != localStorage.getItem('selected_opportunity_filter_id')){
                                                return  <li key={items.key}>
                                                            <div onClick={()=>this.handleFilterChange(items)}>{items.filtername}</div>
                                                            {!["11","12","13","14","15"].includes(items.id) && 
                                                                <><IconButton  className="text-secondary ml-4" size="small">
                                                                    <Icon onClick={()=>this.updateOpportunityFilter(items)} style={{marginTop:'5px',display:'contents',fontSize: '18px'}}>{'settings'}</Icon>
                                                                </IconButton>
                                                                <IconButton  className="text-secondary ml-4" size="small">
                                                                    <Icon onClick={()=>this.setState({isDeleteFilter:true,filterDeleteId:items.id})} style={{marginTop:'5px',display:'contents',fontSize: '18px'}}>{'delete'}</Icon>
                                                                </IconButton></>
                                                            }
                                                            <IconButton className="text-secondary ml-4" size="small">
                                                                <Icon onClick={()=>this.updateFilterDefaultOpportunity(items)} style={{marginTop:'5px',display:'contents',fontSize: '18px'}}>{star_icon}</Icon>
                                                            </IconButton>
                                                        </li>
                                            }
                                        }
                                    )}
                                </ul>

                            <><Dialog
                                fullWidth={true}
                                maxWidth={'xs'}
                                open={this.state.isDeleteFilter}
                                onClose={this.filterRemoveDialoge}
                                disableBackdropClick
                                disableEscapeKeyDown
                                aria-labelledby="ar-remove-dialog-title"
                            >
                                <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to delete filter ?"}</DialogTitle>
                                <DialogActions>
                                    <Button onClick={this.filterRemoveDialoge} color="primary">Cancel</Button>
                                    <Button type="Button" onClick={() => this.confirmDeleteFilter()} className="btn_Primary">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog></>
                            </div>
                        }
                        {isOpenedPipline &&
                            <div className="pipline_tags" style={{zIndex:1000000}}>
                                <Grid container spacing={2}>
                                    <Grid item className="flex-basis" onMouseUp={e => this.handleMouseUpStaticLane('lost')}>
                                        <div className="bg_error">Lost</div>
                                    </Grid>
                                    <Grid item className="flex-basis" onMouseUp={e => this.handleMouseUpStaticLane('abandoned')}>
                                        <div className="bg_white">ABANDONED</div>
                                    </Grid>
                                    <Grid item className="flex-basis" onMouseUp={e => this.handleMouseUpStaticLane('won')}>
                                        <div className="bg_green">WON</div>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </Card>
                    <Card elevation={3} className={`pt-16 pb-16`} style={{marginTop:'5px'}}>
                        <div className="ml-16 mr-16">
                            <Button fullWidth className="btn_Primary" size="small" onClick={this.toggleCustomFilters}><Icon>add</Icon> Add custom filters</Button>
                        </div>
                        {isOpenedCustomFilters &&
                            <React.Fragment>
                                <div className="v_scroll position-relative ml-16 mt-16 pr-16">
                                    <Link  className="text_link" onClick={()=>this.handleFilterChange(this.state.opportunityFilterListArray[this.state.AllOpportunityFilterKey])}>Clear All</Link>
                                    {FilterComponent(this)}
                                </div>
                                <div className="fixed-bottom" style={{position:'initial'}}>
                                    <Button type="button" className="btn_Primary  mb-16 px-12" size="small" onClick={() =>this.handleSavefilterDialoge()}>Save Filter</Button>
                                    {localStorageFilter && !["11","12","13","14","15"].includes(localStorageFilter) &&
                                        <Button type="button" className="btn_Primary  mb-16 px-12 ml-16" size="small" onClick={() =>this.updateFilterOptions()}>update Filter</Button>
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </Card>
                </aside>
                <Card elevation={3} className="fixed_layout" style={{bottom:'109px'}}>
                    <Grid container justify="space-between" className="title-with-label pr-16">
                        <Grid item>
                            <Grid container justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <h3 className="ml-16">Opportunities</h3>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        value="cardview"
                                        control={<Switch color="primary"  checked={switchView} name="switchView" onChange={this.handleChange} value="switchView"  />}
                                        label="Cardview"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item  style={{margin: 'inherit'}}>
                                    <span style={{fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '1.5',
                                                marginLeft: '16px',
                                                letterSpacing: '0.00938em'}}>Sort By</span>
                                    <DropdownDefault
                                        menuButton={
                                            <p  style={{fontSize: '1rem',
                                                fontWeight: '500',
                                                cursor:"pointer",
                                                lineHeight: '1.5',
                                                marginLeft: '5px',
                                                letterSpacing: '0.00938em'}}
                                            >
                                            {this.state.selectedSortByName}
                                            </p>
                                        }
                                    >
                                        <MenuItem onClick={() => this.changeSortOption('name','Name',40)} className="flex flex-middle">Name</MenuItem>
                                        <MenuItem onClick={() => this.changeSortOption('closedate','Close Date',43)} className="flex flex-middle">Close Date</MenuItem>
                                        <MenuItem onClick={() => this.changeSortOption('inactivedays','Inactive Days',0)} className="flex flex-middle">Inactive Days</MenuItem>
                                        <MenuItem onClick={() => this.changeSortOption('company','Company',44)} className="flex flex-middle">Company</MenuItem>
                                        <MenuItem onClick={() => this.changeSortOption('owner','Owned By',46)} className="flex flex-middle">Owned By</MenuItem>
                                        <MenuItem onClick={() => this.changeSortOption('value','Value',50)} className="flex flex-middle">Value</MenuItem>
                                    </DropdownDefault>
                                    <Icon  className={`text-muted ${(this.state.sorttype=='asc') ? 'sortTransform' : ''}`} onClick={()=>this.changeSortType()} style={{marginLeft:'5px',position: 'absolute',marginTop:'16px',cursor:'pointer'}}>sort</Icon>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        className="search_bx"
                                        placeholder="Search..."
                                        name="searchtext"
                                        onChange={this.handleSearch}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <DropdownDefault
                                        menuButton={
                                            <Button variant="contained" color="primary" size="small">
                                                Actions
                                            </Button>
                                        }
                                    >
                                        <MenuItem className="flex flex-middle">
                                            <Icon className="mr-16">view_column</Icon> Customize columns
                                        </MenuItem>
                                        <MenuItem className="flex flex-middle">
                                            <Icon className="mr-16">format_list_numbered</Icon> Create custom fileds
                                         </MenuItem>
                                    </DropdownDefault>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" size="small">
                                        Import
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={() => this.setState({ shouldOpenCreateOpportunityDialog: true })} color="secondary" size="small" style={{ minWidth: 95 }}>
                                        Create New
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={(isOpenedPipline == true) ? "crm-opportunities-dnd" : "opportunities-dnd"} >
                        <Board data={this.state.lanesData} handleDragStart={this.handleDragStart} handleDragEnd={this.handleDragEnd} onCardClick={this.handleDrawerRight} laneDraggable={false} hideCardDeleteIcon={true} components={{ LaneHeader: CustomLaneHeader }} eventBusHandle={setEventBus}/>
                    </div>
                </Card>
                <Card elevation={3} className="pipline_stats">
                    <Grid container className="h-100" alignItems="center">
                        <Grid item className="flex-basis" sm={2}>
                            <h4 className="mb-0 ml-10">Pipeline stats</h4>
                        </Grid>
                        <Grid item className="flex-basis h-100">
                            <div className="border_bx d-flex h-100 justify-content-between align-items-center">
                                <span className="font-size-14">Open opportunites</span>
                                <span className="font-size-20 font-weight-bold text-right">{this.state.openOpportunityCount}</span>
                            </div>
                        </Grid>
                        <Grid item className="flex-basis h-100">
                            <div className="border_bx d-flex h-100 justify-content-between align-items-center">
                                <span className="font-size-14">Total potential value of pipeline</span>
                                <span className="font-size-20 font-weight-bold text-right">{this.state.companyCurrencyData.symbol}{this.state.potentialTotalValue}</span>
                            </div>
                        </Grid>
                        <Grid item className="flex-basis h-100">
                            <div className="border_bx d-flex h-100 justify-content-between align-items-center">
                                <span className="font-size-14">Total weighted value of pipeline</span>
                                <span className="font-size-20 font-weight-bold text-right">{this.state.companyCurrencyData.symbol}{this.state.totalWeightedSum}</span>
                            </div>
                        </Grid>
                    </Grid>
                </Card>

                

                {this.state.shouldOpenCreateOpportunityDialog && (
                    <CreateNewOpportunityDialog
                        handleClose={this.handleOpportunityDialogClose}
                        open={this.state.shouldOpenCreateOpportunityDialog}
                        handleMinimize={this.handleOpportunityDialogMinimize}
                        handleFull={this.handleOpportunityDialogFull}
                        minimizeScreen={this.state.minimizeOpportunityDialog}
                        fullScreen={this.state.FullCreateOpportunityDialog}
                    />
                )}

                {this.state.isOpenLostPopup && (
                    <Dialog onClose={this.handleLostPopupClose} open={this.state.isOpenLostPopup} maxWidth="sm" fullWidth={true} className={`custom_dialog ${this.state.minimizeLostDialog && this.state.fullScreenLostDialog ? 'alignitemsend-fullscreen' : ''} ${this.state.minimizeLostDialog ? 'alignitemsend' : ''} ${this.state.fullScreenLostDialog ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
                        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4" style={{background:'#f44336cc'}}>
                          <h4 className="m-0 text-white">Lost opportunity</h4>
                          <div className="d-flex">
                            <Tooltip title={this.state.minimizeLostDialog ? 'Normal Screen' : 'Minimize Screen'}>
                              <IconButton onClick={this.handleLostDialogMinimize}>
                                <Icon className="text-white">{this.state.minimizeLostDialog ? 'add' : 'remove'}</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={this.state.fullScreenLostDialog ? 'Exit Full Screen' : 'Full Screen'}>
                              <IconButton onClick={this.handleLostDialogFull}>
                                <Icon className="text-white">{this.state.fullScreenLostDialog ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Close">
                              <IconButton onClick={this.handleLostPopupClose}>
                                <Icon className="text-white">clear</Icon>
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <ValidatorForm>
                          <div className="dialog-body-no-scroll" style={{padding: '25px'}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                Add a loss reason and add any additional notes below.
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  name={"lossdropdownVal"}
                                  id={"lossdropdownVal"}
                                  value={this.state.lossdropdownVal}
                                  onChange={this.handleChangeVals}
                                  SelectDisplayProps={{ className: "multiple-text-dropdown" }}
                                >
                                   <MenuItem key={0} value={0}>{"No Loss Reason"}</MenuItem>
                                  {this.state.loassReasonList.length > 0 && this.state.loassReasonList.map((item, key) =>
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                  )}
                                </Select>
                              </Grid>
                              <Grid item xs={12}>
                                <TextValidator
                                  className="w-100 input_bx"
                                  placeholder="(Optional) Add additional notes here..."
                                  onChange={this.handleChangeVals}
                                  type="text"
                                  name="optionalNoteLost"
                                  helperText="Lost opportunities can be seen in the opportunities list by selecting the status filter and checking the 'Lost' box."
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl className="w-100 input_bx">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={"changeDate"}
                                        id={"changeDate"}
                                        checked={this.state.changeDate}
                                        onChange={this.handleChangeVals}
                                        value={this.state.changeDate}
                                        color="primary"
                                      />
                                    }
                                    label={"Change Close Date to Today"}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                          <DialogActions className="dialog-action">
                            <Button variant="contained" onClick={this.handleLostPopupClose}>Cancel</Button>
                            <Button variant="contained" type="submit" color="primary" onClick={this.handleSaveLossPopup}>
                              save
                            </Button>
                          </DialogActions>
                        </ValidatorForm>
                      </Dialog>
                )}

                {this.state.isOpenWonPopup && (
                    <Dialog onClose={this.handleWonPopupClose} open={this.state.isOpenWonPopup} maxWidth="sm" fullWidth={true} className={`custom_dialog ${this.state.minimizeWonDialog && this.state.fullScreenWonDialog ? 'alignitemsend-fullscreen' : ''} ${this.state.minimizeWonDialog ? 'alignitemsend' : ''} ${this.state.fullScreenWonDialog ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
                        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4" style={{background:'#08ad6ccc'}}>
                          <h4 className="m-0 text-white">Won opportunity</h4>
                          <div className="d-flex">
                            <Tooltip title={this.state.minimizeWonDialog ? 'Normal Screen' : 'Minimize Screen'}>
                              <IconButton onClick={this.handleWonDialogMinimize}>
                                <Icon className="text-white">{this.state.minimizeWonDialog ? 'add' : 'remove'}</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={this.state.fullScreenWonDialog ? 'Exit Full Screen' : 'Full Screen'}>
                              <IconButton onClick={this.handleWonDialogFull}>
                                <Icon className="text-white">{this.state.fullScreenWonDialog ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Close">
                              <IconButton onClick={this.handleWonPopupClose}>
                                <Icon className="text-white">clear</Icon>
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <ValidatorForm noValidate debounceTime={500}>
                          <div className="dialog-body-no-scroll" style={{padding: '25px'}}>
                            <Grid container spacing={2}>
                               {(displayContactListWon.length > 0 || displayCompanyListWon.length > 0) &&
                                  (<>
                                    <Grid item xs={12}>
                                      Would you like to update your records to reflect a new Contact Type?
                                          </Grid>
                                    <Grid item xs={12}>
                                      {displayContactListWon}
                                      {displayCompanyListWon}
                                    </Grid>
                                  </>)
                               }
                              
                              <Grid item xs={12}>
                                Would you like to add final notes before this opportunity is archived?
                              </Grid>
                               <Grid item xs={12}>
                                <TextValidator
                                  className="w-100 input_bx"
                                  placeholder="(Optional) Add additional notes here..."
                                  onChange={this.handleChangeVals}
                                  type="text"
                                  name="optionalNoteWon"
                                  helperText="Won opportunities can be seen in the opportunities list by selecting the status filter and checking the 'Won' box."
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl className="w-100 input_bx">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={"changeDate"}
                                        id={"changeDate"}
                                        checked={this.state.changeDate}
                                        onChange={this.handleChangeVals}
                                        value={this.state.changeDate}
                                        color="primary"
                                      />
                                    }
                                    label={"Change Close Date to Today"}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                          <DialogActions className="dialog-action">
                            <Button variant="contained" onClick={this.handleWonPopupClose}>Cancel</Button>
                            <Button variant="contained" onClick={this.handleSaveWonPopup} type="submit" color="primary">
                              save
                            </Button>
                          </DialogActions>
                        </ValidatorForm>
                      </Dialog>
                )}

                {this.state.isOpenAbandonedPopup && (
                    <Dialog onClose={this.handleAbandonedPopupClose} open={this.state.isOpenAbandonedPopup} maxWidth="sm" fullWidth={true} className={`custom_dialog ${this.state.minimizeAbandonedDialog && this.state.fullScreenAbandonedDialog ? 'alignitemsend-fullscreen' : ''} ${this.state.minimizeAbandonedDialog ? 'alignitemsend' : ''} ${this.state.fullScreenAbandonedDialog ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
                        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4" style={{background:'#ffffffcc'}}>
                          <h4 className="m-0 ">Abandoned opportunity</h4>
                          <div className="d-flex">
                            <Tooltip title={this.state.minimizeAbandonedDialog ? 'Normal Screen' : 'Minimize Screen'}>
                              <IconButton onClick={this.handleAbandonedDialogMinimize}>
                                <Icon className="">{this.state.minimizeAbandonedDialog ? 'add' : 'remove'}</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={this.state.fullScreenAbandonedDialog ? 'Exit Full Screen' : 'Full Screen'}>
                              <IconButton onClick={this.handleAbandonedDialogFull}>
                                <Icon className="">{this.state.fullScreenAbandonedDialog ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Close">
                              <IconButton onClick={this.handleAbandonedPopupClose}>
                                <Icon className="">clear</Icon>
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <ValidatorForm noValidate debounceTime={500}>
                          <div className="dialog-body-no-scroll" style={{padding: '25px'}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                Would you like to add final notes before this opportunity is archived?
                              </Grid>
                              <Grid item xs={12}>
                                 <TextValidator
                                  className="w-100 input_bx"
                                  placeholder="(Optional) Add additional notes here..."
                                  onChange={this.handleChangeVals}
                                  type="text"
                                  name="optionalNoteAbandoned"
                                  helperText="Won opportunities can be seen in the opportunities list by selecting the status filter and checking the 'Won' box."
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl className="w-100 input_bx">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={"changeDate"}
                                        id={"changeDate"}
                                        checked={this.state.changeDate}
                                        onChange={this.handleChangeVals}
                                        value={this.state.changeDate}
                                        color="primary"
                                      />
                                    }
                                    label={"Change Close Date to Today"}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                          <DialogActions className="dialog-action">
                            <Button variant="contained" onClick={this.handleAbandonedPopupClose}>Cancel</Button>
                            <Button variant="contained" onClick={this.handleSaveAbandonedPopup} type="submit" color="primary">
                              save
                            </Button>
                          </DialogActions>
                        </ValidatorForm>
                      </Dialog>
                )}

                {this.state.shouldOpenNewPipelineDialog && (
                    <AddNewPipeline
                        handleClose={this.handlePipelineADDDialogClose}
                        open={this.state.shouldOpenNewPipelineDialog}
                        handleMinimize={this.handlePipelineADDDialogMinimize}
                        handleFull={this.handlePipelineADDDialogFull}
                        minimizeScreen={this.state.minimizePipelineDialog}
                        fullScreen={this.state.FullNewPipelineDialog}
                    />
                )}

                <Dialog
                    fullWidth={true}
                    maxWidth = {'xs'}
                    open={this.state.filterSaveDialoge}
                    onClose={()=> this.handleSavefilterDialogeClose()}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="delele-dialog-title"
                  >
                    <DialogTitle id="">
                        {!this.state.filterUpdateId ? "Add a New Filter":"Edit Filter"}
                    </DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <ValidatorForm instantValidate={false} ref="form">
                            <FormControl error={this.state.filternameError} className="w-100 input_bx">
                                 <InputLabel htmlFor="component-error">Name</InputLabel>
                                <Input
                                    type="text"
                                    id={'filtername'}
                                    label={'Filter Name'}
                                    className="w-100 input_bx"
                                    onChange={(event) => this.handleChange(event)}
                                    name={'filtername'}
                                    value={this.state.filtername}
                                />
                                {this.state.filternameError && <FormHelperText id="component-error-text">This field is required</FormHelperText>}
                                </FormControl>
                            <br/><br/>
                            <div>
                                <span>visibility</span>
                                <br/>
                                <RadioGroup  name="fitertype" value={this.state.fitertype} onChange={(event) => this.handleChange(event)} row>
                                    <FormControlLabel
                                      value="1"
                                      control={<Radio color="primary" />}
                                      label="Private"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      value="0"
                                      control={<Radio color="primary" />}
                                      label="Public"
                                      labelPlacement="end"
                                    />
                                </RadioGroup>
                            </div>
                            
                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        {updateSaveFilterBtnDisabled && <p className="lead-filter-error">Please select filter option to save filter.</p>}
                        <Button onClick={()=> this.handleSavefilterDialogeClose()} color="primary">
                            Cancel
                        </Button>
                        {!this.state.filterUpdateId && <Button variant="contained"  disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={()=> this.handleSavefilter()}>Save</Button>}
                        {this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={()=> this.handleUpdatefilter()}>Update</Button>}
                    </DialogActions>
                  </Dialog>
                  <Drawer
                    width={"100px"}
                    variant="temporary"
                    anchor={"right"}
                    className="drawer_right"
                    open={drawerOpen}
                    onClose={this.closeDrawerRight}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    {this.state.opportunities_id && <OpportunityProfileFrame opportunities_id={this.state.opportunities_id}  handleClose={this.closeDrawerRight} />}
                    
                </Drawer>
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{color:'white'}}/><span style={{paddingLeft: '6px',color:'white'}}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
                {this.state.shouldOpenCreateTaskDialog && (
                    <CreateNewTaskDialog
                        handleClose={this.handleTaskDialogClose}
                        open={this.state.shouldOpenCreateTaskDialog}
                        handleMinimize={this.handleTaskDialogMinimize}
                        handleFull={this.handleTaskDialogFull}
                        minimizeScreen={this.state.minimizeTaskDialog}
                        fullScreen={this.state.FullCreateTaskDialog}
                        dataFromSidebar={this.state.newTaskData}
                        taskNm={this.state.taskName}
                        taskDueDate={this.state.taskDueDate}
                    />
                )}
                <Drawer
                    width={"100px"}
                    variant="temporary"
                    anchor={"right"}
                    className="drawer_right"
                    open={drawerOpenTask}
                    onClose={this.closeDrawerRightTask}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                {this.state.task_id && <TaskProfileFrame task_id={parseInt(this.state.task_id)}  handleClose={this.closeDrawerRight} />}
                </Drawer>
            </div>
        );
    }
}


const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  opportunityReducer: state.opportunityReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  contactReducer: state.contactReducer,
  taskReducer: state.taskReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),
    getOpportunitiesFilterList: (client, request) => { dispatch(actionCreators.getOpportunitiesFilterList(client, request)) },
    getCityList: (client) => { dispatch(actionCreators.getCityList(client)) },
    getStateList: (client) => { dispatch(actionCreators.getStateList(client)) },
    getZipcodeList: (client) => { dispatch(actionCreators.getZipcodeList(client)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
    getOpportunitiesField: (client,id) => dispatch(actionCreators.getOpportunitiesField(client,id)),
    saveFilterDetailOpportunity: (client, request) => { dispatch(actionCreators.saveFilterDetailOpportunity(client, request)) },
    getFilterListOpportunity: (client,recordtypeid) => { dispatch(actionCreators.getFilterListOpportunity(client, recordtypeid)) },
    updateFilterDefaultOpportunity: (client,filterid) => { dispatch(actionCreators.updateFilterDefaultOpportunity(client, filterid)) },
    updateFilterDetailOpportunity: (client,req) => { dispatch(actionCreators.updateFilterDetailOpportunity(client, req)) },
    deleteFilterOpportunity: (client,req) => { dispatch(actionCreators.deleteFilterOpportunity(client, req)) },
    customFieldList: (client,req) => { dispatch(actionCreators.customFieldList(client,req)) },
    updateOpportunitiesDetail: (client, request) => { dispatch(actionCreators.updateOpportunitiesDetail(client, request)) },
    getActivityTypes: (client) => dispatch(actionCreators.getActivityTypes(client)),
    getLossReasonList: (client) => dispatch(actionCreators.getLossReasonList(client)),
    opportunitiesLost: (client,req) => { dispatch(actionCreators.opportunitiesLost(client,req)) },
    getOpportunitiesContactList: (client, opportunities_id) => { dispatch(actionCreators.getOpportunitiesContactList(client, opportunities_id)) },
    getOpportunitiesCompanyList: (client, opportunities_id) => { dispatch(actionCreators.getOpportunitiesCompanyList(client, opportunities_id)) },
    getContactType: (client,req) => { dispatch(actionCreators.getContactType(client,req)) },
    opportunitiesWin: (client,req) => { dispatch(actionCreators.opportunitiesWin(client,req)) },
    addFavouriteOpportunities: (client, request) => { dispatch(actionCreators.addFavouriteOpportunities(client, request)) },
    saveTaskDetail: (client, request) => { dispatch(actionCreators.saveTaskDetail(client, request)) },
    getRecordRelatedData: (client,req) => { dispatch(actionCreators.getRecordRelatedData(client,req)) },
    taskComplete: (client, request) => { dispatch(actionCreators.taskComplete(client, request)) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpportunitiesCard);