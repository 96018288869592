import React from "react";
import { Dialog, Button, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

const ConfirmationDialog = (props) => {
    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={props.open}
            onClose={props.onConfirmDialogClose}            
        >
            <DialogTitle id="simple-dialog-title">Confirm</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Are you sure to delete?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onYesClick} variant="contained" color="primary">
                    Yes
                </Button>
                <Button
                    onClick={props.onConfirmDialogClose}
                    variant="contained"
                    color="secondary"
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmationDialog;