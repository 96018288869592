export const navigations = [
  {
    name: "Dashboard",
    path: "",
    icon: "palette"
  },
  {
    name: "Leads",
    //path: "/lead/leads",    
    path: "/lead/leads-list",    
    icon: "supervised_user_circle",
  },
  {
    name: "Contact",
    // path: "/contact/contact",
    path: "/contact/contact-list",
    icon: "group",
    /*children: [
      {
        name: "Shop",
        path: "",
        iconText: "S"
      },
      {
        name: "Cart",
        path: "",
        iconText: "C"
      },
      {
        name: "Checkout",
        path: "",
        iconText: "CO"
      },
    ]*/
  },
  {
    name: "Company",
    path: "/company/company-list",    
    //path: "/company/company",    
    icon: "supervised_user_circle",
  },
  {
    name: "Opportunities",
    //path: "/opportunities/opportunities",
    path: "/opportunities/opportunities-card",
    icon: "business"
  },
  {
    name: "Projects",
    //path: "/project/projects-list",
    //path: "/project/projects",
    path: "/project/projects-card",
    icon: "business_center",
  },
  {
    name: "Task",
    path: "/task/task-list",     
    icon: "list",
  },
  {
    name: "Reports",
    path: "",
    icon: "flag",
  }
];
