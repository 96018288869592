import React, { Component } from "react";
import CompanyTableComponent from './CompanyTableComponent';
import CompanyCreationRule from './CompanyCreationRule'
import { Icon, Switch, FormControlLabel, IconButton, withStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'
import CreateNewFieldCompanyDialog from "./CreateNewFieldCompanyDialog";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class SettingCompany extends Component {

    constructor(props) {
        super(props);

        this.state = {
            companydata: [],
            companyFieldIds: [],
            companyOrderIds: [],
            activecompanydatas: [],
            inactivecompanydatas: [],
            shouldOpenCreateFieldCompanyDialog: false,
        };
    }

    handleChange = (event) => {
        let val = {
            typeId: 3,
            fieldstatus: event.target.checked,
            generatedfieldid: event.target.value
        }

        this.props.updateCustomFieldStatus(this.props.apolloClient.client, val);
    }

    componentDidMount() {
        let val = { typeId: 3 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.customFieldTypeList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.companyCustomFieldList.data && props.settingReducer.customFieldTypeList.data) {
            const customFields = props.settingReducer.companyCustomFieldList.data;
            
            let activecompanydatas = [];
            let inactivecompanydatas = [];
            let companyFieldIds = [];
            let companyOrderIds = [];

            customFields.map((fieldData, index) => {
                if (fieldData.fieldstatus === true) {
                    companyFieldIds.push(parseFloat(fieldData.id));
                    companyOrderIds.push(parseFloat(fieldData.orderno));

                    let fieldlicon = (fieldData.fieldicon === 'fa-dollar-sign') ? 'fa-usd' : fieldData.fieldicon;

                    activecompanydatas.push({
                        drag: <Link className="drag-handle" companyOrderIds={companyOrderIds} to="#"><Icon>drag_handle</Icon></Link>,
                        key: fieldData.id,
                        name: (
                            <div>
                                <FontAwesome className={'crm-fontawsome-icon ' + fieldlicon} ></FontAwesome>
                                <label className="crm-custom-field-label">{fieldData.fieldlabel}</label>
                            </div>
                        ),
                        fieldType: fieldData.fieldtype.name,
                        creationRule: (fieldData.recordcreationrules_editable) ?
                            <CompanyCreationRule dropName="create" fieldId={index} options={fieldData.recordcreationrulesvalue} disabled={false} defaultChecked={fieldData.recordcreationdefaultvalue} />
                            :
                            <CompanyCreationRule dropName="create" fieldId={index} options={fieldData.recordcreationrulesvalue} disabled={true} defaultChecked={fieldData.recordcreationdefaultvalue} />,
                        editRule: (fieldData.recordeditrules_editable) ?
                            <CompanyCreationRule dropName="edit" fieldId={index} options={fieldData.recordeditrulesvalue} disabled={false} defaultChecked={fieldData.recordeditdefaultvalue} />
                            :
                            <CompanyCreationRule dropName="edit" fieldId={index} options={fieldData.recordeditrulesvalue} disabled={true} defaultChecked={fieldData.recordeditdefaultvalue} />,
                        action: <React.Fragment>

                            <FormControlLabel
                                value={fieldData.generatedfieldid}
                                control={(fieldData.fieldstatus) ? <Switch onChange={this.handleChange} color="primary" defaultChecked disabled={!fieldData.isdiactivated} /> : <Switch onChange={this.handleChange} color="primary" disabled={!fieldData.isdiactivated} />}
                                labelPlacement="start"
                                className="ml-0 mr-10"
                            />
                        </React.Fragment>
                    })
                }

                if (fieldData.fieldstatus === false) {
                    inactivecompanydatas.push({
                        key: fieldData.id,
                        name: fieldData.fieldlabel,
                        action: <React.Fragment>
                            <FormControlLabel
                                value={fieldData.generatedfieldid}
                                control={(fieldData.fieldstatus) ? <Switch onChange={this.handleChange} color="primary" defaultChecked disabled={!fieldData.isdiactivated} /> : <Switch onChange={this.handleChange} color="primary" disabled={!fieldData.isdiactivated} />}
                                labelPlacement="start"
                                className="ml-0 mr-10"
                            />
                        </React.Fragment>
                    })
                }
            })

            this.setState({ activecompanydatas: activecompanydatas, inactivecompanydatas: inactivecompanydatas, companyFieldIds: companyFieldIds, companyOrderIds: companyOrderIds }, () => this.forceUpdate());
        }
    }
    closePopUp = () => {
        this.setState({ shouldOpenCreateFieldCompanyDialog: false });
    }
    updateFieldsOrder = (fromIndex, toIndex) => {

        let companyFieldIds = [];

        const companydata = this.state.activecompanydatas;
        const item = companydata.splice(fromIndex, 1)[0];

        companydata.splice(toIndex, 0, item);

        this.setState({
            companydata
        })
        const companyOrderIds = this.state.companyOrderIds;
        companydata.map((value, index) => {
            companyFieldIds.push(parseFloat(value.key));
        });

        let val1 = {
            typeId: 3,
            orderno: companyOrderIds,
            fieldid: companyFieldIds
        }
        this.props.updateCustomFieldOrder(this.props.apolloClient.client, val1);
    }
    handleContactDialogClose = () => {
        this.setState({ shouldOpenCreateFieldCompanyDialog: false });
    };
    render() {
        let { shouldOpenCreateFieldCompanyDialog } = this.state;
        return (
            <>
                <div className="d-flex justify-content-end mb-16">
                    <Button variant="contained" color="primary" onClick={() => this.setState({ shouldOpenCreateFieldCompanyDialog: true })} size="small">Add field</Button>
                    {shouldOpenCreateFieldCompanyDialog && (
                        <CreateNewFieldCompanyDialog
                            handleClose={this.handleContactDialogClose}
                            open={shouldOpenCreateFieldCompanyDialog}
                            allData={this}
                        />
                    )}
                </div>
                <CompanyTableComponent activecompanydata={this.state.activecompanydatas} inActivecompanydata={this.state.inactivecompanydatas} allData={this} />
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        customFieldTypeList: (client) => { dispatch(actions.customFieldTypeList(client)) },
        updateCustomFieldStatus: (client, request) => { dispatch(actions.updateCustomFieldStatus(client, request)) },
        updateCustomFieldOrder: (client, request) => { dispatch(actions.updateCustomFieldOrder(client, request)) },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SettingCompany);
