import React, { Component } from "react";
import {Fab, Grid, withStyles,Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import TaskProfileFrame from "../Tasks/TaskProfileFrame";
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink,withRouter } from "react-router-dom";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import moment from 'moment';
import DropdownDefault from "../Layout/DropdownDefault";
const formatCreateLabel = (value) => `Create "${value}"`;
const isValidNewOption = (value) => value != "";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);
const filter = createFilterOptions();


class Task extends Component {
  state = {
        attachment: null,
        drawerOpenFile: false,
        showAddFiles: false,
        file_id: "",
        fileListArray: [],
        filterdata:[],
        removeFilePopup: false,
        shouldOpenCreateTaskDialog: false,
        FullCreateTaskDialog: false,
        minimizeTaskDialog: false,
        type: "files",
        recordtype: "",
        masterActivityTypesList: [],
        dateformat: 'MM/DD/YYYY',
        timeZone: 'America/New_York',
        newTaskData: {},
    };

    componentDidMount() {
        if(this.props.recordId){
            this.setState({
                recordId:this.props.recordId,
                recordtype:this.props.recordtype,
            },()=>{
                this.forceUpdate()
                //this.props.getOpportunitiesCompanyList(this.props.apolloClient.client, this.state.recordId);
                var req ={
                  id:this.props.recordId,
                  type:this.state.type,
                  recordtype:this.props.recordtype,
                }
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;

            this.setState({ dateformat: format, timeZone: timezone })
        }
        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count  = dataList.filter(function(element) {
                if(element.is_disable == true){
                  return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }
        if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
            this.setState({
                recordId:props.recordId,
                recordtype:props.recordtype,
            },()=>{
                this.forceUpdate()
                //this.props.getOpportunitiesCompanyList(this.props.apolloClient.client, this.state.recordId);
                var req ={
                  id:props.recordId,
                  type:this.state.type,
                  recordtype:props.recordtype,
                }
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
       
        if (props.settingReducer.relatedDataFile.data) {
            props.settingReducer.relatedDataFile.is_loaded = false
            this.setState({
                fileListArray:props.settingReducer.relatedDataFile.data.data,
            },()=>this.forceUpdate());
        }
    }

  toggleFiles = () => {
      this.setState(oldStateProject => ({ isOpenedFiles: !oldStateProject.isOpenedFiles}));
      this.setState({
          showAddFiles: true
      },()=>this.forceUpdate());
      
  }

    
    handleRemoveTask = (file_id) => {
          // let deleteTaskParams = [];
          // deleteTaskParams['deleteid'] = { taskid: this.state.deleteIds };
          // this.props.deleteTask(this.props.apolloClient.client, deleteTaskParams);
          this.setState({
              removeFilePopup: true,
              fileId: file_id,
          }, () => {
              this.forceUpdate()
          })
        
    };
    toggleRemoveFilePopup = () => {
          if(this.state.removeFilePopup){
            this.setState({
                fileId: "",
            }, () => {
                this.forceUpdate()
            })

          }
          this.setState({
                removeFilePopup: !this.state.removeFilePopup,
          }, () => {
              this.forceUpdate()
          })
        
    };
    confirmremoveFilePopup = () => {
          console.log("this.state.fileId", this.state.fileId);
        if(this.state.fileId){
          var data = {
            recordtype:this.state.recordtype,
            type:this.state.type,
            id:parseInt(this.state.recordId),
          }

          var req = {
            fileid:parseInt(this.state.fileId),
            getParams:data,
          }
          this.props.deleteRelatedFiles(this.props.apolloClient.client, req);

          this.setState({
              fileId: "",
              removeFilePopup: false,
          }, () => {
              this.forceUpdate()
          })
        }
        
    };

  findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
  }

  handleAttachmentSelection = (event) => {
    var recortypedid = ""
    if(event.target.files[0] == undefined){
        return false
    }
    if(this.state.recordtype == "lead"){
      recortypedid = 1
    }
    if(this.state.recordtype == "contact"){
      recortypedid = 2
    }
    if(this.state.recordtype == "company"){
      recortypedid = 3
    }
    if(this.state.recordtype == "opportunities"){
      recortypedid = 4
    }
    if(this.state.recordtype == "project"){
      recortypedid = 5
    }
    
    var data = {
      recordtype:this.state.recordtype,
      type:this.state.type,
      id:parseInt(this.state.recordId),
    }

    var req = {
      recordid:parseInt(this.state.recordId),
      recortypedid:recortypedid,
      fileInput:event.target.files[0],
      getParams:data,
    }
    this.props.saveRelatedFiles(this.props.apolloClient.client, req);
    // recordid: Float!
    // recortypedid: Float!
    // fileInput: Upload!
  };

  render() {
    let { drawerOpenFile,isOpenedFiles } = this.state;
    /*STRAT project Display*/
        let displayFileList = []
        {
          this.state.fileListArray.map((item, key) => {
            var date = moment.unix(item.createddate).tz(this.state.timeZone).format(this.state.dateformat)
            if(item){
                var FileName = item.filename
                var url = item.filekey
                displayFileList.push(
                         <div className="box_portlet mt-10 crm-calendar-event">
                                <div className="box_body px-10 py-20">
                                    <div className="lead_pl" style={{paddingLeft: '50px'}}>
                                        <div className="icon_left">
                                            <Fab
                                                  size="small"
                                                  aria-label="Task"
                                                  className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                                              >
                                                <Icon className="activity_icon_normal">attachment</Icon>
                                              </Fab>
                                        </div>
                                        <a href={url} target="_blank" className={"cursor_poiter"}>
                                        <Grid container justify="space-between">
                                            <h6 className="m-0" style={{width:'50%'}}>{FileName}</h6>
                                        </Grid>
                                        </a>
                                        <span className="related-text">{"Added by"} {item.__user__.name} on {date}</span>
                                        <div className="hover_show" style={{top:'-21px',right:'-7px'}}>
                                            <IconButton aria-label="Delete" size="small" onClick={()=>this.handleRemoveTask(item.id)}>
                                                <IconSmall style={{fontSize:'18px'}}>close</IconSmall>
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                         </div>
                         
                )
            }
            
          })
        }
        /*END project Display*/
    
    return (
      <Card elevation={3} className="p-16 mt-16" style={{overflow: 'initial'}}>
        <Grid container justify="space-between">
            <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleFiles}>Files ({displayFileList.length}) <Icon className="vert-middle">{isOpenedFiles ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
            <DropdownDefault
                menuButton={
                    <IconButton
                        aria-haspopup="true"
                        aria-label="Add" size="small"
                    >
                        <Icon>add</Icon>
                    </IconButton>
                }
            >
                <MenuItem onClick={()=>this.fileInput.click()} >Upload From my computer</MenuItem>
                <MenuItem>Link to Google Drive</MenuItem>
                <MenuItem>Link to Dropbox</MenuItem>
            </DropdownDefault>
            <input style={{display:'none'}} type="file" onChange={this.handleAttachmentSelection}
                        ref={fileInput => this.fileInput = fileInput}
            />
        </Grid>
        {isOpenedFiles &&
          <>
              {(displayFileList.length == 0) && 
                <div className="mt-10">
                  <label htmlFor="attachment">
                    <IconButton onClick={()=>this.fileInput.click()} className="mr-8" component="span">
                      <Icon>attachment</Icon>
                    </IconButton>
                    add attachment
                  </label>
                </div>
              }
              {displayFileList}

          </>
        }

      <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.removeFilePopup}
          onClose={this.toggleRemoveFilePopup}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="ar-remove-dialog-title"
      >
          <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the associated file ?"}</DialogTitle>
          <DialogActions>
              <Button onClick={this.toggleRemoveFilePopup} color="primary">
                  Cancel
                            </Button>
              <Button type="Button" onClick={() => this.confirmremoveFilePopup()} className="btn_Primary">
                  Ok
                            </Button>

          </DialogActions>
      </Dialog>
                        
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  taskReducer: state.taskReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    getRecordRelatedData: (client,req) => { dispatch(actionCreators.getRecordRelatedData(client,req)) },
    saveRelatedFiles: (client,req) => { dispatch(actionCreators.saveRelatedFiles(client,req)) },
    deleteRelatedFiles: (client,req) => { dispatch(actionCreators.deleteRelatedFiles(client,req)) },
  }
}


export default (connect(mapStateToProps,mapDispatchToProps)(withRouter(Task)));
