import React, { Component } from "react";
import {  IconButton, Icon, InputLabel, Grid, Button,List, ListItem,
ListItemText, ListItemIcon, ListItemSecondaryAction,FormControl ,Select, MenuItem,Card,Typography,
Dialog,DialogActions,DialogTitle
} from "@material-ui/core";
import "date-fns";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@material-ui/icons/Edit";
import AddUpdateContactType from "./AddUpdateContactType"


// reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((value, index) => {
        result[index].order_no = index + 1;
    })
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

const getListStyle = isDraggingOver => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

class ContactTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ContactTypesList: [],
            openAddUpdateContactTypeDialoge: false,
            FullContactTypeDialog: false,
            minimizeContactTypeDialog: false,
            ContactTypeData: {},
            isDeleteConfirmDialoOpen: false,
            deleteItemName: '',
            deleteData: '',
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    handleContactDialogClose = () => {
        this.setState({ openAddUpdateContactTypeDialoge: false });
    };
    handleContactDialogMinimize = () => {
        this.setState(oldStateminimizecontactDialog => ({ minimizeContactTypeDialog: !oldStateminimizecontactDialog.minimizeContactTypeDialog }));
        this.setState({FullContactTypeDialog: false})
    };
    handleContactDialogFull = () => {
        this.setState(oldStateFullCreateContactDialog => ({ FullContactTypeDialog: !oldStateFullCreateContactDialog.FullContactTypeDialog }));
        this.setState({minimizeContactTypeDialog: false})

    };
    componentDidMount() {
        this.props.getContactType(this.props.apolloClient.client);
    }
    componentWillReceiveProps(props){

        if (props.contactReducer.contactTypeList && props.contactReducer.contactTypeList.data != null)
        {
            this.setState({ContactTypesList:props.contactReducer.contactTypeList.data});            
        }   
    }

    onDragEnd(result) {
        if (!result.destination) {
          return;
        }

        const items = reorder(
          this.state.ContactTypesList,
          result.source.index,
          result.destination.index
        );

        this.setState({ContactTypesList : items});
        
        var idsArray = [];
        var sortOrderArray = [];
        items.map((value, index) => {
            idsArray.push(parseInt(value.id));
            sortOrderArray.push(value.order_no);
        })
        
        let req = {
            contactTypeIds:idsArray,
            contactTypeOrder:sortOrderArray,
        }
        this.props.updateContactTypeOrder(this.props.apolloClient.client,req);
        this.props.contactReducer.contactTypeList.data = items;
    }
    handleAddUpdate = (data) => {

        let info = {}
        if(data && data !=undefined){
            info.name = data.name
            info.id = data.id
            info.isEdit = true
        }else{
            info.isEdit = false
        }
        this.setState({openAddUpdateContactTypeDialoge:false},()=>{
            this.setState({ContactTypeData:info,openAddUpdateContactTypeDialoge:true});
        });
        
    };
    handleDeleteContact = (data) => {
        this.setState({deleteItemName:data.name});
        this.toggleDeleteDialog(data)
    };

    toggleDeleteDialog = (data) => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen,deleteData:data});
    };
    confirmDeleteContactType = () => {
        let req = {
            contacttypeid:parseInt(this.state.deleteData.id),
        }
        this.props.deleteContactType(this.props.apolloClient.client,req);
        this.toggleDeleteDialog()
    };

    findWithAttr = (array, attr, value) => {
        for(var i = 0; i < array.length; i += 1) {
            if(array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    render() {
        return (
            <div>
                <Typography className="card-margin-leadstatus" variant="h5" color="inherit">Contact Types</Typography>
                <InputLabel>Create and customize Contact Types to separate Contacts into different groups.</InputLabel>
                <Card className="signup-card">
                    <div className="lead_upcoming">
                        <div className="box_portlet">
                            <div className="box_header">
                                <div className="box_label"><h3 className="box_title"></h3></div>
                                <div className="box_tools">
                                    <Button variant="contained" color="secondary" onClick={() => this.handleAddUpdate()} size="small" style={{ minWidth: 95 }}>
                                        Add Contact Types
                                    </Button>
                                    {this.state.openAddUpdateContactTypeDialoge && (
                                        <AddUpdateContactType
                                            handleClose={()=>this.handleContactDialogClose()}
                                            open={this.state.openAddUpdateContactTypeDialoge}
                                            handleMinimize={this.handleContactDialogMinimize}
                                            handleFull={this.handleContactDialogFull}
                                            minimizeScreen={this.state.minimizeContactTypeDialog}
                                            fullScreen={this.state.FullContactTypeDialog}
                                            ContactTypeData={this.state.ContactTypeData}
                                        />
                                    )} 
                                </div>
                            </div>
                            <div className="box_body">
                                 <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                      {(provided, snapshot) => (
                                        <RootRef rootRef={provided.innerRef}>
                                                <List style={getListStyle(snapshot.isDraggingOver)} className="crm-lead-status-list-ul">
                                            {this.state.ContactTypesList && this.state.ContactTypesList.map((item, index) => (
                                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <ListItem
                                                        ContainerComponent="li"
                                                        ContainerProps={{ ref: provided.innerRef }}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                          snapshot.isDragging,
                                                          provided.draggableProps.style
                                                        )}
                                                      >
                                                    <ListItemIcon> <Icon>drag_handle</Icon> </ListItemIcon>
                                                    <ListItemText primary={item.name}/>
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={() => this.handleAddUpdate(item)} ><EditIcon /></IconButton>
                                                        {item.is_allowto_delete && <IconButton onClick={()=>this.handleDeleteContact(item)}><Icon>delete</Icon></IconButton>}
                                                        {!item.is_allowto_delete && !item.is_allowto_edit && <IconButton><Icon>lock</Icon></IconButton>}
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                )}
                                              </Draggable>
                                            ))}
                                            {provided.placeholder}
                                          </List>
                                        </RootRef>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                            </div>
                            <Dialog
                                  fullWidth={true}
                                  maxWidth = {'xs'}
                                  open={this.state.isDeleteConfirmDialoOpen}
                                  onClose={this.toggleDeleteDialog}
                                  disableBackdropClick
                                  disableEscapeKeyDown
                                  aria-labelledby="ar-remove-dialog-title"
                                >
                                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove "} {this.state.deleteItemName} {"?"}</DialogTitle>
                                    <DialogActions>
                                      <Button  onClick={this.toggleDeleteDialog} color="primary">
                                          Cancel
                                      </Button>
                                      <Button  type="Button" onClick={() =>this.confirmDeleteContactType()}  className="btn_Primary">
                                        Ok
                                      </Button>
                                       
                                    </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    contactReducer: state.contactReducer,
    apolloClient: state.apolloClient
});

const mapDispatchToProps = dispatch => {
    return {
        getContactType: (client) => dispatch(actionCreators.getContactType(client)),
        updateContactTypeOrder: (client,req) => dispatch(actionCreators.updateContactTypeOrder(client,req)),
        deleteContactType: (client,req) => dispatch(actionCreators.deleteContactType(client,req)),
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ContactTypes));
