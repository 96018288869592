import React, { Component } from "react";
import PropTypes from 'prop-types';
import { IconButton, Checkbox, List, ListItem, Icon, Grid, withStyles, Menu, InputLabel,Tooltip, Typography, MenuItem, Avatar, Fab, Button, Tabs, Tab, Box, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import DropdownDefault from "../Layout/DropdownDefault";
import NoteDialog from "./NoteDialog";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import ProfileDetails from "./ProfileDetails";
import ProfileRight from "./ProfileRight";
import moment from 'moment';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import CreatelogDialog from "./CreatelogDialog";
import ProfileEmailMenu from "./ProfileEmailMenu";
import LeadInboxCompose from "./LeadInboxCompose";
import ViewHtmlDialog from "./ViewHtmlDialog";
//import ConvertLeadDialog from './convertLeadDialog'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import FontAwesome from "react-fontawesome";
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";

const IconButtonWhite = withStyles(theme => ({
  root: {
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "transparent",
    color: "#F2BF58",
    padding: "5px"
  }
}))(IconButton);

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.3rem",
  }
}))(Icon);

const IconSmaller = withStyles(() => ({
  root: {
    fontSize: "1.0rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}


class EditCommentMenu extends Component {
  state = {
    commentid: '',
    Commentoptions: '',
    showCommentoptions: false,
  };

  handleClick = (event) => {
    this.setState({ Commentoptions: event.target, showCommentoptions: true });
  }
  handleCloseOptions = (event) => {
    this.setState({ Commentoptions: null, showCommentoptions: false });
  }
  handleEditComment = () => {
    this.handleCloseOptions();
    this.props.allData.handleEditCommentMain(this.props.cmt.id, this.props.cmt.comment);
  }
  deletecomment = () => {
    this.handleCloseOptions();
    this.props.allData.deleteComment(this.props.cmt.id, this.props.type);
  }

  render() {
    return (
      <Grid item className="ml-auto">
        <IconButton size="small"
          aria-label="More"
          aria-owns={this.state.showCommentoptions ? "long-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className="button_size"
        >
          <Icon>more_vert</Icon>
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state.Commentoptions}
          open={this.state.showCommentoptions}
          onClose={this.handleCloseOptions}
          PaperProps={{
            style: {
              width: 200
            }
          }}
        >
          <MenuItem onClick={this.handleEditComment}>
            <IconButton aria-label="Delete" size="small" className="hover_show  mr-10">
              <IconSmall>edit</IconSmall>
            </IconButton> Edit Comment
                    </MenuItem>
          <MenuItem onClick={() => this.deletecomment()}>
            <IconButton aria-label="Delete" size="small" className="hover_show  mr-10">
              <IconSmall>delete</IconSmall>
            </IconButton> Delete Comment
                    </MenuItem>
        </Menu>
      </Grid>
    )
  }
}
class FiltersMenu extends Component {
  state = {
    commentid: '',
    Commentoptions: '',
    showCommentoptions: false,
    activities: [],
    typeArray: [],
    activityArray: [],
    activityflag: true,
  };

  componentDidMount() {

    let typeArray1 = this.state.typeArray;
    let activityArray1 = this.state.activityArray;

    typeArray1 = [1, 2, 3, 4, 20, 21, 5, 100];

    this.setState({ typeArray: typeArray1 });
    this.props.thisData.setState({ logtypeid: typeArray1 });
  }
  componentWillReceiveProps(props) {

    if (props.proVar && props.proVar.length > 0) {
      this.setState({ activities: props.proVar });
      if (this.state.activityflag) {
        let activityArray1 = this.state.activityArray;
        let activities = props.proVar;

        if (activities && activities.length > 0) {
          activities.map((activity) => {
            if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
              activityArray1.push(parseFloat(activity.id));
            }
          })
          this.setState({ activityArray: activityArray1, activityflag: false });
          props.thisData.setState({ activitypeid: activityArray1 });
        }
      }
    }
  }

  

  handleClick = (event) => {
    this.setState({ Commentoptions: event.target, showCommentoptions: true });
  }

  handleCloseOptions = (event) => {
    this.setState({ Commentoptions: null, showCommentoptions: false });
  }

  handleMenuClick = (e, value, type) => {
    let typeArray1 = this.state.typeArray;
    let activityArray1 = this.state.activityArray;
    var promise = new Promise((resolve, reject) => {
      if (e.target.checked == true) {
        if (type == 0) {

          if (activityArray1.indexOf(value) == -1)
            activityArray1.push(value);

        } else if (type == 1000) {

          typeArray1 = [1, 2, 3, 4,20,21, 5, 100];

          let activities = this.state.activities;

          if (activities && activities.length > 0) {
            activities.map((activity) => {
              if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
                activityArray1.push(parseFloat(activity.id));
              }
            })
          }

        } else if (type == 1001) {

          let pusharr = [1, 2, 3, 4,20,21];
          pusharr.map((arr) => {
            if (typeArray1.indexOf(arr) == -1) {
              typeArray1.push(arr);
            }
          })

        }
        else {

          if (typeArray1.indexOf(type) == -1)
            typeArray1.push(type);

        }
      }
      if (e.target.checked == false) {
        if (type == 0) {

          let key1 = activityArray1.indexOf(value);
          activityArray1.splice(key1, 1);

        } else if (type == 1000) {

          typeArray1 = [];
          activityArray1 = [];

        } else if (type == 1001) {

          let pullarr = [1, 2, 3, 4,20,21];
          pullarr.map((arr) => {
            if (typeArray1.indexOf(arr) != -1) {
              let ind = typeArray1.indexOf(arr);
              typeArray1.splice(ind, 1);
            }
          })

        } else {
          let key = typeArray1.indexOf(type);
          typeArray1.splice(key, 1);
        }
      }
      resolve(1)
    })

    promise.then(result => {
      this.setState({ typeArray: typeArray1, activityArray: activityArray1 });
      this.props.thisData.setState({ logtypeid: typeArray1, activitypeid: activityArray1 });
      this.props.thisData.handlefiltermain(typeArray1, activityArray1);
    });
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  render() {
    let { activities, activityArray, typeArray } = this.state;
    let def_typearr = [1, 2, 3, 4,20,21, 5, 100];
    let flag_all = true;

    if (activities && activities.length > 0) {
      activities.map((act) => {
        if (activityArray.indexOf(parseFloat(act.id)) == -1)
          flag_all = false;
      })
    } else {
      flag_all = false;
    }

    def_typearr.map((def) => {
      if (typeArray.indexOf(def) == -1)
        flag_all = false;
    })

    return (
      <>
        <Grid container justify="space-between">
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <List className="p-0 pull-right mr-20 log_filter_list">
              <ListItem
                aria-label="More"
                aria-owns={this.state.showCommentoptions ? "filters_menu" : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
                className="font-size-14 cursor_poiter  pr-0"
              >
                Filters <Icon>arrow_drop_down</Icon>
              </ListItem>
              <Menu
                                className=""
                                id="long-menu"
                                anchorEl={this.state.Commentoptions}
                                open={this.state.showCommentoptions}
                                onClose={this.handleCloseOptions}
                                PaperProps={{
                                    style: {
                                        width: 200
                                    }
                                }}
                            >
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16 text-muted">storage</Icon><Tooltip title='All Activities'>
 <div className="text_ellipsis " style={{maxWidth:'72px',fontSize:'14px',margin:'3px 0px 0px 0px',fontWeight:'400'}}>All Activities</div></Tooltip> 
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter "}
                                        name={'allActivities'}
                                        id={'filterCheck'}
                                        checked={flag_all}
                                        onChange={(event, value) => this.handleMenuClick(event, 1000, 1000)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                {(activities && activities.length > 0) && activities.map((activity) => (
                                    <MenuItem className="filter_items">
                                        <FontAwesome className={activity.icon + " mr-16"} style={{ fontSize: '15px', padding: '5px' }} /><Tooltip title={activity.name}>
 <div className="text_ellipsis " style={{maxWidth:'72px',fontSize:'14px',margin:'3px 0px 0px 0px',fontWeight:'400'}}>{activity.name}</div></Tooltip>
                                        <Checkbox color="primary"
                                            className={"crm_log_checkbox_filter "}
                                            disabled={false}
                                            name={activity.name}
                                            id={'filterCheck'}
                                            checked={(activityArray.indexOf(parseFloat(activity.id)) != -1) ? true : false}
                                            onChange={(event, value) => this.handleMenuClick(event, parseFloat(activity.id), 0)}
                                            value={this.state.filterCheck}
                                            color="primary"
                                        />
                                    </MenuItem>
                                ))}
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16 text-muted">subject</Icon><Tooltip title='Notes'>
 <div className="text_ellipsis " style={{maxWidth:'72px',fontSize:'14px',margin:'3px 0px 0px 0px',fontWeight:'400'}}>Notes</div></Tooltip> 
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter "}
                                        name={"note"}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(5) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 5, 5)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16 text-muted">email</Icon><Tooltip title='Emails'>
 <div className="text_ellipsis " style={{maxWidth:'72px',fontSize:'14px',margin:'3px 0px 0px 0px',fontWeight:'400'}}>Emails</div></Tooltip> 
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter "}
                                        name={'email'}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(100) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 100, 100)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16 text-muted">storage</Icon> <Tooltip title='System Updates'>
 <div className="text_ellipsis " style={{maxWidth:'72px',fontSize:'14px',margin:'3px 0px 0px 0px',fontWeight:'400'}}>System Updates</div></Tooltip>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter "}
                                        name={'sysupdate'}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(1) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 1001, 1001)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                            </Menu>
            </List>
          </Grid>
        </Grid>
      </>
    )
  }
}

class ProjectProfileFrame extends Component {
  state = {
    logtypeid: [],
    activitypeid: [],
    activities: [],

    isOpenedCommentIds: [],
    viewHtmlData: '',
    shouldOpenViewEmailDialog: false,
    FullViewEmailDialog: false,
    minimizeViewEmailDialog: false,
    Downloadoptions: '',
    showDownloadoptions: false,
    isOpenedEmail: false,
    isOpenedEmailIds: [],
    isOpenedReplayEmail: false,
    isEditCommentLog: false,
    Commentoptions: '',
    showCommentoptions: false,
    logvaluetype: '',
    logtype: 0,
    selectedDate: new Date(),
    textvalue: '',
    shouldOpenCreateLogDialog: false,
    FullConvertProjectDialog: false,
    minimizeConvertProjectDialog: false,
    isDeleteLogConfirmDialoOpen: false,
    isEditLog: false,
    logid: '',
    shouldOpenNoteDialog: false,
    TabValue: 0,
    allLogs: [],
    firstname: "",
    lastname: "",
    isDeleteConfirmDialoOpen: false,
    title: "",
    companyName: "",
    colorClass: '',
    interaction: 0,
    lastcontacted: "--",
    inactivedays: 0,
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
    isfavorite: false,
    star_icon: 'star_border',
    fieldsObj: {},
    fieldsList: [],
    usersListArray: [],
    projectImage: "",
    countryListArray: [],
    systemTagsList: [],
    companyCurrencyData: [],
    userSettings: "",
    shouldOpenConvertProjectDialog: false,
    minimizeConvertProjectDialog: false,
    FullConvertProjectDialog: false,
    relatedListArray: [],

    shouldOpenCreateTaskDialog: false,
    FullCreateTaskDialog: false,
    minimizeTaskDialog: false,
    newTaskData:{},
  };

  componentDidMount() {
    this.props.getRelatedList(this.props.apolloClient.client);
    let val = { typeId: 5 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.customFieldList(this.props.apolloClient.client, val);
    //this.props.getCountryList(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getActivityTypes(this.props.apolloClient.client);
    
    if (this.props.project_id) {
        this.setState({
          project_id: this.props.project_id,
        }, () => this.forceUpdate());  

        let mailid = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let logParams = { projectid: parseFloat(this.props.project_id), emailid: mailid, activitypeid: [], logtypeid: [] }
        this.props.getProjectActivityLogList(this.props.apolloClient.client, logParams);
    }
  }
  componentWillUnmount() {
    // emailLogEmail
    localStorage.removeItem('emailLogEmail');
    this.props.projectReducer.projectFieldData = [];
    this.props.projectReducer.projectLogList = [];
    this.props.projectReducer.projectLogSidebarList = [];
  }
  
  componentWillReceiveProps(props) {
    if (props.settingReducer.relatedListArray.data) {
      props.settingReducer.relatedListArray.is_loaded = false
      const relatedData = props.settingReducer.relatedListArray.data;
      var NewArray = []
      NewArray = NewArray.concat(relatedData.lead); 
      NewArray = NewArray.concat(relatedData.contact); 
      NewArray = NewArray.concat(relatedData.company); 
      NewArray = NewArray.concat(relatedData.opportunities); 
      this.setState({ relatedListArray: NewArray})
    }
    if (props.settingReducer.masterActivityTypesList) {
      this.setState({ activities: props.settingReducer.masterActivityTypesList.data });
    }

    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }
    if (props.settingReducer.companyCurrencyData.data != null && this.state.companyCurrencyData.length == 0) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }
    if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
    }
    if (props.settingReducer.projectCustomFieldList.data && this.state.fieldsList.length == 0) {
      const customFields = props.settingReducer.projectCustomFieldList.data;
      this.setState({ fieldsList: customFields })
      var statuskey = this.findWithAttr(customFields, 'id', "70");
      var defaultKeyStatus = this.findWithAttr(customFields[statuskey].options, 'is_default', true);
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["status"]: { fieldid: "70", values: customFields[statuskey].options[defaultKeyStatus] }
        }
      })
    }

    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      this.setState({ dateformat: format, timeZone: timezone })
    }
   

    if (props.project_id !== undefined) {
      
      this.setState({
        project_id: props.project_id,
      }, () => this.forceUpdate());
      
      if (props.project_id != this.state.project_id)
      {
        // console.log(props.project_id,'props.project_id');
        
        let mailid = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let logParams = { projectid: parseFloat(props.project_id), emailid: mailid, activitypeid: [], logtypeid:[] }
        
        
        this.props.getProjectActivityLogList(this.props.apolloClient.client, logParams);
      }
    }

    if (props.projectReducer.projectFieldData.data) {
      var fielddata = props.projectReducer.projectFieldData.data;

      let star_ic = fielddata[0].leadfavourite ? 'star' : 'star_border';
      this.setState({ star_icon: star_ic, isfavorite: fielddata[0].leadfavourite });

      if (fielddata[0].projectdetail && fielddata[0].projectdetail.length > 0 && fielddata[0].projectdetail[0].project) {
        this.setState({
          interaction: fielddata[0].projectdetail[0].project.interactions ? fielddata[0].projectdetail[0].project.interactions : 0,
          lastcontacted: fielddata[0].projectdetail[0].project.lastcontacted ? moment.unix(fielddata[0].projectdetail[0].project.lastcontacted).tz(this.state.timeZone).format(this.state.dateformat) : "--",
          inactivedays: fielddata[0].projectdetail[0].project.inactivedays ? fielddata[0].projectdetail[0].project.inactivedays : 0,
          projectImage: fielddata[0].image,
        })
      }

      
      if (props.projectReducer.projectLogSidebarList && props.projectReducer.projectLogSidebarList.data) {
        let logdata = [];

        let pined = props.projectReducer.projectLogSidebarList.data.pined;
        let today = props.projectReducer.projectLogSidebarList.data.today;
        let yesterday = props.projectReducer.projectLogSidebarList.data.yesterday;
        let thisweek = props.projectReducer.projectLogSidebarList.data.thisweek;
        let lastweek = props.projectReducer.projectLogSidebarList.data.lastweek;
        let thismonth = props.projectReducer.projectLogSidebarList.data.thismonth;
        let lastmonth = props.projectReducer.projectLogSidebarList.data.lastmonth;
        let earlier = props.projectReducer.projectLogSidebarList.data.earlier;
        
        let source = '';
        if (props.projectReducer.projectFieldData.data) {
          let fieldSource = props.projectReducer.projectFieldData.data;
          var sourcekey = this.findWithAttr(fieldSource, 'fieldkey', 'name');

          if (fieldSource[sourcekey].projectdetail && fieldSource[sourcekey].projectdetail[0].fieldvalue)
            source = fieldSource[sourcekey].projectdetail[0].fieldvalue;
        }

        logdata.push(this.createLogArray(source, pined, 'Pinned'));
        logdata.push(this.createLogArray(source, today, 'Today'));
        logdata.push(this.createLogArray(source, yesterday, 'Yesterday'));
        logdata.push(this.createLogArray(source, thisweek, 'This week'));
        logdata.push(this.createLogArray(source, lastweek, 'Last week'));
        logdata.push(this.createLogArray(source, thismonth, 'This month'));
        logdata.push(this.createLogArray(source, lastmonth, 'Last month'));
        logdata.push(this.createLogArray(source, earlier, 'Earlier'));
        
        this.setState({allLogs:logdata});
      }

      var titleKey = this.findWithAttr(fielddata, 'id', "13");
      if (titleKey != -1 && fielddata[titleKey].projectdetail.length > 0 && fielddata[titleKey].projectdetail[0].fieldvalue) {

        this.setState({
          title: fielddata[titleKey].projectdetail[0].fieldvalue ? fielddata[titleKey].projectdetail[0].fieldvalue : "",
        }, () => this.forceUpdate())
      }
      else {
        this.setState({
          title: "",
        }, () => this.forceUpdate())
      }

      var companyKey = this.findWithAttr(fielddata, 'id', "6");
      if (companyKey != -1 && fielddata[companyKey].projectdetail.length > 0 && fielddata[companyKey].projectdetail[0].fieldvalue) {
        this.setState({
          companyName: fielddata[companyKey].projectdetail[0].fieldvalue ? fielddata[companyKey].projectdetail[0].fieldvalue : "",
        }, () => this.forceUpdate())
      }
      else {
        this.setState({
          companyName: "",
        }, () => this.forceUpdate())
      }

      var nameKey = this.findWithAttr(fielddata, 'id', "67");
      if (nameKey != -1 && fielddata[nameKey].projectdetail.length > 0 && fielddata[nameKey].projectdetail[0].values) {
        var names = fielddata[nameKey].projectdetail[0].fieldvalue;
        let colorClass = (names) ? names.charAt(0).toLowerCase() : "";
        this.setState({
          firstname: names,
          colorClass: colorClass,
        }, () => this.forceUpdate())
      }
      else {
        this.setState({
          firstname: "",
          lastname: "",
          colorClass: "",
        }, () => this.forceUpdate())
      }

      //STRAT setting fieldsObj for update data with lead Image
      this.setState({
        fieldsObj: {
         
        },
      }, () => {
        let fieldsObj = this.state.fieldsObj;
        this.setState({ projectFieldData: props.projectReducer.projectFieldData.data })
        let country = "";
        const projectDataForEdit = props.projectReducer.projectFieldData.data;

        var promise = new Promise((resolve, reject) => {
          projectDataForEdit.map((v, i) => {
                if (v.fieldtype.id == '1' && v.id == '1') {//for first/last name          
                  if(v.projectdetail.length > 0){
                    var data = {
                      fieldid: v.id,
                      projectvalue: v.projectdetail[0].fieldvalue
                    }
                    fieldsObj = {
                      ...fieldsObj,
                      [v.fieldkey]: data,
                    }
                    
                  }
                }
                else if (v.fieldtype.id == '1' && v.id == '69') {//for owner field        
                  if(v.projectdetail.length > 0 && v.projectdetail[0].values.length && v.projectdetail[0].values[0].id){
                    var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id',v.projectdetail[0].values[0].id);
                    if(companyOwnerKey !== -1){
                      var ownerData = {
                        fieldid: v.id,
                        values: this.state.usersListArray[companyOwnerKey]
                      }
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: ownerData,
                      }
                    }
                  }
                }else if (v.fieldtype.id == '1' && v.fieldkey=='tags') {//for tag input
                  var tagArray = []
                  if(v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0){
                    v.projectdetail[0].values.map((v,i)=>{
                      tagArray.push(v.value);
                    })
                  }
                  var data = {
                    fieldid: v.id,
                    values: tagArray
                  }
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: data,
                  }
                } else if (v.fieldtype.id == '1' && v.id == '68') {//related field
                  if(v.projectdetail.length > 0 && v.projectdetail[0].values.length && v.projectdetail[0].values[0].id){
                    var relatedKey = this.findWithAttr(this.state.relatedListArray, 'id',v.projectdetail[0].values[0].id);
                    if(companyOwnerKey !== -1){
                      var relatedData = {
                        fieldid: v.id,
                        values: this.state.relatedListArray[relatedKey]
                      }
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: relatedData,
                      }
                    }
                  }
                }else if (v.fieldtype.id == '1' || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
                  if(v.projectdetail.length > 0 && v.projectdetail.length > 0){
                    var data = {
                      fieldid: v.id,
                      projectvalue: v.projectdetail[0].fieldvalue
                    }
                    fieldsObj = {
                      ...fieldsObj,
                      [v.fieldkey]: data,
                    }
                  }
                }

                if (v.fieldtype.id == '2') {//TextArea
                  if(v.projectdetail.length > 0){
                    var data = {
                      fieldid: v.id,
                      projectvalue: v.projectdetail[0].fieldvalue
                    }
                    fieldsObj = {
                      ...fieldsObj,
                      [v.fieldkey]: data,
                    }
                  }
                }

                if(v.fieldtype.id == "3"){//checkboxes
                  if(v.projectdetail.length > 0){
                    var data = {
                      fieldid: v.id,
                      projectvalue: v.projectdetail[0].fieldvalue
                    }
                    fieldsObj = {
                      ...fieldsObj,
                      [v.fieldkey]: data,
                    }
                  }
                }
                //if(this.state.fieldsList && this.state.fieldsList.length >0){
                  if (v.fieldtype.id === '4') {//dropdown
                    if(v.id === "70" || v.id === "72" || v.id === "75"){
                      if(v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0){
                        var optId = this.findWithAttr(v.options, 'id',v.projectdetail[0].values[0].id);
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: {fieldid: v.id,values:v.options[optId]},
                        }
                      }
                    }else{
                      if(v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0){
                        var optId = this.findWithAttr(v.options, 'id',v.projectdetail[0].values[0].id);
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: {fieldid: v.id,values:v.options[optId]},
                        }
                      }
                    }
                  }
                  if (v.fieldtype.id === '5') {//multi dropdown
                    if(v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0){
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: {fieldid: v.id,values:v.projectdetail[0].values},
                      }
                    }
                  }
                  if (v.fieldtype.id === '8') {//datepicker
                    if(v.projectdetail.length > 0 && v.projectdetail[0].fieldvalue != ""){
                      //var dt = moment.unix(v.projectdetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                       // console.log("Change gayatri ")
                      var dt = new Date(moment(v.projectdetail[0].fieldvalue).format('YYYY-MM-DD'))
                      dt = new Date(dt)
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: {fieldid: v.id,values:dt},
                      }
                    }else if(v.projectdetail.length > 0 && v.projectdetail[0].fieldvalue == ""){
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: {fieldid: v.id,values:null},
                      }
                    }
                  }
                //}
              })

          this.setState({
            fieldsObj,
            country,
            isUpdate: false,
          }, () => {
            resolve(true)
          })
        });

        promise.then(result => {
          props.projectReducer.projectFieldData.is_loaded = false;
        });
      })
      //END setting fieldsObj for update data with lead Image
    }
  }

  handleTaskDialogClose = () => {
    this.setState({ shouldOpenCreateTaskDialog: false, FullCreateTaskDialog: false, minimizeTaskDialog: false });
  };

  handleTaskDialogMinimize = () => {
    this.setState(oldStateminimizeTaskDialog => ({ minimizeTaskDialog: !oldStateminimizeTaskDialog.minimizeTaskDialog }));
    this.setState({ FullCreateTaskDialog: false })
  };

  handleTaskDialogFull = () => {
    this.setState(oldStateFullCreateTaskDialog => ({ FullCreateTaskDialog: !oldStateFullCreateTaskDialog.FullCreateTaskDialog }));
    this.setState({ minimizeTaskDialog: false })
  };

  
  //----log functions------------
  handleViewEmailDialogClose = () => {
    this.setState({ shouldOpenViewEmailDialog: false, FullViewEmailDialog: false, minimizeViewEmailDialog: false });
  };
  handleViewEmailDialogMinimize = () => {
    this.setState(oldStateminimizeViewEmailDialog => ({ minimizeViewEmailDialog: !oldStateminimizeViewEmailDialog.minimizeViewEmailDialog }));
    this.setState({ FullViewEmailDialog: false })
  };
  handleViewEmailDialogFull = () => {
    this.setState(oldStateFullViewEmailDialog => ({ FullViewEmailDialog: !oldStateFullViewEmailDialog.FullViewEmailDialog }));
    this.setState({ minimizeViewEmailDialog: false })
  };

  handlefiltermain = (types, activities) => {
    this.setState({ logtypeid: types, activitypeid: activities }, () => this.forceUpdate());

    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let getfilterLogParam = { projectid: this.state.project_id, emailid: emailParam, logtypeid: types, activitypeid: activities }
    this.props.getProjectActivityLogList(this.props.apolloClient.client, getfilterLogParam);
  }

  createLogArray = (source, partData, partKey) => {
    let logData = [];

    if (partData && partData.length > 0) {
        let logged_in_user_email = JSON.parse(localStorage.getItem("user_data_session")).email;

        partData.map((data) => {
          let pinned = data.ispined;

          if (data.email_type) {
            let log_user = JSON.parse(localStorage.getItem('user_data_session'));
            let color_class = (log_user.name) ? log_user.name.charAt(0) : "";

            if (data.email_type == 1) {
              let date = moment(data.details.email_date_time).format("X");
              logData.push(
                {
                  logId: data.id,
                  ispined: pinned,
                  emailtype: 1,
                  name: data.name,
                  email: data.email,
                  color: color_class,
                  attachment: data.attachment,
                  snippet: data.details.snippet,
                  subject: data.details.subject,
                  body_html: data.details.body_html,
                  email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
                }
              );
            }
            else if (data.email_type == 2) {
              logData.push(
                {
                  logId: data.id,
                  ispined: pinned,
                  emailtype: 2,
                  name: data.name,
                  email: data.email,
                  color: color_class,
                  attachment: data.attachment,
                  snippet: data.details.snippet,
                  subject: data.details.subject,
                  body_html: data.details.body_html,
                  email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
                }
              );
            }
          }
          else {
            let template = data.template;
            let color_class = (data.__user__.name) ? data.__user__.name.charAt(0) : "";
            let text = (data.text) ? data.text : "";
            let loguser = (data.__user__.email == logged_in_user_email) ? 'You' : data.__user__.name;
            let template1 = template.replace("{{user}}", loguser);

            let new_template = "";

            let create_date = data.createddate;
            let updateddate = data.updateddate;
            if (data.logtype == 1) // create time
            {
              new_template = template1.replace("{{source_type}}", "Project");
            }
            else if (data.logtype == 0) // Phone call
            {
              updateddate = data.logdate;
              new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
            }
            else if (data.logtype == 2) // name change
            {
              let old_template = template1.replace("{{source_type}}", "Project");
              new_template = old_template.replace("{{source name=new_value}}", '"' + data.newvalue + '"');
            }
            else if (data.logtype == 3 && data.__assignuser__) // owner change
            {
              let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
              new_template = template1.replace("{{target}}", '"' + logAssignUser + '"');
            }
            else if (data.logtype == 4) // for status
            {
              let old_template = template1.replace("{{old_value}}", data.oldvalue);
              new_template = old_template.replace("{{new_value}}", data.newvalue);
            }
            else if (data.logtype == 5) // for note
            {
              updateddate = data.logdate;
              new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
            }
            else if (data.logtype == 20) // for note
            {
              if(data.__task__ != null){
                let old_template = template1.replace("{{task}}", data.__task__.__taskdetail__[0].fieldvalue);
                new_template = old_template.replace("{{source}}", source);
              }
            }
            else if (data.logtype == 21) // for note
            {
              if (data.__task__ != null) {
                let old_template = template1.replace("{{task}}", data.__task__.__taskdetail__[0].fieldvalue);
                let old_template1 = old_template.replace("{{source}}", source);
                new_template = old_template1.replace("{{target}}", loguser);
              }
            }
            if (new_template != "")
            {
              logData.push(
                {
                  logId: data.id,
                  ispined: pinned,
                  emailtype: 0,
                  comments: data.__commentlist__,
                  activitytype: data.__activitytype__,
                  user: data.__user__.name,
                  useremail: data.__user__.email,
                  propertyType: data.propertyname,
                  color: color_class,
                  template: new_template,
                  text: text,
                  logtype: data.logtype,
                  time: (updateddate != null)
                    ?
                    moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm A')
                    :
                    moment.unix(create_date).tz(this.state.timeZone).format('hh:mm A'),
                  date: (updateddate != null)
                    ?
                    moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                    :
                    moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)
                }
              );
            }
            
          }
        });
    }
    console.log(logData);
    
    return { key: partKey, datas: logData };
  }

  handlechange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }
  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };
  handleCancelAddLog = () => {
    this.setState({ textvalue: '' })
  }
  handleSaveEditLog = () => {
    let date = moment(this.state.selectedDate).format("X");
    let textvalue = this.state.textvalue;
    let saveLogparams = [];

    saveLogparams['update'] = {
      text: textvalue,
      logtime: parseFloat(date),
      logdate: parseFloat(date),
      logid: parseFloat(this.state.logid),
    };

    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    saveLogparams['getlog'] = { emailid: emailParam, projectid: parseFloat(this.props.project_id), logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };

    this.props.updateProjectLog(this.props.apolloClient.client, saveLogparams);
    this.setState({ isEditLog: false })
  }
  handleEditLog = (id, date, time, text) => {
    let date1 = new Date(date + ',' + time);
    this.setState({ isEditLog: true, textvalue: text, selectedDate: date1, logid: id });
  }
  handleCancelEdit = () => {
    this.setState({ isEditLog: false })
  }

  //------delete log
  handleDeleteProjectLog = (logid) => {
    this.setState({ logid: logid });
    this.toggleDeleteLogDialog()
  }
  toggleDeleteLogDialog = () => {
    this.setState({ isDeleteLogConfirmDialoOpen: !this.state.isDeleteLogConfirmDialoOpen });
  };
  confirmDeleteLog = () => {
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let deletelogParams = { logid: parseFloat(this.state.logid), projectid: parseFloat(this.state.project_id), emailid: emailParam, logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };
    this.props.deleteProjectLogDetail(this.props.apolloClient.client, deletelogParams);
    this.toggleDeleteLogDialog()
  };

  //-- Download option
  handleDownloadClick = (event) => {
    this.setState({ Downloadoptions: event.target, showDownloadoptions: true });
  }
  handleCloseDownload = (event) => {
    this.setState({ Downloadoptions: null, showDownloadoptions: false });
  }

  //email menu functions
  handleEmailHtmlPopupMain = (logdata) => {
    this.setState({ shouldOpenViewEmailDialog: true, viewHtmlData: logdata });
  }

  //- comments-----------------


  toggleComment = (logid) => {
    let isOpenedCommentIds = this.state.isOpenedCommentIds;

    if (isOpenedCommentIds.indexOf(logid) == -1) {
      isOpenedCommentIds.push(logid);
    }
    else {
      let index = isOpenedCommentIds.indexOf(logid);
      isOpenedCommentIds.splice(index, 1);
    }

    this.setState(({ logid: logid, isOpenedCommentIds: isOpenedCommentIds }));


    // this.setState(({ isOpenedComment: !this.state.isOpenedComment, logid: logid }));
  }

  handleCancelComment = () => {
    this.setState({ commentvalue: '' });
  }

  handleSaveCommentLog = (type) => {
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let commentParams = { type: type, comment: this.state.commentvalue, logid: parseFloat(this.state.logid), projectid: parseFloat(this.state.project_id), emailid: emailParam, logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };
    this.props.addProjectLogComment(this.props.apolloClient.client, commentParams);
    this.setState({ commentvalue: '' });
  }

  //-edit comment
  handleSaveEditComment = (type) => {
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let commentParams = { type: type, commentid: parseFloat(this.state.commentid), comment: this.state.commentvalue, projectid: parseFloat(this.state.project_id), emailid: emailParam, logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };
    this.setState({ commentvalue: '', isEditComment: false, commentid: '' });
    this.props.updateProjectLogComment(this.props.apolloClient.client, commentParams);
  }

  handleEditCommentMain = (id, comment) => {
    this.setState({ isEditComment: true, commentvalue: comment, commentid: id });
  }

  handleCancelEditComment = () => {
    this.setState({ isEditComment: false, commentvalue: '' })
  }


  //-delete comment
  deleteComment = (cmt_id, type) => {
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let commentParams = { commentid: cmt_id, type: type, emailid: emailParam, projectid: parseFloat(this.state.project_id), logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };
    this.props.deleteProjectLogComment(this.props.apolloClient.client, commentParams);
  }
  //--------------comments functions-----------


  //---- email functions
  toggleEmail = (id) => {
    let isOpenedEmailIds = this.state.isOpenedEmailIds;

    if (isOpenedEmailIds.indexOf(id) == -1) {
      isOpenedEmailIds.push(id);
    }
    else {
      let index = isOpenedEmailIds.indexOf(id);
      isOpenedEmailIds.splice(index, 1);
    }

    this.setState(({ logid: id, isOpenedEmailIds: isOpenedEmailIds }));
  }

  toggleReplayEmail = () => {
    this.setState(oldStateReplayEmail => ({ isOpenedReplayEmail: !oldStateReplayEmail.isOpenedReplayEmail }));
  }

  
  //------------
  //-----------------------------------------------


  handleSubmit = event => {
    console.log("submitted");
    console.log(event);
  };

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDialogClose = () => {
    this.setState({ shouldOpenNoteDialog: false });
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ TabValue: newValue });
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  handleDeleteProject = () => {
    this.toggleDeleteDialog()
  }

  toggleDeleteDialog = () => {
    this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
  };
  confirmDeleteCustomField = () => {
    let deleteProjectParams = [];

    deleteProjectParams['deleteid'] = { projectid: [parseFloat(this.state.project_id)] };
    deleteProjectParams['listval'] = {
      filterdata: [],
      list: {
        limit: 10,
        pageno: 1,
        searchtext: "",
        sorttype: "",
        type: "list",
        fieldId: 0,
        columnname: "",
      },
      filterid: 1
    };
    this.toggleDeleteDialog();
    this.props.handleClose();
    this.props.deleteProject(this.props.apolloClient.client, deleteProjectParams);
  };
  handleFavorite = (project_id, status) => {
    let setFavoriteParams = [];

    let fav = !status;
    let star_i = fav ? 'star' : 'star_border';
    this.setState({ isfavorite: fav, star_icon: star_i });

    setFavoriteParams['favorite'] = { status: !status, projectid: parseFloat(this.state.project_id) };
    setFavoriteParams['listval'] = {
      filterdata: [],
      list: {
        limit: 10,
        pageno: 1,
        searchtext: "",
        sorttype: "",
        type: "list",
        fieldId: 0,
        columnname: ""
      },
      filterid: 1
    };

    this.props.addFavouriteProject(this.props.apolloClient.client, setFavoriteParams);
  }

  handleLeadProfileImage = event => {
    var leadFile;
    console.log("event.target.files[0]", event.target.files[0]);
    if (event.target.files[0] == undefined) {
      return false
    }

    leadFile = event.target.files[0]


    let logData = [];
    console.log("this.state.fieldsObj", this.state.fieldsObj);
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
    submitData = Object.values(submitData).map((row)=>{
          if (row.fieldid == "68") {//realated  field
            if(row.values == null){
              return false
            }
            row.values = [{ srno: "1", id: row.values.id, value: row.values.name,type: row.values.type }]
          }
          if(row.fieldid === "72" || row.fieldid === "70" || row.fieldid === "75"){
            row.values = [{srno:"1", id:row.values.id,value:row.values.name}]
          }
          var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
          if(this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false){
            row.values = [{srno:"1",id:row.values.id,value:row.values.optionvalue}]
          }
          if(this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false){
            var ar = [];
            if(row.values.length >0){
              row.values.map((val,i)=>{
                var optName;
                if(val.optionvalue){
                  optName = val.optionvalue
                }else{
                  optName = val.value
                }
                ar.push({srno:(i+1).toString(),id:val.id, value:optName})
              })
            }
            row.values = ar
          }

          //validating date fiels
          if(this.state.fieldsList[key].fieldtype.id == "8"){//date field
            if(row.values == null){
              row.projectvalue = "";
              delete row.values;
            }else if(row.values == "Invalid Date"){
              return false
            }else{
              // row.projectvalue = moment(row.values).format("X");
              // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
              // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
              // row.projectvalue = moment.utc(logdate).format('X');
              var dt = moment(row.values).format('YYYY-MM-DD')
              row.projectvalue = dt;
              delete row.values;
            }
          }

          if(row.fieldid == "74"){
            if(row.values.length > 0){
              var objArr = [];
              row.values.map((val,k)=>{
                var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
                var tagId = "0";
                if(foundKey !== -1){
                  tagId = this.state.systemTagsList[foundKey].id
                }
                objArr.push({"srno":(k+1).toString(),value:val.toLowerCase(),id:tagId,isdeleted:"0"})
              })

              //add tags from props value which is removed   with id deleted id "1"
              var tagsKeyFromProps = this.findWithAttr(this.props.projectReducer.projectFieldData.data, 'id', "74");
              if(this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail.length>0){
                var oldTagsValues = this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail[0].values;
                if(oldTagsValues.length > 0){
                  oldTagsValues.map((v,k)=>{
                    var checkKey = this.findWithAttr(objArr, 'value', v.value);
                    if(checkKey === -1){
                      v.isdeleted = "1";
                      v.srno = (objArr.length + 1).toString();
                      objArr.push(v);
                    }
                  })
                }
              }

              row.values = objArr
              if(this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail.length>0){
                this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail[0].values = objArr;  
              }
            }
          }

          if(row.fieldid == "69"){//owner value set
           if(row.values){
              row.values = [{"srno":"1",id:row.values.id,value:row.values.email,name:row.values.name}];
            }else{
              row.values = [];
            }
            delete row.projectvalue;
          }
          return row;
        })

    //adding  projectdetailid for update object from this.state.projectFieldData
    submitData = submitData.map((row, i) => {
      var key = this.findWithAttr(this.state.projectFieldData, 'id', row.fieldid);
      if (key !== -1) {
        if (this.state.projectFieldData[key].projectdetail.length > 0) {
          row.projectdetailid = this.state.projectFieldData[key].projectdetail[0].id;
        } else {
          row.projectdetailid = ""
        }
      }


      return row
    })

    //filter null and undefined object 
    submitData = submitData.filter(function (element) {
      return element !== undefined;
    });

    //not to submit if any field is false
    var allowToSubmit = true
    submitData.map((row) => {
      if (!row) {
        allowToSubmit = false
      }
    })
    console.log("submitData", submitData);
    //return
    if (this.state.project_id && allowToSubmit) {
      let req = {
        projectdata:submitData,
        projectid:this.state.project_id
      }
      this.props.updateProjectDetail(this.props.apolloClient.client, req);
    }
  };
  openConvertLeadDialog = () => {
    this.setState({ shouldOpenConvertProjectDialog: true });
  };
  handleConvertLeadDialogClose = () => {
    this.setState({ shouldOpenConvertProjectDialog: false, FullConvertProjectDialog: false, minimizeConvertProjectDialog: false });
  };

  handleProjectDialogMinimize = () => {
    this.setState(oldStateminimizeConvertProjectDialog => ({ minimizeConvertProjectDialog: !oldStateminimizeConvertProjectDialog.minimizeConvertProjectDialog }));
    this.setState({ FullConvertProjectDialog: false })
  };
  handleProjectDialogFull = () => {
    this.setState(oldStateFullConvertProjectDialog => ({ FullConvertProjectDialog: !oldStateFullConvertProjectDialog.FullConvertProjectDialog }));
    this.setState({ minimizeConvertProjectDialog: false })

  };
  opeAddNewTaskPopup = () => {
      var data = {
        recordtype:"project",
        type:"tasks",
        id:parseInt(this.state.project_id),
      }
      this.setState({
          newTaskData:data,
      }, () => {
        this.setState({ 
          shouldOpenCreateTaskDialog: true        
        });
      })
  }
  togglePin = (logId, pinned) => {
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';

    let pinParams = {
      ispined: !pinned,
      logid: parseFloat(logId),
      recordid: parseFloat(this.state.project_id),
      recordtypeid: 5,
      emailid: emailParam,
      logtypeid: this.state.logtypeid,
      activitypeid: this.state.activitypeid
    }

    this.props.addLogPined(this.props.apolloClient.client, pinParams);
  }
  render() {
    let { FullCreateTaskDialog, minimizeTaskDialog, shouldOpenCreateTaskDialog, isOpenedReplayEmail, commentvalue, shouldOpenNoteDialog, textvalue, selectedDate, shouldOpenCreateLogDialog, TabValue, allLogs, colorClass } = this.state;
    let { handleClose } = this.props;
    let current_logUser = JSON.parse(localStorage.getItem('user_data_session')).email;
    let loghtml = [];
    let logbody = [];

    allLogs.map((logdata) => {
      logbody = [];
      if(logdata.datas.length > 0){
        logbody.push(
          <div className="box_header">
            <div className="box_label"><h3 className="box_title">{logdata.key}</h3></div>
            <div className="box_tools">
              <Fab className="bg-dark small-size overflow-hidden" size="small">
                <h5 className="text-primary m-0 font-weight-normal">JS</h5>
              </Fab>
            </div>
          </div>
        );
        logdata.datas.map((log) => {
          if (log.emailtype != 0) {
            if (log.emailtype == 1) {
              logbody.push(
                <div className="box_body">
                  <div className="lead_pl p-0">
                    <Icon className="icon_left">mail</Icon>
                    <Grid container justify="space-between">
                      <Grid onClick={(id) => this.toggleEmail(log.logId)} className="flex-auto cursor_poiter">
                        <h5 className="mb-0">{log.name} to You </h5>
                        <p className="mt-4 mb-4 font-size-13 text-hint">{log.email_date_time}</p>
                      </Grid>
                      <Grid>
                        {(log.ispined == true) ?
                          <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => this.togglePin(log.logId, log.ispined)} className="button_size hover_show">
                            <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="var(--primary)" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                          </IconButton>
                          :
                          <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => this.togglePin(log.logId, log.ispined)} className="button_size hover_show">
                            <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                          </IconButton>
                        }
                        <IconButton aria-label="Add" size="small" className="hover_show">
                          <IconSmall>add_circle</IconSmall>
                        </IconButton>
                        <IconButton aria-label="Reply" size="small" className="hover_show" onClick={this.toggleReplayEmail}>
                          <IconSmall>reply</IconSmall>
                        </IconButton>
                        <IconButton aria-label="Reply All" size="small" className="hover_show" onClick={this.toggleReplayEmail}>
                          <IconSmall>reply_all</IconSmall>
                        </IconButton>
                        <ProfileEmailMenu logDatas={log} allfunction={this} />
                      </Grid>
                    </Grid>
                    {(this.state.isOpenedEmailIds.indexOf(log.logId) == -1) ?
                      <p className="mt-0 font-size-14"><span className="font-weight-500">{log.subject}</span> - {log.snippet}</p>
                      :
                      <div className="border_bx">
                        <h6>{log.subject}</h6>
                        <div dangerouslySetInnerHTML={{ __html: log.body_html }} />
                      </div>
                    }
                    <div className="mt-20">
                      {(log.attachment.length > 0) &&
                        log.attachment.map((attach) => (
                          <>
                            <span onClick={this.handleDownloadClick} className="crm-email-log-attachment">
                              <IconButton size="small">
                                <IconSmaller>attach_file</IconSmaller>
                              </IconButton>
                              {attach.file_name}
                              <IconButton size="small">
                                <IconSmaller>arrow_drop_down</IconSmaller>
                              </IconButton>
                            </span>
                            <Menu
                              id="long-menu"
                              anchorEl={this.state.Downloadoptions}
                              open={this.state.showDownloadoptions}
                              onClose={this.handleCloseDownload}
                            >
                              <MenuItem onClick={this.handleCloseDownload}>
                                <a href={attach.s3_file_name} target="__blank" className="text-center">
                                  Download
                                                      </a>
                              </MenuItem>
                              <MenuItem onClick={this.handleCloseDownload}>
                                <span className="font-size-13 mr-10 mb-5">Relate To Project</span>
                              </MenuItem>
                            </Menu>
                          </>
                        ))
                      }
                    </div>
                    {isOpenedReplayEmail &&
                      <LeadInboxCompose handleClose={this.toggleReplayEmail} />
                    }

                    <p className="mb-0 font-size-14">
                      <span className="cursor_poiter" onClick={() => this.toggleComment(log.logId)}>
                        <Icon className="vert-middle"> {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                        Comments ({(log.comments) ? log.comments.length : 0})
                                  </span>
                    </p>
                    {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) &&
                      <Grid container spacing={1}>
                        <Grid item className="flex-auto">
                          <div className="border_bx pr-10">
                            {(log.comments && log.comments.length > 0) &&
                              (log.comments).map((cmmt) => (
                                <Grid container className="mb-10" spacing={1}>
                                  <Grid item>
                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                      <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (cmmt.__commentby__.name).charAt(0)}>{(cmmt.__commentby__.name).charAt(0).toUpperCase()}</h5>
                                    </Fab>
                                  </Grid>
                                  {
                                    (this.state.isEditComment && (this.state.commentid == cmmt.id)) ?
                                      <>
                                        <Grid className="mb-10" item md={9}>
                                          <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveEditComment('email')}>
                                            <Grid className="mb-10" item>
                                              <TextValidator
                                                required={true}
                                                id="comment-input"
                                                fullWidth
                                                label="Text"
                                                name="commentvalue"
                                                placeholder="Add Comment"
                                                value={commentvalue}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                                onChange={this.handlechange}
                                              />
                                            </Grid>
                                            {(commentvalue && commentvalue.length > 0) &&
                                              <Grid className="mb-10" item>
                                                <Button className="mr-10" color="secondary" onClick={this.handleCancelEditComment}>Cancel</Button>
                                                <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                              </Grid>
                                            }
                                          </ValidatorForm>
                                        </Grid>
                                      </>
                                      :
                                      <>
                                        <Grid item>
                                          <p className="mt-4 mb-4 font-size-13 text-hint">
                                            {
                                              (cmmt.lastmodifieddate != null)
                                                ?
                                                moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format("hh:mm A")
                                                :
                                                moment.unix(cmmt.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                moment.unix(cmmt.createddate).tz(this.state.timeZone).format("hh:mm A")
                                            }
                                          </p>
                                          <p className="mt-4 mb-4 font-size-13">{cmmt.comment}</p>
                                        </Grid>
                                        {(cmmt.__commentby__.email == current_logUser) &&
                                          <EditCommentMenu allData={this} cmt={cmmt} type="email" />
                                        }
                                      </>
                                  }
                                </Grid>
                              ))
                            }
                            {(!this.state.isEditComment) &&
                              <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveCommentLog('email')}>
                                <Grid container spacing={1}>
                                  <Grid className="mb-10" item >
                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                      <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                    </Fab>
                                  </Grid>
                                  <Grid className="mb-10" item md={9}>
                                    <Grid className="mb-10" item>
                                      <TextValidator
                                        required={true}
                                        id="comment-input"
                                        fullWidth
                                        label="Text"
                                        name="commentvalue"
                                        placeholder="Add Comment"
                                        value={(log.logId == this.state.logid) ? commentvalue : ""}
                                        validators={["required"]}
                                        errorMessages={["this field is required"]}
                                        onChange={(event, logid) => this.handlechange(event, log.logId)}
                                      />
                                    </Grid>
                                    {
                                      (commentvalue && commentvalue.length > 0 && (log.logId == this.state.logid)) &&
                                      <Grid className="mb-10" item>
                                        <Button className="mr-10" color="secondary" onClick={this.handleCancelComment}>Cancel</Button>
                                        <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>
                              </ValidatorForm>
                            }
                          </div>
                        </Grid>
                        <Grid item className="align-self-center">

                        </Grid>
                      </Grid>
                    }
                  </div>
                </div>
              )
            }
            else if (log.emailtype == 2) {
              logbody.push(
                <div className="box_body">
                  <div className="lead_pl p-0">
                    <Icon className="icon_left">mail</Icon>
                    <Grid container justify="space-between">
                      <Grid onClick={(id) => this.toggleEmail(log.logId)} className="flex-auto cursor_poiter">
                        <h5 className="mb-0">You to {log.email}</h5>
                        <p className="mt-4 mb-4 font-size-13 text-hint">{log.email_date_time}</p>
                      </Grid>
                      <Grid>
                        {(log.ispined == true) ?
                          <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => this.togglePin(log.logId, log.ispined)} className="button_size hover_show">
                            <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="var(--primary)" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                          </IconButton>
                          :
                          <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => this.togglePin(log.logId, log.ispined)} className="button_size hover_show">
                            <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                          </IconButton>
                        }
                        <IconButton aria-label="Add" size="small" className="hover_show">
                          <IconSmall>add_circle</IconSmall>
                        </IconButton>
                        <IconButton aria-label="Reply" size="small" className="hover_show" onClick={this.toggleReplayEmail}>
                          <IconSmall>reply</IconSmall>
                        </IconButton>
                        <IconButton aria-label="Reply All" size="small" className="hover_show" onClick={this.toggleReplayEmail}>
                          <IconSmall>reply_all</IconSmall>
                        </IconButton>
                        <ProfileEmailMenu logDatas={log} allfunction={this} />
                      </Grid>
                    </Grid>
                    {(this.state.isOpenedEmailIds.indexOf(log.logId) == -1) ?
                      <p className="mt-0 font-size-14"><span className="font-weight-500">{log.subject}</span> - {log.snippet}</p>
                      :
                      <div className="border_bx">
                        <h6>{log.subject}</h6>
                        <div dangerouslySetInnerHTML={{ __html: log.body_html }} />
                      </div>
                    }
                    <div className="mt-20">
                      {(log.attachment.length > 0) &&
                        log.attachment.map((attach) => (
                          <>
                            <span onClick={this.handleDownloadClick} className="crm-email-log-attachment">
                              <IconButton size="small">
                                <IconSmaller>attach_file</IconSmaller>
                              </IconButton>
                              {attach.file_name}
                              <IconButton size="small">
                                <IconSmaller>arrow_drop_down</IconSmaller>
                              </IconButton>
                            </span>
                            <Menu
                              id="long-menu"
                              anchorEl={this.state.Downloadoptions}
                              open={this.state.showDownloadoptions}
                              onClose={this.handleCloseDownload}
                            >
                              <MenuItem onClick={this.handleCloseDownload}>
                                <a href={attach.s3_file_name} target="__blank" className="text-center">
                                  Download
                                                      </a>
                              </MenuItem>
                              <MenuItem onClick={this.handleCloseDownload}>
                                <span className="font-size-13 mr-10 mb-5">Relate To Project</span>
                              </MenuItem>
                            </Menu>
                          </>
                        ))
                      }
                    </div>
                    {isOpenedReplayEmail &&
                      <LeadInboxCompose handleClose={this.toggleReplayEmail} />
                    }
                    <p className="mb-0 font-size-14">
                      <span className="cursor_poiter" onClick={() => this.toggleComment(log.logId)}>
                        <Icon className="vert-middle"> {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                        Comments ({(log.comments) ? log.comments.length : 0})
                                  </span>
                    </p>
                    {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) &&
                      <Grid container spacing={1}>
                        <Grid item className="flex-auto">
                          <div className="border_bx pr-10">
                            {(log.comments && log.comments.length > 0) &&
                              (log.comments).map((cmmt) => (
                                <Grid container className="mb-10" spacing={1}>
                                  <Grid item>
                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                      <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (cmmt.__commentby__.name).charAt(0)}>{(cmmt.__commentby__.name).charAt(0).toUpperCase()}</h5>
                                    </Fab>
                                  </Grid>
                                  {
                                    (this.state.isEditComment && (this.state.commentid == cmmt.id)) ?
                                      <>
                                        <Grid className="mb-10" item md={9}>
                                          <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveEditComment('email')}>
                                            <Grid className="mb-10" item>
                                              <TextValidator
                                                required={true}
                                                id="comment-input"
                                                fullWidth
                                                label="Text"
                                                name="commentvalue"
                                                placeholder="Add Comment"
                                                value={commentvalue}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                                onChange={this.handlechange}
                                              />
                                            </Grid>
                                            {(commentvalue && commentvalue.length > 0) &&
                                              <Grid className="mb-10" item>
                                                <Button className="mr-10" color="secondary" onClick={this.handleCancelEditComment}>Cancel</Button>
                                                <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                              </Grid>
                                            }
                                          </ValidatorForm>
                                        </Grid>
                                      </>
                                      :
                                      <>
                                        <Grid item>
                                          <p className="mt-4 mb-4 font-size-13 text-hint">
                                            {
                                              (cmmt.lastmodifieddate != null)
                                                ?
                                                moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format("hh:mm A")
                                                :
                                                moment.unix(cmmt.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                moment.unix(cmmt.createddate).tz(this.state.timeZone).format("hh:mm A")
                                            }
                                          </p>
                                          <p className="mt-4 mb-4 font-size-13">{cmmt.comment}</p>
                                        </Grid>
                                        {(cmmt.__commentby__.email == current_logUser) &&
                                          <EditCommentMenu allData={this} cmt={cmmt} type="email" />
                                        }
                                      </>
                                  }
                                </Grid>
                              ))
                            }
                            {(!this.state.isEditComment) &&
                              <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveCommentLog('email')}>
                                <Grid container spacing={1}>
                                  <Grid className="mb-10" item >
                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                      <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                    </Fab>
                                  </Grid>
                                  <Grid className="mb-10" item md={9}>
                                    <Grid className="mb-10" item>
                                      <TextValidator
                                        required={true}
                                        id="comment-input"
                                        fullWidth
                                        label="Text"
                                        name="commentvalue"
                                        placeholder="Add Comment"
                                        value={(log.logId == this.state.logid) ? commentvalue : ""}
                                        validators={["required"]}
                                        errorMessages={["this field is required"]}
                                        onChange={(event, logid) => this.handlechange(event, log.logId)}
                                      />
                                    </Grid>
                                    {
                                      (commentvalue && commentvalue.length > 0 && (log.logId == this.state.logid)) &&
                                      <Grid className="mb-10" item>
                                        <Button className="mr-10" color="secondary" onClick={this.handleCancelComment}>Cancel</Button>
                                        <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>
                              </ValidatorForm>
                            }

                          </div>
                        </Grid>
                        <Grid item className="align-self-center">

                        </Grid>
                      </Grid>

                    }
                  </div>
                </div>
              )
            }
          } else {
            if (log.logtype == 0) {
              logbody.push(
                <div className="box_body">
                  <div className="lead_pl p-0">
                    {/* {
                      (log.propertyType == 'Phone Call') ?
                        <Icon className="icon_left">call</Icon>
                        :
                        <Icon className="icon_left">calendar_today</Icon>
                    } */}

                    <Grid container justify="space-between">
                      <Grid>
                        <h5 className="mb-0">
                          <FontAwesome className={log.activitytype.icon + " mr-10 vert-middle"} style={{ fontSize: '20px', padding: '5px' }} />
                          {log.propertyType}
                        </h5>
                        {/* <p className="mt-4 mb-4 font-size-13 text-hint">{log.creaetedDate}</p> */}
                        <p className="mt-4 mb-4 font-size-13 text-hint">{log.date}, {log.time}</p>
                      </Grid>
                      <Grid>
                        {(log.ispined == true) ?
                          <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => this.togglePin(log.logId, log.ispined)} className="button_size hover_show">
                            <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="var(--primary)" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                          </IconButton>
                          :
                          <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => this.togglePin(log.logId, log.ispined)} className="button_size hover_show">
                            <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                          </IconButton>
                        }
                        <IconButton aria-label="Add" size="small" onClick={() => this.setState({ logtype: log.logtype, logvaluetype: log.propertyType, shouldOpenCreateLogDialog: true })} className="hover_show">
                          <IconSmall>add_circle</IconSmall>
                        </IconButton>
                        {(log.useremail == current_logUser) && 
                          <IconButton aria-label="Delete" size="small" onClick={(logId) => this.handleDeleteProjectLog(log.logId)} className="hover_show">
                            <IconSmall>delete</IconSmall>
                          </IconButton>
                        }
                      </Grid>
                    </Grid>
                    {(this.state.isEditLog && (this.state.logid == log.logId)) ?
                      <>
                        <ValidatorForm noValidate debounceTime={500} onSubmit={this.handleSaveEditLog}>
                          <TextValidator
                            required={true}
                            id="search-input"
                            fullWidth
                            label="Text"
                            placeholder="Click here to add Text"
                            value={textvalue}
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                            onChange={(event, logid) => this.handlechange(event, log.logId)}
                            name="textvalue"
                          />
                          <div className="mt-40">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container spacing={3}>
                                <Grid item xs={6} className="pt-0">
                                  <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Date</InputLabel>
                                  <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format={this.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                                    maxDate={new Date()}
                                    value={selectedDate}
                                    onKeyPress={(e) => e.preventDefault()}
                                    onChange={this.handleDateChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6} className="pt-0">
                                  <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Time</InputLabel>
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    format="hh:mm a"
                                    value={selectedDate}
                                    onKeyPress={(e) => e.preventDefault()}
                                    onChange={this.handleDateChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </MuiPickersUtilsProvider>
                            <div className="pull-right">
                              <Button className="mr-10" color="secondary" onClick={this.handleCancelEdit}>Cancel</Button>
                              <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Update</Button>
                            </div>
                          </div>
                        </ValidatorForm>
                      </>
                      :
                      <div className="border_bx">
                        {log.text}
                        {(log.useremail == current_logUser) && 
                          <div className="hover_show">
                            <IconButton aria-label="Delete" size="small" onClick={(id, date, time, text) => this.handleEditLog(log.logId, log.date, log.time, log.text)}>
                              <IconSmall>edit</IconSmall>
                            </IconButton>
                          </div>
                        }
                      </div>
                    }
                    <p className="font-size-14 pt-10">
                      <Grid container spacing={1}>
                        <Grid item md={2}>
                          <Fab className="small-size overflow-hidden mr-8" color="secondary" size="small">
                            <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                          </Fab>
                        </Grid>
                        <Grid item md={10}>
                          <span className="font-weight-500">{log.user}</span> {log.template}
                          </Grid>
                      </Grid>
                    </p>
                    <p className="mb-0 font-size-14 pt-10">
                      <span className="cursor_poiter" onClick={() => this.toggleComment(log.logId)}>
                        <Icon className="vert-middle"> {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                        Comments ({(log.comments) ? log.comments.length : 0})
                      </span>
                    </p>

                    {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) &&
                      <Grid container spacing={1}>
                        <Grid item className="flex-auto">
                          <div className="border_bx pr-10">
                            {(log.comments && log.comments.length > 0) &&
                              (log.comments).map((cmmt) => (
                                <Grid container className="mb-10" spacing={1}>
                                  <Grid item>
                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                      <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (cmmt.__commentby__.name).charAt(0)}>{(cmmt.__commentby__.name).charAt(0).toUpperCase()}</h5>
                                    </Fab>
                                  </Grid>
                                  {
                                    (this.state.isEditComment && (this.state.commentid == cmmt.id)) ?
                                      <>
                                        <Grid className="mb-10" item md={9}>
                                          <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveEditComment('log')}>
                                            <Grid className="mb-10" item>
                                              <TextValidator
                                                required={true}
                                                id="comment-input"
                                                fullWidth
                                                label="Text"
                                                name="commentvalue"
                                                placeholder="Add Comment"
                                                value={commentvalue}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                                onChange={this.handlechange}
                                              />
                                            </Grid>
                                            {(commentvalue && commentvalue.length > 0) &&
                                              <Grid className="mb-10" item>
                                                <Button className="mr-10" color="secondary" onClick={this.handleCancelEditComment}>Cancel</Button>
                                                <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                              </Grid>
                                            }
                                          </ValidatorForm>
                                        </Grid>
                                      </>
                                      :
                                      <>
                                        <Grid item>
                                          <p className="mt-4 mb-4 font-size-13 text-hint">
                                            {
                                              (cmmt.lastmodifieddate != null)
                                                ?
                                                moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format("hh:mm A")
                                                :
                                                moment.unix(cmmt.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                moment.unix(cmmt.createddate).tz(this.state.timeZone).format("hh:mm A")
                                            }
                                          </p>
                                          <p className="mt-4 mb-4 font-size-13">{cmmt.comment}</p>
                                        </Grid>
                                        {(cmmt.__commentby__.email == current_logUser) &&
                                          <EditCommentMenu allData={this} cmt={cmmt} type="log" />
                                        }
                                      </>
                                  }
                                </Grid>
                              ))
                            }
                            {(!this.state.isEditComment) &&
                              <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveCommentLog('log')}>
                                <Grid container spacing={1}>
                                  <Grid className="mb-10" item >
                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                      <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                    </Fab>
                                  </Grid>
                                  <Grid className="mb-10" item md={9}>
                                    <Grid className="mb-10" item>
                                      <TextValidator
                                        required={true}
                                        id="comment-input"
                                        fullWidth
                                        label="Text"
                                        name="commentvalue"
                                        placeholder="Add Comment"
                                        value={(log.logId == this.state.logid) ? commentvalue : ""}
                                        validators={["required"]}
                                        errorMessages={["this field is required"]}
                                        onChange={(event, logid) => this.handlechange(event, log.logId)}
                                      />
                                    </Grid>
                                    {
                                      (commentvalue && commentvalue.length > 0 && (log.logId == this.state.logid)) &&
                                      <Grid className="mb-10" item>
                                        <Button className="mr-10" color="secondary" onClick={this.handleCancelComment}>Cancel</Button>
                                        <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>
                              </ValidatorForm>
                            }

                          </div>
                        </Grid>
                        <Grid item className="align-self-center">

                        </Grid>
                      </Grid>
                    }
                  </div>
                </div>
              )
            }
            else if (log.logtype == 5) {
              logbody.push(
                <div className="box_body">
                  <div className="lead_pl p-0">
                    
                    <Grid container justify="space-between">
                      <Grid>
                        <h5 className="mb-0"><Icon className="vert-middle mr-10">note</Icon>{log.propertyType}</h5>
                        <p className="mt-4 mb-4 font-size-13 text-hint">{log.date}, {log.time}</p>
                      </Grid>
                      <Grid>
                        {(log.ispined == true) ?
                          <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => this.togglePin(log.logId, log.ispined)} className="button_size hover_show">
                            <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="var(--primary)" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                          </IconButton>
                          :
                          <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => this.togglePin(log.logId, log.ispined)} className="button_size hover_show">
                            <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                          </IconButton>
                        }
                        <IconButton aria-label="Add" size="small" onClick={() => this.setState({ logtype: log.logtype, logvaluetype: log.propertyType, shouldOpenCreateLogDialog: true })} className="hover_show">
                          <IconSmall>add_circle</IconSmall>
                        </IconButton>
                        {(log.useremail == current_logUser) && 
                          <IconButton aria-label="Delete" size="small" onClick={(logId) => this.handleDeleteProjectLog(log.logId)} className="hover_show">
                            <IconSmall>delete</IconSmall>
                          </IconButton>
                        }
                      </Grid>
                    </Grid>
                    {(this.state.isEditLog && (this.state.logid == log.logId)) ?
                      <>
                        <ValidatorForm noValidate debounceTime={500} onSubmit={this.handleSaveEditLog}>
                          <TextValidator
                            required={true}
                            id="search-input"
                            fullWidth
                            label="Text"
                            placeholder="Click here to add Text"
                            value={textvalue}
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                            onChange={(event, logid) => this.handlechange(event, log.logId)}
                            name="textvalue"
                          />
                          <div className="mt-40">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container spacing={3}>
                                <Grid item xs={6} className="pt-0">
                                  <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Date</InputLabel>
                                  <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format={this.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                                    maxDate={new Date()}
                                    value={selectedDate}
                                    onKeyPress={(e) => e.preventDefault()}
                                    onChange={this.handleDateChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6} className="pt-0">
                                  <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Time</InputLabel>
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    format="hh:mm a"
                                    value={selectedDate}
                                    onKeyPress={(e) => e.preventDefault()}
                                    onChange={this.handleDateChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </MuiPickersUtilsProvider>
                            <div className="pull-right">
                              <Button className="mr-10" color="secondary" onClick={this.handleCancelEdit}>Cancel</Button>
                              <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Update</Button>
                            </div>
                          </div>
                        </ValidatorForm>
                      </>
                      :
                      <div className="border_bx">
                        {log.text}
                        {(log.useremail == current_logUser) && 
                          <div className="hover_show">
                            <IconButton aria-label="Delete" size="small" onClick={(id, date, time, text) => this.handleEditLog(log.logId, log.date, log.time, log.text)}>
                              <IconSmall>edit</IconSmall>
                            </IconButton>
                          </div>
                        }
                      </div>
                    }
                    
                    <p className="font-size-14 pt-10">
                      <Grid container spacing={1}>
                          <Grid item md={2}>
                            <Fab className="small-size overflow-hidden mr-8" color="secondary" size="small">
                              <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                            </Fab> 
                          </Grid>
                          <Grid item md={10}>
                            <span className="font-weight-500">{log.user}</span>  added a note
                          </Grid>
                        </Grid>
                    </p>

                    <p className="mb-0 font-size-14 pt-10">
                      <span className="cursor_poiter" onClick={() => this.toggleComment(log.logId)}>
                        <Icon className="vert-middle"> {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                        Comments ({(log.comments) ? log.comments.length : 0})
                      </span>
                    </p>
                    {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) &&
                      <Grid container spacing={1}>
                        <Grid item className="flex-auto">
                          <div className="border_bx pr-10">
                            {(log.comments && log.comments.length > 0) &&
                              (log.comments).map((cmmt) => (
                                <Grid container className="mb-10" spacing={1}>
                                  <Grid item>
                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                      <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (cmmt.__commentby__.name).charAt(0)}>{(cmmt.__commentby__.name).charAt(0).toUpperCase()}</h5>
                                    </Fab>
                                  </Grid>
                                  {
                                    (this.state.isEditComment && (this.state.commentid == cmmt.id)) ?
                                      <>
                                        <Grid className="mb-10" item md={9}>
                                          <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveEditComment('log')}>
                                            <Grid className="mb-10" item>
                                              <TextValidator
                                                required={true}
                                                id="comment-input"
                                                fullWidth
                                                label="Text"
                                                name="commentvalue"
                                                placeholder="Add Comment"
                                                value={commentvalue}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                                onChange={this.handlechange}
                                              />
                                            </Grid>
                                            {(commentvalue && commentvalue.length > 0) &&
                                              <Grid className="mb-10" item>
                                                <Button className="mr-10" color="secondary" onClick={this.handleCancelEditComment}>Cancel</Button>
                                                <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                              </Grid>
                                            }
                                          </ValidatorForm>
                                        </Grid>
                                      </>
                                      :
                                      <>
                                        <Grid item>
                                          <p className="mt-4 mb-4 font-size-13 text-hint">
                                            {
                                              (cmmt.lastmodifieddate != null)
                                                ?
                                                moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format("hh:mm A")
                                                :
                                                moment.unix(cmmt.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                moment.unix(cmmt.createddate).tz(this.state.timeZone).format("hh:mm A")
                                            }
                                          </p>
                                          <p className="mt-4 mb-4 font-size-13">{cmmt.comment}</p>
                                        </Grid>
                                        {(cmmt.__commentby__.email == current_logUser) &&
                                          <EditCommentMenu allData={this} cmt={cmmt} type="log" />
                                        }
                                      </>
                                  }
                                </Grid>
                              ))
                            }
                            {(!this.state.isEditComment) &&
                              <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveCommentLog('log')}>
                                <Grid container spacing={1}>
                                  <Grid className="mb-10" item >
                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                      <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                    </Fab>
                                  </Grid>
                                  <Grid className="mb-10" item md={9}>
                                    <Grid className="mb-10" item>
                                      <TextValidator
                                        required={true}
                                        id="comment-input"
                                        fullWidth
                                        label="Text"
                                        name="commentvalue"
                                        placeholder="Add Comment"
                                        value={(log.logId == this.state.logid) ? commentvalue : ""}
                                        validators={["required"]}
                                        errorMessages={["this field is required"]}
                                        onChange={(event, logid) => this.handlechange(event, log.logId)}
                                      />
                                    </Grid>
                                    {
                                      (commentvalue && commentvalue.length > 0 && (log.logId == this.state.logid)) &&
                                      <Grid className="mb-10" item>
                                        <Button className="mr-10" color="secondary" onClick={this.handleCancelComment}>Cancel</Button>
                                        <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>
                              </ValidatorForm>
                            }

                          </div>
                        </Grid>
                        <Grid item className="align-self-center">

                        </Grid>
                      </Grid>
                    }
                  </div>
                </div>
              )
            }
            else {
              logbody.push(
                <div className="box_portlet">
                  <div className="box_body">
                    <div className="lead_pl p-0">
                      <p className="font-size-14">
                        <Grid container spacing={1}>
                          <Grid item md={2}>
                            <Fab className="small-size overflow-hidden mr-8" color="secondary" size="small">
                              <h5 className={"text-white m-0 font-weight-normal  avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                            </Fab>
                          </Grid>
                          <Grid item md={10}>
                            <span className="font-weight-500">{log.template}</span>
                            <p className="mt-4 mb-4 font-size-13 text-hint">{log.date}, {log.time}</p>
                            <p className="ml-40 font-size-14">{log.text}</p>
                          </Grid>
                        </Grid>
                      </p>
                    </div>
                  </div>
                </div>
              )
            }
          }
        })
        if (logbody && logbody.length > 0) {
          loghtml.push(logbody);
        }
      }
    }) 

    return (
      <React.Fragment>
        <div className="frame_body">
          <div className="mt-10 ml-16 mr-16">
            <Grid container justify="space-between">
              <Grid item><IconButton size="small" className="ml--4" onClick={handleClose}><Icon>arrow_forward</Icon></IconButton></Grid>
              <Grid item>
                <IconButton size="small"><Icon>turned_in_not</Icon></IconButton>
                <DropdownDefault
                  menuButton={
                    <IconButton size="small" className="ml-4 mr--8">
                      <Icon>more_vert</Icon>
                    </IconButton>
                  }
                >
                  <MenuItem className="flex flex-middle"><Icon className="mr-16 text-muted">launch</Icon>Open in web</MenuItem>
                  <MenuItem className="flex flex-middle"><Icon className="mr-16 text-muted">inbox</Icon> Filer inbox</MenuItem>
                  <MenuItem className="flex flex-middle"><svg viewBox="0 0 24 24" style={{ width: 24 }} className="mr-16" fill="rgba(0, 0, 0, 0.54)" title="LinkedIn" xmlns="http://www.w3.org/2000/svg"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path></svg> In Linkedin</MenuItem>
                  <MenuItem onClick={() => this.fileInput.click()} className="flex flex-middle"><Icon className="mr-16 text-muted">camera_alt</Icon> Change Image</MenuItem>
                  <MenuItem onClick={this.handleDeleteProject} className="flex flex-middle"><Icon className="mr-16 text-muted">delete</Icon>Delete</MenuItem>
                </DropdownDefault>
              </Grid>
            </Grid>
          </div>
          <div className="list_user_profile white_space_nowrap">
            <Grid container spacing={2}>
              <Grid item>
                <input style={{ display: 'none' }} accept="image/*" type="file" onChange={this.handleLeadProfileImage}
                  ref={fileInput => this.fileInput = fileInput}
                />
                {!this.state.projectImage && <Avatar onClick={() => this.fileInput.click()} style={{ cursor: 'pointer' }} className={"avatar_circle color_" + colorClass}>{colorClass.toUpperCase()}</Avatar>}
                {this.state.projectImage && <Avatar onClick={() => this.fileInput.click()} style={{ cursor: 'pointer' }} className="avatar_circle color_a" alt="Lead image" src={this.state.projectImage} />}
              </Grid>
              <Grid item>
                <h5>
                  {this.state.firstname ? this.state.firstname : ""} {this.state.lastname ? this.state.lastname : ""}
                  <IconButtonWhite className="filled_star crm-star-custom-icon" size="small" onClick={(lead, status) => this.handleFavorite(this.state.project_id, this.state.isfavorite)}>
                    <IconSmall>{this.state.star_icon}</IconSmall>
                  </IconButtonWhite>
                </h5>
                <p className="position_text">
                {this.state.title} {(this.state.companyName) && 'at ' + this.state.companyName}</p>
              </Grid>
            </Grid>
            <div className="user__menu">
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Note"
                  // onClick={() => this.setState({ shouldOpenNoteDialog: true })}
                  onClick={() => this.setState({ logtype: 5, logvaluetype: 'Note', shouldOpenCreateLogDialog: true })}
                >
                  <Icon className="primary_color">library_books</Icon>
                </Fab>
                <div className="text-gray">Note</div>
              </div>
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Email"
                >
                  <Icon className="primary_color">email</Icon>
                </Fab>
                <div className="text-gray">Email</div>
              </div>
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Log"
                  onClick={() => this.setState({ logtype: 0, logvaluetype: 'Phone Call', shouldOpenCreateLogDialog: true })}
                >
                  <Icon className="primary_color">add_circle</Icon>
                </Fab>
                <div className="text-gray">Log</div>
              </div>
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Task"
                  onClick={() => this.opeAddNewTaskPopup()}
                >
                  <Icon className="primary_color">storage</Icon>
                </Fab>
                <div className="text-gray">Task</div>
              </div>
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Meet"
                  onClick={() => this.setState({ logvaluetype: 'Meeting', shouldOpenCreateLogDialog: true })}
                >
                  <Icon className="primary_color">calendar_today</Icon>
                </Fab>
                <div className="text-gray">Meet</div>
              </div>
            </div>
             
            {shouldOpenNoteDialog && (
              <NoteDialog
                handleClose={this.handleDialogClose}
                open={shouldOpenNoteDialog}
              />
            )}
          </div>
          <Tabs
            value={TabValue}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className="lead_tabs"
          >
            <Tab label="About" {...a11yProps(0)} />
            <Tab label="Activity" {...a11yProps(1)} />
            <Tab label="Related" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={TabValue} index={0} className="rightbar_vscroll">
            <ProfileDetails project_id={this.state.project_id} />
          </TabPanel>
          <TabPanel value={TabValue} index={1} className="rightbar_vscroll">
            {/* <Grid container className="text-center flex-nowrap mb-16 activity_summary">
              <Grid item xs={4}>
                <span className="font-weight-500 font-size-15 text-primary">{this.state.interaction}</span>
                <p className="m-0 text-gray">Interactions</p>
              </Grid>
              <Grid item xs={4}>
                <span className="font-weight-500 font-size-15 text-primary">{this.state.lastcontacted}</span>
                <p className="m-0 text-gray">Last contacted</p>
              </Grid>
              <Grid item xs={4}>
                <span className="font-weight-500 font-size-15 text-primary">{this.state.inactivedays}</span>
                <p className="m-0 text-gray">Inactive days</p>
              </Grid>
            </Grid> */}
            <div className="pl-16 pr-16">
              {/* <Grid container justify="space-between" alignItems="center">
                                <Grid item><h5 className="mb-0 text-muted">Today</h5></Grid>
                                <Grid item className="d-flex align-items-center">
                                    <Fab className="bg-dark small-size overflow-hidden" size="small">
                                        <h5 className="text-primary m-0 font-weight-normal">JS</h5>
                                    </Fab> <FiltersMenu />
                                </Grid>
                            </Grid> */}
            </div>
            <ul className="sidebar_activity crm-leads-sidebar-logs-list">
              <FiltersMenu proVar={this.state.activities} thisData={this} />
              <div>
                {loghtml.map((day_log) => (
                  <div className="lead_upcoming">
                    <div className="box_portlet">
                      {day_log}
                    </div>
                  </div>
                ))}
              </div>
            </ul>
          </TabPanel>
          <TabPanel value={TabValue} index={2} className="rightbar_vscroll">
            <div className="rightbar_related v_scroll">
              {this.state.project_id && <ProfileRight project_id={parseInt(this.state.project_id)}/>}
            </div>
          </TabPanel>
          <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={this.state.isDeleteConfirmDialoOpen}
            onClose={this.toggleDeleteDialog}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="ar-remove-dialog-title"
          >
            <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove?"}</DialogTitle>
            <DialogActions>
              <Button onClick={this.toggleDeleteDialog} color="primary">
                Cancel
                                      </Button>
              <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">
                Ok
                                      </Button>

            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={this.state.isDeleteLogConfirmDialoOpen}
            onClose={this.toggleDeleteLogDialog}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="ar-remove-dialog-title"
          >
            <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove selected project log ?"}</DialogTitle>
            <DialogActions>
              <Button onClick={this.toggleDeleteLogDialog} color="primary">
                Cancel
                                          </Button>
              <Button type="Button" onClick={() => this.confirmDeleteLog()} className="btn_Primary">
                Ok
                                          </Button>

            </DialogActions>
          </Dialog>
          {this.state.shouldOpenViewEmailDialog && (
            <ViewHtmlDialog
              handleClose={this.handleViewEmailDialogClose}
              open={this.state.shouldOpenViewEmailDialog}
              handleMinimize={this.handleViewEmailDialogMinimize}
              handleFull={this.handleViewEmailDialogFull}
              minimizeScreen={this.state.minimizeViewEmailDialog}
              fullScreen={this.state.FullViewEmailDialog}
              logDatas={this.state.viewHtmlData}
            />
          )}
          {shouldOpenCreateLogDialog && (
            <CreatelogDialog
              handleClose={() => { this.setState({ shouldOpenCreateLogDialog: false }) }}
              open={shouldOpenCreateLogDialog}
              handleMinimize={this.handleProjectDialogMinimize}
              handleFull={this.handleProjectDialogFull}
              minimizeScreen={this.state.minimizeConvertProjectDialog}
              fullScreen={this.state.FullConvertProjectDialog}
              project_id={this.state.project_id}
              logvaluetype={this.state.logvaluetype}
              logtype={this.state.logtype}
            />
          )}
          {shouldOpenCreateTaskDialog && (
            <CreateNewTaskDialog
              handleClose={this.handleTaskDialogClose}
              open={shouldOpenCreateTaskDialog}
              handleMinimize={this.handleTaskDialogMinimize}
              handleFull={this.handleTaskDialogFull}
              minimizeScreen={minimizeTaskDialog}
              fullScreen={FullCreateTaskDialog}
              allstates={this.state}
              dataFromSidebar={this.state.newTaskData}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
  projectReducer: state.projectReducer,
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});

const mapDispatchToProps = dispatch => {
  return {
    addLogPined: (client, request) => dispatch(actionCreators.addLogPined(client, request)),
    deleteProject: (client, request) => { dispatch(actionCreators.deleteProject(client, request)) },
    addFavouriteProject: (client, request) => { dispatch(actionCreators.addFavouriteProject(client, request)) },

    getActivityTypes: (client) => { dispatch(actionCreators.getActivityTypes(client)) },
    getProjectActivityLogList: (client, request) => dispatch(actionCreators.getProjectActivityLogList(client, request)),

    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    updateProjectDetail: (client, request) => { dispatch(actionCreators.updateProjectDetail(client, request)) },
    getCountryList: (client) => dispatch(actionCreators.getCountryList(client)),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),

    saveProjectLog: (client, request) => dispatch(actionCreators.saveProjectLog(client, request)),
    deleteProjectLogDetail: (client, request) => dispatch(actionCreators.deleteProjectLogDetail(client, request)),
    updateProjectLog: (client, request) => dispatch(actionCreators.updateProjectLog(client, request)),
    addProjectLogComment: (client, request) => dispatch(actionCreators.addProjectLogComment(client, request)),
    updateProjectLogComment: (client, request) => dispatch(actionCreators.updateProjectLogComment(client, request)),
    deleteProjectLogComment: (client, request) => dispatch(actionCreators.deleteProjectLogComment(client, request)),
    getRelatedList: (client) => dispatch(actionCreators.getRelatedList(client)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectProfileFrame);
