import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as dataSet from './dataSet';
import Server from "../../Common/Server"

const config = {
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
};

export const registerUser = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + "UserRegister/RegisterUser/", value)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                let newstate = Object.assign(return_data, value)
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.REGISTER_USER, newstate));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};


export const loginWithGoogle = (value) => {
    return function (dispatch) {
        value.type = "web";
        return axios.post(Server.API + "GoogleLoginUser/GoogleLogin", value)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                if (data.error == false) {
                    if (data.data !== null) {
                        setUserSession(data.data);
                        dispatch(setResponseDetails(actionTypes.LOGIN_WITH_EMAIL_AND_PASSWORD, return_data));
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                }
                else
                {
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                }
            })
            .catch((error) => {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}


export const resendEmail = (value) => {
    console.log("in resend email")
    return function (dispatch) {
        return axios.post(Server.API + "MailResend/ResendMail", value)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                let newstate = Object.assign(return_data, value)
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.RESEND_EMAIL, newstate));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};




export const forgotPasswordRequest = (value) => {
    return dispatch => {
        return axios.post(Server.API + 'ForgotPassword/ForgotPasswordRequest', value).then(({ data }) => {
            let return_data = dataSet.processData(data);
            dispatch(setResponseDetails(actionTypes.FORGOT_PASSWORD_REQUEST, return_data));
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
            if (data.error == false) { 
                setTimeout(function() {
                    window.location = '/sign-in' 
                }, 3000);
            }
        }).catch((error) => {
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
        });
    }
};
export const loginWithEmailAndPassword = (data) => {
    return function (dispatch) {
        return axios.post(Server.API + "LoginUser/Login", data, config)
            .then(({ data }) => {
                console.log(data)
                let return_data = dataSet.processData(data);
                if (data.error == false) {
                    console.log("fdfsdfsdfdf===", data.data)
                    if (data.data !== null) {
                        setUserSession(data.data);
                        dispatch(setResponseDetails(actionTypes.LOGIN_WITH_EMAIL_AND_PASSWORD, return_data));
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                } else {
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                }
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
}


export const userVerify = (data) => {
    return dispatch => {
        return axios.post(Server.API + 'VerifyUser/UserVerify', data, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.USER_VERIFY, return_data));
                if (data.error == false) {
                    setTimeout(function() {
                        window.location = '/sign-in';
                    },1000);
                }
            }).catch((error) => {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    }
};

export const notifications = (value) => {
    return {
        type: actionTypes.NOTIFICATIONS,
        val: value
    }
}


export const forgotPassword = (value) => {
    console.log("function_forgot_password");
    return {
        type: actionTypes.FORGOT_PASSWORD,
        val: value
    };
};

export const userLogin = (value) => {
    console.log("function_userLogin", value);
    // return {
    //     type:actionTypes.USER_LOGIN,
    //     val:value
    // };

    return function (dispatch) {

        return axios.post(Server.API + "LoginUser/Login/", value)
            .then(({ data }) => {
                dispatch(setResponseDetails(actionTypes.USER_LOGIN, data));
            });
    };
};
export const add = (value) => {
    return {
        type: actionTypes.ADD,
        val: value
    };
};
export const subtract = (value) => {
    return {
        type: actionTypes.SUBTRACT,
        val: value
    };
};
export const decrement = (value) => {
    return {
        type: actionTypes.DECREMENT,
        val: value
    };
};

const setUserSession = (data) => {
    if (data.sessiontoken) {
        localStorage.setItem("jwt_token", data.sessiontoken);
        localStorage.setItem("user_data_session", JSON.stringify(data));
        console.log("dasasd")
        setTimeout(function(){
            window.location = '/dashboard/info';
        },2000);
        
        //axios.defaults.headers.common["Authorization"] = "Bearer " + data.sessiontoken;
    } else {
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("user_data_session");
        // delete axios.defaults.headers.common["Authorization"];
    }
};

export const checkMultiCompany = (data) => {
    return dispatch => {
        return axios.post(Server.API + 'UpdateToken/TokenUpdate', data, config)
            .then(({ data }) => {
                console.log("data", data);
                if (data.error == false) {
                    setUserSession(data.data);
                    window.location.reload()
                }
            }).catch((error) => {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    }
};

const setResponseDetails = (newType, data) => {
    return {
        type: newType,
        val: data
    };
}
const notificationAll = (newType, data) => {
    return {
        type: newType,
        val: data
    }
}
const errorMessageAll = () => {
    return {
        notify_msg: "Something went wrong try again later.",
        error: true
    }
}

export const clearNotification = (value) => {
    return function (dispatch) {
        dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS,{},false));
    }
}
const clearNotificationAll = (newType, data,status) => {
    return {
        type: actionTypes.NOTIFICATIONS,
        val: '',
        status: '',
    }
}
