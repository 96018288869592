import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { CreateFieldsDynamicLeadEdit } from './CreateFieldsDynamicLeadEdit'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
class ProfileDetails extends Component {
  state = {
    opportunities_id:"",
    lastname: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray: [],
    companyCurrencyData: [],
    focusValue: false,
    fieldsList: [],
    fieldsObj: {
      
    },
    systemTagsList: [],
    isUpdate: true,
    isopportunitiesdetailUpdate: true,
    opportunityFieldData: [],
    usersListArray:[],
    fnameError:false,
    userSettings:"",
    isAnyValueChange:false,
    selectedPipelineId: "",
    pipelineListArray: [],
    stageArray: [],
    comp_options:[],
    comp_value:'',
    primarycontact_value:"",
    primarycontact_options:[],
    pipeline_id_createpopup:"",
    isPipelineLoaded:true,
  };

  // componentWillMount() {
componentWillUnmount() {
      this.props.settingReducer.pipelineListArray.is_loaded = true
}
componentDidMount(){
    let val = { typeId: 4 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.getCompanyRecList(this.props.apolloClient.client);
    this.props.getContactRecList(this.props.apolloClient.client);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    
    if(this.props.opportunities_id && (this.state.opportunities_id != this.props.opportunities_id)){
      this.props.getOpportunitiesField(this.props.apolloClient.client,parseInt(this.props.opportunities_id));
      this.setState({
        opportunities_id:parseInt(this.props.opportunities_id),
        isUpdate:true,
      },()=>this.forceUpdate())
    }
    
      
  }
  componentWillUnmount() {
    // emailLogEmail  
    this.props.opportunityReducer.opportunityFieldData = [];
    this.props.opportunityReducer.opportunityLogList = [];
    this.props.opportunityReducer.opportunityLogSidebarList = [];
  }
  componentWillReceiveProps(props) {
    if (props.settingReducer.companyRecList && props.settingReducer.companyRecList.data && props.settingReducer.companyRecList.data.data){
      let companyOptions = [];
      props.settingReducer.companyRecList.data.data.map((compOpt)=>{
        companyOptions.push({ label: compOpt.name, value: compOpt.company_id});
      })
      this.setState({ comp_options: companyOptions});
    }
      if (props.settingReducer.contactRecList && props.settingReducer.contactRecList.data && props.settingReducer.contactRecList.data.data){
      let contactOptions = [];
      props.settingReducer.contactRecList.data.data.map((conOpt)=>{
        contactOptions.push({ label: conOpt.name, value: conOpt.contact_id});
      })
      this.setState({ primarycontact_options: contactOptions});
    }
    //Pipelines list array
    if (props.settingReducer.pipelineListArray.data && this.state.isPipelineLoaded) {
      props.settingReducer.pipelineListArray.is_loaded = false
        var pipeData = props.settingReducer.pipelineListArray.data
        //Apply pipeline id to localstorage
        var selectedPipelineId = localStorage.getItem('selected_pipeline_id');
        if(!selectedPipelineId){
            localStorage.setItem('selected_pipeline_id',"1");
        }else{
            selectedPipelineId = localStorage.getItem('selected_pipeline_id');
        }
        selectedPipelineId = localStorage.getItem('selected_pipeline_id');
        selectedPipelineId = selectedPipelineId.toString()
        var ky = this.findWithAttr(pipeData, 'id', selectedPipelineId);
        var pipelineArray = pipeData[ky];
        console.log("pipelineArray", pipelineArray);
        this.setState({ 
            pipelineListArray: pipeData,
            stageArray: pipelineArray.stage,
            fieldsObj: {
              ...this.state.fieldsObj,
              ["pipeline"]: { fieldid: "41", values:pipelineArray},
              ["stage"]: { fieldid: "47", values:pipelineArray.stage[0]},
              ["winpercentage"]: { fieldid: "52", opportunitiesvalue:pipelineArray.stage[0].probability},
            },
            pipelineListArray: pipeData,
            selectedPipelineId: selectedPipelineId,
            //pipeline_id_createpopup: selectedPipelineId,
        },()=>{
            this.forceUpdate()
            if(!this.state.pipeline_id_createpopup){
              this.setState({
                  pipeline_id_createpopup: selectedPipelineId,
                  isPipelineLoaded:false,
              })
            }
        })
    }
    //storing user setting data in state
    if(props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
        const settingData = props.settingReducer.userSettingArray.data;
        this.setState({
            userSettings:settingData,
        })
    }

    if(props.opportunities_id && (this.state.opportunities_id != props.opportunities_id) || this.state.isUpdate && props.opportunities_id != undefined){
      props.getOpportunitiesField(props.apolloClient.client,parseInt(props.opportunities_id));
      
      let logParams = { opportunitiesid: parseFloat(props.opportunities_id), activitypeid: [], logtypeid:[] }
      this.props.getOpportunitiesLogListNew(this.props.apolloClient.client, logParams);

      // if(props.opportunityReducer.opportunityFieldData.data )
      // {
      //   var emailkey1 = this.findWithAttr(props.opportunityReducer.opportunityFieldData.data, 'fieldkey', "email");
      //   if (emailkey1 != -1)
      //   {
      //     let emailData = props.opportunityReducer.opportunityFieldData.data[emailkey1];
      //     if (emailData.opportunitiesdetail[0] && emailData.opportunitiesdetail[0].values && emailData.opportunitiesdetail[0].values.length > 0)
      //     {
      //       localStorage.setItem('emailLogEmail', emailData.opportunitiesdetail[0].values[0].value);
      //       let logParams = { leadid: parseFloat(props.opportunities_id), emailid: emailData.opportunitiesdetail[0].values[0].value }
      //       //this.props.getOpportunitiesLogListNew(this.props.apolloClient.client, logParams);
      //     }
      //     else {
      //       localStorage.removeItem('emailLogEmail');
      //     }
      //   }
      // }
      
      this.setState({
        opportunities_id:parseInt(props.opportunities_id),
      },()=>this.forceUpdate())
    }


    
    if (props.settingReducer.opportunitiesCustomFieldList.data && this.state.fieldsList.length == 0) {
      const customFields = props.settingReducer.opportunitiesCustomFieldList.data;
      this.setState({ fieldsList: customFields })
      var statuskey = this.findWithAttr(customFields, 'id', "45");
      var prioritykey = this.findWithAttr(customFields, 'id', "51");
      var defaultKeyStatus = this.findWithAttr(customFields[statuskey].options, 'id', 4);
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["status"]: {fieldid: "45",values:customFields[statuskey].options[defaultKeyStatus]},
          ["priority"]: {fieldid: "51",values:customFields[prioritykey].options[0]},
          ["created"]: {fieldid: "49",values:this.state.selectedDate},
        }
      })
    }
    
    if (props.settingReducer.countryListGql.data && this.state.countryListArray.length == 0) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      // this.setState({
      //   fieldsObj: {
      //     ...this.state.fieldsObj,
      //     ["owner"]: { fieldid: "4", values:usersListArray[companyOwnerKey]}
      //   }
      // })
    }


    if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null && this.state.companyCurrencyData.length == 0) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }

    if (props.opportunityReducer.opportunityFieldData.data && props.opportunityReducer.opportunityFieldData.is_loaded) {
      this.setState({
        fieldsObj: {},
        comp_value:'',
        primarycontact_value:"",
      },() => {
          let fieldsObj = this.state.fieldsObj;
          this.setState({opportunityFieldData:props.opportunityReducer.opportunityFieldData.data})
          let country = "";
          const opportunityDataForEdit = props.opportunityReducer.opportunityFieldData.data;
          var tempStages = []
          var promise = new Promise((resolve, reject) => {
              opportunityDataForEdit.map((v, i) => {
                if (v.id == '44' ||  v.id == '42') {
                  if (v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0) {
                    var optId = parseInt(v.opportunitiesdetail[0].values[0].id);
                    if(v.id == '42'){//primary contact
                      this.setState({ primarycontact_value: optId});
                    }else if(v.id == '44'){//company
                      this.setState({ comp_value: optId});
                    }
                  }
                }else if (v.fieldtype.id == '1' && v.id == '40') {//for first/last name          
                  if(v.opportunitiesdetail.length > 0){
                    var data = {
                      fieldid: v.id,
                      opportunitiesvalue: v.opportunitiesdetail[0].fieldvalue
                    }
                    fieldsObj = {
                      ...fieldsObj,
                      [v.fieldkey]: data,
                    }
                  }
                }
                else if (v.fieldtype.id == '1' && v.id == '46') {//for owner field        
                  if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length && v.opportunitiesdetail[0].values[0].id){
                    var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id',v.opportunitiesdetail[0].values[0].id);
                    if(companyOwnerKey !== -1){
                      var ownerData = {
                        fieldid: v.id,
                        values: this.state.usersListArray[companyOwnerKey]
                      }
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: ownerData,
                      }
                    }
                  }
                }else if (v.fieldtype.id == '1' && v.fieldkey=='tags') {//for tag input
                  var tagArray = []
                  if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                    v.opportunitiesdetail[0].values.map((v,i)=>{
                      tagArray.push(v.value);
                    })
                  }
                  var data = {
                    fieldid: v.id,
                    values: tagArray
                  }
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: data,
                  }
                }else if (v.fieldtype.id == '1' && v.id == '50') {//For value with currency Field
                  if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                    var data = {
                      fieldid: v.id,
                      opportunitiesvalue: v.opportunitiesdetail[0].values[0].value
                    }
                    fieldsObj = {
                      ...fieldsObj,
                      [v.fieldkey]: data,
                    }
                  }
                }else if ((v.fieldtype.id == '1' && v.id != '56' && v.id != '46' && v.id != '44' && v.id != '42') || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
                  if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail.length > 0){
                    var data = {
                      fieldid: v.id,
                      opportunitiesvalue: v.opportunitiesdetail[0].fieldvalue
                    }
                    fieldsObj = {
                      ...fieldsObj,
                      [v.fieldkey]: data,
                    }
                  }
                }

               if (v.fieldtype.id == '2') {//TextArea
                  if(v.opportunitiesdetail.length > 0){
                    var data = {
                      fieldid: v.id,
                      opportunitiesvalue: v.opportunitiesdetail[0].fieldvalue
                    }
                    fieldsObj = {
                      ...fieldsObj,
                      [v.fieldkey]: data,
                    }
                  }
                }

                if(v.fieldtype.id == "3"){//checkboxes
                  if(v.opportunitiesdetail.length > 0){
                    var data = {
                      fieldid: v.id,
                      opportunitiesvalue: v.opportunitiesdetail[0].fieldvalue
                    }
                    fieldsObj = {
                      ...fieldsObj,
                      [v.fieldkey]: data,
                    }
                  }
                }
                //if(this.state.fieldsList && this.state.fieldsList.length >0){
                  if (v.fieldtype.id === '4') {//dropdown
                    if(v.fieldid === "54" ||  v.fieldid === "45" || v.fieldid === "51"){//54-visibility,48-source,45-status,51-Priority
                      if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                        var optId = this.findWithAttr(v.options, 'id',v.opportunitiesdetail[0].values[0].id);
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: {fieldid: v.id,values:v.options[optId]},
                        }
                      }
                    }else if(v.id === "41"){//pipeline
                      if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                        var optId = this.findWithAttr(v.options, 'id',parseInt(v.opportunitiesdetail[0].values[0].id));
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: {fieldid: v.id,values:v.options[optId]},
                        }
                        tempStages = v.options[optId].stage
                        this.setState({
                          stageArray:v.options[optId].stage
                        },() => {
                        })
                      }
                    }else if(v.id === "47"){//stage
                      if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                        var optId = this.findWithAttr(tempStages, 'id',parseInt(v.opportunitiesdetail[0].values[0].id));
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: {fieldid: v.id,values:tempStages[optId]},
                        }
                      }
                    }else{
                      if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                        var optId = this.findWithAttr(v.options, 'id',parseInt(v.opportunitiesdetail[0].values[0].id));
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: {fieldid: v.id,values:v.options[optId]},
                        }
                      }
                    }
                  }
                  if (v.fieldtype.id === '5') {//multi dropdown
                    if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].values.length > 0){
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: {fieldid: v.id,values:v.opportunitiesdetail[0].values},
                      }
                    }
                  }
                  if (v.fieldtype.id === '8') {
                    if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].fieldvalue != ""){
                      //var dt = moment.unix(v.opportunitiesdetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                      var dt = new Date(moment(v.opportunitiesdetail[0].fieldvalue).format('YYYY-MM-DD'))
                      dt = new Date(dt)
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: {fieldid: v.id,values:dt},
                      }
                    }else if(v.opportunitiesdetail.length > 0 && v.opportunitiesdetail[0].fieldvalue == ""){
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: {fieldid: v.id,values:null},
                      }
                    }
                  }
              })

              this.setState({
                fieldsObj,
                country,
                isUpdate:false,
                isAnyValueChange:false
              },() => {
                resolve(true)     
              })
          });

          promise.then( result => {
            props.opportunityReducer.opportunityFieldData.is_loaded = false;
            this.setState({
             isAnyValueChange:false
            },() => {
            })
          });
      })
    }
  }

  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;
    var keyName = key;
    var keyVal = data;
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      },
      isAnyValueChange:true
    },() => {
      // setTimeout(() => {
      //   //dont upadte lead if first name is empty
      //   if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].opportunitiesvalue == ""){
      //     return false
      //   }
      //   //dont allow to update if value length 1 and value is blank
      //   if(keyVal.values.length == 1 && keyVal.values[0].value == ""){
      //     return false
      //   }
      //   if (this.state.fieldsObj[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 2500);
    })
  }

  handleNormalFields = (event) => {
    var keyName = event.target.name;
    var keyVal = event.target.value;
    this.setState({
      [event.target.name]: event.target.value,
      isAnyValueChange:true
    },() => {
      //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].opportunitiesvalue == ""){
      //   return false
      // }
      // setTimeout(() => {
      //   if (this.state[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 1500);
    })
  };

  handleCountryChange = (event, values) => {
    this.setState({
      country: values,
      isAnyValueChange:true
    },() => {
      //dont upadte lead if first name is empty
      if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].opportunitiesvalue == ""){
        return false
      }
      setTimeout(() => {
        if (this.state.country == values) {
          this.handleFormSubmit()
        }
      }, 500);
    })
  }

  focusInValue = () => {
    this.setState({
      focusValue: true,
    }, () => this.forceUpdate())
  };


  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleCompanyChange = (val , newValue) => {
    let id =  (newValue.__isNew__) ? "0" : newValue.value;
    let comp_values = {srno: "1", id: id, value: newValue.label };
    this.handleChange({ fieldid: "44", values: [comp_values] }, 'company','allowSubmitChange');
    this.setState({ comp_value: id });
  };
  handlePrimaryContactChange = (val , newValue) => {
    let id =  (newValue.__isNew__) ? "0" : newValue.value;
    let contact_values = {srno: "1", id: id, value: newValue.label };
    this.handleChange({ fieldid: "42", values: [contact_values] }, 'primarycontact','allowSubmitChange');
    this.setState({ primarycontact_value: id });
  };

  handleChange = (val, name,isSubmit) => {
    var winVal = "";
    if(val.fieldid === "41"){//pipeline dorpdown change
      this.setState({
        stageArray: val.values.stage,
        pipeline_id_createpopup: val.values.id,
      },()=>{
        this.forceUpdate()
        this.setState({
          fieldsObj: {
            ...this.state.fieldsObj,
            ["stage"]: { fieldid: "47", values:val.values.stage[0]},
            ["winpercentage"]: { fieldid: "52", opportunitiesvalue:val.values.stage[0].probability},
          }
        })
      })
    }else if(val.fieldid === "47"){//stage dorpdown change
      winVal = { fieldid: "52", opportunitiesvalue:val.values.probability}
    }
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if(key != -1 && this.state.fieldsList[key].fieldtype.id != "4"  && this.state.fieldsList[key].fieldtype.id != "8"){
      if(this[name]){
        this[name].validate(val.value);
      }
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val,
        ['winpercentage']:winVal?winVal:(val.fieldid == "52")?val:this.state.fieldsObj['winpercentage']
      },
      isAnyValueChange:true
    },() => {
      
      if(!isSubmit){
        return false
      }
      setTimeout(() => {
        if (this.state.fieldsObj[name] == val) {
          console.log("this.state.fieldsObj", this.state.fieldsObj);
          this.handleFormSubmit()
        }
      }, 500);
    })
  };

  focusOutSubmitForm = (event,ref) => {
    console.log("this[ref]", this[ref]);
    if(this[ref].props && this[ref].props.id == "tagInputId1"){
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      },10)
    }
    this[ref].validate(event.target.value);
    setTimeout(() => {
      if (this.state.isAnyValueChange) {
        this.handleFormSubmit()
      }
    }, 0);
  }

  handleFormSubmit = () => {
    this.updateLeadForm.isFormValid(false).then(isValid => {
      this.setState({
        isAnyValueChange:false
      }, () => this.forceUpdate())

      if(isValid){
        let logData = [];
        let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
        console.log("this.state.fieldsObj", this.state.fieldsObj);
        submitData = Object.values(submitData).map((row)=>{
          if(row.fieldid == "50"){
            row.values = [{id:this.state.companyCurrencyData.currencyid.toString(),srno:"1",value:row.opportunitiesvalue}]
            delete row['opportunitiesvalue'];
            if(row.values[0].value == ""){
              row.values = []
            }
          }
          
           
          if(row.fieldid === "54" || row.fieldid === "48" || row.fieldid === "45" || row.fieldid === "51" || row.fieldid === "41" || row.fieldid === "47"){//54-visibility,48-source,45-status,51-Priority
            row.values = [{srno:"1", id:row.values.id,value:row.values.name}]
          }
          var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
          if(this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false){
            row.values = [{srno:"1",id:row.values.id,value:row.values.optionvalue}]
          }
          if(this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false){
            var ar = [];
            if(row.values.length >0){
              row.values.map((val,i)=>{
                var optName;
                if(val.optionvalue){
                  optName = val.optionvalue
                }else{
                  optName = val.value
                }
                ar.push({srno:(i+1).toString(),id:val.id, value:optName})
              })
            }
            row.values = ar
          }

          //validating date fiels
          if(this.state.fieldsList[key].fieldtype.id == "8"){//date field
            if(row.values == null){
              row.opportunitiesvalue = "";
              delete row.values;
            }else if(row.values == "Invalid Date"){
              return false
            }else{
              // row.opportunitiesvalue = moment(row.values).format("X");

              // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
              // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
              // row.opportunitiesvalue = moment.utc(logdate).format('X');
              var dt = moment(row.values).format('YYYY-MM-DD')
              row.opportunitiesvalue = dt;

              delete row.values;
            }
          }

          if(row.fieldid == "56"){
            if(row.values.length > 0){
              var objArr = [];
              row.values.map((val,k)=>{
                var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
                var tagId = "0";
                if(foundKey !== -1){
                  tagId = this.state.systemTagsList[foundKey].id
                }
                objArr.push({"srno":(k+1).toString(),value:val.toLowerCase(),id:tagId,isdeleted:"0"})
              })

              //add tags from props value which is removed   with id deleted id "1"
              var tagsKeyFromProps = this.findWithAttr(this.props.opportunityReducer.opportunityFieldData.data, 'id', "56");
              if(this.props.opportunityReducer.opportunityFieldData.data[tagsKeyFromProps].opportunitiesdetail.length>0){
                var oldTagsValues = this.props.opportunityReducer.opportunityFieldData.data[tagsKeyFromProps].opportunitiesdetail[0].values;
                if(oldTagsValues.length > 0){
                  oldTagsValues.map((v,k)=>{
                    var checkKey = this.findWithAttr(objArr, 'value', v.value);
                    if(checkKey === -1){
                      v.isdeleted = "1";
                      v.srno = (objArr.length + 1).toString();
                      objArr.push(v);
                    }
                  })
                }
              }

              row.values = objArr
              if(this.props.opportunityReducer.opportunityFieldData.data[tagsKeyFromProps].opportunitiesdetail.length>0){
                this.props.opportunityReducer.opportunityFieldData.data[tagsKeyFromProps].opportunitiesdetail[0].values = objArr;  
              }
            }
          }

          if(row.fieldid == "8"){//address field
            row.opportunitiesvalue = {
              street:row.opportunitiesvalue,
              city:this.state.city,
              state:this.state.addressState,
              zipcode:this.state.zipcode,
              country:(this.state.country && this.state.country.name)?this.state.country.name:""
            }
          }
          if(row.fieldid == "46"){//owner value set
           if(row.values){
              row.values = [{"srno":"1",id:row.values.id,value:row.values.email,name:row.values.name}];
            }else{
              row.values = [];
            }
            delete row.opportunitiesvalue;
          }
          return row;
        })

        //adding  opportunitiesdetailid for update object from this.state.opportunityFieldData
        submitData = submitData.map((row,i)=>{
          var key = this.findWithAttr(this.state.opportunityFieldData, 'id', row.fieldid);
          if(key !== -1){
            if(this.state.opportunityFieldData[key].opportunitiesdetail.length > 0){
              row.opportunitiesdetailid = this.state.opportunityFieldData[key].opportunitiesdetail[0].id;
            }else{
              row.opportunitiesdetailid = ""
            }
          }


          return row
        })

        //filter null and undefined object 
        submitData = submitData.filter(function(element) {
           return element !== undefined;
        });
        
        //not to submit if any field is false
        var allowToSubmit = true
        submitData.map((row)=>{
          if(!row){
            allowToSubmit = false
          }
        })
        console.log("submitData", submitData);
        //return
        
        if(this.state.opportunities_id && allowToSubmit){
          // update lead field data after update.... (For log)
          this.setState({ isUpdate: true });
          // -- ---------------

          let req = {
            opportunitiesdata:submitData,
            opportunitiesid:this.state.opportunities_id,
            sidebarSubmit:true,
          }
          this.props.updateOpportunitiesDetail(this.props.apolloClient.client,req);
        }
      }
    });
  };

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1)
        arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1)
        arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }
  render() {
    // console.log("this.state.pipelineListArray", this.state.pipelineListArray);
    return (
      <div className="v_scroll position-relative label-size-14 pr-16">
        <ValidatorForm ref={updateLeadForm => this.updateLeadForm = updateLeadForm} noValidate instantValidate={false}  onSubmit={this.handleFormSubmit}>
          <Grid container spacing={2}>
            {CreateFieldsDynamicLeadEdit(this)}
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  opportunityReducer: state.opportunityReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    getOpportunitiesLogListNew: (client, leadid) => dispatch(actionCreators.getOpportunitiesLogListNew(client, leadid)),
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    updateOpportunitiesDetail: (client, request) => { dispatch(actionCreators.updateOpportunitiesDetail(client, request)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getOpportunitiesField: (client,leadId) => dispatch(actionCreators.getOpportunitiesField(client,leadId)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
    getCompanyRecList: (client) => { dispatch(actionCreators.getCompanyRecList(client)) },
    getContactRecList: (client) => { dispatch(actionCreators.getContactRecList(client)) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDetails);