import React, { Component } from "react";
import { IconButton, Icon, MenuItem, withStyles, Fab } from "@material-ui/core";
import DropdownDefault from "../Layout/DropdownDefault";
import NoteDialog from "./NoteDialog";
import ProfileDetails from "./ProfileDetails";

const IconButtonWhite = withStyles(theme => ({
    root: {
        // color: theme.palette.getContrastText(purple[500]),
        backgroundColor: "transparent",
        padding: "5px"
    }
}))(IconButton);

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
    }
}))(Icon);
class ProfileLeft extends Component {
    state = {
        shouldOpenNoteDialog: false,
    };
    handleDialogClose = () => {
        this.setState({ shouldOpenNoteDialog: false });
    };
    render() {
        let { shouldOpenNoteDialog } = this.state;
        return (
            <aside className="sidenav">
                <div className="sidenav__user">
                    <div className="more_vert_menu">
                        <DropdownDefault
                            menuButton={
                                <IconButton
                                    aria-label="More"
                                    aria-haspopup="true"
                                >
                                    <Icon>more_vert</Icon>
                                </IconButton>
                            }
                        >
                            <MenuItem>Lookup LinkedIn</MenuItem>
                            <MenuItem>Customize detail field order</MenuItem>
                            <MenuItem>Delete</MenuItem>
                        </DropdownDefault>
                    </div>
                    <div className="username-photo">
                        <img src="/assets/images/face-7.jpg" alt="user" />
                        <span className="username">
                            John williamson
                            <IconButtonWhite className="filled_star" size="small">
                                <IconSmall>star</IconSmall>
                            </IconButtonWhite>
                        </span>
                        <span className="user_designation">cto at startup</span>
                    </div>
                    <div className="user__menu">
                        <div className="text-center">
                            <Fab
                                size="small"
                                color="primary"
                                className="btn-primary"
                                aria-label="Note"
                                onClick={() => this.setState({ shouldOpenNoteDialog: true })}
                            >
                                <Icon className="primary_color">library_books</Icon>
                            </Fab>
                            <div className="text-gray">Note</div>
                        </div>
                        <div className="text-center">
                            <Fab
                                size="small"
                                color="primary"
                                className="btn-primary"
                                aria-label="Email"
                            >
                                <Icon className="primary_color">email</Icon>
                            </Fab>
                            <div className="text-gray">Email</div>
                        </div>
                        <div className="text-center">
                            <Fab
                                size="small"
                                color="primary"
                                className="btn-primary"
                                aria-label="Log"
                            >
                                <Icon className="primary_color">add_circle</Icon>
                            </Fab>
                            <div className="text-gray">Log</div>
                        </div>
                        <div className="text-center">
                            <Fab
                                size="small"
                                color="primary"
                                className="btn-primary"
                                aria-label="Task"
                            >
                                <Icon className="primary_color">storage</Icon>
                            </Fab>
                            <div className="text-gray">Task</div>
                        </div>
                        <div className="text-center">
                            <Fab
                                size="small"
                                color="primary"
                                className="btn-primary"
                                aria-label="Meet"
                            >
                                <Icon className="primary_color">calendar_today</Icon>
                            </Fab>
                            <div className="text-gray">Meet</div>
                        </div>
                    </div>
                    {shouldOpenNoteDialog && (
                        <NoteDialog
                            handleClose={this.handleDialogClose}
                            open={shouldOpenNoteDialog}
                        />
                    )}
                </div>
                <div className="ml-16 mr-0">
                    <ProfileDetails />
                </div>
            </aside>
        );
    }
}
export default ProfileLeft;
