import React, { Component } from "react";
import { Fab, Checkbox, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar,Tooltip } from "@material-ui/core";
import { default as MaterialGrid } from '@material-ui/core/Grid';
import DropdownDefault from "../Layout/DropdownDefault";
import { Link } from "react-router-dom";
import CreateNewTaskDialog from "./CreateNewTaskDialog";
import TaskProfileFrame from "./TaskProfileFrame";
import moment from 'moment';
import { FilterComponent } from './FilterComponent'
import { ValidatorForm } from "react-material-ui-form-validator";
import UnChecked from '@material-ui/icons/CheckBoxOutlineBlank';
import FontAwesome from "react-fontawesome";
import HiddenColumns from "../Common/HiddenColumns";

import {
    Grid,
    DragDropProvider,
    Table,
    TableHeaderRow,
    Toolbar,
    ColumnChooser,
    VirtualTable,
    TableSelection,
    TableColumnVisibility,
    TableFixedColumns,
    TableColumnReordering,
    PagingPanel,
    TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
    PagingState,
    CustomPaging
} from '@devexpress/dx-react-grid';

import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
let hiddneColumns = [];


class TaskList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            deleteIds: [],
            currentPage: 0,
            pageSize: 10,
            column_name: "",
            sorttype: "",
            fieldId: 1,
            columns_array: [],
            isDeleteConfirmDialoOpen: false,
            columnWidths: [],
            defaultCurrentpage: 1,
            totalCount: 0,
            taskListData: [],
            updateColumnOrder: true,
            defaultColumnOrder: [],
            column_ids: [],
            isOpenedTaskList: true,
            isOpenedCustomFilters: false,
            isOpenedRange: false,
            shouldOpenCreateTaskDialog: false,
            FullCreateTaskDialog: false,
            minimizeTaskDialog: false,
            defaulSortingData: [
                { columnName: 'name', direction: 'asc' },
                { columnName: 'visibility', direction: 'desc' },
                { columnName: 'company', direction: 'desc' },
                { columnName: 'contacttype', direction: 'desc' },
                { columnName: 'title', direction: 'desc' },
                { columnName: 'email', direction: 'desc' },
                { columnName: 'owner', direction: 'desc' },
                { columnName: 'phones', direction: 'desc' },
                // { columnName: 'lastcontacted', direction: 'desc' },
                // { columnName: 'interactions', direction: 'desc' },
                // { columnName: 'inactivedays', direction: 'desc' },
            ],
            selection: [],
            taskLogs: [],
            filterObject: {},
            systemTagsList: [],
            systemCityListArray: [],
            systemStateListArray: [],
            systemZipcodeListArray: [],
            countryListArray: [],
            fieldDisplay: [],
            filterRangeArray: [],
            dropdownhide: [],
            filterSaveDialoge: false,
            fitertype: "1",
            filtername: "",
            recordtypeid: 6,
            filternameError: false,
            taskFilterListArray: [],
            displaySelectedFilterName: "All Tasks",
            filterUpdateId: "",
            allowStateUpdateFilter: true,
            filterdata: [],
            activityquery: "any",
            tagsquery: "any",
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
            usersListArray: [],
            AllTaskFilterKey: "",
            companiesList: [],
            isDataLoading: true,
            newSavedFilterName: "",
            defaultHiddenColumnNames: [],
            masterActivityTypesList: [],
        };
    }

    componentDidMount() {
        let val2 = { typeId: 6 }
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let userprefparams = { userid: userData.id }
        this.props.getActivityTypes(this.props.apolloClient.client);
        this.props.getUserSetting(this.props.apolloClient.client, userprefparams);

        this.props.customFieldList(this.props.apolloClient.client, val2);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getCityList(this.props.apolloClient.client);
        this.props.getFilterListTask(this.props.apolloClient.client, 6);
        this.props.getStateList(this.props.apolloClient.client);
        this.props.getZipcodeList(this.props.apolloClient.client);
        this.props.getCompanyRecList(this.props.apolloClient.client);
        let val = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            filterid: 1
        }
        this.getListing(val)
    }
    getListing = (val) => {
        this.props.getTaskFilterList(this.props.apolloClient.client, val);
        this.props.getTaskListColumnOrder(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;

            this.setState({ dateformat: format, timeZone: timezone })
        }
        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count  = dataList.filter(function(element) {
                if(element.is_disable == true){
                  return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }
        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }
        if (props.settingReducer.companyRecList && props.settingReducer.companyRecList.data && props.settingReducer.companyRecList.data.data) {
            this.setState({ companiesList: props.settingReducer.companyRecList.data.data });
        }

        if (props.taskReducer.taskFilterListArray.data && this.state.allowStateUpdateFilter || props.taskReducer.taskFilterListArray.is_loaded) {
            const taskFilterListArray = props.taskReducer.taskFilterListArray.data;
            this.setState({ taskFilterListArray: taskFilterListArray, allowStateUpdateFilter: false })
            props.taskReducer.taskFilterListArray.is_loaded = false
            var AllfilterIdKey = this.findWithAttr(taskFilterListArray, 'id', "16");
            if (AllfilterIdKey != -1) {
                this.setState({ AllTaskFilterKey: AllfilterIdKey })
            }

            //Aplying default filter selected  STRAT
            let defaultFilter;
            //checkdefault filter  in localStorage
            var localStorageFilterId = localStorage.getItem('selected_task_filter_id');
            if (localStorageFilterId) {
                var localId = this.findWithAttr(taskFilterListArray, 'id', localStorageFilterId);
                // console.log("localId", taskFilterListArray[localId]);
                defaultFilter = taskFilterListArray[localId];
            } else {
                var defaultKeyStatus = this.findWithAttr(taskFilterListArray, 'isdefault', true);
                if (defaultKeyStatus == -1) {
                    defaultKeyStatus = this.findWithAttr(taskFilterListArray, 'id', "16");
                }
                defaultFilter = taskFilterListArray[defaultKeyStatus];
                localStorage.setItem('selected_task_filter_id', defaultFilter.id);
            }
            //updateFilter name to display

            this.setState({
                displaySelectedFilterName: this.state.newSavedFilterName ? this.state.newSavedFilterName : defaultFilter.filtername,
                fieldDisplay: [],
                dropdownhide: [],
            })


            if (defaultFilter.id == "18") {//my open task
                let udata = JSON.parse(localStorage.getItem('user_data_session'))
                defaultFilter.filteroption = [
                    {
                        fieldid: "61", name: "owner", type: "value", value: [udata.email]
                    },
                    {
                        fieldid: "0", name: "iscomplete", type: "iscomplete", value: [true],chktype: "notiscomplete"
                    }
                ]
            }
            if (defaultFilter.id == "17") {//completed
                defaultFilter.filteroption = [
                    {
                        fieldid: "0", name: "iscomplete", type: "iscomplete", value: [true],chktype: "iscomplete"
                    }
                ]
            }

            if (defaultFilter.id == "19") {//open 
                defaultFilter.filteroption = [
                    {
                        fieldid: "0", name: "iscomplete", type: "iscomplete", value: [true],chktype: "notiscomplete"
                    }
                ]
            }
            
            if (defaultFilter.id == "20") {
                defaultFilter.filteroption = [
                    {
                        fieldid: "0",
                        name: "followed",
                        type: "followed",
                        value: [true],
                        chktype: "follow",
                    }
                ]
            }
            if (defaultFilter.filteroption.length > 0) {
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    defaultFilter.filteroption.map((val, k) => {
                        if (val.name == "activitytype") {
                            this.setState({ activityquery: val.query })
                            if(val.value.length > 0){
                                var arr = []
                                val.value.map(v=>{
                                    var k = this.findWithAttr(this.state.masterActivityTypesList, 'name',v)
                                    if(k != -1){
                                        arr.push(this.state.masterActivityTypesList[k])
                                    }
                                })
                                val.value = arr;

                            }
                        }

                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }
                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }

                        if (val.type === "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }
                        if (val.type === "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }
                        if (val.name == "zipcode" || val.name == "city" || val.name == "state" || val.name == "country") {//hideshow ENABLE state/city/country/state
                            dropdownhideShow.push(val.name)
                        } else {
                            dropdownhideShow.push(val.name)
                        }
                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                        newSavedFilterName: "",
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    this.handleFilterSubmit();
                });
            }
            //Aplying default filter selected  END
        }

        if (props.taskReducer.taskLogList.data && props.taskReducer.taskLogList.data && props.taskReducer.taskLogList.data.logdata) {
            this.setState({ taskLogs: props.taskReducer.taskLogList.data.logdata })
        }

        if (props.settingReducer.taskCustomFieldList.data) {
            const customFields = props.settingReducer.taskCustomFieldList.data;
            this.setState({ fieldsList: customFields })
        }
        if (props.settingReducer.systemCityListArray && props.settingReducer.systemCityListArray.data && this.state.systemCityListArray.length == 0) {
            this.setState({ systemCityListArray: props.settingReducer.systemCityListArray.data })
        }
        if (props.settingReducer.systemZipcodeListArray && props.settingReducer.systemZipcodeListArray.data && this.state.systemZipcodeListArray.length == 0) {
            this.setState({ systemZipcodeListArray: props.settingReducer.systemZipcodeListArray.data })
        }
        if (props.settingReducer.systemStateListArray && props.settingReducer.systemStateListArray.data && this.state.systemStateListArray.length == 0) {
            this.setState({ systemStateListArray: props.settingReducer.systemStateListArray.data })
        }
        if (props.settingReducer.countryListGql.data && this.state.countryListArray.length == 0) {
            const countryies = props.settingReducer.countryListGql.data;
            this.setState({ countryListArray: countryies })
        }

        if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
            const tagsData = props.settingReducer.systemTagsList.data;
            this.setState({ systemTagsList: tagsData })
        }

        if (props.taskReducer.taskFieldData.data && props.taskReducer.taskFieldData.is_loaded) {
            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
        }

        if (props.settingReducer.taskCustomFieldList.data) {
            let column_ids = this.state.column_ids;
            let columns_array = this.state.columns_array;
            let columnWidths = this.state.columnWidths;
            let defaultColumnOrder = this.state.defaultColumnOrder;
            let defaulSortingData = this.state.defaulSortingData;

            const customFields = props.settingReducer.taskCustomFieldList.data;

            customFields.map((field) => {
                if (field && (field.isdiactivated || field.is_default)) {
                    // if (field && field.id == '1') {
                    column_ids[field.fieldkey] = parseFloat(field.id);
                    if (this.findWithAttr(columns_array, 'name', field.fieldkey) == -1) {

                        // creating dynamic columns for table ------------------------
                        columns_array.push({ name: field.fieldkey, title: field.fieldlabel });
                        if (field.fieldkey != 'name')
                            defaultColumnOrder.push(field.fieldkey);

                        if (field.fieldkey == 'name') {
                            columnWidths.push(
                                { columnName: "name", width: 250 },
                            );
                        } else {
                            columnWidths.push(
                                { columnName: field.fieldkey, width: 180 },
                            );
                        }
                    }
                }
            });
            // if (this.findWithAttr(columns_array, 'name', 'statusupdatedate') == -1) {
            //     columns_array.push({ name: 'statusupdatedate', title: 'Status updatedate' });
            //     columnWidths.push({ columnName: 'statusupdatedate', width: 180 });
            //     defaultColumnOrder.push('statusupdatedate');
            // }
            // if (this.findWithAttr(columns_array, 'name', 'statusduration') == -1) {
            //     columns_array.push({ name: 'statusduration', title: 'Status Duration' })
            //     columnWidths.push({ columnName: 'statusduration', width: 180 });
            //     defaultColumnOrder.push('statusduration');
            // }
            // if (this.findWithAttr(columns_array, 'name', 'lastcontacted') == -1) {
            //     columns_array.push({ name: 'lastcontacted', title: 'Last Tasked' })
            //     columnWidths.push({ columnName: 'lastcontacted', width: 180 });
            //     defaultColumnOrder.push('lastcontacted');
            // }
            // if (this.findWithAttr(columns_array, 'name', 'interactions') == -1) {
            //     columns_array.push({ name: 'interactions', title: 'Interactions' })
            //     columnWidths.push({ columnName: 'interactions', width: 180 });
            //     defaultColumnOrder.push('interactions');
            // }
            // if (this.findWithAttr(columns_array, 'name', 'inactivedays') == -1) {
            //     columns_array.push({ name: 'inactivedays', title: 'Inactive Days' })
            //     columnWidths.push({ columnName: 'inactivedays', width: 180 });
            //     defaultColumnOrder.push('inactivedays');
            // }


            this.setState({ column_ids: column_ids, columnWidths: columnWidths, columns_array: columns_array, defaultColumnOrder: defaultColumnOrder, }, () => this.forceUpdate());

            if (this.state.drawerflag) {
                setTimeout(() => {
                    this.setState({ drawerOpen: true, drawerflag: false }, () => this.forceUpdate())
                }, 500);
            }


            if (props.taskReducer.taskListOrder && props.taskReducer.taskListOrder.data && props.taskReducer.taskListOrder.data.length > 0) {
                let ordernoList = [];
                let list = [];
                let newcolumn_arr = [];
                let hidddenCol = ['lastname'];
                props.taskReducer.taskListOrder.data.map((listOrder) => {
                    if (listOrder.field) {
                        if (listOrder.field.fieldkey != "name") {
                            if (listOrder.isactive == false)
                                hidddenCol.push(listOrder.field.fieldkey);

                            ordernoList.push(listOrder.field.fieldkey)
                        }
                        if (this.state.columns_array) {
                            let key_col = this.findWithAttr(this.state.columns_array, 'name', listOrder.field.fieldkey);
                            newcolumn_arr.push(this.state.columns_array[key_col]);
                        }
                    }
                });

                this.setState({ columns_array: newcolumn_arr });

                if (this.state.updateColumnOrder)
                    this.setState({ defaultColumnOrder: ordernoList, defaultHiddenColumnNames: hidddenCol }, () => this.forceUpdate());

            }
        }


        if (props.taskReducer.taskList.data && props.taskReducer.taskList.data.data) {
            let totalRecord = props.taskReducer.taskList.data.totalrecord;
            this.setState({ totalCount: totalRecord });
            let listrows = [];
            let columns_array = this.state.columns_array;
            // // for displaying table rows dynamically-------------------
            props.taskReducer.taskList.data.data.map((taskdata, taskindex) => {
                let row_str = [];
                let thisVar = this;
                
                Object.keys(taskdata).forEach(function (key) {
                    columns_array.map((colm) => {
                        if (!row_str[colm.name]) {
                            if (colm.name == "name") {//name field
                                let star_icon = taskdata['isfavourite'] ? "star" : "star_border";
                                let activity_icon = (taskdata.activitytype) ? taskdata.activitytype[0].icon : '';
                                if(taskdata.activitytype && taskdata.activitytype.length > 0){
                                    var k = thisVar.findWithAttr(thisVar.state.masterActivityTypesList, 'id',taskdata.activitytype[0].id)
                                    if(k !=-1){
                                        activity_icon = thisVar.state.masterActivityTypesList[k].icon;
                                    }
                                }
                                // let complete_icon = taskdata['isfavourite'] ? "checkbox" : "checkboxoutlineblank";
                                let color_class = (taskdata[colm.name]) ? taskdata[colm.name].charAt(0).toLowerCase() : "";
                                let data = '-';
                                data = (
                                    <div className="white_space_nowrap">
                                        <Fab
                                            size="small"
                                            aria-label="Task"
                                            onClick={(task1, status1) => thisVar.handleCompleteTask(taskdata['task_id'], taskdata['iscomplete'])}
                                            className={`wrap_icons_task vert-middle ${(taskdata['iscomplete'] == true) ? 'bg-success' : 'bg-grey'}`}
                                        >
                                            {(activity_icon == '') ? 
                                                <Icon>{(taskdata['iscomplete'] == true) ? 'check_box' : 'check_box_outline_blank'}</Icon>
                                            :
                                                <>
                                                    <Icon  className="hover_icon" >{(taskdata['iscomplete'] == true) ? 'check_box' : 'check_box_outline_blank'}</Icon>
                                                    <FontAwesome className={"activity_icon " + activity_icon} />
                                                </>
                                            }
                                        </Fab>
                                        
                                        <span className="font-weight-500 text_ellipsis ml-8">
                                            {taskdata[colm.name]}
                                            {/* <Link to={"/task/task/" + taskdata.task_id}>{taskdata[colm.name]}</Link> */}
                                        </span>
                                                                                
                                        {/* <IconButton className="text-secondary ml-4" onClick={(task1, status1) => thisVar.handleCompleteTask(taskdata['task_id'], taskdata['iscomplete'])} size="small">
                                            {(taskdata['iscomplete'] == true) ? <Icon>check_box</Icon> : <UnChecked />}
                                        </IconButton>         */}
                                        <IconButton className={'text-secondary ml-4'+' '+(star_icon == 'star'?'visibleStarIcon':'')} onClick={(task, status) => thisVar.handleFavorite(taskdata['task_id'], taskdata['isfavourite'])} size="small">
                                            <Icon>{star_icon}</Icon>
                                        </IconButton>
                                        <IconButton size="small" className="user_info ml-4" color="primary" onClick={(e) => thisVar.handleDrawerRight(taskdata['task_id'])}>
                                            <Icon>remove_red_eye</Icon>
                                        </IconButton>
                                    </div>);

                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }

                            else if (colm.name == "value") {//Value fiels
                                let value = '-';
                                if (taskdata[colm.name]) {
                                    taskdata[colm.name].map((statusdata) => (
                                        value = (statusdata.srno == 1) ? statusdata.value : null
                                    ))
                                }
                                if (!row_str[colm.name])
                                    row_str[colm.name] = value;
                            }
                            else if (colm.name == "websites" || colm.name == "phones" || colm.name == "contacttype" || colm.name == "email" || colm.name == "status" || colm.name == "source" || colm.name == "visibility" || colm.name == "tags" || colm.name == "company" || colm.name == "activitytype" || colm.name == "relatedto" ) {
                                let data = '-';
                                if (taskdata[colm.name]) {
                                    if (colm.name == "email") {
                                        taskdata[colm.name].map((statusdata) => (
                                            data = (statusdata.srno == 1) ? <a href={"mailto:" + statusdata.value} className="text_link">{statusdata.value}</a> : null
                                        ))
                                    }
                                    if (colm.name == "websites") {
                                        taskdata[colm.name].map((statusdata) => (
                                            data = (statusdata.srno == 1) ? <a href={statusdata.value} className="text_link">{statusdata.value}</a> : null
                                        ))
                                    }
                                    if (colm.name == "phones" || colm.name == "status" || colm.name == "source" || colm.name == "visibility" || colm.name == "contacttype" || colm.name == "company" || colm.name == "activitytype" || colm.name == "relatedto") {
                                        taskdata[colm.name].map((statusdata) => (
                                            data = (statusdata.srno == 1) ? statusdata.value : null
                                        ))
                                    }
                                    if (colm.name == "tags") {
                                        let tag = [];
                                        taskdata[colm.name].map((statusdata) => {
                                            statusdata.value && tag.push(statusdata.value)
                                        })

                                        data = (tag.toString()) ? tag.toString() : null
                                    }
                                }


                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }
                            else if (colm.name == "priority") {
                                let data = '-';
                                if (taskdata[colm.name]) {
                                    if (typeof (taskdata[colm.name]) == "string") {
                                        data = taskdata[colm.name];
                                    } else {
                                        taskdata[colm.name].map((statusdata) => (
                                            data = (statusdata.srno == 1) ? statusdata.value : null
                                        ))
                                    }
                                }

                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }
                            else if (colm.name == "owner") {
                                let data = '-';
                                if (taskdata[colm.name]) {
                                    if (typeof (taskdata[colm.name]) == "string") {
                                        data = taskdata[colm.name];
                                    }
                                    else {
                                        taskdata[colm.name].map((statusdata) => (
                                            data = (statusdata.srno == 1) ? statusdata.name : null
                                        ))
                                    }
                                }

                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }
                            else if (colm.name == "address") {
                                let data = '-';
                                if (taskdata[colm.name]) {
                                    if (typeof (taskdata[colm.name]) == "string") {
                                        data = taskdata[colm.name];
                                    }
                                    else {
                                        taskdata[colm.name].map((mainaddress) => (
                                            data = (mainaddress.street ? mainaddress.street + "," : "") + (mainaddress.city ? mainaddress.city + "-" : "") + (mainaddress.pincode ? mainaddress.pincode : "")
                                        ))
                                    }
                                }

                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }
                            // { statusupdatedate: 1001, statusduration: 1002, lastcontacted: 1003, interactions: 1004, inactivedays: 1005 }
                            else if (colm.name == "statusupdatedate" || colm.name == "lastcontacted") {
                                let data = '-';
                                data = taskdata[colm.name] ?
                                    moment.unix(taskdata[colm.name]).tz(thisVar.state.timeZone).format(thisVar.state.dateformat)
                                    : '-';

                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }
                            else if (colm.name == "statusduration" || colm.name == "interactions" || colm.name == "inactivedays") {
                                let data = '-';
                                data = taskdata[colm.name] ? taskdata[colm.name] : '-';

                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }
                            // 
                            else if (colm.name == "reminderdate") {
                                let data = '-';
                                if (taskdata[colm.name] && taskdata[colm.name].length > 0)
                                {
                                    if (taskdata[colm.name][0].date != '')
                                    {
                                        data = moment.unix(taskdata[colm.name][0].date).tz(thisVar.state.timeZone).format(thisVar.state.dateformat)
                                    } 
                                }

                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }
                            else {
                                let data = '-'
                                if (thisVar.state.fieldsList) {
                                    if (taskdata[colm.name]) {
                                        var key = thisVar.findWithAttr(thisVar.state.fieldsList, 'fieldkey', colm.name);
                                        if (key != -1) {
                                            if (thisVar.state.fieldsList[key].is_default == false && (thisVar.state.fieldsList[key].fieldtype.id == "4")) {
                                                taskdata[colm.name].map((statusdata) => (
                                                    data = (statusdata.srno == 1) ? statusdata.value : null
                                                ))
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "5") {
                                                let maindata = [];
                                                taskdata[colm.name].map((statusdata) => {
                                                    statusdata.value && maindata.push(statusdata.value)
                                                })
                                                data = (maindata.toString()) ? maindata.toString() : null
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "1" || thisVar.state.fieldsList[key].fieldtype.id == "2") {

                                                data = taskdata[colm.name] ? taskdata[colm.name] : null
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "6") {//
                                                data = taskdata[colm.name]
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "7") {//date field
                                                data = taskdata[colm.name] + "%"
                                            }
                                            // //validating date fields
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "8") {//date field
                                                
                                                if(colm.name !="duedate"){
                                                    data = moment(taskdata[colm.name]).format(thisVar.state.dateformat)
                                                }
                                                if(taskdata[colm.name] > 0){
                                                    data = moment.unix(taskdata[colm.name]).tz(thisVar.state.timeZone).format(thisVar.state.dateformat)
                                                }

                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "9") {
                                                data = taskdata[colm.name] ?
                                                    <a href={taskdata[colm.name]} className="text_link">{taskdata[colm.name]}</a>
                                                    : null
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "10") {
                                                data = taskdata[colm.name]
                                            }
                                            if (!row_str[colm.name])
                                                row_str[colm.name] = data;
                                        }
                                    }
                                }
                            }
                        }
                    });
                });
                let row_str_data = Object.assign({}, row_str);
                listrows.push(row_str_data)
                row_str = [];
            });

            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
            this.setState({ taskListData: listrows }, () => this.forceUpdate());
        }
        else {
            let listrows = [];
            this.setState({
                taskListData: listrows,
            }, () => {
                this.forceUpdate()

                setTimeout(() => {
                    this.setState({
                        isDataLoading: false,
                    })
                }, 3000);
            })
        }
    }

    handleCompleteTask = (task1, status1) => {
        let taskCompleteParams = [];
        taskCompleteParams['complete'] = { status: !status1, taskid: parseFloat(task1) };
        taskCompleteParams['listval'] = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: this.state.currentPage + 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            filterid: 1
        };
        
        this.props.taskComplete(this.props.apolloClient.client, taskCompleteParams);
        console.log(taskCompleteParams, 'taskCompleteParams');
        this.setState({ isDataLoading: true });
    }
    handleChangeColumnOrder = (val) => {

        let arr_ids = [];
        let arr_orders = [];
        let arr_status = [];
        let columns = [];
        let hiddens = this.state.defaultHiddenColumnNames;

        val.map((col, key) => {
            if (this.state.column_ids[col] != null) {
                arr_ids.push(this.state.column_ids[col]);
                arr_orders.push(key + 1);
                columns.push(col);

                if (hiddens.indexOf(col) == -1)
                    arr_status.push(true)
                else
                    arr_status.push(false)
            }            
        });

        this.setState({ updateColumnOrder: false, defaultColumnOrder: columns });
        let changeColumnOrderparams = {
            fieldid: arr_ids,
            columnstatus: arr_status,
            orderno: arr_orders
        };
        this.props.updateTaskListColumnOrder(this.props.apolloClient.client, changeColumnOrderparams);
    }
    // handleChangeColumnHidden = (val) => {
    //     let arr_ids = [];
    //     let arr_orders = [];
    //     let arr_status = [];
    //     let columns = [];
    //     const colsOrder = this.state.defaultColumnOrder;

    //     colsOrder.map((col, key) => {
    //         if (this.state.column_ids[col] != null) {
    //             arr_ids.push(this.state.column_ids[col]);
    //             arr_orders.push(key + 1);
    //             columns.push(col);

    //             if (val.indexOf(col) == -1)
    //                 arr_status.push(true)
    //             else
    //                 arr_status.push(false)
    //         }           
    //     });


    //     let changeColumnOrderparams = {
    //         fieldid: arr_ids,
    //         columnstatus: arr_status,
    //         orderno: arr_orders
    //     };
    //     this.setState({ updateColumnOrder: false,  defaultHiddenColumnNames: val });
    //     this.props.updateTaskListColumnOrder(this.props.apolloClient.client, changeColumnOrderparams);
    // }
    handleChangeColumnHidden = (val) =>{
        hiddneColumns = val;
        this.setState({ defaultHiddenColumnNames: hiddneColumns });
    }
    handleHiddensMain = (hiddens) => {
        console.log(hiddens,'hiddens');
        
        let arr_ids = [];
        let arr_orders = [];
        let arr_status = [];
        let columns = [];
        hiddneColumns = hiddens;
        let colsOrder = this.state.defaultColumnOrder;

        colsOrder.map((col, key) => {

            // if (col == 'statusupdatedate' || col == 'statusduration' || col == 'interactions' || col == 'inactivedays' || col == 'lastcontacted') {
            //     if (this.props.leadReducer.lead_column_order_extra[0][col] != null) {
            //         arr_ids.push(this.props.leadReducer.lead_column_order_extra[0][col])
            //         arr_orders.push(key + 1);
            //         columns.push(col);

            //         if (hiddens.indexOf(col) == -1)
            //             arr_status.push(true)
            //         else
            //             arr_status.push(false)
            //     }
            // }
            // else {
                if (this.state.column_ids[col] != null) {
                    arr_ids.push(this.state.column_ids[col]);
                    arr_orders.push(key + 1);
                    columns.push(col);

                    if (hiddens.indexOf(col) == -1)
                        arr_status.push(true)
                    else
                        arr_status.push(false)
                }
            }
     //   }
        );

        let changeColumnOrderparams = {
            fieldid: arr_ids,
            columnstatus: arr_status,
            orderno: arr_orders
        };
        
        this.setState({ updateColumnOrder: false, defaultHiddenColumnNames: hiddneColumns });
         this.props.updateTaskListColumnOrder(this.props.apolloClient.client, changeColumnOrderparams);

    }

    handleFavorite = (task, status) => {
        let setFavoriteParams = [];
        setFavoriteParams['favorite'] = { status: !status, taskid: task };
        setFavoriteParams['listval'] = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: this.state.currentPage + 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            filterid: 1
        };
        this.props.addFavouriteTask(this.props.apolloClient.client, setFavoriteParams);
    }

    handleCurrentPage = (page) => {
        this.setState({
            currentPage: page,
            selection: [],
        })

        let params_for_pagechange = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: page + 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            filterid: 1
        }
        this.getListing(params_for_pagechange);
    }
    handlePageSize = (size) => {
        this.setState({
            pageSize: size,
            currentPage: 0,
            selection: [],
        })

        let params_for_pagesize = {
            filterdata: this.state.filterdata,
            list: {
                limit: size,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            filterid: 1
        }
        this.getListing(params_for_pagesize);
    }

    toggleTaskList = () => {
        this.setState(oldStateTaskList => ({ isOpenedTaskList: !oldStateTaskList.isOpenedTaskList }));
    }
    toggleCustomFilters = () => {
        this.setState(oldStateCustomFilters => ({ isOpenedCustomFilters: !oldStateCustomFilters.isOpenedCustomFilters }));
    }
    toggleRange = (e) => {
        e.preventDefault();
        this.setState(oldStateRange => ({ isOpenedRange: !oldStateRange.isOpenedRange }));
    }
    changeDefaultSorting = (val) => {
        let column = val[0].columnName;
        let direction = val[0].direction;

        let data = this.state.defaulSortingData;
        data = data.filter(sort => sort.columnName !== val[0].columnName).map(sort => {
            sort.direction = "desc"
            return sort;
        })
        data.push(val[0]);
        this.setState({
            defaulSortingData: data,
            sorttype: direction
        }, () => this.forceUpdate)

        // if (column == 'interactions' || column == 'inactivedays' || column == 'lastcontacted') {
        //     let params_for_sort = {
        //         filterdata: this.state.filterdata,
        //         list: {
        //             limit: this.state.pageSize,
        //             pageno: this.state.currentPage + 1,
        //             searchtext: this.state.searchText,
        //             sorttype: direction,
        //             type: "sort",
        //             fieldId: 0,
        //             columnname: column
        //         },
        //         filterid: 1
        //     }
        //     this.getListing(params_for_sort);
        //     this.setState({ fieldId: 0, column_name: column });
        // } else 
        if (this.state.column_ids[column]) {
            let params_for_sort = {
                filterdata: this.state.filterdata,
                list: {
                    limit: this.state.pageSize,
                    pageno: this.state.currentPage + 1,
                    searchtext: this.state.searchText,
                    sorttype: direction,
                    type: "sort",
                    fieldId: this.state.column_ids[column],
                    columnname: column
                },
                filterid: 1
            }
            this.getListing(params_for_sort);
            this.setState({ fieldId: this.state.column_ids[column], column_name: column });
        }

    }

    changeSearching = (e) => {

        let params_for_search = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: 1,
                searchtext: e.target.value,
                sorttype: this.state.sorttype,
                type: "search",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            filterid: 1
        }
        this.getListing(params_for_search);


        this.setState({ searchText: e.target.value });
    }


    handleTaskDialogClose = () => {
        this.setState({ shouldOpenCreateTaskDialog: false, FullCreateTaskDialog: false, minimizeTaskDialog: false });
    };
    handleTaskDialogMinimize = () => {
        this.setState(oldStateminimizeTaskDialog => ({ minimizeTaskDialog: !oldStateminimizeTaskDialog.minimizeTaskDialog }));
        this.setState({ FullCreateTaskDialog: false })
    };
    handleTaskDialogFull = () => {
        this.setState(oldStateFullCreateTaskDialog => ({ FullCreateTaskDialog: !oldStateFullCreateTaskDialog.FullCreateTaskDialog }));
        this.setState({ minimizeTaskDialog: false })

    };
    setSelection = (val) => {
        let selectedIds = [];

        if (this.props.taskReducer.taskList.data.data) {
            let list_data = this.props.taskReducer.taskList.data.data;
            val.map((key) => {
                selectedIds.push(parseFloat(list_data[key].task_id));
            });
        }
        this.setState({
            selection: val,
            deleteIds: selectedIds
        });
    }
    handleDrawerRight = (task_id) => {
        if (this.state.task_id && (this.state.task_id === task_id)) { } else {
            this.setState({
                task_id: task_id,
                isDataLoading: true,
            }, () => {
                this.forceUpdate()
            })
        }
        setTimeout(() => {
            this.setState({
                drawerOpen: true,
            }, () => {
                this.forceUpdate()
            })
        }, 2000);
    };
    closeDrawerRight = (e) => {
        // e.preventDefault()
        this.setState({ drawerOpen: false });
    };
    handleDeleteTask = () => {
        this.toggleDeleteDialog()
    }

    toggleDeleteDialog = () => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
    };
    confirmDeleteCustomField = () => {
        let deleteTaskParams = [];

        deleteTaskParams['deleteid'] = { taskid: this.state.deleteIds };
        deleteTaskParams['listval'] = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            filterid: 1
        };

        this.props.deleteTask(this.props.apolloClient.client, deleteTaskParams);
        this.setState({
            selection: [],
            deleteIds: []
        });
        this.toggleDeleteDialog()
    };

    handleFollowedCheckbox = (val, name) => {
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })

    }

    handleChangeFilter = (val, name) => {
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    };

    rangeClick = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "range", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }
    rangeFilter = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({ filterRangeArray })
    }

    rangeClickDate = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "date", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    rangeFilterDate = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({
            filterRangeArray
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                this.rangeClickDate(rname, rval.fieldid)
            }, 100);
        })
    }

    handleToggleFieldDisplay = (name) => {
        // console.log("name", name);
        var exists = this.state.fieldDisplay.includes(name);
        var joined = [];
        let filterRangeArray = this.state.filterRangeArray;

        if (exists) {
            joined = this.state.fieldDisplay.filter((item) => item != name)
            if (filterRangeArray[name]) {
                //delete filterRangeArray[name];
            }
        } else {
            joined = this.state.fieldDisplay.concat(name);
            if (!filterRangeArray[name]) {
                filterRangeArray[name] = ["", ""];
            }
        }

        this.setState({ fieldDisplay: joined, filterRangeArray })
    }

    dropdownMenu = (fkey) => {
        var exists = this.state.dropdownhide.includes(fkey);
        var joined = [];
        if (exists) {
            joined = this.state.dropdownhide.filter((item) => item != fkey)

        } else {
            joined = this.state.dropdownhide.concat(fkey);
        }
        this.setState({ dropdownhide: joined })
    }

    handleFilterSubmit = () => {
        var allowToFilter = true
        
        let copyObj = JSON.parse(JSON.stringify(this.state.filterObject));
        var filterValuesArray = Object.keys(copyObj);
        var finalFilterData = filterValuesArray.map((k, i) => {
            let v = copyObj[k];

            if (v.value.length > 0) {
                var valuesArray = [];
                v.value.map((val, k) => {
                    if (v.type === "date") {
                        if (val == "Invalid Date") {
                            allowToFilter = false
                        } else if (val == "") {
                            valuesArray.push(val)
                        } else if (val == null) {
                            valuesArray.push("")
                        } else if (val != null) {
                            if(v.fieldid != "0"){
                                 val = moment(val).format('YYYY-MM-DD');
                            }else{
                                //val = moment(val).format("X");
                                val = moment(val).format('MM/DD/YYYY');
                            }
                            valuesArray.push(val)
                        }
                    } else if (v.fieldid == '61' && val.email) {
                        valuesArray.push(val.email)
                    } else if (v.fieldid != '61' && val.name) {
                        valuesArray.push(val.name)
                    } else if (val.optionvalue) {
                        valuesArray.push(val.optionvalue)
                    } else if (v.fieldid === "66" || v.type === "range") {//tag array and value
                        valuesArray.push(val);
                    } else {
                        valuesArray.push(val);
                    }
                })


                let checkBoxKey = -1
                if (this.state.fieldsList != undefined) {
                    checkBoxKey = this.findWithAttr(this.state.fieldsList, 'id', v.fieldid);
                }

                v.value = valuesArray;
                if (v.type == "followed" || v.type == 'iscomplete') {//follow/not follow filter only value is true
                    if (v.value[0] === true) {
                        return v
                    }
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3" && v.value[0] == "1") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id != "3") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3") {
                } else {
                    return v
                }
            }
        })

        //filter null and undefined object 
        finalFilterData = finalFilterData.filter(function (element) {
            return element !== undefined || (element !== undefined && element.value.length != 0);
        });
        console.log("allowToFilter", allowToFilter);
        console.log("finalFilterData", finalFilterData);
        if (allowToFilter) {
            //saveFilter Data for save in future use.
            this.setState({
                filterdata: finalFilterData,
                isDataLoading: true,
            })

            let val = {
                filterdata: finalFilterData,
                list: {
                    limit: this.state.pageSize,
                    pageno: 1,
                    searchtext: this.state.searchText,
                    sorttype: this.state.sorttype,
                    type: "list",
                    fieldId: this.state.fieldId,
                    columnname: this.state.column_name
                },
                filterid: 1
            }
            this.getListing(val)
        }
    }

    handleSavefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        if (this.state.filterdata && this.state.filterdata.length > 0) {
            let req = {
                filterdata: this.state.filterdata,
                fitertype: this.state.fitertype,
                filtername: this.state.filtername,
                recordtypeid: this.state.recordtypeid,
            }
                console.log("req", req);
            this.props.saveFilterDetailTask(this.props.apolloClient.client, req);
            this.handleSavefilterDialogeClose()
            this.setState({
                displaySelectedFilterName: this.state.filtername,
                newSavedFilterName: this.state.filtername,
            })
        } else {
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
        }
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 1500);
    }
    updateFilter = (data) => {
        this.setState({
            filterSaveDialoge: true,
            filterUpdateId: data.id,
            filtername: data.filtername,
            fitertype: data.fitertype.toString(),
            oldFilterOption: data.filteroption,
        })
    }

    handleUpdatefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        let req = {
            filterdata: this.state.oldFilterOption,
            fitertype: this.state.fitertype,
            filtername: this.state.filtername,
            filterid: parseInt(this.state.filterUpdateId),
        }
        //return
        this.props.updateFilterDetailTask(this.props.apolloClient.client, req);
        let updateDataInState = this.findWithAttr(this.state.taskFilterListArray, 'id', this.state.filterUpdateId);
        if (updateDataInState !== -1) {
            this.state.taskFilterListArray[updateDataInState].filtername = this.state.filtername;
            this.state.taskFilterListArray[updateDataInState].fitertype = this.state.fitertype;
        }
        this.handleSavefilterDialogeClose()
    }
    updateFilterOptions = () => {
        let key = this.findWithAttr(this.props.taskReducer.taskFilterListArray.data, 'id', localStorage.getItem('selected_task_filter_id'));
        var data = ""
        if (key !== -1) {
            data = this.props.taskReducer.taskFilterListArray.data[key];
            if (this.state.filterdata && this.state.filterdata.length > 0) {
                let req = {
                    filterdata: this.state.filterdata,
                    fitertype: data.fitertype.toString(),
                    filtername: data.filtername,
                    filterid: parseInt(data.id),
                }
                this.props.updateFilterDetailTask(this.props.apolloClient.client, req);
                this.handleSavefilterDialogeClose()
            } else {
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
            }
            setTimeout(() => {
                this.setState({
                    allowStateUpdateFilter: true,
                })
            }, 1500);
        }
    }
    confirmDeleteFilter = () => {
        this.props.deleteFilterTask(this.props.apolloClient.client, parseInt(this.state.filterDeleteId));
        let removeFilterObjKey = this.findWithAttr(this.props.taskReducer.taskFilterListArray.data, 'id', this.state.filterDeleteId);
        if (removeFilterObjKey !== -1) {
            if (!this.props.taskReducer.taskFilterListArray.data[removeFilterObjKey].isdefault == true) {
                this.props.taskReducer.taskFilterListArray.data.splice(removeFilterObjKey, 1);
            }
        }
        this.filterRemoveDialoge()
    }

    filterRemoveDialoge = () => {
        this.setState({ isDeleteFilter: false, filterDeleteId: "", allowStateUpdateFilter: true })
    }
    handleSavefilterDialoge = () => {
        this.setState({ filterSaveDialoge: true })
    }
    handleSavefilterDialogeClose = () => {
        this.setState({
            filterSaveDialoge: false,
            filternameError: false,
            fitertype: "1",
            filterUpdateId: "",
            oldFilterOption: "",
            filtername: "",
        })
    }
    handleChange = (event) => {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    updateFilterDefaultTask = (data) => {
        this.props.updateFilterDefaultTask(this.props.apolloClient.client, parseInt(data.id));
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    handleFilterChange = (filterdata) => {
        console.log("filterdata", filterdata);
        
        if (filterdata.id == "18") {//my open task
            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            filterdata.filteroption = [
                {
                    fieldid: "61", name: "owner", type: "value", value: [udata.email]
                },
                {
                    fieldid: "0", name: "iscomplete", type: "iscomplete", value: [true],chktype: "notiscomplete"
                }
            ]
        }
        if (filterdata.id == "17") {//completed
            filterdata.filteroption = [
                {
                    fieldid: "0", name: "iscomplete", type: "iscomplete", value: [true],chktype: "iscomplete"
                }
            ]
        }

        if (filterdata.id == "19") {//open 
            filterdata.filteroption = [
                {
                    fieldid: "0", name: "iscomplete", type: "iscomplete", value: [true],chktype: "notiscomplete"
                }
            ]
        }
        if (filterdata.id == "20") {
            filterdata.filteroption = [
                {
                    fieldid: "0",
                    name: "followed",
                    type: "followed",
                    value: [true],
                    chktype: "follow",
                }
            ]
        }
        this.setState({
            filterObject: {},
            filterRangeArray: [],
            fieldDisplay: [],
            dropdownhide: [],
        }, () => {
            this.forceUpdate()
            console.log("filterdata.filteroption", filterdata.filteroption);
            this.setState({ displaySelectedFilterName: filterdata.filtername, currentPage: 0 })
            if (filterdata.filteroption.length > 0) {
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    filterdata.filteroption.map((val, k) => {
                        if (val.name == "activitytype") {
                            this.setState({ activityquery: val.query })
                            if(val.value.length > 0){
                                var arr = []
                                val.value.map(v=>{
                                    var k = this.findWithAttr(this.state.masterActivityTypesList, 'name',v)
                                    if(k != -1){
                                        arr.push(this.state.masterActivityTypesList[k])
                                    }
                                })
                                val.value = arr;

                            }
                        }

                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }
                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }

                        if (val.type === "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }

                        if (val.type == "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }

                        if (val.name == "zipcode" || val.name == "city" || val.name == "state" || val.name == "country") {//hideshow ENABLE state/city/country/state
                            dropdownhideShow.push(val.name)
                        } else {
                            dropdownhideShow.push(val.name)

                        }
                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    this.handleFilterSubmit();
                    localStorage.setItem('selected_task_filter_id', filterdata.id)
                });
            } else {
                let val = {
                    filterdata: [],
                    list: {
                        limit: this.state.pageSize,
                        pageno: 1,
                        searchtext: this.state.searchText,
                        sorttype: this.state.sorttype,
                        type: "list",
                        fieldId: this.state.fieldId,
                        columnname: this.state.column_name
                    },
                    filterid: 1
                }
                localStorage.setItem('selected_task_filter_id', filterdata.id)
                this.handleFilterSubmit();
            }
        })

    }

    handlechangeActivityQuery = (event) => {
        this.setState({
            activityquery: event.target.value
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject['activitytype'] && this.state.filterObject['activitytype'].value.length > 0) {
                    this.state.filterObject['activitytype'].query = event.target.value;
                    this.handleFilterSubmit();
                }
            }, 1500);
        })
    }
    handlechangeTagsQuery = (event) => {
        this.setState({
            tagsquery: event.target.value
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject['tags'] && this.state.filterObject['tags'].value.length > 0) {
                    this.state.filterObject['tags'].query = event.target.value;
                    this.handleFilterSubmit();
                }
            }, 1500);
        })
    }
    handleClearFilterData = () => {
        this.setState({
            fieldDisplay: [],
            filterRangeArray: [],
            dropdownhide: [],
            filterObject: {},
            filterdata: [],
            activityquery: "any",
            tagsquery: "any",
        }, () => {
            setTimeout(() => {
                // if(this.state.filterObject['tags'] && this.state.filterObject['tags'].value.length > 0){
                //     this.state.filterObject['tags'].query = event.target.value;
                //     this.handleFilterSubmit();
                // }
            }, 1500);
        })
    }

    handleTaskTypeCheckbox = (val, name) => {
        var valArray = []
        if (this.state.filterObject['contacttype']) {
            valArray = this.state.filterObject['contacttype'].value
            if (val.checked) {
                valArray.push(val.text);
                val.value = valArray;
                this.setState({
                    filterObject: {
                        ...this.state.filterObject,
                        [name]: val
                    }
                })
            } else {
                if (this.state.filterObject['contacttype'].value == 0) {
                    valArray.push(val.text);
                    this.state.filterObject['contacttype'].value = valArray;
                } else {
                    var array = this.state.filterObject['contacttype'].value;
                    var index = array.indexOf(val.text)
                    if (index !== -1) {
                        array.splice(index, 1);
                        this.state.filterObject['contacttype'].value = array
                    }
                }
            }
        } else {
            if (val.checked) {
                valArray.push(val.text);
                val.value = valArray
            }
            this.setState({
                filterObject: {
                    ...this.state.filterObject,
                    [name]: val
                }
            })
        }

        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })

    }
    onColumnWidthsChange = (widths) => {
        console.log(widths, 'widthswidthswidths');
    }
    render() {
        let { taskLogs, defaultColumnOrder, pageSize, currentPage, totalCount, taskListData, isOpenedTaskList, isOpenedCustomFilters, isOpenedRange, shouldOpenCreateTaskDialog, minimizeTaskDialog, FullCreateTaskDialog, drawerOpen } = this.state;
        const tableColumnExtensions = [
            { columnName: 'name', width: 250 },
        ];

        const columns = this.state.columns_array;

        const defaultHiddenColumnNames = this.state.defaultHiddenColumnNames;
        const tableColumnVisibilityColumnExtensions = [
            { columnName: 'name', togglingEnabled: false },
        ];
        const leftColumns = [TableSelection.COLUMN_TYPE, 'name',];

        var localStorageFilter = localStorage.getItem('selected_task_filter_id');
        let updateSaveFilterBtnDisabled = (this.state.filterdata == undefined || !this.state.filterdata.length) ? ((this.state.oldFilterOption == undefined || !this.state.oldFilterOption.length) ? true : false) : false
        const toolbarTemplate = (params) => {

            let { searchText, pageSize, currentPage, totalCount, taskListData, isOpenedTaskList, isOpenedCustomFilters, isOpenedRange, shouldOpenCreateTaskDialog, minimizeTaskDialog, FullCreateTaskDialog, drawerOpen } = this.state;
            return (
                <div className="d-flex list_table_header">
                    <MaterialGrid container justify="space-between">
                        <MaterialGrid>
                            <MaterialGrid container justify="space-between">
                                <MaterialGrid>
                                    <h3 className="ml-16">Tasks</h3>
                                </MaterialGrid>
                                {this.state.selection.length ?
                                    <MaterialGrid className="flex flex-middle ml-10">

                                        {this.state.selection.length}  selected
                                         <IconButton aria-label="Email" size="small" className="ml-8">
                                            <Icon>email</Icon>
                                        </IconButton>

                                        {/* <IconButton aria-label="Edit" size="small" className="ml-8">
                                        <Icon>edit</Icon>
                                        </IconButton> */}

                                        <IconButton aria-label="Delete" size="small" onClick={() => this.handleDeleteTask()} className="ml-8">
                                            <Icon>delete</Icon>
                                        </IconButton>

                                        <DropdownDefault
                                            menuButton={
                                                <IconButton size="small" className="ml-8">
                                                    <Icon>more_vert</Icon>
                                                </IconButton>
                                            }
                                        >
                                            <MenuItem className="flex flex-middle">
                                                <Icon className="mr-16">call_merge</Icon> Merge Tasks
                                            </MenuItem>
                                            <MenuItem className="flex flex-middle">
                                                <Icon className="mr-16">file_download</Icon> Export List
                                            </MenuItem>
                                        </DropdownDefault>
                                    </MaterialGrid>
                                    :
                                    ''
                                }
                            </MaterialGrid>
                        </MaterialGrid>
                        <MaterialGrid>
                        </MaterialGrid>
                    </MaterialGrid>

                    <TextField
                        id="search-input"
                        placeholder="search"
                        defaultValue={searchText}
                        onChange={this.changeSearching}
                        onKeyDown={this.keyPress}
                        autoFocus={searchText ? true : false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>search</Icon>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <HiddenColumns allData={this} columns={columns} />

                    {params.children}

                    <div className="d-inline-block ml-8">
                        <DropdownDefault
                            menuButton={
                                <Button variant="contained" color="primary" size="small">
                                    Actions
                                </Button>
                            }
                        >
                            <MenuItem className="flex flex-middle">
                                <Icon className="mr-16">format_list_numbered</Icon> Create custom fileds
                            </MenuItem>
                        </DropdownDefault>
                    </div>
                    <div className="d-inline-block ml-8">
                        <Button variant="contained" size="small">
                            Import
                        </Button>
                    </div>
                    <div className="d-inline-block ml-8 mr-16">
                        <Button variant="contained" color="secondary" onClick={() => this.setState({ shouldOpenCreateTaskDialog: true })} size="small" style={{ minWidth: 95 }}>
                            Create New
                        </Button>
                    </div>
                </div>
            );
        };

        return (
            <React.Fragment>
                <aside className="aside_left" style={{overflowY: 'scroll',overflow: 'auto'}}>
                    <Card elevation={3} className="pt-16">
                        <h5 onClick={this.toggleTaskList} className="cursor_poiter d-flex justify-content-between pl-16 pr-16"><Tooltip title={this.state.displaySelectedFilterName }>
                        <div className="text_ellipsis" style={{maxWidth:'130px',fontSize:'16px',margin:'3px 0px 0px 0px',fontWeight:'500'}}>{this.state.displaySelectedFilterName}</div></Tooltip> ({taskListData.length}) <Icon className="vert-middle flex-end">{isOpenedTaskList ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
                        {isOpenedTaskList &&
                            <div className="filter_menu_custom">
                                <h6 className="mt-8 font-size-14 mb-0 pl-16 pr-16">Private Filters </h6>
                                <ul>
                                    {this.state.taskFilterListArray.length > 0 && this.state.taskFilterListArray.map((item, key) => {
                                        let star_icon = item.isdefault ? "star" : "star_border";
                                        if (item.fitertype == 1 && item.id != localStorage.getItem('selected_task_filter_id')) {
                                            return <li key={item.key}>
                                                <div onClick={() => this.handleFilterChange(item)}>{item.filtername}</div>
                                                <><IconButton className="text-secondary ml-4" size="small">
                                                    <Icon onClick={() => this.updateFilter(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                </IconButton>
                                                    <IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: item.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                    </IconButton>
                                                    <IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.updateFilterDefaultTask(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                    </IconButton>
                                                </>
                                            </li>
                                        }
                                    }
                                    )}
                                </ul>
                                <h6 className="mt-8 font-size-14 mb-0 mt-16 pl-16 pr-16">Public Filters</h6>
                                <ul>
                                    {this.state.taskFilterListArray.length > 0 && this.state.taskFilterListArray.map((items, key) => {
                                        let star_icon = items.isdefault ? "star" : "star_border";
                                        if (items.fitertype == 0 && items.id != localStorage.getItem('selected_task_filter_id')) {
                                            return <li key={items.key}>
                                                <div onClick={() => this.handleFilterChange(items)}>{items.filtername}</div>
                                                {!["16", "17", "18", "19", "20"].includes(items.id) &&
                                                    <><IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.updateFilter(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                    </IconButton>
                                                        <IconButton className="text-secondary ml-4" size="small">
                                                            <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: items.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                        </IconButton></>
                                                }
                                                <IconButton className="text-secondary ml-4" size="small">
                                                    <Icon onClick={() => this.updateFilterDefaultTask(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                </IconButton>
                                            </li>
                                        }
                                    }
                                    )}
                                </ul>

                                <><Dialog
                                    fullWidth={true}
                                    maxWidth={'xs'}
                                    open={this.state.isDeleteFilter}
                                    onClose={this.filterRemoveDialoge}
                                    disableBackdropClick
                                    disableEscapeKeyDown
                                    aria-labelledby="ar-remove-dialog-title"
                                >
                                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to delete filter ?"}</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={this.filterRemoveDialoge} color="primary">Cancel</Button>
                                        <Button type="Button" onClick={() => this.confirmDeleteFilter()} className="btn_Primary">
                                            Ok
                                    </Button>
                                    </DialogActions>
                                </Dialog></>
                            </div>
                        }
                    </Card>
                    <Card elevation={3} className={`pt-16 pb-16`} style={{marginTop:'5px'}}>
                        <div className="ml-16 mr-16">
                            <Button fullWidth className="btn_Primary" size="small" onClick={this.toggleCustomFilters}><Icon>add</Icon> Add custom filters</Button>
                        </div>
                        {isOpenedCustomFilters &&
                            <React.Fragment>
                                <div className="v_scroll position-relative ml-16 mt-16 pr-16">
                                    <Link className="text_link" onClick={() => this.handleFilterChange(this.state.taskFilterListArray[this.state.AllTaskFilterKey])}>Clear All</Link>
                                    {FilterComponent(this)}
                                </div>
                                <div className="fixed-bottom" style={{position:'initial'}}>
                                    <Button type="button" className="btn_Primary  mb-16 px-12" size="small" onClick={() => this.handleSavefilterDialoge()}>Save Filter</Button>
                                    {localStorageFilter && !["16", "17", "18", "19", "20"].includes(localStorageFilter) &&
                                        <Button type="button" className="btn_Primary  mb-16 px-12 ml-16" size="small" onClick={() => this.updateFilterOptions()}>Update Filter</Button>
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </Card>
                </aside>
                <Card elevation={3} className="fixed_layout">
                    <div className="leads_list_table">
                        <Grid rows={taskListData} columns={columns}>

                            <SelectionState
                                selection={this.state.selection}
                                onSelectionChange={this.setSelection}
                            />

                            <IntegratedSelection />

                            <VirtualTable />

                            {/* <SearchState defaultValue="" onValueChange={((val) => { this.changeSearching(val) })} /> */}
                            <IntegratedFiltering />

                            <SortingState
                                defaultSorting={this.state.defaulSortingData}
                                onSortingChange={(val => { this.changeDefaultSorting(val) })}
                                sorting={this.state.defaulSortingData}
                            />

                            {/* <IntegratedSorting /> */}
                            <DragDropProvider />

                            <PagingState
                                currentPage={currentPage}
                                pageSize={pageSize}
                                onCurrentPageChange={(page) => this.handleCurrentPage(page)}
                                onPageSizeChange={(size) => this.handlePageSize(size)}
                            />
                            
                            <Table columnExtensions={tableColumnExtensions} />

                            <TableColumnResizing
                                defaultColumnWidths={this.state.columnWidths}
                                onColumnWidthsChange={this.onColumnWidthsChange}
                            />

                            <TableSelection showSelectAll highlightRow />

                            <TableColumnReordering
                                defaultOrder={defaultColumnOrder}
                                order={defaultColumnOrder}
                                onOrderChange={(val) => { this.handleChangeColumnOrder(val) }}
                            />

                            <TableHeaderRow showSortingControls />

                            <PagingPanel
                                // pageSizes={[2, 5, 10, 15]}
                                pageSizes={[10, 25, 50, 100]}
                            />

                            <CustomPaging
                                totalCount={totalCount}
                            />

                            <TableFixedColumns
                                leftColumns={leftColumns}
                            />

                            <TableColumnVisibility
                                defaultHiddenColumnNames={defaultHiddenColumnNames}
                                hiddenColumnNames={defaultHiddenColumnNames}
                                columnExtensions={tableColumnVisibilityColumnExtensions}
                                onHiddenColumnNamesChange={(val) => { this.handleChangeColumnHidden(val) }}
                            />

                            <Toolbar rootComponent={toolbarTemplate} />

                            {/* <ColumnChooser /> */}

                        </Grid>
                    </div>
                </Card>

                <Drawer
                    width={"100px"}
                    variant="temporary"
                    anchor={"right"}
                    className="drawer_right"
                    open={drawerOpen}
                    onClose={this.closeDrawerRight}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <TaskProfileFrame task_id={this.state.task_id} taskLogs={taskLogs} handleClose={this.closeDrawerRight} />
                </Drawer>

                {shouldOpenCreateTaskDialog && (
                    <CreateNewTaskDialog
                        handleClose={this.handleTaskDialogClose}
                        open={shouldOpenCreateTaskDialog}
                        handleMinimize={this.handleTaskDialogMinimize}
                        handleFull={this.handleTaskDialogFull}
                        minimizeScreen={minimizeTaskDialog}
                        fullScreen={FullCreateTaskDialog}
                        allstates={this.state}
                    />
                )}

                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteConfirmDialoOpen}
                    onClose={this.toggleDeleteDialog}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove selected Tasks ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteDialog} color="primary">Cancel</Button>
                        <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">Ok</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.filterSaveDialoge}
                    onClose={() => this.handleSavefilterDialogeClose()}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="delele-dialog-title"
                >
                    <DialogTitle id="">
                        {!this.state.filterUpdateId ? "Add a New Filter" : "Edit Filter"}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <ValidatorForm instantValidate={false} ref="form">
                            <FormControl error={this.state.filternameError} className="w-100 input_bx">
                                <InputLabel htmlFor="component-error">Name</InputLabel>
                                <Input
                                    type="text"
                                    id={'filtername'}
                                    label={'Filter Name'}
                                    className="w-100 input_bx"
                                    onChange={(event) => this.handleChange(event)}
                                    name={'filtername'}
                                    value={this.state.filtername}
                                />
                                {this.state.filternameError && <FormHelperText id="component-error-text">This field is required</FormHelperText>}
                            </FormControl>
                            <br /><br />
                            <div>
                                <span>visibility</span>
                                <br />
                                <RadioGroup name="fitertype" value={this.state.fitertype} onChange={(event) => this.handleChange(event)} row>
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio color="primary" />}
                                        label="Private"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        control={<Radio color="primary" />}
                                        label="Public"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </div>

                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        {updateSaveFilterBtnDisabled && <p className="lead-filter-error">Please select filter option to save filter.</p>}
                        <Button onClick={() => this.handleSavefilterDialogeClose()} color="primary">
                            Cancel
                        </Button>
                        {!this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={() => this.handleSavefilter()}>Save</Button>}
                        {this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={() => this.handleUpdatefilter()}>Update</Button>}
                    </DialogActions>
                </Dialog>
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        taskReducer: state.taskReducer,
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        taskComplete: (client, request) => { dispatch(actions.taskComplete(client, request)) },
        addFavouriteTask: (client, request) => { dispatch(actions.addFavouriteTask(client, request)) },
        deleteTask: (client, request) => { dispatch(actions.deleteTask(client, request)) },
        getTaskFilterList: (client, request) => { dispatch(actions.getTaskFilterList(client, request)) },
        getTaskListColumnOrder: (client) => { dispatch(actions.getTaskListColumnOrder(client)) },
        updateTaskListColumnOrder: (client, request) => { dispatch(actions.updateTaskListColumnOrder(client, request)) },
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },

        getCompanyRecList: (client) => { dispatch(actions.getCompanyRecList(client)) },
        getCityList: (client) => { dispatch(actions.getCityList(client)) },
        getStateList: (client) => { dispatch(actions.getStateList(client)) },
        getZipcodeList: (client) => { dispatch(actions.getZipcodeList(client)) },

        saveFilterDetailTask: (client, request) => { dispatch(actions.saveFilterDetailTask(client, request)) },
        getFilterListTask: (client, recordtypeid) => { dispatch(actions.getFilterListTask(client, recordtypeid)) },
        updateFilterDetailTask: (client, req) => { dispatch(actions.updateFilterDetailTask(client, req)) },
        deleteFilterTask: (client, req) => { dispatch(actions.deleteFilterTask(client, req)) },
        updateFilterDefaultTask: (client, filterid) => { dispatch(actions.updateFilterDefaultTask(client, filterid)) },
        getUserSetting: (client, data) => dispatch(actions.getUserSetting(client, data)),
        getUserList: (client) => dispatch(actions.getUserList(client)),
        getActivityTypes: (client) => dispatch(actions.getActivityTypes(client)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TaskList);
