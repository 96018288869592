import React, { Component } from "react";
import { Button, IconButton, Icon } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";


class LeadInboxCompose extends Component {
  state = {
    to: "",
    subject: "",
    content: "",
    attachment: null
  };

  handleSubmit = event => {
    console.log(this.state);
  };

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };

  handleContentChange = contentHtml => {
    this.setState({
      content: contentHtml
    });
  };

  handleAttachmentSelection = event => {
    this.setState({
      attachment: event.target.files[0]
    });
  };

  render() {
    let { to, subject, attachment } = this.state;
    let { handleClose } = this.props;

    return (
        <div className="inbox-form label-size-14">
          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
            onError={errors => null}
          >
            <TextValidator
              className="mb-16 w-100"
              label="To"
              onChange={this.handleChange}
              type="email"
              name="to"
              value={to}
              validators={["required", "isEmail"]}
              errorMessages={["this field is required", "email is not valid"]}
            />
            <TextValidator
              className="mb-16 w-100"
              label="Subject"
              onChange={this.handleChange}
              type="text"
              name="subject"
              value={subject}
              validators={["required"]}
              errorMessages={["this field is required"]}
            />
            <div className="mt-16 flex flex-wrap flex-space-between">
            {attachment && <p className="mr-24">{attachment.name}</p>}
                <label htmlFor="attachment">
                  <IconButton className="mr-8" component="span" size="small">
                    <Icon>attach_file</Icon>
                  </IconButton>
                </label>
                <input
                  onChange={this.handleAttachmentSelection}
                  className="d-none"
                  id="attachment"
                  type="file"
                />
              <div className="flex flex-middle">                
                <Button onClick={handleClose} color="primary">Cancel</Button>
                <Button onClick={handleClose} variant="contained" color="secondary" className="ml-10">Send</Button>
              </div>
            </div>
          </ValidatorForm>
        </div>
    );
  }
}

export default LeadInboxCompose;
