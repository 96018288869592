import React, { Component } from "react";
import ProfileLeft from "./ProfileLeft";
import ProfileCenter from "./ProfileCenter";
import ProfileRight from "./ProfileRight";

class Opportunities extends Component {
	state={
		opportunities_id:""
	}
	componentWillMount() {
		this.setState({
		  opportunities_id:parseInt(this.props.match.params.id)
		})
	}
  	render() {
	    return (
	      <React.Fragment>
	        <ProfileLeft opportunities_id={this.state.opportunities_id}/>
	        <ProfileCenter opportunities_id={this.state.opportunities_id}/>
	        <ProfileRight opportunities_id={this.state.opportunities_id}/>
	      </React.Fragment>
	    );
  	}
}

export default Opportunities;
