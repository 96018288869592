import React, { Component } from "react";
import { Dialog, List, ListItem, DialogTitle, DialogActions, IconButton, Icon, Menu, withStyles, Grid, Fab, Select, MenuItem, TextField, Card, InputLabel, Button, Checkbox, Tooltip } from "@material-ui/core";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CreatelogDialog from "./CreatelogDialog";
import ProfileEmailMenu from "./ProfileEmailMenu";
import LeadInboxCompose from "./LeadInboxCompose";
import ViewHtmlDialog from "./ViewHtmlDialog";
import FontAwesome from "react-fontawesome";

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
// const ITEM_HEIGHT = 48;

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

const IconSmaller = withStyles(() => ({
    root: {
        fontSize: "1.0rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);


class EditCommentMenu extends Component {
    state = {
        commentid: '',
        Commentoptions: '',
        showCommentoptions: false,
    };

    handleClick = (event) => {
        this.setState({ Commentoptions: event.target, showCommentoptions: true });
    }
    handleCloseOptions = (event) => {
        this.setState({ Commentoptions: null, showCommentoptions: false });
    }
    handleEditComment = () => {
        this.handleCloseOptions();
        this.props.allData.handleEditCommentMain(this.props.cmt.id, this.props.cmt.comment);
    }
    deletecomment = () => {
        this.handleCloseOptions();
        this.props.allData.deleteComment(this.props.cmt.id, this.props.type);
    }

    render() {
        return (
            <Grid item className="ml-auto">
                <IconButton size="small"
                    aria-label="More"
                    aria-owns={this.state.showCommentoptions ? "long-menu" : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    className="button_size"
                >
                    <Icon>more_vert</Icon>
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.Commentoptions}
                    open={this.state.showCommentoptions}
                    onClose={this.handleCloseOptions}
                    PaperProps={{
                        style: {
                            width: 200
                        }
                    }}
                >
                    <MenuItem onClick={this.handleEditComment}>
                        <IconButton aria-label="Delete" size="small" className="hover_show  mr-10">
                            <IconSmall>edit</IconSmall>
                        </IconButton> Edit Comment
                    </MenuItem>
                    <MenuItem onClick={() => this.deletecomment()}>
                        <IconButton aria-label="Delete" size="small" className="hover_show  mr-10">
                            <IconSmall>delete</IconSmall>
                        </IconButton> Delete Comment
                    </MenuItem>
                </Menu>
            </Grid>
        )
    }
}

class FiltersMenu extends Component {
    state = {
        commentid: '',
        Commentoptions: '',
        showCommentoptions: false,
        activities: [],
        typeArray: [],
        activityArray: [],
        activityflag: true,
    };
    componentDidMount() {

        let typeArray1 = this.state.typeArray;
        let activityArray1 = this.state.activityArray;

        typeArray1 = [1, 2, 3, 4, 15, 20, 21, 5, 100, 1000, 1001];

        this.setState({ typeArray: typeArray1 });
        this.props.thisData.setState({ logtypeid: typeArray1 });
    }
    componentWillReceiveProps(props) {

        if (props.proVar && props.proVar.length > 0) {
            this.setState({ activities: props.proVar });
            if (this.state.activityflag) {
                let activityArray1 = this.state.activityArray;
                let activities = props.proVar;

                if (activities && activities.length > 0) {
                    activities.map((activity) => {
                        if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
                            activityArray1.push(parseFloat(activity.id));
                        }
                    })
                    this.setState({ activityArray: activityArray1, activityflag: false });
                    props.thisData.setState({ activitypeid: activityArray1 });
                }
            }
        }
    }

    handleClick = (event) => {
        this.setState({ Commentoptions: event.target, showCommentoptions: true });
    }

    handleCloseOptions = (event) => {
        this.setState({ Commentoptions: null, showCommentoptions: false });
    }

    handleMenuClick = (e, value, type) => {
        let typeArray1 = this.state.typeArray;
        let activityArray1 = this.state.activityArray;
        var promise = new Promise((resolve, reject) => {
            if (e.target.checked == true) {
                if (type == 0) {

                    if (activityArray1.indexOf(value) == -1)
                        activityArray1.push(value);

                } else if (type == 1000) {

                    typeArray1 = [1, 2, 3, 4, 15, 20, 21, 5, 100, 1000, 1001];

                    let activities = this.state.activities;

                    if (activities && activities.length > 0) {
                        activities.map((activity) => {
                            if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
                                activityArray1.push(parseFloat(activity.id));
                            }
                        })
                    }

                } else if (type == 1001) {

                    let pusharr = [1, 2, 3, 4, 15, 20, 21, 1001];
                    pusharr.map((arr) => {
                        if (typeArray1.indexOf(arr) == -1) {
                            typeArray1.push(arr);
                        }
                    })

                }
                else {

                    if (typeArray1.indexOf(type) == -1)
                        typeArray1.push(type);

                }
            }
            if (e.target.checked == false) {
                if (type == 0) {

                    let key1 = activityArray1.indexOf(value);
                    activityArray1.splice(key1, 1);

                } else if (type == 1000) {

                    typeArray1 = [];
                    activityArray1 = [];

                } else if (type == 1001) {

                    let pullarr = [1, 2, 3, 4, 15, 20, 21, 1000, 1001];
                    pullarr.map((arr) => {
                        if (typeArray1.indexOf(arr) != -1) {
                            let ind = typeArray1.indexOf(arr);
                            typeArray1.splice(ind, 1);
                        }
                    })

                } else {
                    let key = typeArray1.indexOf(type);
                    typeArray1.splice(key, 1);
                }
            }
            resolve(1)
        })

        promise.then(result => {
            this.setState({ typeArray: typeArray1, activityArray: activityArray1 });
            this.props.thisData.setState({ logtypeid: typeArray1, activitypeid: activityArray1 });
            this.props.thisData.handlefiltermain(typeArray1, activityArray1);
        });
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    render() {
        let { activities, activityArray, typeArray } = this.state;
        let def_typearr = [1, 2, 3, 4, 15, 20, 21, 5, 100];
        let flag_all = true;

        if (activities && activities.length > 0) {
            activities.map((act) => {
                if (activityArray.indexOf(parseFloat(act.id)) == -1)
                    flag_all = false;
            })
        } else {
            flag_all = false;
        }

        def_typearr.map((def) => {
            if (typeArray.indexOf(def) == -1)
                flag_all = false;
        })

        return (
            <>
                <Grid container justify="space-between">
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <List className="p-0 pull-right mr-20 log_filter_list">
                            <ListItem
                                aria-label="More"
                                aria-owns={this.state.showCommentoptions ? "filters_menu" : undefined}
                                aria-haspopup="true"
                                onClick={this.handleClick}
                                className="font-size-14 cursor_poiter  pr-0"
                            >
                                Filters <Icon>arrow_drop_down</Icon>
                            </ListItem>
                            <Menu
                                className=""
                                id="long-menu"
                                anchorEl={this.state.Commentoptions}
                                open={this.state.showCommentoptions}
                                onClose={this.handleCloseOptions}
                                PaperProps={{
                                    style: {
                                        width: 200
                                    }
                                }}
                            >
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16 text-muted">storage</Icon> <Tooltip title='All Activities'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>All Activities</div></Tooltip>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter "}
                                        name={'allActivities'}
                                        id={'filterCheck'}
                                        checked={flag_all}
                                        onChange={(event, value) => this.handleMenuClick(event, 1000, 1000)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                {(activities && activities.length > 0) && activities.map((activity) => (
                                    <MenuItem className="filter_items">
                                        <FontAwesome className={activity.icon + " mr-16"} style={{ fontSize: '15px', padding: '5px' }} /><Tooltip title={activity.name}>
                                            <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>{activity.name}</div></Tooltip>
                                        <Checkbox color="primary"
                                            className={"crm_log_checkbox_filter "}
                                            disabled={false}
                                            name={activity.name}
                                            id={'filterCheck'}
                                            checked={(activityArray.indexOf(parseFloat(activity.id)) != -1) ? true : false}
                                            onChange={(event, value) => this.handleMenuClick(event, parseFloat(activity.id), 0)}
                                            value={this.state.filterCheck}
                                            color="primary"
                                        />
                                    </MenuItem>
                                ))}
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16 text-muted">subject</Icon><Tooltip title='Notes'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>Notes</div></Tooltip>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter "}
                                        name={"note"}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(5) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 5, 5)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16 text-muted">email</Icon><Tooltip title='Emails'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>Emails</div></Tooltip>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter "}
                                        name={'email'}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(100) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 100, 100)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16 text-muted">storage</Icon><Tooltip title='System Updates'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>System Updates</div></Tooltip>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter "}
                                        name={'sysupdate'}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(1) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 1001, 1001)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                            </Menu>
                        </List>
                    </Grid>
                </Grid>
            </>
        )
    }
}



class ContactLogs extends Component {

    state = {
        viewHtmlData: '',
        shouldOpenViewEmailDialog: false,
        FullViewEmailDialog: false,
        minimizeViewEmailDialog: false,
        Downloadoptions: '',
        showDownloadoptions: false,
        selectedDate: new Date(),
        isOpenedEmailIds: [],
        isOpenedReplayEmail: false,
        allLogs: [],
        logvaluetype: 'Phone Call',
        logtype: '',
        textvalue: '',
        dateformat: 'MM/DD/YYYY',
        timeZone: 'America/New_York',
        shouldOpenCreateLogDialog: false,
        FullCreateContactDialog: false,
        minimizeContactDialog: false,
        isDeleteLogConfirmDialoOpen: false,
        isEditLog: false,
        isEditComment: false,
        logid: '',
        commentid: '',
        Commentoptions: '',
        showCommentoptions: false,
        isOpenedCommentIds: [],
        logtypeid: [],
        activitypeid: [],
        activities: [],
    };

    componentDidMount() {
        this.props.getActivityTypes(this.props.apolloClient.client);
    }
    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;
            this.setState({ dateformat: format, timeZone: timezone })
        }
        if (props.settingReducer.masterActivityTypesList) {
            this.setState({ activities: props.settingReducer.masterActivityTypesList.data });
        }
        if (props.contactReducer.contactLogList && props.contactReducer.contactLogList.data && props.contactReducer.contactLogList.data.logdata && props.contactReducer.contactLogList.data.logdata.length > 0) {
            let logged_in_user_email = JSON.parse(localStorage.getItem("user_data_session")).email;
            let logData = [];
            let results = [];

            let smallData = props.contactReducer.contactLogList.data.logdata[props.contactReducer.contactLogList.data.logdata.length - 1]
            let resultList = this.getMonths(smallData.createddate, props.contactReducer.contactLogList.data.logdata[0].createddate);

            let name = '';
            let source = '';
            if (props.contactReducer.contactFieldData.data) {
                let fieldSource = props.contactReducer.contactFieldData.data;
                var sourcekey = this.findWithAttr(fieldSource, 'fieldkey', 'name');

                if (fieldSource[sourcekey].contactdetail && fieldSource[sourcekey].contactdetail[0].fieldvalue)
                    source = fieldSource[sourcekey].contactdetail[0].fieldvalue;
            }
            resultList.map((mont) => {
                results[mont.str] = [];
                props.contactReducer.contactLogList.data.logdata.map((data) => {
                    if (mont.str == moment.unix(data.createddate).tz(this.state.timeZone).format('MMM YYYY')) {
                        if (!data.email_type) {
                            let template = data.template;
                            let color_class = (data.__user__.name) ? data.__user__.name.charAt(0) : "";
                            let text = (data.text) ? data.text : "";
                            let loguser = (data.__user__.email == logged_in_user_email) ? 'You' : data.__user__.name;
                            let template1 = template.replace("{{user}}", loguser);

                            let new_template = "";

                            let create_date = data.createddate;
                            let updateddate = data.updateddate;

                            if (data.logtype == 0) // Phone call
                            {
                                updateddate = data.logdate;
                                new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                            }
                            
                            if (new_template != "") {
                                logData.push(
                                    {
                                        logId: data.id,
                                        creaetedDate: moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat),
                                        emailtype: 0,
                                        activitytype: data.__activitytype__,
                                        comments: data.__commentlist__,
                                        user: data.__user__.name,
                                        useremail: data.__user__.email,
                                        propertyType: data.propertyname,
                                        color: color_class,
                                        template: new_template,
                                        text: text,
                                        logtype: data.logtype,
                                        time: (updateddate != null)
                                            ?
                                            moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm a')
                                            :
                                            moment.unix(create_date).tz(this.state.timeZone).format('hh:mm a'),
                                        date: (updateddate != null)
                                            ?
                                            moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                                            :
                                            moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)
                                    }
                                );
                            }
                        }

                        if (logData.length > 0) {
                            results[mont.str].push(logData);
                            logData = []
                        }
                    }
                });
            });
            let temp_arr1 = [];

            Object.keys(results).forEach(function (key) {
                let temp_arr2 = [];
                temp_arr2['key'] = key;
                temp_arr2['log'] = results[key];
                temp_arr1.push(temp_arr2);


            })

            // this.setState({ LogResult: results, contact_id: props.contact_id});
            this.setState({ allLogs: temp_arr1, contact_id: props.contact_id });
        }
        else if (props.contactReducer.contactLogList && props.contactReducer.contactLogList.data && props.contactReducer.contactLogList.data.logdata && props.contactReducer.contactLogList.data.logdata.length <= 0) {
            this.setState({ allLogs: [], contact_id: props.contact_id });
        }

    }


    handleContactDialogClose = () => {
        this.setState({ shouldOpenCreateLogDialog: false, FullCreateContactDialog: false, minimizeContactDialog: false });
    };
    handleContactDialogMinimize = () => {
        this.setState(oldStateminimizeContactDialog => ({ minimizeContactDialog: !oldStateminimizeContactDialog.minimizeContactDialog }));
        this.setState({ FullCreateContactDialog: false })
    };
    handleContactDialogFull = () => {
        this.setState(oldStateFullCreateContactDialog => ({ FullCreateContactDialog: !oldStateFullCreateContactDialog.FullCreateContactDialog }));
        this.setState({ minimizeContactDialog: false })
    };


    handleViewEmailDialogClose = () => {
        this.setState({ shouldOpenViewEmailDialog: false, FullViewEmailDialog: false, minimizeViewEmailDialog: false });
    };
    handleViewEmailDialogMinimize = () => {
        this.setState(oldStateminimizeViewEmailDialog => ({ minimizeViewEmailDialog: !oldStateminimizeViewEmailDialog.minimizeViewEmailDialog }));
        this.setState({ FullViewEmailDialog: false })
    };
    handleViewEmailDialogFull = () => {
        this.setState(oldStateFullViewEmailDialog => ({ FullViewEmailDialog: !oldStateFullViewEmailDialog.FullViewEmailDialog }));
        this.setState({ minimizeViewEmailDialog: false })
    };


    handlechange = (event, logid) => {
        this.setState({ [event.target.name]: event.target.value, logid: logid })
    }
    handleDateChange = date => {
        this.setState({ selectedDate: date });
    };
    handleCancelAddLog = () => {
        this.setState({ textvalue: '' })
    }
    handleSaveEditLog = () => {
        let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
        var logdate = moment.tz(date_test, this.state.timeZone).format();
        var date = moment.utc(logdate).format('X');

        // let date = moment(this.state.selectedDate).format("X");

        let textvalue = this.state.textvalue;
        let saveLogparams = [];

        saveLogparams['update'] = {
            text: textvalue,
            logtime: parseFloat(date),
            logdate: parseFloat(date),
            logid: parseFloat(this.state.logid),
        };
        // saveLogparams['contactid'] = this.state.contact_id
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        saveLogparams['getlog'] = { emailid: emailParam, contactid: parseFloat(this.props.contact_id), activitypeid: this.state.activitypeid, logtypeid: this.state.logtypeid };

        this.props.updateContactLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ isEditLog: false })
    }
    handleEditLog = (id, date, time, text) => {
        let date1 = new Date(date + ',' + time);
        this.setState({ isEditLog: true, textvalue: text, selectedDate: date1, logid: id });
    }
    handleCancelEdit = () => {
        this.setState({ isEditLog: false })
    }

    //------delete log
    handleDeleteContact = (logid) => {
        this.setState({ logid: logid });
        this.toggleDeleteDialog()
    }
    toggleDeleteDialog = () => {
        this.setState({ isDeleteLogConfirmDialoOpen: !this.state.isDeleteLogConfirmDialoOpen });
    };
    confirmDeleteLog = () => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let deletelogParams = { logid: parseFloat(this.state.logid), contactid: parseFloat(this.state.contact_id), emailid: emailParam, activitypeid: this.state.activitypeid, logtypeid: this.state.logtypeid };
        this.props.deleteContactLogDetail(this.props.apolloClient.client, deletelogParams);
        this.toggleDeleteDialog()
    };

    //-- Download option
    handleDownloadClick = (event) => {
        this.setState({ Downloadoptions: event.target, showDownloadoptions: true });
    }
    handleCloseDownload = (event) => {
        this.setState({ Downloadoptions: null, showDownloadoptions: false });
    }

    //email menu functions
    handleEmailHtmlPopupMain = (logdata) => {
        this.setState({ shouldOpenViewEmailDialog: true, viewHtmlData: logdata });
    }

    //- comments-----------------
    toggleComment = (logid) => {
        let isOpenedCommentIds = this.state.isOpenedCommentIds;

        if (isOpenedCommentIds.indexOf(logid) == -1) {
            isOpenedCommentIds.push(logid);
        }
        else {
            let index = isOpenedCommentIds.indexOf(logid);
            isOpenedCommentIds.splice(index, 1);
        }

        this.setState(({ commentvalue: "", logid: logid, isOpenedCommentIds: isOpenedCommentIds }));
        // this.setState(({ isOpenedComment: !this.state.isOpenedComment, logid: logid }));
    }

    handleCancelComment = () => {
        this.setState({ commentvalue: '' });
    }

    handleSaveCommentLog = (type) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = { type: type, comment: this.state.commentvalue, logid: parseFloat(this.state.logid), contactid: parseFloat(this.state.contact_id), emailid: emailParam, activitypeid: this.state.activitypeid, logtypeid: this.state.logtypeid };
        this.props.addContactLogComment(this.props.apolloClient.client, commentParams);
        this.setState({ commentvalue: '' });
    }

    //-edit comment
    handleSaveEditComment = (type) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = { type: type, commentid: parseFloat(this.state.commentid), comment: this.state.commentvalue, contactid: parseFloat(this.state.contact_id), emailid: emailParam, activitypeid: this.state.activitypeid, logtypeid: this.state.logtypeid };
        this.setState({ commentvalue: '', isEditComment: false, commentid: '' });
        this.props.updateContactLogComment(this.props.apolloClient.client, commentParams);
    }

    handleEditCommentMain = (id, comment) => {
        this.setState({ isEditComment: true, commentvalue: comment, commentid: id });
    }

    handleCancelEditComment = () => {
        this.setState({ isEditComment: false, commentvalue: '' })
    }

    //-delete comment
    deleteComment = (cmt_id, type) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = { commentid: cmt_id, type: type, emailid: emailParam, contactid: parseFloat(this.state.contact_id), activitypeid: this.state.activitypeid, logtypeid: this.state.logtypeid };
        this.props.deleteContactLogComment(this.props.apolloClient.client, commentParams);
    }
    //--------------comments functions-----------

    //---- email functions
    toggleEmail = (id) => {
        let isOpenedEmailIds = this.state.isOpenedEmailIds;

        if (isOpenedEmailIds.indexOf(id) == -1) {
            isOpenedEmailIds.push(id);
        }
        else {
            let index = isOpenedEmailIds.indexOf(id);
            isOpenedEmailIds.splice(index, 1);
        }

        this.setState(({ logid: id, isOpenedEmailIds: isOpenedEmailIds }));
    }

    toggleReplayEmail = () => {
        this.setState(oldStateReplayEmail => ({ isOpenedReplayEmail: !oldStateReplayEmail.isOpenedReplayEmail }));
    }

    componentWillUnmount() {
        localStorage.removeItem('emailLogEmail');
    }
    //------------

    // -- get all months between two dates
    getMonths = (startDate, endDate) => {
        var resultList = [];
        var date = moment.unix(startDate).tz(this.state.timeZone)._d;
        var endDate = moment.unix(endDate).tz(this.state.timeZone)._d;

        var monthNameList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        while (date.getMonth() <= endDate.getMonth() + 1) {
            var stringDate = monthNameList[date.getMonth()] + " " + date.getFullYear();

            //get first and last day of month
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            resultList.push({
                str: stringDate,
            });
            date.setMonth(date.getMonth() + 1);
        }
        resultList.reverse();
        return resultList;
    };

    handlefiltermain = (types, activities) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        this.setState({ logtypeid: types, activitypeid: activities });

        let getfilterLogParam = { contactid: this.state.contact_id, emailid: emailParam, logtypeid: types, activitypeid: activities }
        this.props.getContactLogListNew(this.props.apolloClient.client, getfilterLogParam);
    }

    render() {
        const { isOpenedReplayEmail, commentvalue, allLogs, logvaluetype, isOpenedComment, textvalue, selectedDate, shouldOpenCreateLogDialog, minimizeContactDialog, FullCreateContactDialog } = this.state
        let current_logUser = JSON.parse(localStorage.getItem('user_data_session')).email;
        let loghtml = [];
        let logheader = [];
        let logbody = [];


        allLogs.map((logdata) => {
            logheader = [];
            logbody = [];
            logheader.push(
                <div className="box_header">
                    <div className="box_label"><h3 className="box_title">{logdata['key']}</h3></div>
                    <div className="box_tools">
                        <Fab className="bg-dark small-size overflow-hidden" size="small">
                            <h5 className="text-primary m-0 font-weight-normal">JS</h5>
                        </Fab>
                    </div>
                </div>
            )

            logdata['log'].map((log) => {
                log = log[0];
                if (log.emailtype != 0) {
                    if (log.emailtype == 1) {
                        logbody.push(
                            <div className="box_body">
                                <div className="lead_pl p-0">
                                    <Icon className="icon_left">mail</Icon>
                                    <Grid container justify="space-between">
                                        <Grid onClick={(id) => this.toggleEmail(log.logId)} className="flex-auto cursor_poiter">
                                            <h5 className="mb-0">{log.name} to You </h5>
                                            <p className="mt-4 mb-4 font-size-13 text-hint">{log.email_date_time}</p>
                                        </Grid>
                                        <Grid>
                                            <IconButton aria-label="Pin" size="small" className="button_size hover_show">
                                                <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" fillOpacity="0.6" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                                            </IconButton>
                                            <IconButton aria-label="Add" size="small" className="hover_show">
                                                <IconSmall>add_circle</IconSmall>
                                            </IconButton>
                                            <IconButton aria-label="Reply" size="small" className="hover_show" onClick={this.toggleReplayEmail}>
                                                <IconSmall>reply</IconSmall>
                                            </IconButton>
                                            <IconButton aria-label="Reply All" size="small" className="hover_show" onClick={this.toggleReplayEmail}>
                                                <IconSmall>reply_all</IconSmall>
                                            </IconButton>
                                            <ProfileEmailMenu logDatas={log} allfunction={this} />
                                        </Grid>
                                    </Grid>
                                    {(this.state.isOpenedEmailIds.indexOf(log.logId) == -1) ?
                                        <p className="mt-0 font-size-14"><span className="font-weight-500">{log.subject}</span> - {log.snippet}</p>
                                        :
                                        <div className="border_bx">
                                            <h6>{log.subject}</h6>
                                            <div dangerouslySetInnerHTML={{ __html: log.body_html }} />
                                        </div>
                                    }
                                    <div className="mt-20">
                                        {(log.attachment.length > 0) &&
                                            log.attachment.map((attach) => (
                                                <>
                                                    <span onClick={this.handleDownloadClick} className="crm-email-log-attachment">
                                                        <IconButton size="small">
                                                            <IconSmaller>attach_file</IconSmaller>
                                                        </IconButton>
                                                        {attach.file_name}
                                                        <IconButton size="small">
                                                            <IconSmaller>arrow_drop_down</IconSmaller>
                                                        </IconButton>
                                                    </span>
                                                    <Menu
                                                        id="long-menu"
                                                        anchorEl={this.state.Downloadoptions}
                                                        open={this.state.showDownloadoptions}
                                                        onClose={this.handleCloseDownload}
                                                    >
                                                        <MenuItem onClick={this.handleCloseDownload}>
                                                            <a href={attach.s3_file_name} target="__blank" className="text-center">
                                                                Download
                                                        </a>
                                                        </MenuItem>
                                                        <MenuItem onClick={this.handleCloseDownload}>
                                                            <span className="font-size-13 mr-10 mb-5">Relate To Lead</span>
                                                        </MenuItem>
                                                    </Menu>
                                                </>
                                            ))
                                        }
                                    </div>
                                    {isOpenedReplayEmail &&
                                        <LeadInboxCompose handleClose={this.toggleReplayEmail} />
                                    }

                                    <p className="mb-0 font-size-14">
                                        <span className="cursor_poiter" onClick={() => this.toggleComment(log.logId)}>
                                            <Icon className="vert-middle"> {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                                            Comments ({(log.comments) ? log.comments.length : 0})
                                    </span>
                                    </p>
                                    {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) &&
                                        <Grid container spacing={1}>
                                            <Grid item className="flex-auto">
                                                <div className="border_bx pr-10">
                                                    {(log.comments && log.comments.length > 0) &&
                                                        (log.comments).map((cmmt) => (
                                                            <Grid container className="mb-10" spacing={1}>
                                                                <Grid item>
                                                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                                        <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (cmmt.__commentby__.name).charAt(0)}>{(cmmt.__commentby__.name).charAt(0).toUpperCase()}</h5>
                                                                    </Fab>
                                                                </Grid>
                                                                {
                                                                    (this.state.isEditComment && (this.state.commentid == cmmt.id)) ?
                                                                        <>
                                                                            <Grid className="mb-10" item md={11}>
                                                                                <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveEditComment('email')}>
                                                                                    <Grid className="mb-10" item>
                                                                                        <TextValidator
                                                                                            required={true}
                                                                                            id="comment-input"
                                                                                            fullWidth
                                                                                            label="Text"
                                                                                            name="commentvalue"
                                                                                            placeholder="Add Comment"
                                                                                            value={commentvalue}
                                                                                            validators={["required"]}
                                                                                            errorMessages={["this field is required"]}
                                                                                            onChange={this.handlechange}
                                                                                        />
                                                                                    </Grid>
                                                                                    {(commentvalue && commentvalue.length > 0) &&
                                                                                        <Grid className="mb-10" item>
                                                                                            <Button className="mr-10" color="secondary" onClick={this.handleCancelEditComment}>Cancel</Button>
                                                                                            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                                        </Grid>
                                                                                    }
                                                                                </ValidatorForm>
                                                                            </Grid>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Grid item>
                                                                                <p className="mt-4 mb-4 font-size-13 text-hint">
                                                                                    {
                                                                                        (cmmt.lastmodifieddate != null)
                                                                                            ?
                                                                                            moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                                                            moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format("hh:mm a")
                                                                                            :
                                                                                            moment.unix(cmmt.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                                                            moment.unix(cmmt.createddate).tz(this.state.timeZone).format("hh:mm a")
                                                                                    }
                                                                                </p>
                                                                                <p className="mt-4 mb-4 font-size-13">{cmmt.comment}</p>
                                                                            </Grid>
                                                                            {(cmmt.__commentby__.email == current_logUser) &&
                                                                                <EditCommentMenu allData={this} cmt={cmmt} type="email" />
                                                                            }
                                                                        </>
                                                                }
                                                            </Grid>
                                                        ))
                                                    }
                                                    {(!this.state.isEditComment) &&
                                                        <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveCommentLog('email')}>
                                                            <Grid container spacing={1}>
                                                                <Grid className="mb-10" item >
                                                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                                        <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                                                    </Fab>
                                                                </Grid>
                                                                <Grid className="mb-10" item md={11}>
                                                                    <Grid className="mb-10" item>
                                                                        <TextValidator
                                                                            required={true}
                                                                            id="comment-input"
                                                                            fullWidth
                                                                            label="Text"
                                                                            name="commentvalue"
                                                                            placeholder="Add Comment"
                                                                            value={(log.logId == this.state.logid) ? commentvalue : ""}
                                                                            validators={["required"]}
                                                                            errorMessages={["this field is required"]}
                                                                            onChange={(event, logid) => this.handlechange(event, log.logId)}
                                                                        />
                                                                    </Grid>
                                                                    {
                                                                        (commentvalue && commentvalue.length > 0 && (log.logId == this.state.logid)) &&
                                                                        <Grid className="mb-10" item>
                                                                            <Button className="mr-10" color="secondary" onClick={this.handleCancelComment}>Cancel</Button>
                                                                            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </ValidatorForm>
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item className="align-self-center">

                                            </Grid>
                                        </Grid>
                                    }
                                </div>
                            </div>
                        )
                    }
                    else if (log.emailtype == 2) {
                        logbody.push(
                            <div className="box_body">
                                <div className="lead_pl p-0">
                                    <Icon className="icon_left">mail</Icon>
                                    <Grid container justify="space-between">
                                        <Grid onClick={(id) => this.toggleEmail(log.logId)} className="flex-auto cursor_poiter">
                                            <h5 className="mb-0">You to {log.email}</h5>
                                            <p className="mt-4 mb-4 font-size-13 text-hint">{log.email_date_time}</p>
                                        </Grid>
                                        <Grid>
                                            <IconButton aria-label="Pin" size="small" className="button_size hover_show">
                                                <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" fillOpacity="0.6" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                                            </IconButton>
                                            <IconButton aria-label="Add" size="small" className="hover_show">
                                                <IconSmall>add_circle</IconSmall>
                                            </IconButton>
                                            <IconButton aria-label="Reply" size="small" className="hover_show" onClick={this.toggleReplayEmail}>
                                                <IconSmall>reply</IconSmall>
                                            </IconButton>
                                            <IconButton aria-label="Reply All" size="small" className="hover_show" onClick={this.toggleReplayEmail}>
                                                <IconSmall>reply_all</IconSmall>
                                            </IconButton>
                                            <ProfileEmailMenu logDatas={log} allfunction={this} />
                                        </Grid>
                                    </Grid>
                                    {(this.state.isOpenedEmailIds.indexOf(log.logId) == -1) ?
                                        <p className="mt-0 font-size-14"><span className="font-weight-500">{log.subject}</span> - {log.snippet}</p>
                                        :
                                        <div className="border_bx">
                                            <h6>{log.subject}</h6>
                                            <div dangerouslySetInnerHTML={{ __html: log.body_html }} />
                                        </div>
                                    }
                                    <div className="mt-20">
                                        {(log.attachment.length > 0) &&
                                            log.attachment.map((attach) => (
                                                <>
                                                    <span onClick={this.handleDownloadClick} className="crm-email-log-attachment">
                                                        <IconButton size="small">
                                                            <IconSmaller>attach_file</IconSmaller>
                                                        </IconButton>
                                                        {attach.file_name}
                                                        <IconButton size="small">
                                                            <IconSmaller>arrow_drop_down</IconSmaller>
                                                        </IconButton>
                                                    </span>
                                                    <Menu
                                                        id="long-menu"
                                                        anchorEl={this.state.Downloadoptions}
                                                        open={this.state.showDownloadoptions}
                                                        onClose={this.handleCloseDownload}
                                                    >
                                                        <MenuItem onClick={this.handleCloseDownload}>
                                                            <a href={attach.s3_file_name} target="__blank" className="text-center">
                                                                Download
                                                        </a>
                                                        </MenuItem>
                                                        <MenuItem onClick={this.handleCloseDownload}>
                                                            <span className="font-size-13 mr-10 mb-5">Relate To Lead</span>
                                                        </MenuItem>
                                                    </Menu>
                                                </>
                                            ))
                                        }
                                    </div>
                                    {isOpenedReplayEmail &&
                                        <LeadInboxCompose handleClose={this.toggleReplayEmail} />
                                    }
                                    <p className="mb-0 font-size-14">
                                        <span className="cursor_poiter" onClick={() => this.toggleComment(log.logId)}>
                                            <Icon className="vert-middle"> {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                                            Comments ({(log.comments) ? log.comments.length : 0})
                                    </span>
                                    </p>
                                    {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) &&
                                        <Grid container spacing={1}>
                                            <Grid item className="flex-auto">
                                                <div className="border_bx pr-10">
                                                    {(log.comments && log.comments.length > 0) &&
                                                        (log.comments).map((cmmt) => (
                                                            <Grid container className="mb-10" spacing={1}>
                                                                <Grid item>
                                                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                                        <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (cmmt.__commentby__.name).charAt(0)}>{(cmmt.__commentby__.name).charAt(0).toUpperCase()}</h5>
                                                                    </Fab>
                                                                </Grid>
                                                                {
                                                                    (this.state.isEditComment && (this.state.commentid == cmmt.id)) ?
                                                                        <>
                                                                            <Grid className="mb-10" item md={11}>
                                                                                <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveEditComment('email')}>
                                                                                    <Grid className="mb-10" item>
                                                                                        <TextValidator
                                                                                            required={true}
                                                                                            id="comment-input"
                                                                                            fullWidth
                                                                                            label="Text"
                                                                                            name="commentvalue"
                                                                                            placeholder="Add Comment"
                                                                                            value={commentvalue}
                                                                                            validators={["required"]}
                                                                                            errorMessages={["this field is required"]}
                                                                                            onChange={this.handlechange}
                                                                                        />
                                                                                    </Grid>
                                                                                    {(commentvalue && commentvalue.length > 0) &&
                                                                                        <Grid className="mb-10" item>
                                                                                            <Button className="mr-10" color="secondary" onClick={this.handleCancelEditComment}>Cancel</Button>
                                                                                            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                                        </Grid>
                                                                                    }
                                                                                </ValidatorForm>
                                                                            </Grid>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Grid item>
                                                                                <p className="mt-4 mb-4 font-size-13 text-hint">
                                                                                    {
                                                                                        (cmmt.lastmodifieddate != null)
                                                                                            ?
                                                                                            moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                                                            moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format("hh:mm a")
                                                                                            :
                                                                                            moment.unix(cmmt.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                                                            moment.unix(cmmt.createddate).tz(this.state.timeZone).format("hh:mm a")
                                                                                    }
                                                                                </p>
                                                                                <p className="mt-4 mb-4 font-size-13">{cmmt.comment}</p>
                                                                            </Grid>
                                                                            {(cmmt.__commentby__.email == current_logUser) &&
                                                                                <EditCommentMenu allData={this} cmt={cmmt} type="email" />
                                                                            }
                                                                        </>
                                                                }
                                                            </Grid>
                                                        ))
                                                    }
                                                    {(!this.state.isEditComment) &&
                                                        <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveCommentLog('email')}>
                                                            <Grid container spacing={1}>
                                                                <Grid className="mb-10" item >
                                                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                                        <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                                                    </Fab>
                                                                </Grid>
                                                                <Grid className="mb-10" item md={11}>
                                                                    <Grid className="mb-10" item>
                                                                        <TextValidator
                                                                            required={true}
                                                                            id="comment-input"
                                                                            fullWidth
                                                                            label="Text"
                                                                            name="commentvalue"
                                                                            placeholder="Add Comment"
                                                                            value={(log.logId == this.state.logid) ? commentvalue : ""}
                                                                            validators={["required"]}
                                                                            errorMessages={["this field is required"]}
                                                                            onChange={(event, logid) => this.handlechange(event, log.logId)}
                                                                        />
                                                                    </Grid>
                                                                    {
                                                                        (commentvalue && commentvalue.length > 0 && (log.logId == this.state.logid)) &&
                                                                        <Grid className="mb-10" item>
                                                                            <Button className="mr-10" color="secondary" onClick={this.handleCancelComment}>Cancel</Button>
                                                                            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </ValidatorForm>
                                                    }

                                                </div>
                                            </Grid>
                                            <Grid item className="align-self-center">

                                            </Grid>
                                        </Grid>

                                    }
                                </div>
                            </div>
                        )
                    }
                } else {
                    if (log.logtype == 0) {
                        logbody.push(
                            <div className="box_body">
                                <div className="lead_pl p-0">
                                    <Grid container justify="space-between">
                                        <Grid>
                                            <h5 className="mb-0">
                                                {log.activitytype != null && <FontAwesome className={log.activitytype.icon + " mr-10 vert-middle"} style={{ fontSize: '20px', padding: '5px' }} />}
                                                {log.propertyType}
                                            </h5>
                                            {/* <p className="mt-4 mb-4 font-size-13 text-hint">{log.creaetedDate}</p> */}
                                            <p className="mt-4 mb-4 font-size-13 text-hint">{log.date}, {log.time}</p>
                                        </Grid>
                                        <Grid>
                                            <IconButton aria-label="Pin" size="small" className="button_size hover_show">
                                                <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" fillOpacity="0.6" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                                            </IconButton>
                                            <IconButton aria-label="Add" size="small" onClick={() => this.setState({ logtype: log.logtype, logvaluetype: log.propertyType, shouldOpenCreateLogDialog: true })} className="hover_show">
                                                <IconSmall>add_circle</IconSmall>
                                            </IconButton>
                                            {(log.useremail == current_logUser) &&
                                                <IconButton aria-label="Delete" size="small" onClick={(logId) => this.handleDeleteContact(log.logId)} className="hover_show">
                                                    <IconSmall>delete</IconSmall>
                                                </IconButton>
                                            }
                                        </Grid>
                                    </Grid>
                                    {(this.state.isEditLog && (this.state.logid == log.logId)) ?
                                        <>
                                            <ValidatorForm noValidate debounceTime={500} onSubmit={this.handleSaveEditLog}>
                                                <TextValidator
                                                    required={true}
                                                    id="search-input"
                                                    fullWidth
                                                    label="Text"
                                                    placeholder="Click here to add Text"
                                                    value={textvalue}
                                                    validators={["required"]}
                                                    errorMessages={["this field is required"]}
                                                    onChange={(event, logid) => this.handlechange(event, log.logId)}
                                                    name="textvalue"
                                                />
                                                <div className="mt-40">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={4} className="pt-0">
                                                                <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Date</InputLabel>
                                                                <KeyboardDatePicker
                                                                    margin="normal"
                                                                    id="date-picker-dialog"
                                                                    format="MM/dd/yyyy"
                                                                    maxDate={new Date()}
                                                                    value={selectedDate}
                                                                    onKeyPress={(e) => e.preventDefault()}
                                                                    onChange={this.handleDateChange}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} className="pt-0">
                                                                <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Time</InputLabel>
                                                                <KeyboardTimePicker
                                                                    margin="normal"
                                                                    id="time-picker"
                                                                    format="hh:mm a"
                                                                    value={selectedDate}
                                                                    onKeyPress={(e) => e.preventDefault()}
                                                                    onChange={this.handleDateChange}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change time',
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </MuiPickersUtilsProvider>
                                                    <div className="pull-right">
                                                        <Button className="mr-10" color="secondary" onClick={this.handleCancelEdit}>Cancel</Button>
                                                        <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Update</Button>
                                                    </div>
                                                </div>
                                            </ValidatorForm>
                                        </>
                                        :
                                        <div className="border_bx">
                                            {log.text}
                                            {(log.useremail == current_logUser) &&
                                                <div className="hover_show">
                                                    <IconButton aria-label="Delete" size="small" onClick={(id, date, time, text) => this.handleEditLog(log.logId, log.date, log.time, log.text)}>
                                                        <IconSmall>edit</IconSmall>
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <p className="font-size-14 pt-10">
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Fab className="small-size overflow-hidden mr-8" color="secondary" size="small">
                                                    <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                                </Fab>
                                            </Grid>
                                            <Grid item>
                                                <span className="font-weight-500">{log.user}</span> {log.template}
                                            </Grid>
                                        </Grid>
                                    </p>

                                    <p className="mb-0 font-size-14">
                                        <span className="cursor_poiter" onClick={() => this.toggleComment(log.logId)}>
                                            <Icon className="vert-middle"> {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                                            Comments ({(log.comments) ? log.comments.length : 0})
                                        </span>
                                    </p>
                                    {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) &&
                                        <Grid container spacing={1}>
                                            <Grid item className="flex-auto">
                                                <div className="border_bx pr-10">
                                                    {(log.comments && log.comments.length > 0) &&
                                                        (log.comments).map((cmmt) => (
                                                            <Grid container className="mb-10" spacing={1}>
                                                                <Grid item>
                                                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                                        <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (cmmt.__commentby__.name).charAt(0)}>{(cmmt.__commentby__.name).charAt(0).toUpperCase()}</h5>
                                                                    </Fab>
                                                                </Grid>
                                                                {
                                                                    (this.state.isEditComment && (this.state.commentid == cmmt.id)) ?
                                                                        <>
                                                                            <Grid className="mb-10" item md={11}>
                                                                                <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveEditComment('log')}>
                                                                                    <Grid className="mb-10" item>
                                                                                        <TextValidator
                                                                                            required={true}
                                                                                            id="comment-input"
                                                                                            fullWidth
                                                                                            label="Text"
                                                                                            name="commentvalue"
                                                                                            placeholder="Add Comment"
                                                                                            value={commentvalue}
                                                                                            validators={["required"]}
                                                                                            errorMessages={["this field is required"]}
                                                                                            onChange={this.handlechange}
                                                                                        />
                                                                                    </Grid>
                                                                                    {(commentvalue && commentvalue.length > 0) &&
                                                                                        <Grid className="mb-10" item>
                                                                                            <Button className="mr-10" color="secondary" onClick={this.handleCancelEditComment}>Cancel</Button>
                                                                                            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                                        </Grid>
                                                                                    }
                                                                                </ValidatorForm>
                                                                            </Grid>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Grid item>
                                                                                <p className="mt-4 mb-4 font-size-13 text-hint">
                                                                                    {
                                                                                        (cmmt.lastmodifieddate != null)
                                                                                            ?
                                                                                            moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                                                            moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format("hh:mm a")
                                                                                            :
                                                                                            moment.unix(cmmt.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                                                            moment.unix(cmmt.createddate).tz(this.state.timeZone).format("hh:mm a")
                                                                                    }
                                                                                </p>
                                                                                <p className="mt-4 mb-4 font-size-13">{cmmt.comment}</p>
                                                                            </Grid>
                                                                            {(cmmt.__commentby__.email == current_logUser) &&
                                                                                <EditCommentMenu allData={this} cmt={cmmt} type="log" />
                                                                            }
                                                                        </>
                                                                }
                                                            </Grid>
                                                        ))
                                                    }
                                                    {(!this.state.isEditComment) &&
                                                        <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveCommentLog('log')}>
                                                            <Grid container spacing={1}>
                                                                <Grid className="mb-10" item >
                                                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                                        <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                                                    </Fab>
                                                                </Grid>
                                                                <Grid className="mb-10" item md={11}>
                                                                    <Grid className="mb-10" item>
                                                                        <TextValidator
                                                                            required={true}
                                                                            id="comment-input"
                                                                            fullWidth
                                                                            label="Text"
                                                                            name="commentvalue"
                                                                            placeholder="Add Comment"
                                                                            value={(log.logId == this.state.logid) ? commentvalue : ""}
                                                                            validators={["required"]}
                                                                            errorMessages={["this field is required"]}
                                                                            onChange={(event, logid) => this.handlechange(event, log.logId)}
                                                                        />
                                                                    </Grid>
                                                                    {
                                                                        (commentvalue && commentvalue.length > 0 && (log.logId == this.state.logid)) &&
                                                                        <Grid className="mb-10" item>
                                                                            <Button className="mr-10" color="secondary" onClick={this.handleCancelComment}>Cancel</Button>
                                                                            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </ValidatorForm>
                                                    }

                                                </div>
                                            </Grid>
                                            <Grid item className="align-self-center">

                                            </Grid>
                                        </Grid>
                                    }
                                </div>
                            </div>
                        )
                    }
                    else if (log.logtype == 5) {
                        logbody.push(
                            <div className="box_body">
                                <div className="lead_pl p-0">

                                    <Grid container justify="space-between">
                                        <Grid>
                                            <h5 className="mb-0"><Icon className="vert-middle mr-10">note</Icon>{log.propertyType}</h5>
                                            <p className="mt-4 mb-4 font-size-13 text-hint">{log.date}, {log.time}</p>
                                        </Grid>
                                        <Grid>
                                            <IconButton aria-label="Pin" size="small" className="button_size hover_show">
                                                <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" fillOpacity="0.6" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                                            </IconButton>
                                            <IconButton aria-label="Add" size="small" onClick={() => this.setState({ logtype: log.logtype, logvaluetype: log.propertyType, shouldOpenCreateLogDialog: true })} className="hover_show">
                                                <IconSmall>add_circle</IconSmall>
                                            </IconButton>
                                            {(log.useremail == current_logUser) &&
                                                <IconButton aria-label="Delete" size="small" onClick={(logId) => this.handleDeleteContact(log.logId)} className="hover_show">
                                                    <IconSmall>delete</IconSmall>
                                                </IconButton>
                                            }
                                        </Grid>
                                    </Grid>
                                    {(this.state.isEditLog && (this.state.logid == log.logId)) ?
                                        <>
                                            <ValidatorForm noValidate debounceTime={500} onSubmit={this.handleSaveEditLog}>
                                                <TextValidator
                                                    required={true}
                                                    id="search-input"
                                                    fullWidth
                                                    label="Text"
                                                    placeholder="Click here to add Text"
                                                    value={textvalue}
                                                    validators={["required"]}
                                                    errorMessages={["this field is required"]}
                                                    onChange={(event, logid) => this.handlechange(event, log.logId)}
                                                    name="textvalue"
                                                />
                                                <div className="mt-40">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={4} className="pt-0">
                                                                <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Date</InputLabel>
                                                                <KeyboardDatePicker
                                                                    margin="normal"
                                                                    id="date-picker-dialog"
                                                                    format="MM/dd/yyyy"
                                                                    maxDate={new Date()}
                                                                    value={selectedDate}
                                                                    onKeyPress={(e) => e.preventDefault()}
                                                                    onChange={this.handleDateChange}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} className="pt-0">
                                                                <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Time</InputLabel>
                                                                <KeyboardTimePicker
                                                                    margin="normal"
                                                                    id="time-picker"
                                                                    format="hh:mm a"
                                                                    value={selectedDate}
                                                                    onKeyPress={(e) => e.preventDefault()}
                                                                    onChange={this.handleDateChange}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change time',
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </MuiPickersUtilsProvider>
                                                    <div className="pull-right">
                                                        <Button className="mr-10" color="secondary" onClick={this.handleCancelEdit}>Cancel</Button>
                                                        <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Update</Button>
                                                    </div>
                                                </div>
                                            </ValidatorForm>
                                        </>
                                        :
                                        <div className="border_bx">
                                            {log.text}
                                            {(log.useremail == current_logUser) &&
                                                <div className="hover_show">
                                                    <IconButton aria-label="Delete" size="small" onClick={(id, date, time, text) => this.handleEditLog(log.logId, log.date, log.time, log.text)}>
                                                        <IconSmall>edit</IconSmall>
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <p className="font-size-14 pt-10">
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Fab className="small-size overflow-hidden mr-8" color="secondary" size="small">
                                                    <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                                </Fab>
                                            </Grid>
                                            <Grid item>
                                                <span className="font-weight-500">{log.user}</span>  added a note
                                            </Grid>
                                        </Grid>
                                    </p>

                                    <p className="mb-0 font-size-14">
                                        <span className="cursor_poiter" onClick={() => this.toggleComment(log.logId)}>
                                            <Icon className="vert-middle"> {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                                            Comments ({(log.comments) ? log.comments.length : 0})
                                        </span>
                                    </p>
                                    {(this.state.isOpenedCommentIds.indexOf(log.logId) != -1) &&
                                        <Grid container spacing={1}>
                                            <Grid item className="flex-auto">
                                                <div className="border_bx pr-10">
                                                    {(log.comments && log.comments.length > 0) &&
                                                        (log.comments).map((cmmt) => (
                                                            <Grid container className="mb-10" spacing={1}>
                                                                <Grid item>
                                                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                                        <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (cmmt.__commentby__.name).charAt(0)}>{(cmmt.__commentby__.name).charAt(0).toUpperCase()}</h5>
                                                                    </Fab>
                                                                </Grid>
                                                                {
                                                                    (this.state.isEditComment && (this.state.commentid == cmmt.id)) ?
                                                                        <>
                                                                            <Grid className="mb-10" item md={11}>
                                                                                <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveEditComment('log')}>
                                                                                    <Grid className="mb-10" item>
                                                                                        <TextValidator
                                                                                            required={true}
                                                                                            id="comment-input"
                                                                                            fullWidth
                                                                                            label="Text"
                                                                                            name="commentvalue"
                                                                                            placeholder="Add Comment"
                                                                                            value={commentvalue}
                                                                                            validators={["required"]}
                                                                                            errorMessages={["this field is required"]}
                                                                                            onChange={this.handlechange}
                                                                                        />
                                                                                    </Grid>
                                                                                    {(commentvalue && commentvalue.length > 0) &&
                                                                                        <Grid className="mb-10" item>
                                                                                            <Button className="mr-10" color="secondary" onClick={this.handleCancelEditComment}>Cancel</Button>
                                                                                            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                                        </Grid>
                                                                                    }
                                                                                </ValidatorForm>
                                                                            </Grid>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Grid item>
                                                                                <p className="mt-4 mb-4 font-size-13 text-hint">
                                                                                    {
                                                                                        (cmmt.lastmodifieddate != null)
                                                                                            ?
                                                                                            moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                                                            moment.unix(cmmt.lastmodifieddate).tz(this.state.timeZone).format("hh:mm a")
                                                                                            :
                                                                                            moment.unix(cmmt.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " +
                                                                                            moment.unix(cmmt.createddate).tz(this.state.timeZone).format("hh:mm a")
                                                                                    }
                                                                                </p>
                                                                                <p className="mt-4 mb-4 font-size-13">{cmmt.comment}</p>
                                                                            </Grid>
                                                                            {(cmmt.__commentby__.email == current_logUser) &&
                                                                                <EditCommentMenu allData={this} cmt={cmmt} type="log" />
                                                                            }
                                                                        </>
                                                                }
                                                            </Grid>
                                                        ))
                                                    }
                                                    {(!this.state.isEditComment) &&
                                                        <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => this.handleSaveCommentLog('log')}>
                                                            <Grid container spacing={1}>
                                                                <Grid className="mb-10" item >
                                                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                                        <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                                                    </Fab>
                                                                </Grid>
                                                                <Grid className="mb-10" item md={11}>
                                                                    <Grid className="mb-10" item>
                                                                        <TextValidator
                                                                            required={true}
                                                                            id="comment-input"
                                                                            fullWidth
                                                                            label="Text"
                                                                            name="commentvalue"
                                                                            placeholder="Add Comment"
                                                                            value={(log.logId == this.state.logid) ? commentvalue : ""}
                                                                            validators={["required"]}
                                                                            errorMessages={["this field is required"]}
                                                                            onChange={(event, logid) => this.handlechange(event, log.logId)}
                                                                        />
                                                                    </Grid>
                                                                    {
                                                                        (commentvalue && commentvalue.length > 0 && (log.logId == this.state.logid)) &&
                                                                        <Grid className="mb-10" item>
                                                                            <Button className="mr-10" color="secondary" onClick={this.handleCancelComment}>Cancel</Button>
                                                                            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </ValidatorForm>
                                                    }

                                                </div>
                                            </Grid>
                                            <Grid item className="align-self-center">

                                            </Grid>
                                        </Grid>
                                    }
                                </div>
                            </div>
                        )
                    }
                    else {
                        logbody.push(
                            <div className="box_portlet">
                                <div className="box_body">
                                    <div className="lead_pl p-0">
                                        <p className="font-size-14">
                                            <Grid container spacing={1}>
                                                <Grid item md={1}>
                                                    <Fab className="small-size overflow-hidden mr-8" color="secondary" size="small">
                                                        <h5 className={"text-white m-0 font-weight-normal  avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                                    </Fab>
                                                </Grid>
                                                <Grid item md={11}>
                                                    <span className="font-weight-500">{log.template}</span>
                                                    <p className="mt-4 mb-4 font-size-13 text-hint">{log.date}, {log.time}</p>
                                                    <p className="ml-40 font-size-14">{log.text}</p>
                                                </Grid>
                                            </Grid>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            })
            if (logbody && logbody.length > 0) {
                loghtml.push(logheader);
                loghtml.push(logbody);
            }
        })


        return (
            <>
                {/* <FiltersMenu proVar={this.state.activities} thisData={this} /> */}
                <div>
                    {loghtml.map((month_log) => (
                        <div className="lead_upcoming">
                            <div className="box_portlet">
                                {month_log}
                            </div>
                        </div>
                    ))}
                </div>
                {this.state.shouldOpenViewEmailDialog && (
                    <ViewHtmlDialog
                        handleClose={this.handleViewEmailDialogClose}
                        open={this.state.shouldOpenViewEmailDialog}
                        handleMinimize={this.handleViewEmailDialogMinimize}
                        handleFull={this.handleViewEmailDialogFull}
                        minimizeScreen={this.state.minimizeViewEmailDialog}
                        fullScreen={this.state.FullViewEmailDialog}
                        logDatas={this.state.viewHtmlData}
                    />
                )}
                {shouldOpenCreateLogDialog && (
                    <CreatelogDialog
                        handleClose={this.handleContactDialogClose}
                        open={shouldOpenCreateLogDialog}
                        handleMinimize={this.handleContactDialogMinimize}
                        handleFull={this.handleContactDialogFull}
                        minimizeScreen={minimizeContactDialog}
                        fullScreen={FullCreateContactDialog}
                        contact_id={this.state.contact_id}
                        logvaluetype={this.state.logvaluetype}
                        logtype={this.state.logtype}
                    />
                )}
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteLogConfirmDialoOpen}
                    onClose={this.toggleDeleteDialog}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove selected contact log ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button type="Button" onClick={() => this.confirmDeleteLog()} className="btn_Primary">
                            Ok
                        </Button>

                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

//export default ContactLogs;

const mapStateToProps = state => {
    return {
        contactReducer: state.contactReducer,
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getContactLogListNew: (client, request) => dispatch(actions.getContactLogListNew(client, request)),
        // getLeadLogList: (client, request) => dispatch(actions.getLeadLogList(client, request)),
        getActivityTypes: (client) => { dispatch(actions.getActivityTypes(client)) },
        saveContactLog: (client, request) => dispatch(actions.saveContactLog(client, request)),
        deleteContactLogDetail: (client, request) => dispatch(actions.deleteContactLogDetail(client, request)),
        updateContactLog: (client, request) => dispatch(actions.updateContactLog(client, request)),
        addContactLogComment: (client, request) => dispatch(actions.addContactLogComment(client, request)),
        updateContactLogComment: (client, request) => dispatch(actions.updateContactLogComment(client, request)),
        deleteContactLogComment: (client, request) => dispatch(actions.deleteContactLogComment(client, request)),

    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContactLogs);