import React, { Component } from "react";
import { NavLink,withRouter } from "react-router-dom";
import { Icon, IconButton, MenuItem ,Avatar} from "@material-ui/core";
import { navigations } from "../navigations";
import TopSearchBox from "./TopSearchBox";
import DropdownMenu from "./DropdownMenu";
import NotificationBar from "./NotificationBar";
import UserProfileDialog from "../Profile/profile"
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';

class TopNavbar extends Component {
    state = {
        shouldOpenProfileDialog: false,
        imagePreview: '',
    }

    componentDidMount() {
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let val = { userid: userData.id }
        this.props.getUserSetting(this.props.apolloClient.client, val);
        this.props.customFieldTypeList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {

        
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let user_datas = props.settingReducer.userSettingArray.data;
          
            let profile_image = user_datas.profileimage ? user_datas.profileimage : null;
            
           
            this.setState({ imagePreview: profile_image })
        }
    }

    openSetting = () => {
        this.props.history.push("/settings/settings")
    }

    redirectPage = (url) => {
        // this.props.history.push(url)
        window.location.href = url;
    }

    logoutUser = () => {
        localStorage.removeItem('user_data_session')
        localStorage.removeItem('selected_contact_filter_id')
        localStorage.removeItem('selected_lead_filter_id')
        localStorage.removeItem('selected_company_filter_id')
        localStorage.clear();
        window.location.href = '/sign-in'
    }
    handleLeadDialogClose = () => {
        this.setState({ shouldOpenProfileDialog: false });
    };
    

    render() {
        let { shouldOpenProfileDialog } = this.state;
        function renderLevels(levels) {
            return levels.map((item, key) => {
                if (item.children) {
                    return (
                        <li key={key}>
                            <NavLink to={item.path}>
                                {item.icon && (
                                    <Icon className="item-icon text-middle">{item.icon}</Icon>
                                )}
                                {item.name}
                            </NavLink>
                            <ul>{renderLevels(item.children)}</ul>
                        </li>
                    );
                } else {
                    return (
                        <li key={key}>
                            <NavLink to={item.path}>
                                {item.icon && (
                                    <Icon className="item-icon text-middle">{item.icon}</Icon>
                                )}
                                {item.name}
                            </NavLink>
                        </li>
                    );
                }
            });
        }
        return (
            <div className="navbar">
                <div className="container">
                    <div className="d-flex align-items-center h-100">
                        <div className="d-flex align-items-center brand">
                            <img src="/assets/images/logo.svg" alt="company-logo" width="30" />
                        </div>
                        <div className="d-flex flex-auto justify-content-between">
                            <ul className="menu">{renderLevels(navigations)}</ul>
                            <div className="position-relative align-items-center d-flex" style={{ minWidth: 240 }}>
                                <TopSearchBox />
                                <IconButton className="btn-white">
                                    <Icon>add_circle</Icon>
                                </IconButton>
                                <IconButton className="btn-white" onClick={this.openSetting}>
                                    <Icon>settings</Icon>
                                </IconButton>
                                <NotificationBar />
                                <DropdownMenu
                                    menuButton={
                                        <Avatar className="mx-8 text-middle circular-image-small cursor-pointer" alt="Profile Picture" src={this.state.imagePreview} />
                                    }
                                >
                                    <MenuItem
                                        className="d-flex align-items-center "
                                        style={{ minWidth: 185 }}
                                        onClick={() => this.redirectPage("/")}
                                    >
                                        <Icon> home </Icon>
                                        <span className="pl-16"> Home </span>
                                    </MenuItem>
                                    <MenuItem
                                        className="d-flex align-items-center "
                                        style={{ minWidth: 185 }}
                                        onClick={() => this.setState({ shouldOpenProfileDialog: true })}
                                    >
                                        <Icon> person </Icon>
                                        <span className="pl-16"> Profile </span>
                                    </MenuItem>
                                    <MenuItem
                                        className="d-flex align-items-center "
                                        style={{ minWidth: 185 }}
                                        onClick={() => this.logoutUser()}
                                    >
                                        <Icon> power_settings_new </Icon>
                                        <span className="pl-16"> Logout </span>
                                    </MenuItem>
                                </DropdownMenu>
                            </div>
                        </div>
                    </div>
                </div>
                {shouldOpenProfileDialog && (
                    <UserProfileDialog
                        handleClose={this.handleLeadDialogClose}
                        open={shouldOpenProfileDialog}
                    />
                )}
            </div>
        );
    }
}

// export default withRouter(TopNavbar);
const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
});
const mapDispatchToProps = dispatch => {
    return {
        getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
        customFieldTypeList: (client) => dispatch(actionCreators.customFieldTypeList(client)),
        
        // getUserProfileInfoFirst: (data) => dispatch(actionCreators.getUserProfileInfoFirst(data)),
        // updateCompanyInfo: (data) => dispatch(actionCreators.updateCompanyInfo(data)),
        // getBussinessType: () => dispatch(actionCreators.getBussinessType()),
        // GetCompanyStrength: () => dispatch(actionCreators.GetCompanyStrength()),
        // companyListById: (data) => dispatch(actionCreators.companyListById(data)),
    }
}
export default (connect(mapStateToProps,mapDispatchToProps)(withRouter(TopNavbar)));