import React from "react";
import { Icon, List, ListItem, Menu, MenuItem } from "@material-ui/core";



function FiltersMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <List className="p-0">
      <ListItem
        aria-label="More"
        aria-owns={open ? "filters_menu" : undefined}
        aria-haspopup="true"
       
        className="font-size-14 cursor_poiter  pr-0"
      >
        Filters (6) <Icon>arrow_drop_down</Icon>
      </ListItem>
      <Menu
        id="filters_menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="choose_field_menu"
      >
          <MenuItem  onClick={handleClick} className="filter_items"><Icon className="mr-16 text-muted">format_list_bulleted</Icon> All Activities <Icon className="right_side" color="primary">check_box</Icon></MenuItem>
          <MenuItem  onClick={handleClick} className="filter_items"><Icon className="mr-16 text-muted">subject</Icon> Notes <Icon className="right_side" color="primary">check_box</Icon></MenuItem>
          <MenuItem className="filter_items"><Icon className="mr-16 text-muted">email</Icon> Emails <Icon className="right_side" color="primary">check_box</Icon></MenuItem>
          <MenuItem className="filter_items"><Icon className="mr-16 text-muted">phone</Icon> Phone Calls <Icon className="right_side" color="primary">check_box</Icon></MenuItem>
          <MenuItem className="filter_items"><Icon className="mr-16 text-muted">event</Icon> Meetings <Icon className="right_side" color="primary">check_box</Icon></MenuItem>
          <MenuItem className="filter_items"><Icon className="mr-16 text-muted">event</Icon> Calendar Events <Icon className="right_side" color="primary">check_box</Icon></MenuItem>
          <MenuItem className="filter_items"><Icon className="mr-16 text-muted">layers</Icon> System Updates <Icon className="right_side" color="primary">check_box</Icon></MenuItem>   
      </Menu>
    </List>
  );
}

export default FiltersMenu;
