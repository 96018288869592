import React, { Component } from "react";
import { IconButton, Grid, Card, Icon, withStyles } from "@material-ui/core";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import { compose } from "redux";
import ListComponent from "../Common/ListComponent";


const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: "rgba(0, 0, 0, 0.6)",
  },
}))(Icon);

class ProfileRight extends Component {
  state = {
    attachment: null,
    activedatas: [],
    updatelistorder: true,
    opportunities_id:"",
  };

  componentDidMount() {
    let val = { recordtypeid: 4 };
    this.props.getRelatedMasterList(this.props.apolloClient.client, val);
    if(this.props.opportunities_id){
        this.setState({
            opportunities_id:this.props.opportunities_id,
        },()=>{
            this.forceUpdate()
        });
    }
  }
  componentWillReceiveProps(props) {
    if (props.opportunities_id != this.state.opportunities_id) {
          this.setState({
              opportunities_id:parseInt(props.opportunities_id),
          },()=>{
              this.forceUpdate()
          });
    }

    if (props.settingReducer.opportunityrelatedMaster.data) {
      const opportunityrelatedMaster = props.settingReducer.opportunityrelatedMaster.data;
      let activedatas = [];

      opportunityrelatedMaster.map((fieldData, index) => {
        if (fieldData.isactive === true) {
          activedatas.push({
            key: fieldData.id,
            alias: fieldData.alias,
            isactive: fieldData.isactive,
            orderid: fieldData.orderno,
          });
        }
      });
      if (this.state.updatelistorder == true)
        this.setState({ activedatas: activedatas }, () => this.forceUpdate());
    }
  }

  render() {
    var flag = 0;
    if(window.location.pathname == "/opportunities/opportunities-card" || window.location.pathname == "/opportunities/opportunities-list"){
      flag = 1
    }
    return (
      <>
        <div className={(flag == 1) ? "" : "lead_right"}>
          {this.state.opportunities_id && <ListComponent recordId= {this.state.opportunities_id} recordtype={"opportunities"} activedata={this.state.activedatas} relate allData={this} />}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRelatedMasterList: (client, request) => {
      dispatch(actions.getRelatedMasterList(client, request));
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    ProfileRight
);