import React, { Component } from "react";
import { Checkbox, Typography, Card, Grid, Button } from "@material-ui/core";
import { Table } from 'antd';
import BusinessIcon from '@material-ui/icons/Business';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
class ExportData extends Component {
    state = {

    };
    render() {
        return (
            <React.Fragment>
                <div className="v_scroll crm-custom-importdata-settings">
                    <div className="mb-40">
                        <Typography className=" p-10" variant="h5" color="inherit">Export Data</Typography>
                        <Typography className="text-hint p-10" variant="p">
                            Export your Copper data to an Excel file.
                        </Typography>
                    </div>
                    <Card className="p-20 mb-10">
                        <Typography className="font-size-18 p-10" variant="h6" color="inherit">Start a New Export</Typography>
                        <Grid container>
                            <Grid item md={3} className="text-center">
                                <div>
                                    <PersonIcon className="text-hint " style={{ fontSize: '80px' }} />
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" size="small">EXPORT PEOPLE</Button>
                                </div>
                            </Grid>

                            <Grid item md={3} className="text-center">
                                <div>
                                    <BusinessIcon className="text-hint font-size-100" style={{ fontSize: '80px' }} />
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" size="small">EXPORT COMPANIES</Button>
                                </div>
                            </Grid>
                            
                            <Grid item md={3} className="text-center">
                                <div>
                                    <AttachMoneyIcon className="text-hint" style={{ fontSize: '80px' }} />
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" size="small">EXPORT OPPORTUNIRIES</Button>
                                </div>
                            </Grid>

                            <Grid item md={3} className="text-center">
                                <div>
                                    <WorkIcon className="text-hint" style={{ fontSize: '80px' }} />
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" size="small">EXPORT PROJECTS</Button>
                                </div>
                            </Grid>
                            
                            <Grid item md={3} className="text-center">
                                <div>
                                    <CheckBoxIcon className="text-hint" style={{ fontSize: '80px' }} />
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" size="small">EXPORT TASKS</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>                    
                </div>
            </React.Fragment>
        );
    }
}

export default ExportData;
