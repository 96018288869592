import React, { Component } from "react";
import { FormControl,Checkbox,Dialog, IconButton, Button, Icon, Tooltip, Grid,DialogActions } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import FontAwesome from "react-fontawesome";
import FormControlLabel from '@material-ui/core/FormControlLabel';
class AddActivityType extends Component {
  state = {
    activitytypename:'',
    isEdit:false,
    id :'',
    dialogTitle:'New Activity Type',
    iconsArray:[
      'fa-commenting-o','fa-phone',
      'fa-calendar','fa-sticky-note',
      'fa-users',
      'fa-file-text','fa-comments-o',
      'fa-credit-card','fa-pencil-square',
      'fa-microphone','fa-file-o',
      'fa-video-camera','fa-headphones',
      'fa-play','fa-map-marker',
      'fa-location-arrow',
      'fa-paper-plane',
      'fa-cart-arrow-down',
    ],
    selectedIcon:"fa-commenting-o",
    countAsInteration:true,
    is_disable:'',
  };


  handleChange = (event, source) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  onClickIcon = (iconName) => {
    this.setState({
      selectedIcon: iconName
    });
  };
  handleInteractionChange = () => {
    this.setState({
      countAsInteration: !this.state.countAsInteration
    });
  };
  handleFormSubmit = () => {
    let error = [];
    if (!this.state.activitytypename || !this.state.activitytypename.trim()) {
      error.push("activitytypename")
    }

    let req = {};

    if(this.state.isEdit){
      req.icon = this.state.selectedIcon;
      req.ininteractions = this.state.countAsInteration?1:0;
      req.activitytypename = this.state.activitytypename;
      req.isdisable = this.state.is_disable;
      req.activitytypeid = parseInt(this.state.id);
      this.props.updateActivityTypesDetail(this.props.apolloClient.client,req);
    }else{
      req.icon = this.state.selectedIcon;
      req.ininteractions = this.state.countAsInteration?1:0;
      req.activitytypename = this.state.activitytypename;
      this.props.saveActivityTypes(this.props.apolloClient.client,req);
    }
  };

  componentWillReceiveProps(props){
    if(props.notification && props.notification.status === true){
        this.props.handleClose();
        this.props.clearNotification();
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('nameTrim', (value) => {
      if (!value || !value.trim()) {
        return false;
      }
      return true;
    });


    if(this.props.data.isEdit){
      this.setState({
          id:this.props.data.id,
          activitytypename:this.props.data.name,
          countAsInteration:this.props.data.ininteractions,
          countAsInteration:this.props.data.ininteractions,
          is_disable:this.props.data.is_disable,
          selectedIcon:this.props.data.icon,
          isEdit:true,
          dialogTitle:'Update Activity Type',
      });
    }else{
      this.setState({
          activitytypename:'',
          isEdit:false,
          id :'',
          dialogTitle:'New Activity Type',
      });
    }
  }

  render() {
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen} = this.props;
          console.log("isdisable", this.state.isdisable);
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">{this.state.dialogTitle}</h4>
          <div className="d-flex">
            <Tooltip title="Minimize Screen">
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">remove</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Full Screen">
              <IconButton onClick={handleFull}>
                <Icon className="text-white rotate-45">height</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
          <div className="dialog-body-no-scroll">
            <Grid container spacing={4}>
              <div className="p-24 mt-20 width-100">
                <ValidatorForm ref="form" noValidate debounceTime={500} onSubmit={this.handleFormSubmit}>
                  <Grid className="mb-16" container spacing={4}>
                    <Grid  sm={12} xs={12}>
                      <TextValidator
                        className="w-100 mb-16"
                        onChange={this.handleChange}
                        type="text"
                        placeholder="Enter Activity Type"
                        name="activitytypename"
                        value={this.state.activitytypename}
                        validators={["required", "nameTrim"]}
                        errorMessages={["this field is required", "this field is required"]}
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid  sm={12} xs={12}>
                      <span class="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Select Icon</span>
                    </Grid>
                    <Grid  sm={12} xs={12}>
                      {this.state.iconsArray.length > 0 &&  this.state.iconsArray.map(item => (
                          <div onClick={()=>this.onClickIcon(item)} style={{padding:'12px',fontSize:'25px',cursor:'pointer',float: 'left'}}>
                            <FontAwesome className={item + " "+`${this.state.selectedIcon === item ? "activityIconColor" : ""}`}/>
                          </div>
                      ))}
                    </Grid>
                    <Grid  sm={12} xs={12} style={{marginTop:"15px"}}>
                      <FormControl className="w-100 input_bx">
                        <FormControlLabel
                          control={
                            <Checkbox
                                //disabled={item.is_default ? true : false}
                                name={"interactions"}
                                id={"interactions"}
                                checked={(this.state.countAsInteration == 1) ? true : false}
                                onChange={(event,value) => this.handleInteractionChange(event)}
                                value={this.state.countAsInteration == 1 ? 1: 0}
                                color="primary"
                                
                            />
                          }
                          label="Count as interactions"
                        />
                      </FormControl>
                      
                    </Grid>
                  </Grid>
                  <DialogActions className="padding__0">
                    <Button onClick={() => this.props.handleClose()} variant="contained">Cancel</Button>
                    {!this.state.isEdit && 
                      <Button variant="contained" type="submit" color="primary">Save</Button>
                    }
                    {this.state.isEdit && 
                      <Button variant="contained" type="submit" color="primary">Update</Button>
                    }
                    
                  </DialogActions>
                </ValidatorForm>
              </div>
            </Grid>
          </div>
      </Dialog>
    );
  }
}
const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});
const mapDispatchToProps = dispatch => {
  return {
    saveActivityTypes: (c,req) => dispatch(actionCreators.saveActivityTypes(c,req)),
    updateActivityTypesDetail: (client,req) => dispatch(actionCreators.updateActivityTypesDetail(client,req)),
    clearNotification: () => dispatch(actionCreators.clearNotification()),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddActivityType);
