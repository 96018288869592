import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator,SelectValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

export const createDynamicFields = (data) => {
  var elements = data.state.fieldsList
  var fieldsArray = [];
  let relatedOptions = data.state.relatedListArray;
  relatedOptions = relatedOptions.filter(value => Object.keys(value).length !== 0);
  const options = relatedOptions.map((option) => {
    const firstLetter = option.type;
    return {
      firstLetter: firstLetter,
      ...option,
    };
  });
  elements.map((v, i) => {
    if (v.fieldstatus === true) {
      if(v.fieldtype.id == '1' && v.id=='67') {//Name
        fieldsArray.push(<><Grid item xs={12}>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event)=>data.handleBlur(event,v.fieldkey)}
            type="text"
            id={v.id}
            required={(v.recordcreationdefaultvalue == 2) ? true:false}
            disabled={v.recordcreationdefaultvalue == 3?true:false}
            className="w-100 input_bx"
            label={"Project Name"}
            onChange={(event) => data.handleChange({ fieldid: v.id, projectvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
          />
        </Grid></>
        )
      }else if (v.fieldtype.id == '1' && v.id == '68') { // related field

        var relatedVal = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          relatedVal = data.state.fieldsObj[v.fieldkey].values
          
        }


        fieldsArray.push(<Grid item xs={12}>
          
          <FormControl className="w-100 input_bx">
            <Autocomplete
              
              disableOpenOnFocus
              name={v.fieldkey}
              options={options}
              groupBy={(option) => option.firstLetter.toUpperCase()}
              getOptionLabel={(option) => option.name}
              value={relatedVal}
              disabled={(v.recordcreationdefaultvalue == 3 || data.state.isAddedFromSidebar) ? true : false}
              name={v.fieldkey}
              onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
              id={v.id}
              renderInput={params => (
                <TextValidator
                
       
                  {...params}
                  variant="standard"
                  label={v.fieldlabel}
                  fullWidth
                  name={v.fieldkey}
                  placeholder="Add Relation"
                  ref={ref => data[v.fieldkey] = ref}
                  onBlur={(event)=>data.handleBlur(event,v.fieldkey)}
                  //value={relatedVal && relatedVal.length ? 1 : null}
                  required={(v.recordcreationdefaultvalue == 2) ? true : false}
                  validators={(v.recordcreationdefaultvalue == 2 && (relatedVal == null || relatedVal.length == 0)) ? ["required"] : []}
                  errorMessages={(v.recordcreationdefaultvalue == 2 && (relatedVal == null || relatedVal.length == 0)) ? ["this field is required"] : []}
                />
              )}
            />
          </FormControl>
        </Grid>)
      }else if (v.fieldtype.id == '1' && v.fieldkey=='tags') { // tags
        var valTag = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          valTag = data.state.fieldsObj[v.fieldkey].values
        }

        fieldsArray.push(<Grid item xs={6}>
                <Autocomplete
                  disableOpenOnFocus
                  multiple
                  value={valTag}
                  filterSelectedOptions
                  disabled={v.recordcreationdefaultvalue == 3?true:false}
                  name={v.fieldkey}
                  id="tagInputId1"
                  options={data.state.systemTagsList.map(option => option.tagname)}
                  freeSolo
                  onChange={(event,value) => data.handleChange({ fieldid: v.id, values: value}, v.fieldkey)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option.charAt(0).toUpperCase() + option.slice(1)} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={params => (
                    <TextValidator
                      ref={ref => data[v.fieldkey] = ref}
                      onBlur={(event)=>data.handleBlur(event,v.fieldkey)} 
                      {...params}
                      label="Tags"
                      placeholder="Add tag"
                      name={v.fieldkey}
                      value={valTag && valTag.length ? 1 : null}
                      className="w-100 input_bx"
                      required={(v.recordcreationdefaultvalue == 2) ? true:false}
                      validators={(v.recordcreationdefaultvalue == 2 && (valTag == null || valTag.length == 0)) ? ["required"] : []}
                      errorMessages={(v.recordcreationdefaultvalue == 2 && (valTag == null || valTag.length == 0)) ? ["this field is required"] : []}
                      onFocus={() => 
                        setTimeout(() => {
                          document.getElementById("tagInputId1").value = "";
                        },0)
                      }
                    />
                  )}
                />
              </Grid>)
      } else if (v.fieldtype.id == '1' && v.id == '69') { // owner
        var nameOwner = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          nameOwner = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={6}>
          <FormControl className="w-100 input_bx">
                <Autocomplete
                  disableOpenOnFocus
                  forcePopupIcon={false}
                  name={v.fieldkey}
                  options={data.state.usersListArray}
                  getOptionLabel={option => option.name}
                  value={nameOwner}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  name={v.fieldkey}
                  onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
                  id={v.id}
                  renderInput={params => (
                    <TextValidator
                      {...params}
                      ref={ref => data[v.fieldkey] = ref}
                      onBlur={(event)=>data.handleBlur(event,v.fieldkey)}
                      variant="standard"
                      label={"Owner"}
                      fullWidth
                      name={v.fieldkey}
                      value={nameOwner && nameOwner.length ? 1 : null}
                      required={(v.recordcreationdefaultvalue == 2) ? true:false}
                      validators={(v.recordcreationdefaultvalue == 2 && (nameOwner == null || nameOwner.length == 0)) ? ["required"] : []}
                      errorMessages={(v.recordcreationdefaultvalue == 2 && (nameOwner == null || nameOwner.length == 0)) ? ["this field is required"] : []}
                    />
                  )}
                />
              </FormControl>
        </Grid>)
      }else if (v.fieldtype.id == '1' || v.fieldtype.id == '9') {//TextField with
        fieldsArray.push(<Grid item xs={6}>
          <TextValidator
            type="text"
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event)=>data.handleBlur(event,v.fieldkey)}
            required={(v.recordcreationdefaultvalue == 2) ? true:false}
            id={v.id}
            disabled={v.recordcreationdefaultvalue == 3?true:false}
            className="w-100 input_bx"
            label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, projectvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
          />
        </Grid>)
      }
      else if (v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7') {
        fieldsArray.push(<Grid item xs={6}>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event)=>data.handleBlur(event,v.fieldkey)}
            type="number"
            required={(v.recordcreationdefaultvalue == 2) ? true:false}
            id={v.id}
            disabled={v.recordcreationdefaultvalue == 3?true:false}
            className="w-100 input_bx"
            label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, projectvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '2') {
        fieldsArray.push(<Grid item xs={6}>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event)=>data.handleBlur(event,v.fieldkey)}
            multiline
            type="text"
            id={v.id}
            required={(v.recordcreationdefaultvalue == 2) ? true:false}
            disabled={v.recordcreationdefaultvalue == 3?true:false}
            className="w-100 input_bx"
            label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, projectvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2 && (data.state.country == "")) ? ["this field is required"] : []}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '4') {//if dropdown
        if(v.id === "70" || v.id === "72" || v.id === "75"){//72-visibility,70-status
          fieldsArray.push(<Grid item xs={6}>
            <FormControl  className="w-100 input_bx">
              {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
              <SelectValidator
                ref={ref => data[v.fieldkey] = ref}
                disabled={v.recordcreationdefaultvalue == 3?true:false}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].values : ""}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey)}
                name={v.fieldlabel}
                id={v.id}
                required={(v.recordcreationdefaultvalue == 2) ? true:false}
                label={v.fieldlabel}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
              >
                {v.options && v.options.map((item, key) =>
                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.name}</MenuItem>
                )}
              </SelectValidator>
            </FormControl>
          </Grid >
          )
        }else{
          fieldsArray.push(<Grid item xs={6}>
            <FormControl className="w-100 input_bx">
              {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
              <SelectValidator
                ref={ref => data[v.fieldkey] = ref}
                label={v.fieldlabel}
                disabled={v.recordcreationdefaultvalue == 3?true:false}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].values : ""}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey)}
                name={v.fieldlabel}
                id={v.id}
                required={(v.recordcreationdefaultvalue == 2)?true:false}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["this field is required"] : []}
              >
                {v.options && v.options.map((item, key) =>
                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.optionvalue}</MenuItem>
                )}
                
              </SelectValidator>
            </FormControl>
          </Grid >
          )
        }
      }
      if (v.fieldtype.id == '5') {//multidropdown field
        if (data.state.fieldsObj[v.fieldkey]) {
          var valDetail = [];
          valDetail = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={6}>
          <FormControl className="w-100 input_bx">
            <Autocomplete
              multiple
              disabled={v.recordcreationdefaultvalue == 3?true:false}
              name={v.fieldkey}
              onChange={(event,value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
              id={v.id}
              options={v.options}
              getOptionLabel={option => option.optionvalue}
              renderInput={params => (
                <TextValidator
                  {...params}
                  variant="standard"
                  label={v.fieldlabel}
                  ref={ref => data[v.fieldkey] = ref}
                  onBlur={(event)=>data.handleBlur(event,v.fieldkey)}
                  fullWidth
                  name={v.fieldkey}
                  value={valDetail && valDetail.length ? 1 : null}
                  required={(v.recordcreationdefaultvalue == 2) ? true:false}
                  validators={(v.recordcreationdefaultvalue == 2 && (valDetail == null || valDetail.length == 0)) ? ["required"] : []}
                  errorMessages={(v.recordcreationdefaultvalue == 2 && (valDetail == null || valDetail.length == 0)) ? ["this field is required"] : []}
                />
              )}
            />
          </FormControl>
        </Grid >
        )
      }
      if (v.fieldtype.id == '3') {//checkbox field
        fieldsArray.push(<Grid item xs={6} className="checkbox-margin-18">
          <FormControl className="w-100 input_bx">
            <FormControlLabel
              control={
                <Checkbox
                  name={v.fieldkey}
                  id={v.id}
                  checked={data.state.checkedB}
                  onChange={(event,value) => data.handleChange({ fieldid: v.id, projectvalue: value?"1":"0" }, v.fieldkey)}
                  value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
                  color="primary"
                />
              }
              label={v.fieldlabel}
            />
          </FormControl>
        </Grid >
        )
      }
      if (v.fieldtype.id == '8' && v.id != '71') {//datepicker field
        var dtFormat = "MM/dd/yyyy";
        var selectedDate = data.state.selectedDate;
        if (data.state.fieldsObj[v.fieldkey]) {
          selectedDate = data.state.fieldsObj[v.fieldkey].values
        }

        if(data.state.userSettings != ""){
          dtFormat = data.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }
        fieldsArray.push(<Grid item xs={6} >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl className="w-100 input_bx">
            <KeyboardDatePicker
                label={v.fieldlabel}
                format={dtFormat}
                required={(v.recordcreationdefaultvalue == 2) ? true:false}
                placeholder={dtFormat}
                error={(v.recordcreationdefaultvalue == 2 && selectedDate==null || selectedDate=="Invalid Date")?true:false}
                value={selectedDate}
                onChange={(event,value) => data.handleChange({ fieldid: v.id, values: event=="Invalid Date"?"Invalid Date":event}, v.fieldkey)}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                helperText={(v.recordcreationdefaultvalue == 2 && selectedDate==null)?"this field is required":(selectedDate=="Invalid Date")?"enter valid date.":""}

            />
          </FormControl>
          </MuiPickersUtilsProvider>
        </Grid >
        )
      }

      
    }
  })

  return fieldsArray;
}