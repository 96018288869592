import React, { Component } from "react";
import { IconButton, Icon, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card } from "@material-ui/core";
import DropdownDefault from "../Layout/DropdownDefault";
import NoteDialog from "./NoteDialog";
import CompanyProfileDetails from "./CompanyProfileDetails";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Redirect, Route } from 'react-router-dom'
import moment from 'moment';
import CreatelogDialog from "./CreatelogDialog";
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";

const IconButtonWhite = withStyles(theme => ({
    root: {
        // color: theme.palette.getContrastText(purple[500]),
        backgroundColor: "transparent",
        padding: "5px"
    }
}))(IconButton);

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
    }
}))(Icon);
class CompanyProfileLeft extends Component {
    state = {
        shouldOpenCreateLogDialog: false,
        FullCreateLogDialog: false,
        minimizeLogDialog: false,
        isDeleteLogConfirmDialoOpen: false,
        shouldOpenNoteDialog: false,
        logvaluetype:'',
        logtype:0,
        firstname: '',
        title: '',
        companyName: '',
        contactTypes: [],
        isDeleteConfirmDialoOpen: false,
        company_id: '',
        redirecttocompany: false,
        colorClass: "",
        isfavorite: false,
        star_icon: 'star_border',
        fieldsObj: {},
        fieldsList: [],
        usersListArray:[],
        companyImage:"",
        countryListArray:[],
        systemTagsList: [],
        companyCurrencyData: [],
        userSettings:"",

        shouldOpenCreateTaskDialog: false,
        FullCreateTaskDialog: false,
        minimizeTaskDialog: false,
        newTaskData:{},
    };

    componentWillMount() {
        if (this.props.company_id) {
            this.setState({
                company_id: this.props.company_id,
            });
        }

        this.props.getContactType(this.props.apolloClient.client);
        let val = { typeId: 3 }
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let userid = { userid: userData.id }
        this.props.getUserSetting(this.props.apolloClient.client, userid);
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.getCountryList(this.props.apolloClient.client);
        this.props.getTagsList(this.props.apolloClient.client);
        this.props.getCompanyCurrency(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        //storing user setting data in state
        if(props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
            const settingData = props.settingReducer.userSettingArray.data;
            this.setState({
                userSettings:settingData,
            })
        }
        if (props.settingReducer.companyCurrencyData.data != null && this.state.companyCurrencyData.length == 0) {
          var cData = props.settingReducer.companyCurrencyData.data;
          this.setState({ companyCurrencyData: cData[0] });
        }
        if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
          const tagsData = props.settingReducer.systemTagsList.data;
          this.setState({ systemTagsList: tagsData })
        }
        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
          const usersListArray = props.settingReducer.usersListArray.data;
          this.setState({ usersListArray: usersListArray })
        }

        if (props.settingReducer.companyCustomFieldList.data && this.state.fieldsList.length == 0) {
          const customFields = props.settingReducer.companyCustomFieldList.data;
          this.setState({ fieldsList: customFields })
        }

        if (props.companyReducer.companyFieldData && props.companyReducer.companyFieldData.data != null) {
            var data = props.companyReducer.companyFieldData.data;
            let star_ic = data[0].leadfavourite ? 'star' : 'star_border';
            this.setState({ star_icon: star_ic, isfavorite: data[0].leadfavourite });

            var nameKey = this.findWithAttr(data, 'id', "29");
            if (nameKey != -1 && data[nameKey].companydetail.length > 0 && data[nameKey].companydetail[0].values) {
                var names = data[nameKey].companydetail[0].fieldvalue;
                let colorClass = (names) ? names.charAt(0).toLowerCase() : "";
                this.setState({
                    firstname: names,
                    colorClass: colorClass,
                }, () => this.forceUpdate())
            }

            //if (data[0].image){
                this.setState({
                    companyImage :data[0].image,
                })
            //}

            //STRAT setting fieldsObj for update data with company Image
            
            this.setState({
                fieldsObj: {
                  websites: {
                    fieldid: "32",
                    values: [
                      { srno: "1", id: null, value: "" },
                    ]
                  },
                  social: {
                    fieldid: "35",
                    values: [
                      { srno: "1", id: null, value: "" },
                    ]
                  },
                  phones: {
                    fieldid: "30",
                    values: [
                      { srno: "1", id: null, value: "" },
                    ]
                  },
                },
                comp_value: '',
                city: '',
                state: '',
                zipcode: '',
              }, () => {
                let fieldsObj = this.state.fieldsObj;
                this.setState({ companyFieldData: props.companyReducer.companyFieldData.data })
                let country = "";
                const companyDataForEdit = props.companyReducer.companyFieldData.data;
                  

                var promise = new Promise((resolve, reject) => {
                  companyDataForEdit.map((v, i) => {
                    
                    
                    //if(!v.companydetail.length){return false}
                    if (v.fieldtype.id == '1' && ((v.fieldkey == "websites" || v.fieldkey == "social" || v.fieldkey == "phones") && v.is_default)) {
                      if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                        var data = {
                          fieldid: v.id,
                          values: v.companydetail[0].values
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }

                      }
                    } else if (v.fieldtype.id == '1' && v.id == '29') {//for first/last name      
                      
                      if (v.companydetail.length > 0) {
                        var data = {
                          fieldid: v.id,
                          companyvalue: v.companydetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                        // this.setState({
                        //   lastname: v.companydetail[0].values.lastname
                        // })
                      }
                    }
                    else if (v.fieldtype.id == '1' && v.id == '31') {//for owner field        
                      if (v.companydetail.length > 0 && v.companydetail[0].values.length && v.companydetail[0].values[0].id) {
                        var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id', v.companydetail[0].values[0].id);
                        if (companyOwnerKey !== -1) {
                          var ownerData = {
                            fieldid: v.id,
                            values: this.state.usersListArray[companyOwnerKey]
                          }
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: ownerData,
                          }
                        }
                      }
                    } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') {//for tag input
                      var tagArray = []
                      if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                        v.companydetail[0].values.map((v, i) => {
                          tagArray.push(v.value);
                        })
                      }
                      var data = {
                        fieldid: v.id,
                        values: tagArray
                      }
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: data,
                      }
                    } else if (v.fieldtype.id == '1' && v.id == '40') {//For value with currency Field
                      if (v.companydetail.length > 0) {
                        var data = {
                          fieldid: v.id,
                          companyvalue: v.companydetail[0].values[0].value
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    } else if (v.fieldtype.id == '1' || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
                      if (v.companydetail.length > 0 && v.companydetail.length > 0) {
                        var data = {
                          fieldid: v.id,
                          companyvalue: v.companydetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }

                    if (v.fieldtype.id == '2' && v.id == "36") {//Text aread with Address field
                      if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                        var data = {
                          fieldid: v.id,
                          companyvalue: v.companydetail[0].values[0].street
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }


                        this.setState({
                          city: v.companydetail[0].values[0].city,
                          state: v.companydetail[0].values[0].state,
                          zipcode: v.companydetail[0].values[0].zipcode,
                        })

                        if (v.companydetail[0].values[0].country !== "" && this.state.countryListArray.length > 0) {
                          var key = this.findWithAttr(this.state.countryListArray, 'name', v.companydetail[0].values[0].country);
                          country = this.state.countryListArray[key];
                        }
                      }
                    } else if (v.fieldtype.id == '2') {//TextArea
                      if (v.companydetail.length > 0) {
                        var data = {
                          fieldid: v.id,
                          companyvalue: v.companydetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }

                    if (v.fieldtype.id == "3") {//checkboxes
                      if (v.companydetail.length > 0) {
                        var data = {
                          fieldid: v.id,
                          companyvalue: v.companydetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }
                    //if(this.state.fieldsList && this.state.fieldsList.length >0){
                    if (v.fieldtype.id === '4') {//dropdown
                      //38-visibility 33- contact type
                      if (v.id === "38" || v.id === "33") {
                        if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                          var optId = this.findWithAttr(v.options, 'id', parseInt(v.companydetail[0].values[0].id));
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                          }
                        }
                      } else {
                        if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                          var optId = this.findWithAttr(v.options, 'id', v.companydetail[0].values[0].id);
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                          }
                        }
                      }
                    }
                    if (v.fieldtype.id === '5') {//multi dropdown
                      if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: { fieldid: v.id, values: v.companydetail[0].values },
                        }
                      }
                    }
                    if (v.fieldtype.id === '8') {//datepicker
                      if (v.companydetail.length > 0 && v.companydetail[0].fieldvalue != "") {
                        //var dt = moment.unix(v.companydetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                        var dt = new Date(moment(v.companydetail[0].fieldvalue).format('YYYY-MM-DD'))
                        dt = new Date(dt)
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: { fieldid: v.id, values: dt },
                        }
                      } else if (v.companydetail.length > 0 && v.companydetail[0].fieldvalue == "") {
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: { fieldid: v.id, values: null },
                        }
                      }
                    }
                    //}
                  })

                  this.setState({
                    fieldsObj,
                    country,
                    isUpdate: false,
                  }, () => {
                    resolve(true)
                  })
                });

                promise.then(result => {
                  props.companyReducer.companyFieldData.is_loaded = false;
                });
              })
            
            //END setting fieldsObj for update data with company Image
        }

        if (props.company_id !== undefined) {
            this.setState({
                company_id: props.company_id,
            }, () => this.forceUpdate());
        }

    }
    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    handleTaskDialogClose = () => {
      this.setState({ shouldOpenCreateTaskDialog: false, FullCreateTaskDialog: false, minimizeTaskDialog: false });
    };
    handleTaskDialogMinimize = () => {
      this.setState(oldStateminimizeTaskDialog => ({ minimizeTaskDialog: !oldStateminimizeTaskDialog.minimizeTaskDialog }));
      this.setState({ FullCreateTaskDialog: false })
    };
    handleTaskDialogFull = () => {
      this.setState(oldStateFullCreateTaskDialog => ({ FullCreateTaskDialog: !oldStateFullCreateTaskDialog.FullCreateTaskDialog }));
      this.setState({ minimizeTaskDialog: false })
    };

    //-----------log functions
    handleLogDialogClose = () => {
      this.setState({ shouldOpenCreateLogDialog: false, FullCreateLogDialog: false, minimizeLogDialog: false });
    };

    handleLogDialogMinimize = () => {
      this.setState(oldStateminimizeLogDialog => ({ minimizeLogDialog: !oldStateminimizeLogDialog.minimizeLogDialog }));
      this.setState({ FullCreateLogDialog: false })
    };

    handleLogDialogFull = () => {
      this.setState(oldStateFullCreateLogDialog => ({ FullCreateLogDialog: !oldStateFullCreateLogDialog.FullCreateLogDialog }));
      this.setState({ minimizeLogDialog: false })

    };
    ////----------------------
    handleDialogClose = () => {
        this.setState({ shouldOpenNoteDialog: false });
    };
    handleDeleteCompany = () => {
        this.toggleDeleteDialog()
    }

    toggleDeleteDialog = () => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
    };
    confirmDeleteCustomField = () => {
        let deleteCompanyParams = [];

        deleteCompanyParams['deleteid'] = { companyid: [parseFloat(this.state.company_id)] };
        deleteCompanyParams['listval'] = {
            filterdata: [],
            list: {
                limit: 10,
                pageno: 1,
                searchtext: "",
                sorttype: "",
                type: "list",
                fieldId: 0,
                columnname: ""
            },
            filterid: 1
        };

        this.toggleDeleteDialog();
        this.props.deleteCompanyRec(this.props.apolloClient.client, deleteCompanyParams);
        this.setState({ redirecttocompany: true });
    };
    handleFavorite = (company, status) => {
        let setFavoriteParams = [];

        let fav = !status;
        let star_i = fav ? 'star' : 'star_border';
        this.setState({ isfavorite: fav, star_icon: star_i });

        setFavoriteParams['favorite'] = { status: !status, companyid: company };
        setFavoriteParams['listval'] = {
            filterdata: [],
            list: {
                limit: 10,
                pageno: 1,
                searchtext: "",
                sorttype: "",
                type: "list",
                fieldId: 0,
                columnname: ""
            },
            filterid: 1
        };

        this.props.addFavouriteCompanyRec(this.props.apolloClient.client, setFavoriteParams);
    }

    handleCompanyImageChange = event => {
        var companyImage;
        if(event.target.files[0] == undefined){
            return false
        }

        companyImage = event.target.files[0]

        let logData = [];
        let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
        submitData = Object.values(submitData).map((row) => {
          if (row.fieldid == "32" || row.fieldid == "35" || row.fieldid == "30") { //website social phone email
            if (row.values.length == 1 && (row.values[0].id == null || row.values[0].value == "")) {
              row.values = [{ "fieldid": row.fieldid, value: [] }]
            }
            if (row.values.length > 1) {
              row.values.map((val, i) => {
                if (val.value == "") {
                  row.values.splice(i, 1);
                }
              })
            }

            if (row.fieldid == "30") {
              if (row.values.length === 1) {
                if (row.values[0].fieldid) {
                  row.values = []
                } else {
                  if (row.values.length > 0) {
                    var foundMobile = this.findWithAttr(row.values, 'id', "2");
                    if (foundMobile != -1) {
                      var index = 1;
                      row.values.map((val, i) => {
                        if (val.id == "2") {
                          row.values[i].srno = "1"
                        } else {
                          row.values[i].srno = (index + 1).toString()
                        }
                        index++;
                      })
                    }
                  }
                }
              } else {
                if (row.values.length > 0) {
                  var foundMobile = this.findWithAttr(row.values, 'id', "2");
                  if (foundMobile != -1) {
                    var index = 1;
                    row.values.map((val, i) => {
                      if (val.id == "2") {
                        row.values[i].srno = "1"
                      } else {
                        row.values[i].srno = (index + 1).toString()
                      }
                      index++;
                    })
                  }
                }
              }
            }
            if (row.fieldid == "32" || row.fieldid == "35" ) {//make value blank array if notfill in this field
              if (row.values.length === 1) {
                if (row.values[0].fieldid) {
                  row.values = []
                }
              }
            }
          }
          if (row.fieldid === "38" || row.fieldid === "33" ) {
            row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
          }
          var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
          if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
            row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
          }
          if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
            var ar = [];
            if (row.values.length > 0) {
              row.values.map((val, i) => {
                var optName;
                if (val.optionvalue) {
                  optName = val.optionvalue
                } else {
                  optName = val.value
                }
                ar.push({ srno: (i + 1).toString(), id: val.id, value: optName })
              })
            }
            row.values = ar
          }

          //validating date fiels
          if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
            if (row.values == null) {
              row.companyvalue = "";
              delete row.values;
            } else if (row.values == "Invalid Date") {
              return false
            } else {
              //row.companyvalue = moment(row.values).format("X");
              var dt = moment(row.values).format('YYYY-MM-DD')
              row.companyvalue = dt;
              delete row.values;
            }
          }

          if (row.fieldid == "39") {
            if (row.values.length > 0) {
              var objArr = [];
              row.values.map((val, k) => {
                var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
                var tagId = "0";
                if (foundKey !== -1) {
                  tagId = this.state.systemTagsList[foundKey].id
                }
                objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId, isdeleted: "0" })
              })

              //add tags from props value which is removed   with id deleted id "1"
              var tagsKeyFromProps = this.findWithAttr(this.props.companyReducer.companyFieldData.data, 'id', "39");
              if (this.props.companyReducer.companyFieldData.data[tagsKeyFromProps].companydetail.length > 0) {
                var oldTagsValues = this.props.companyReducer.companyFieldData.data[tagsKeyFromProps].companydetail[0].values;
                if (oldTagsValues.length > 0) {
                  oldTagsValues.map((v, k) => {
                    var checkKey = this.findWithAttr(objArr, 'value', v.value);
                    if (checkKey === -1) {
                      v.isdeleted = "1";
                      v.srno = (objArr.length + 1).toString();
                      objArr.push(v);
                    }
                  })
                }
              }

              row.values = objArr
              if (this.props.companyReducer.companyFieldData.data[tagsKeyFromProps].companydetail.length > 0) {
                this.props.companyReducer.companyFieldData.data[tagsKeyFromProps].companydetail[0].values = objArr;
              }
            }
          }

          if (row.fieldid == "36") {//address field
            row.companyvalue = {
              street: row.companyvalue,
              city: this.state.city,
              state: this.state.state,
              zipcode: this.state.zipcode,
              country: (this.state.country && this.state.country.name) ? this.state.country.name : ""
            }
          }
          if (row.fieldid == "31") {//owner value set
            if (row.values) {
              row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
            } else {
              row.values = [];
            }
            delete row.companyvalue;
          }
          return row;
        })

        //adding  companydetailid for update object from this.state.companyFieldData
        submitData = submitData.map((row, i) => {
          var key = this.findWithAttr(this.state.companyFieldData, 'id', row.fieldid);
          if (key !== -1) {
            if (this.state.companyFieldData[key].companydetail.length > 0) {
              row.companydetailid = this.state.companyFieldData[key].companydetail[0].id;
            } else {
              row.companydetailid = ""
            }
          }
          return row
        })

        //not to submit if any field is false
        var allowToSubmit = true
        submitData.map((row) => {
          if (!row) {
            allowToSubmit = false
          }
        })

        console.log("submitData", submitData);
        
        if (this.state.company_id && allowToSubmit) {
          let req = {
            companydata: submitData,
            companyid: this.state.company_id,
            companyImage: companyImage,
          }
          this.props.updateCompanyRecDetail(this.props.apolloClient.client, req);
        }
    }

    opeAddNewTaskPopup = () => {
        var data = {
          recordtype:"company",
          type:"tasks",
          id:parseInt(this.state.company_id),
        }
        this.setState({
            newTaskData:data,
        }, () => {
          this.setState({ 
            shouldOpenCreateTaskDialog: true        
          });
        })
    }

    render() {
        let { FullCreateTaskDialog, minimizeTaskDialog, shouldOpenCreateTaskDialog, shouldOpenNoteDialog, shouldOpenCreateLogDialog, redirecttocompany, colorClass } = this.state;
        return (
            (redirecttocompany) ?
                <Redirect to='/company/company-list' />
                :
                <aside className="sidenav">
                   <Card style={{boxShadow:'none'}}>
                        <div className="sidenav__user">
                            <div className="more_vert_menu">
                                <DropdownDefault
                                    menuButton={
                                        <IconButton
                                            aria-label="More"
                                            aria-haspopup="true"
                                        >
                                            <Icon>more_vert</Icon>
                                        </IconButton>
                                    }
                                >
                                    <MenuItem>Lookup LinkedIn</MenuItem>
                                    <MenuItem>Customize detail field order</MenuItem>
                                    <MenuItem onClick={()=>this.fileInput.click()}>Change profile image</MenuItem>
                                    <MenuItem onClick={this.handleDeleteCompany}>Delete</MenuItem>
                                </DropdownDefault>
                            </div>
                            <div className="username-photo white_space_nowrap">
                                <input style={{display:'none'}} accept="image/*" type="file" onChange={this.handleCompanyImageChange}
                                    ref={fileInput => this.fileInput = fileInput}
                                />
                                {!this.state.companyImage && <Avatar onClick={()=>this.fileInput.click()} className={"avatar_circle color_" + colorClass+ " custom-avatar-sidebar"}>{colorClass.toUpperCase()}</Avatar>}
                                {this.state.companyImage &&  <Avatar onClick={()=>this.fileInput.click()} className="custom-avatar-sidebar" alt="company image" src={this.state.companyImage} />}
                                <span className="username">
                                    {this.state.firstname} 
                                    <IconButtonWhite className="filled_star" size="small" onClick={(company, status) => this.handleFavorite(this.state.company_id, this.state.isfavorite)} >
                                        <IconSmall>{this.state.star_icon}</IconSmall>
                                    </IconButtonWhite>
                                </span>
                                <span className="user_designation">{this.state.title} {(this.state.companyName) && 'at ' + this.state.companyName} </span>
                                {!this.state.title && !this.state.companyName && <span className="user_designation">&nbsp;</span>}
                            </div>
                            <div className="user__menu">
                                <div className="text-center">
                                    <Fab
                                        size="small"
                                        color="primary"
                                        className="btn-primary"
                                        aria-label="Note"
                                        // onClick={() => this.setState({ shouldOpenNoteDialog: true })}
                                        onClick={() => this.setState({ logtype:5,  logvaluetype:'Note', shouldOpenCreateLogDialog: true })}
                                    >
                                        <Icon className="primary_color">library_books</Icon>
                                    </Fab>
                                    <div className="text-gray">Note</div>
                                </div>
                                <div className="text-center">
                                    <Fab
                                        size="small"
                                        color="primary"
                                        className="btn-primary"
                                        aria-label="Email"
                                    >
                                        <Icon className="primary_color">email</Icon>
                                    </Fab>
                                    <div className="text-gray">Email</div>
                                </div>
                                <div className="text-center">
                                    <Fab
                                        size="small"
                                        color="primary"
                                        className="btn-primary"
                                        aria-label="Log"
                                        onClick={() => this.setState({ logtype: 0, logvaluetype:'Phone Call', shouldOpenCreateLogDialog: true })}
                                    >
                                        <Icon className="primary_color">add_circle</Icon>
                                    </Fab>
                                    <div className="text-gray">Log</div>
                                </div>
                                <div className="text-center">
                                    <Fab
                                        size="small"
                                        color="primary"
                                        className="btn-primary"
                                        aria-label="Task"
                                        onClick={() => this.opeAddNewTaskPopup()}
                                    >
                                        <Icon className="primary_color">storage</Icon>
                                    </Fab>
                                    <div className="text-gray">Task</div>
                                </div>
                                <div className="text-center">
                                    <Fab
                                        size="small"
                                        color="primary"
                                        className="btn-primary"
                                        aria-label="Meet"
                                        onClick={() => this.setState({ logvaluetype:'Meeting', shouldOpenCreateLogDialog: true })}
                                    >
                                        <Icon className="primary_color">calendar_today</Icon>
                                    </Fab>
                                    <div className="text-gray">Meet</div>
                                </div>
                            </div>
                            {/* <Button className="btn_Primary" size="small">Convert Contact</Button> */}
                            {shouldOpenNoteDialog && (
                                <NoteDialog
                                    handleClose={this.handleDialogClose}
                                    open={shouldOpenNoteDialog}
                                />
                            )}

                            <Dialog
                                fullWidth={true}
                                maxWidth={'xs'}
                                open={this.state.isDeleteConfirmDialoOpen}
                                onClose={this.toggleDeleteDialog}
                                disableBackdropClick
                                disableEscapeKeyDown
                                aria-labelledby="ar-remove-dialog-title"
                            >
                                <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove this company ?"}</DialogTitle>
                                <DialogActions>
                                    <Button onClick={this.toggleDeleteDialog} color="primary">
                                        Cancel
                                        </Button>
                                    <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">
                                        Ok
                                        </Button>

                                </DialogActions>
                            </Dialog>
                        </div>
                    </Card>
                    {shouldOpenCreateLogDialog && (
                    <CreatelogDialog
                        handleClose={this.handleLogDialogClose}
                        open={shouldOpenCreateLogDialog}
                        handleMinimize={this.handleLogDialogMinimize}
                        handleFull={this.handleLogDialogFull}
                        minimizeScreen={this.state.minimizeLogDialog}
                        fullScreen={this.state.FullCreateLogDialog}
                        company_id={this.state.company_id}
                        logvaluetype={this.state.logvaluetype}
                        logtype={this.state.logtype}
                    />
                    )}

                    {shouldOpenCreateTaskDialog && (
                      <CreateNewTaskDialog
                        handleClose={this.handleTaskDialogClose}
                        open={shouldOpenCreateTaskDialog}
                        handleMinimize={this.handleTaskDialogMinimize}
                        handleFull={this.handleTaskDialogFull}
                        minimizeScreen={minimizeTaskDialog}
                        fullScreen={FullCreateTaskDialog}
                        allstates={this.state}
                        dataFromSidebar={this.state.newTaskData}
                      />
                    )}
                    <div className="ml-16 mr-0 mt-20">
                        <CompanyProfileDetails company_id={this.state.company_id} />
                    </div>
                </aside>
        );
    }
}

const mapStateToProps = state => {
    return {
        leadReducer: state.leadReducer,
        contactReducer: state.contactReducer,
        companyReducer: state.companyReducer,
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getContactType: (client) => dispatch(actions.getContactType(client)),
        deleteCompanyRec: (client, request) => { dispatch(actions.deleteCompanyRec(client, request)) },
        addFavouriteCompanyRec: (client, request) => { dispatch(actions.addFavouriteCompanyRec(client, request)) },
        //getLeadFilterList: (client, request) => { dispatch(actions.getLeadFilterList(client, request)) },

        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        getUserList: (client) => dispatch(actions.getUserList(client)),
        updateCompanyRecDetail: (client, request) => { dispatch(actions.updateCompanyRecDetail(client, request)) },
        getCountryList: (client) => dispatch(actions.getCountryList(client)),
        getCompanyCurrency: (client) => dispatch(actions.getCompanyCurrency(client)),
        getTagsList: (client) => dispatch(actions.getTagsList(client)),
        getUserSetting: (client, data) => dispatch(actions.getUserSetting(client, data)),        
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyProfileLeft);