import React, { Component } from "react";
import OpportunityTableComponent from './OpportunityTableComponent';
import OpportunityCreationRule from './OpportunityCreationRule'
import { Icon, Switch, FormControlLabel, IconButton, withStyles, Button, Menu, MenuItem, Select } from "@material-ui/core";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'
import CreateNewFieldOpportunitiesDialog from "./CreateNewFieldOpportunitiesDialog";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class SettingOpportunities extends Component {

    constructor(props) {
        super(props);

        this.state = {
            opportunitydata: [],
            opportunityFieldIds: [],
            opportunityOrderIds: [],
            activeopportunitydatas: [],
            inactiveopportunitydatas: [],
            shouldOpenCreateFieldOpportunitydDialog: false,
            selectedpipeline: 1,
            pipelines:[],
        };
    }
   
    handleChange = (event,pipelineids) => {

        if(pipelineids.indexOf(parseFloat(this.state.selectedpipeline)) == -1){
            if (event.target.checked){
                pipelineids.push(parseFloat(this.state.selectedpipeline))
            } 
            else{
                let key1 = pipelineids.indexOf(0);
                pipelineids.splice(key1, 1);                
            }
        }
        else{
            if (event.target.checked == false) {
                let key = pipelineids.indexOf(parseFloat(this.state.selectedpipeline));
                pipelineids.splice(key, 1);
            }
        }
        let val = {
            typeId: 4,
            fieldstatus: event.target.checked,
            generatedfieldid: event.target.value,
            pipelinesid:pipelineids
        }
        // console.log(val,'valvalval');
        
        this.props.updateCustomFieldStatus(this.props.apolloClient.client, val);
    }

    componentDidMount() {
        let val = { typeId: 4 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.customFieldTypeList(this.props.apolloClient.client);
        this.props.getPipelines(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.pipelineListArray.data) {
            this.setState({ pipelines: props.settingReducer.pipelineListArray.data });
        }
        
        
        if (props.settingReducer.opportunitiesCustomFieldList.data && props.settingReducer.customFieldTypeList.data) {
            const customFields = props.settingReducer.opportunitiesCustomFieldList.data;
            
            let activeopportunitydatas = [];
            let inactiveopportunitydatas = [];
            let opportunityFieldIds = [];
            let opportunityOrderIds = [];

            customFields.map((fieldData, index) => {
                if (fieldData.piplineid != null)
                {
                    if (fieldData.fieldstatus === true) {
                        opportunityFieldIds.push(parseFloat(fieldData.id));
                        opportunityOrderIds.push(parseFloat(fieldData.orderno));

                        let fieldlicon = (fieldData.fieldicon === 'fa-dollar-sign') ? 'fa-usd' : fieldData.fieldicon;
                        
                        if ((fieldData.piplineid && fieldData.piplineid.length > 0) && fieldData.piplineid.indexOf(parseFloat(this.state.selectedpipeline)) != -1 || fieldData.piplineid.indexOf(0) != -1) {
                            activeopportunitydatas.push({
                                drag: <Link className="drag-handle" opportunityOrderIds={opportunityOrderIds} to="#"><Icon>drag_handle</Icon></Link>,
                                key: fieldData.id,
                                name: (
                                    <div>
                                        <FontAwesome className={'crm-fontawsome-icon ' + fieldlicon} ></FontAwesome>
                                        <label className="crm-custom-field-label">{fieldData.fieldlabel}</label>
                                    </div>
                                ),
                                fieldType: fieldData.fieldtype.name,
                                creationRule: (fieldData.recordcreationrules_editable) ?
                                    <OpportunityCreationRule dropName="create" fieldId={index} options={fieldData.recordcreationrulesvalue} disabled={false} defaultChecked={fieldData.recordcreationdefaultvalue} />
                                    :
                                    <OpportunityCreationRule dropName="create" fieldId={index} options={fieldData.recordcreationrulesvalue} disabled={true} defaultChecked={fieldData.recordcreationdefaultvalue} />,
                                editRule: (fieldData.recordeditrules_editable) ?
                                    <OpportunityCreationRule dropName="edit" fieldId={index} options={fieldData.recordeditrulesvalue} disabled={false} defaultChecked={fieldData.recordeditdefaultvalue} />
                                    :
                                    <OpportunityCreationRule dropName="edit" fieldId={index} options={fieldData.recordeditrulesvalue} disabled={true} defaultChecked={fieldData.recordeditdefaultvalue} />,
                                action: <React.Fragment>

                                    <FormControlLabel
                                        value={fieldData.generatedfieldid}
                                        control={(fieldData.fieldstatus) ? <Switch onChange={(event, pipelineids) => this.handleChange(event, fieldData.piplineid)} color="primary" defaultChecked disabled={!fieldData.isdiactivated} /> : <Switch onChange={(event, pipelineids) => this.handleChange(event, fieldData.piplineid)} color="primary" disabled={!fieldData.isdiactivated} />}
                                        labelPlacement="start"
                                        className="ml-0 mr-10"
                                    />
                                </React.Fragment>
                            })
                        }
                    }

                    if (fieldData.fieldstatus === false) {
                        if ((fieldData.piplineid ) && fieldData.piplineid.indexOf(parseFloat(this.state.selectedpipeline)) != -1 || fieldData.piplineid.indexOf(0) != -1) {
                            inactiveopportunitydatas.push({
                                key: fieldData.id,
                                name: fieldData.fieldlabel,
                                action: <React.Fragment>
                                    <FormControlLabel
                                        value={fieldData.generatedfieldid}
                                        control={(fieldData.fieldstatus) ? <Switch onChange={(event, pipelineids) => this.handleChange(event, fieldData.piplineid)} color="primary" defaultChecked disabled={!fieldData.isdiactivated} /> : <Switch onChange={(event, pipelineids) => this.handleChange(event, fieldData.piplineid)} color="primary" disabled={!fieldData.isdiactivated} />}
                                        labelPlacement="start"
                                        className="ml-0 mr-10"
                                    />
                                </React.Fragment>
                            })
                        }
                    }
                    if ((fieldData.piplineid) && fieldData.piplineid.indexOf(parseFloat(this.state.selectedpipeline)) == -1 && fieldData.piplineid.indexOf(0) == -1) {
                        inactiveopportunitydatas.push({
                            key: fieldData.id,
                            name: fieldData.fieldlabel,
                            action: <React.Fragment>
                                <FormControlLabel
                                    value={fieldData.generatedfieldid}
                                    control={<Switch onChange={(event, pipelineids) => this.handleChange(event, fieldData.piplineid)} color="primary" disabled={!fieldData.isdiactivated} />}
                                    labelPlacement="start"
                                    className="ml-0 mr-10"
                                />
                            </React.Fragment>
                        })
                    }
                }
            })
            this.setState({ activeopportunitydatas: activeopportunitydatas, inactiveopportunitydatas: inactiveopportunitydatas, opportunityFieldIds: opportunityFieldIds, opportunityOrderIds: opportunityOrderIds }, () => this.forceUpdate());
        }
    }
    handleChangePipeline = (event) => {
        
        this.setState({ selectedpipeline:event.target.value});
        let val = { typeId: 4 }
        this.props.customFieldList(this.props.apolloClient.client, val);

    }
    closePopUp = () => {
        this.setState({ shouldOpenCreateFieldOpportunitydDialog: false });
    }
    updateFieldsOrder = (fromIndex, toIndex) => {

        let opportunityFieldIds = [];

        const opportunitydata = this.state.activeopportunitydatas;
        const item = opportunitydata.splice(fromIndex, 1)[0];

        opportunitydata.splice(toIndex, 0, item);

        this.setState({
            opportunitydata
        })
        const opportunityOrderIds = this.state.opportunityOrderIds;
        opportunitydata.map((value, index) => {
            opportunityFieldIds.push(parseFloat(value.key));
        });

        let val1 = {
            typeId: 4,
            orderno: opportunityOrderIds,
            fieldid: opportunityFieldIds
        }
        this.props.updateCustomFieldOrder(this.props.apolloClient.client, val1);
    }
    handleOpportunityDialogClose = () => {
        this.setState({ shouldOpenCreateFieldOpportunitydDialog: false });
    };
    render() {
        let { shouldOpenCreateFieldOpportunitydDialog, pipelines, selectedpipeline  } = this.state;
        
        return (
            <>
                <Select
                    name={"pipelines"}
                    id={"pipelines"}
                    value={selectedpipeline}
                    disableUnderline
                    onChange={this.handleChangePipeline}
                >
                    {pipelines && pipelines.map((item, key) =>
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    )}
                </Select>
                <div className="d-flex justify-content-end mb-16">
                    <Button variant="contained" color="primary" onClick={() => this.setState({ shouldOpenCreateFieldOpportunitydDialog: true })} size="small">Add field</Button>
                    {shouldOpenCreateFieldOpportunitydDialog && (
                        <CreateNewFieldOpportunitiesDialog
                            handleClose={this.handleOpportunityDialogClose}
                            open={shouldOpenCreateFieldOpportunitydDialog}
                            allData={this}
                        />
                    )}
                </div>
                
                <OpportunityTableComponent activeopportunitydata={this.state.activeopportunitydatas} inActiveopportunitydata={this.state.inactiveopportunitydatas} allData={this} />
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        customFieldTypeList: (client) => { dispatch(actions.customFieldTypeList(client)) },
        updateCustomFieldStatus: (client, request) => { dispatch(actions.updateCustomFieldStatus(client, request)) },
        updateCustomFieldOrder: (client, request) => { dispatch(actions.updateCustomFieldOrder(client, request)) },
        getPipelines: (client) => dispatch(actions.getPipelines(client)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SettingOpportunities);
