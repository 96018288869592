import React, { Component } from "react";
import { Grid, Card } from "@material-ui/core";
import LeadTab from "./LeadTab";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import moment from 'moment';//moment
import 'moment-timezone';//timezone


class ProfileCenter extends Component {
  state = {
    lead_id: "",
    interaction:0,
    lastcontacted: "--",
    leadstatus: "",
    inactivedays: 0,
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
  }

  componentDidMount(){

    this.setState({
      lead_id: parseInt(this.props.lead_id)
    })

    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userprefparams = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userprefparams);
  }

  componentWillReceiveProps(props){
    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;

      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.leadReducer.leadFieldData && props.leadReducer.leadFieldData.data)
    {
      
      var fielddata = props.leadReducer.leadFieldData.data;
      if (fielddata[0].leaddetail && fielddata[0].leaddetail.length > 0)
      {
        let interaction = fielddata[0].leaddetail[0].lead.interactions ? fielddata[0].leaddetail[0].lead.interactions : 0
        let lastcontacted = fielddata[0].leaddetail[0].lead.lastcontacted ? moment.unix(fielddata[0].leaddetail[0].lead.lastcontacted).tz(this.state.timeZone).format(this.state.dateformat) : '--'
        let inactivedays = fielddata[0].leaddetail[0].lead.inactivedays ? fielddata[0].leaddetail[0].lead.inactivedays : 0

        this.setState({ interaction: interaction, lastcontacted: lastcontacted, inactivedays: inactivedays })
       
        fielddata.map((field) => {
          if (field.fieldkey == "status" && field.leaddetail && field.leaddetail.length > 0) {
            (field.leaddetail) && this.setState({ leadstatus: field.leaddetail[0].values[0].value })
          }
        });
      }
      
    }
  }


  render() {  
    return (
      <div className="lead_content">
        <Grid container spacing={2} className="text-center">
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.interaction}</span>
              <p className="m-0 text-gray">Interactions</p>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.lastcontacted}</span>
              <p className="m-0 text-gray">Last contacted</p>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.leadstatus}</span>
              <p className="m-0 text-gray">Lead status</p>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.inactivedays}</span>
              <p className="m-0 text-gray">Inactive days</p>
            </Card>
          </Grid>
        </Grid>
        <LeadTab thisData={this} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    leadReducer: state.leadReducer,
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
  };
};
const mapDispatchToProps = dispatch => {
  return {
    //getLeadLogList: (client, leadid) => dispatch(actions.getLeadLogList(client, leadid)),
    getUserSetting: (client, data) => dispatch(actions.getUserSetting(client, data)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProfileCenter);