import React, { Component } from "react";
import { Checkbox } from "@material-ui/core";
import { Table } from 'antd';

class CommentsNotifications extends Component {
    state = {
        selection: [],
        checkbox_comments_inapp1: true,
        checkbox_comments_inapp2: true,
    };
    handleCheckBoxChange = (event, name) => {
        event.persist();
        this.setState({ [name]: event.target.checked });
    };
    render() {
        const comments_rows = [];
        const comments_columns = [

            {
                title: "",
                dataIndex: "name",
                className: "align_left",
                width: 300
            },
            {
                title: "In App",
                dataIndex: "inapp",
                // className: "align_left"
            },
            {
                title: "",
                dataIndex: "email",
                // className: "align_left"
            },
            {
                title: "",
                dataIndex: "mobile",
                // className: "align_left"
            },
            {
                title: "",
                dataIndex: "action",                
            }
        ];
        
        comments_rows.push (
            {
                name: (
                    <div className="ml-10">                           
                        <label className="crm-custom-field-label font-weight-500">Comments on an activity I created</label>
                    </div>
                ),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_comments_inapp1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_comments_inapp1')}
                        value="checkbox_comments_inapp1"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Reactions on an activity I created</label>
                    </div>
                ),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_comments_inapp2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_comments_inapp2')}
                        value="checkbox_comments_inapp2"
                        color="primary"
                    />
                </React.Fragment>),
                
            },
        )


        return (
            <React.Fragment >
                <div className="table_width antd-tables">
                    <Table
                        bordered={false}
                        columns={comments_columns}
                        pagination={false}
                        dataSource={comments_rows}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default CommentsNotifications;
