import React, { Component } from "react";
import { IconButton, Icon, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar } from "@material-ui/core";
import DropdownDefault from "../Layout/DropdownDefault";
import NoteDialog from "./NoteDialog";
import ProfileDetails from "./ProfileDetails";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Redirect, Route } from 'react-router-dom'
import moment from 'moment';
import CreatelogDialog from "./CreatelogDialog";
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";

const IconButtonWhite = withStyles(theme => ({
    root: {
        // color: theme.palette.getContrastText(purple[500]),
        backgroundColor: "transparent",
        padding: "5px"
    }
}))(IconButton);

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
    }
}))(Icon);
class ProfileLeft extends Component {
    state = {
      shouldOpenCreateLogDialog: false,
      FullCreateLogDialog: false,
      minimizeLogDialog: false,
      isDeleteLogConfirmDialoOpen: false,
      logvaluetype:'',
      logtype:0,
      shouldOpenNoteDialog: false,
      firstname:'',
      lastname:'',
      title:'',
      companyName:'',
      contactTypes:[],
      isDeleteConfirmDialoOpen:false,
      project_id:'',
      redirecttolead:false,
      colorClass:"",
      isfavorite: false,
      star_icon : 'star_border',
      fieldsObj: {},
      fieldsList: [],
      usersListArray:[],
      leadImage:"",
      shouldOpenConvertLeadDialog: false,
      minimizeConvertLeadDialog: false,
      FullConvertLeadDialog:false,
      countryListArray:[],
      systemTagsList: [],
      companyCurrencyData: [],
      userSettings:"",
      relatedListArray: [],

      shouldOpenCreateTaskDialog: false,
      FullCreateTaskDialog: false,
      minimizeTaskDialog: false,
      newTaskData:{},
    };

    componentWillMount() {
        if(this.props.project_id){
            this.setState({
                project_id:this.props.project_id,
            });
        }

        //this.props.getContactType(this.props.apolloClient.client);

        this.props.getRelatedList(this.props.apolloClient.client);
        let val = { typeId: 5 }
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let userid = { userid: userData.id }
        this.props.getUserSetting(this.props.apolloClient.client, userid);
        this.props.customFieldList(this.props.apolloClient.client, val);
        //this.props.getCountryList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getCompanyCurrency(this.props.apolloClient.client);
        this.props.getTagsList(this.props.apolloClient.client);

    }
    componentWillReceiveProps(props)
    {
        if (props.settingReducer.relatedListArray.data) {
          props.settingReducer.relatedListArray.is_loaded = false
          const relatedData = props.settingReducer.relatedListArray.data;
          var NewArray = []
          NewArray = NewArray.concat(relatedData.lead); 
          NewArray = NewArray.concat(relatedData.contact); 
          NewArray = NewArray.concat(relatedData.company); 
          NewArray = NewArray.concat(relatedData.opportunities); 
          this.setState({ relatedListArray: NewArray})
        }
        //storing user setting data in state
        if(props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
            const settingData = props.settingReducer.userSettingArray.data;
            this.setState({
                userSettings:settingData,
            })
        }
        if (props.settingReducer.companyCurrencyData.data != null && this.state.companyCurrencyData.length == 0) {
          var cData = props.settingReducer.companyCurrencyData.data;
          this.setState({ companyCurrencyData: cData[0] });
        }
        if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
          const tagsData = props.settingReducer.systemTagsList.data;
          this.setState({ systemTagsList: tagsData })
        }
        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
          const usersListArray = props.settingReducer.usersListArray.data;
          this.setState({ usersListArray: usersListArray })
        }
        if (props.settingReducer.projectCustomFieldList.data && this.state.fieldsList.length == 0) {
          const customFields = props.settingReducer.projectCustomFieldList.data;
          this.setState({ fieldsList: customFields })
          var statuskey = this.findWithAttr(customFields, 'id', "70");
          var defaultKeyStatus = this.findWithAttr(customFields[statuskey].options, 'is_default', true);
          this.setState({
            fieldsObj: {
              ...this.state.fieldsObj,
              ["status"]: { fieldid: "70", values: customFields[statuskey].options[defaultKeyStatus] }
            }
          })
        }

        if (props.projectReducer.projectFieldData && props.projectReducer.projectFieldData.data != null) {
            var data = props.projectReducer.projectFieldData.data;
          
            
            let star_ic = data[0].leadfavourite ? 'star' : 'star_border';
            this.setState({ star_icon: star_ic, isfavorite: data[0].leadfavourite });

            var companyKey = this.findWithAttr(data, 'id', "69");
            // if (companyKey != -1 && data[companyKey].projectdetail.length > 0 && data[companyKey].projectdetail[0].fieldvalue) { // change for update company even it's value is empty
            if (companyKey != -1 && data[companyKey].projectdetail.length > 0 ) {
                this.setState({
                    companyName: data[companyKey].projectdetail[0].fieldvalue ? data[companyKey].projectdetail[0].fieldvalue : "",
                }, () => this.forceUpdate())
            }
            
            var nameKey = this.findWithAttr(data, 'id', "67");
            if (nameKey != -1 && data[nameKey].projectdetail.length > 0 && data[nameKey].projectdetail[0].values) {
                var names = data[nameKey].projectdetail[0].fieldvalue;
                let colorClass = (names) ? names.charAt(0).toLowerCase() : "";
                this.setState({
                  firstname: names,
                  colorClass: colorClass,
                }, () => this.forceUpdate())
              }

            if (data[0].projectdetail && data[0].projectdetail.length > 0 && data[0].projectdetail[0].lead){
                this.setState({
                    leadImage:data[0].image,
                })
            }

            //STRAT setting fieldsObj for update data with lead Image
            this.setState({
            fieldsObj: {
              
            },
          },() => {
              let fieldsObj = this.state.fieldsObj;
              this.setState({projectFieldData: props.projectReducer.projectFieldData.data})
              let country = "";
              const projectDataForEdit =  props.projectReducer.projectFieldData.data;
              
              var promise = new Promise((resolve, reject) => {
                projectDataForEdit.map((v, i) => {
                    if (v.fieldtype.id == '1' && v.id == '1') {//for first/last name          
                      if(v.projectdetail.length > 0){
                        var data = {
                          fieldid: v.id,
                          projectvalue: v.projectdetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                        
                      }
                    }
                    else if (v.fieldtype.id == '1' && v.id == '69') {//for owner field        
                      if(v.projectdetail.length > 0 && v.projectdetail[0].values.length && v.projectdetail[0].values[0].id){
                        var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id',v.projectdetail[0].values[0].id);
                        if(companyOwnerKey !== -1){
                          var ownerData = {
                            fieldid: v.id,
                            values: this.state.usersListArray[companyOwnerKey]
                          }
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: ownerData,
                          }
                        }
                      }
                    }else if (v.fieldtype.id == '1' && v.fieldkey=='tags') {//for tag input
                      var tagArray = []
                      if(v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0){
                        v.projectdetail[0].values.map((v,i)=>{
                          tagArray.push(v.value);
                        })
                      }
                      var data = {
                        fieldid: v.id,
                        values: tagArray
                      }
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: data,
                      }
                    } else if (v.fieldtype.id == '1' && v.id == '68') {//related field
                      if(v.projectdetail.length > 0 && v.projectdetail[0].values.length && v.projectdetail[0].values[0].id){
                        var relatedKey = this.findWithAttr(this.state.relatedListArray, 'id',v.projectdetail[0].values[0].id);
                        if(companyOwnerKey !== -1){
                          var relatedData = {
                            fieldid: v.id,
                            values: this.state.relatedListArray[relatedKey]
                          }
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: relatedData,
                          }
                        }
                      }
                    }else if (v.fieldtype.id == '1' || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
                      if(v.projectdetail.length > 0 && v.projectdetail.length > 0){
                        var data = {
                          fieldid: v.id,
                          projectvalue: v.projectdetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }

                    if (v.fieldtype.id == '2') {//TextArea
                      if(v.projectdetail.length > 0){
                        var data = {
                          fieldid: v.id,
                          projectvalue: v.projectdetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }

                    if(v.fieldtype.id == "3"){//checkboxes
                      if(v.projectdetail.length > 0){
                        var data = {
                          fieldid: v.id,
                          projectvalue: v.projectdetail[0].fieldvalue
                        }
                        fieldsObj = {
                          ...fieldsObj,
                          [v.fieldkey]: data,
                        }
                      }
                    }
                    //if(this.state.fieldsList && this.state.fieldsList.length >0){
                      if (v.fieldtype.id === '4') {//dropdown
                        if(v.id === "70" || v.id === "72" || v.id === "75"){
                          if(v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0){
                            var optId = this.findWithAttr(v.options, 'id',v.projectdetail[0].values[0].id);
                            fieldsObj = {
                              ...fieldsObj,
                              [v.fieldkey]: {fieldid: v.id,values:v.options[optId]},
                            }
                          }
                        }else{
                          if(v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0){
                            var optId = this.findWithAttr(v.options, 'id',v.projectdetail[0].values[0].id);
                            fieldsObj = {
                              ...fieldsObj,
                              [v.fieldkey]: {fieldid: v.id,values:v.options[optId]},
                            }
                          }
                        }
                      }
                      if (v.fieldtype.id === '5') {//multi dropdown
                        if(v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0){
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: {fieldid: v.id,values:v.projectdetail[0].values},
                          }
                        }
                      }
                      if (v.fieldtype.id === '8') {//datepicker
                        if(v.projectdetail.length > 0 && v.projectdetail[0].fieldvalue != ""){
                          //var dt = moment.unix(v.projectdetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                          var dt = new Date(moment(v.projectdetail[0].fieldvalue).format('YYYY-MM-DD'))
                          dt = new Date(dt)
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: {fieldid: v.id,values:dt},
                          }
                        }else if(v.projectdetail.length > 0 && v.projectdetail[0].fieldvalue == ""){
                          fieldsObj = {
                            ...fieldsObj,
                            [v.fieldkey]: {fieldid: v.id,values:null},
                          }
                        }
                      }
                    //}
              })

                  this.setState({
                    fieldsObj,
                    country,
                    isUpdate:false,
                  },() => {
                    resolve(true)     
                  })
              });

              promise.then( result => {
                props.projectReducer.projectFieldData.is_loaded = false;
              });
          })
          //END setting fieldsObj for update data with lead Image
           
            
        }

        if (props.project_id !== undefined) {
            this.setState({
                project_id: props.project_id,
            }, () => this.forceUpdate());
        }
        
    }

    handleTaskDialogClose = () => {
        this.setState({ shouldOpenCreateTaskDialog: false, FullCreateTaskDialog: false, minimizeTaskDialog: false });
    };

    handleTaskDialogMinimize = () => {
        this.setState(oldStateminimizeTaskDialog => ({ minimizeTaskDialog: !oldStateminimizeTaskDialog.minimizeTaskDialog }));
        this.setState({ FullCreateTaskDialog: false })
    };

    handleTaskDialogFull = () => {
        this.setState(oldStateFullCreateTaskDialog => ({ FullCreateTaskDialog: !oldStateFullCreateTaskDialog.FullCreateTaskDialog }));
        this.setState({ minimizeTaskDialog: false })
    };

    //-----------log functions
    handleLogDialogClose = () => {
      this.setState({ shouldOpenCreateLogDialog: false, FullCreateLogDialog: false, minimizeLogDialog: false });
    };

    handleLogDialogMinimize = () => {
      this.setState(oldStateminimizeLogDialog => ({ minimizeLogDialog: !oldStateminimizeLogDialog.minimizeLogDialog }));
      this.setState({ FullCreateLogDialog: false })
    };

    handleLogDialogFull = () => {
      this.setState(oldStateFullCreateLogDialog => ({ FullCreateLogDialog: !oldStateFullCreateLogDialog.FullCreateLogDialog }));
      this.setState({ minimizeLogDialog: false })

    };
    ////----------------------

    handleLeadDialogClose = () => {
        this.setState({ shouldOpenCreateLeadDialog: false });
    };

    handleLeadDialogMinimize = () => {
      this.setState(oldStateminimizeLeadDialog => ({ minimizeConvertLeadDialog: !oldStateminimizeLeadDialog.minimizeConvertLeadDialog }));
        this.setState({ FullCreateLeadDialog: false })
    };

    handleLeadDialogFull = () => {
      this.setState(oldStateFullCreateLeadDialog => ({ FullConvertLeadDialog: !oldStateFullCreateLeadDialog.FullConvertLeadDialog }));
        this.setState({ minimizeLeadDialog: false })
    };
    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    } 
    handleDialogClose = () => {
        this.setState({ shouldOpenNoteDialog: false });
    };
    handleDeleteLead = () => {
        this.toggleDeleteDialog()
    }

    toggleDeleteDialog = () => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
    };
    confirmDeleteCustomField = () => {
        let deleteLeadParams = [];

        deleteLeadParams['deleteid'] = { leadid: [parseFloat(this.state.project_id)] };
        deleteLeadParams['listval'] = {
            filterdata: [],
            list: {
                limit: 10,
                pageno: 1,
                searchtext: "",
                sorttype: "",
                type: "list",
                fieldId: 0,
                columnname: ""
            },
            filterid: 1 
        };
        
        this.toggleDeleteDialog();
        this.props.deleteLead(this.props.apolloClient.client, deleteLeadParams); 
        this.setState({ redirecttolead: true });
    };

    handleFavorite = (project_id, status) => {
        let setFavoriteParams = [];

        let fav = !status;
        let star_i = fav ? 'star' : 'star_border';
        this.setState({ isfavorite: fav, star_icon: star_i });

        setFavoriteParams['favorite'] = { status: !status, projectid: project_id };
        setFavoriteParams['listval'] = {
          filterdata: [],
          list: {
            limit: 10,
            pageno: 1,
            searchtext: "",
            sorttype: "",
            type: "list",
            fieldId: 0,
            columnname: ""
          },
          filterid: 1
        };

        this.props.addFavouriteProject(this.props.apolloClient.client, setFavoriteParams);
      }
    handleLeadProfileImage = event => {
        var leadFile;
        if(event.target.files[0] == undefined){
            return false
        }

        leadFile = event.target.files[0]
        
        
        let logData = [];
        let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
        submitData = Object.values(submitData).map((row)=>{
          if (row.fieldid == "68") {//realated  field
            if(row.values == null){
              return false
            }
            row.values = [{ srno: "1", id: row.values.id, value: row.values.name,type: row.values.type }]
          }
          if(row.fieldid === "72" || row.fieldid === "70" || row.fieldid === "75"){
            row.values = [{srno:"1", id:row.values.id,value:row.values.name}]
          }
          var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
          if(this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false){
            row.values = [{srno:"1",id:row.values.id,value:row.values.optionvalue}]
          }
          if(this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false){
            var ar = [];
            if(row.values.length >0){
              row.values.map((val,i)=>{
                var optName;
                if(val.optionvalue){
                  optName = val.optionvalue
                }else{
                  optName = val.value
                }
                ar.push({srno:(i+1).toString(),id:val.id, value:optName})
              })
            }
            row.values = ar
          }

          //validating date fiels
          if(this.state.fieldsList[key].fieldtype.id == "8"){//date field
            if(row.values == null){
              row.projectvalue = "";
              delete row.values;
            }else if(row.values == "Invalid Date"){
              return false
            }else{
              // row.projectvalue = moment(row.values).format("X");
              // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
              // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
              // row.projectvalue = moment.utc(logdate).format('X');
              var dt = moment(row.values).format('YYYY-MM-DD')
              row.projectvalue = dt;
              delete row.values;
            }
          }

          if(row.fieldid == "74"){
            if(row.values.length > 0){
              var objArr = [];
              row.values.map((val,k)=>{
                var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
                var tagId = "0";
                if(foundKey !== -1){
                  tagId = this.state.systemTagsList[foundKey].id
                }
                objArr.push({"srno":(k+1).toString(),value:val.toLowerCase(),id:tagId,isdeleted:"0"})
              })

              //add tags from props value which is removed   with id deleted id "1"
              var tagsKeyFromProps = this.findWithAttr(this.props.projectReducer.projectFieldData.data, 'id', "74");
              if(this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail.length>0){
                var oldTagsValues = this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail[0].values;
                if(oldTagsValues.length > 0){
                  oldTagsValues.map((v,k)=>{
                    var checkKey = this.findWithAttr(objArr, 'value', v.value);
                    if(checkKey === -1){
                      v.isdeleted = "1";
                      v.srno = (objArr.length + 1).toString();
                      objArr.push(v);
                    }
                  })
                }
              }

              row.values = objArr
              if(this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail.length>0){
                this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail[0].values = objArr;  
              }
            }
          }

          if(row.fieldid == "69"){//owner value set
           if(row.values){
              row.values = [{"srno":"1",id:row.values.id,value:row.values.email,name:row.values.name}];
            }else{
              row.values = [];
            }
            delete row.projectvalue;
          }
          return row;
        })

        //adding  projectdetailid for update object from this.state.projectFieldData
        submitData = submitData.map((row,i)=>{
          var key = this.findWithAttr(this.state.projectFieldData, 'id', row.fieldid);
          if(key !== -1){
            if(this.state.projectFieldDataproject.length > 0){
              row.projectdetailid = this.state.projectFieldData[key].projectdetail[0].id;
            }else{
              row.projectdetailid = ""
            }
          }


          return row
        })

        //filter null and undefined object 
        submitData = submitData.filter(function(element) {
           return element !== undefined;
        });
        
        //not to submit if any field is false
        var allowToSubmit = true
        submitData.map((row)=>{
          if(!row){
            allowToSubmit = false
          }
        })
        // console.log("submitData", submitData);
        //return
        if(this.state.project_id && allowToSubmit){
          let req = {
            projectdata:submitData,
            projectid:this.state.project_id
          }
          this.props.updateProjectDetail(this.props.apolloClient.client,req);
        }
    };
    openConvertLeadDialog = () => {
        this.setState({ shouldOpenConvertLeadDialog: true });
      };
    handleConvertLeadDialogClose = () => {
      this.setState({ shouldOpenConvertLeadDialog: false });
    };
    opeAddNewTaskPopup = () => {
        var data = {
          recordtype:"project",
          type:"tasks",
          id:parseInt(this.state.project_id),
        }
        this.setState({
            newTaskData:data,
        }, () => {
          this.setState({ 
            shouldOpenCreateTaskDialog: true        
          });
        })
    }

    render() {
      let { FullCreateTaskDialog, minimizeTaskDialog, shouldOpenCreateTaskDialog, shouldOpenConvertLeadDialog, shouldOpenCreateLogDialog, shouldOpenNoteDialog, redirecttolead, colorClass } = this.state;

        return (
            (redirecttolead) ?
                <Redirect to='/lead/leads-list' />
            :
            <aside className="sidenav">
                <div className="sidenav__user">
                    <div className="more_vert_menu">
                        <DropdownDefault
                            menuButton={
                                <IconButton
                                    aria-label="More"
                                    aria-haspopup="true"
                                >
                                    <Icon>more_vert</Icon>
                                </IconButton>
                            }
                        >
                            <MenuItem>Lookup LinkedIn</MenuItem>
                            <MenuItem>Customize detail field order</MenuItem>
                            <MenuItem onClick={()=>this.fileInput.click()} >Change profile image</MenuItem>
                            <MenuItem onClick={this.handleDeleteLead}>Delete</MenuItem>
                        </DropdownDefault>
                    </div>
                    <div className="username-photo white_space_nowrap">
                        <input style={{display:'none'}} accept="image/*" type="file" onChange={this.handleLeadProfileImage}
                                    ref={fileInput => this.fileInput = fileInput}
                        />
                        {!this.state.leadImage && <Avatar onClick={()=>this.fileInput.click()} className={"avatar_circle color_" + colorClass+ " custom-avatar-sidebar"}>{colorClass.toUpperCase()}</Avatar>}
                        {this.state.leadImage &&  <Avatar onClick={()=>this.fileInput.click()} className="custom-avatar-sidebar" alt="Lead image" src={this.state.leadImage} />}
                        <span className="username">
                            {this.state.firstname} {this.state.lastname}
                                <IconButtonWhite className="filled_star" size="small" onClick={(lead, status) => this.handleFavorite(this.state.project_id, this.state.isfavorite)}>
                                <IconSmall>{this.state.star_icon}</IconSmall>
                            </IconButtonWhite>
                        </span>
                        <span className="user_designation">{this.state.title} {(this.state.companyName) && 'at ' + this.state.companyName} </span>
                      {!this.state.title && !this.state.companyName && <span className="user_designation">&nbsp;</span>}
                    </div>
                    <div className="user__menu">
                        <div className="text-center">
                            <Fab
                                size="small"
                                color="primary"
                                className="btn-primary"
                                aria-label="Note"
                                // onClick={() => this.setState({ shouldOpenNoteDialog: true })}
                                onClick={() => this.setState({ logtype:5,  logvaluetype:'Note', shouldOpenCreateLogDialog: true })}
                            >
                                <Icon className="primary_color">library_books</Icon>
                            </Fab>
                            <div className="text-gray">Note</div>
                        </div>
                        <div className="text-center">
                            <Fab
                                size="small"
                                color="primary"
                                className="btn-primary"
                                aria-label="Email"
                            >
                                <Icon className="primary_color">email</Icon>
                            </Fab>
                            <div className="text-gray">Email</div>
                        </div>
                        <div className="text-center">
                            <Fab
                                size="small"
                                color="primary"
                                className="btn-primary"
                                aria-label="Log"
                                onClick={() => this.setState({ logtype: 0, logvaluetype:'Phone Call', shouldOpenCreateLogDialog: true })}
                            >
                                <Icon className="primary_color">add_circle</Icon>
                            </Fab>
                            <div className="text-gray">Log</div>
                        </div>
                        <div className="text-center">
                            <Fab
                                size="small"
                                color="primary"
                                className="btn-primary"
                                aria-label="Task"
                                onClick={() => this.opeAddNewTaskPopup()}
                            >
                                <Icon className="primary_color">storage</Icon>
                            </Fab>
                            <div className="text-gray">Task</div>
                        </div>
                        <div className="text-center">
                            <Fab
                                size="small"
                                color="primary"
                                className="btn-primary"
                                aria-label="Meet"
                                onClick={() => this.setState({ logtype:0, logvaluetype:'Meeting', shouldOpenCreateLogDialog: true })}
                            >
                                <Icon className="primary_color">calendar_today</Icon>
                            </Fab>
                            <div className="text-gray">Meet</div>
                        </div>
                    </div>
                
                    {shouldOpenNoteDialog && (
                        <NoteDialog
                            handleClose={this.handleDialogClose}
                            open={shouldOpenNoteDialog}
                        />
                    )}
                     
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={this.state.isDeleteConfirmDialoOpen}
                        onClose={this.toggleDeleteDialog}
                        disableBackdropClick
                        disableEscapeKeyDown
                        aria-labelledby="ar-remove-dialog-title"
                    >
                        <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove this lead ?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.toggleDeleteDialog} color="primary">
                                Cancel
                                      </Button>
                            <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">
                                Ok
                                      </Button>

                        </DialogActions>
                    </Dialog>
                </div>
                {shouldOpenCreateLogDialog && (
                    <CreatelogDialog
                        handleClose={this.handleLogDialogClose}
                        open={shouldOpenCreateLogDialog}
                        handleMinimize={this.handleLogDialogMinimize}
                        handleFull={this.handleLogDialogFull}
                        minimizeScreen={this.state.minimizeLogDialog}
                        fullScreen={this.state.FullCreateLogDialog}
                        project_id={this.state.project_id}
                        logvaluetype={this.state.logvaluetype}
                        logtype={this.state.logtype}
                    />
                )}
                {shouldOpenCreateTaskDialog && (
                  <CreateNewTaskDialog
                    handleClose={this.handleTaskDialogClose}
                    open={shouldOpenCreateTaskDialog}
                    handleMinimize={this.handleTaskDialogMinimize}
                    handleFull={this.handleTaskDialogFull}
                    minimizeScreen={minimizeTaskDialog}
                    fullScreen={FullCreateTaskDialog}
                    allstates={this.state}
                    dataFromSidebar={this.state.newTaskData}
                  />
                )}
                <div className="ml-16 mr-0">
                    <ProfileDetails project_id={this.state.project_id}/>
                </div>
            </aside>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectReducer: state.projectReducer,
        settingReducer: state.settingReducer,
        contactReducer: state.contactReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getContactType: (client) => dispatch(actions.getContactType(client)),
        deleteLead: (client, request) => { dispatch(actions.deleteLead(client, request)) },
        addFavouriteProject: (client, request) => { dispatch(actions.addFavouriteProject(client, request)) },
        //getLeadFilterList: (client, request) => { dispatch(actions.getLeadFilterList(client, request)) },

        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        getUserList: (client) => dispatch(actions.getUserList(client)),
        updateProjectDetail: (client, request) => { dispatch(actions.updateProjectDetail(client, request)) },
        getCountryList: (client) => dispatch(actions.getCountryList(client)),
        getCompanyCurrency: (client) => dispatch(actions.getCompanyCurrency(client)),
        getTagsList: (client) => dispatch(actions.getTagsList(client)),
        getUserSetting: (client, data) => dispatch(actions.getUserSetting(client, data)),
        getRelatedList: (client) => dispatch(actions.getRelatedList(client)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProfileLeft);