import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, 
  FormControl, Select, InputLabel, Menu, MenuItem, List, 
  ListItem, ListItemText, DialogActions,CircularProgress,
  Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { createDynamicFields } from './CreateFieldsDynamic'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

class CreateNewLead extends Component {
  state = {
    lastname: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray:[],
    companyCurrencyData:[],
    focusValue:false,
    fieldsList: [],
    fieldsObj: {
      websites: {
        fieldid: "32",
        values: [
          { srno: "1", id: null, value: "" }
        ]
      },
      social: {
        fieldid: "35",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      phones: {
        fieldid: "30",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
    },
    tags: [],
    systemTagsList: [],
    usersListArray:[],
    selectedDate: new Date(),
    userSettings:"",
    isDataLoading:true,
    dataFromSidebar:""
  };

  componentWillMount() {
    // ^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$
    ValidatorForm.addValidationRule('emailDomain', (value) => {
      if (value.length > 0) {
        var specialChar = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });

    let val = { typeId: 3 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);

    //if compnay create from opportunity sidebar then set name
    if(this.props.name){
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["name"]: { fieldid: "29", companyvalue:this.props.name}
        }
      })
    }
    if(this.props.opportunitiesid){
      localStorage.setItem('opportunitiesid',this.props.opportunitiesid)
    }
  }

  componentWillReceiveProps(props) {

    //storing user setting data in state
    if(props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
        const settingData = props.settingReducer.userSettingArray.data;
    }
    if(props.dataFromSidebar){
        this.setState({
            dataFromSidebar:props.dataFromSidebar,
        })
    }

    if (props.settingReducer.companyCustomFieldList.data && props.settingReducer.companyCustomFieldList.is_loaded) {
      const customFields = props.settingReducer.companyCustomFieldList.data;
      props.settingReducer.companyCustomFieldList.is_loaded = false
      this.setState({ fieldsList: customFields ,isDataLoading:false})
      var contactTypeKey = this.findWithAttr(customFields, 'id', "33");
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["contacttype"]: { fieldid: "33", values:customFields[contactTypeKey].options[0]}
        }
      })
      //setting selected date in date picker
      customFields.map((v, i) => {
          if(v.fieldtype.id === "8"){
            var data = {
              fieldid:v.id,
              values:this.state.selectedDate
            }
            this.setState({
              fieldsObj: {
                ...this.state.fieldsObj,
                [v.fieldkey]: data
              }
            })
          }
      })

    }
    if (props.settingReducer.countryListGql.data) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["owner"]: { fieldid: "31", values:usersListArray[companyOwnerKey]}
        }
      })
    }
    if(props.notification && props.notification.status === true){
        // props.handleCloseCompany();
        props.clearNotification();
    }

    if (props.settingReducer.systemTagsList.data) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList : tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null) {
        var cData = props.settingReducer.companyCurrencyData.data;
        this.setState({companyCurrencyData:cData[0]});
    }
  }

  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    }, () => this.forceUpdate())
  }

  handleNormalFields = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCountryChange = (event, values) => {
    this.setState({country: values});
  }

  focusInValue = () => {
    this.setState({ focusValue: true })
  };

  focusOutValue = () => {
    this.setState({ focusValue: false })
  };
  
  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name) => {
    console.log("name", name);
    console.log("val", val);
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if(key != -1 && this.state.fieldsList[key].fieldtype.id != "4"  && this.state.fieldsList[key].fieldtype.id != "8"){
      if(this[name]){
        this[name].validate(val.value);
      }
    }
    //return

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      }
    })

  };
  handleBlur = (event,ref) => {
    console.log("this[ref]", this[ref]);
    if(this[ref].props.id == "tagInputId1"){
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      },10)
    }
    this[ref].validate(event.target.value);
}
  handleFormSubmit = () => {
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
    submitData = Object.values(submitData).map((row)=>{
      if(row.fieldid == "32" || row.fieldid == "35" || row.fieldid == "30"){
          if(row.values.length == 1 && (row.values[0].id == null || row.values[0].value == "")){
            row.values = [{"fieldid":row.fieldid,value:[]}]
          }
          if(row.values.length > 1){
            row.values.map((val,i)=>{
              if(val.value == ""){
                row.values.splice(i,1);
              }
            })
          }

          if(row.fieldid == "30"){
            if(row.values.length === 1){
              if(row.values[0].fieldid){
                row.values = []
              }else{
                if(row.values.length > 0){
                  var foundMobile = this.findWithAttr(row.values, 'id', "30");
                  if(foundMobile != -1){
                    var index = 1;
                    row.values.map((val,i)=>{
                      if(val.id == "30"){
                        row.values[i].srno = "1"
                      }else{
                        row.values[i].srno = (index+1).toString()
                      }
                      index++;
                    })
                  }
                }
              }
            }
          }
          if(row.fieldid == "32" || row.fieldid == "35" || row.fieldid == "30"){//make value blank array if notfill in this field
            if(row.values.length === 1){
              if(row.values[0].fieldid){
                row.values = []
              }
            }
          }
      }
      
      if(row.fieldid === "33" || row.fieldid === "38"){//38-visibility  33 contact type
        row.values = [{srno:"1", id:row.values.id,value:row.values.name}]
      }
      var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
      if(this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false){
        row.values = [{srno:"1",id:row.values.id,value:row.values.optionvalue}]
      }
      if(this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false){
        if(row.values.length >0){
          var ar = [];
          row.values.map((val,i)=>{
            ar.push({srno:(i+1).toString(),id:val.id, value:val.optionvalue})
          })
        }
        row.values = ar
      }
      
      //validating date fiels
      if(this.state.fieldsList[key].fieldtype.id == "8"){//date field
        if(this.state.fieldsList[key].recordcreationdefaultvalue == 2 && row.values == null){
          return false
        }else if(this.state.fieldsList[key].recordcreationdefaultvalue != 2 && row.values == null){
          row.companyvalue = "";
          delete row.values;
        }else if(row.values == "Invalid Date"){
          return false
        }else{
          // row.companyvalue = moment(row.values).format("X");

          // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          // row.companyvalue = moment.utc(logdate).format('X');
          var dt = moment(row.values).format('YYYY-MM-DD')
          row.companyvalue = dt;
          
          delete row.values;
        }
      }

      if(row.fieldid == "39"){//for tag
        if(row.values.length > 0){
          var objArr = [];
          row.values.map((val,k)=>{
            var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
            var tagId = "0";
            if(foundKey !== -1){
              tagId = this.state.systemTagsList[foundKey].id
            }
            objArr.push({"srno":(k+1).toString(),value:val.toLowerCase(),id:tagId})
          })  
          row.values = objArr  
        }
      }

      if(row.fieldid == "36"){//address
        row.companyvalue = {
          street:row.companyvalue,
          city:this.state.city,
          state:this.state.state,
          zipcode:this.state.zipcode,
          country:this.state.country.name
        }
      }
      if(row.fieldid == "31"){//owner value set
        if(row.values){
          row.values = [{"srno":"1",id:row.values.id,value:row.values.email,name:row.values.name}];
        }else{
          row.values = [];
        }
        delete row.companyvalue;
      }
      return row;
    })

    
    //not to submit if any field is false
    var allowToSubmit = true
    submitData.map((row)=>{
      if(!row){
        allowToSubmit = false
      }
    })

    let list = {
      filterdata: this.props.allstates.filterdata,
      list: {
        limit: this.props.allstates.pageSize,
        pageno: 1,
        searchtext: this.props.allstates.searchText,
        sorttype: this.props.allstates.sorttype,
        type: "list",
        fieldId: this.props.allstates.fieldId,
        columnname: this.props.allstates.column_name
      },
      filterid: 1
    };

    console.log("submitData", submitData);
    console.log("list", list);
    
    if(allowToSubmit){
      let req = {
        companydata:submitData,
        listParams:list,
        dataFromSidebar:this.state.dataFromSidebar,
      }
      this.props.handleCloseCompany();
      this.props.saveCompanyRecDetail(this.props.apolloClient.client,req);
    }
  };

  handleDateChange = date => {
      this.setState({ selectedDate: date });
  };

  arrDifference = (arr1, arr2) =>{
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
       if(arr2.indexOf(arr1[i]) === -1)
       arr.push(arr1[i]);
    }
    // for array2
    for(i in arr2) {
       if(arr1.indexOf(arr2[i]) === -1)
       arr.push(arr2[i]);
    }
    return arr.sort((x,y) => x-y);
  }

  findWithAttr = (array, attr, value) => {
      for(var i = 0; i < array.length; i += 1) {
          if(array[i][attr] === value) {
              return i;
          }
      }
      return -1;
  }
  render() {
    let { openCompany, handleCloseCompany, handleMinimizeCompany, handleFullCompany, fullScreenCompany, minimizeScreenCompany } = this.props;
    return (
      <Dialog onClose={handleCloseCompany} open={openCompany} maxWidth="md" fullWidth={true} className={`custom_dialog ${minimizeScreenCompany && fullScreenCompany ? 'alignitemsend-fullscreen' : ''} ${minimizeScreenCompany ? 'alignitemsend' : ''} ${fullScreenCompany ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create New company</h4>
          <div className="d-flex">
            <Tooltip title={minimizeScreenCompany ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimizeCompany}>
                <Icon className="text-white">{minimizeScreenCompany ? 'add' : 'remove'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={fullScreenCompany ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFullCompany}>
                <Icon className="text-white">{fullScreenCompany ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={handleCloseCompany}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <ValidatorForm noValidate debounceTime={500}   onSubmit={this.handleFormSubmit}>
          <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`}>
            <Grid container spacing={2}>
              {this.state.isDataLoading && <CircularProgress size={25} className="loaderPopupCenter"/>}
              {!this.state.isDataLoading && createDynamicFields(this)}
            </Grid>
          </div>

          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleCloseCompany}>Cancel</Button>
            <Button disabled={this.state.isDataLoading} variant="contained" type="submit" color="primary">Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});

const mapDispatchToProps = dispatch => {
  return {
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    saveCompanyRecDetail: (client, request) => { dispatch(actionCreators.saveCompanyRecDetail(client, request)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewLead);
