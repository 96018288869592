import React, { Component } from "react";
import {
  Dialog, IconButton, Button, Icon, Tooltip, Grid,
  FormControl, Select, InputLabel, Menu, MenuItem, List,
  ListItem, ListItemText, DialogActions,
  Chip, TextField, withStyles, Fab, Card
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';


const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.3rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);

class CreatelogDialog extends Component {
  state = {
    selectedDate: new Date(),
    logvaluetype: 'Phone Call',
    textvalue: '',
    selectedDate: new Date(),
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
  };
  componentDidMount() {
    if (this.props.logvaluetype != '') {
      this.setState({ logvaluetype: this.props.logvaluetype });
    }
  }
  handletextchange = (event) => {
    this.setState({ textvalue: event.target.value })
  }

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleCancelAddLog = () => {
    this.setState({ textvalue: '' })
  }

  handleSaveAddLog = () => {
    // let date = moment(this.state.selectedDate).format("X");

    let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
    var logdate = moment.tz(date_test, this.state.timeZone).format();
    var date = moment.utc(logdate).format('X');

    let propname = this.props.logvaluetype;
    let textvalue = this.state.textvalue;
    let saveLogparams = [];
    saveLogparams['save'] = {
      propertyname: propname,
      text: textvalue,
      logtime: 0,
      logdate: parseFloat(date),
      oldvalue: "",
      newvalue: "",
      logtype: this.props.logtype,
      fieldid: 0,
      taskid: this.props.task_id
    };
    saveLogparams['getlog'] = { taskid: this.props.task_id, logtypeid: [] }
    this.props.saveTaskLog(this.props.apolloClient.client, saveLogparams);
    this.setState({ textvalue: '' });
    this.props.handleClose();
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  render() {
   
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    const { logvaluetype, textvalue, selectedDate } = this.state
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Add new {logvaluetype}</h4>
          <div className="d-flex">
            <Tooltip title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <ValidatorForm noValidate debounceTime={500} onSubmit={this.handleSaveAddLog}>
          <div className="dialog-body">
            <Grid className="p-20" container spacing={2}>
                {(logvaluetype == 'Phone Call') ?
                  <>
                    <IconSmall className="mb-10 mr-10 mt-10 icon_left">call</IconSmall>
                    <span className="mb-10  mt-10 font-weight-500 font-size-14 ml-5 mr-5"> {logvaluetype} </span>
                  </>
                  :
                  <>
                    <IconSmall className="mb-10 mr-10 mt-10 icon_left">calendar_today</IconSmall>
                    <span className="mb-10  mt-10 font-weight-500 font-size-14 ml-5 mr-5"> {logvaluetype}</span>
                  </>
                }
                <TextValidator
                  required={true}
                  id="search-input"
                  fullWidth
                  label="Text"
                  placeholder="Click here to add Text"
                  value={textvalue}
                  validators={["required"] }
                  errorMessages={["this field is required"]}
                  onChange={this.handletextchange}
                />
                {/* {(textvalue && textvalue.length > 0) && ( */}
                  <div className="mt-40">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container spacing={3}>
                        <Grid item xs={4} className="pt-0">
                          <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Date</InputLabel>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format={this.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                            maxDate={new Date()}
                            value={selectedDate}
                            onKeyPress={(e) => e.preventDefault()}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} className="pt-0">
                          <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Time</InputLabel>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            format="HH:mm"
                            value={selectedDate}
                            onKeyPress={(e) => e.preventDefault()}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <div className="pull-right">
                      
                    </div>
                  </div>
                {/* )} */}
            </Grid>
          </div>
          <DialogActions className="dialog-action">
            <Button className="mr-10" color="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Save</Button>                          
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  taskReducer: state.taskReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    saveTaskLog: (client, request) => dispatch(actionCreators.saveTaskLog(client, request)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatelogDialog);
