import React, { Component } from "react";
import { Fab, IconButton, Icon, withStyles, InputLabel, Grid, TextField, Button } from "@material-ui/core";
import FiltersMenu from "./FiltersMenu";
import AssignTo from "./AssignTo";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class LeadUpcoming extends Component {
    state = {
        selectedDate: new Date('2014-08-18T21:11:54'),
        isOpenedTitle: false,
        isOpenedDescription: false,
        taskTitle: 'Call to test lead',
        taskDescription: 'Add Description',
    };
    handleDateChange = date => {
        console.log(date);
        this.setState({ date });
    };
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleSubmitTitle = event => {
        this.setState({ taskTitle: this.state.taskTitle });
        this.toggleTitle();
    }
    handleSubmitDescription = event => {
        this.setState({ taskDescription: this.state.taskDescription });
        this.toggleDescription();
    }
    toggleTitle = () => {
        this.setState(oldStateTitle => ({ isOpenedTitle: !oldStateTitle.isOpenedTitle }));
    }
    toggleDescription = () => {
        this.setState(oldStateDescription => ({ isOpenedDescription: !oldStateDescription.isOpenedDescription }));
    }
    render() {
        let { selectedDate, isOpenedTitle, isOpenedDescription, taskTitle, taskDescription } = this.state;
        return (
            <div className="lead_upcoming">
                <div className="box_portlet">
                    <div className="box_header">
                        <div className="box_label"><h3 className="box_title">Upcoming</h3></div>
                        <div className="box_tools">
                            <Fab className="bg-dark small-size overflow-hidden" size="small">
                                <h5 className="text-primary m-0 font-weight-normal">JS</h5>
                            </Fab> <FiltersMenu />
                        </div>
                    </div>
                    <div className="box_body">
                        <div className="lead_pl">
                            <Icon className="icon_left">storage</Icon>
                            <h5>Task</h5>
                            <Grid container className="mt-8">
                                <Grid>
                                    <Fab
                                        size="small"
                                        aria-label="Task"
                                        onClick={this.toggleTitle}
                                        className={`vert-middle ${!isOpenedTitle ? 'bg-success' : 'bg-grey'}`}
                                    >
                                        <Icon>{!isOpenedTitle ? 'check_box' : 'check_box_outline_blank'}</Icon>
                                    </Fab>
                                </Grid>
                                <Grid className="flex-auto">
                                    {!isOpenedTitle &&
                                        <div className="edit_bx">
                                            {/* <div onClick={this.toggleTitle}> */}
                                            <div >
                                                {!isOpenedTitle
                                                    ? <strike>{taskTitle}</strike>
                                                    :
                                                    { taskTitle }
                                                }
                                                <div className="edit_hover_show">
                                                    <IconButton aria-label="Delete" size="small">
                                                        <IconSmall>edit</IconSmall>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {isOpenedTitle &&
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                defaultValue={taskTitle}
                                                onChange={this.handleChange}
                                                name="taskTitle"
                                                className="ml-4 mb-8"
                                                fullWidth
                                                inputProps={{
                                                    style: {
                                                        padding: "8px"
                                                    }
                                                }}
                                            />
                                            <Button variant="contained" size="small" className="ml-4 mr-8" color="primary" onClick={this.handleSubmitTitle}>Save</Button>
                                            <Button variant="contained" size="small" onClick={this.toggleTitle}>Cancel</Button>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} className="mt-4">
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <AssignTo />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Due date</InputLabel>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={6} md={6} sm={6} xs={12} className="pt-0">
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    format="MM/dd/yyyy"
                                                    value={selectedDate}
                                                    onChange={this.handleDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12} className="pt-0">
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    id="time-picker"
                                                    value={selectedDate}
                                                    onChange={this.handleDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            {!isOpenedDescription &&
                                <div className="edit_bx mt-10" style={{ marginLeft: -6 }}>
                                    <div onClick={this.toggleDescription} className="font-size-13 font-weight-normal">
                                        {taskDescription}
                                        <div className="edit_hover_show">
                                            <IconButton aria-label="Delete" size="small">
                                                <IconSmall>edit</IconSmall>
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            }
                            {isOpenedDescription &&
                                <div>
                                    <TextField
                                        label="Add description"
                                        multiline
                                        rows="2"
                                        className="w-100 mt-10 mb-8"
                                        name="taskDescription"
                                        defaultValue={taskDescription}
                                        onChange={this.handleChange}
                                    />
                                    <Button variant="contained" size="small" className="mr-8" color="primary" onClick={this.handleSubmitDescription}>Save</Button>
                                    <Button variant="contained" size="small" onClick={this.toggleDescription}>Cancel</Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeadUpcoming;
