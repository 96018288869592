import React, { Component } from "react";
import { Button, Fab, Icon, Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";


class LeadInboxCompose extends Component {
  state = {
    to: "",
    subject: "",
    content: "",
    attachment: null,
  };

  handleSubmit = event => {
    console.log(this.state);
  };

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };
  handleAttachmentSelection = event => {
    this.setState({
      attachment: event.target.files[0]
    });
  };

  render() {
    let { to, subject, content, attachment } = this.state;
    let { handleClose } = this.props;

    return (
      <div className="inbox-form label-size-14 with_editor">
        <ValidatorForm
          ref="form"
          onSubmit={this.handleSubmit}
          onError={errors => null}
        >
          <TextValidator
            className="mb-16 w-100"
            label="To"
            onChange={this.handleChange}
            type="email"
            name="to"
            value={to}
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
          />
          <TextValidator
            className="mb-16 w-100"
            label="Subject"
            onChange={this.handleChange}
            type="text"
            name="subject"
            value={subject}
            validators={["required"]}
            errorMessages={["this field is required"]}
          />
          <TextValidator
            className="mb-16 w-100"
            onChange={this.handleChange}
            multiline
            name="content"
            placeholder="Message"
            rows="8"
            value={content}
            validators={["required"]}
            errorMessages={["this field is required"]}
          />
          <Grid container spacing={2} justify="space-between" alignItems="center">
            <Grid item>
              {attachment && <p className="mr-24">{attachment.name}</p>}
              <label htmlFor="attachment">
                <Fab color="primary" size="small" className="fab_primary" aria-label="attachment">
                  <Icon>attach_file</Icon>
                </Fab>
              </label>
              <input
                onChange={this.handleAttachmentSelection}
                className="d-none"
                id="attachment"
                type="file"
              />
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleClose}>Cancel</Button>
              <Button variant="contained" onClick={handleClose} type="submit" color="primary" className="ml-10">Send</Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

export default LeadInboxCompose;
