import React, { Component } from "react";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Link } from 'react-router-dom'

class SettingSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            "Personal preferences" : true,
            // "Manage Your Data": true,
            "Account settings":true,
            "Customize":true,
            "Team":true,
            "Opportunity":true,
            "Lead":true,
            "People":true,
            "Automation":true,
            "Item4":true,
            "data": [ 
                {
                    "name": "Manage Your Data",
                    "class": 'first_level',
                    "children": [
                        {
                            "name": "Import Data",
                            "class": 'second_level',
                            "url": "/settings/import-data/"
                        },
                        {
                            "name": "Export Data",
                            "class": 'second_level',
                            "url": "/settings/export-data/"
                        },
                    ]
                },
                {
                    "name": "Personal preferences",
                    "class": 'first_level',
                    "children": [
                        {
                            "name": "My Preferences",
                            "class": 'second_level',
                            "url": "/settings/my-preferences/"
                        },
                        {
                            "name": "Email Settings & Templates",
                            "class": 'second_level',
                            "url": "/settings/email-settings/"
                        },
                        {
                            "name": "Basic information",
                            "class": 'second_level',
                            "url": "#"
                        },
                        {
                            "name": "Notification",
                            "class": 'second_level',
                            "url": "/settings/notifications/"
                        },
                        {
                            "name": "Security",
                            "class": 'second_level',
                            "url": "#"
                        },
                    ]
                },
                
                {
                    "name": "Account settings",
                    "class": 'first_level',
                    "children": [
                        {
                            "name": "Company & invite new users",
                            "class": 'second_level',
                            "url": "/settings/user-management/"
                        },
                        {
                            "name": "Currency",
                            "class": 'second_level',
                            "url": "/settings/currency/"
                        }
                    ]
                },
                {
                    "name": "Customize",
                    "class": 'first_level',
                    "children": [
                        {
                            "name": "Dashboard",
                            "class": 'second_level',
                            "url": "#"
                        },
                        {
                            "name": "Manage Fields",
                            "class": 'second_level',
                            "url": "/settings/settings"
                        },
                        {
                            "name": "Manage activity type",
                            "class": 'second_level',
                            "url": "/settings/activity-types"
                        },
                        {
                            "name": "Tags",
                            "class": 'second_level',
                            "url": "#"
                        },
                        {
                            "name": "Related section",
                            "class": 'second_level',
                            "url": "/settings/relatedsettings"
                        },
                        {
                            "name": "Manage project stage",
                            "class": 'second_level',
                            "url": "/settings/project-stage"
                        },
                        {
                            "name": "Leads",
                            "class": 'second_level',
                            "children": [
                                {
                                    "name": "Lead Status",
                                    "class": 'third_level',
                                    "url": "/settings/lead-statuses"
                                }
                            ]
                        },
                        {
                            "name": "Contact",
                            "class": 'second_level',
                            "children": [
                                {
                                    "name": "Contact Type",
                                    "class": 'third_level',
                                    "url": "/settings/contact-types"
                                }
                            ]
                        },
                    ]
                },
                // {
                //     "name": "Team",
                //     "children": [
                //         {
                //             "name": "Set Goals",
                //             "url": "/settings/setgoal/"
                //         }
                //     ]
                // },
                {
                    "name": "Opportunity",
                    "class": 'first_level',
                    "children": [
                        {
                            "name": "Pipeline Stage",
                            "class": 'second_level',
                            "url": "/settings/pipeline-stages"
                        },
                        {
                            "name": "Source",
                            "class": 'second_level',
                            "url": "/settings/sources"
                        },
                        {
                            "name": "Loss Reasons",
                            "class": 'second_level',
                            "url": "/settings/loss-reason"
                        },
                    ]
                },
                {
                    "name": "Lead",
                    "class": 'first_level',
                    "url": "#",
                },
                {
                    "name": "People",
                    "class": 'first_level',
                    "url": "#",
                },
                // {
                //     "name": "Automation",
                //     "children": [
                //         {
                //             "name": "Workflow automation",
                //             "url": "/settings/workflow-automation/"
                //         },
                //         {
                //             "name": "Task automation",
                //             "url": "/settings/task-automation/"
                //         }
                //     ]
                // },
            ]
        }
    }
    // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
    handleClick(item) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
    }
    // if the menu item doesn't have any child, this method simply returns a clickable menu item that redirects to any location and if there is no child this method uses recursion to go until the last level of children and then returns the item by the first condition.
    handler(children, isChild) {
        const { state } = this
        return children.map((subOption) => {
            if (!subOption.children) {
                return (
                    <div key={subOption.name}>
                        <Link
                            to={subOption.url}
                        >
                            <ListItem
                                button
                                alignItems="left"
                                key={subOption.name}>
                                    <ListItemText     
                                        className={subOption.class} 
                                        inset={isChild ? true : false}
                                        disableTypography={isChild ? true : false}
                                        primary={subOption.name}
                                    />
                            </ListItem>
                        </Link>
                    </div>
                )
            }

            return (
                <div key={subOption.name}>
                    <ListItem
                        button
                        className={state[subOption.name] ? 'active' : ''}
                        onClick={() => this.handleClick(subOption.name)}>
                        <ListItemText                            
                            className={subOption.class}
                            primary={subOption.name} />
                        {state[subOption.name] ?
                            <ExpandLess /> :
                            <ExpandMore />
                        }
                    </ListItem>
                    <Collapse
                        in={state[subOption.name]}
                        timeout="auto"
                        unmountOnExit
                    >
                        {this.handler(subOption.children, true)}
                    </Collapse>
                </div>
            )
        })
    }
    render() {
        return (
            <React.Fragment>
                <aside className="sidenav collepse_menu">
                    <List className="side_menu">
                        {this.handler(this.state.data)}
                    </List>
                </aside>
            </React.Fragment>
        );
    }
}

export default SettingSidebar;
