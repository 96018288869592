import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tabs, Tab, Box, Typography, Icon, Card, IconButton, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { connect } from "react-redux";
import ConfirmationDialog from './ConfirmationDialog';
import CreateNewLeadDialog from './CreateNewLeadDialog'
import {
    Grid,
    Table,
    TableHeaderRow,
    Toolbar,
    VirtualTable,
    TableSelection,
    SearchPanel,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';
import * as actionCreators from '../../../store/actions/index';
import List from "../Teams/List";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}


class userManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            settingUserListArr: [],
            firstUserDetailArr: [],
            shouldOpenEditorDialog: false,
            selectoptions: [],
            shouldOpenConfirmationDialog: false,
            delete_id: '',
            isOpenedLeadList: true,
            isOpenedCustomFilters: false,
            isOpenedRange: false,
            shouldOpenCreateLeadDialog: false,
            FullCreateLeadDialog: false,
            minimizeLeadDialog: false,
            defaulSortingData: [
                { columnName: 'name', direction: 'asc' },
                { columnName: 'email', direction: 'desc' },
                { columnName: 'roles', direction: 'desc' },
                { columnName: 'team', direction: 'desc' },
                { columnName: 'pendingInvite', direction: 'desc' },
                { columnName: 'actions', direction: 'desc' },
            ],
            selection: [],
            showuserOwnermessage: false,
        }
    }

    componentDidMount() {
        let sendData = this.getsetMultipleCompanyId();
        this.props.getSettingUserList(sendData);
    }

    getsetMultipleCompanyId() {
        let setMultipleCompanyId = localStorage.getItem('setMultipleCompanyId');
        let fetch_data = '';
        if (setMultipleCompanyId !== null) {
            fetch_data = { companyid: setMultipleCompanyId }
        } else {
            fetch_data = { companyid: JSON.parse(localStorage.getItem('user_data_session')).companyId }
        }
        return fetch_data;
    }

    componentWillReceiveProps(props) {
        //debugger
        if (props.settingReducer.userList.data !== undefined) {
            this.setState({
                settingUserListArr: props.settingReducer.userList.data
            })
        }
        if (props.settingReducer.firstUserInfo.data !== undefined)
        {
            this.setState({
                firstUserDetailArr: props.settingReducer.firstUserInfo.data
            })
        }
    }
    fetchSettingUserList() {
        let sendData = this.getsetMultipleCompanyId();
        this.props.getSettingUserList(sendData);
    }

    handleDialogClose = () => {
        // debugger
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenConfirmationDialog: false
        })
        setTimeout(
            function () {
                this.fetchSettingUserList();
            }
                .bind(this),
            1000
        );
    }
    rendertableData = () => {
        if (this.state.settingUserListArr.length >= 1) {
            let renderData = [];
            this.state.settingUserListArr.map((val, index) => {
                //console.log("gfg==>>",val)
                renderData.push([
                    val.name,
                    val.email,
                    val
                ])
            })
            return renderData;
        }
    }


    handleDeleteUser = userid => {
        //debugger
        this.setState({
            delete_id: userid,
            shouldOpenConfirmationDialog: true
        });
    };

    handleConfirmationResponse = () => {    
        this.deleteUser();
        this.handleDialogClose();
        this.fetchSettingUserList();
    };

    deleteUser() {
        let send_data = ({ userid: [this.state.delete_id] })
        this.props.inviteUserDelete(send_data);
    };

    handleEditUser(value) {
        this.setState({
            uid: value,
            shouldOpenCreateLeadDialog: true
        })
    }

    multipleDelete = () => {
        let deleteArray = []

        this.state.selection.map(val => {
            if(this.state.settingUserListArr[val].companyowner != "true"){
                deleteArray.push(this.state.settingUserListArr[val].id) 
            }
            else
            {
                // remove(this.state.selection[val])
                this.setState({ showuserOwnermessage: !this.state.showuserOwnermessage });
            }
        })

        if (deleteArray.length >= 1) {
            let send_data = ({ userid: deleteArray })
            this.props.inviteUserDelete(send_data);
        }
        deleteArray = [];
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    }
    
    renderUsertable = () => {
        
        let rows = [];
        if (this.state.settingUserListArr) {
            this.state.settingUserListArr.map((val) => {
                //console.log(val)
                rows.push(
                    {
                        name: val.name,
                        email: val.email,
                        roles: "-",
                        team: "-",
                        pendingInvite: "-",
                        actions: (this.state.firstUserDetailArr.companyowner === "true" && val.companyowner !== "true") ? <>
                            <IconButton onClick={() => this.handleEditUser(val.id)} aria-label="delete">
                                <Icon>edit</Icon>
                            </IconButton>
                            <IconButton onClick={() => this.handleDeleteUser(val.id)} aria-label="delete">
                                <Icon>delete</Icon>
                            </IconButton>
                        </> : "-",
                        // actions: val.companyowner !== "true" ? <>
                        //     <IconButton onClick={() => this.handleEditUser(val.id)} aria-label="delete">
                        //         <Icon>edit</Icon>
                        //     </IconButton>
                        //     <IconButton onClick={() => this.handleDeleteUser(val.id)} aria-label="delete">
                        //         <Icon>delete</Icon>
                        //     </IconButton>
                        // </> : "",
                    }
                )
            })
        }

        return rows;
    }

    changeDefaultSorting = (val) => {
        let data = this.state.defaulSortingData;
        data = data.filter(sort => sort.columnName !== val[0].columnName).map(sort => {
            sort.direction = "desc"
            return sort;
        })
        data.push(val[0]);
        this.setState({
            defaulSortingData: data
        }, () => this.forceUpdate)
    }

    setSelection = (val) => {
        // if (this.state.settingUserListArr[val] && this.state.settingUserListArr[val].companyowner != "true")
        // {
            this.setState({ selection: val });
        // }    
    }
    
    handleLeadDialogClose = () => {
        this.setState({ shouldOpenCreateLeadDialog: false });
    };

    handleLeadDialogMinimize = () => {
        this.setState(oldStateminimizeLeadDialog => ({ minimizeLeadDialog: !oldStateminimizeLeadDialog.minimizeLeadDialog }));
        this.setState({ FullCreateLeadDialog: false })
    };

    handleLeadDialogFull = () => {
        this.setState(oldStateFullCreateLeadDialog => ({ FullCreateLeadDialog: !oldStateFullCreateLeadDialog.FullCreateLeadDialog }));
        this.setState({ minimizeLeadDialog: false })

    };
    toggleDeleteDialog = () => {
        this.setState({ showuserOwnermessage: !this.state.showuserOwnermessage });
    };
    render() {
        let {  shouldOpenCreateLeadDialog, minimizeLeadDialog, FullCreateLeadDialog } = this.state;
        let {  shouldOpenConfirmationDialog } = this.state;

        const { value, firstUserDetailArr } = this.state;

        const columns = [
            {
                name: "name",
                title: "Name"
            },
            {
                name: "email",
                title: "Email"
            },
            {
                name: "roles",
                title: "Roles"
            },
            {
                name: "team",
                title: "Team"
            },
            {
                name: "pendingInvite",
                title: "Pending Invite"
            },
            {
                name: "actions",
                title: "Actions"
            }
        ];
        
        
        const toolbarTemplate = params => {
            return (
                <div className="align__right">
                    <div className="d-inline-block ml-8 mr-16">
                        {
                            (firstUserDetailArr.companyowner === "true") ? 
                                <>
                                    {this.state.selection.length > 0 &&
                                        <div className="d-inline-block ml-8">
                                            <Button onClick={this.multipleDelete} variant="contained" color="secondary" aria-label="Delete" size="small" className="ml-8">
                                                Delete
                                            </Button>
                                        </div>
                                    }
                                    {this.state.selection.length > 0 &&
                                        <div className="d-inline-block ml-8">
                                            <Button variant="contained" size="small">
                                                Add to Team
                                        </Button>
                                        </div>
                                    }
                                    <div className="d-inline-block ml-8">
                                        <Button color="primary" onClick={() => this.setState({ shouldOpenCreateLeadDialog: true, uid: null })} variant="contained" size="small">
                                            Invite New User
                                        </Button>
                                    </div>
                                </>
                            :
                            ''
                        }
                    </div>
                </div>
            );
        };
        
        return (
            <Card elevation={3} className="h-100">
                <h3 className="ml-16 mt-16 mb-16">Company & Invite new users</h3>
                <Tabs
                    value={value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    className="settings_tabs display__block"
                    aria-label="scrollable auto tabs"
                >
                    <Tab label="Users" {...a11yProps(0)} />
                    <Tab label="Team" {...a11yProps(1)} />
                </Tabs>
                <div className="v_scroll user_list_table_wrapper position-relative">
                    <TabPanel value={value} index={0} className="h-100 fixed_layout" style={{ position: "initial" }}>
                        <div className="leads_list_table">
                            <Grid rows={this.renderUsertable()} columns={columns}>
                                <SelectionState
                                    selection={this.state.selection}
                                    onSelectionChange={this.setSelection}
                                />
                                <IntegratedSelection />
                                <VirtualTable />
                                <SearchState defaultValue="" />
                                <IntegratedFiltering />
                                <SortingState
                                    // defaultSorting={this.state.defaulSortingData}
                                    // onSortingChange={(val => { this.changeDefaultSorting(val) })}
                                    // sorting={this.state.defaulSortingData}
                                />
                                <IntegratedSorting />
                                {/* <DragDropProvider /> */}
                                <PagingState
                                    defaultCurrentPage={0}
                                    defaultPageSize={10}
                                />
                                <IntegratedPaging />
                                <Table />
                                <TableSelection showSelectAll highlightRow />
                                {/* <TableColumnReordering
                                    defaultOrder={['position', 'company', 'email', 'contactowner', 'phone', 'status', 'statusduration', 'statusupdated', 'lastcontacted', 'interactions']}
                                    onOrderChange={(val) => { console.log(val) }}
                                /> */}
                                <TableHeaderRow showSortingControls />

                                <PagingPanel
                                    pageSizes={[5, 10, 15, 0]}
                                />
                                {/* <TableFixedColumns
                                    leftColumns={leftColumns}
                                /> */}
                                {/* <TableColumnVisibility
                                    columnExtensions={tableColumnVisibilityColumnExtensions}
                                /> */}
                                <Toolbar rootComponent={toolbarTemplate} />
                                <SearchPanel />
                                {/* <ColumnChooser /> */}
                            </Grid>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} className="h-100">
                        <List />
                    </TabPanel>
                </div>
                {/* {shouldOpenEditorDialog && (
                    <MemberEditorDialog
                        handleClose={this.handleDialogClose}
                        open={shouldOpenEditorDialog}
                        uid={this.state.uid}
                        fetchUserList={this.fetchSettingUserList}
                    />
                )} */}

                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.showuserOwnermessage}
                    onClose={this.toggleDeleteDialog}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Company Owner can't be deleted ! "}</DialogTitle>
                    <DialogActions>
                        <Button type="Button" onClick={this.toggleDeleteDialog} className="btn_Primary">
                        Ok
                        </Button>
                    </DialogActions>
                </Dialog>

                {shouldOpenConfirmationDialog && (
                    <ConfirmationDialog
                        open={shouldOpenConfirmationDialog}
                        onConfirmDialogClose={this.handleDialogClose}
                        onYesClick={this.handleConfirmationResponse}

                    />
                )}
                {shouldOpenCreateLeadDialog && (
                    <CreateNewLeadDialog
                        handleClose={this.handleLeadDialogClose}
                        open={shouldOpenCreateLeadDialog}
                        handleMinimize={this.handleLeadDialogMinimize}
                        handleFull={this.handleLeadDialogFull}
                        minimizeScreen={minimizeLeadDialog}
                        fullScreen={FullCreateLeadDialog}
                        uid={this.state.uid}
                        fetchUserList={this.fetchSettingUserList}
                    />
                )}
            </Card >
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer
});


const mapDispatchToProps = dispatch => {
    return {
        getSettingUserList: (sendData) => dispatch(actionCreators.getSettingUserList(sendData)),
        inviteUserDelete: (delete_data) => dispatch(actionCreators.inviteUserDelete(delete_data))
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(userManagement));
