import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, 
  FormControl, Select, InputLabel, Menu, MenuItem, List, 
  ListItem, ListItemText, DialogActions, 
  Chip, TextField, CircularProgress, withStyles} from "@material-ui/core";
import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { createDynamicFields } from './CreateFieldsDynamic'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class CreateNewLead extends Component {
  state = {
    lastname: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray:[],
    gmailSuggestion: [],
    companyCurrencyData:[],
    focusValue:false,
    fieldsList: [],
    fieldsObj: {
      websites: {
        fieldid: "15",
        values: [
          { srno: "1", id: null, value: "" }
        ]
      },
      social: {
        fieldid: "10",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      phones: {
        fieldid: "7",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      email: {
        fieldid: "2",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
    },
    tags: [],
    systemTagsList: [],
    usersListArray:[],
    selectedDate: new Date(),
    userSettings:"",
    isDataLoading:true,
  };

  componentWillMount() {
    let val = { typeId: 1 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
  }

  componentWillReceiveProps(props) {

    //storing user setting data in state
    if(props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
        const settingData = props.settingReducer.userSettingArray.data;
        this.setState({
            userSettings:settingData,
        })
    }

    if (props.settingReducer.customFieldList.data && props.settingReducer.customFieldList.is_loaded) {
      props.settingReducer.customFieldList.is_loaded = false;
      const customFields = props.settingReducer.customFieldList.data;
      this.setState({ fieldsList: customFields,isDataLoading:false})
      var statuskey = this.findWithAttr(customFields, 'id', "3");
      var defaultKeyStatus = this.findWithAttr(customFields[statuskey].options, 'is_default', true);
      
      //setting selected date in date picker
      customFields.map((v, i) => {
          if(v.fieldtype.id === "8"){
            var data = {
              fieldid:v.id,
              values:this.state.selectedDate
            }
            this.setState({
              fieldsObj: {
                ...this.state.fieldsObj,
                [v.fieldkey]: data
              }
            })
          }
      })

      //setting default object for status
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["status"]: {fieldid: "3",values:customFields[statuskey].options[defaultKeyStatus]}
        }
      })
      
    }
    if (props.settingReducer.countryListGql.data) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
      // console.log("this.state.usersListArray", this.state);
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["owner"]: { fieldid: "4", values:usersListArray[companyOwnerKey]}
        }
      })
    }
    if(props.notification && props.notification.status === true){
        // props.handleClose();
        props.clearNotification();
    }

    if (props.settingReducer.systemTagsList.data) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList : tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null) {
        var cData = props.settingReducer.companyCurrencyData.data;
        this.setState({companyCurrencyData:cData[0]});
    }
   

    if (props.settingReducer.gmailSuggestions && props.settingReducer.gmailSuggestions.data) {
      
      let gmailSuggestion = [];
      let name = '';
      
      props.settingReducer.gmailSuggestions.data.map((suggestion) => {
        // if (suggestion.name != null){
        //   name = suggestion.name;
        // }else{
        //   name = suggestion.email.split('@')[0];
        // }
       
        gmailSuggestion.push({ label: suggestion.name, value: suggestion.id, email: suggestion.email });
      })

      // console.log(gmailSuggestion);
      
      this.setState({ gmailSuggestion });
    }
    
  }

  getChangeSuggestions = (event, fieldkey) => {
    let val = { name: event.target.value };
    this.handleChange({ fieldid: "1", leadvalue: event.target.value.trim() }, fieldkey);
    this.props.getSuggestionData(this.props.apolloClient.client, val);
  }

  handleNameChange = (event, values, fieldkey) => {
    if (values){
      if (values.email)
        this.handleAddMoreField('value', 'email', 0, values.email);
        
      if (values.label){
        this.handleChange({ fieldid: "1", leadvalue: values.label.trim() }, fieldkey)
      }else{
          this.handleChange({ fieldid: "1", leadvalue: "" }, fieldkey)
      }
        
    }
    // console.log('name----' + 'value', 'key----' + 'email', 'index----' + 0, 'value----' + value.email);
    // this.setState({ country: values });
  }

  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;
    
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    }, () => this.forceUpdate())
  }

  handleNormalFields = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCountryChange = (event, values) => {
    this.setState({country: values});
  }

  
  focusInValue = () => {
    this.setState({ focusValue: true })
  };

  focusOutValue = () => {
    this.setState({ focusValue: false })
  };
  
  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name) => {
   
    // console.log("name", name);
    // console.log("val", val);
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if(key != -1 && this.state.fieldsList[key].fieldtype.id != "4"  && this.state.fieldsList[key].fieldtype.id != "8"){
      if(this[name]){
        this[name].validate(val.value);
      }
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      }
    })

  };

  handleFormSubmit = () => {
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
    
    submitData = Object.values(submitData).map((row)=>{
      if(row.fieldid == "1"){//name field
        row.names = {firstname:row.leadvalue,lastname:this.state.lastname}
        delete row['leadvalue'];
      }
      if(row.fieldid == "14"){//Value fiels
        row.values = [{id:this.state.companyCurrencyData.currencyid.toString(),srno:"1",value:row.leadvalue}]
        delete row['leadvalue'];
      }
      
      if(row.fieldid == "15" || row.fieldid == "10" || row.fieldid == "7"|| row.fieldid == "2"){
        
          if(row.values.length == 1 && (row.values[0].id == null || row.values[0].value == "")){
            row.values = [{"fieldid":row.fieldid,value:[]}]
          }
          if(row.values.length > 1){
            row.values.map((val,i)=>{
              if(val.value == ""){
                row.values.splice(i,1);
              }
            })
          }

          if(row.fieldid == "7"){
            if(row.values.length === 1){
              if(row.values[0].fieldid){
                row.values = []
              }else{
                if(row.values.length > 0){
                  var foundMobile = this.findWithAttr(row.values, 'id', "2");
                  if(foundMobile != -1){
                    var index = 1;
                    row.values.map((val,i)=>{
                      if(val.id == "2"){
                        row.values[i].srno = "1"
                      }else{
                        row.values[i].srno = (index+1).toString()
                      }
                      index++;
                    })
                  }
                }
              }
            }else{
              if(row.values.length > 0){
                  var foundMobile = this.findWithAttr(row.values, 'id', "2");
                  if(foundMobile != -1){
                    var index = 1;
                    row.values.map((val,i)=>{
                      if(val.id == "2"){
                        row.values[i].srno = "1"
                      }else{
                        row.values[i].srno = (index+1).toString()
                      }
                      index++;
                    })
                  }
                }
            }
          }
          if(row.fieldid == "15" || row.fieldid == "10" || row.fieldid == "2"){//make value blank array if notfill in this field
            if(row.values.length === 1){
              if(row.values[0].fieldid){
                row.values = []
              }
            }
          }
      }
      if(row.fieldid === "3" || row.fieldid === "11" || row.fieldid === "5"){
        row.values = [{srno:"1", id:row.values.id,value:row.values.name}]
      }
      var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
      if(this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false){
        row.values = [{srno:"1",id:row.values.id,value:row.values.optionvalue}]
      }
      if(this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false){
        if(row.values.length >0){
          var ar = [];
          row.values.map((val,i)=>{
            ar.push({srno:(i+1).toString(),id:val.id, value:val.optionvalue})
          })
        }
        row.values = ar
      }
      
      //validating date fiels
      if(this.state.fieldsList[key].fieldtype.id == "8"){//date field
          console.log("row.values", row.values);
        if(this.state.fieldsList[key].recordcreationdefaultvalue == 2 && row.values == null){
          return false
        }else if(this.state.fieldsList[key].recordcreationdefaultvalue != 2 && row.values == null){
          row.leadvalue = "";
          delete row.values;
        }else if(row.values == "Invalid Date"){
          return false
        }else{
          // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          // row.leadvalue = moment.utc(logdate).format('X');
          var dt = moment(row.values).format('YYYY-MM-DD')
          row.leadvalue = dt;
          delete row.values;
        }
      }

      if(row.fieldid == "12"){
        if(row.values.length > 0){
          var objArr = [];
          row.values.map((val,k)=>{
            var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
            var tagId = "0";
            if(foundKey !== -1){
              tagId = this.state.systemTagsList[foundKey].id
            }
            console.log("val", val);
            objArr.push({"srno":(k+1).toString(),value:val.toLowerCase(),id:tagId})
          })  
          row.values = objArr  
        }
      }

      if(row.fieldid == "8"){
        row.leadvalue = {
          street:row.leadvalue,
          city:this.state.city,
          state:this.state.addressState,
          zipcode:this.state.zipcode,
          country:this.state.country.name
        }
      }
      if(row.fieldid == "4"){//owner value set
        if(row.values){
          row.values = [{"srno":"1",id:row.values.id,value:row.values.email,name:row.values.name}];
        }else{
          row.values = [];
        }
        delete row.leadvalue;
      }

      
      
      return row;
    })
    
    //not to submit if any field is false
    var allowToSubmit = true
    submitData.map((row)=>{
      if(!row){
        allowToSubmit = false
      }
    })
    
    console.log("submitData", submitData);
    console.log("allowToSubmit", allowToSubmit);
    // return
    if(allowToSubmit){
      let req = {
        LeadData:submitData
      }
      this.props.handleClose();
      this.props.saveLeadDetail(this.props.apolloClient.client,req);
    }
  };

  handleDateChange = date => {
      console.log("date", date);
      this.setState({ selectedDate: date });
  };

  arrDifference = (arr1, arr2) =>{
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
       if(arr2.indexOf(arr1[i]) === -1)
       arr.push(arr1[i]);
    }
    // for array2
    for(i in arr2) {
       if(arr1.indexOf(arr2[i]) === -1)
       arr.push(arr2[i]);
    }
    return arr.sort((x,y) => x-y);
  }

  findWithAttr = (array, attr, value) => {
      for(var i = 0; i < array.length; i += 1) {
          if(array[i][attr] === value) {
              return i;
          }
      }
      return -1;
  }
  handleBlur = (event,ref) => {
      // console.log("this[ref]", this[ref]);
      if(this[ref].props.id == "tagInputId1"){
        setTimeout(() => {
          document.getElementById("tagInputId1").value = "";
        },10)
      }
      this[ref].validate(event.target.value);
  }
  render() {
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create New lead</h4>
          <div className="d-flex">
            <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
        <ValidatorForm noValidate  instantValidate={false}  onSubmit={this.handleFormSubmit}>
          <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`}>
            <Grid container spacing={2}>
              {this.state.isDataLoading && <CircularProgress size={25} className="loaderPopupCenter"/>}
              {!this.state.isDataLoading && createDynamicFields(this)}
            </Grid>
          </div>
           
          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button disabled={this.state.isDataLoading} variant="contained" type="submit" color="primary">Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});

const mapDispatchToProps = dispatch => {
  return {
    
    getSuggestionData: (client, request) => { dispatch(actionCreators.getSuggestionData(client, request)) },
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    saveLeadDetail: (client, request) => { dispatch(actionCreators.saveLeadDetail(client, request)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewLead);
