import React, { Component } from "react";
import { DialogTitle,Dialog, IconButton, Button, Icon, Tooltip, Grid, DialogActions, Select, MenuItem, Checkbox, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import ReactDragListView from "react-drag-listview";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Table } from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import AddProjectStage from "./AddProjectStage"
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const followers = [
  { title: 'Everyone' },
  { title: 'Team abcd' },
  { title: 'Team xyz' },
  { title: 'Team 123' },
];
class projectStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stagedata: [],
      openAddPop: false,
      FullStageStatusDialog: false,
      minimizeStageStatusDialog: false,
      isDeleteConfirmDialoOpen: false,
      deleteItemName: '',
      deleteData: '',
      stageRecData: {},
    };
    this.columns = [
      {
        title: "",
        dataIndex: "drag",
        width: 50,
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: 50,
            }
          }
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        width: '40%',
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: '40%',
            }
          }
        },
      },
      
      {
        title: "Action",
        dataIndex: "delete",
      }
    ];

    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const stagedata = that.state.stagedata;
        const item = stagedata.splice(fromIndex, 1)[0];
        stagedata.splice(toIndex, 0, item);
        var completedStageKey = that.findWithAttr(stagedata,'key',"2")
        stagedata.push(stagedata.splice(completedStageKey, 1)[0]);
        that.setState({
          stagedata
        }, () =>{
          that.updateProjectStageOrder();
        })
      },
      handleSelector: "a"
    };
  }

  componentDidMount(){
    this.props.getProjectStage(this.props.apolloClient.client)
  }

  componentWillReceiveProps(props){
    if(props.settingReducer.projectStageList.data && props.settingReducer.projectStageList.is_loaded){
      props.settingReducer.projectStageList.is_loaded = false
      var stageData = props.settingReducer.projectStageList.data
      console.log("stageData", stageData);
      var dataArray = []

      var promise = new Promise((resolve, reject) => {
        stageData.map((val, k) => {
          dataArray.push({
              key: val.id,
              drag: <Link className={(val.id == 2)?"drag-handle stop-drag-stage":"drag-handle"} to="#"><Icon>drag_handle</Icon></Link>,
              name:val.name,
              
              delete: 
                  <>
                    <IconButton aria-label="delete" disabled={val.is_allowto_delete?false:true} className={val.is_allowto_delete?"text-muted":"text-muted disable-color"}  size="small" onClick={()=>this.deleteStage(val)}><Icon>delete</Icon></IconButton>
                    <IconButton aria-label="edit" disabled={!val.is_allowto_edit} className={!val.is_allowto_edit?"text-muted disable-color":"text-muted"}  size="small"  onClick={()=>this.handleAddUpdate(val)}><Icon>edit</Icon></IconButton>
                  </>
              ,
          })
        })
        resolve(true)
      })

      promise.then(result => {
        this.setState({
          stagedata:dataArray
        }, () => this.forceUpdate())
      });
    }
  }

  handleStageDialogClose = () => {
        this.setState({ openAddPop: false });
  };
  handleStageDialogMinimize = () => {
      this.setState(oldStateminimizeStageDialog => ({ minimizeStageStatusDialog: !oldStateminimizeStageDialog.minimizeStageStatusDialog }));
      this.setState({FullStageStatusDialog: false})
  };

  handleStageDialogFull = () => {
      this.setState(oldStateFullCreateStageDialog => ({ FullStageStatusDialog: !oldStateFullCreateStageDialog.FullStageStatusDialog }));
      this.setState({minimizeStageStatusDialog: false})

  };

  handleAddUpdate = (data)=>{
    console.log("data", data);
        let info = {}
        if(data && data !=undefined){
            info.name = data.name
            info.id = data.id
            info.isEdit = true
        }else{
            info.isEdit = false
        }
            console.log("info", info);
        this.setState({openAddPop:false},()=>{
            this.setState({stageRecData:info,openAddPop:true});
        });
    }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }

  deleteStage = (data) => {
        this.setState({deleteItemName:data.name});
        this.toggleDeleteDialog(data)
  };

  toggleDeleteDialog = (data) => {
      this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen,deleteData:data});
  };

  confirmDeleteStage = () => {
        let req = {
            stageid:parseInt(this.state.deleteData.id),
        }
        this.props.deleteProjectStage(this.props.apolloClient.client,req);
        this.toggleDeleteDialog()
    };
    updateProjectStageOrder = () => {
        var stageid = [];
        var orderno = [];
        this.state.stagedata.map((value, index) => {
            stageid.push(parseInt(value.key));
            orderno.push(index);
        })
        
        let req = {
            stageid:stageid,
            orderno:orderno,
        }
          //console.log("req", req);return
        this.props.updateProjectStageOrder(this.props.apolloClient.client,req);
    };

 
  render() {
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen, } = this.props;
    let { pipelinesname } = this.state;
    return (
        <>
        <div className="">
            <div className="box_portlet mb-16 mt-16 border-0">
              <div className="box_header">
                <div className="box_label"><h3 className="box_title">Project Stages</h3></div>
                <Button size="small" className="mt-16 mb-16" variant="contained" color="primary" onClick={()=>this.handleAddUpdate()}>Add stage</Button>
                {this.state.openAddPop && (
                      <AddProjectStage
                          handleClose={this.handleStageDialogClose}
                          open={this.state.openAddPop}
                          handleMinimize={this.handleStageDialogMinimize}
                          handleFull={this.handleStageDialogFull}
                          minimizeScreen={this.state.minimizeStageStatusDialog}
                          fullScreen={this.state.FullStageStatusDialog}
                          stageRecData={this.state.stageRecData}
                      />
                  )}
              </div>
              <div className="box_body p-0">
                <ReactDragListView {...this.dragProps}>
                  <Table
                    columns={this.columns}
                    pagination={false}
                    dataSource={this.state.stagedata}
                  />
                </ReactDragListView>
              </div>
            </div>
            <Dialog
                  fullWidth={true}
                  maxWidth = {'xs'}
                  open={this.state.isDeleteConfirmDialoOpen}
                  onClose={this.toggleDeleteDialog}
                  disableBackdropClick
                  disableEscapeKeyDown
                  aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove "} {this.state.deleteItemName} {"?"}</DialogTitle>
                    <DialogActions>
                      <Button  onClick={this.toggleDeleteDialog} color="primary">
                          Cancel
                      </Button>
                      <Button  type="Button" onClick={() =>this.confirmDeleteStage()}  className="btn_Primary">
                        Ok
                      </Button>
                       
                    </DialogActions>
            </Dialog>
          </div>
        </>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});
const mapDispatchToProps = dispatch => {
  return {
    getProjectStage: (client) => dispatch(actionCreators.getProjectStage(client)),
    deleteProjectStage: (client,req) => dispatch(actionCreators.deleteProjectStage(client,req)),
    updateProjectStageOrder: (client,req) => dispatch(actionCreators.updateProjectStageOrder(client,req)),
    clearNotification: () => dispatch(actionCreators.clearNotification()),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(projectStage);