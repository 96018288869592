import React from 'react';
import { Button, Icon, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, FormControlLabel, Checkbox, FormControl, Select, MenuItem } from '@material-ui/core';
import { Link } from "react-router-dom";
class TeamAccess extends React.Component {
    state = {
        teamMember: 1,
        pipeline: 1,
        reportsType: 1,
    };
    handleChange = event => {
        event.persist();
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
        }
    };
    handleFormSubmit = () => {
        console.log('submited')
    };
    render() {
        let { teamMember, pipeline, reportsType } = this.state;
        return (
            <div className="p-16 v_scroll position-relative">
                <ExpansionPanel className="expansion_panel">
                    <ExpansionPanelSummary
                        className="panel_heading"
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <div className="panel_summary">
                            <Icon className="expansion_icon">radio_button_checked</Icon>
                            <span>Lead</span>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="panel_details">
                        <Grid container spacing={2}>
                            <Grid item className="align-items-center d-flex w-220">
                                <Icon className="expansion_icon">radio_button_checked</Icon>
                                <span>Lead</span>
                            </Grid>
                            <Grid item className="w-400x">
                                <FormControl>
                                    <lable className="text-lable">What is access?</lable>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <FormControlLabel
                                                value="Access"
                                                control={<Checkbox color="primary" />}
                                                label="Access"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Create"
                                                control={<Checkbox color="primary" />}
                                                label="Create"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Edit"
                                                control={<Checkbox color="primary" />}
                                                label="Edit"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Delete"
                                                control={<Checkbox color="primary" />}
                                                label="Delete"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                                <FormControl className="w-100">
                                    <lable className="text-lable">Member of this team can see</lable>
                                    <Select
                                        name="teamMember"
                                        value={teamMember}
                                        fullWidth
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem className="select-menu-item" value={1}>Records ownded by anyone</MenuItem>
                                        <MenuItem className="select-menu-item" value={2}>Only records ownded by members of this team.</MenuItem>
                                        <MenuItem className="select-menu-item" value={3}>Only records ownded by themselves.</MenuItem>  </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="expansion_panel">
                    <ExpansionPanelSummary
                        className="panel_heading"
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <div className="panel_summary">
                            <Icon className="expansion_icon">contacts</Icon>
                            <span>Contact</span>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="panel_details">
                        <Grid container spacing={2}>
                            <Grid item className="align-items-center d-flex w-220">
                                <Icon className="expansion_icon">contacts</Icon>
                                <span>Contact</span>
                            </Grid>
                            <Grid item className="w-400x">
                                <FormControl>
                                    <lable className="text-lable">What is access?</lable>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <FormControlLabel
                                                value="Access"
                                                control={<Checkbox color="primary" />}
                                                label="Access"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Create"
                                                control={<Checkbox color="primary" />}
                                                label="Create"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Edit"
                                                control={<Checkbox color="primary" />}
                                                label="Edit"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Delete"
                                                control={<Checkbox color="primary" />}
                                                label="Delete"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                                <FormControl className="w-100">
                                    <lable className="text-lable">Member of this team can see</lable>
                                    <Select
                                        name="teamMember"
                                        value={teamMember}
                                        fullWidth
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem className="select-menu-item" value={1}>Records ownded by anyone</MenuItem>
                                        <MenuItem className="select-menu-item" value={2}>Only records ownded by members of this team.</MenuItem>
                                        <MenuItem className="select-menu-item" value={3}>Only records ownded by themselves.</MenuItem>  </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="expansion_panel">
                    <ExpansionPanelSummary
                        className="panel_heading"
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <div className="panel_summary">
                            <Icon className="expansion_icon">business_center</Icon>
                            <span>Company</span>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="panel_details">
                        <Grid container spacing={2}>
                            <Grid item className="align-items-center d-flex w-220">
                                <Icon className="expansion_icon">business_center</Icon>
                                <span>Company</span>
                            </Grid>
                            <Grid item className="w-400x">
                                <FormControl>
                                    <lable className="text-lable">What is access?</lable>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <FormControlLabel
                                                value="Access"
                                                control={<Checkbox color="primary" />}
                                                label="Access"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Create"
                                                control={<Checkbox color="primary" />}
                                                label="Create"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Edit"
                                                control={<Checkbox color="primary" />}
                                                label="Edit"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Delete"
                                                control={<Checkbox color="primary" />}
                                                label="Delete"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                                <FormControl className="w-100">
                                    <lable className="text-lable">Member of this team can see</lable>
                                    <Select
                                        name="teamMember"
                                        value={teamMember}
                                        fullWidth
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem className="select-menu-item" value={1}>Records ownded by anyone</MenuItem>
                                        <MenuItem className="select-menu-item" value={2}>Only records ownded by members of this team.</MenuItem>
                                        <MenuItem className="select-menu-item" value={3}>Only records ownded by themselves.</MenuItem>  </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="expansion_panel">
                    <ExpansionPanelSummary
                        className="panel_heading"
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <div className="panel_summary">
                            <Icon className="expansion_icon">dvr</Icon>
                            <span>Contact</span>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="panel_details">
                        <Grid container spacing={2}>
                            <Grid item className="align-items-center d-flex w-220">
                                <Icon className="expansion_icon">dvr</Icon>
                                <span>Contact</span>
                            </Grid>
                            <Grid item className="w-400x">
                                <FormControl>
                                    <lable className="text-lable">What is access?</lable>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <FormControlLabel
                                                value="Access"
                                                control={<Checkbox color="primary" />}
                                                label="Access"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Create"
                                                control={<Checkbox color="primary" />}
                                                label="Create"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Edit"
                                                control={<Checkbox color="primary" />}
                                                label="Edit"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Delete"
                                                control={<Checkbox color="primary" />}
                                                label="Delete"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                                <FormControl className="w-100">
                                    <lable className="text-lable">Member of this team can see</lable>
                                    <Select
                                        name="teamMember"
                                        value={teamMember}
                                        fullWidth
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem className="select-menu-item" value={1}>Records ownded by anyone</MenuItem>
                                        <MenuItem className="select-menu-item" value={2}>Only records ownded by members of this team.</MenuItem>
                                        <MenuItem className="select-menu-item" value={3}>Only records ownded by themselves.</MenuItem>  </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="expansion_panel">
                    <ExpansionPanelSummary
                        className="panel_heading"
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                    >
                        <div className="panel_summary">
                            <Icon className="expansion_icon">account_tree</Icon>
                            <span>Pipeline</span>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="panel_details">
                        <Grid container spacing={2}>
                            <Grid item className="align-items-center d-flex w-220">
                                <Icon className="expansion_icon">account_tree</Icon>
                                <span>Pipeline</span>
                            </Grid>
                            <Grid item className="w-400x">
                                <FormControl>
                                    <lable className="text-lable"><span className="w-125x d-inline-block">Select pipeline</span>
                                <Select
                                            name="pipeline"
                                            value={pipeline}
                                            className="ml-16 text-middle"
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem className="select-menu-item" value={1}>Select pipeline</MenuItem>
                                            <MenuItem className="select-menu-item" value={2}>Business Development</MenuItem>
                                            <MenuItem className="select-menu-item" value={3}>Sales</MenuItem>  </Select>
                                    </lable>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <FormControlLabel
                                                value="Access"
                                                control={<Checkbox color="primary" />}
                                                label="Access"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Create"
                                                control={<Checkbox color="primary" />}
                                                label="Create"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Edit"
                                                control={<Checkbox color="primary" />}
                                                label="Edit"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="Delete"
                                                control={<Checkbox color="primary" />}
                                                label="Delete"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                                <FormControl className="w-100">
                                    <lable className="text-lable">Member of this team can see</lable>
                                    <Select
                                        name="teamMember"
                                        value={teamMember}
                                        fullWidth
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem className="select-menu-item" value={1}>Records ownded by anyone</MenuItem>
                                        <MenuItem className="select-menu-item" value={2}>Only records ownded by members of this team.</MenuItem>
                                        <MenuItem className="select-menu-item" value={3}>Only records ownded by themselves.</MenuItem>  </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="expansion_panel">
                    <ExpansionPanelSummary
                        className="panel_heading"
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel6bh-content"
                        id="panel6bh-header"
                    >
                        <div className="panel_summary">
                            <Icon className="expansion_icon">assistant_photo</Icon>
                            <span>Reports</span>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="panel_details">
                        <Grid container spacing={2}>
                            <Grid item className="align-items-center d-flex w-220">
                                <Icon className="expansion_icon">assistant_photo</Icon>
                                <span>Reports</span>
                            </Grid>
                            <Grid item className="w-400x">
                                <FormControl>
                                    <lable className="text-lable"><span className="w-125x d-inline-block">Select reports type</span>
                                <Select
                                            name="reportsType"
                                            value={reportsType}
                                            className="ml-16 text-middle"
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem className="select-menu-item" value={1}>Pipeline reports</MenuItem>
                                            <MenuItem className="select-menu-item" value={2}>Business Development</MenuItem>
                                            <MenuItem className="select-menu-item" value={3}>Sales</MenuItem>  </Select>
                                    </lable>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <FormControlLabel
                                                value="Access"
                                                control={<Checkbox color="primary" />}
                                                label="Access"
                                                labelPlacement="start"
                                                className="custom-checkbox"
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                                <FormControl className="w-100">
                                    <lable className="text-lable">Member of this team can see</lable>
                                    <Select
                                        name="teamMember"
                                        value={teamMember}
                                        fullWidth
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem className="select-menu-item" value={1}>Records ownded by anyone</MenuItem>
                                        <MenuItem className="select-menu-item" value={2}>Only records ownded by members of this team.</MenuItem>
                                        <MenuItem className="select-menu-item" value={3}>Only records ownded by themselves.</MenuItem>  </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Link to="/settings/user-management/"><Button variant="contained" size="small" color="primary" startIcon={<Icon>arrow_back</Icon>}>Back to Teams List</Button></Link>
            </div>
        );
    }
}


export default TeamAccess;
