import React from "react";
import { Icon, Fab, List, ListItem, Menu, ListItemText, MenuItem, InputLabel } from "@material-ui/core";


const options = [
    "Justin Sung (Justin@gmail.com)",
    "john williamson (john@gmail.com)",
];

export default function AssignTo() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    function handleClickListItem(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuItemClick(event, index) {
        setSelectedIndex(index);
        setAnchorEl(null);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <React.Fragment>
            <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500 pb-10">Assigned to</InputLabel>
            <List className="p-0">
                <ListItem
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    onClick={handleClickListItem}
                    className="p-0"
                >
                    <Fab className="bg-dark small-size overflow-hidden mr-8" size="small">
                        <h5 className="text-primary m-0 font-weight-normal">M</h5>
                    </Fab>
                    <ListItemText className="assign_list"
                        secondary={options[selectedIndex]}
                    />
                    <Icon>arrow_drop_down</Icon>
                </ListItem>
            </List>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                    >
                        <Fab className="bg-dark small-size overflow-hidden mr-8" size="small">
                            <h5 className="text-primary m-0 font-weight-normal">j</h5>
                        </Fab> {option}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}
