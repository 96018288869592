import React, { Component } from "react";
import SettingSidebar from '../../Layout/SettingSidebarMenu'
import PropTypes from 'prop-types';
import { Tabs, Tab, Card } from '@material-ui/core';
import Detail from "./Detail";
import TeamAccess from "./TeamAccess";

class DetailMain extends Component {
    constructor(props) {
        super(props)

        const pageName = (this.props.match.params.id === 1) ? "Sparten" : "Athens";
        this.state = {
            activeTab: 1,
            page: pageName,
            tabs: [
                {
                    id: 1,
                    label: pageName + ' Team',
                    component: <Detail team={pageName} />,
                    icon: ""
                },
                {
                    id: 2,
                    label: 'Access & Permissions',
                    component: <TeamAccess />,
                    icon: ""
                },
            ]
        }
    }

    handleChange = (event, activeTab) => {
        this.setState((state) => ({ activeTab }));
    };

    handleClick(item) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
    }

    render() {
        const { tabs, activeTab } = this.state;
        return (
            <React.Fragment>
                <SettingSidebar />
                <div className="lead_content right-16 settings_view">
                    <Card elevation={3} className="h-100">
                        <Tabs indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            className="settings_tabs"
                            aria-label="scrollable auto tabs"
                            value={activeTab}
                            onChange={this.handleChange}
                        >
                            {
                                tabs.map(tab => (
                                    <Tab
                                        key={tab.id}
                                        label={tab.label}
                                        value={tab.id}
                                        icon={tab.icon}
                                    />
                                ))
                            }
                        </Tabs>

                        {
                            tabs.map(tab => (
                                activeTab === tab.id
                                    ? <React.Fragment key={tab.id}>{tab.component}</React.Fragment>
                                    : null
                            ))
                        }
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

DetailMain.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            component: PropTypes.object.isRequired,
        }).isRequired)
        .isRequired,
};

export default DetailMain;
