import React, { Component } from "react";
import { Button, Icon, IconButton, withStyles } from "@material-ui/core";
import { default as MaterialGrid } from '@material-ui/core/Grid';
import { Link } from "react-router-dom";

import {
    Grid,
    TableHeaderRow,
    Toolbar,
    VirtualTable,
    TableSelection,
    SearchPanel,
    Table,
    TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    SelectionState,
    IntegratedSelection,
} from '@devexpress/dx-react-grid';
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);
class ActionButton extends React.Component {

    render() {
        return (
            <>
                <Link to={"/settings/team-detail/" + this.props.teamId}><IconButton size="small"><IconSmall>visibility</IconSmall></IconButton></Link>
                <Link to="#"><IconButton size="small"><IconSmall>delete</IconSmall></IconButton></Link>
            </>
        )
    }
}

class List extends Component {
    state = {
        selection: []
    };

    setSelection = (val) => {
        this.setState({
            selection: val
        })
    }

    render() {
        const tableColumnExtensions = [{}];
        const columns = [
            {
                name: "team",
                title: "Team name"
            },
            {
                name: "users",
                title: "Users"
            },
            {
                name: "action",
                title: "Action"
            },
        ];

        const rows = [
            {
                team: "Sparten",
                users: "10",
                action: <ActionButton teamId="1" />,
            },
            {
                team: "Athens",
                users: "5",
                action: <ActionButton teamId="2" />,
            },
        ];
        const toolbarTemplate = params => {
            return (
                <div className="d-flex mt-16 mb-8 ml-16 mr-16">
                    <MaterialGrid container justify="space-between">
                        <MaterialGrid>
                            <MaterialGrid container justify="space-between">
                                <MaterialGrid>
                                    <h3 className="ml-16">Team List</h3>
                                </MaterialGrid>
                            </MaterialGrid>
                        </MaterialGrid>
                        <MaterialGrid>
                        </MaterialGrid>
                    </MaterialGrid>
                    {params.children}
                    {this.state.selection.length ?
                        <div className="d-inline-block ml-8">
                            <Button variant="contained" color="secondary" size="small">
                                Delete
                        </Button>
                        </div>
                        :
                        ''
                    }
                    <div className="d-inline-block ml-8">
                        <Button variant="contained" color="primary" size="small" style={{ minWidth: 95 }}>
                            Create New
                        </Button>
                    </div>
                </div>
            );
        };
        return (
            <div className="leads_list_table">
                <Grid rows={rows} columns={columns}>
                    <SelectionState
                        selection={this.state.selection}
                        onSelectionChange={this.setSelection}
                    />
                    <IntegratedSelection />
                    <VirtualTable />
                    <SearchState defaultValue="" />

                    <SortingState />
                    <IntegratedSorting />
                    <Table columnExtensions={tableColumnExtensions} />
                    <TableSelection showSelectAll highlightRow />

                    <TableHeaderRow showSortingControls />

                    <TableFixedColumns />
                    <Toolbar rootComponent={toolbarTemplate} />
                    <SearchPanel />
                </Grid>
            </div>
        );
    }
}

export default List;
