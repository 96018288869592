import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";
const initialState={
  counter:[],
  userData:[],
  //notifications:[]
  // results:[]
}

const reducer = ( state=initialState, action ) => {
  switch ( action.type ) {  
    case actionTypes.REGISTER_USER:
      console.log("sdfsdfsfsf===");
      const newSate= Object.assign( {}, state );
      newSate.userData = action.val;
    return newSate;

    case actionTypes.LOGIN_WITH_EMAIL_AND_PASSWORD:
      console.log("return login with email and password")  
    return { ...state, userData:action.val };

    case actionTypes.USER_VERIFY:
      console.log("user_verify");
      return { ...state, userData:action.val };

    case actionTypes.FORGOT_PASSWORD_REQUEST:
      console.log("forgot_password_request");
      return{ ...state, counter:action.val };

    case actionTypes.FORGOT_PASSWORD:
      console.log("forgot_password_request");
      return{ ...state, counter:action.val };

    case actionTypes.USER_LOGIN:
      console.log("return user_login_data");
      return { ...state, counter:action.val };

    case actionTypes.DECREMENT:
      console.log("herere")
      return updateObject(state,{counter:state.counter-action.val})

    case actionTypes.RESEND_EMAIL:
    console.log("herere")
    return updateObject(state,{userData:state.counter})
   
    default:
    return state;
  }
};
export default reducer;