import React, { Component } from "react";
import { IconButton, Icon, withStyles, Grid, Fab } from "@material-ui/core";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

class LeadCallLog extends Component {
    state = {
        selectedDate: new Date()
    };
    handleDateChange = date => {
        console.log(date);

        this.setState({ date });
    };
    render() {
        return (
            <div className="lead_upcoming mt-16">
                <div className="box_portlet">
                    <div className="box_body">
                        <div className="lead_pl">
                            <Icon className="icon_left">call</Icon>
                            <Grid container justify="space-between">
                                <Grid>
                                    <h5 className="mb-0">Call log</h5>
                                    <p className="mt-4 mb-4 font-size-13 text-hint">10 December 2019 at 09:00 AM GMT +5:30</p>
                                </Grid>
                                <Grid>
                                    <IconButton aria-label="Pin" size="small" className="button_size hover_show">
                                        <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" fillOpacity="0.6" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                                    </IconButton>
                                    <IconButton aria-label="Add" size="small" className="hover_show">
                                        <IconSmall>add_circle</IconSmall>
                                    </IconButton>
                                    <IconButton aria-label="Delete" size="small" className="hover_show">
                                        <IconSmall>delete</IconSmall>
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div className="border_bx">
                                This is call logs
                            <div className="hover_show">
                                    <IconButton aria-label="Delete" size="small">
                                        <IconSmall>edit</IconSmall>
                                    </IconButton>
                                </div>
                            </div>
                            <p className="font-size-14"><Fab className="small-size overflow-hidden mr-8" color="secondary" size="small">
                                                        <h5 className="text-white m-0 font-weight-normal">J</h5>
                                                    </Fab> <span className="font-weight-500">Justin</span> logged a call</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeadCallLog;
