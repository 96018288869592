import React, { Component } from "react";
import ReactDragListView from "react-drag-listview";
import { Table } from 'antd';
import Files from "../Common/Files";
import Tasks from "../Common/Tasks";
import Calendar from "../Common/Calendar";
import  Projects from "../Common/Projects";
import People from "../Common/People";
import Companies from "../Common/Companies";
import Opportunity from "../Common/Opportunity";

class ListComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            leaddata: [],
            rows:[],
            recordId:"",
            recordtype:"",
        };
       
    }
    componentDidMount() {
        if(this.props.recordId){
            this.setState({
                recordId:this.props.recordId,
                recordtype:this.props.recordtype,
            },()=>{
                this.forceUpdate()
            });
        }
      }   
    componentWillReceiveProps(props) {
        this.setState({ rows: this.props.activedata});
        this.setState({ inRows: this.props.inActivedata });
        if (props.recordId != this.state.recordId) {
              this.setState({
                  recordId:parseInt(props.recordId),
                  recordtype:props.recordtype,
              },()=>{
                  this.forceUpdate()
              });
        }
    }

    render() {   
    const { rows } = this.state;
    const items=[];
   

    for (const [index, value] of rows.entries()) {

        if(value.alias=='tasks'){
            items.push(<Tasks recordId={this.state.recordId} recordtype={this.state.recordtype}/>);
        }
        if(value.alias=='files')
        {
            items.push(<Files recordId={this.state.recordId} recordtype={this.state.recordtype}/>);

        }
        if(value.alias=='calendarevents')
        {
            items.push(<Calendar recordId={this.state.recordId} recordtype={this.state.recordtype}/>);

        }
        if(value.alias=='contact')
        {
            items.push(<People recordId={this.state.recordId} recordtype={this.state.recordtype}/>);

        }
        if(value.alias=='companies')
        {
            items.push(<Companies recordId={this.state.recordId} recordtype={this.state.recordtype}/>);

        }
        if(value.alias=='projects')
        {
            items.push(<Projects recordId={this.state.recordId} recordtype={this.state.recordtype}/>);

        }
        if(value.alias=='opportunities')
        {
            items.push(<Opportunity recordId={this.state.recordId} recordtype={this.state.recordtype}/>);

        }

      }
        return (
            <>
                     {items}
            </>
            
        );
    }
}

export default ListComponent;
