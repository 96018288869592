import React, { Component } from "react";
import SettingSidebar from '../Layout/SettingSidebarMenu';
import "../../styles/_settings.scss"
import { Route } from 'react-router-dom';
import RelatedSettingTab from "../Settings/Relatedsettings/RelatedSettingTab";
import EmailSettings from "../Settings/EmailSettings/EmailSettingTab";
import Notifications from "../Settings/Notifications/Notifications";
import ImportData from "../Settings/ManageData/ImportData";
import ImportCompanies from "../Settings/ManageData/ImportCompanies";
import ImportOpportunities from "../Settings/ManageData/ImportOpportunities";
import ImportPeople from "../Settings/ManageData/ImportPeople";
import UploadCompanies from "../Settings/ManageData/UploadCompanies";
import UploadOpportunities from "../Settings/ManageData/UploadOpportunities";
import UploadPeople from "../Settings/ManageData/UploadPeople";

import ExportData from "../Settings/ManageData/ExportData";

import SettingTab from "../Settings/SettingTab";
import SettingsPipeLine from "./SettingPipeline";
import UserManagement from './UserManagement/UserManagement'
import SettingTask from './SettingTask';
import LeadStatuses from "../Settings/LeadStatuses";
import ContactTypes from "../Settings/ContactTypes";


import Sources from "../Settings/Sources";
import Currency from "../Settings/Currency";
import DetailMain from "../Settings/Teams/DetailMain";
import MyPreferences from '../Settings/MyPreferences';
import LossReason from "../Settings/LossReason";
import ActivityTypes from "../Settings/ActivityTypes";
import projectStage from "../Settings/projectStage";

class Settings extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
    handleClick(item) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
    }
    render() {
        return (
            <React.Fragment>
                <SettingSidebar />
                <div className="lead_content right-16 settings_view">
                    <Route exact path="/settings/settings/" component={SettingTab} />
                    <Route exact path="/settings/relatedsettings" component={RelatedSettingTab} />
                    <Route exact path="/settings/setting-task/" component={SettingTask} />
                    <Route exact path="/settings/pipeline-stages/" component={SettingsPipeLine} />
                    <Route exact path="/settings/user-management/" component={UserManagement} />
                    <Route exact path="/settings/lead-statuses" component={LeadStatuses} />
                    <Route exact path="/settings/contact-types" component={ContactTypes} />
                    <Route exact path="/settings/sources" component={Sources} />
                    <Route exact path="/settings/currency" component={Currency} />
                    <Route exact path="/settings/my-preferences/" component={MyPreferences} />
                    <Route exact path="/settings/email-settings/" component={EmailSettings} />
                    <Route exact path="/settings/notifications/" component={Notifications} />
                    <Route exact path="/settings/import-data/" component={ImportData} />
                    <Route exact path="/settings/import-companies/" component={ImportCompanies} />
                    <Route exact path="/settings/import-companies/:id" component={UploadCompanies} />
                    <Route exact path="/settings/import-opportunities/" component={ImportOpportunities} />  
                    <Route exact path="/settings/import-opportunities/:id" component={UploadOpportunities} />  
                    <Route exact path="/settings/import-people/" component={ImportPeople} />
                    <Route exact path="/settings/import-people/:id" component={UploadPeople} />
                    <Route exact path="/settings/export-data/" component={ExportData} />
                    
                    {/* <Route exact path="/settings/teams/" component={TeamMain} /> */}
                    <Route exact path="/settings/team-detail/:id" component={DetailMain} />
                    <Route exact path="/settings/loss-reason" component={LossReason} />
                    <Route exact path="/settings/activity-types" component={ActivityTypes} />
                    <Route exact path="/settings/project-stage" component={projectStage} />
                </div>
            </React.Fragment>
        );
    }
}

export default Settings;


