import * as actionTypes from './actionTypes';
import { gql } from "apollo-boost";
import axios from 'axios';
import * as dataSet from './dataSet';
import * as actionCreators from './index';
import Server from "../../Common/Server"

const sessionToken = localStorage.getItem('jwt_token');

const config = {
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'token': sessionToken,
        'api_for': 1
    },
};

const configForFile = {
    headers: {
        'content-type': 'multipart/form-data',
        'token': sessionToken,
        'api_for': 1
    }
}


export const settingAddUser = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + "UserAdd/AddUser", value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.SETTING_ADD_USER, return_data));
                fetchData(dispatch)
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const getSettingUserList = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + "UserList/ListUser", value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                //let newstate = Object.assign(return_data , value)
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));  
                dispatch(setResponseDetails(actionTypes.GET_SETTING_USER_LIST, return_data));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const getUserById = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + "GetUser/UserDetail", value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                //let newstate = Object.assign(return_data , value)
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));  
                dispatch(setResponseDetails(actionTypes.GET_USER_BY_ID, return_data));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const inviteUserDelete = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'DeleteUser/UserDelete', value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                fetchData(dispatch)
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

const fetchData = (dispatch) =>{
    let setMultipleCompanyId = localStorage.getItem('setMultipleCompanyId');
    let fetch_data = '';
    if (setMultipleCompanyId !== null) {
        fetch_data = { companyid: setMultipleCompanyId }
    } else {
        fetch_data = { companyid: JSON.parse(localStorage.getItem('user_data_session')).companyId }
    }

    dispatch(actionCreators.getSettingUserList(fetch_data))
}

export const saveEditUser = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'UserEdit/EditUser', value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                fetchData(dispatch)
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const getUserProfileInfoFirst = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'GetUser/UserDetail', value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                dispatch(setResponseDetails(actionTypes.GET_USER_PROFILE_INFO_FIRST, return_data));
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const getBussinessType = () => {
    return function (dispatch) {
        return axios.post(Server.API + 'GetBusiness/BusinessType', '', config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.GET_BUSSINESS_TYPE, return_data));
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}


export const GetCompanyStrength = () => {
    return function (dispatch) {
        return axios.post(Server.API + 'GetStrength/CompanyStrength', '', config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.GET_COMPANY_STRENGTH, return_data));
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const updateCompanyInfo = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'UpdateInfo/CompanyInfo', value, configForFile)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                //dispatch(setResponseDetails(actionTypes.GET_COMPANY_STRENGTH, return_data));
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const companyListById = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'CompanyList/ListCompany', value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.COMPANY_LIST_BY_ID, return_data));
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}


export const updateUser = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'UpdateDetail/UserUpdate', value, config)
            .then(({ data }) => {

                let resp = { userid: value.userid, type: "profile" }
                dispatch(actionCreators.getUserById(resp))

                let return_data = dataSet.processData(data);

                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data, true));
                //dispatch(setResponseDetails(actionTypes.UPDATE_USER, return_data));
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}


export const notifications = (value) => {
    return {
        type: actionTypes.NOTIFICATIONS,
        val: value
    }
}

const setResponseDetails = (newType, data) => {
    return {
        type: newType,
        val: data
    };
}
const notificationAll = (newType, data, status) => {
    return {
        type: newType,
        val: data,
        status: status,
    }
}
const errorMessageAll = (error) => {
    let msg = 'Something Went Wrong!';
    if (error) {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            //msg = error.graphQLErrors[0].message;
            error = error.graphQLErrors;
            if (error.result && error.result.errors && error.result.errors.length > 0) {
                msg = error.result.errors[0].message;
            }
        } else if (error.networkError && error.networkError.length > 0) {
            //msg = error.networkError[0].message;
            error = error.graphQLErrors;
            if (error.result && error.result.errors && error.result.errors.length > 0) {
                msg = error.result.errors[0].message;
            }
        } 
    }
    return {
        notify_msg: msg,
        error: true
    }

}

    export const countryList = (value) => {
        return function (dispatch) {
            return axios.post(Server.API + 'GetCountry/CountryList', value, config)
                .then(({ data }) => {
                    let return_data = dataSet.processData(data);
                    dispatch(notificationAll(actionTypes.COUNTRY_LIST, return_data));
                    //dispatch(setResponseDetails(actionTypes.UPDATE_USER, return_data));
                })
                .catch(function (error) {
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
                })
        }
    }



    /**
     * TYPE : GET
     * Getting Custom Field list
     */
    export const customFieldTypeList = (client) => {

        return function (dispatch) {
            const query = gql`
            query getFieldTypeList
            {
                getFieldTypeList {
                   id
                   name
                   fieldicon
                }
            }
        `;
            client.query({
                query: query
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getFieldTypeList);
                dispatch(setResponseDetails(actionTypes.COSTOM_FIELD_TYPE_LIST, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    /**
     * TYPE : GET
     * Getting Custom Field list
     */
    export const customFieldList = (client, value) => {
        
        return function (dispatch) {
            const query = gql`
                query getCustomFieldList($typeId: Float!) 
                {
                    getCustomFieldList(typeId: $typeId) {
                        id
                        recordtypeid
                        customefieldmasterid
                        fieldlabel
                        fieldtype{
                            fieldicon
                            id
                            name
                        }
                        fieldkey
                        recordcreationrules_editable
                        recordeditrules_editable
                        isdiactivated
                        includeinfilter
                        recordcreationdefaultvalue
                        recordeditdefaultvalue
                        generatedfieldid
                        autogeneratefieldkey
                        is_default
                        fieldstatus
                        createdby
                        createdhost
                        createddate
                        lastmodifiedby
                        lastmodifiedhost
                        lastmodifieddate
                        orderno
                        recordcreationrulesvalue
                        recordeditrulesvalue
                        fieldicon
                        currencytype
                        options
                        piplineid
                    }
                }
            `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
                variables: value,
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getCustomFieldList);
                if(value.typeId == 1)
                    dispatch(setResponseDetails(actionTypes.COSTOM_FIELD_LIST, return_data));
                
                if (value.typeId == 2)
                    dispatch(setResponseDetails(actionTypes.CONTACT_COSTOM_FIELD_LIST, return_data));

                if (value.typeId == 3)
                    dispatch(setResponseDetails(actionTypes.COMPANY_COSTOM_FIELD_LIST, return_data));
                
                if (value.typeId == 4)
                    dispatch(setResponseDetails(actionTypes.OPPORTUNITIES_COSTOM_FIELD_LIST, return_data));
                
                if (value.typeId == 5)
                    dispatch(setResponseDetails(actionTypes.PROJECTS_COSTOM_FIELD_LIST, return_data));

                if (value.typeId == 6)
                    dispatch(setResponseDetails(actionTypes.TASKS_COSTOM_FIELD_LIST, return_data));
                
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }
    
    /**
     * TYPE : GET
     * Getting Company List
     */
    export const companyList = (client, value) => {
        return function (dispatch) {
            const query = gql`{
            getCompanyList {
             name
             displayname
             companysize
            }
        }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getCompanyList);
                dispatch(setResponseDetails(actionTypes.COMPANY_LIST, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    /**
     * TYPE : POST
     * on dropdown change updating custom field record
     */
    export const updateCustomFieldRecord = (client, value) => {
        return function (dispatch) {
            const mutation = gql`
             mutation updateRecordValue($typeId: Float!,$editdefaultvalue: Float!, $creationdefaultvalue: Float!, $generatedfieldid: String!) {
                 updateRecordValue(typeId: $typeId, editdefaultvalue: $editdefaultvalue, creationdefaultvalue:$creationdefaultvalue, generatedfieldid: $generatedfieldid) {
                        id
                        recordtypeid
                        customefieldmasterid
                        fieldlabel
                        fieldtype{
                            fieldicon
                            id
                            name
                        }
                        fieldkey
                        recordcreationrules_editable
                        recordeditrules_editable
                        isdiactivated
                        includeinfilter
                        recordcreationdefaultvalue
                        recordeditdefaultvalue
                        generatedfieldid
                        autogeneratefieldkey
                        is_default
                        fieldstatus
                        createdby
                        createdhost
                        createddate
                        lastmodifiedby
                        lastmodifiedhost
                        lastmodifieddate
                        orderno
                        recordcreationrulesvalue
                        recordeditrulesvalue
                        fieldicon
                        currencytype
                        options
                        piplineid
                        leadfavourite
                        taskcomplete
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: value
            })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != null) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Custom Field Record Updated Successfully"
                    }

                    let return_data = dataSet.processGQLData(response.data.updateRecordValue);
                    if (value.typeId == 1)
                        dispatch(setResponseDetails(actionTypes.COSTOM_FIELD_LIST, return_data));
                    if (value.typeId == 2)
                        dispatch(setResponseDetails(actionTypes.CONTACT_COSTOM_FIELD_LIST, return_data));

                    if (value.typeId == 3)
                        dispatch(setResponseDetails(actionTypes.COMPANY_COSTOM_FIELD_LIST, return_data));

                    if (value.typeId == 4)
                        dispatch(setResponseDetails(actionTypes.OPPORTUNITIES_COSTOM_FIELD_LIST, return_data));
                    
                    if (value.typeId == 5)
                        dispatch(setResponseDetails(actionTypes.PROJECTS_COSTOM_FIELD_LIST, return_data));
                    
                    if (value.typeId == 6)
                        dispatch(setResponseDetails(actionTypes.TASKS_COSTOM_FIELD_LIST, return_data));

                    // if (value.typeId == 2 || value.typeId == 3 || value.typeId == 4)   
                    //     dispatch(actionCreators.customFieldList(client, { typeId: value.typeId }))
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    /**
     * TYPE : POST
     * on status change update custom field record
     */
    export const updateCustomFieldStatus = (client, value) => {
        return function (dispatch) {
            const mutation = gql`
            mutation updateFieldStatus($typeId: Float!,$pipelinesid:[Float!], $fieldstatus: Boolean!, $generatedfieldid: String!) {
                updateFieldStatus(typeId: $typeId, pipelinesid: $pipelinesid, fieldstatus: $fieldstatus, generatedfieldid: $generatedfieldid) {
                    id
                    recordtypeid
                    customefieldmasterid
                    fieldlabel
                    fieldtype{
                        fieldicon
                        id
                        name
                    }
                    fieldkey
                    recordcreationrules_editable
                    recordeditrules_editable
                    isdiactivated
                    includeinfilter
                    recordcreationdefaultvalue
                    recordeditdefaultvalue
                    generatedfieldid
                    autogeneratefieldkey
                    is_default
                    fieldstatus
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                    orderno
                    recordcreationrulesvalue
                    recordeditrulesvalue
                    fieldicon
                    currencytype
                    options
                }
            }
            `;
            client.mutate({
                mutation: mutation,
                variables: value
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != null) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Custom Field Status Updated Successfully"
                        }

                        let return_data = dataSet.processGQLData(response.data.updateFieldStatus);
                        dispatch(actionCreators.customFieldList(client, { typeId: value.typeId}))
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                    
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }



    /**
     * TYPE : POST
     * on order change updating custom field record
     */
    export const updateCustomFieldOrder = (client, value) => {
        return function (dispatch) {
            const mutation = gql`
            mutation updateFieldOrder($typeId:Float!, $orderno: [Float!]!, $fieldid: [Float!]!) {
                updateFieldOrder(typeId:$typeId, orderno: $orderno, fieldid: $fieldid) {
                    id
                    recordtypeid
                    customefieldmasterid
                    fieldlabel
                    fieldtype{
                        fieldicon
                        id
                        name
                    }
                    fieldkey
                    recordcreationrules_editable
                    recordeditrules_editable
                    isdiactivated
                    includeinfilter
                    recordcreationdefaultvalue
                    recordeditdefaultvalue
                    generatedfieldid
                    autogeneratefieldkey
                    is_default
                    fieldstatus
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                    orderno
                    recordcreationrulesvalue
                    recordeditrulesvalue
                    fieldicon
                    currencytype
                    options
                }
            }
            `;
            client.mutate({
                mutation: mutation,
                variables: value
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != null) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Custom Field Order Updated Successfully"
                        }
                        let return_data = dataSet.processGQLData(response.data.updateFieldOrder);
                       

                        if (value.typeId == 1)
                            dispatch(setResponseDetails(actionTypes.UPDATE_CUSTOM_FIELD_ORDER, return_data));

                        if (value.typeId == 2 || value.typeId == 3 || value.typeId == 4 || value.typeId == 5 || value.typeId == 6)
                            dispatch(actionCreators.customFieldList(client, { typeId: value.typeId }));
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }


    /**
     * Type : POST
     * Add/Create new Custom field.
     */
    export const addCustomFieldInput = (client, request) => {
        return dispatch => {
            const mutation = gql`
            mutation saveCustomField($field: CustomFieldInput!) {
                saveCustomField(field:$field) {
                    id
                    fieldlabel
                    fieldtype
                    fieldkey
                    inleads
                    inpeople
                    incompany
                    inopportunities
                    inprojects
                    intasks
                    includeinfilter
                    autogeneratefieldkey
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                    currencytype
                    piplineid
                }
            }
            `;
            client.mutate({
                mutation: mutation,
                variables: request['data'],
            })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != null) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Custom Field Added Successfully"
                    }
                    let return_data = dataSet.processGQLData(response.data.saveCustomField);
                    dispatch(setResponseDetails(actionTypes.ADD_CUSTOM_FIELD, return_data));
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.CreatedFieldList(client))
                dispatch(actionCreators.customFieldTypeList(client))
                dispatch(actionCreators.customFieldList(client, request['typeId']))
                

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    /**
     * Type : POST
     * Update Custom field.
     */
    export const updateCustomFieldInput = (client, request) => {
        return dispatch => {
            const mutation = gql`
            mutation updateCreatedFieldDetail($field: CustomFiledUpdateInput!) {
                updateCreatedFieldDetail(field:$field) {
                    id
                    fieldlabel
                    fieldtype
                    fieldkey
                    inleads
                    inpeople
                    incompany
                    inopportunities
                    inprojects
                    intasks
                    includeinfilter
                    autogeneratefieldkey
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                    currencytype
                    options
                    piplineid
                }
            }
            `;
            client.mutate({
                mutation: mutation,
                variables: request['fieldData']
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != null) {
                        let return_data = dataSet.processGQLData(response.data.updateCreatedFieldDetail);
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Custom Field Updated Successfully"
                        }
                        dispatch(setResponseDetails(actionTypes.UPDATE_CUSTOM_FIELD, return_data));
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                    dispatch(actionCreators.CreatedFieldList(client))
                    dispatch(actionCreators.customFieldTypeList(client))
                    dispatch(actionCreators.customFieldList(client, { typeId: 1 }))
                    dispatch(actionCreators.customFieldList(client, { typeId: 2 }))
                    dispatch(actionCreators.customFieldList(client, { typeId: 3 }))
                    dispatch(actionCreators.customFieldList(client, { typeId: 4 }))
                    dispatch(actionCreators.customFieldList(client, { typeId: 5 }))
                    dispatch(actionCreators.customFieldList(client, { typeId: 6 }))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }


    /**
     * Type : POST
     * Delete Custom field.
     */
    export const deleteCustomFieldInput = (client, request) => {
        return function (dispatch) {
            const query = gql`
            query deleteCustomField($fieldId: Float!) 
            {
                deleteCustomField(fieldId: $fieldId) {
                    status
                    message
                }
            }
        `;
            client.query({
                query: query,
                variables: request['fieldId']
            }).then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteCustomField.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.deleteCustomField.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.deleteCustomField.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.CreatedFieldList(client))
                dispatch(actionCreators.customFieldTypeList(client))
                dispatch(actionCreators.customFieldList(client, { typeId: 1 }))
                dispatch(actionCreators.customFieldList(client, { typeId: 2 }))
                dispatch(actionCreators.customFieldList(client, { typeId: 3 }))
                dispatch(actionCreators.customFieldList(client, { typeId: 4 }))
                dispatch(actionCreators.customFieldList(client, { typeId: 5 }))
                dispatch(actionCreators.customFieldList(client, { typeId: 6 }))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }


    /**
     * Type: GET
     * Featch created custom fields
     */
    export const CreatedFieldList = (client) => {

        return function (dispatch) {
            const query = gql`
            query getCreateFieldList
            {
                getCreateFieldList {
                    id
                    fieldlabel
                    fieldtype
                    fieldkey
                    inleads
                    inpeople
                    incompany
                    inopportunities
                    inprojects
                    intasks
                    includeinfilter
                    autogeneratefieldkey
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                }
            }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getCreateFieldList);
                dispatch(setResponseDetails(actionTypes.CREATED_FIELD_LIST, return_data));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    /**
     * Type: GET
     * Featch defails of created custom field
     */
    export const customFieldDetail = (client, value) => {

        return function (dispatch) {
            const query = gql`
            query getCreatedFieldDetail($fieldid: Float!) 
            {
                getCreatedFieldDetail(fieldid: $fieldid) {
                    id
                    fieldlabel
                    fieldtype
                    fieldkey
                    inleads
                    inpeople
                    incompany
                    inopportunities
                    inprojects
                    intasks
                    includeinfilter
                    autogeneratefieldkey
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                    currencytype
                    options
                }
            }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
                variables: value
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getCreatedFieldDetail);
                dispatch(setResponseDetails(actionTypes.COSTOM_FIELD_DETAIL, return_data));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }


    export const loadLeadStatues = (client) => {
        return dispatch => {
            const query = gql`query getLeadStatus {
                    getLeadStatus{
                        id, name, order_no, is_allowto_delete, is_default,is_allowto_edit
                    }
                }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getLeadStatus);
                dispatch(setResponseDetails(actionTypes.LEAD_STATUSES_LIST_GET, return_data));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }
    export const updateLeadStatusesOrder = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updateStatusOrder($orderno:[Float!]!, $statusid:[Float!]!) {
                 updateStatusOrder(orderno:$orderno,statusid:$statusid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    orderno: request.leadStatusOrder,
                    statusid: request.leadStatusIds,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateStatusOrder.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateStatusOrder.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateStatusOrder.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }
    export const updateDefaultLeadStatus = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updateStatusDefault($statusid:Float!) {
                 updateStatusDefault(statusid:$statusid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    statusid: request.statusid,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateStatusDefault.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateStatusDefault.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateStatusDefault.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }
    export const AddLeadStatus = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation saveLeadStatus($statusname: String!) {
                 saveLeadStatus(statusname:$statusname) {
                    id
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    statusname: request.name,
                }
            })
                .then(response => {
                    let returnData = [];
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Lead Status Added Successfully."
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.loadLeadStatues(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }
    export const updateLeadStatusRecord = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updateStatusDetail($statusname: String!,$statusid: Float!) {
                 updateStatusDetail(statusname:$statusname,statusid:$statusid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    statusname: request.name,
                    statusid: request.id,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateStatusDetail.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateStatusDetail.message
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateStatusDetail.message
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                        }
                    }

                    dispatch(actionCreators.loadLeadStatues(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const deleteLeadStatus = (client, request) => {
        return dispatch => {
            const query = gql`
             query deleteLeadStatus($statusid:Float!) {
                 deleteLeadStatus(statusid:$statusid) {
                    status
                    message
                 }
               }
             `;
            client.query({
                query: query,
                variables: {
                    statusid: request.statusid,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.deleteLeadStatus.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.deleteLeadStatus.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.deleteLeadStatus.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                    dispatch(actionCreators.loadLeadStatues(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const loadSources = (client) => {
        return dispatch => {
            const query = gql`query getSourcesOption {
                    getSourcesOption{
                        id, name, order_no, is_allowto_delete, is_default,is_allowto_edit
                    }
                }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getSourcesOption);
                dispatch(setResponseDetails(actionTypes.LEAD_SOUECES_LIST, return_data));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    export const updateSourceListOrder = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updateSourceOrder($orderno:[Float!]!, $sourceid:[Float!]!) {
                 updateSourceOrder(orderno:$orderno,sourceid:$sourceid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    orderno: request.sourceListOrder,
                    sourceid: request.sourceIds,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateSourceOrder.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateSourceOrder.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateSourceOrder.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const deleteSource = (client, request) => {
        return dispatch => {
            const query = gql`
             query deleteSource($sourceid:Float!) {
                 deleteSource(sourceid:$sourceid) {
                    status
                    message
                 }
               }
             `;
            client.query({
                query: query,
                variables: {
                    sourceid: request.sourceid,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.deleteSource.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.deleteSource.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.deleteSource.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                    dispatch(actionCreators.loadSources(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }
    export const saveSource = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation saveSource($sourcename: String!) {
                 saveSource(sourcename:$sourcename) {
                    id
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    sourcename: request.name,
                }
            })
                .then(response => {
                    let returnData = [];
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Source Added Successfully."
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.loadSources(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }
    export const updateSourceRecord = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updateSourceDetail($sourcename: String!,$sourceid: Float!) {
                 updateSourceDetail(sourcename:$sourcename,sourceid:$sourceid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    sourcename: request.name,
                    sourceid: request.id,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateSourceDetail.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateSourceDetail.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateSourceDetail.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.loadSources(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }


    export const loadCurrencyList = (client) => {
        return dispatch => {
            const query = gql`query getCurrencyList {
                    getCurrencyList{
                        id,code,name,symbol,is_default
                    }
                }
        `;

            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getCurrencyList);
                dispatch(setResponseDetails(actionTypes.ALL_CURRENCY_LIST, return_data));
            }).catch((error) => {
                // let err_data = errorMessageGraphQl(error);
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    export const getCompanyCurrency = (client) => {
        return function (dispatch) {
            const query = gql`{
            getCmpCurrencyList {
                id
                currencyid
                symbol
                thousandsseparator
                decimalplaces
                decimalseparator
                detail
            }
        }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getCmpCurrencyList);
                dispatch(setResponseDetails(actionTypes.COMPANY_CURRENCY_DATA, return_data));
            }).catch((error) => {
                // let err_data = errorMessageGraphQl(error);
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    export const updateCurrencyData = (client, request) => {
        request.id = parseInt(request.id);
        request.currencyid = parseInt(request.currencyid);
        request.decimalplaces = parseInt(request.decimalplaces);
        var params = {
            id: request.id,
            currencyid: request.currencyid,
            symbol: request.symbol,
            thousandsseparator: request.thousandsseparator,
            decimalplaces: request.decimalplaces,
            decimalseparator: request.decimalseparator,
        }
        return dispatch => {
            const mutation = gql`
             mutation updateCmpCurrency($currency: CurrencyUpdateInput!) {
                 updateCmpCurrency(currency:$currency) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    currency: params
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateCmpCurrency.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateCmpCurrency.message
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData,true));
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateCmpCurrency.message
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData,false));
                        }
                    }
                    
                    dispatch(actionCreators.getCompanyCurrency(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const getCountryList = (client) => {
        return dispatch => {
            const query = gql`query getCountryList {
                    getCountryList{
                        id name code
                    }
                }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getCountryList);
                dispatch(setResponseDetails(actionTypes.COUNTRY_LIST_GQL, return_data));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    const errorMessageGraphQl = (err) => {
        let msg = 'Something Went Wrong!';
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
            msg = err.graphQLErrors[0].message;
        } else if (err.networkError && err.networkError.result ) {
            msg = err.networkError.result.errors[0].message;
        }
         else if (err.message) {
            msg = err.message;
        }

        return {
            notify_msg: msg,
            error: true
        }
    }

    export const clearNotification = (value) => {
        return function (dispatch) {
            dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
        }
    }
    const clearNotificationAll = (newType, data, status) => {
        return {
            type: actionTypes.NOTIFICATIONS,
            val: '',
            status: '',
        }
    }

    export const getUserSetting = (client, value) => {

        return function (dispatch) {
            const query = gql`
            query getUserSetting($userid: Float!) 
            {
                getUserSetting(userid: $userid) {
                    id
                    profileimage
                    dateformat
                    currencyid
                    taskvisible
                    setreminders
                    remindersday
                    userid
                    timezone
                }
            }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
                variables: value,
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getUserSetting);
                dispatch(setResponseDetails(actionTypes.GET_USER_SETTING, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    export const saveUserSetting = (client, request) => {
        return dispatch => {
            const mutation = gql`
            mutation saveUserSetting($setting: UserSettingInput!, $fileInput: Upload!) {
                saveUserSetting(setting:$setting, fileInput:$fileInput) {
                    id
                    profileimage
                    dateformat
                    currencyid
                    taskvisible
                    setreminders
                    remindersday
                    userid
                    timezone
                }
            }
            `;
            client.mutate({
                mutation: mutation,
                variables: {
                    setting: request.setting,
                    fileInput: request.fileInput,
                }
            })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveUserSetting.id) {
                        returnData = {
                            'data': response.data.saveUserSetting,
                            'error': false,
                            'notify_msg': "Data updated Successfully."
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData,true));
                    } else {
                        returnData = {
                            'data': request.setting,
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData,false));
                    }
                }
                dispatch(setResponseDetails(actionTypes.GET_USER_SETTING, returnData));
                
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    export const getTagsList = (client) => {
        return dispatch => {
            const query = gql`query getTagsList {
                    getTagsList{
                        id tagname createdby createdhost createddate lastmodifiedby lastmodifieddate
                    }
                }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getTagsList);
                dispatch(setResponseDetails(actionTypes.SYSTEM_TAGS_LIST, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }
    export const getUserList = (client) => {
        return dispatch => {
            const query = gql`query getUserList {
                    getUserList{
                        id name email password companyId accesstoken sessiontoken
                        activestatus googlestatus passwordtoken emailverified
                        forgotpasswordtoken forgotverified systemadmin googlesync
                        dataexport dropbox title phoneno address country
                        state city pincode companyowner fcmtoken createdhost 
                        createdby createddate lastmodifiedby lastmodifiedhost 
                        lastmodifieddate lastlogin isFirstCmp
                    }
                }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getUserList);
                dispatch(setResponseDetails(actionTypes.USERS_LIST_LIST, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }
    export const getCityList = (client) => {
        return dispatch => {
            const query = gql`query getCityList {
                    getCityList{
                        id name
                    }
                }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getCityList);
                dispatch(setResponseDetails(actionTypes.GET_CITY_LIST, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }
    export const getStateList = (client) => {
        return dispatch => {
            const query = gql`query getStateList {
                    getStateList{
                        id name
                    }
                }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getStateList);
                dispatch(setResponseDetails(actionTypes.GET_STATE_LIST, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }
    export const getZipcodeList = (client) => {
        return dispatch => {
            const query = gql`query getZipcodeList {
                    getZipcodeList{
                        id name
                    }
                }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getZipcodeList);
                dispatch(setResponseDetails(actionTypes.GET_ZIPCODE_LIST, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }
    export const getTimezoneList = (client) => {
        return dispatch => {
            const query = gql`query getTimezoneList {
                    getTimezoneList{
                        id name
                    }
                }
            `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getTimezoneList);
                dispatch(setResponseDetails(actionTypes.GET_TIMEZONE_LIST, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    export const getPipelines = (client) => {
        return dispatch => {
            const query = gql`query getPipelines {
                    getPipelines{
                        id stage name
                    }
                }
            `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getPipelines);
                dispatch(setResponseDetails(actionTypes.SETTING_PIPELINE_LIST, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    export const savePipelines = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation savePipelines($stagedata: [JSON!]!,$pipelinesname:String!) {
                 savePipelines(stagedata:$stagedata,pipelinesname:$pipelinesname) {
                    id stage name
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    stagedata: request.stagedata,
                    pipelinesname: request.pipelinesname,
                }
            })
                .then(response => {
                    let returnData = [];
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Pipeline Added Successfully."
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getPipelines(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const deletePipelines = (client, request) => {
        return dispatch => {
            const query = gql`
             query deletePipelines($pipelinesid:Float!) {
                 deletePipelines(pipelinesid:$pipelinesid) {
                    status
                    message
                 }
               }
             `;
            client.query({
                query: query,
                variables: {
                    pipelinesid: parseInt(request.pipelinesid),
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.deletePipelines.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.deletePipelines.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.deletePipelines.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                    dispatch(actionCreators.getPipelines(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const getPipelinesDetail = (client,request) => {

        return function (dispatch) {
            const query = gql`
            query getPipelinesDetail($pipelinesid: Float!) 
            {
                getPipelinesDetail(pipelinesid: $pipelinesid) {
                    id name stage
                }
            }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
                variables: {
                    pipelinesid:request.pipelinesid
                }
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getPipelinesDetail);
                dispatch(setResponseDetails(actionTypes.PIPELINE_DATA_BY_ID, return_data));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    export const updatePipelinesDetail = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updatePipelinesDetail($stagedata:[JSON!]!, $pipelinesname:String!,$pipelinesid:Float!) {
                 updatePipelinesDetail(stagedata:$stagedata,pipelinesname:$pipelinesname,pipelinesid:$pipelinesid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    stagedata: request.stagedata,
                    pipelinesname: request.pipelinesname,
                    pipelinesid: request.pipelinesid,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updatePipelinesDetail.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updatePipelinesDetail.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updatePipelinesDetail.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                    dispatch(actionCreators.getPipelines(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const getLossReasonList = (client) => {
        return dispatch => {
            const query = gql`query getLossReasonList {
                    getLossReasonList{
                     id name alias order_no is_allowto_delete is_default is_allowto_edit createdby createdhost createddate lastmodifiedby lastmodifiedhost lastmodifieddate
                    }
                }
        `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getLossReasonList);
                dispatch(setResponseDetails(actionTypes.LOSS_REASON_LIST, return_data));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }

    export const updateLossReasonOrder = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updateLossReasonOrder($orderno:[Float!]!, $reasonid:[Float!]!) {
                 updateLossReasonOrder(orderno:$orderno,reasonid:$reasonid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    orderno: request.orderListArray,
                    reasonid: request.reasonIds,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateLossReasonOrder.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateLossReasonOrder.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateLossReasonOrder.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const deleteLossReason = (client, request) => {
        return dispatch => {
            const query = gql`
             query deleteLossReason($reasonid:Float!) {
                 deleteLossReason(reasonid:$reasonid) {
                    status
                    message
                 }
               }
             `;
            client.query({
                query: query,
                variables: {
                    reasonid: request.reasonid,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.deleteLossReason.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.deleteLossReason.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.deleteLossReason.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                    dispatch(actionCreators.getLossReasonList(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }
    export const saveLossReason = (client, request) => {
        return dispatch => {
            const mutation = gql`
            mutation saveLossReason($reasonname: String!) {
                saveLossReason(reasonname:$reasonname) {
                    id
                }
            }
            `;
            client.mutate({
                mutation: mutation,
                variables: {
                    reasonname: request.name,
                }
            })
                .then(response => {
                    let returnData = [];
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Loss Reason Added Successfully."
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getLossReasonList(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }
    export const updateLossReasonDetail = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updateLossReasonDetail($reasonname: String!,$reasonid: Float!) {
                 updateLossReasonDetail(reasonname:$reasonname,reasonid:$reasonid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    reasonname: request.name,
                    reasonid: request.id,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateLossReasonDetail.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateLossReasonDetail.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateLossReasonDetail.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getLossReasonList(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }
    export const updateCompanyInfoGraphQl = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation UpdateCompanyInfo($detail: CompanyInfoInput!,$fileInput: Upload!) {
                 UpdateCompanyInfo(detail:$detail,fileInput:$fileInput) {
                    id
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    detail: request.detail,
                    fileInput: request.fileInput,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.UpdateCompanyInfo.id) {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': "data updated successfully."
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': "Unable to save data."
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                        }
                    }
                    let userData = JSON.parse(localStorage.getItem('user_data_session'))
                    let userprefparams = { userid: userData.id }
                    dispatch(actionCreators.getUserSetting(client,userprefparams))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const getCompanyRecList = (client) => {
        return function (dispatch) {
            const query = gql`
                    query getCompanyRecList
                    {
                        getCompanyRecList {
                            data
                        }
                    }
                `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache'
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getCompanyRecList);
                dispatch(setResponseDetails(actionTypes.GET_COMPANY_REC_LIST, return_data));
            }).catch((error) => {
                var returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': ""
                }
                dispatch(setResponseDetails(actionTypes.GET_COMPANY_REC_LIST, returnData));
            });
        }
    }
    export const getContactRecList = (client) => {
        return function (dispatch) {
            const query = gql`
                    query getContactList
                    {
                        getContactList {
                            data
                            totalrecord
                            totalpage
                        }
                    }
                `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache'
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getContactList);
                dispatch(setResponseDetails(actionTypes.GET_CONTACT_REC_LIST, return_data));
            }).catch((error) => {
                var returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': ""
                }
                dispatch(setResponseDetails(actionTypes.GET_CONTACT_REC_LIST, returnData));
            });
        }
    }
    export const getProjectList = (client) => {
        return function (dispatch) {
            const query = gql`
                    query getProjectList
                    {
                        getProjectList {
                            data
                            totalrecord
                            totalpage
                        }
                    }
                `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache'
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getProjectList);
                dispatch(setResponseDetails(actionTypes.GET_PROJECT_REC_LIST, return_data));
            }).catch((error) => {
                var returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': ""
                }
                dispatch(setResponseDetails(actionTypes.GET_PROJECT_REC_LIST, returnData));
            });
        }
    }
    export const getOpportunitiesList = (client) => {
        return function (dispatch) {
            const query = gql`
                    query getOpportunitiesList
                    {
                        getOpportunitiesList {
                            data
                            totalrecord
                            totalpage
                        }
                    }
                `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache'
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getOpportunitiesList);
                dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITY_REC_LIST, return_data));
            }).catch((error) => {
                var returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': ""
                }
                dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITY_REC_LIST, returnData));
            });
        }
    }
    export const getTaskList = (client) => {
        return function (dispatch) {
            const query = gql`
                    query getTaskList
                    {
                        getTaskList {
                            data
                            totalrecord
                            totalpage
                        }
                    }
                `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache'
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getTaskList);
                dispatch(setResponseDetails(actionTypes.GET_TASK_REC_LIST, return_data));
            }).catch((error) => {
                var returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': ""
                }
                dispatch(setResponseDetails(actionTypes.GET_TASK_REC_LIST, returnData));
            });
        }
    }
    export const getActivityTypes = (client) => {
        return function (dispatch) {
            const query = gql`
                    query getActivityTypes
                    {
                        getActivityTypes {
                            id
                            name
                            alias
                            icon
                            ininteractions
                            is_default
                            is_disable
                            createdby
                            createdhost
                            createddate
                            lastmodifiedby
                            lastmodifiedhost
                            lastmodifieddate
                        }
                    }
                `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache'
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getActivityTypes);
                // console.log("return_data", return_data);
                dispatch(setResponseDetails(actionTypes.MASTER_ACTIVITYTYPES_LIST, return_data));
            }).catch((error) => {
                var returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': ""
                }
                dispatch(setResponseDetails(actionTypes.MASTER_ACTIVITYTYPES_LIST, returnData));
            });
        }
    }

    export const saveActivityTypes = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation saveActivityTypes($icon: String!,$ininteractions: Float!,$activitytypename: String!) {
                 saveActivityTypes(icon:$icon,ininteractions:$ininteractions,activitytypename:$activitytypename) {
                    id
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    icon: request.icon,
                    ininteractions: request.ininteractions,
                    activitytypename: request.activitytypename,
                }
            })
                .then(response => {
                    let returnData = [];
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Activity Types Added Successfully."
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getActivityTypes(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const updateActivityTypesDetail = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updateActivityTypesDetail($isdisable: Boolean!, $icon: String!,$ininteractions: Float!,$activitytypename: String! ,$activitytypeid: Float!) {
                 updateActivityTypesDetail(isdisable:$isdisable,icon:$icon,ininteractions:$ininteractions,activitytypename:$activitytypename,activitytypeid:$activitytypeid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    isdisable:request.isdisable,
                    icon: request.icon,
                    ininteractions: request.ininteractions,
                    activitytypename: request.activitytypename,
                    activitytypeid: request.activitytypeid
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateActivityTypesDetail.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateActivityTypesDetail.message
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateActivityTypesDetail.message
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                        }
                    }
                    dispatch(actionCreators.getActivityTypes(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const getRelatedList = (client) => {
        return function (dispatch) {
            const query = gql`
                    query getRelatedList
                    {
                        getRelatedList {
                            lead
                            contact
                            company
                            opportunities
                            project
                            status
                            message
                        }
                    }
                `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache'
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getRelatedList);
                dispatch(setResponseDetails(actionTypes.GET_RELATEDLIST_LIST, return_data));
            }).catch((error) => {
                var returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': ""
                }
                dispatch(setResponseDetails(actionTypes.GET_RELATEDLIST_LIST, returnData));
            });
        }
    }


/**
* Type: GET
* Featch related master list
*/
export const getRelatedMasterList = (client, value) => {

    return function (dispatch) {
        const query = gql`
            query getRelatedMasterList($recordtypeid: Float!) 
            {
                getRelatedMasterList(recordtypeid: $recordtypeid) {
                    id
                    recordid
                    name
                    icon
                    alias
                    orderno
                    isactive
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getRelatedMasterList);
            
            if (value.recordtypeid == 1){
                dispatch(setResponseDetails(actionTypes.LEAD_RELATED_MASTER_LIST, return_data));
            }
            if(value.recordtypeid == 2){
                dispatch(setResponseDetails(actionTypes.CONTACT_RELATED_MASTER_LIST, return_data));
            }
            if (value.recordtypeid == 3) {
                dispatch(setResponseDetails(actionTypes.COMPANY_RELATED_MASTER_LIST, return_data));
            }
            if (value.recordtypeid == 4) {
                dispatch(setResponseDetails(actionTypes.OPPORTUNITY_RELATED_MASTER_LIST, return_data));
            }
            if (value.recordtypeid == 5) {
                dispatch(setResponseDetails(actionTypes.PROJECT_RELATED_MASTER_LIST, return_data));
            }
            
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


export const updateRelatedStatus = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateRelatedStatus($status: Boolean!, $relatedid: Float!) {
                 updateRelatedStatus(status:$status,relatedid:$relatedid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                status: request.status,
                relatedid: request.relatedid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateRelatedStatus.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateRelatedStatus.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getRelatedMasterList(client, {recordtypeid: request.recordtypeid}))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateRelatedStatus.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateRelatedOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateRelatedOrder($orderno:[Float!]!, $relatedid:[Float!]!, $recordtypeid: Float!,) {
                 updateRelatedOrder(orderno:$orderno,relatedid:$relatedid, ,recordtypeid:$recordtypeid) {
                    id
                    recordid
                    name
                    icon
                    alias
                    orderno
                    isactive
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                orderno: request.orderno,
                relatedid: request.relatedid,
                recordtypeid: request.recordtypeid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != null) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Related Object Order Updated Successfully"
                    }

                    dispatch(actionCreators.getRelatedMasterList(client, { recordtypeid: request.recordtypeid }))
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

/**
* Type: GET
* Featch related master list
*/
export const getEmailTemplateList = (client, value) => {

    return function (dispatch) {
        const query = gql`
            query getEmailTemplateList
            {
                getEmailTemplateList {
                    id
                    user{
                        id
                        name
                    }
                    name
                    subject
                    message
                    isshared
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getEmailTemplateList);
            
            dispatch(setResponseDetails(actionTypes.EMAIL_TEMPLATE_LIST, return_data));
            
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveEmailTemplate = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveEmailTemplate($isshared: Boolean!, $message: String!, $subject: String!, $name: String!, $messagehtml: String!, $subjecthtml: String!) {
                 saveEmailTemplate(isshared:$isshared, message:$message, subject:$subject, name:$name, messagehtml:$messagehtml, subjecthtml:$subjecthtml) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                isshared: request.isshared,
                message: request.message,
                subject: request.subject,
                name: request.name,
                messagehtml: request.messagehtml,
                subjecthtml: request.subjecthtml,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data.saveEmailTemplate.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.saveEmailTemplate.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getEmailTemplateList(client))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.saveEmailTemplate.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
                
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteEmailTemplate = (client, request) => {

    return dispatch => {
        const query = gql`
            query deleteEmailTemplate($templateid: Float!)                 
            {
                deleteEmailTemplate(templateid:$templateid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: { templateid: request.templateid },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteEmailTemplate.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteEmailTemplate.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getEmailTemplateList(client))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteEmailTemplate.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getEmailTemplateDetail = (client, value) => {

    return function (dispatch) {
        const query = gql`
            query getEmailTemplateDetail($templateid: Float!)
            {
                getEmailTemplateDetail(templateid:$templateid) {
                    id
                    user{
                        id
                        name
                    }
                    name
                    subject
                    message
                    isshared
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getEmailTemplateDetail);
            
            
            dispatch(setResponseDetails(actionTypes.EMAIL_TEMPLATE_DETAIL, return_data));
            console.log(return_data, 'return_data');
            return
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateEmailTemplate = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation updateEmailTemplate($isshared: Boolean!,$message: String!,$subject: String!,$name: String!, $messagehtml: String!, $subjecthtml: String!, $templateid: Float!) {
                updateEmailTemplate(isshared:$isshared,message:$message, subject:$subject, name:$name, templateid:$templateid, messagehtml:$messagehtml, subjecthtml:$subjecthtml) {
                status
                message
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                isshared: request.isshared,
                message: request.message,
                subject: request.subject,
                name: request.name,
                templateid: request.templateid,
                messagehtml: request.messagehtml,
                subjecthtml: request.subjecthtml,
            }
        })
        .then(response => {
            let returnData = [];
            if (response.data.updateEmailTemplate.status == "true") {
                returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': response.data.updateEmailTemplate.message
                }

                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                dispatch(actionCreators.getEmailTemplateList(client))
            } else {
                returnData = {
                    'data': {},
                    'error': true,
                    'notify_msg': response.data.updateEmailTemplate.message
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getOpportunitiesRelatedData = (client, req) => {

    return function (dispatch) {
        const query = gql`
            query getOpportunitiesRelatedData($id: Float!,$type: String!)
            {
                getOpportunitiesRelatedData(id:$id,type:$type) {
                    status
                    message
                    type
                    data
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                id:req.id,
                type:req.type,
            }
        }).then(response => {
            console.log("response", response);
            // let return_data = dataSet.processGQLData(response.data.getEmailTemplateDetail);
            
            
            // dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_COMPANY, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const getRecordRelatedData = (client, req) => {

    return function (dispatch) {
        const query = gql`
            query getRecordRelatedData($id: Float!,$type: String!,$recordtype: String!)
            {
                getRecordRelatedData(id:$id,type:$type,recordtype:$recordtype) {
                    status
                    message
                    type
                    data
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                id:req.id,
                type:req.type,
                recordtype:req.recordtype,
            }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getRecordRelatedData);
            if(req.type =="companies"){
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_COMPANY, return_data));
            }
            if(req.type =="contact"){
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_CONTACT, return_data));
            }
            if(req.type =="projects"){
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_PROJECT, return_data));
            }
            if(req.type =="opportunities"){
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_OPPORTUNITY, return_data));
            }
            if(req.type =="tasks"){
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_TASK, return_data));
            }

            if (req.type == "calendarevents") {
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_CALANDAR, return_data));
            }
            
            if(req.type =="files"){
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_FILE, return_data));
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const addProjectCompany = (client,req) => { 
    console.log("addProjectCompany", req);
    return dispatch => {
        const mutation = gql`
             mutation addProjectCompany($companydetail:JSON!, $projectid:Float!) {
                 addProjectCompany(companydetail:$companydetail,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                companydetail:req.companydetail,
                projectid:req.projectid,
            },
        })
        .then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addProjectCompany.length > 0) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': "unable to update data"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            var reqData = {
                id:req.projectid,
                type:'companies',
                recordtype:"project",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData))
            var reqData1 = {
                id:req.companydetail.id,
                type: "projects",
                recordtype:"company",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData1))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const deleteProjectCompany = (client,req) => { 
    return dispatch => {
        const mutation = gql`
             mutation deleteProjectCompany($companyid:Float!, $projectid:Float!) {
                 deleteProjectCompany(companyid:$companyid,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                companyid:req.companyid,
                projectid:req.projectid,
            },
        })
        .then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteProjectCompany) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                } 
                // else {
                //     returnData = {
                //         'data': {},
                //         'error': true,
                //         'notify_msg': "unable to update data"
                //     }
                //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                // }
            }
            var reqData = {
                id:req.projectid,
                type:'companies',
                recordtype:"project",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData))
            var reqData1 = {
                id:req.companyid,
                type: "projects",
                recordtype:"company",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData1))   
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const addProjectOpportunities = (client,req) => { 
    return dispatch => {
        const mutation = gql`
             mutation addProjectOpportunities($opportunitiesdetail:JSON!, $projectid:Float!) {
                 addProjectOpportunities(opportunitiesdetail:$opportunitiesdetail,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                opportunitiesdetail:req.opportunitiesdetail,
                projectid:req.projectid,
            },
        })
        .then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addProjectOpportunities.length > 0) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': "unable to update data"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            var reqData = {
                id:req.projectid,
                type:'opportunities',
                recordtype:"project",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData))
            var reqData1 = {
                id:req.opportunitiesdetail.id,
                type: "projects",
                recordtype:"opportunities",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData1))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const deleteProjectOpportunities = (client,req) => { 
    return dispatch => {
        const mutation = gql`
             mutation deleteProjectOpportunities($opportunitiesid:Float!, $projectid:Float!) {
                 deleteProjectOpportunities(opportunitiesid:$opportunitiesid,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                opportunitiesid:req.opportunitiesid,
                projectid:req.projectid,
            },
        })
        .then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteProjectOpportunities) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                } 
                // else {
                //     returnData = {
                //         'data': {},
                //         'error': true,
                //         'notify_msg': "unable to update data"
                //     }
                //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                // }
            }
            var reqData = {
                id:req.projectid,
                type:'opportunities',
                recordtype:"project",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData))
            var reqData1 = {
                id:req.opportunitiesid,
                type: "projects",
                recordtype:"opportunities",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData1))   
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const addProjectContact = (client,req) => { 
    return dispatch => {
        const mutation = gql`
             mutation addProjectContact($contactdetail:JSON!, $projectid:Float!) {
                 addProjectContact(contactdetail:$contactdetail,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                contactdetail:req.contactdetail,
                projectid:req.projectid,
            },
        })
        .then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addProjectContact.length > 0) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': "unable to update data"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            var reqData = {
                id:req.projectid,
                type:'contact',
                recordtype:"project",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData))
            var reqData1 = {
                id:req.contactdetail.id,
                type:'projects',
                recordtype:"contact",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData1))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const deleteProjectContact = (client,req) => { 
    return dispatch => {
        const mutation = gql`
             mutation deleteProjectContact($contactid:Float!, $projectid:Float!) {
                 deleteProjectContact(contactid:$contactid,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                contactid:req.contactid,
                projectid:req.projectid,
            },
        })
        .then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteProjectContact) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                } 
                // else {
                //     returnData = {
                //         'data': {},
                //         'error': true,
                //         'notify_msg': "unable to update data"
                //     }
                //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                // }
            }
            var reqData = {
                id:req.projectid,
                type:'contact',
                recordtype:"project",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData))

            var reqData1 = {
                id:req.contactid,
                type:'projects',
                recordtype:"contact",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData1))   
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getFreeContactList = (client, req) => {

    return function (dispatch) {
        const query = gql`
            query getFreeContactList($id: Float!,$type: String!,$recordtype: String!)
            {
                getFreeContactList(id:$id,type:$type,recordtype:$recordtype) {
                    status
                    message
                    type
                    data
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                id:req.id,
                type:req.type,
                recordtype:req.recordtype,
            }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getFreeContactList);
             
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const addNewCompanyContact = (client,req) => { 
    return dispatch => {
        const mutation = gql`
             mutation addNewCompanyContact($companyid:Float!, $contactid:Float!) {
                 addNewCompanyContact(companyid:$companyid,contactid:$contactid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                companyid:req.companyid,
                contactid:req.contactid,
            },
        })
        .then(response => {
            console.log("response", response);
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addNewCompanyContact.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': "unable to update data"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            var reqData = {
                id:req.companyid,
                type:'contact',
                recordtype:"company",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


export const deleteCompanyContact = (client, req) => {
    return function (dispatch) {
        const query = gql`
            query deleteCompanyContact($contactid: Float!)
            {
                deleteCompanyContact(contactid:$contactid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                contactid:req.contactid,
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteCompanyContact.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': "unable to update data"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            var reqData = {
                id:req.companyid,
                type:'contact',
                recordtype:"company",
            }
            dispatch(actionCreators.getRecordRelatedData(client,reqData))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}



export const generateMailBoxId = (client) => {
    return function (dispatch) {
        const query = gql`
                    query generateMailBoxId
                    {
                        generateMailBoxId {
                            mailboxid
                        }
                    }
                `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.generateMailBoxId);
            dispatch(setResponseDetails(actionTypes.GET_GENERATED_MAIL_ID, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_GENERATED_MAIL_ID, returnData));
        });
    }
}

export const getSuggestionData = (client, value) => {

    return function (dispatch) {
        const query = gql`
            query getSuggestionData($name: String!)
            {
                getSuggestionData(name:$name) {
                    id
                    name
                    email
                    emaillist_id_id
                    email_type
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getSuggestionData);
            dispatch(setResponseDetails(actionTypes.GMAIL_SUGGESTION_NAMES, return_data));            
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveRelatedFiles = (client,req) => { 
    console.log("saveRelatedFiles", req);
    return dispatch => {
        const mutation = gql`
             mutation saveRelatedFiles($recordid:Float!,$recortypedid:Float!,$fileInput:Upload!) {
                 saveRelatedFiles(recordid:$recordid,recortypedid:$recortypedid,fileInput:$fileInput) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                recordid:req.recordid,
                recortypedid:req.recortypedid,
                fileInput:req.fileInput,
            },
        })
        .then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.saveRelatedFiles.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': "unable to update data"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getRecordRelatedData(client,req.getParams))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const deleteRelatedFiles = (client, req) => {
    return function (dispatch) {
        const query = gql`
            query deleteRelatedFiles($fileid: Float!)
            {
                deleteRelatedFiles(fileid:$fileid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                fileid:req.fileid,
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteRelatedFiles.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));                        
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': "unable to update data"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getRecordRelatedData(client,req.getParams)) 
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getProjectStage = (client) => {
        return dispatch => {
            const query = gql`query getProjectStage {
                    getProjectStage{
                        id
                        name
                        alias
                        order_no
                        is_allowto_delete
                        is_allowto_edit
                        createdby
                        createdhost
                        createddate
                        lastmodifiedby
                        lastmodifiedhost
                        lastmodifieddate
                    }
                }
            `;
            client.query({
                query: query,
                fetchPolicy: 'no-cache',
            }).then(response => {
                let return_data = dataSet.processGQLData(response.data.getProjectStage);
                dispatch(setResponseDetails(actionTypes.PROJECT_STAGE_LIST, return_data));
            }).catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
        }
    }


    export const saveProjectStage = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation saveProjectStage($stagename: String!) {
                 saveProjectStage(stagename:$stagename) {
                    id
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    stagename: request.name,
                }
            })
                .then(response => {
                    let returnData = [];
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Project Stage Added Successfully."
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getProjectStage(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }
    export const updateProjectStageDetail = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updateProjectStageDetail($stagename: String!,$stageid: Float!) {
                 updateProjectStageDetail(stagename:$stagename,stageid:$stageid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    stagename: request.name,
                    stageid: request.id,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateProjectStageDetail.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateProjectStageDetail.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateProjectStageDetail.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getProjectStage(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const deleteProjectStage = (client, request) => {
        return dispatch => {
            const query = gql`
             query deleteProjectStage($stageid:Float!) {
                 deleteProjectStage(stageid:$stageid) {
                    status
                    message
                 }
               }
             `;
            client.query({
                query: query,
                variables: {
                    stageid: request.stageid,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.deleteProjectStage.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.deleteProjectStage.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.deleteProjectStage.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                    dispatch(actionCreators.getProjectStage(client))
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

    export const updateProjectStageOrder = (client, request) => {
        return dispatch => {
            const mutation = gql`
             mutation updateProjectStageOrder($orderno:[Float!]!, $stageid:[Float!]!) {
                 updateProjectStageOrder(orderno:$orderno,stageid:$stageid) {
                    status
                    message
                 }
               }
             `;
            client.mutate({
                mutation: mutation,
                variables: {
                    orderno: request.orderno,
                    stageid: request.stageid,
                }
            })
                .then(response => {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.updateProjectStageOrder.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.updateProjectStageOrder.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.updateProjectStageOrder.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                }).catch((error) => {
                    let err_data = errorMessageGraphQl(error);
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
                });
        }
    }

export const addLogPined = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addLogPined($ispined: Boolean!,$logid: Float!,$recordid: Float!,$recordtypeid: Float!)  {
                addLogPined(ispined: $ispined,logid: $logid,recordid: $recordid,recordtypeid: $recordtypeid) {
                    status
                    message
                }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                ispined: request.ispined,
                logid: request.logid,
                recordid: request.recordid,
                recordtypeid: request.recordtypeid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data.addLogPined.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.addLogPined.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.addLogPined.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
                if (request.recordtypeid == 1){
                    let logparam = { leadid: request.recordid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid }
                    dispatch(actionCreators.getLeadActivityLogList(client, logparam))
                    dispatch(actionCreators.getLeadLogListNew(client, logparam))
                    dispatch(actionCreators.getLeadField(client, request.recordid))
                }
                if (request.recordtypeid == 2) {
                    let logParams = { contactid: request.recordid, emailid: request.emailid, activitypeid: request.activitypeid, logtypeid: request.logtypeid }
                    dispatch(actionCreators.getContactActivityLogList(client, logParams))
                    dispatch(actionCreators.getContactLogListNew(client, logParams))
                    dispatch(actionCreators.getContactField(client, request.recordid))
                }
                if (request.recordtypeid == 3) {
                    let logparam = { companyid: request.recordid, emailid: "", logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                    dispatch(actionCreators.getCompanyRecLogListNew(client, logparam));
                    dispatch(actionCreators.getCompanyRecActivityLogList(client, logparam));
                    dispatch(actionCreators.getCompanyRecField(client, request.recordid));
                }
                if (request.recordtypeid == 4) {
                    let getlog = { opportunitiesid: request.recordid, activitypeid: request.activitypeid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getOpportunitiesLogListNew(client, getlog));
                    dispatch(actionCreators.getOpportunitiesActivityLogList(client, getlog))
                    dispatch(actionCreators.getOpportunitiesField(client, request.recordid))
                }
                if (request.recordtypeid == 5) {
                    let logparam = { projectid: request.recordid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                    dispatch(actionCreators.getProjectActivityLogList(client, logparam))
                    dispatch(actionCreators.getProjectLogListNew(client, logparam))
                    dispatch(actionCreators.getProjectField(client, request.recordid))
                }
                if (request.recordtypeid == 6) {
                    let logparam = { taskid: request.recordid, logtypeid: [] };
                    dispatch(actionCreators.getTaskActivityLogList(client, logparam))
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}