import React, { Component } from "react";
import {
    IconButton, Icon, InputLabel, Grid, Button, List, ListItem,
    ListItemText, ListItemIcon, ListItemSecondaryAction, FormControl, Select, MenuItem, Card, Typography,
    Dialog, DialogActions, DialogTitle, Link, Avatar, Checkbox, FormControlLabel
} from "@material-ui/core";
import "date-fns";
import axios from 'axios';
import * as actionCreators from '../../store/actions/index';
import { connect } from "react-redux";
//import Image from '../../static/images/default.jpg';

class MyPreferences extends Component {

    state = {
        settingObject:"",
        userSettingArray: [],
        timezoneList: [],
        companyCurrencyData: "",
        imagePreview:"",
    };

    componentDidMount() {
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let val = { userid: userData.id }
        this.props.getUserSetting(this.props.apolloClient.client, val);
        this.props.getTimezoneList(this.props.apolloClient.client);
        this.props.getCompanyCurrency(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if(props.settingReducer.timezoneList.data && this.state.timezoneList.length == 0) {
            const data = props.settingReducer.timezoneList.data;
            this.setState({
                timezoneList:data,
            })
        }
        if(props.settingReducer.userSettingArray.data) {
            const data = props.settingReducer.userSettingArray.data;
            this.setState({
                settingObject:data,
                imagePreview:data.profileimage?data.profileimage:"",
            },()=> {
                this.forceUpdate()
            })
        }else{
            let userData = JSON.parse(localStorage.getItem('user_data_session'))
            let val = { userid: userData.id }
            this.setState({
                settingObject:{
                    currencyid:149,
                    taskvisible:false,
                    setreminders:false,
                    remindersday:0,
                    userid:userData.id,
                    dateformat: "DD/MM/YYYY",
                    timezone: "America/New_York"
                }
            },()=> {
                this.forceUpdate()
            })
        }
        if(props.settingReducer.companyCurrencyData.data && this.state.companyCurrencyData.length == 0) {
            const data = props.settingReducer.companyCurrencyData.data;
            this.setState({
                companyCurrencyData:data,
            })
        }
    }

    handleChange = event => {
        this.setState({
            settingObject:{
                ...this.state.settingObject,
                [event.target.name]:event.target.value
            }
        },()=>this.forceUpdate());
    };
    handleChangeCheckBox = (data,key) => {
        this.setState({
            settingObject:{
                ...this.state.settingObject,
                [key]:data
            }
        },()=>this.forceUpdate());
    };

    fileChangedHandler = (event) => {
        if(event.target.files[0] == undefined){
            return false
        }
        this.setState({ 
            selectedFile: event.target.files[0],
            imagePreview: URL.createObjectURL(event.target.files[0])
        })
    }
    removeProfileImage = () => {
        console.log("imagePreview");
        this.setState({ 
            selectedFile: "",
            imagePreview: ""
        })
    }

    saveUserSettings = () => {
        // var data = new FormData()
        // data.append('file', this.state.selectedFile);
        var isdeleted = 0;
        if(this.state.selectedFile == ""){
            isdeleted = 1;
        }

        var dataobj ={
            timezone:this.state.settingObject.timezone,
            dateformat:this.state.settingObject.dateformat,
            currencyid:this.state.settingObject.currencyid,
            taskvisible:this.state.settingObject.taskvisible,
            setreminders:this.state.settingObject.setreminders,
            remindersday:this.state.settingObject.remindersday,
            userid:this.state.settingObject.userid,
            isdeleted:isdeleted,
        }

        var file = ""
        if(this.state.selectedFile != undefined){
            file = this.state.selectedFile;
        }
        const send_data = {
            setting :  dataobj,
            fileInput : file
        }
        
        //console.log("send_data", send_data);return
        this.props.saveUserSetting(this.props.apolloClient.client, send_data);
    }

    render() {
        return (
            <div style={{ overflow: 'auto', height:'100%' }}>
                <Typography variant="h5" color="inherit" className="top_typograpy">My Preferences</Typography>
                <div style={{ marginBottom: '20px' }}>
                    <Typography className="Typography" variant="p">Manage your personal user settings</Typography>
                </div>
                <Card className="signup-card">
                    <div className="lead_upcoming">
                        <div className="box_portlet">
                            <div className="outer_div">
                                <Typography className="Typography" variant="p">Profile Picture</Typography>
                                <div className={"profile_picture_div"}>
                                    <div style={{ marginTop: '10px' }}>
                                        <Avatar className="Avatar" alt="Profile Picture" src={this.state.imagePreview} />
                                        
                                        <div className="link_div">
                                            <Link onClick={()=>this.removeProfileImage()} style={{color:'#2B41B2',cursor:'pointer'}}>Remove profile picture</Link>
                                        </div>
                                    </div>
                                    <div>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="contained-button-file"
                                            multiple
                                            type="file"
                                            onChange={this.fileChangedHandler}
                                        />
                                        <label htmlFor="contained-button-file">
                                            <Button
                                                className="UploadButton"
                                                color="primary"
                                                variant="contained"
                                                component="span">
                                                CHOOSE A FILE
                                            </Button>
                                        </label>
                                    </div>
                                </div>
                                <div className="dropdown_div">
                                    <Grid item sm={12} xs={12}>
                                        <InputLabel className="input_label">Time Zone</InputLabel>
                                        <FormControl className="w-100 input_bx" style={{ marginTop: '5px' }}>
                                            <Select
                                                name="timezone"
                                                onChange={(event) => this.handleChange(event)}
                                                value={(this.state.settingObject && this.state.settingObject.timezone)?this.state.settingObject.timezone:""}
                                            >
                                                {this.state.timezoneList && this.state.timezoneList.map((item, key) =>
                                                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.name}>{item.name}</MenuItem>
                                                )}
                                                
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12} className="grid_spacing">
                                        <InputLabel className="input_label">Date Format</InputLabel>
                                        <FormControl className="w-100 input_bx" style={{ marginTop: '5px' }}>
                                            <Select
                                                name="dateformat"
                                                onChange={(event) => this.handleChange(event)}
                                                value={(this.state.settingObject && this.state.settingObject.dateformat)?this.state.settingObject.dateformat:""}
                                            >
                                                <MenuItem className="MenuItem" value={'MM/DD/YYYY'}>MM/DD/YYYY</MenuItem>
                                                <MenuItem className="MenuItem" value={'DD/MM/YYYY'}>DD/MM/YYYY</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12} className="grid_spacing">
                                        <InputLabel className="input_label">Default Currency</InputLabel>
                                        <FormControl className="w-100 input_bx" style={{ marginTop: '5px' }}>
                                            <Select
                                                name="currencyid"
                                                //onChange={(event) => this.handleChange(event)}
                                                value={this.state.companyCurrencyData?this.state.companyCurrencyData[0].detail.id:""}
                                            >
                                                <MenuItem className="MenuItem" value={this.state.companyCurrencyData?this.state.companyCurrencyData[0].detail.id:""}>{this.state.companyCurrencyData?this.state.companyCurrencyData[0].detail.name:""}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </div>
                                <Grid item sm={12} xs={12} className="tasks_div">
                                    <InputLabel className="input_label">Tasks</InputLabel>
                                    <Grid item sm={12} xs={12} className="grid_spacing">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name={'taskvisible'}
                                              id={'taskvisible'}
                                              onChange={(event,value) => this.handleChangeCheckBox(value,'taskvisible')}
                                              value={(this.state.settingObject != "")?this.state.settingObject.taskvisible:false}
                                              checked={(this.state.settingObject != "")?this.state.settingObject.taskvisible:false}
                                              color="primary"
                                            />
                                          }
                                          label={<span className="checkbox_labels">Make my tasks visible to others by default</span>}
                                        />
                                        
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{marginTop:'-7px'}}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name={'setreminders'}
                                              id={'setreminders'}
                                              onChange={(event,value) => this.handleChangeCheckBox(value,'setreminders')}
                                              value={(this.state.settingObject != "")?this.state.settingObject.setreminders:false}
                                              checked={(this.state.settingObject != "")?this.state.settingObject.setreminders:false}
                                              color="primary"
                                            />
                                          }
                                          label={<span className="checkbox_labels">Automatically set reminders on tasks with due dates</span>}
                                        />
                                        
                                    </Grid>
                                </Grid>
                                <Button variant="contained" color="primary" onClick={this.saveUserSettings}>Save</Button>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
});

const mapDispatchToProps = dispatch => {
    return {
        saveUserSetting: (client, data) => dispatch(actionCreators.saveUserSetting(client, data)),
        getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
        getTimezoneList: (client) => dispatch(actionCreators.getTimezoneList(client)),
        getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    };
};

export default (connect(mapStateToProps, mapDispatchToProps)(MyPreferences));
