import React, { Component } from "react";
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, IconButton, Icon, Menu, MenuItem, Grid } from "@material-ui/core";

class HiddenColumns extends Component {
    state = {
        commentid: '',
        Commentoptions: '',
        showCommentoptions: false,
        columns:[],
        hiddencolumns:[],
    };
    componentDidMount(){
        if (this.props.columns)
        {
            this.setState({ columns: this.props.columns })
        }
        if (this.props.allData.state.defaultHiddenColumnNames)
        {
            this.setState({ hiddencolumns: this.props.allData.state.defaultHiddenColumnNames })
        }
    }
    componentWillReceiveProps(props){
        console.log(props.columns,' columns');
        
    }
    handleClick = (event) => {
        this.setState({ Commentoptions: event.target, showCommentoptions: true });
    }
    handleCloseOptions = (event) => {
        this.setState({ Commentoptions: null, showCommentoptions: false });
    }
    handleMenuClick = (e, value) => {
        let hiddens = this.state.hiddencolumns;
        var promise = new Promise((resolve, reject) => {
            if (e.target.checked == true) {
                let key = hiddens.indexOf(value);
                if (key != -1) {
                    hiddens.splice(key, 1);
                }
            }
            if (e.target.checked == false) {
                if (hiddens.indexOf(value) == -1) {
                    hiddens.push(value);
                }
            }
            resolve(1)
        })

        promise.then(result => {
            this.setState({ hiddencolumns: hiddens })
            this.props.allData.setState({ defaultHiddenColumnNames: hiddens });
            this.props.allData.handleHiddensMain(hiddens);
        });
    }

    render() {
        let { columns, hiddencolumns } = this.state;
        
        
        return (
            <Grid item className="ml-auto">
                <IconButton size="small"
                    aria-label="More"
                    aria-owns={this.state.showCommentoptions ? "long-menu" : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    className="button_size"
                >
                    <Icon>visibility_off</Icon>
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.Commentoptions}
                    open={this.state.showCommentoptions}
                    onClose={this.handleCloseOptions}
                    PaperProps={{
                        // style: {
                        //     width: 200
                        // }
                    }}
                >
                    {(columns && columns.length > 0) && columns.map((column)=>(
                        <MenuItem className="m-0 pt-0 pb-0" disabled={column.name == "name" ? true : false}>
                            <FormControlLabel
                                value={column.name}
                                control={
                                    <Checkbox 
                                        color={"secondary"}
                                        disabled={column.name=="name" ? true : false}
                                        // className={"crm_log_checkbox_filter "}
                                        name={column.name}
                                        id={column.name}
                                        checked={(hiddencolumns.indexOf(column.name) == -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, column.name)}
                                        value={column.name}
                                        
                                    />
                                }
                                label={column.title}
                                labelPlacement="end"
                            />
                        </MenuItem>
                    ))}
                    
                </Menu>
            </Grid>
        )
    }
}

export default HiddenColumns;
