import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, 
  FormControl, Select, InputLabel, Menu, MenuItem, List, 
  ListItem, ListItemText, DialogActions, 
  Chip, TextField ,CircularProgress} from "@material-ui/core";
import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { createDynamicFields } from './CreateFieldsDynamic'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

class CreateNewProjectDialog extends Component {
  state = {
    lastname: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray:[],
    companyCurrencyData:[],
    focusValue:false,
    fieldsList: [],
    fieldsObj: {},
    tags: [],
    systemTagsList: [],
    usersListArray:[],
    selectedDate: new Date(),
    userSettings:"",
    isDataLoading:true,
    relatedListArray: [],
    isAddedFromSidebar: false,

  };

  componentWillMount() {
    this.props.getRelatedList(this.props.apolloClient.client);
    let val = { typeId: 5 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
  }

  componentWillReceiveProps(props) {
    
    if (props.settingReducer.relatedListArray.data) {
      var selectedObj = [];
      props.settingReducer.relatedListArray.is_loaded = false
      const relatedData = props.settingReducer.relatedListArray.data;
      var NewArray = []
      // NewArray = NewArray.concat(relatedData.lead); 
      NewArray = NewArray.concat(relatedData.contact); 
      NewArray = NewArray.concat(relatedData.company); 
      NewArray = NewArray.concat(relatedData.opportunities);
      // NewArray = NewArray.concat(relatedData.project); 
      this.setState({ relatedListArray: NewArray }, () => {
        if (props.dataFromSidebar) {
          let fieldsObj = this.state.fieldsObj;

          selectedObj = this.state.relatedListArray.filter(function (element) {
            if (element.id == props.dataFromSidebar.id && element.type == props.dataFromSidebar.recordtype) {
              return element
            }
          });
          if (selectedObj.length > 0) {
            
            this.setState({
              fieldsObj: {
                ...this.state.fieldsObj,
                ["relatedto"]: { fieldid: "68", values: selectedObj[0] },
                ["name"]: { fieldid: "67", projectvalue: this.props.name }
              },
              isAddedFromSidebar: true,
              sidebarParams: props.dataFromSidebar,
            })
          }
        }
      })
       
    }

    //storing user setting data in state
    if(props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
        const settingData = props.settingReducer.userSettingArray.data;
        this.setState({
            userSettings:settingData,
        })
    }

    if (props.settingReducer.projectCustomFieldList.data && props.settingReducer.projectCustomFieldList.is_loaded) {
      console.log("props.settingReducer.projectCustomFieldList", props.settingReducer.projectCustomFieldList);
      props.settingReducer.projectCustomFieldList.is_loaded = false;
      const customFields = props.settingReducer.projectCustomFieldList.data;
      this.setState({ fieldsList: customFields,isDataLoading:false})
      var statuskey = this.findWithAttr(customFields, 'id', "70");
      var defaultKeyStatus = this.findWithAttr(customFields[statuskey].options, 'is_default', true);


      var stagekey = this.findWithAttr(customFields, 'id', "75");
      var defaultKeyStage = this.findWithAttr(customFields[stagekey].options, 'id', 1);
      
      //setting selected date in date picker
      customFields.map((v, i) => {
          if(v.fieldtype.id === "8"){
            var data = {
              fieldid:v.id,
              values:this.state.selectedDate
            }
            this.setState({
              fieldsObj: {
                ...this.state.fieldsObj,
                [v.fieldkey]: data
              }
            })
          }
      })

      //setting default object for status
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["status"]: {fieldid: "70",values:customFields[statuskey].options[defaultKeyStatus]},
          ["stage"]: {fieldid: "75",values:customFields[stagekey].options[defaultKeyStage]}
        }
      })
      
    }
    if (props.settingReducer.countryListGql.data) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      console.log("usersListArray", usersListArray);
      this.setState({ usersListArray: usersListArray })
      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["owner"]: { fieldid: "69", values:usersListArray[companyOwnerKey]}
        }
      })
    }
    if(props.notification && props.notification.status === true){
        // props.handleClose();
        props.clearNotification();
    }

    if (props.settingReducer.systemTagsList.data) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList : tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null) {
        var cData = props.settingReducer.companyCurrencyData.data;
        this.setState({companyCurrencyData:cData[0]});
    }
  }

  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    }, () => this.forceUpdate())
  }

  handleNormalFields = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCountryChange = (event, values) => {
    this.setState({country: values});
  }

  focusInValue = () => {
    this.setState({ focusValue: true })
  };

  focusOutValue = () => {
    this.setState({ focusValue: false })
  };
  
  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name) => {
    console.log("name", name);
    console.log("val", val);
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if(key != -1 && this.state.fieldsList[key].fieldtype.id != "4"  && this.state.fieldsList[key].fieldtype.id != "8"){
      if(this[name]){
        this[name].validate(val.value);
      }
    }
    //return
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      }
    })

  };

  handleFormSubmit = () => {
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
    submitData = Object.values(submitData).map((row)=>{
      if (row.fieldid == "68") {//realated  field
        if(row.values == null){
          return false
        }
        row.values = [{ srno: "1", id: row.values.id, value: row.values.name,type: row.values.type }]
      }
      
      if(row.fieldid === "72" || row.fieldid === "70" ||  row.fieldid === "75"){
        row.values = [{srno:"1", id:row.values.id,value:row.values.name}]
      }
      var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
      if(this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false){
        row.values = [{srno:"1",id:row.values.id,value:row.values.optionvalue}]
      }
      if(this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false){
        if(row.values.length >0){
          var ar = [];
          row.values.map((val,i)=>{
            ar.push({srno:(i+1).toString(),id:val.id, value:val.optionvalue})
          })
        }
        row.values = ar
      }
      
      //validating date fiels
      if(this.state.fieldsList[key].fieldtype.id == "8"){//date field
          console.log("row.values", row.values);
        if(this.state.fieldsList[key].recordcreationdefaultvalue == 2 && row.values == null){
          return false
        }else if(this.state.fieldsList[key].recordcreationdefaultvalue != 2 && row.values == null){
          row.projectvalue = "";
          delete row.values;
        }else if(row.values == "Invalid Date"){
          return false
        }else{
          /*let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          row.projectvalue = moment.utc(logdate).format('X');*/
          // row.projectvalue = moment(row.values).format("X");
          var dt = moment(row.values).format('YYYY-MM-DD')
          row.projectvalue = dt;
          delete row.values;
        }
      }

      if(row.fieldid == "74"){
        if(row.values.length > 0){
          var objArr = [];
          row.values.map((val,k)=>{
            var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
            var tagId = "0";
            if(foundKey !== -1){
              tagId = this.state.systemTagsList[foundKey].id
            }
            console.log("val", val);
            objArr.push({"srno":(k+1).toString(),value:val.toLowerCase(),id:tagId})
          })  
          row.values = objArr  
        }
      }

      
      if(row.fieldid == "69"){//owner value set
        if(row.values){
          row.values = [{"srno":"1",id:row.values.id,value:row.values.email,name:row.values.name}];
        }else{
          row.values = [];
        }
        delete row.projectvalue;
      }
      return row;
    })

    
    //not to submit if any field is false
    var allowToSubmit = true
    submitData.map((row)=>{
      if(!row){
        allowToSubmit = false
      }
    })
    console.log("submitData", submitData);
    console.log("allowToSubmit", allowToSubmit);
    // return
    if(allowToSubmit){
      let req = {
        projectdata:submitData,
        sidebarParams: this.state.sidebarParams,
      }
        
      this.props.handleClose();
      this.props.saveProjectDetail(this.props.apolloClient.client,req);
    }
  };

  handleDateChange = date => {
      console.log("date", date);
      this.setState({ selectedDate: date });
  };

  arrDifference = (arr1, arr2) =>{
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
       if(arr2.indexOf(arr1[i]) === -1)
       arr.push(arr1[i]);
    }
    // for array2
    for(i in arr2) {
       if(arr1.indexOf(arr2[i]) === -1)
       arr.push(arr2[i]);
    }
    return arr.sort((x,y) => x-y);
  }

  findWithAttr = (array, attr, value) => {
      for(var i = 0; i < array.length; i += 1) {
          if(array[i][attr] === value) {
              return i;
          }
      }
      return -1;
  }
  handleBlur = (event,ref) => {
      if(this[ref].props.id == "tagInputId1"){
        setTimeout(() => {
          document.getElementById("tagInputId1").value = "";
        },10)
      }
      this[ref].validate(event.target.value);
  }
  render() {    
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Add a New Project</h4>
          <div className="d-flex">
            <Tooltip title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <ValidatorForm noValidate  instantValidate={false}  onSubmit={this.handleFormSubmit}>
          <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`}>
            <Grid container spacing={2}>
              {this.state.isDataLoading && <CircularProgress size={25} className="loaderPopupCenter"/>}
              {!this.state.isDataLoading && createDynamicFields(this)}
            </Grid>
          </div>
           
          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button disabled={this.state.isDataLoading} variant="contained" type="submit" color="primary">Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});

const mapDispatchToProps = dispatch => {
  return {
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    saveProjectDetail: (client, request) => { dispatch(actionCreators.saveProjectDetail(client, request)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
    getRelatedList: (client) => dispatch(actionCreators.getRelatedList(client)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewProjectDialog);
