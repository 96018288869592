import React, { Component } from "react";
import TableComponent from './TableComponent';
// import TaskCreationRule from './TaskCreationRule'
import { Icon, Switch, FormControlLabel, IconButton, withStyles, Button, Card, Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, FormHelperText, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'
// import CreateNewFieldTaskDialog from "./CreateNewFieldTaskDialog";
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class RelatedSettingLead extends Component {

    constructor(props) {
        super(props);

        this.state = {
            leaddata: [],
            leadFieldIds: [],
            leadOrderIds: [],
            activedatas: [],
            inactivedatas: [],
            shouldOpenCreateFieldTaskDialog: false,
            updatelistorder: true,
            mailboxid:"",
        };
    }

    

    componentDidMount() {
        let val = { recordtypeid: 1 }
        this.props.getRelatedMasterList(this.props.apolloClient.client, val);

        let userdata = JSON.parse(localStorage.getItem('user_data_session'));
        const mailboxid = userdata.mailboxid ? userdata.mailboxid : "";
        this.setState({ mailboxid: mailboxid });
        // this.props.customFieldTypeList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        
        if (props.settingReducer.generatedmailboxid.data)
        {
            let mailboxid = props.settingReducer.generatedmailboxid.data.mailboxid;  
            this.setState({ mailboxid });
        }
    }

    closePopUp = () => {
        this.setState({ shouldOpenCreateFieldTaskDialog: false });
    }
    handleUpdateStatus = (id, status) =>{
        let val = {
            status: status,
            relatedid: parseFloat(id),
            recordtypeid:1,
        }

        this.props.updateRelatedStatus(this.props.apolloClient.client, val);
    }

    updateFieldsOrder = (fromIndex, toIndex) => {

        let leadFieldIds = [];

        const leaddata = this.state.activedatas;
        const item = leaddata.splice(fromIndex, 1)[0];

        leaddata.splice(toIndex, 0, item);

        this.setState({
            leaddata: leaddata,
            updatelistorder:false,
        })
        const leadOrderIds = this.state.leadOrderIds;
        leaddata.map((value, index) => {
            leadFieldIds.push(parseFloat(value.key));
        });

        let val1 = {
            orderno: leadOrderIds,
            relatedid: leadFieldIds,
            recordtypeid: 1,
        }
        this.props.updateRelatedOrder(this.props.apolloClient.client, val1);
    }
    handleTaskDialogClose = () => {
        this.setState({ shouldOpenCreateFieldTaskDialog: false });
    };
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    generateMailbox = () => {
        this.props.generateMailBoxId(this.props.apolloClient.client);
    }
    render() {
        let { shouldOpenCreateFieldTaskDialog } = this.state;
        
        return (
            <>
                <div className="font-size-13 mb-20">Manage how IDrive CRM will interact with your email.</div>
                
                <Card className="signup-card">
                    <Grid item xs={7} style={{ padding: '20px' }}>
                        <FormControl className="w-100 input_bx">
                            <InputLabel className="font-size-13">Email Preferances</InputLabel>
                            <Select
                                className="font-size-13"
                                name="default_selected_lead_status"
                                value={this.state.defaultLeadStatusSelected}
                                onChange={this.handleChangeLeadStatusDropDown}
                            >
                                <MenuItem key="" value="email_gmail">Email links open in Gmail</MenuItem>
                                <MenuItem key="" value="email_client">Email links open in my default email client</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            className="font-size-12"
                            control={<Checkbox color="primary" onChange={this.handleChange} checked={this.state.Preference} name="default" />}
                            label={<span style={{ fontSize: "13px" }}>Mark synced emails public by default</span>}
                        />
                        <FormHelperText className="ml-30 mt-0">Changes to this setting do not affect the visibility of previously synced emails. Admins can always see all synced emails.</FormHelperText>
                        <FormControlLabel
                            className="font-size-12"
                            control={<Checkbox color="primary" onChange={this.handleChange} checked={this.state.Preference} name="bbc" />}
                            label={<span style={{ fontSize: "13px" }}>Always include my IDrive CRM mailbox address in the BCC field</span>}
                        />
                        <FormHelperText className="ml-30 mt-0">Required to sync emails if your Google Sync Settings do not allow Gmail access.</FormHelperText>
                    </Grid>
                </Card>
                <h5 className="mt-20 mb-20">Admin Private Email Visibility (Account Owner)</h5>
                <Card className="signup-card">
                    <Grid item xs={7} style={{ padding: '20px' }}>
                        
                        <FormControlLabel
                            control={<Checkbox color="primary" onChange={this.handleChange} checked={this.state.Preference} name="default" />}
                            label={<span style={{ fontSize: "13px" }}>Allow Admins to see emails marked as private by any user</span>}
                        />
                        <FormHelperText className="ml-30 mt-0">All emails are visibile to admins. The account owner can choose to disable this so emails marked as private are not visibile to any admins.</FormHelperText>
                    </Grid>
                </Card>
                <h5 className="mt-20 mb-20">Individual Email Visibility Control (Admin)</h5>
                <Card className="signup-card">
                    <Grid item xs={7} style={{ padding: '20px' }}>
                        <FormControl>
                            <h5 className="font-size-14">Allow users within your company to choose the default visibility for their synced emails</h5>
                            <FormHelperText>Individual email visibility can always be changed by the owner of the email conversation.</FormHelperText>
                            <RadioGroup name="visibility_control" value={this.state.visibility_control} onChange={this.handleChange}>
                                <FormControlLabel value="yes" control={<Radio color="primary" />} label={<span style={{ fontSize: "13px" }}>Yes, users may specify their own default visibility (private by default)</span>} />
                                <FormControlLabel value="no" control={<Radio color="primary" />} label={<span style={{ fontSize: "13px" }}>No (always private by default)</span>} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Card>
                <h5 className="mt-20 mb-20">IDrive CRM Mailbox</h5>
                <Card className="signup-card">
                    <Grid item xs={12} style={{ padding: '20px' }}>
                        <FormControl>
                            <h5 className="font-size-14">IDrive CRM mailbox address:</h5>
                            <FormHelperText>Automatic email syncing requires your Google Sync Settings to allow Gmail access. If that access is disabled or you do not use Gmail as your email provider, include this email address in the BCC line of any email to sync it with IDrive CRM.</FormHelperText>
                            <br />
                            <span className="font-size-14 font-weight-500">{this.state.mailboxid+"@inbox.kanhacrm.xyz"} <a href="#" className="ml-20" onClick={this.generateMailbox} style={{ color:"rgba(0, 0, 0, 0.54)"}}> Generate new mailbox address</a></span>
                            
                            <FormControlLabel
                                control={<Checkbox color="primary" onChange={this.handleChange} checked={this.state.Preference} name="default" />}
                                label={<span style={{ fontSize: "13px" }}>Add CC’d contacts to IDrive CRM</span>}
                            />
                            <FormHelperText className="ml-30 mt-0">All emails sent via web app or the mobile apps will be automatically tracked. You can choose which emails sent via Gmail or Inbox can be tracked.</FormHelperText>
                        </FormControl>
                    </Grid>
                </Card>
                <h5 className="mt-20 mb-20">Email Open Tracking</h5>
                <Card className="signup-card">
                    <Grid item xs={7} style={{ padding: '20px' }}>
                        <FormControlLabel
                            control={<Checkbox color="primary" onChange={this.handleChange} checked={this.state.Preference} name="default" />}
                            label={<span style={{ fontSize: "13px" }}>Track when emails are opened by default</span>}
                        />
                        <FormHelperText className="ml-30 mt-0">All emails sent via web app or the mobile apps will be automatically tracked. You can choose which emails sent via Gmail or Inbox can be tracked.</FormHelperText>
                    </Grid>
                </Card>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        generateMailBoxId: (client) => { dispatch(actions.generateMailBoxId(client)) },
        getRelatedMasterList: (client, request) => { dispatch(actions.getRelatedMasterList(client, request)) },
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        customFieldTypeList: (client) => { dispatch(actions.customFieldTypeList(client)) },
        updateRelatedStatus: (client, request) => { dispatch(actions.updateRelatedStatus(client, request)) },
        updateRelatedOrder: (client, request) => { dispatch(actions.updateRelatedOrder(client, request)) },

    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RelatedSettingLead);
