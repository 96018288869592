import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import "../styles/_app.scss";
import Leads from "./Leads/Leads";
import LeadsList from "./Leads/LeadsList";
import Contacts from "./Contacts/Contact";
import ContactList from "./Contacts/ContactList";
import Company from "./Company/Company";
import CompanyList from "./Company/CompanyList";
import Tasks from "./Tasks/Tasks";
import TasksList from "./Tasks/TasksList";
import Opportunities from "./Opportunities/Opportunities";
import OpportunitiesList from "./Opportunities/OpportunitiesList";
import Project from "./Projects/Project";
import ProjectList from "./Projects/ProjectList";
import TopNavbar from "./Layout/TopNavbar";
import OpportunitiesCard from "./Opportunities/OpportunitiesCard";
import ProjectCard from "./Projects/ProjectsCard";
import Settings from "./Settings/Settings";
import { connect } from "react-redux";
import {  IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {  green } from '@material-ui/core/colors';
import GoogleLogins from '../components/views/google_login';
import ByGoogleLogin from '../components/views/by_google_login';
import SignIn from '../components/views/sessions/SignIn';
import SignUp from '../components/views/sessions/SignUp';
import ForgotPassword from '../components/views/sessions/ForgotPassword';
import Snackbar from '@material-ui/core/Snackbar';
import CheckEmail from '../components/views/email/CheckEmail';
import UserCredential from '../components/views/sessions/UserCredential';
import Page404 from '../components/views/page404';
import InfoPage from './InfoPage';
import MultipleCompany from '../components/views/dashboard/MultipleCompany';
import { ApolloProvider } from '@apollo/react-hooks';
import Profile from "./Profile/profile";
import Crome from "../crome";
import ProgressionReport from "./Opportunities/ProgressionReport";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      renderNow: false,
      open: false,
      message: '',
      color: '',
      openFixedUserDetail: true,
      isShowMultiCompany: false
    }
  }
  componentWillMount = (e) => {
    var userDataSession = JSON.parse(localStorage.getItem('user_data_session'));
    //console.log("from main container => ",userDataSession)
    this.chkCompanyIdUser();
    if (userDataSession !== null) {
      this.setState({ isLogin: true });
      if (userDataSession.isMultiple == true) {
        let getMultipleCompanyId = localStorage.getItem('setMultipleCompanyId');
        // console.log("==>>getMultipleCompanyId",getMultipleCompanyId)
        if (getMultipleCompanyId !== null) {
          this.setState({ isLogin: true });
        } else {
          this.setState({ isShowMultiCompany: true })

          // window.location='/select-company';
        }
      }
    }
  }
  componentWillReceiveProps(props) {

    if (props.notification.message) {
      if (props.notification.message.error) {
        this.setState({
          message: props.notification.message.notify_msg,
          // color: '#d32f2f',
          color:'#f8d7da',
          renderNow: true,
          open: true
        })
      } else {
        this.setState({
          message: props.notification.message.notify_msg,
          color: '#c8e2b2',
          // color: green[600],
          renderNow: true,
          open: true
        })
      }
      setTimeout(
        function () { this.setState({ open: false }); }
          .bind(this),
        4000
      );
    }
  }
  chkCompanyIdUser() {
    //console.log("chkCompanyIdUser==>")
  }

  toggleSnackbar = () => {
    this.setState({ open: false })
  }

  renderRoutes = () => {
    return (
      <div >
        <Route path='/' exact component={SignIn} />
        <Route path='/crome' exact component={Crome} />
        <Route path="/google-login" component={GoogleLogins} />
        <Route path='/by-google-login' component={ByGoogleLogin} />
        <Route path='/sign-in' exact component={SignIn} />
        <Route path='/sign-up' component={SignUp} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/email/confirm-email' component={CheckEmail} />
        <Route path='/password' component={UserCredential} />
      </div>
    );
  }

  render() {
    var notify;
    if (this.state.renderNow) {
      notify =
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          onClick={this.toggleSnackbar}
          open={this.state.open}
          autoHideDuration={700000}
          ContentProps={{
            'role': 'alertdialog',
            style: { background: this.state.color, marginTop:'35px' },
          }}
          message={<span id="">{this.state.message}</span>}
          action={
            <IconButton key="close" aria-label="close" color="inherit">
              <CloseIcon />
            </IconButton>
          }
        />
    }
    return (
      <ApolloProvider client={this.props.apolloClient.client}>
      <Router>
        <Route path='/404' component={Page404} />
        {/* <Route component={Notfound} /> */}
        {this.state.isShowMultiCompany ?
          <MultipleCompany data={this} />
          :
          ''
        }
        {this.state.isLogin && !this.state.isShowMultiCompany ?
          // <DashboardIndex/>
          <React.Fragment>
            <Router>
              <TopNavbar />
              <Route exact path="/lead/lead/:id" component={Leads} />
              <Route exact path="/lead/leads-list/" component={LeadsList} />
              <Route exact path="/contact/contact/:id" component={Contacts} />
              <Route exact path="/contact/contact-list/" component={ContactList} />
              <Route exact path="/company/company/:id" component={Company} />
              <Route exact path="/company/company-list/" component={CompanyList} />
              <Route exact path="/task/task/:id" component={Tasks} />
              <Route exact path="/task/task-list" component={TasksList} />
              <Route exact path="/opportunities/opportunities/:id" component={Opportunities} />
              <Route exact path="/opportunities/opportunities-list/" component={OpportunitiesList} />
              <Route exact path="/opportunities/opportunities-card/" component={OpportunitiesCard} />
              <Route exact path="/project/projects/:id" component={Project} />
              <Route exact path="/project/projects-list/" component={ProjectList} />
              <Route exact path="/project/projects-card/" component={ProjectCard} />
              <Route exact path="/opportunities/progression-report/" component={ProgressionReport} />
              {/* <Route exact path="/settings/settings/" component={Settings} />
              <Route exact path="/settings/pipeline-stages/" component={SettingsPipeLine} /> */}
              <Route exact path="/settings/:page/" component={Settings} />
              <Route exact path="/settings/:page/:id" component={Settings} />
              <Route exact path="/user/Profile/" component={Profile} />
              <Route component={InfoPage} />
            </Router>
          </React.Fragment>
          :
          this.renderRoutes()
        }
        {notify}

      </Router>
      </ApolloProvider>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.authReducer,
  notification: state.notification,
  apolloClient: state.apolloClient
});
export default connect(
  mapStateToProps,
)(App);
