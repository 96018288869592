import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
const initialState = {
  results: [],
  userList: [],
  userListById: [],
  firstUserInfo: [],
  bussinessType: [],
  compStrength: [],
  compList: [],
  companyList: [],
  customFieldList: [],
  //contact custom field
  contactCustomFieldList: [],
  //company custom field
  companyCustomFieldList:[],
  
  //opportunities custom field
  opportunitiesCustomFieldList: [],

  //projects custom field 
  projectCustomFieldList : [],

  //tasks custom field
  taskCustomFieldList: [],

  addCustomList: [],
  updateCustomList: [],
  deleteCustomList:[],
  createdFieldList: [],
  customFieldOrder: [],
  leadStatusList: [],
  sourcesList: [],
  //customFieldTypeList: [ 'None', 'Text field' ,  'Text area' ,  'Checkbox' ,  'Drop down' ,  'Multi drop down' ,  'Number' , 'Percentage' , 'Date' , 'Url' , 'Currency' , 'Connect field' ],
  customFieldTypeList: [],
  currencyList: [],
  companyCurrencyData: [],
  fieldDetail: [],
  countryListGql: [],
  userSettingList: [],
  systemTagsList: [],
  usersListArray: [],
  systemCityListArray: [],
  systemStateListArray: [],
  systemZipcodeListArray: [],
  userSettingArray: [],
  timezoneList: [],
  pipelineListArray: [],
  singlePipelineData: [],
  loassReasonList: [],
  companyRecList: [],
  contactRecList: [],
  projectRecList: [],
  opportunityRecList: [],
  taskRecList: [],
  masterActivityTypesList: [],
  relatedListArray: [],
  leadrelatedMaster:[],
  contactrelatedMaster:[],
  companyrelatedMaster: [],
  opportunityrelatedMaster: [],
  projectrelatedMaster: [],
  emailtemplatelist:[],
  emailtemplatedetail:[],
  relatedDataCompany:[],
  relatedDataContact:[],
  relatedDataProject:[],
  relatedDataOpportunity:[],
  relatedDataTask:[],

  relatedDataCalandar:[],

  relatedDataFile:[],

  generatedmailboxid:[],
  gmailSuggestions:[],
  projectStageList:[],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTING_ADD_USER:
      return { ...state, results: action.val };
    case actionTypes.GET_SETTING_USER_LIST:
      return { ...state, userList: action.val };
    case actionTypes.GET_USER_BY_ID:
      return updateObject(state, { userListById: action.val })
    case actionTypes.GET_USER_PROFILE_INFO_FIRST:
      return updateObject(state, { firstUserInfo: action.val })
    case actionTypes.GET_BUSSINESS_TYPE:
      return updateObject(state, { bussinessType: action.val })
    case actionTypes.GET_COMPANY_STRENGTH:
      return updateObject(state, { compStrength: action.val })
    case actionTypes.COMPANY_LIST_BY_ID:
      return updateObject(state, { compList: action.val })
    case actionTypes.COUNTRY_LIST:
      return updateObject(state, { countryList: action.val })
    case actionTypes.COMPANY_LIST:
      return updateObject(state, { companyList: action.val })

      
    case actionTypes.COSTOM_FIELD_TYPE_LIST:
      return updateObject(state, { customFieldTypeList: action.val })
    case actionTypes.COSTOM_FIELD_LIST:
      return updateObject(state, { customFieldList: action.val })
    case actionTypes.UPDATE_CUSTOM_FIELD_RECORD:
      return updateObject(state, { customFieldList: action.val })
    case actionTypes.UPDATE_CUSTOM_FIELD_STATUS:
      return updateObject(state, { customFieldList: action.val })
    case actionTypes.UPDATE_CUSTOM_FIELD_ORDER:
      return updateObject(state, { customFieldList: action.val })


    case actionTypes.CONTACT_COSTOM_FIELD_LIST:
      return updateObject(state, { contactCustomFieldList: action.val })
    
    case actionTypes.COMPANY_COSTOM_FIELD_LIST:
      return updateObject(state, { companyCustomFieldList: action.val })
  
    case actionTypes.OPPORTUNITIES_COSTOM_FIELD_LIST:
      return updateObject(state, { opportunitiesCustomFieldList: action.val })  
    
    case actionTypes.PROJECTS_COSTOM_FIELD_LIST:
      return updateObject(state, { projectCustomFieldList: action.val })  

    case actionTypes.TASKS_COSTOM_FIELD_LIST:
      return updateObject(state, { taskCustomFieldList: action.val })  


    case actionTypes.ADD_CUSTOM_FIELD:
      return updateObject(state, { addCustomList: action.val }) 
    case actionTypes.UPDATE_CUSTOM_FIELD:
      return updateObject(state, { updateCustomList: action.val }) 
    case actionTypes.DELETE_CUSTOM_FIELD:
      return updateObject(state, { deleteCustomList: action.val }) 
    case actionTypes.CREATED_FIELD_LIST:
      return updateObject(state, { createdFieldList: action.val }) 
    case actionTypes.COSTOM_FIELD_DETAIL:
      return updateObject(state, { fieldDetail: action.val })

    case actionTypes.LEAD_STATUSES_LIST_GET:
      return updateObject(state, { leadStatusList: action.val })
    case actionTypes.LEAD_SOUECES_LIST:
      return updateObject(state, { sourcesList: action.val })
    case actionTypes.ALL_CURRENCY_LIST:
      return updateObject(state, { currencyList: action.val })  
    case actionTypes.COMPANY_CURRENCY_DATA:
      return updateObject(state, { companyCurrencyData: action.val })
    case actionTypes.COUNTRY_LIST_GQL:
      return updateObject(state, { countryListGql: action.val })
    case actionTypes.SYSTEM_TAGS_LIST:
      return updateObject(state, { systemTagsList: action.val })  
    case actionTypes.USERS_LIST_LIST:
      return updateObject(state, { usersListArray: action.val })
    case actionTypes.GET_CITY_LIST:
      return updateObject(state, { systemCityListArray: action.val })
    case actionTypes.GET_STATE_LIST:
      return updateObject(state, { systemStateListArray: action.val })
    case actionTypes.GET_ZIPCODE_LIST:
      return updateObject(state, { systemZipcodeListArray: action.val })
    case actionTypes.GET_USER_SETTING:
      return updateObject(state, { userSettingArray: action.val })
    case actionTypes.GET_TIMEZONE_LIST:
      return updateObject(state, { timezoneList: action.val })
    case actionTypes.SETTING_PIPELINE_LIST:
      return updateObject(state, { pipelineListArray: action.val })
    case actionTypes.PIPELINE_DATA_BY_ID:
      return updateObject(state, { singlePipelineData: action.val })
    case actionTypes.LOSS_REASON_LIST:
      return updateObject(state, { loassReasonList: action.val })

    case actionTypes.GET_COMPANY_REC_LIST:
      return updateObject(state, { companyRecList: action.val })
    case actionTypes.GET_CONTACT_REC_LIST:
      return updateObject(state, { contactRecList: action.val })
    case actionTypes.GET_PROJECT_REC_LIST:
      return updateObject(state, { projectRecList: action.val })
    case actionTypes.GET_OPPORTUNITY_REC_LIST:
      return updateObject(state, { opportunityRecList: action.val })
    case actionTypes.GET_TASK_REC_LIST:
      return updateObject(state, { taskRecList: action.val })
    case actionTypes.MASTER_ACTIVITYTYPES_LIST:
      return updateObject(state, { masterActivityTypesList: action.val })
    case actionTypes.GET_RELATEDLIST_LIST:
      return updateObject(state, { relatedListArray: action.val })
    case actionTypes.LEAD_RELATED_MASTER_LIST:
      return updateObject(state, { leadrelatedMaster: action.val })
    case actionTypes.CONTACT_RELATED_MASTER_LIST:
      return updateObject(state, { contactrelatedMaster: action.val })
    case actionTypes.COMPANY_RELATED_MASTER_LIST:
      return updateObject(state, { companyrelatedMaster: action.val })
    case actionTypes.OPPORTUNITY_RELATED_MASTER_LIST:
      return updateObject(state, { opportunityrelatedMaster: action.val })
    case actionTypes.PROJECT_RELATED_MASTER_LIST:
      return updateObject(state, { projectrelatedMaster: action.val })
    case actionTypes.EMAIL_TEMPLATE_LIST:
      return updateObject(state, { emailtemplatelist: action.val })
    case actionTypes.EMAIL_TEMPLATE_DETAIL:
      return updateObject(state, { emailtemplatedetail: action.val })
    case actionTypes.RELATED_DATALIST_COMPANY:
      return updateObject(state, { relatedDataCompany: action.val })
    case actionTypes.RELATED_DATALIST_CONTACT:
      return updateObject(state, { relatedDataContact: action.val })
    case actionTypes.RELATED_DATALIST_PROJECT:
      return updateObject(state, { relatedDataProject: action.val })
    case actionTypes.RELATED_DATALIST_OPPORTUNITY:
      return updateObject(state, { relatedDataOpportunity: action.val })
    case actionTypes.RELATED_DATALIST_TASK:
      return updateObject(state, { relatedDataTask: action.val })

    case actionTypes.RELATED_DATALIST_CALANDAR:
      return updateObject(state, { relatedDataCalandar: action.val })

    case actionTypes.RELATED_DATALIST_FILE:
      return updateObject(state, { relatedDataFile: action.val })

      
    case actionTypes.GET_GENERATED_MAIL_ID:
      return updateObject(state, { generatedmailboxid: action.val })
    case actionTypes.GMAIL_SUGGESTION_NAMES:
      return updateObject(state, { gmailSuggestions: action.val })
    case actionTypes.PROJECT_STAGE_LIST:
      return updateObject(state, { projectStageList: action.val })
    default:
      return state;
  }
};
export default reducer;
