import React, { Component } from "react";
import {
  Dialog,
  IconButton,
  Button,
  Icon,
  Tooltip,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Chip,
  TextField,
} from "@material-ui/core";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";

import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { SingleSelect } from "react-select-material-ui";

export const CreateFieldsDynamicLeadEdit = (data) => {
  const formatCreateLabel = (value) => `Create "${value}"`;
  const isValidNewOption = (value) => value != "";
  var elements = data.state.fieldsList;
  var selectedPipelineId = data.state.pipeline_id_createpopup;
  elements = elements.filter(function (element) {
    if (
      (element.piplineid[0] == 0 && element.id != "49") ||
      parseInt(selectedPipelineId) === element.piplineid[0]
    ) {
      return element;
    }
  });
  var fieldsArray = [];
  elements.map((v, i) => {
    if (v.fieldstatus === true) {
      if (v.fieldtype.id == "1" && v.id == "40") {
        //name
        fieldsArray.push(
          <>
            <Grid item xs={12}>
              <TextValidator
                type="text"
                id={v.id}
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                required={v.recordcreationdefaultvalue == 2 ? true : false}
                label={"First Name"}
                onChange={(event) =>
                  data.handleChange(
                    { fieldid: v.id, opportunitiesvalue: event.target.value },
                    v.fieldkey
                  )
                }
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                name={v.fieldkey}
                value={
                  data.state.fieldsObj[v.fieldkey]
                    ? data.state.fieldsObj[v.fieldkey].opportunitiesvalue
                    : ""
                }
                validators={v.recordeditdefaultvalue == 2 ? ["required"] : []}
                errorMessages={
                  v.recordeditdefaultvalue == 2
                    ? ["this field is required"]
                    : []
                }
              />
            </Grid>
          </>
        );
      } else if (v.fieldtype.id == "1" && v.fieldkey == "tags") {
        var valTag = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          valTag = data.state.fieldsObj[v.fieldkey].values;
        }
        fieldsArray.push(
          <Grid item xs={12}>
            <Autocomplete
              disableOpenOnFocus
              multiple
              value={valTag}
              filterSelectedOptions
              disabled={v.recordeditdefaultvalue == 3 ? true : false}
              name={v.fieldkey}
              id="tagInputId"
              options={data.state.systemTagsList.map(
                (option) => option.tagname
              )}
              freeSolo
              onChange={(event, value) =>
                data.handleChange(
                  { fieldid: v.id, values: value },
                  v.fieldkey,
                  "allowSubmitChange"
                )
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.charAt(0).toUpperCase() + option.slice(1)}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  label="Tags"
                  placeholder="Add tag"
                  className="w-100 input_bx"
                  ref={ref => data[v.fieldkey] = ref}
                  onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                  onFocus={() =>
                    setTimeout(() => {
                      document.getElementById("tagInputId").value = "";
                    }, 0)
                  }
                />
              )}
            />
          </Grid>
        );
      } else if (v.fieldtype.id == "1" && v.id == "46") {
        //owner field
        var nameOwner = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          nameOwner = data.state.fieldsObj[v.fieldkey].values;
        }
        fieldsArray.push(
          <Grid item xs={12}>
            <FormControl className="w-100 input_bx">
              <Autocomplete
                disableOpenOnFocus
                forcePopupIcon={false}
                name={v.fieldkey}
                options={data.state.usersListArray}
                getOptionLabel={(option) => option.name}
                value={nameOwner}
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                name={v.fieldkey}
                onChange={(event, value) =>
                  data.handleChange(
                    { fieldid: v.id, values: value },
                    v.fieldkey,
                    "allowSubmitChange"
                  )
                }
                id={v.id}
                renderInput={(params) => (
                  <TextValidator
                  ref={ref => data[v.fieldkey] = ref}
                  onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                    {...params}
                    variant="standard"
                    label={"Owner"}
                    fullWidth
                    className="mb-16 input_bx"
                  />
                )}
              />
            </FormControl>
          </Grid>
        );
      } else if (v.fieldtype.id == "1" && v.id == "44") {
        //company field
        fieldsArray.push(
          <Grid item xs={12}>
            <FormControl className="w-100 input_bx" style={{ zIndex: 20 }}>
              <InputLabel
                style={{ fontSize: "15px", marginTop: "-6px" }}
                shrink={
                  data.state.comp_value || data.state.fcus1 ? true : false
                }
              >
                {" "}
                {v.fieldlabel}
              </InputLabel>
              <SingleSelect
                required={v.recordcreationdefaultvalue == 2 ? true : false}
               // label={v.fieldlabel}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={data.state.comp_value}
                SelectProps={{
                  isCreatable: true,
                  formatCreateLabel,
                  isValidNewOption,
                }}
                placeholder=" "
                options={data.state.comp_options}
                onChange={data.handleCompanyChange}
                onFocus={() => data.setState({ fcus1: true })}
                onBlur={() => data.setState({ fcus1: false })}
                onCreateOption={data.handleCompanyCreate}
                validators={
                  v.recordcreationdefaultvalue == 2 ? ["required"] : []
                }
                errorMessages={
                  v.recordcreationdefaultvalue == 2
                    ? ["this field is required"]
                    : []
                }
              />
            </FormControl>
          </Grid>
        );
      } else if (v.fieldtype.id == "1" && v.id == "42") {
        //Primary contact field
        fieldsArray.push(
          <Grid item xs={12}>
            <FormControl className="w-100 input_bx" style={{ zIndex: 30 }}>
              <InputLabel
                style={{ fontSize: "15px", marginTop: "-6px" }}
                shrink={
                  data.state.primarycontact_value || data.state.fcus
                    ? true
                    : false
                }
              >
                {" "}
                {v.fieldlabel}
              </InputLabel>
              <SingleSelect
                required={v.recordcreationdefaultvalue == 2 ? true : false}
               // label={v.fieldlabel}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={data.state.primarycontact_value}
                SelectProps={{
                  isCreatable: true,
                  formatCreateLabel,
                  isValidNewOption,
                }}
                placeholder=""
                onFocus={() => data.setState({ fcus: true })}
                onBlur={() => data.setState({ fcus: false })}
                options={data.state.primarycontact_options}
                onChange={data.handlePrimaryContactChange}
                validators={
                  v.recordcreationdefaultvalue == 2 ? ["required"] : []
                }
                errorMessages={
                  v.recordcreationdefaultvalue == 2
                    ? ["this field is required"]
                    : []
                }
              />
            </FormControl>
          </Grid>
        );
      } else if (v.fieldtype.id == "1" && v.id == "50") {
        // value field
        fieldsArray.push(
          <>
            {data.state.focusValue && (
              <Grid item xs={4}>
                <FormControl className="w-100 input_bx">
                  {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
                  <SelectValidator
                    className="mb-16 input_bx"
                    label={v.fieldlabel}
                    value={data.state.companyCurrencyData.currencyid}
                    name={"currency"}
                    id={"currency"}
                    SelectProps={{ onClose: data.currencyDropdownClose }}
                    ref={ref => data['currency'] = ref}

                  >
                    <MenuItem
                      className="select-menu-item item-with-link"
                      value={data.state.companyCurrencyData.currencyid}
                    >
                      {data.state.companyCurrencyData.detail.code}
                    </MenuItem>
                  </SelectValidator>
                </FormControl>
              </Grid>
            )}
            <Grid
              item
              xs={data.state.focusValue ? 8 : 12}
              row
              className={data.state.focusValue ? "checkbox-margin-18" : ""}
            >
              <TextValidator
                type="number"
                pattern="[0-9]*"
                id={v.id}
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                onFocus={data.focusInValue}
                label={!data.state.focusValue ? v.fieldlabel : ""}
                onChange={(event) =>
                  data.handleChange(
                    { fieldid: v.id, opportunitiesvalue: event.target.value },
                    v.fieldkey
                  )
                }
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                name={v.fieldkey}
                placeholder={"Add value"}
                value={
                  data.state.fieldsObj[v.fieldkey]
                    ? data.state.fieldsObj[v.fieldkey].opportunitiesvalue
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {data.state.companyCurrencyData.symbol}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        );
      } else if (v.fieldtype.id == "1" || v.fieldtype.id == "9") {
        fieldsArray.push(
          <Grid item xs={12}>
            <TextValidator
              type="text"
              id={v.id}
              disabled={v.recordeditdefaultvalue == 3 ? true : false}
              className="w-100 input_bx"
              label={v.fieldlabel}
              onChange={(event) =>
                data.handleChange(
                  { fieldid: v.id, opportunitiesvalue: event.target.value },
                  v.fieldkey
                )
              }
              ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
              name={v.fieldkey}
              value={
                data.state.fieldsObj[v.fieldkey]
                  ? data.state.fieldsObj[v.fieldkey].opportunitiesvalue
                  : ""
              }
            />
          </Grid>
        );
      } else if (
        v.fieldtype.id == "6" ||
        v.fieldtype.id == "10" ||
        v.fieldtype.id == "7"
      ) {
        fieldsArray.push(
          <Grid item xs={12}>
            <TextValidator
              type="number"
              id={v.id}
              disabled={v.recordeditdefaultvalue == 3 ? true : false}
              className="w-100 input_bx"
              label={v.fieldlabel}
              onChange={(event) =>
                data.handleChange(
                  { fieldid: v.id, opportunitiesvalue: event.target.value },
                  v.fieldkey
                )
              }
              ref={ref => data[v.fieldkey] = ref}
              onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
              name={v.fieldkey}
              value={
                data.state.fieldsObj[v.fieldkey]
                  ? data.state.fieldsObj[v.fieldkey].opportunitiesvalue
                  : ""
              }
            />
          </Grid>
        );
      }

      if (v.fieldtype.id == "2") {
        //TextArea
        fieldsArray.push(
          <Grid item xs={12}>
            <TextValidator
              multiline
              type="text"
              id={v.id}
              disabled={v.recordeditdefaultvalue == 3 ? true : false}
              className="w-100 input_bx"
              label={v.fieldlabel}
              onChange={(event) =>
                data.handleChange(
                  { fieldid: v.id, opportunitiesvalue: event.target.value },
                  v.fieldkey
                )
              }
              ref={ref => data[v.fieldkey] = ref}
              onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
              name={v.fieldkey}
              value={
                data.state.fieldsObj[v.fieldkey]
                  ? data.state.fieldsObj[v.fieldkey].opportunitiesvalue
                  : ""
              }
            />
          </Grid>
        );
      }

      if (v.fieldtype.id == "4") {
        if (v.id === "45" || v.id === "48" || v.id === "51" || v.id === "54") {
          //54-visibility,48-source,45-status,51-Priority
          var valDropdown = "";
          if (
            v.options &&
            data.state.fieldsObj[v.fieldkey] &&
            data.state.fieldsObj[v.fieldkey].values
          ) {
            valDropdown = v.options.filter(
              (row) => row.id == data.state.fieldsObj[v.fieldkey].values.id
            )[0];
          }
          fieldsArray.push(
            <Grid item xs={12}>
              <FormControl className="w-100 input_bx">
                <SelectValidator
                  className="mb-16 input_bx"
                  disabled={v.recordeditdefaultvalue == 3 ? true : false}
                  value={valDropdown}
                  onChange={(event) =>
                    data.handleChange(
                      { fieldid: v.id, values: event.target.value },
                      v.fieldkey,
                      "allowSubmitChange"
                    )
                  }
                  name={v.fieldkey}
                  id={v.id}
                  label={v.fieldlabel}
                  ref={ref => data[v.fieldkey] = ref}

                >
                  {v.options &&
                    v.options.map((item, key) => (
                      <MenuItem
                        className="select-menu-item w-100 input_bx"
                        key={item.id}
                        value={item}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </FormControl>
            </Grid>
          );
        } else if (v.id === "41") {
          //pipeline dropdown
          var valDropdown = "";
          if (
            v.options &&
            data.state.fieldsObj[v.fieldkey] &&
            data.state.fieldsObj[v.fieldkey].values
          ) {
            valDropdown = data.state.pipelineListArray.filter(
              (row) => row.id == data.state.fieldsObj[v.fieldkey].values.id
            )[0];
          }
          fieldsArray.push(
            <Grid item xs={12}>
              <FormControl className="w-100 input_bx">
                {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
                <SelectValidator
                  className="mb-16 input_bx"
                  label={v.fieldlabel}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  value={valDropdown}
                  onChange={(event) =>
                    data.handleChange(
                      { fieldid: v.id, values: event.target.value },
                      v.fieldkey,
                      "allowSubmitChange"
                    )
                  }
                  name={v.fieldlabel}
                  id={v.id}
                  required={v.recordcreationdefaultvalue == 2 ? true : false}
                  validators={
                    v.recordcreationdefaultvalue == 2 ? ["required"] : []
                  }
                  errorMessages={
                    v.recordcreationdefaultvalue == 2
                      ? ["this field is required"]
                      : []
                  }
                  ref={ref => data[v.fieldkey] = ref}

                >
                  {data.state.pipelineListArray.length > 0 &&
                    data.state.pipelineListArray.map((item, key) => (
                      <MenuItem
                        className="select-menu-item w-100 input_bx"
                        key={item.id}
                        value={item}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </FormControl>
            </Grid>
          );
        } else if (v.id === "47") {
          //stage dropdown
          var valDropdown = "";
          if (
            v.options &&
            data.state.fieldsObj[v.fieldkey] &&
            data.state.fieldsObj[v.fieldkey].values
          ) {
            valDropdown = data.state.stageArray.filter(
              (row) => row.id == data.state.fieldsObj[v.fieldkey].values.id
            )[0];
          }
          fieldsArray.push(
            <Grid item xs={12}>
              <FormControl className="w-100 input_bx">
                {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
                <SelectValidator
                  className="mb-16 input_bx"
                  label={v.fieldlabel}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  value={valDropdown}
                  onChange={(event) =>
                    data.handleChange(
                      { fieldid: v.id, values: event.target.value },
                      v.fieldkey,
                      "allowSubmitChange"
                    )
                  }
                  name={v.fieldlabel}
                  id={v.id}
                  required={v.recordcreationdefaultvalue == 2 ? true : false}
                  validators={
                    v.recordcreationdefaultvalue == 2 ? ["required"] : []
                  }
                  errorMessages={
                    v.recordcreationdefaultvalue == 2
                      ? ["this field is required"]
                      : []
                  }
                  ref={ref => data[v.fieldkey] = ref}

                >
                  {data.state.stageArray.length > 0 &&
                    data.state.stageArray.map((item, key) => (
                      <MenuItem
                        className="select-menu-item w-100 input_bx"
                        key={item.id}
                        value={item}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </FormControl>
            </Grid>
          );
        } else if (v.id !== "53") {
          var valDropdown = {};
          if (
            v.options &&
            data.state.fieldsObj[v.fieldkey] &&
            data.state.fieldsObj[v.fieldkey].values
          ) {
            //valDropdown = data.state.fieldsObj[v.fieldkey].values
            valDropdown = v.options.filter(
              (row) => row.id == data.state.fieldsObj[v.fieldkey].values.id
            )[0];
          }
          fieldsArray.push(
            <Grid item xs={12}>
              <FormControl className="w-100 input_bx">
                {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
                <SelectValidator
                  className="mb-16 input_bx"
                  label={v.fieldlabel}
                  disabled={v.recordeditdefaultvalue == 3 ? true : false}
                  value={valDropdown}
                  onChange={(event) =>
                    data.handleChange(
                      { fieldid: v.id, values: event.target.value },
                      v.fieldkey,
                      "allowSubmitChange"
                    )
                  }
                  name={v.fieldlabel}
                  id={v.id}
                  ref={ref => data[v.fieldkey] = ref}

                >
                  {v.options &&
                    v.options.map((item, key) => (
                      <MenuItem
                        className="select-menu-item w-100 input_bx"
                        key={item.id}
                        value={item}
                      >
                        {item.optionvalue}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </FormControl>
            </Grid>
          );
        }
      }
      if (v.fieldtype.id == "5") {
        //multi dropdown
        var multiSelectedVal = [];
        if (
          data.state.fieldsObj[v.fieldkey] &&
          data.state.fieldsObj[v.fieldkey].values &&
          data.state.fieldsObj[v.fieldkey].values.length > 0
        ) {
          multiSelectedVal = v.options.filter(
            (row) =>
              data.state.fieldsObj[v.fieldkey].values.filter(
                (val) => val.id == row.id
              ).length
          );
        }

        fieldsArray.push(
          <Grid item xs={12}>
            <FormControl className="w-100 input_bx">
              <Autocomplete
                multiple
                value={multiSelectedVal}
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                name={v.fieldkey}
                onChange={(event, value) =>
                  data.handleChange(
                    { fieldid: v.id, values: value },
                    v.fieldkey,
                    "allowSubmitChange"
                  )
                }
                id={v.id}
                options={v.options}
                getOptionLabel={(option) => option.optionvalue}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    variant="standard"
                    label={v.fieldlabel}
                    fullWidth
                    className="mb-16 input_bx"
                    ref={ref => data[v.fieldkey] = ref}
                    onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                  />
                )}
              />
            </FormControl>
          </Grid>
        );
      }
      if (v.fieldtype.id == "3") {
        //checkbox field
        fieldsArray.push(
          <Grid item xs={12} className="checkbox-margin-18">
            <FormControl className="w-100 input_bx">
              <FormControlLabel
                control={
                  <Checkbox
                    name={v.fieldkey}
                    id={v.id}
                    checked={
                      data.state.fieldsObj[v.fieldkey] &&
                      data.state.fieldsObj[v.fieldkey].opportunitiesvalue == "1"
                        ? true
                        : false
                    }
                    onChange={(event, value) =>
                      data.handleChange(
                        {
                          fieldid: v.id,
                          opportunitiesvalue: value ? "1" : "0",
                        },
                        v.fieldkey,
                        "allowSubmitChange"
                      )
                    }
                    value={
                      data.state.fieldsObj[v.fieldkey]
                        ? data.state.fieldsObj[v.fieldkey].opportunitiesvalue
                        : ""
                    }
                    color="primary"
                  />
                }
                label={v.fieldlabel}
              />
            </FormControl>
          </Grid>
        );
      }
      if (v.fieldtype.id == "8") {
        //datepicker field
        var dtFormat = "MM/dd/yyyy";
        var selectedDate = data.state.selectedDate;
        if (data.state.fieldsObj[v.fieldkey]) {
          selectedDate = data.state.fieldsObj[v.fieldkey].values;
        }

        if (data.state.userSettings != "") {
          dtFormat = data.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }

        fieldsArray.push(
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl className="w-100 input_bx">
                <KeyboardDatePicker
                  label={v.fieldlabel}
                  className="mb-16 input_bx"
                  format={dtFormat}
                  placeholder={dtFormat}
                  error={selectedDate == "Invalid Date" ? true : false}
                  value={selectedDate}
                  onChange={(event, value) =>
                    data.handleChange(
                      {
                        fieldid: v.id,
                        values:
                          event == "Invalid Date" ? "Invalid Date" : event,
                      },
                      v.fieldkey,
                      "allowSubmitChange"
                    )
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  helperText={
                    selectedDate == "Invalid Date" ? "enter valid date." : ""
                  }
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </Grid>
        );
      }
    }
  });

  return fieldsArray;
};
