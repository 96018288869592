import React, { Component } from "react";
import {
  Dialog, IconButton, Button, Icon, Tooltip, Grid,
  FormControl, Select, InputLabel, Menu, MenuItem, List,
  ListItem, ListItemText, DialogActions,
  Chip, TextField, CircularProgress
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { createDynamicFields } from './CreateFieldsDynamic'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

class CreateNewLead extends Component {
  state = {
    selectedRepeatValue:"never",
    selectedRepeatName:"Set to repeat",
    selectedReminderValue:"0",
    selectedReminderName:"Add reminder",
    type:"never",
    repeatdays:1,
    repeattype:"days",
    days:0,
    reminderMinitue:0,

    lastname: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray: [],
    companyCurrencyData: [],
    focusValue: false,
    fieldsList: [],
    fieldsObj: {
      duedate: {
        fieldid: "60",
        values: new Date()
      },
      reminderdate: {
        fieldid: "62",
        values: new Date()
      },
    },
    tags: [],
    systemTagsList: [],
    usersListArray: [],
    selectedDate: new Date(),
    userSettings: "",
    isDataLoading: true,
    relatedListArray: [],
    isAddedFromSidebar:false,
    sidebarParams:"",
  };

  componentWillMount() {
    let val = { typeId: 6 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getRelatedList(this.props.apolloClient.client);
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    if(this.props.taskDueDate && this.props.taskDueDate != ""){
      let fieldsObj = this.state.fieldsObj;
      fieldsObj = {
        ...fieldsObj,
        ["duedate"]: {fieldid:"60",values:this.props.taskDueDate},
      }
      this.setState({
        fieldsObj,
      })
    }
  }

  componentWillReceiveProps(props) {
    
    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }
    if (props.settingReducer.relatedListArray.data) {
      props.settingReducer.relatedListArray.is_loaded = false
      const relatedData = props.settingReducer.relatedListArray.data;
      var NewArray = []
      NewArray = NewArray.concat(relatedData.lead); 
      NewArray = NewArray.concat(relatedData.contact); 
      NewArray = NewArray.concat(relatedData.company); 
      NewArray = NewArray.concat(relatedData.opportunities);
      NewArray = NewArray.concat(relatedData.project);
      this.setState({
           relatedListArray: NewArray
      }, () => {
          if(props.dataFromSidebar){
            let fieldsObj = this.state.fieldsObj;
            var selectedObj = this.state.relatedListArray.filter(function(element) {
              if(element.id == props.dataFromSidebar.id && element.type == props.dataFromSidebar.recordtype){
                return element
              }
            });
            if(selectedObj.length > 0){
              fieldsObj = {
                ...fieldsObj,
                ["relatedto"]: {fieldid:"59",values:selectedObj[0]},
              }
              this.setState({
                fieldsObj,
                isAddedFromSidebar:true,
                sidebarParams:props.dataFromSidebar,
              })
            }
          }

      }) 
    }
    if(props.taskNm && props.taskNm != ""){
      let fieldsObj = this.state.fieldsObj;
      fieldsObj = {
        ...fieldsObj,
        ["name"]: {fieldid:"57",taskvalue:props.taskNm},
      }
      this.setState({
        fieldsObj,
      })
    }
    

    if (props.settingReducer.taskCustomFieldList.data && props.settingReducer.taskCustomFieldList.is_loaded) {
      props.settingReducer.taskCustomFieldList.is_loaded = false;
      const customFields = props.settingReducer.taskCustomFieldList.data;
      this.setState({ fieldsList: customFields, isDataLoading: false })
      var activitytypeKey = this.findWithAttr(customFields, 'id', "58");
      
      //setting selected date in date picker
      customFields.map((v, i) => {
        if (v.fieldtype.id === "8") {
          var data = {
            fieldid: v.id,
            values: this.state.selectedDate
          }
          this.setState({
            fieldsObj: {
              ...this.state.fieldsObj,
              [v.fieldkey]: data
            }
          })
        }
      })

      //setting default object for status
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["activitytype"]: {fieldid: "58",values:customFields[activitytypeKey].options[0]}
        }
      })
    }
    if (props.settingReducer.countryListGql.data) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["owner"]: { fieldid: "61", values: usersListArray[companyOwnerKey] }
        }
      })
    }
    if (props.notification && props.notification.status === true) {
      // props.handleClose();
      props.clearNotification();
    }

    if (props.settingReducer.systemTagsList.data) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }
  }

  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    }, () => this.forceUpdate())
  }

  handleNormalFields = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
    if(event.target.name == "repeattype"){
      this.setState({
        repeatdays: 1
      });
    }
  };

  handleCountryChange = (event, values) => {
    this.setState({ country: values });
  }

  focusInValue = () => {
    this.setState({ focusValue: true })
  };

  focusOutValue = () => {
    this.setState({ focusValue: false })
  };

  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name) => {
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if(key != -1 && this.state.fieldsList[key].fieldtype.id != "4"  && this.state.fieldsList[key].fieldtype.id != "8"){
      if(this[name]){
        this[name].validate(val.value);
      }
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      }
    })

  };
  handleBlur = (event,ref) => {
    if(this[ref].props.id == "tagInputId1"){
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      },10)
    }
    this[ref].validate(event.target.value);
}

  handleFormSubmit = () => {
    var dd = ""
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
    submitData = Object.values(submitData).map((row) => {
      if (row.fieldid == "59") {//realated  field
        if(row.values == null){
          return false
        }
        row.values = [{ srno: "1", id: row.values.id, value: row.values.name,type: row.values.type }]
      }
      if (row.fieldid === "58")
      {
        row.values = [{ srno: "1", id: row.values.id, value: row.values.name, icon: row.values.icon }]
      }
      if (row.fieldid === "65" || row.fieldid === "63") {//visibility
        row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
      }
      var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
      if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
        row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
      }
      if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
        if (row.values.length > 0) {
          var ar = [];
          row.values.map((val, i) => {
            ar.push({ srno: (i + 1).toString(), id: val.id, value: val.optionvalue })
          })
        }
        row.values = ar
      }

      //validating date fiels
      if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
        if (this.state.fieldsList[key].recordcreationdefaultvalue == 2 && row.values == null) {
          return false
        } else if (this.state.fieldsList[key].recordcreationdefaultvalue != 2 && row.values == null) {
          row.taskvalue = "";
          delete row.values;
        } else if (row.values == "Invalid Date") {
          return false
        } else {

          let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          row.taskvalue = moment.utc(logdate).format('X');

          // row.taskvalue = moment(row.values).format("X");
          delete row.values;
        }
      }

      if(row.fieldid == "60"){
        if(row.taskvalue == ""){
          let date_test = moment(new Date()).format('YYYY-MM-DD HH:mm');
          var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          row.taskvalue = moment.utc(logdate).format('X');

          // row.taskvalue = moment(new Date()).format("X");
          // dd = moment(new Date()).format("X");
          dd = row.taskvalue;
          
        }else{
          dd = row.taskvalue;
        }
      }
      if(row.fieldid == "62"){
        if(this.state.selectedReminderValue == "0"){
           row.values = [{type:0, date:"", minitue:0}];
        }else if(this.state.selectedReminderValue == "5"){//custom
            var dt1 = moment.unix(dd).format(this.state.userSettings.dateformat).toString()
            dt1 = new Date(dt1)
            var dt2 = moment.unix(row.taskvalue).format(this.state.userSettings.dateformat).toString()
            dt2 = new Date(dt2)

            var dif = (dt1 - dt2); 
            var difMinute = Math.round((dif/1000)/60); 
 
          row.values = [{type:this.state.selectedReminderValue, date:row.taskvalue, minitue:parseInt(difMinute)}];

        }else{
          row.values = [{type:this.state.selectedReminderValue, date:"", minitue:parseInt(this.state.reminderMinitue)}];
        }
        
        delete row.taskvalue;
      }

      if (this.state.fieldsList[key].fieldtype.id == "8" && row.fieldid !='60' && row.fieldid !='62') {//For custom date fiels
          if(row.taskvalue != ""){
            var inFormat = moment.unix(row.taskvalue).format('YYYY-MM-DD')
            row.taskvalue = inFormat
          }
      }

      if (row.fieldid == "66") {//tags
        if (row.values.length > 0) {
          var objArr = [];
          row.values.map((val, k) => {
            var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
            var tagId = "0";
            if (foundKey !== -1) {
              tagId = this.state.systemTagsList[foundKey].id
            }
            objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId })
          })
          row.values = objArr
        }
      }


      if (row.fieldid == "61") {//owner value set
        if (row.values) {
          row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
        } else {
          row.values = [];
        }
        delete row.taskvalue;
      }
      return row;
    })


    //not to submit if any field is false
    var allowToSubmit = true
    submitData.map((row) => {
      if (!row) {
        allowToSubmit = false
      }
    })
      
    //logic for setting repeat object
    var repeat = {
      type:this.state.type,
      repeatdays:this.state.repeatdays,
      repeattype:this.state.repeattype,
      days:this.state.days,
    } 

    if(this.state.type == "never"){
      repeat.repeatdays = 0
      repeat.repeattype = ""
      repeat.days = 0
    }

    if(this.state.type == "custom"){
      if(this.state.repeattype == "days"){
        repeat.days = this.state.repeatdays
      }
      if(this.state.repeattype == "weeks"){
        repeat.days = this.state.repeatdays * 7
      }
      if(this.state.repeattype == "months"){
        repeat.days = this.state.repeatdays * 30
      }
    }

    if(this.state.type != "custom" && this.state.type != "never"){
      repeat.repeatdays = 0
      repeat.repeattype = ""
    }
    console.log("submitData", submitData);
    console.log("repeat", repeat);
    console.log("allowToSubmit", allowToSubmit);
    if (allowToSubmit) {
      let req = {
        taskdata: submitData,
        repeat: repeat,
        sidebarParams: this.state.sidebarParams,
      }
      console.log("req", req);
      //return
      this.props.handleClose();
      this.props.saveTaskDetail(this.props.apolloClient.client, req);
    }
  };

  handleDateChange = date => {
    console.log("date", date);
    this.setState({ selectedDate: date });
  };

  changeRepeatOption = (val,name,days) => {
      this.setState({
          selectedRepeatValue:val,
          selectedRepeatName:name,
          days:days,
          type:val,
      });

      if(val == "custom"){
          this.setState({
              repeatdays:1,
              repeattype:"days",
          });        
      }
  }
  changeReminderOption = (fieldkey,name,fieldid) => {
      this.setState({
          selectedReminderValue:fieldkey,
          selectedReminderName:name,
          reminderMinitue:fieldid,
      });
  }

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1)
        arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1)
        arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  render() {
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create New Task</h4>
          <div className="d-flex">
            <Tooltip title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <ValidatorForm noValidate  instantValidate={false}  onSubmit={this.handleFormSubmit}>
          <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`}>
            <Grid container spacing={2}>
              {this.state.isDataLoading && <CircularProgress size={25} className="loaderPopupCenter" />}
              {!this.state.isDataLoading && createDynamicFields(this)}
            </Grid>
          </div>

          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button disabled={this.state.isDataLoading} variant="contained" type="submit" color="primary">Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});

const mapDispatchToProps = dispatch => {
  return {
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    saveTaskDetail: (client, request) => { dispatch(actionCreators.saveTaskDetail(client, request)) },

    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
    getRelatedList: (client) => dispatch(actionCreators.getRelatedList(client)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewLead);
