import React from "react";
import { IconButton, Icon, withStyles, Menu, MenuItem } from "@material-ui/core";
const ITEM_HEIGHT = 48;
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem"
    }
}))(Icon);
function ProfileEmailMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <React.Fragment>
            <IconButton size="small"
                aria-label="More"
                aria-owns={open ? "long-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className="hover_show"
            >
                <Icon>more_vert</Icon>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 200
                    }
                }}
            >
                <MenuItem onClick={handleClose}>
                    <IconButton aria-label="Delete" size="small" className="hover_show  mr-10">
                        <IconSmall>launch</IconSmall>
                    </IconButton> View HTML Version
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <IconButton aria-label="Delete" size="small" className="hover_show  mr-10">
                        <IconSmall>delete</IconSmall>
                    </IconButton> Delete Email
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <IconButton aria-label="Delete" size="small" className="hover_show text-error mr-10">
                        <IconSmall>lock</IconSmall>
                    </IconButton> Make email public
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export default ProfileEmailMenu;
