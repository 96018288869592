import React, { Component } from "react";
import { Checkbox } from "@material-ui/core";
import { Table } from 'antd';


class GeneralNotification extends Component {
    state = {
        selection: [],
        checkbox_general_email1:true,
        checkbox_general_email2:true,
        checkbox_general_email3:true,
        checkbox_general_email4:true,
        checkbox_general_email5:true,
        checkbox_general_inapp1:true,
        checkbox_general_inapp3:true,
        checkbox_general_mobile3:true,
    };
    handleCheckBoxChange = (event, name) => {
        event.persist();
        this.setState({ [name]: event.target.checked });
    };
    render() {
        const columns = [];
        const rows = [];
        const general_rows = [];

        const general_columns = [

            {
                title: "",
                dataIndex: "name",
                className: "align_left",
                width: 300
            },
            {
                title: "Email",
                dataIndex: "email",
                // className: "align_left"
            },
            {
                title: "In App",
                dataIndex: "inapp",
                // className: "align_left"
            },
            {
                title: "Mobile",
                dataIndex: "mobile",
                // className: "align_left"
            },
            {
                title: "",
                dataIndex: "action",
            }
        ];
        
        general_rows.push (
            {
                name: (
                    <div className="ml-10">                           
                        <label className="crm-custom-field-label font-weight-500">Records I follow are updated</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_general_email1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_general_email1')}
                        value="checkbox_general_email1"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_general_inapp1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_general_inapp1')}
                        value="checkbox_general_inapp1"
                        color="primary"
                    />
                </React.Fragment>),
                mobile  : (<></>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">New Contact suggestions</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_general_email2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_general_email2')}
                        value="checkbox_general_email2"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<></>),
                mobile: (<></>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Someone @mentions me</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_general_email3}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_general_email3')}
                        value="checkbox_general_email3"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_general_inapp3}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_general_inapp3')}
                        value="checkbox_general_inapp3"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_general_mobile3}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_general_mobile3')}
                        value="checkbox_general_mobile3"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Weekly Progress Report</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_general_email4}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_general_email4')}
                        value="checkbox_general_email4"
                        color="primary"

                    />
                </React.Fragment>),
                inapp: (<></>),
                mobile: (<></>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Daily Task Report</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_general_email5}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_general_email5')}
                        value="checkbox_general_email5"
                        color="primary"
                        
                    />
                </React.Fragment>),
                inapp: (<></>),
                mobile: (<></>),
            }
        )
        


        return (
            <React.Fragment>
                <div className="table_width antd-tables">
                    <Table
                        bordered={false}
                        columns={general_columns}
                        pagination={false}
                        dataSource={general_rows}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default GeneralNotification;
