import React, { Component } from "react";
import {
 TextField, Button, Menu, Icon
} from "@material-ui/core";
import "date-fns";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';

import Autocomplete from '@material-ui/lab/Autocomplete';
import FontAwesome from "react-fontawesome";

class SearchDropdown extends Component {
  constructor(props) {

    super(props);
    this.state = {
      // currencyList: [],
      // defaultCurrencyObj: {},
      anchorEl: null,
      dropdown_data : [],
      defaultObj: {},
    };
  }

  componentDidMount() {
    let dropdownType = this.props.dropdownType;
    let dropdown_data = this.props.dropdownData;
    let default_selected = dropdown_data[this.props.defaultValue];
    
    this.setState({ dropdownType: dropdownType, dropdown_data: dropdown_data, defaultObj: default_selected });
  }

  componentWillReceiveProps(props) {

    let dropdownType = this.props.dropdownType;
    let dropdown_data = props.dropdownData;
    let default_selected = dropdown_data[props.defaultValue];

    this.setState({ dropdownType: dropdownType, dropdown_data: dropdown_data, defaultObj: default_selected });
    // if (props.settingReducer.currencyList.data != null) {
    //   var dataList = props.settingReducer.currencyList.data
    //   this.setState({ dropdown_data: dataList, defaultObj: dataList[0] });
    // }
  }

  handleCurrencySelectChange = (event, values) => {
    if (values) {
      this.setState({ defaultObj: values });
      this.props.data.setCurrency(values);
    }
    this.setState({ anchorEl: null });
  }
  handleTypeSelectChange = (event, values) => {
    if (values) {
      this.setState({ defaultObj: values });
      this.props.data.setFieldType(values);
    }
    this.setState({ anchorEl: null });
  }
  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    })
    setTimeout(function () {
      document.getElementById("size-small-standard-currency").focus();
    }, 200);
  };
  handleClickType = event => {
    this.setState({
      anchorEl: event.currentTarget
    })
    setTimeout(function () {
      document.getElementById("size-small-standard-type").focus();
    }, 200);
  };
  toCamelCase = (s) => {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '');
      });
    };
  render() {
    const { anchorEl, dropdownType } = this.state;
    return (
      (dropdownType == 'currency') ?
        <div>
          {this.state.defaultObj && 
            <Button aria-controls="simple-menu" className="currency-list-dropdown-search" aria-haspopup="true" onClick={this.handleClick}>
            <span className="currency-list-selected-currency">{this.state.defaultObj.name}</span>{this.state.defaultObj.name && <Icon className="currency-list-icon">arrow_drop_down</Icon>}
            </Button>
          }
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <Autocomplete
              id="size-small-standard-currency"
              onChange={this.handleCurrencySelectChange}
              options={this.state.dropdown_data}
              getOptionLabel={option => option.code + ' - ' + option.name}
              defaultValue={this.state.dropdown_data[this.props.defaultValue]}
              closeIcon={<span></span>}
              className="currency-list-dropdown-autocomplete"
              onClose={this.handleDropdownClose}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                  placeholder="Search"
                />
              )}
            />
          </Menu>
        </div>
        : (dropdownType == 'type') ?
        <div>
          {this.state.defaultObj &&
            <Button aria-controls="simple-menu" className="currency-list-dropdown-search" aria-haspopup="true" onClick={this.handleClickType}>
              <span className="currency-list-selected-currency"><FontAwesome  className={"crm-fontawsome-icon " +this.state.defaultObj.fieldicon} /> {this.state.defaultObj.name}</span>{this.state.defaultObj.name && <Icon className="currency-list-icon">arrow_drop_down</Icon>}
            </Button>
          }
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <Autocomplete
              id="size-small-standard-type"
              onChange={this.handleTypeSelectChange}
              options={this.state.dropdown_data}
              getOptionLabel={option => option.name}
              renderOption={(option) => (
                <React.Fragment>
                 <FontAwesome className={"crm-fontawsome-icon "+option.fieldicon} />{option.name}
                </React.Fragment>
              )}
              defaultValue={this.state.dropdown_data[this.props.defaultValue]}
              closeIcon={<span></span>}
              className="currency-list-dropdown-autocomplete"
              onClose={this.handleDropdownClose}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                  placeholder="Search"
                />
              )}
            />
          </Menu>
        </div>
        :
        null
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient
});


const mapDispatchToProps = dispatch => {
  return {
    loadCurrencyList: (client) => dispatch(actionCreators.loadCurrencyList(client)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(SearchDropdown));
