import React, { Component } from "react";
import {Fab, Grid, withStyles,Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import ContactProfileFrame from "../Contacts/ContactProfileFrame";
import CreateNewContactDialog from "../Contacts/CreateNewContactDialog";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink,withRouter } from "react-router-dom";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import CreateNewCompanyDialog from "../Company/CreateNewCompanyDialog";
import CompanyProfileFrame from "../Company/CompanyProfileFrame";
const formatCreateLabel = (value) => `Create "${value}"`;
const isValidNewOption = (value) => value != "";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);
const filter = createFilterOptions();


class Companies extends Component {
  state = {
        attachment: null,
        drawerOpenCompany: false,
        showAddCompany: false,
        opportunities_id: "",
        company_id: "",
        companyListArray: [],
        allCompanyList: [],
        createNewCompanyName:"",
        filterdata:[],
        removeCompanyPopup: false,
        shouldOpenCreateCompanyDialog: false,
        FullCreateCompanyDialog: false,
        minimizeCompanyDialog: false,
        type: "companies",
        recordtype: "",
        opportunities_id:""
    };

    componentDidMount() {
        this.props.getCompanyRecList(this.props.apolloClient.client);

        //console.log("this.props", this.props);
        if(this.props.recordId){
            this.setState({
                recordId:this.props.recordId,
                recordtype:this.props.recordtype,
            },()=>{
                this.forceUpdate()
                if(this.props.recordtype == "opportunities"){
                  this.setState({
                      opportunities_id:this.props.recordId
                  })
                }
                var req ={
                  id:this.props.recordId,
                  type:this.state.type,
                  recordtype:this.props.recordtype,
                }
                  //console.log("req", req);
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
    }
    componentWillReceiveProps(props) {
        if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
            this.setState({
                recordId:props.recordId,
                recordtype:props.recordtype,
            },()=>{
                this.forceUpdate()
                var req ={
                  id:props.recordId,
                  type:this.state.type,
                  recordtype:props.recordtype,
                }
                  //console.log("req", req);
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
        if (props.companyReducer.companyFieldData.data && props.companyReducer.companyFieldData.is_loaded) {
            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
        }
         
         
        if (props.settingReducer.relatedDataCompany.data) {
            props.settingReducer.relatedDataCompany.is_loaded = false
            var dd = []
            dd = props.settingReducer.relatedDataCompany.data.data
            this.setState({
                companyListArray:dd,
            },()=>this.forceUpdate());
        }

         if (props.settingReducer.companyRecList && props.settingReducer.companyRecList.data && props.settingReducer.companyRecList.data.data){
          let companyOptions = [];
          props.settingReducer.companyRecList.data.data.map((compOpt)=>{
            companyOptions.push({ label: compOpt.name, value: compOpt.company_id});
          })
          this.setState({ allCompanyList: companyOptions});
        }
         
    }

  toggleCompany = () => {
      this.setState(oldStateCompany => ({ isOpenedCompany: !oldStateCompany.isOpenedCompany}));
      if(this.state.isOpenedCompany){
          this.setState({
              showAddCompany: false
          },()=>this.forceUpdate());
      }
  }

  /* START cmp dialoge */

    handleCompanyDialogClose = () => {
        this.setState({ shouldOpenCreateCompanyDialog: false, FullCreateCompanyDialog: false, minimizeCompanyDialog: false });
    };

    handleCompanyDialogMinimize = () => {
        this.setState(oldStateminimizeCompanyDialog => ({ minimizeCompanyDialog: !oldStateminimizeCompanyDialog.minimizeCompanyDialog }));
        this.setState({ FullCreateCompanyDialog: false })
    };

    handleCompanyDialogFull = () => {
        this.setState(oldStateFullCreateCompanyDialog => ({ FullCreateCompanyDialog: !oldStateFullCreateCompanyDialog.FullCreateCompanyDialog }));
        this.setState({ minimizeCompanyDialog: false })

    };
    
    /* END cmp dialoge */
    closeDrawerRight = (e) => {
        //e.preventDefault()
        this.setState({
          drawerOpenContact: false,
          drawerOpenCompany: false,
        });
    };
    toggleAddCompany = () => {
        this.setState({ 
            isOpenedCompany: true,
            showAddCompany: !this.state.showAddCompany
        });
    }
    handleDrawerCompanyRight = (company_id) => {
      if (this.state.company_id && (this.state.company_id === company_id)) { } else {
            this.setState({
                company_id: company_id,
                isDataLoading: true,
            }, () => {
                this.forceUpdate()
            })
        }

        setTimeout(() => {
            this.setState({
                drawerOpenCompany: true,
            }, () => {
                this.forceUpdate()
            })
        }, 2000);

    };
    handleCompanyLaunch = (company_id) => {
        this.props.history.push('/company/company/'+company_id)
    };
    handleRemoveCompany = (company_id) => {
          this.setState({
                removeCompanyPopup: true,
                deleteCompanyId: company_id,
          }, () => {
              this.forceUpdate()
          })
        
    };
    toggleRemoveCompanyPopup = () => {
          if(this.state.removeCompanyPopup){
            this.setState({
                deleteCompanyId: "",
            }, () => {
                this.forceUpdate()
            })

          }
          this.setState({
                removeCompanyPopup: !this.state.removeCompanyPopup,
          }, () => {
              this.forceUpdate()
          })
        
    };
    confirmremoveCompanyPopup = () => {
          console.log("this.state.deleteCompanyId", this.state.deleteCompanyId);
        if(this.state.deleteCompanyId){
          if(this.state.recordtype == "opportunities"){
            var req = {
                companyid:parseInt(this.state.deleteCompanyId),
                opportunitiesid:parseInt(this.state.recordId),
            }
            this.props.deleteOpportunitiesCompany(this.props.apolloClient.client,req)
          }
          if(this.state.recordtype == "project"){
            var req = {
                companyid:parseInt(this.state.deleteCompanyId),
                projectid:parseInt(this.state.recordId),
            }
            this.props.deleteProjectCompany(this.props.apolloClient.client,req)
          }

          this.setState({
              deleteCompanyId: "",
              removeCompanyPopup: false,
          }, () => {
              this.forceUpdate()
          })
        }
        
    };

    handleCompanyChange = (val , newValue) => {
      if(newValue){
          let id =  (newValue.__isNew__) ? "0" : newValue.value;
          if(id == "0"){
            this.setState({
              shouldOpenCreateCompanyDialog: true ,
              createNewCompanyName: newValue.label,
              dataFromSidebar:{
                  id:parseInt(this.state.recordId),
                  type:this.state.type,
                  recordtype:this.state.recordtype,
              },
            })
          }else{
            var isAlreadyAdded  = this.state.companyListArray.filter(function(element) {
                if(element.company.id == id){
                  return element
                }
            });
            if(isAlreadyAdded.length > 0){
              this.setState({ showAddCompany: false});
              return
            }
            let data = {srno: "1", id: id, value: newValue.label};
            if(this.state.recordtype == "opportunities"){
              var req = {
                companydetail:data,
                opportunitiesid:parseInt(this.state.recordId),
              }
              this.props.addNewOpportunitiesCompany(this.props.apolloClient.client,req)
            }
            if(this.state.recordtype == "project"){
              var req = {
                companydetail:data,
                projectid:parseInt(this.state.recordId),
              }
              this.props.addProjectCompany(this.props.apolloClient.client,req)
            }


          }
          this.setState({ showAddCompany: false});

      }
    };

  findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
  render() {
    let { drawerOpenCompany,isOpenedCompany } = this.state;
    /*STRAT company Display*/
        let displayCompanyList = []
        {
            this.state.companyListArray.map((item, key) => {
              var companyimg = ""
              if(item.company.image){
                  companyimg = item.company.image
              }
              if(item.company.__companydetail__.length >0){
                  var name = ""
                  item.company.__companydetail__.map((data, key1) => {
                      if(data.__field__.id == "29"){//name
                          name =  data.fieldvalue;
                      }
                      
                  
                  })
                  displayCompanyList.push(
                        <div className="box_portlet mt-10 crm-calendar-event">
                                <div className="box_body px-10 py-20">
                                    <div className="lead_pl" style={{paddingLeft: '50px'}}>
                                        <div className="icon_left">
                                            <Fab
                                                  size="small"
                                                  aria-label="Task"
                                                  onClick={()=>this.handleDrawerCompanyRight(item.company.id)}
                                                  className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                                              >
                                                 
                                                {!companyimg && <Avatar className="bg-body"><Icon className="activity_icon_normal">person</Icon></Avatar>}
                                                {companyimg && <Avatar className="bg-body" src={companyimg}></Avatar> }
                                                  
                                              </Fab>

                                        </div>
                                        <Grid item className="flex-auto">
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <a onClick={()=>this.handleDrawerCompanyRight(item.company.id)} className={"cursor_poiter"}>
                                                    <div className="font-size-13"><span className="font-weight-500" style={{color:'#000000de',fontWeight:'500'}}>{name}</span></div>
                                                    </a>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <span className="related-text">{item.company.totalcontact+" Contacts"}</span>
                                        <div className="hover_show" style={{top:'-21px',right:'-7px',bottom:'50px'}}>
                                          <IconButton aria-label="Launch" size="small" onClick={()=>this.handleCompanyLaunch(item.company.id)}>
                                              <IconSmall style={{fontSize:'18px'}}>launch</IconSmall>
                                          </IconButton>
                                          <IconButton aria-label="Delete" size="small" onClick={()=>this.handleRemoveCompany(item.company.id)}>
                                              <IconSmall style={{fontSize:'18px'}}>close</IconSmall>
                                          </IconButton>
                                      </div>
                                    </div>
                                </div>
                        </div>                            
                  )
              }
              
            })
        }
        /*END company Display*/
    
    return (
      <Card elevation={3} className="p-16 mt-16" style={{overflow: 'initial'}}>
        <Grid container justify="space-between">
            <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleCompany}>Companies ({displayCompanyList.length}) <Icon className="vert-middle">{isOpenedCompany ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
            <IconButton aria-label="Add" size="small">
                <IconSmall onClick={()=>this.toggleAddCompany()} className={this.state.showAddCompany?"plusIconTransform":""}>add</IconSmall>
            </IconButton>
        </Grid>
        {isOpenedCompany &&
                      <>
                          {this.state.showAddCompany && 
                              <FormControl className="w-100 input_bx" style={{zIndex:10000}}>
                                  <SingleSelect
                                    SelectProps={{
                                      isCreatable: true,
                                      formatCreateLabel,
                                      isValidNewOption,
                                    }}
                                    placeholder="Add Company"
                                    options={this.state.allCompanyList}
                                    onChange={this.handleCompanyChange}
                                  />
                              </FormControl>

                          }
                          {(!this.state.showAddCompany &&  displayCompanyList.length == 0) && 
                            <label className="text-hint">
                              <IconButton className="mr-8" component="span">
                                <Icon onClick={()=>this.toggleAddCompany()} >business</Icon>
                              </IconButton>
                                Companies
                            </label>
                          }
                          {displayCompanyList}

                      </>
                    }

                    {this.state.shouldOpenCreateCompanyDialog && (
                        <CreateNewCompanyDialog
                            handleCloseCompany={() => this.handleCompanyDialogClose()}
                            openCompany={this.state.shouldOpenCreateCompanyDialog}
                            handleMinimizeCompany={() => this.handleCompanyDialogMinimize()}
                            handleFullCompany={() => this.handleCompanyDialogFull()}
                            minimizeScreenCompany={this.state.minimizeCompanyDialog}
                            fullScreenCompany={this.state.FullCreateCompanyDialog}
                            allstates={this.state}
                            name={this.state.createNewCompanyName}
                            opportunitiesid={this.state.opportunities_id}
                            dataFromSidebar={this.state.dataFromSidebar}
                        />
                    )}

                    <Dialog
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={this.state.removeCompanyPopup}
                        onClose={this.toggleRemoveCompanyPopup}
                        disableBackdropClick
                        disableEscapeKeyDown
                        aria-labelledby="ar-remove-dialog-title"
                    >
                        <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the associated company ?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.toggleRemoveCompanyPopup} color="primary">
                                Cancel
                                          </Button>
                            <Button type="Button" onClick={() => this.confirmremoveCompanyPopup()} className="btn_Primary">
                                Ok
                                          </Button>

                        </DialogActions>
                    </Dialog>
                        <Drawer
                        width={"100px"}
                        variant="temporary"
                        anchor={"right"}
                        className="drawer_right"
                        open={drawerOpenCompany}
                        onClose={this.closeDrawerRight}
                        ModalProps={{
                            keepMounted: true
                        }}
                    >
                    {this.state.company_id && <CompanyProfileFrame company_id={parseInt(this.state.company_id)}  handleClose={this.closeDrawerRight} />}
                    </Drawer>
                    {this.state.isDataLoading &&
                      <Snackbar
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          open={this.state.isDataLoading}
                          TransitionComponent={Fade}
                          message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                          variant={'success'}
                      />
                    }
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  opportunityReducer: state.opportunityReducer,
  companyReducer: state.companyReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    deleteOpportunitiesCompany: (client,req) => { dispatch(actionCreators.deleteOpportunitiesCompany(client,req)) },
    getCompanyRecList: (client) => { dispatch(actionCreators.getCompanyRecList(client)) },
    addNewOpportunitiesCompany: (client,req) => { dispatch(actionCreators.addNewOpportunitiesCompany(client,req)) },
    getRecordRelatedData: (client,req) => { dispatch(actionCreators.getRecordRelatedData(client,req)) },
    addProjectCompany: (client,req) => { dispatch(actionCreators.addProjectCompany(client,req)) },
    deleteProjectCompany: (client,req) => { dispatch(actionCreators.deleteProjectCompany(client,req)) },
  }
}


export default (connect(mapStateToProps,mapDispatchToProps)(withRouter(Companies)));
