import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { SingleSelect } from "react-select-material-ui";
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

export const CreateFieldsDynamicContactEdit = (data) => {
  
  var elements = data.state.fieldsList
  var fieldsArray = [];
  const formatCreateLabel = (value) => `Create "${value}"`;
  const isValidNewOption = (value) => value != "";

  elements.map((v, i) => {

    if (v.fieldstatus === true) {
      if (v.fieldtype.id == '1' && ((v.fieldkey == "websites" || v.fieldkey == "social" || v.fieldkey == "phones" || v.fieldkey == "email") && v.is_default)) {
        {
          let tempFieldArray = []
          data.state.fieldsObj[v.fieldkey].values && data.state.fieldsObj[v.fieldkey].values.map((row, index) => {
            if (!row.id) {
              data.handleAddMoreField("id", v.fieldkey, index, v.options[0].id)
            }
            if(v.fieldkey === "email"){//for email validation
              tempFieldArray.push(
                <>
                  <Select
                    ref={ref => data[v.fieldkey] = ref}
                    name={v.fieldkey}
                    className="mb-16 input_bx"
                    id={v.id}
                    value={row.id}
                    disableUnderline
                    onChange={(event) => data.handleAddMoreField("id", v.fieldkey, index, event.target.value)}
                    SelectDisplayProps={{ className: "multiple-text-dropdown" }}
                  >
                    {v.options && v.options.map((item, key) =>
                      <MenuItem key={item.id} value={item.id}>{item.optionvalue}</MenuItem>
                    )}
                  </Select>
                  <TextValidator
                    type="text"
                    id={v.id}
                    placeholder={"Add " + v.fieldkey}
                    className="w-100 input_bx"
                    onChange={(event) => data.handleAddMoreField("value", v.fieldkey, index, event.target.value)}
                    name={v.fieldkey}
                    value={row.value}
                    disabled={v.recordeditdefaultvalue == 3 ? true : false}
                    validators={(v.recordeditdefaultvalue == 2) ? ["required","isEmail"] : ["isEmail"]}
                    errorMessages={(v.recordeditdefaultvalue == 2) ? ["this field is required","enter valid email address."] : ["enter valid email address."]}
                    ref={ref => data[v.fieldkey] = ref}
                    onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                   
                  />
                </>
              )
            }else{
              tempFieldArray.push(
                <>
                  <Select
                    ref={ref => data[v.fieldkey] = ref}

                    name={v.fieldkey}
                    className="mb-16 input_bx"
                    id={v.id}
                    value={row.id}
                    disableUnderline
                    onChange={(event) => data.handleAddMoreField("id", v.fieldkey, index, event.target.value)}
                    SelectDisplayProps={{ className: "multiple-text-dropdown" }}
                  >
                    {v.options && v.options.map((item, key) =>
                      <MenuItem key={item.id} value={item.id}>{item.optionvalue}</MenuItem>
                    )}
                  </Select>
                  <TextValidator
                    type="text"
                    id={v.id}
                    placeholder={"Add " + v.fieldkey}
                    className="w-100 input_bx"
                    onChange={(event) => data.handleAddMoreField("value", v.fieldkey, index, event.target.value)}
                    name={v.fieldkey}
                    value={row.value}
                    disabled={v.recordeditdefaultvalue == 3 ? true : false}
                    ref={ref => data[v.fieldkey] = ref}
                    onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                    
                  />
                  {v.fieldkey != "email" && row.value && index == (data.state.fieldsObj[v.fieldkey].values.length - 1) &&
                    <span className="add-more-field" type="button" onClick={() => { data.addNewField(v.fieldkey, v.options[0]) }}>Add more</span>
                  }
                </>
              )
            }
            
          })
          fieldsArray.push(
            <Grid item xs={12}>
              {tempFieldArray}
            </Grid>
          )
        }

      } else if (v.fieldtype.id == '1' && v.id == '16') {
        fieldsArray.push(<><Grid item xs={12}>
          <TextValidator
            type="text"
            id={v.id}
            disabled={v.recordeditdefaultvalue == 3 ? true : false}
            required={(v.recordcreationdefaultvalue == 2) ? true:false}
            className="w-100 input_bx"
            label={"First Name"}
            onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
            validators={(v.recordeditdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordeditdefaultvalue == 2) ? ["this field is required"] : []}
          />
        </Grid>
          <Grid item xs={12}>
            <TextValidator
              type="text"
              id="lastname"
              disabled={v.recordeditdefaultvalue == 3 ? true : false}
              className="w-100 input_bx"
              label={"Last Name"}
              onChange={(event) => data.handleNormalFields(event)}
              name={"lastname"}
              value={data.state.lastname}
              ref={ref => data[v.fieldkey] = ref}
              onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}

              
            />
          </Grid></>
        )
      } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') {
        var valTag = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          valTag = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={12}>

          <Autocomplete
            disableOpenOnFocus
            multiple
            value={valTag}
            filterSelectedOptions
            disabled={v.recordeditdefaultvalue == 3 ? true : false}
            name={v.fieldkey}
            id="tagInputId"
            options={data.state.systemTagsList.map(option => option.tagname)}
            freeSolo
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey,'allowSubmitChange')}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option.charAt(0).toUpperCase() + option.slice(1)} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextValidator
                {...params}
                id={v.fieldkey}
                label="Tags"
                placeholder="Add tag"
                className="w-100 input_bx"
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                onFocus={() => 
                  setTimeout(() => {
                    document.getElementById("tagInputId").value = "";
                  },0)
                }
              />
            )}
          />
        </Grid>)
      }else if (v.fieldtype.id == '1' && v.id == '20') {//owner field
        var nameOwner = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          nameOwner = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={12}>
          <FormControl className="w-100 input_bx">
                <Autocomplete
                  disableOpenOnFocus
                  forcePopupIcon={false}
                  name={v.fieldkey}
                  options={data.state.usersListArray}
                  getOptionLabel={option => option.name}
                  value={nameOwner}
                  disabled={v.recordeditdefaultvalue == 3 ? true : false}
                  name={v.fieldkey}
                  onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey,'allowSubmitChange')}
                  id={v.id}
                  renderInput={params => (
                    <TextValidator
                    ref={ref => data[v.fieldkey] = ref}
                    onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                      {...params}
                      variant="standard"
                      label={"Owner"}
                      fullWidth
                      className="mb-16 input_bx"
                    />
                  )}
                />
              </FormControl>
        </Grid>)
      } else if (v.fieldtype.id == '1' && v.id == '23') {
        fieldsArray.push(
          <>
            {data.state.focusValue &&
              <Grid item xs={4}>
                <FormControl className="w-100 input_bx">
                  {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
                  <SelectValidator
                    label={v.fieldlabel}
                    value={data.state.companyCurrencyData.currencyid}
                    name={"currency"}
                    id={"currency"}
                    SelectProps={{ onClose: data.currencyDropdownClose }}
                    className="mb-16 input_bx"
                    ref={ref => data[v.fieldkey] = ref}

                    
                  >
                    <MenuItem className="select-menu-item item-with-link" value={data.state.companyCurrencyData.currencyid}>{data.state.companyCurrencyData.detail.code}</MenuItem>
                  </SelectValidator>
                </FormControl>
              </Grid >
            }
            <Grid item xs={data.state.focusValue ? 8 : 12} row className={data.state.focusValue ? "checkbox-margin-18" : ""}>
              <TextValidator
                type="number"
                pattern="[0-9]*"
                id={v.id}
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                onFocus={data.focusInValue}
                label={!data.state.focusValue ? v.fieldlabel : ""}
                onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                name={v.fieldkey}
                placeholder={"Add value"}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
                
                
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {data.state.companyCurrencyData.symbol}
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </>
        )
      } else if (v.fieldtype.id == '1' || v.fieldtype.id == '9') {
        fieldsArray.push(<Grid item xs={12}>
          <TextValidator
            type="text"
            id={v.id}
            disabled={v.recordeditdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
         
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
            
            
          />
        </Grid>)
      }
      else if (v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7') {
        fieldsArray.push(<Grid item xs={12}>
          <TextValidator
            type="number"
            id={v.id}
            disabled={v.recordeditdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
         
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
            
            
          />
        </Grid>)
      }

      if (v.fieldtype.id == '2' && v.id == "25") {//Address Fields.
        var displayMapLink = false;
        var mapAddress="";

        if(data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].contactvalue != "" || data.state.city || data.state.state || data.state.country || data.state.zipcode){
          displayMapLink = true
          var line1 = ""
          
          if(data.state.fieldsObj[v.fieldkey] != undefined){
            line1 = data.state.fieldsObj[v.fieldkey].contactvalue 
          }
          var line2 = data.state.city 
          var line3 = data.state.addressState

          var line4 = (data.state.country && data.state.country.name)?data.state.country.name:""; 
          var line5 = data.state.zipcode
          var mapAddress = encodeURI(line1+" "+line2+" "+line3+" "+line5+" "+line4);
        }
        fieldsArray.push(
          <>
            {displayMapLink && 
              <Grid item xs={12}>
                <a className="pull-right" style={{color:'#7467ef'}} target="_blank" href={"https://maps.google.com/?q=" + mapAddress}>Address map</a>
              </Grid>
            }
            <Grid item xs={12}>
              <TextValidator
                multiline
                type="text"
                id={v.id}
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                label={v.fieldlabel}
                onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
                             name={v.fieldkey}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                type="text"
                id={"city"}
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                label={"City"}
                onChange={(event) => data.handleNormalFields(event)}
                ref={ref => data['city'] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event,'city')}
                name={"city"}
                value={data.state.city}
                
                
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                type="text"
                id={"state"}
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                label={"State"}
                onChange={(event) => data.handleNormalFields(event)}
                ref={ref => data['addressState'] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event,'addressState')}
                name={"addressState"}
                value={data.state.addressState}
                
                
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                type="text"
                id={"zipcode"}
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                label={"Zip code"}
                onChange={(event) => data.handleNormalFields(event)}
                ref={ref => data['zipcode'] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event,'zipcode')}
                name={"zipcode"}
                value={data.state.zipcode}
                
                
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className="w-100 input_bx">
                <Autocomplete
                  disableOpenOnFocus
                  forcePopupIcon={false}
                  disabled={v.recordeditdefaultvalue == 3 ? true : false}
                  name={"country"}
                  onChange={data.handleCountryChange}
                  id={"country"}
                  value={data.state.country}
                  options={data.state.countryListArray}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextValidator

                      {...params}
                      variant="standard"
                      label={"Country"}
                      fullWidth
                      className="mb-16 input_bx"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </>
        )
      } else if (v.fieldtype.id == '2') {
        fieldsArray.push(<Grid item xs={12}>
          <TextValidator
            multiline
            type="text"
            id={v.id}
            disabled={v.recordeditdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, contactvalue: event.target.value }, v.fieldkey)}
             ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event,v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
            
            
          />
        </Grid>)
      }

      if (v.fieldtype.id == '4') {
        if (v.id === "26" || v.id === "19") {
          var valDropdown = "";
          if (v.options && data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].values) {
            valDropdown = v.options.filter(row => row.id == data.state.fieldsObj[v.fieldkey].values.id)[0]
          }
          fieldsArray.push(<Grid item xs={12}>
            <FormControl className="w-100 input_bx">
              <SelectValidator
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                value={valDropdown}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey,'allowSubmitChange')}
                name={v.fieldkey}
                id={v.id}
                label={v.fieldlabel}
                className="mb-16 input_bx"
                ref={ref => data[v.fieldkey] = ref}

                
              >
                {v.options && v.options.map((item, key) =>
                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.name}</MenuItem>
                )}
                {v.options && v.id == "19" && 
                  <MenuItem className="select-menu-item item-with-link" value={5}><Link to="/settings/contact-types">Customize Contact Types</Link></MenuItem>
                }
              </SelectValidator>
            </FormControl>
          </Grid >
          )
        } else if (v.id === "17") { // company
          fieldsArray.push(<Grid item xs={12}>
            <FormControl className="w-100 input_bx " style={{zIndex:20}}>
            <InputLabel
                style={{ fontSize: "15px", marginTop: "-6px" }}
                shrink={(data.state.comp_value || data.state.fcus1)?true:false}
              >
                {" "}
                {v.fieldlabel}
              </InputLabel>
              <SingleSelect
              //label={v.fieldlabel}

                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={parseInt(data.state.comp_value)}
                SelectProps={{ isCreatable: true, formatCreateLabel, isValidNewOption }}
                placeholder=""
                onFocus={() => data.setState({fcus1: true })}
                onBlur={() => data.setState({fcus1: false })}
          
                options={data.state.comp_options}
                onChange={data.handleCompanyChange}
              />
            </FormControl>
          </Grid >
          )
        } else {
          var valDropdown = "";
          if (v.options && data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].values) {
            //valDropdown = data.state.fieldsObj[v.fieldkey].values
            valDropdown = v.options.filter(row => row.id == data.state.fieldsObj[v.fieldkey].values.id)[0]
          }
          fieldsArray.push(<Grid item xs={12}>
            <FormControl className="w-100 input_bx">
              {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
              <SelectValidator
                label={v.fieldlabel}
                disabled={v.recordeditdefaultvalue == 3 ? true : false}
                value={valDropdown}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey,'allowSubmitChange')}
                name={v.fieldlabel}
                id={v.id}
                className="mb-16 input_bx"
                ref={ref => data[v.fieldkey] = ref}

                
              >
                {v.options && v.options.map((item, key) =>
                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.optionvalue}</MenuItem>
                )}

              </SelectValidator>
            </FormControl>
          </Grid >
          )
        }
      }
      if (v.fieldtype.id == '5') {//multi dropdown
        var multiSelectedVal = [];
        if (data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].values && data.state.fieldsObj[v.fieldkey].values.length > 0) {
            multiSelectedVal = v.options.filter(row => data.state.fieldsObj[v.fieldkey].values.filter(val => val.id == row.id).length);
        }
        
        fieldsArray.push(<Grid item xs={12}>
          <FormControl className="w-100 input_bx">
            <Autocomplete
              multiple
              value={multiSelectedVal}
              disabled={v.recordeditdefaultvalue == 3 ? true : false}
              name={v.fieldkey}
              onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey,'allowSubmitChange')}
              id={v.id}
              options={v.options}
              getOptionLabel={option => option.optionvalue}
              renderInput={params => (
                <TextValidator
                  {...params}
                  variant="standard"
                  label={v.fieldlabel}
                  fullWidth
                  className="mb-16 input_bx"
                />
              )}
            />
          </FormControl>
        </Grid >
        )
      }
      if (v.fieldtype.id == '3') {//checkbox field
        fieldsArray.push(<Grid item xs={12} className="checkbox-margin-18">
          <FormControl className="w-100 input_bx">
            <FormControlLabel
              control={
                <Checkbox
                  name={v.fieldkey}
                  id={v.id}
                  checked={(data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].contactvalue == "1") ? true : false}
                  onChange={(event, value) => data.handleChange({ fieldid: v.id, contactvalue: value ? "1" : "0" }, v.fieldkey,'allowSubmitChange')}
                  value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
                  color="primary"
                />
              }
              label={v.fieldlabel}
            />
          </FormControl>
        </Grid >
        )
      }
      if (v.fieldtype.id == '8') {//datepicker field
        var dtFormat = "MM/dd/yyyy";
        var selectedDate = data.state.selectedDate;
        if (data.state.fieldsObj[v.fieldkey]) {
          selectedDate = data.state.fieldsObj[v.fieldkey].values
        }

        if(data.state.userSettings != ""){
          dtFormat = data.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }

        fieldsArray.push(<Grid item xs={12} >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl className="w-100 input_bx">
            <KeyboardDatePicker
                label={v.fieldlabel}
                format={dtFormat}
                placeholder={dtFormat}
                error={(selectedDate=="Invalid Date")?true:false}
                value={selectedDate}
                onChange={(event,value) => data.handleChange({ fieldid: v.id, values: event=="Invalid Date"?"Invalid Date":event}, v.fieldkey,'allowSubmitChange')}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                helperText={(selectedDate=="Invalid Date")?"enter valid date.":""}

            />
          </FormControl>
          </MuiPickersUtilsProvider>
        </Grid >
        )
      }
    }
  })

  return fieldsArray;
}