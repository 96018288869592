export const LOGIN_WITH_EMAIL_AND_PASSWORD = 'LOGIN_WITH_EMAIL_AND_PASSWORD';
export const REGISTER_USER = 'REGISTER_USER';
export const USER_VERIFY = 'USER_VERIFY';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const USER_LOGIN = 'USER_LOGIN';
export const DECREMENT = 'DECREMENT';
export const ADD = "ADD";
export const SUBTRACT = "SUBTRACT";
export const STORE_RESULT = "STORE_RESULT";
export const DELETE_RESULT = "DELETE_RESULT";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const SETTING_ADD_USER = "SETTING_ADD_USER";
export const GET_SETTING_USER_LIST = "GET_SETTING_USER_LIST";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const INVITE_USER_DELETE = "INVITE_USER_DELETE";
export const SAVE_EDIT_USER = "SAVE_EDIT_USER";
export const GET_USER_PROFILE_INFO_FIRST = "GET_USER_PROFILE_INFO_FIRST";
export const GET_BUSSINESS_TYPE = "GET_BUSSINESS_TYPE";
export const GET_COMPANY_STRENGTH = "GET_COMPANY_STRENGTH";
export const UPDATE_COMPANY_INFO = "UPDATE_COMPANY_INFO";
export const COMPANY_LIST_BY_ID = "COMPANY_LIST_BY_ID";
export const UPDATE_USER = "UPDATE_USER";
export const COUNTRY_LIST = "COUNTRY_LIST";

export const COMPANY_LIST = "COMPANY_LIST";
export const GET_GENERATED_MAIL_ID = "GET_GENERATED_MAIL_ID";
export const GMAIL_SUGGESTION_NAMES = "GMAIL_SUGGESTION_NAMES";

//custom field action types
export const COSTOM_FIELD_TYPE_LIST = "COSTOM_FIELD_TYPE_LIST";
export const COSTOM_FIELD_LIST = "COSTOM_FIELD_LIST";
export const COSTOM_FIELD_DETAIL = "COSTOM_FIELD_DETAIL";
export const CREATED_FIELD_LIST = "CREATED_FIELD_LIST";
export const UPDATE_CUSTOM_FIELD_RECORD = "UPDATE_CUSTOM_FIELD_RECORD";
export const UPDATE_CUSTOM_FIELD_STATUS = "UPDATE_CUSTOM_FIELD_STATUS";
export const UPDATE_CUSTOM_FIELD_ORDER = "UPDATE_CUSTOM_FIELD_ORDER";
export const ADD_CUSTOM_FIELD = "ADD_CUSTOM_FIELD";
export const UPDATE_CUSTOM_FIELD = "UPDATE_CUSTOM_FIELD";
export const DELETE_CUSTOM_FIELD = "DELETE_CUSTOM_FIELD";


//contact action types
export const CONTACT_COSTOM_FIELD_LIST = "CONTACT_COSTOM_FIELD_LIST";
export const GET_CONTACT_TYPE = "GET_CONTACT_TYPE";
export const GET_COMPANY_REC_LIST = "GET_COMPANY_REC_LIST";
export const GET_CONTACT_FILTER_LIST = "GET_CONTACT_FILTER_LIST";
export const GET_CONTACT_LIST_ORDER = "GET_CONTACT_LIST_ORDER";
export const GET_CONTACT_LOG_LIST = "GET_CONTACT_LOG_LIST";
export const GET_CONTACT_SIDEBAR_LOG_LIST = "GET_CONTACT_SIDEBAR_LOG_LIST";

export const CONTACT_FIELD_DATA = "CONTACT_FIELD_DATA";
export const GET_CONTACTFILTER_LIST = "GET_CONTACTFILTER_LIST";


//compan action types
export const COMPANY_COSTOM_FIELD_LIST = "COMPANY_COSTOM_FIELD_LIST";
export const GET_COMAPNY_FILTER_LIST = "GET_COMAPNY_FILTER_LIST";
export const GET_COMPANY_LIST_ORDER = "GET_COMPANY_LIST_ORDER";
export const GET_COMPANYFILTERS = "GET_COMPANYFILTERS";
export const COMPANY_FIELD_DATA = "COMPANY_FIELD_DATA";
export const GET_COMPANY_LOG_LIST = "GET_COMPANY_LOG_LIST";
export const GET_COMPANY_SIDEBAR_LOG_LIST = "GET_COMPANY_SIDEBAR_LOG_LIST";






export const LEAD_STATUSES_LIST_GET = "LEAD_STATUSES_LIST_GET";
export const LEAD_SOUECES_LIST = "LEAD_SOUECES_LIST";
export const ALL_CURRENCY_LIST = "ALL_CURRENCY_LIST";
export const COMPANY_CURRENCY_DATA = "COMPANY_CURRENCY_DATA";
export const COUNTRY_LIST_GQL = "COUNTRY_LIST_GQL";
export const GET_USER_SETTING = "GET_USER_SETTING";
export const SYSTEM_TAGS_LIST = "SYSTEM_TAGS_LIST";
export const USERS_LIST_LIST = "USERS_LIST_LIST";

//Lead action types
export const GET_LEAD_LIST = "GET_LEAD_LIST";
export const GET_LEAD_FILTER_LIST = "GET_LEAD_FILTER_LIST";
export const GET_LEAD_LOG_LIST = "GET_LEAD_LOG_LIST";
export const GET_LEAD_LOG_SIDEBAR_LIST = "GET_LEAD_LOG_SIDEBAR_LIST";
export const GET_LEAD_LIST_ORDER = "GET_LEAD_LIST_ORDER";
export const LEAD_FIELD_DATA = "LEAD_FIELD_DATA";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_ZIPCODE_LIST = "GET_ZIPCODE_LIST";
export const GET_TIMEZONE_LIST = "GET_TIMEZONE_LIST";
export const GET_LEADFILTER_LIST = "GET_LEADFILTER_LIST";



//START For opportunity and pipelines 
export const SETTING_PIPELINE_LIST = "SETTING_PIPELINE_LIST";
export const PIPELINE_DATA_BY_ID = "PIPELINE_DATA_BY_ID";
export const LOSS_REASON_LIST = "LOSS_REASON_LIST";


export const GET_OPPORTUNITY_FILTER_LIST = "GET_OPPORTUNITY_FILTER_LIST";
export const GET_OPPORTUNITY_LIST_ORDER = "GET_OPPORTUNITY_LIST_ORDER";
export const GET_OPPORTUNITY_LOG_LIST = "GET_OPPORTUNITY_LOG_LIST";
export const GET_OPPORTUNITY_SIDEBAR_LOG_LIST = "GET_OPPORTUNITY_SIDEBAR_LOG_LIST";

export const OPPORTUNITY_FIELD_DATA = "OPPORTUNITY_FIELD_DATA";
export const GET_OPPORTUNITYFILTER_LIST = "GET_OPPORTUNITYFILTER_LIST";

export const OPPORTUNITIES_COSTOM_FIELD_LIST = "OPPORTUNITIES_COSTOM_FIELD_LIST";
export const OPPORTUNITY_CONTACT_LIST = "OPPORTUNITY_CONTACT_LIST";
export const OPPORTUNITY_COMPANY_LIST = "OPPORTUNITY_COMPANY_LIST";

//END For opportunity and pipelines 

export const GET_CONTACT_REC_LIST = "GET_CONTACT_REC_LIST";
export const MASTER_ACTIVITYTYPES_LIST = "MASTER_ACTIVITYTYPES_LIST";

//Tasks Action types

//contact action types
export const TASKS_COSTOM_FIELD_LIST = "TASKS_COSTOM_FIELD_LIST";
export const GET_TASK_TYPE = "GET_TASK_TYPE";
export const GET_TASK_FILTER_LIST = "GET_TASK_FILTER_LIST";
export const GET_TASK_LIST_ORDER = "GET_TASK_LIST_ORDER";
export const GET_TASK_LOG_LIST = "GET_TASK_LOG_LIST";
export const TASK_FIELD_DATA = "TASK_FIELD_DATA";
export const GET_TASKFILTER_LIST = "GET_TASKFILTER_LIST";

export const GET_RELATEDLIST_LIST = "GET_RELATEDLIST_LIST";
export const EMAIL_TEMPLATE_LIST = "EMAIL_TEMPLATE_LIST";
export const EMAIL_TEMPLATE_DETAIL = "EMAIL_TEMPLATE_DETAIL";



// projects action types
export const PROJECTS_COSTOM_FIELD_LIST = "PROJECTS_COSTOM_FIELD_LIST";

export const LEAD_RELATED_MASTER_LIST = "LEAD_RELATED_MASTER_LIST";
export const CONTACT_RELATED_MASTER_LIST = "CONTACT_RELATED_MASTER_LIST";
export const COMPANY_RELATED_MASTER_LIST = "COMPANY_RELATED_MASTER_LIST";
export const OPPORTUNITY_RELATED_MASTER_LIST = "OPPORTUNITY_RELATED_MASTER_LIST";
export const PROJECT_RELATED_MASTER_LIST = "PROJECT_RELATED_MASTER_LIST";

export const GET_PROJECT_LIST = "GET_PROJECT_LIST";
export const GET_PROJECT_FILTER_LIST = "GET_PROJECT_FILTER_LIST";
export const GET_PROJECT_LOG_LIST = "GET_PROJECT_LOG_LIST";
export const GET_PROJECT_LOG_SIDEBAR_LIST = "GET_PROJECT_LOG_SIDEBAR_LIST";
export const GET_PROJECT_LIST_ORDER = "GET_PROJECT_LIST_ORDER";
export const PROJECT_FIELD_DATA = "PROJECT_FIELD_DATA";
export const GET_PROJECTFILTER_LIST = "GET_PROJECTFILTER_LIST";

export const RELATED_DATALIST_COMPANY = "RELATED_DATALIST_COMPANY";
export const RELATED_DATALIST_CONTACT = "RELATED_DATALIST_CONTACT";
export const RELATED_DATALIST_PROJECT = "RELATED_DATALIST_PROJECT";
export const RELATED_DATALIST_OPPORTUNITY = "RELATED_DATALIST_OPPORTUNITY";
export const RELATED_DATALIST_TASK = "RELATED_DATALIST_TASK";

export const RELATED_DATALIST_CALANDAR = "RELATED_DATALIST_CALANDAR";
export const RELATED_DATALIST_FILE = "RELATED_DATALIST_FILE";

export const GET_PROJECT_REC_LIST = "GET_PROJECT_REC_LIST";
export const GET_OPPORTUNITY_REC_LIST = "GET_OPPORTUNITY_REC_LIST";
export const GET_TASK_REC_LIST = "GET_TASK_REC_LIST";


export const PROJECT_STAGE_LIST = "PROJECT_STAGE_LIST";
