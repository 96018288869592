import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, DialogActions, CircularProgress } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";

import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { createContactDynamicFields } from './createContactDynamicFields'
import moment from 'moment';
class CreateNewContact extends Component {
  state = {
    lastname: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray: [],
    gmailSuggestion: [],
    companyCurrencyData: [],
    comp_options: [],
    comp_value: '',
    comp_isLoading: false,
    focusValue: false,
    fieldsList: [],
    fieldsObj: {
      websites: {
        fieldid: "23",
        values: [
          { srno: "1", id: null, value: "" }
        ]
      },
      social: {
        fieldid: "24",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      phones: {
        fieldid: "22",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      email: {
        fieldid: "21",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
    },
    tags: [],
    systemTagsList: [],
    usersListArray: [],
    userSettings: "",
    isDataLoading: true,
    datafromsidebar: {}
  };


  componentWillMount() {
    this.props.getCompanyRecList(this.props.apolloClient.client);
    //if contact create from opportunity sidebar then set name
    if (this.props.name) {
      let fieldsObj = this.state.fieldsObj;
      var data = {
        fieldid: "16",
        contactvalue: this.props.name
      }
        console.log("data", data);

      fieldsObj = {
          ...fieldsObj,
          ["name"]: data,
        }
      this.setState({
        datafromsidebar: this.props.dataFromSidebar,
        fieldsObj
      })
    }

    if (this.props.opportunitiesid) {
      localStorage.setItem('opportunitiesid', this.props.opportunitiesid)
    }
    if (this.props.cmpId) {
      this.setState({
        comp_value: this.props.cmpId,
      })
    }

    // let userData = JSON.parse(localStorage.getItem('user_data_session'))
    // let userid = { userid: userData.id }
    // this.props.getUserSetting(this.props.apolloClient.client, userid);

    let val = { typeId: 2 }
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
  }

  componentWillReceiveProps(props) {
    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }

    if (props.settingReducer.companyRecList && props.settingReducer.companyRecList.data && props.settingReducer.companyRecList.data.data) {
      let companyOptions = [];
      props.settingReducer.companyRecList.data.data.map((compOpt) => {
        companyOptions.push({ label: compOpt.name, value: compOpt.company_id });
      })
      this.setState({ comp_options: companyOptions }, () => {
        if (this.props.cmpId) {
          let compSelectkey = this.findWithAttr(companyOptions, 'value', this.state.comp_value);
          if (compSelectkey != -1) {
            this.handleChange({ fieldid: "17", values: { id: companyOptions[compSelectkey].value, name: companyOptions[compSelectkey].label } }, 'company');
            this.setState({ comp_value: this.props.cmpId });
          }


        }
      });
    }

    if (props.settingReducer.contactCustomFieldList.data && props.settingReducer.contactCustomFieldList.is_loaded) {
      const customFields = props.settingReducer.contactCustomFieldList.data;
      props.settingReducer.contactCustomFieldList.is_loaded = false
      this.setState({ fieldsList: customFields, isDataLoading: false, })
      var contactTypeKey = this.findWithAttr(customFields, 'id', "19");
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["contacttype"]: { fieldid: "19", values: customFields[contactTypeKey].options[0] }
        }
      })
    }
    if (props.settingReducer.countryListGql.data) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;

      this.setState({ usersListArray: usersListArray })
      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["owner"]: { fieldid: "20", values: usersListArray[companyOwnerKey] }
        }
      })
    }

    if (props.notification && props.notification.status === true) {
      // props.handleClose();
      props.clearNotification();
    }

    if (props.settingReducer.systemTagsList.data) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }
    if (props.settingReducer.gmailSuggestions && props.settingReducer.gmailSuggestions.data) {

      let gmailSuggestion = [];
      let name = '';

      props.settingReducer.gmailSuggestions.data.map((suggestion) => {
        // if (suggestion.name != null) {
        //   name = suggestion.name;
        // } else {
        //   name = suggestion.email.split('@')[0];
        // }

        gmailSuggestion.push({ label: suggestion.name, value: suggestion.id, email: suggestion.email });
      })
      this.setState({ gmailSuggestion });
    }
    // if (props.settingReducer.gmailSuggestions.data) {
    //   this.setState({ gmailSuggestion: props.settingReducer.gmailSuggestions.data })
    // }
  }

  getChangeSuggestions = (event, fieldkey) => {
    let val = { name: event.target.value };
    this.handleChange({ fieldid: "16", contactvalue: event.target.value.trim() }, fieldkey);
    this.props.getSuggestionData(this.props.apolloClient.client, val);
  }

  handleNameChange = (event, values, fieldkey) => {
    if (values) {

      if (values.email)
        this.handleAddMoreField('value', 'email', 0, values.email);

      if (values.label) {
        this.handleChange({ fieldid: "16", contactvalue: values.label.trim() }, fieldkey);
      } else {
        this.handleChange({ fieldid: "16", contactvalue: "" }, fieldkey);
      }
      
    }
  }

  handleCompanyChange = (val, newValue) => {
    let id = (newValue.__isNew__) ? "0" : newValue.value;
    let comp_values = { id: id, name: newValue.label };
    this.handleChange({ fieldid: "17", values: comp_values }, 'company');
    this.setState({ comp_value: id });
  };

  // handleCompanyCreate = (inputValue) => {

  //   console.log(inputValue,'ajsdakjshdk');
  //     const { comp_options } = this.state;
  //     const newOption = { label: inputValue, value: inputValue, isnew: 1};
  //     let comp_values = { id: "0", name: newOption.label };
  //     this.handleChange({ fieldid: "17", values: comp_values }, 'company');
  //     comp_options.map((opt,ind)=>{
  //       if (opt.isnew)
  //       {
  //         delete opt[ind];
  //       }
  //       else
  //       {
  //         comp_options.push(newOption)
  //       }
  //     })

  //     this.setState({
  //       comp_isLoading: false,
  //       comp_options: comp_options,
  //       comp_value: newOption,
  //     });

  // };
  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    }, () => this.forceUpdate())
  }

  handleNormalFields = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCountryChange = (event, values) => {
    this.setState({ country: values });
  }

  focusInValue = () => {
    this.setState({ focusValue: true })
  };

  focusOutValue = () => {
    this.setState({ focusValue: false })
  };

  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name) => {
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if (key != -1 && this.state.fieldsList[key].fieldtype.id != "4" && this.state.fieldsList[key].fieldtype.id != "8") {
      if (this[name]) {
        this[name].validate(val.value);
      }
    }
    //return
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      }
    })

  };
  handleBlur = (event, ref) => {
    if (this[ref].props.id == "tagInputId1") {
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      }, 10)
    }
    this[ref].validate(event.target.value);
  }

  handleFormSubmit = () => {
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));

    submitData = Object.values(submitData).map((row) => {
      if (row.fieldid == "16") {   // name
        row.names = { firstname: row.contactvalue, lastname: this.state.lastname }
        delete row['contactvalue'];
      }

      if (row.fieldid == "23" || row.fieldid == "24" || row.fieldid == "22" || row.fieldid == "21") { //website ,social, phone, email
        if (row.values.length == 1 && (row.values[0].id == null || row.values[0].value == "")) {
          row.values = [{ "fieldid": row.fieldid, value: [] }]
        }
        if (row.values.length > 1) {
          row.values.map((val, i) => {
            if (val.value == "") {
              row.values.splice(i, 1);
            }
          })
        }

        if (row.fieldid == "22") {
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = []
            } else {
              if (row.values.length > 0) {
                var foundMobile = this.findWithAttr(row.values, 'id', "2");
                if (foundMobile != -1) {
                  var index = 1;
                  row.values.map((val, i) => {
                    if (val.id == "2") {
                      row.values[i].srno = "1"
                    } else {
                      row.values[i].srno = (index + 1).toString()
                    }
                    index++;
                  })
                }
              }
            }
          } else {
            if (row.values.length > 0) {
              var foundMobile = this.findWithAttr(row.values, 'id', "2");
              if (foundMobile != -1) {
                var index = 1;
                row.values.map((val, i) => {
                  if (val.id == "2") {
                    row.values[i].srno = "1"
                  } else {
                    row.values[i].srno = (index + 1).toString()
                  }
                  index++;
                })
              }
            }
          }
        }
        if (row.fieldid == "23" || row.fieldid == "24" || row.fieldid == "21") {//make value blank array if notfill in this field 
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = []
            }
          }
        }
      }
      if (row.fieldid === "26" || row.fieldid === "19" || row.fieldid === "17") {  //Dropdown company, Visibility, contacttype
        row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
      }
      var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
      if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
        row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
      }
      if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
        if (row.values.length > 0) {
          var ar = [];
          row.values.map((val, i) => {
            ar.push({ srno: (i + 1).toString(), id: val.id, value: val.optionvalue })
          })
        }
        row.values = ar
      }
      //validating date fiels
      if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
        if (this.state.fieldsList[key].recordcreationdefaultvalue == 2 && row.values == null) {
          return false
        } else if (this.state.fieldsList[key].recordcreationdefaultvalue != 2 && row.values == null) {
          row.contactvalue = "";
          delete row.values;
        } else if (row.values == "Invalid Date") {
          return false
        } else {
          // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          // row.contactvalue = moment.utc(logdate).format('X');
          var dt = moment(row.values).format('YYYY-MM-DD')
          row.contactvalue = dt;
          delete row.values;
        }
      }


      if (row.fieldid == "28") { //tags
        if (row.values.length > 0) {
          var objArr = [];
          row.values.map((val, k) => {
            var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
            var tagId = "0";
            if (foundKey !== -1) {
              tagId = this.state.systemTagsList[foundKey].id
            }
            objArr.push({ "srno": (k + 1).toString(), value: val, id: tagId })
          })
          row.values = objArr
        }
      }

      if (row.fieldid == "25") { // Address
        row.contactvalue = {
          street: row.contactvalue,
          city: this.state.city,
          state: this.state.addressState,
          zipcode: this.state.zipcode,
          country: this.state.country.name
        }
      }

      if (row.fieldid == "20") {//owner value set 
        if (row.values) {
          row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
        } else {
          row.values = [];
        }
        delete row.contactvalue;
      }
      return row;
    })

    let list = {
      filterdata: this.props.allstates.filterdata,
      list: {
        limit: this.props.allstates.pageSize,
        pageno: this.props.allstates.currentPage + 1,
        searchtext: this.props.allstates.searchText,
        sorttype: this.props.allstates.sorttype,
        type: "list",
        fieldId: this.props.allstates.fieldId,
        columnname: this.props.allstates.column_name
      },
      filterid: 1
    };
    let req = {
      listParams: list,
      contactdata: submitData,
      leadid: 0,
      sidebarParams: this.state.datafromsidebar,
    }

    this.props.handleClose();
    this.props.saveContactDetail(this.props.apolloClient.client, req);


    // var arr1 = []
    // var arr2 = []
    // this.state.fieldsList.map((v,i)=>{
    //   arr1.push(parseInt(v.id))
    // })
    // Object.values(this.state.fieldsObj).map((v,i)=>{
    //   arr2.push(parseInt(v.fieldid))
    // })
    // var valueNotEnter = this.arrDifference(arr1,arr2)
    // console.log("valueNotEnter", valueNotEnter);
    // valueNotEnter.map((v,i)=>{
    //   const dataobj = this.state.fieldsList.find(val => parseInt(val.id) === v);
    //   console.log(v,"-",dataobj.fieldtype);
    //   //if(dataobj.fieldtype)
    // })
  };

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1)
        arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1)
        arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  render() {
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create New Contact</h4>
          <div className="d-flex">
            <Tooltip title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <ValidatorForm noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
          <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`}>
            <Grid container spacing={2}>
              {this.state.isDataLoading && <CircularProgress size={25} className="loaderPopupCenter" />}
              {!this.state.isDataLoading && createContactDynamicFields(this)}
            </Grid>
          </div>

          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button disabled={this.state.isDataLoading} variant="contained" type="submit" color="primary">Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  contactReducer: state.contactReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});

const mapDispatchToProps = dispatch => {
  return {
    getSuggestionData: (client, request) => { dispatch(actionCreators.getSuggestionData(client, request)) },
    getCompanyRecList: (client) => { dispatch(actionCreators.getCompanyRecList(client)) },
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    saveContactDetail: (client, request) => { dispatch(actionCreators.saveContactDetail(client, request)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewContact);
