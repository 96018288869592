import React, { Component } from "react";
import { IconButton, Icon, withStyles, Grid } from "@material-ui/core";
import ProfileEmailMenu from "./ProfileEmailMenu";
import LeadInboxCompose from "./LeadInboxCompose";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

class LeadEmail extends Component {
    state = {
        selectedDate: new Date(),
        isOpenedEmail: false,
        isOpenedReplayEmail: false,
    };
    handleDateChange = date => {
        console.log(date);

        this.setState({ date });
    };
    toggleEmail = () => {
        this.setState(oldStateEmail => ({ isOpenedEmail: !oldStateEmail.isOpenedEmail }));
    }
    toggleReplayEmail = () => {
        this.setState(oldStateReplayEmail => ({ isOpenedReplayEmail: !oldStateReplayEmail.isOpenedReplayEmail }));
    }
    render() {
        let { isOpenedEmail, isOpenedReplayEmail } = this.state;
        return (
            <div className="lead_upcoming mt-16">
                <div className="box_portlet">
                    <div className="box_header">
                        <div className="box_label"><h3 className="box_title">November</h3></div>
                    </div>
                    <div className="box_body">
                        <div className="lead_pl">
                            <Icon className="icon_left">mail</Icon>
                            <Grid container justify="space-between">
                                <Grid onClick={this.toggleEmail} className="flex-auto cursor_poiter">
                                    <h5 className="mb-0">Email (To John)</h5>
                                    <p className="mt-4 mb-4 font-size-13 text-hint">27 November 2019 at 09:00 AM GMT +5:30</p>
                                </Grid>
                                <Grid>
                                    <IconButton aria-label="Pin" size="small" className="button_size hover_show">
                                        <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" fillOpacity="0.6" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                                    </IconButton>
                                    <IconButton aria-label="Add" size="small" className="hover_show">
                                        <IconSmall>add_circle</IconSmall>
                                    </IconButton>
                                    <IconButton aria-label="Reply" size="small" className="hover_show" onClick={this.toggleReplayEmail}>
                                        <IconSmall>reply</IconSmall>
                                    </IconButton>
                                    <ProfileEmailMenu />
                                </Grid>
                            </Grid>
                            {!isOpenedEmail &&
                                <p className="mt-0 font-size-14"><span className="font-weight-500">Hello John,</span> - How are you donig? This is testing logs for email</p>
                            }
                            {isOpenedEmail &&
                                <div className="border_bx">
                                    Hello John,<br />
                                    How are you donig?<br />
                                    This is testing logs for email
                            </div>
                            }
                            {isOpenedReplayEmail &&
                                 <LeadInboxCompose handleClose={this.toggleReplayEmail} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeadEmail;
