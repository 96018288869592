import React, { Component } from "react";
import { Container, Grid, Button, withStyles, CircularProgress, Backdrop } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';
import { Link } from "react-router-dom";

const styles = (theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "45%",
    left: "28%",
    marginTop: -12,
    marginLeft: -12
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class UserCredential extends Component {
  state = {
    email: "",
    password: "",
    repeatPassword: "",
    loading: false,
    backdrop: false,
    token: "",
    err_msg: ''
  };
  componentDidMount() {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      const formData = this.state;
      if (value !== formData.password) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('minLength', (value) => {
      if (value.length < 8) {
        this.setState({
          err_msg: 'Minimum length 8.'
        })
        return false;
      }
      if (value.length > 100) {
        this.setState({
          err_msg: 'Maximum length 100.'
        })
        return false;
      }

      var specialChar = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (!specialChar.test(value)) {
        this.setState({
          err_msg: 'Password should contain special character.'
        })
        return false;
      }

      if (this.checkIsNumeric(value)) {
        this.setState({
          err_msg: 'Should Contain Numeric value.'
        })
        return false;
      }
      if (!value.match(/[A-Z]/g)) {
        this.setState({
          err_msg: 'Should Contain 1 UpperCaseLetter.'
        })
        return false;
      }
      if (!value.match(/[a-z]/g)) {
        this.setState({
          err_msg: 'Should Contain 1 LowerCaseLetter.'
        })
        return false;
      }
      if (value.indexOf(' ') >= 0) {
        this.setState({
          err_msg: 'Remove space from password.'
        })
        return false;
      }
      this.setState({
        err_msg: ''
      })
      return true;

    });
  }
  componentWillReceiveProps() {
    this.setState({ loading: false, backdrop: false })
  }
  checkUpperCaseLetter(value) {
    let arr = value.split('');
    let chk_flag = false;

    arr.map((val, index) => {
      if (val == val.toUpperCase() && isNaN(val * 1)) {
        console.log("uppvalue", val)
        chk_flag = true;
      }
    })
    console.log("chk_flag", chk_flag);
    return chk_flag;
  }
  checkIsNumeric(value) {
    let chk_flag = true;
    let arr = value.split('');
    arr.map((val, index) => {
      if (!isNaN(val * 1)) {
        chk_flag = false;
      }
    })
    return chk_flag;
  }
  handleChangePassword = event => {
    event.persist();
    this.setState({
      password: event.target.value
    });
  };
  handleChangeConfirmPassword = event => {
    event.persist();
    this.setState({
      repeatPassword: event.target.value
    });
  }
  handleFormSubmit = event => {
    this.setState({ loading: true, backdrop: true })
    let url = window.location.href;
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    var arr = queryString.split('&');
    var email = arr[0].split('=');
    var token = arr[1].split('=');
    this.setState({ loading: true })
    let credential_data = {
      email: email[1],
      password: this.state.password,
      token: token[1]
    }
    this.props.UserVerify(credential_data);
  };

  handleBlur = (event,ref) => {
      this[ref].validate(event.target.value);
  }

  render() {
    let { password, repeatPassword } = this.state;
    let { classes } = this.props;
    return (
      <Container maxWidth="md" className="h-100vh">
        <Grid container justify="center" alignItems="center" className="h-100">
          <div className="center_bx">
            <Grid container>
              <Grid item sm={6} xs={12}>
                <div className="pl-50 pr-50 py-80">
                  <h3 className="text-center font-weight-bold">Reset your password</h3>
                  <p className="text-center mt-0">Lorem Ipsum is simply dummy text.</p>
                  <ValidatorForm noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      ref={ref => this.passwordRef = ref}
                      onBlur={(event)=>this.handleBlur(event,'passwordRef')}
                      className="mb-16 w-100"
                      label="Password"
                      onChange={this.handleChangePassword}
                      type="password"
                      name="password"
                      value={password}
                      validators={["required", 'minLength']}
                      errorMessages={["this field is required", this.state.err_msg]}
                      autoComplete="off"
                    />
                    <TextValidator
                      ref={ref => this.passwordcRef = ref}
                      onBlur={(event)=>this.handleBlur(event,'passwordcRef')}
                      className="mb-24 w-100"
                      label="Confirm Password"
                      onChange={this.handleChangeConfirmPassword}
                      type="Password"
                      name="repeatPassword"
                      value={repeatPassword}
                      validators={['isPasswordMatch', 'required']}
                      errorMessages={[
                        'Password not match.',
                        "this field is required."
                      ]}
                      autoComplete="off"
                    />
                    <div className="flex flex-middle">
                      <Button variant="contained" color="primary" type="submit">Submit</Button>
                      {this.state.loading && (
                        <Backdrop
                          className={classes.backdrop}
                          open={this.state.backdrop}
                        >
                          <CircularProgress size={60} color="inherit" />
                        </Backdrop>
                      )}
                      <span className="ml-16 mr-16">or</span>
                      <Link to="/sign-in/" className="link">Sign in</Link>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
              <Grid item sm={6} xs={12} className="layers_bg align-items-end d-flex">
                <div>
                  <h2>What is Lorem Ipsum?</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.authReducer,
  login: state.login
});
const mapDispatchToProps = dispatch => {
  return {
    UserVerify: (response_data) => dispatch(actionCreators.userVerify(response_data)),
  }
}
export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserCredential)
);
