import React, { Component } from "react";
// import $ from "jquery";
import {
  Dialog,
  IconButton,
  Button,
  Icon,
  Tooltip,
  Grid,
  DialogActions,
  FormControl,
  MenuItem,
  TextField,
  Checkbox,
  InputLabel,
  FormControlLabel,
  Grow,
  Select,
  ListSubheader,
  withStyles,
} from "@material-ui/core";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Redirect, Route } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";

import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
import { lastDayOfDecade } from "date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from 'moment';

import { MegadraftEditor, editorStateFromRaw, editorStateToJSON, MegadraftPlugin, insertDataBlock } from "megadraft-denistsuman";

import { convertFromHTML, EditorState, ContentState, Modifier, Entity, genKey, ContentBlock } from 'draft-js';

import LinkInput from "megadraft-denistsuman/lib/entity_inputs/LinkInput";
import icons from "megadraft-denistsuman/lib/icons"
import 'megadraft-denistsuman/dist/css/megadraft.css'
import FontAwesome from "react-fontawesome";
import { stateToHTML } from 'draft-js-export-html';
import { Map } from 'immutable';
import clearFormatting from 'draft-js-clear-formatting';
const { BlockContent, BlockData, BlockInput, CommonBlock, blockProps } = MegadraftPlugin;
let messageeditstate = '';
const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.0rem",
  }
}))(Icon);

class clearFormatIcon extends React.Component {
  render() {
    return (
      <>
        <div className="pt-12">
          <FontAwesome onClick={this.clearFormating} className={"fa fa-underline toolbar__button"} style={{ fontSize: '18px', paddingBottom: '15px', paddingTop: "0px" }} />
        </div>
      </>
    );
  }
}

class UnderlineIcon extends React.Component {
  render() {
    return (
      <>
        <div className="pt-12">
          <FontAwesome className={"fa fa-underline toolbar__button"} style={{ fontSize: '18px', paddingBottom: '15px', paddingTop: "0px" }} />
        </div>
      </>
    );
  }
}
class CustomSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      selecttemplate: "none",
      placeholder: "none",
      Template_list: [],
      current_template: [],
      subject_edit: '',
      message_edit: '',
    };
  }


  componentWillReceiveProps(props) {

    if (props.thisData.props.settingReducer.emailtemplatelist.data) {
      let email_list = props.thisData.props.settingReducer.emailtemplatelist.data;
      this.setState({ Template_list: email_list })
    }

  }

  handleSelectPlaceholder = (event) => {

    let value = "#" + event.target.value.split("#")[1];
    let type = event.target.value.split("#")[0];

    if (this.props.editorType == "Message") {
      let meta = event.target.value;
      let editorState = this.props.thisData.state.editorState;
      let currentContent = editorState.getCurrentContent();
      let selection = editorState.getSelection();
      let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#' + type, meta });
      let textWithEntity = Modifier.insertText(currentContent, selection, value, null, entityKey);
      let neweditorstate = EditorState.push(editorState, textWithEntity, 'insert-characters');

      currentContent = neweditorstate.getCurrentContent();
      selection = neweditorstate.getSelection();
      let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
      let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
      neweditorstate = EditorState.push(neweditorstate, textWithEntity1, 'insert-characters');

      messageeditstate = neweditorstate;
      this.props.thisData.onChange(neweditorstate);
    }
    else {
      //subject
      // let subjectmeta = event.target.value;
      let editorSubjectState = this.props.thisData.state.subject;
      let currentSubjectContent = editorSubjectState.getCurrentContent();
      let subjectSelection = editorSubjectState.getSelection();

      let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#' + type, value });
      let textWithEntity = Modifier.insertText(currentSubjectContent, subjectSelection, value, null, entityKey);
      let newsubjecteditorstate = EditorState.push(editorSubjectState, textWithEntity, 'insert-characters');

      currentSubjectContent = newsubjecteditorstate.getCurrentContent();
      subjectSelection = newsubjecteditorstate.getSelection();
      let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
      let textWithEntity1 = Modifier.insertText(currentSubjectContent, subjectSelection, " ", null, entityKey1);
      newsubjecteditorstate = EditorState.push(newsubjecteditorstate, textWithEntity1, 'insert-characters');

      this.props.thisData.handleChangeSubject(newsubjecteditorstate);
    }
  }

  handleSelectTemplate = (event) => {

    let emailTemplatelist = this.state.Template_list;
    let key = this.props.thisData.findWithAttr(emailTemplatelist, 'id', event.target.value);
    if (key != -1) {
      let selectedMessageTemplate = JSON.parse(JSON.parse(emailTemplatelist[key].message));

      let newMessageState = JSON.parse(editorStateToJSON(this.props.thisData.state.editorState));
      let MessageBlocks = newMessageState.blocks;
      let selectedMessageBlocks = selectedMessageTemplate.blocks;

      (selectedMessageBlocks) && selectedMessageBlocks.map((block, key) => {
        selectedMessageBlocks[key].key = genKey()
      })

      var finalObj = MessageBlocks.concat(selectedMessageBlocks);
      newMessageState.blocks = finalObj;
      messageeditstate = newMessageState;
      this.props.thisData.onChange(editorStateFromRaw(newMessageState));

      // subject
      let selectedSubjectTemplate = JSON.parse(JSON.parse(emailTemplatelist[key].subject));

      let newSubjectState = JSON.parse(editorStateToJSON(this.props.thisData.state.subject));
      let SubjectBlocks = newSubjectState.blocks;
      let selectedSubjectBlocks = selectedSubjectTemplate.blocks;

      (selectedSubjectBlocks) && selectedSubjectBlocks.map((block, key) => {
        selectedSubjectBlocks[key].key = genKey()
      })

      var subjectFinalObj = SubjectBlocks.concat(selectedSubjectBlocks);
      newSubjectState.blocks = subjectFinalObj;

      this.props.thisData.handleChangeSubject(editorStateFromRaw(newSubjectState));
    }
  }
  togglesidbarCLick = () => {
    this.setState({ checked: !this.state.checked })
  }

  render() {
    let { checked, Template_list } = this.state;

    return (
      <>
        <div className="sidebar__menu">
          <Grid container spacing={1}  >
            {checked ?
              <>
                <Grid item className="ml-10">
                  <Select

                    name="selecttemplate"
                    id="selecttemplate"
                    label="Templates"
                    className="font-size-13"
                    value={this.state.selecttemplate}
                    disableUnderline
                    onChange={this.handleSelectTemplate}
                  >
                    <MenuItem value="none">Templates</MenuItem>
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>My Template</ListSubheader>
                    {Template_list.map((template) => (
                      (template.isshared == false) &&
                      <MenuItem className="" value={template.id}><span className="font-weight-500 font-size-14 ml-5 mr-5"> {template.name}</span></MenuItem>
                    ))}
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>Shared Template</ListSubheader>
                    {Template_list.map((template) => (
                      (template.isshared == true) &&
                      <MenuItem className="" value={template.id}><span className="font-weight-500 font-size-14 ml-5 mr-5"> {template.name}</span></MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item className="">
                  <Select
                    name="placeholder"
                    id="placeholder"
                    label="Merge Fields"
                    className="font-size-13"
                    value={this.state.placeholder}
                    disableUnderline
                    onChange={this.handleSelectPlaceholder}
                  >
                    <MenuItem value="none">Merge Fields</MenuItem>
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>Recipient Merge Fields</ListSubheader>
                    <MenuItem className="wrapper-rec" value="rec#FirstName"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" > #FirstName</span></MenuItem>
                    <MenuItem className="wrapper-rec" value="rec#LastName"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" > #LastName</span></MenuItem>
                    <MenuItem className="wrapper-rec" value="rec#FullName"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" > #FullName</span></MenuItem>
                    <MenuItem className="wrapper-rec" value="rec#Email"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" > #Email</span></MenuItem>
                    <MenuItem className="wrapper-rec" value="rec#Phone"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" > #Phone</span></MenuItem>
                    <MenuItem className="wrapper-rec" value="rec#CompanyName"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" > #CompanyName</span></MenuItem>
                    <MenuItem className="wrapper-rec" value="rec#Title"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" > #Title</span></MenuItem>
                    <MenuItem className="wrapper-rec" value="rec#Address"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" > #Address</span></MenuItem>
                    <MenuItem className="wrapper-rec" value="rec#ContactType"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" > #ContactType</span></MenuItem>
                    <MenuItem className="wrapper-rec" value="rec#Owner"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" > #Owner</span></MenuItem>
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>Sender Merge Fields</ListSubheader>
                    <MenuItem className="wrapper-send" value="send#SenderName"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-send-hashplaceholder"> #SenderName</span></MenuItem>
                    <MenuItem className="wrapper-send" value="send#SenderEmail"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-send-hashplaceholder"> #SenderEmail</span></MenuItem>
                    <MenuItem className="wrapper-send" value="send#SenderPhone"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-send-hashplaceholder"> #SenderPhone</span></MenuItem>
                    <MenuItem className="wrapper-send" value="send#SenderCompanyName"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-send-hashplaceholder"> #SenderCompanyName</span></MenuItem>
                    <MenuItem className="wrapper-send" value="send#SenderAddress"><span className="font-weight-500 font-size-14 ml-5 mr-5 crm-send-hashplaceholder"> #SenderAddress</span></MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <IconButton className="p-0 mt-8 mr-4" onClick={this.togglesidbarCLick}><IconSmall >clear</IconSmall></IconButton>
                </Grid>
              </>

              :
              <Grid item>
                <IconButton className="p-0" style={{ marginTop: '-1px' }} onClick={this.togglesidbarCLick}><IconSmall >add</IconSmall></IconButton>
                {/* <IconButton className="toolbar__button mr-0" style={{ fontSize: '12px', backgroundColor: '#fff', color: '#3192e7', width: '13px' }} onClick={this.togglesidbarCLick}><IconSmall >add</IconSmall></IconButton> */}
              </Grid>
            }

          </Grid>
        </div>
        {/* <div className="sidebar__menu">
          <FontAwesome onClick={this.togglesidbarCLick} className={"fa fa-plus-circle toolbar__button"} style={{ fontSize: '30px', backgroundColor:'#fff' }} />
        </div> */}

      </>
    );
  }
}

const entityInputs = {
  LINK: LinkInput,
}

class EditTemplate extends Component {

  constructor(props) {
    super(props);

    const myContent = {
      "entityMap": {},
      "blocks": [
        {
          "key": "ag6qs",
          "text": "",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }
      ]
    };
    const mySubject = {
      "entityMap": {},
      "blocks": [
        {
          "key": genKey(),
          "text": "",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }
      ]
    };
    const editorState = editorStateFromRaw(myContent);
    messageeditstate = editorState;
    const subjectEditorState = editorStateFromRaw(mySubject);
    let name = "";
    this.state = {
      isshared: false,
      editorState: editorState,
      isshared: false,
      message: {},
      subject: subjectEditorState,
      name: "",
      templateid: "",
      html: "",
      isSubmitDisable: !(name.length > 0 && subjectEditorState.getCurrentContent().hasText() && editorState.getCurrentContent().hasText()),
    };
  }


  onChange = (editorState) => {
    messageeditstate = editorState;
    this.setState({ editorState });
    this.setState({ isSubmitDisable: !(this.state.name.length > 0 && this.state.subject.getCurrentContent().hasText() && editorState.getCurrentContent().hasText()) })
  }

  handleChangeSubject = (subject) => {
    this.setState({ subject });
    this.setState({ isSubmitDisable: !(this.state.name.length > 0 && this.state.editorState.getCurrentContent().hasText() && subject.getCurrentContent().hasText()) })
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.emailtemplatedetail.data) {

      let emailtemplateDetail = props.settingReducer.emailtemplatedetail.data;
      const myContent = JSON.parse(JSON.parse(emailtemplateDetail.message));
      const mySubject = JSON.parse(JSON.parse(emailtemplateDetail.subject));

      const editorState = editorStateFromRaw(myContent);
      const subjectEditorState = editorStateFromRaw(mySubject);
      const contentState = editorState.getCurrentContent();
      let html = stateToHTML(contentState);
      this.setState({ templateid: emailtemplateDetail.id, name: emailtemplateDetail.name, editorState: editorState, subject: subjectEditorState, html: html }, this.forceUpdate())
    }
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
    this.setState({ isSubmitDisable: !(event.target.value.length > 0 && this.state.subject.getCurrentContent().hasText() && this.state.editorState.getCurrentContent().hasText()) })
  }

  handleFormSubmit = () => {
    const { editorState } = this.state;

    const content = editorStateToJSON(editorState);
    let string = JSON.stringify(content);
    const contentState = editorState.getCurrentContent();
    let html = stateToHTML(contentState);

    const subjectContent = editorStateToJSON(this.state.subject);
    let SubjectString = JSON.stringify(subjectContent);
    const contentSubject = this.state.subject.getCurrentContent();
    let subjecthtml = stateToHTML(contentSubject);


    let updateEmailParams = {
      isshared: this.props.isshared,
      message: string,
      subject: SubjectString,
      name: this.state.name,
      templateid: parseFloat(this.state.templateid),
      messagehtml: html,
      subjecthtml: subjecthtml,
    };

    this.props.updateEmailTemplate(this.props.apolloClient.client, updateEmailParams);
    this.props.handleClose();
  }
  getCustomSidebar = (props) => {
    return <CustomSidebar thisData={this} editorType="Message" />
  }
  getCustomSubjectSidebar = (props) => {
    return <CustomSidebar thisData={this} editorType="Subject" />
  }
  render() {
    let onChangeFunction = this.onChange;
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;

    return (
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="md"
        fullWidth={true}
        className={`custom_dialog ${
          minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
          } ${minimizeScreen ? "alignitemsend" : ""} ${
          fullScreen ? "fullscreen" : ""
          } crm_dialog_create_template`}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Edit Email Template</h4>
          <div className="d-flex">
            <Tooltip
              title={minimizeScreen ? "Normal Screen" : "Minimize Screen"}
            >
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">
                  {minimizeScreen ? "add" : "remove"}
                </Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={fullScreen ? "Exit Full Screen" : "Full Screen"}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">
                  {fullScreen ? "fullscreen_exit" : "fullscreen"}
                </Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <ValidatorForm noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
          <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`} style={{ overflow: "visible" }}>
            <Grid container spacing={1} className="form-container-wrapper">
              <Grid item xs={12}>
                <label className="font-weight-500" style={{ fontSize: '12px' }}>Template Name</label>
                <TextValidator
                  type="text"
                  className="w-100 input_bx"
                  onChange={(event) => this.handleChange(event)}
                  name={"name"}
                  placeholder="Add Template Name"
                  value={this.state.name}
                />
              </Grid>
              <Grid item xs={12} className="subject-editor">
                <label className="font-weight-500" style={{ fontSize: '12px' }}>Template Content</label>
                <MegadraftEditor
                  placeholder='Add Subject'
                  theme="white-thin"
                  editorState={this.state.subject}
                  onChange={this.handleChangeSubject}
                  actions={[]}
                  // entityInputs={entityInputs}
                  sidebarRendererFn={this.getCustomSubjectSidebar}
                // modalOptions={this.state.modalOptions}
                />
              </Grid>
              <Grid item xs={12} className="message-editor">
                <MegadraftEditor
                  placeholder='Add Message'
                  theme="white-thin"
                  editorState={this.state.editorState}
                  onChange={this.onChange}
                  actions={[
                    { type: "inline", label: "B", style: "BOLD", icon: icons.BoldIcon },
                    { type: "inline", label: "I", style: "ITALIC", icon: icons.ItalicIcon },
                    { type: "inline", label: "U", style: "UNDERLINE", icon: UnderlineIcon },
                    {
                      type: "custom", icon: icons.H2Icon, action() {
                        let options = {
                          inline: true,
                          entities: false,
                          lists: false,
                        }
                        const newEditorState = clearFormatting(messageeditstate, options)
                        messageeditstate = newEditorState;
                        onChangeFunction(newEditorState);
                      }, active() { }
                    },
                    { type: "separator" },
                    { type: "block", label: "UL", style: "unordered-list-item", icon: icons.ULIcon },
                    { type: "block", label: "OL", style: "ordered-list-item", icon: icons.OLIcon },
                    { type: "separator" },
                    { type: "entity", label: "Link", style: "link", entity: "LINK", icon: icons.LinkIcon },
                  ]}
                  entityInputs={entityInputs}
                  sidebarRendererFn={this.getCustomSidebar}
                // modalOptions={this.state.modalOptions}
                />
              </Grid>
            </Grid>
          </div>
          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button disabled={this.state.isSubmitDisable} variant="contained" type="submit" color={this.state.isSubmitDisable ? "default" : "primary"}>Save</Button>
          </DialogActions>
        </ValidatorForm>

      </Dialog>
    );
  }
}
const mapStateToProps = (state) => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  leadReducer: state.leadReducer,
  contactReducer: state.contactReducer,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyRecList: (client) => { dispatch(actionCreators.getCompanyRecList(client)); },
    getContactType: (client) => dispatch(actionCreators.getContactType(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    updateEmailTemplate: (client, request) => { dispatch(actionCreators.updateEmailTemplate(client, request)); },
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),

    // getLeadField: (client, leadId) => dispatch(actionCreators.getLeadField(client, leadId)),
    // loadLeadStatues: (client) => dispatch(actionCreators.loadLeadStatues(client)),
    // AddLeadStatus: (c, req) => dispatch(actionCreators.AddLeadStatus(c, req)),
    // updateLeadStatusRecord: (c, req) => dispatch(actionCreators.updateLeadStatusRecord(c, req)),
    // clearNotification: () => dispatch(actionCreators.clearNotification()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTemplate);