import React, { Component } from "react";
import { Fab,Grid, withStyles,Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import ContactProfileFrame from "../Contacts/ContactProfileFrame";
import CreateNewContactDialog from "../Contacts/CreateNewContactDialog";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink,withRouter } from "react-router-dom";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
const formatCreateLabel = (value) => `Create "${value}"`;
const isValidNewOption = (value) => value != "";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);
const filter = createFilterOptions();


class People extends Component {
    state = {
        attachment: null,
        drawerOpenContact: false,
        showAddContact: false,
        contact_id: "",
        contactListArray: [],
        allContactList: [],
        createNewContactName:"",
        filterdata:[],
        removeContactPopup: false,
        shouldOpenCreateContactDialog: false,
        FullCreateContactDialog: false,
        minimizeContactDialog: false,
        type: "contact",
        recordtype: "",
        newContactData: {},
        opportunities_id:"",
        cmpId:"",
    };

    componentDidMount() {
        this.props.getContactRecList(this.props.apolloClient.client);

        if(this.props.recordId){
            this.setState({
                recordId:this.props.recordId,
                recordtype:this.props.recordtype,
            },()=>{
                this.forceUpdate()
                if(this.props.recordtype == "opportunities"){
                  this.setState({
                      opportunities_id:this.props.recordId
                  })
                }
                if(this.props.recordtype == "company"){
                  this.setState({
                      cmpId:this.props.recordId
                  })
                }
                var req ={
                  id:this.props.recordId,
                  type:this.state.type,
                  recordtype:this.props.recordtype,
                }
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
    }
    componentWillReceiveProps(props) {
        if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
            this.setState({
                recordId:props.recordId,
                recordtype:props.recordtype,
            },()=>{
                this.forceUpdate()
                if(this.props.recordtype == "opportunities"){
                  this.setState({
                      opportunities_id:this.props.recordId
                  })
                }
                if(this.props.recordtype == "company"){
                  this.setState({
                      cmpId:this.props.recordId
                  })
                }
                var req ={
                  id:props.recordId,
                  type:this.state.type,
                  recordtype:props.recordtype,
                }
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
        if (props.contactReducer.contactFieldData.data && props.contactReducer.contactFieldData.is_loaded) {
            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
        }
         
         
        if (props.settingReducer.relatedDataContact.data) {
            props.settingReducer.relatedDataContact.is_loaded = false
            
            this.setState({
                contactListArray:props.settingReducer.relatedDataContact.data.data,
            },()=>this.forceUpdate());
        }

         if (props.settingReducer.contactRecList && props.settingReducer.contactRecList.data && props.settingReducer.contactRecList.data.data){
          let contactOptions = [];
          props.settingReducer.contactRecList.data.data.map((compOpt)=>{
            contactOptions.push({ label: compOpt.name, value: compOpt.contact_id});
          })
          this.setState({ allContactList: contactOptions});
        }
         
    }

  toggleContact = () => {
      this.setState(oldStateContact => ({ isOpenedContact: !oldStateContact.isOpenedContact}));
      if(this.state.isOpenedContact){
          this.setState({
              showAddContact: false
          },()=>this.forceUpdate());
      }
  }

  /* START cmp dialoge */

    handleContactDialogClose = () => {
        this.setState({ shouldOpenCreateContactDialog: false, FullCreateContactDialog: false, minimizeContactDialog: false });
    };

    handleContactDialogMinimize = () => {
        this.setState(oldStateminimizeContactDialog => ({ minimizeContactDialog: !oldStateminimizeContactDialog.minimizeContactDialog }));
        this.setState({ FullCreateContactDialog: false })
    };

    handleContactDialogFull = () => {
        this.setState(oldStateFullCreateContactDialog => ({ FullCreateContactDialog: !oldStateFullCreateContactDialog.FullCreateContactDialog }));
        this.setState({ minimizeContactDialog: false })

    };
    
    /* END cmp dialoge */
    closeDrawerRight = (e) => {
        //e.preventDefault()
        this.setState({
          drawerOpenContact: false,
          drawerOpenContact: false,
        });
    };
    toggleAddContact = () => {
        this.setState({ 
            isOpenedContact: true,
            showAddContact: !this.state.showAddContact
        });
    }
    handleDrawerContactRight = (contact_id) => {
      if (this.state.contact_id && (this.state.contact_id === contact_id)) { } else {
            this.setState({
                contact_id: contact_id,
                isDataLoading: true,
            }, () => {
                this.forceUpdate()
            })
        }

        setTimeout(() => {
            this.setState({
                drawerOpenContact: true,
            }, () => {
                this.forceUpdate()
            })
        }, 2000);

    };
    handleContactLaunch = (contact_id) => {
        this.props.history.push('/contact/contact/'+contact_id)
    };
    handleRemoveContact = (contact_id) => {
          this.setState({
                removeContactPopup: true,
                deleteContactId: contact_id,
          }, () => {
              this.forceUpdate()
          })
        
    };
    toggleRemoveContactPopup = () => {
          if(this.state.removeContactPopup){
            this.setState({
                deleteContactId: "",
            }, () => {
                this.forceUpdate()
            })

          }
          this.setState({
                removeContactPopup: !this.state.removeContactPopup,
          }, () => {
              this.forceUpdate()
          })
        
    };
    confirmremoveContactPopup = () => {
          console.log("this.state.deleteContactId", this.state.deleteContactId);
        if(this.state.deleteContactId){
          if(this.state.recordtype == "opportunities"){
            var req = {
                contactid:parseInt(this.state.deleteContactId),
                opportunitiesid:parseInt(this.state.recordId),
            }
            this.props.deleteOpportunitiesContact(this.props.apolloClient.client,req)
          }
          if(this.state.recordtype == "project"){
            var req = {
                contactid:parseInt(this.state.deleteContactId),
                projectid:parseInt(this.state.recordId),
            }
            this.props.deleteProjectContact(this.props.apolloClient.client,req)
          }
          if(this.state.recordtype == "company"){
            var req = {
                contactid:parseInt(this.state.deleteContactId),
                companyid:parseInt(this.state.recordId),
            }
            this.props.deleteCompanyContact(this.props.apolloClient.client,req)
          }

          this.setState({
              deleteContactId: "",
              removeContactPopup: false,
          }, () => {
              this.forceUpdate()
          })
        }
        
    };

    handleContactChange = (val , newValue) => {
      if(newValue){
          var relateddata = {
              recordtype: this.state.recordtype,
              type: this.state.type,
              id: parseInt(this.state.recordId),
          }

          let id =  (newValue.__isNew__) ? "0" : newValue.value;
          if(id == "0"){
            this.setState({
                newContactData: relateddata,
                shouldOpenCreateContactDialog: true ,
                createNewContactName: newValue.label 
            })
          }else{
            var isAlreadyAdded  = this.state.contactListArray.filter(function(element) {
                if(element.contact.id == id){
                  return element
                }
            });
            if(isAlreadyAdded.length > 0){
              this.setState({ showAddContact: false});
              return
            }
            let data = {srno: "1", id: id, value: newValue.label};
            if(this.state.recordtype == "opportunities"){
              var req = {
                contactdetail:data,
                opportunitiesid:parseInt(this.state.recordId),
              }
              this.props.addNewOpportunitiesContact(this.props.apolloClient.client,req)
            }
            if(this.state.recordtype == "project"){
              var req = {
                contactdetail:data,
                projectid:parseInt(this.state.recordId),
              }
              this.props.addProjectContact(this.props.apolloClient.client,req)
            }
            if(this.state.recordtype == "company"){
              var req = {
                companyid:parseInt(this.state.recordId),
                contactid:parseInt(id),
              }
              this.props.addNewCompanyContact(this.props.apolloClient.client,req)
            }


          }
          this.setState({ showAddContact: false});

      }
    };

  findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
  render() {
    let { drawerOpenContact,isOpenedContact } = this.state;
    /*STRAT contact Display*/
        let displayContactList = []
        {
          this.state.contactListArray.map((item, key) => {
            var contactimg = ""
            if(item.contact.image){
                contactimg = item.contact.image
            }
            if(item.contact.__contactdetail__.length >0){
                var cName = ""
                var cmpName = ""
                var title = ""
                var phone = ""
                var email = ""
                item.contact.__contactdetail__.map((data, key1) => {
                    if(data.__field__.id == "16"){//name
                        cName =  data.fieldvalue;
                    }
                    if(data.__field__.id == "17" && data.values.length > 0){//company
                        cmpName =  data.values[0].value;
                    }
                    if(data.__field__.id == "18"){//title
                        title =  data.fieldvalue +" at ";
                    }
                    if(data.__field__.id == "22" && data.values.length > 0){//phone
                        var srData  = data.values.filter(function(element) {
                            if(element.srno == "1"){
                              return element
                            }
                        });
                        if(srData.length > 0){
                            phone = srData[0].value
                        }else{
                            phone = data.values[0].value
                        }

                    }
                    if(data.__field__.id == "21" &&  data.values.length > 0){//email
                        email =  data.values[0].value;
                    }
                
                })
                displayContactList.push(
                        <div className="box_portlet mt-10 crm-calendar-event">
                                <div className="box_body px-10 py-20">
                                    <div className="lead_pl" style={{paddingLeft: '50px'}}>
                                        <div className="icon_left">
                                            <Fab
                                                  size="small"
                                                  aria-label="Task"
                                                  onClick={()=>this.handleDrawerContactRight(item.contact.id)}
                                                  className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                                              >
                                                 
                                                {!contactimg && <Avatar className="bg-body"><Icon className="activity_icon_normal">person</Icon></Avatar>}
                                                {contactimg && <Avatar className="bg-body" src={contactimg}></Avatar> }
                                                  
                                              </Fab>

                                        </div>
                                        <Grid item className="flex-auto">
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <a onClick={()=>this.handleDrawerContactRight(item.contact.id)} className={"cursor_poiter"}>
                                                      <h6 className="m-0 text_ellipsis">{cName}</h6>
                                                    </a>
                                                </Grid>
                                                <Grid item>
                                                    <div className="font-size-13 related-text">{title}{cmpName}</div>
                                                </Grid>
                                            </Grid>
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <div className="font-size-13 related-text">{email}</div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="font-size-13 related-text">{phone}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {!email && !phone && <span className="related-text">&nbsp;</span>}
                                        <div className="hover_show" style={{top:'-21px',right:'-7px',bottom:'50px'}}>
                                          <IconButton aria-label="Launch" size="small" onClick={()=>this.handleContactLaunch(item.contact.id)}>
                                              <IconSmall style={{fontSize:'18px'}}>launch</IconSmall>
                                          </IconButton>
                                          <IconButton aria-label="Delete" size="small" onClick={()=>this.handleRemoveContact(item.contact.id)}>
                                              <IconSmall style={{fontSize:'18px'}}>close</IconSmall>
                                          </IconButton>
                                      </div>
                                    </div>
                                </div>
                        </div>                       
                )
            }
            
          })
        }
        /*END contact Display*/
    
    return (
      <Card elevation={3} className="p-16 mt-16" style={{overflow: 'initial'}}>
        <Grid container justify="space-between">
            <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleContact}>Contact ({displayContactList.length}) <Icon className="vert-middle">{isOpenedContact ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
            <IconButton aria-label="Add" size="small">
                <IconSmall onClick={()=>this.toggleAddContact()} className={this.state.showAddContact?"plusIconTransform":""}>add</IconSmall>
            </IconButton>
        </Grid>
        {isOpenedContact &&
                      <>
                          {this.state.showAddContact && 
                              <FormControl className="w-100 input_bx" style={{zIndex:10000}}>
                                  <SingleSelect
                                    SelectProps={{
                                      isCreatable: true,
                                      formatCreateLabel,
                                      isValidNewOption,
                                    }}
                                    placeholder="Add contact"
                                    options={this.state.allContactList}
                                    onChange={this.handleContactChange}
                                  />
                              </FormControl>

                          }
                          {(!this.state.showAddContact &&  displayContactList.length == 0) && 
                            <label className="text-hint">
                              <IconButton className="mr-8" component="span">
                                <Icon onClick={()=>this.toggleAddContact()} >business</Icon>
                              </IconButton>
                                Contact
                            </label>
                          }
                          {displayContactList}

                      </>
                    }

                    {this.state.shouldOpenCreateContactDialog && (
                        <CreateNewContactDialog
                            handleClose={this.handleContactDialogClose}
                            open={this.state.shouldOpenCreateContactDialog}
                            handleMinimize={this.handleContactDialogMinimize}
                            handleFull={this.handleContactDialogFull}
                            minimizeScreen={this.state.minimizeContactDialog}
                            fullScreen={this.state.FullCreateContactDialog}
                            name={this.state.createNewContactName}
                            opportunitiesid={this.state.opportunities_id}
                            cmpId={this.state.cmpId}
                            allstates={this.state}
                            dataFromSidebar={this.state.newContactData}
                        />
                    )}

                    <Dialog
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={this.state.removeContactPopup}
                        onClose={this.toggleRemoveContactPopup}
                        disableBackdropClick
                        disableEscapeKeyDown
                        aria-labelledby="ar-remove-dialog-title"
                    >
                        <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the associated contact ?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.toggleRemoveContactPopup} color="primary">
                                Cancel
                                          </Button>
                            <Button type="Button" onClick={() => this.confirmremoveContactPopup()} className="btn_Primary">
                                Ok
                                          </Button>

                        </DialogActions>
                    </Dialog>
                        <Drawer
                        width={"100px"}
                        variant="temporary"
                        anchor={"right"}
                        className="drawer_right"
                        open={drawerOpenContact}
                        onClose={this.closeDrawerRight}
                        ModalProps={{
                            keepMounted: true
                        }}
                    >
                    {this.state.contact_id && <ContactProfileFrame contact_id={parseInt(this.state.contact_id)}  handleClose={this.closeDrawerRight} />}
                    </Drawer>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  opportunityReducer: state.opportunityReducer,
  contactReducer: state.contactReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    deleteOpportunitiesContact: (client,req) => { dispatch(actionCreators.deleteOpportunitiesContact(client,req)) },
    getContactRecList: (client) => { dispatch(actionCreators.getContactRecList(client)) },
    addNewOpportunitiesContact: (client,req) => { dispatch(actionCreators.addNewOpportunitiesContact(client,req)) },
    getRecordRelatedData: (client,req) => { dispatch(actionCreators.getRecordRelatedData(client,req)) },
    addProjectContact: (client,req) => { dispatch(actionCreators.addProjectContact(client,req)) },
    deleteProjectContact: (client,req) => { dispatch(actionCreators.deleteProjectContact(client,req)) },
    addNewCompanyContact: (client,req) => { dispatch(actionCreators.addNewCompanyContact(client,req)) },
    deleteCompanyContact: (client,req) => { dispatch(actionCreators.deleteCompanyContact(client,req)) },
  }
}


export default (connect(mapStateToProps,mapDispatchToProps)(withRouter(People)));
