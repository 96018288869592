import React, { Component } from "react";
import {
  Dialog, IconButton, Button, Icon, Tooltip, Grid,
  FormControl, Select, InputLabel, Menu, MenuItem, List,
  ListItem, ListItemText, DialogActions,
  Chip, TextField, withStyles, Fab, Card
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import FontAwesome from "react-fontawesome";
const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.3rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);
const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class CreatelogDialog extends Component {
  state = {
    selectedDate: new Date(),
    logvaluetype: 'Phone Call',
    textvalue: '',
    selectedDate: new Date(),
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
    logtype: 0,
    propertyid: 1,
    activities: [],
  };
  componentDidMount() {

    
    if (this.props.logvaluetype != '') {
      
      this.setState({ logvaluetype: this.props.logvaluetype });
    }

    if (this.props.settingReducer.masterActivityTypesList && this.props.settingReducer.masterActivityTypesList.data) {
      this.setState({ activities: this.props.settingReducer.masterActivityTypesList.data });
    }
  }
  componentWillReceiveProps(props) {
    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.logtype) {
      this.setState({ logtype: props.logtype });
    }
  }
  handlechange = (event) => {
    
    let key = this.findWithAttr(this.state.activities, 'id', event.target.value);
    let prop_name = this.state.activities[key].name;

    this.setState({ propertyid: event.target.value, logvaluetype: prop_name })
  }
  handletextchange = (event) => {
    this.setState({ textvalue: event.target.value })
  }
  
  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleCancelAddLog = () => {
    this.setState({ textvalue: '' })
  }

  handleSaveAddLog = () => {
    let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
    console.log(date_test, 'inserted');

    var logdate = moment.tz(date_test, this.state.timeZone).format();
    console.log(logdate, this.state.timeZone);

    var date = moment.utc(logdate).format('X');
    console.log(date, 'utc');

    console.log(moment.unix(date).tz(this.state.timeZone).format('YYYY-MM-DD hh:mm a'), 'conv');
    
    // let date = moment(this.state.selectedDate).format("X");
    let propname = this.state.logvaluetype;
    let textvalue = this.state.textvalue;
    let prop_id = (this.props.logtype == 0) ? parseFloat(this.state.propertyid) : 0;
    let saveLogparams = [];
    saveLogparams['save'] = {
      propertyname: propname,
      propertyid: prop_id,
      text: textvalue,
      logtime: parseFloat(date),
      logdate: parseFloat(date),
      oldvalue: "",
      newvalue: "",
      logtype: this.props.logtype,
      fieldid: 0,
      leadid: this.props.lead_id
    };
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    saveLogparams['getlog'] = { emailid: emailParam, leadid: parseFloat(this.props.lead_id), logtypeid: [], activitypeid: [] };
    
    this.props.saveLeadLog(this.props.apolloClient.client, saveLogparams);
    this.setState({ textvalue: '' });
    this.props.handleClose();
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  toCamelCase = (s) => {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '');
      });
    };
  render() {

    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    const { propertyid, activities, logvaluetype, textvalue, selectedDate } = this.state;
    
    
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
          <div className="crm-custom-bg-primary">
            <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary ">
              <h4 className="m-0 text-white">
                {(this.props.logtype == 0) ?
                  "Add New Log"
                  : (this.props.logtype == 5) ?
                  "Add New Note"
                  :
                  null
                }
              </h4>
              <div className="d-flex">
                <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
                  <IconButton onClick={handleMinimize}>
                    <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
                  </IconButton>
                </TooltipNew>
                <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
                  <IconButton onClick={handleFull}>
                    <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                  </IconButton>
                </TooltipNew>
                <TooltipNew title="Close">
                  <IconButton onClick={handleClose}>
                    <Icon className="text-white">clear</Icon>
                  </IconButton>
                </TooltipNew>
              </div>
            </div>
          </div>
          <ValidatorForm noValidate debounceTime={500} onSubmit={this.handleSaveAddLog}>
            <div className="dialog-body">
              <Grid className="p-20" container spacing={2}>
                <div>
                  {(this.props.logtype == 0) &&
                    (
                      <Select
                        name="phone_log_drop"
                        id="phone_log_drop"
                        className="font-size-13"
                        value={propertyid}
                        disableUnderline
                        defaultValue={propertyid}
                        onChange={this.handlechange}
                      >
                        {activities.map((activity) => (
                          (activity.is_disable == false) &&
                          <MenuItem key={activity.name} className="" value={activity.id}>
                            <FontAwesome className={activity.icon + " pr-10 crm_activitytype_icon"} />
                            <span className="font-weight-500 font-size-14 ml-5 mr-5"> {activity.name}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    )
                  }
                </div>

                <TextValidator
                  required={true}
                  id="search-input"
                  fullWidth
                  label="Text"
                  placeholder="Click here to add Text"
                  value={textvalue}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  onChange={this.handletextchange}
                />
                {/* {(textvalue && textvalue.length > 0) && ( */}
                <div className="mt-40">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={3}>
                      <Grid item xs={4} className="pt-0">
                        <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Date</InputLabel>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          format={this.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                          maxDate={new Date()}
                          value={selectedDate}
                          onChange={this.handleDateChange}
                          onKeyPress={(e) => e.preventDefault()}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} className="pt-0">
                        <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Time</InputLabel>
                        <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          format="hh:mm a"
                          value={selectedDate}
                          onKeyPress={(e) => e.preventDefault()}
                          onChange={this.handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <div className="pull-right">

                  </div>
                </div>
                {/* )} */}
              </Grid>
            </div>
            <DialogActions className="dialog-action">
              <Button className="mr-10" color="secondary" onClick={handleClose}>Cancel</Button>
              <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Save</Button>
            </DialogActions>
          </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  leadReducer: state.leadReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    saveLeadLog: (client, request) => dispatch(actionCreators.saveLeadLog(client, request)),
    getActivityTypes: (client) => { dispatch(actionCreators.getActivityTypes(client)) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatelogDialog);
