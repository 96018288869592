import React, { Component } from "react";
import { Checkbox } from "@material-ui/core";
import { Table } from 'antd';

class SystemNotification extends Component {
    state = {
        selection: [],
        checkbox_system_email1: true,
        checkbox_system_email2: true,
        checkbox_system_email3: true,
        checkbox_system_email4: true,
        checkbox_system_email5: true,
        checkbox_system_inapp1: true,
        checkbox_system_inapp3: true,
        checkbox_system_inapp4: true,
        checkbox_system_inapp5: true,
    };
    handleCheckBoxChange = (event, name) => {
        event.persist();
        this.setState({ [name]: event.target.checked });
    };
    render() {
        const columns = [];
        const rows = [];
        const general_rows = [];

        const general_columns = [

            {
                title: "",
                dataIndex: "name",
                className: "align_left",
                width: 300
            },
            {
                title: "Email",
                dataIndex: "email",
            },
            {
                title: "In App",
                dataIndex: "inapp",                
            },
            {
                title: "",
                dataIndex: "mobile",
            },
            {
                title: "",
                dataIndex: "action",
            }
        ];
        
        general_rows.push (
            {
                name: (
                    <div className="ml-10">                           
                        <label className="crm-custom-field-label font-weight-500">My import is complete</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_system_email1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_system_email1')}
                        value="checkbox_system_email1"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_system_inapp1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_system_inapp1')}
                        value="checkbox_system_inapp1"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">My import has been reverted</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_system_email2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_system_email2')}
                        value="checkbox_system_email2"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<></>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">My bulk update is complete</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_system_email3}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_system_email3')}
                        value="checkbox_system_email3"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_system_inapp3}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_system_inapp3')}
                        value="checkbox_system_inapp3"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">My export is complete</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_system_email4}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_system_email4')}
                        value="checkbox_system_email4"
                        color="primary"
                        disabled="true"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_system_inapp4}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_system_inapp4')}
                        value="checkbox_system_inapp4"
                        color="primary"
                    />
                </React.Fragment>),                
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">My bulk emails are complete</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_system_email5}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_system_email5')}
                        value="checkbox_system_email5"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_system_inapp5}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_system_inapp5')}
                        value="checkbox_system_inapp5"
                        color="primary"
                    />
                </React.Fragment>),
            }
        )
        


        return (
            <React.Fragment>
                <div className="table_width antd-tables">
                    <Table
                        bordered={false}
                        columns={general_columns}
                        pagination={false}
                        dataSource={general_rows}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default SystemNotification;
