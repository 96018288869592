import React, { Component } from "react";
import { Fab,Grid, withStyles,Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import OpportunityProfileFrame from "../Opportunities/OpportunityProfileFrame";
import CreateNewOpportunityDialog from "../Opportunities/CreateNewOpportunityDialog";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink,withRouter } from "react-router-dom";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
const formatCreateLabel = (value) => `Create "${value}"`;
const isValidNewOption = (value) => value != "";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);
const filter = createFilterOptions();


class Project extends Component {
  state = {
        attachment: null,
        drawerOpenOpportunity: false,
        showAddOpportunity: false,
        opportunities_id: "",
        opportunityListArray: [],
        allOpportunityList: [],
        createNewOpportunityName:"",
        filterdata:[],
        removeOpportunityPopup: false,
        shouldOpenCreateOpportunityDialog: false,
        FullCreateOpportunityDialog: false,
        minimizeOpportunitytDialog: false,
        type: "opportunities",
        recordtype: "",
        newOpportunityData: {},
        companyCurrencyData:[],
    };

    componentDidMount() {
        this.props.getOpportunitiesList(this.props.apolloClient.client);

        if(this.props.recordId){
            this.setState({
                recordId:this.props.recordId,
                recordtype:this.props.recordtype,
            },()=>{
                this.forceUpdate()
                var req ={
                  id:this.props.recordId,
                  type:this.state.type,
                  recordtype:this.props.recordtype,
                }
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.companyCurrencyData.data != null) {
            var cData = props.settingReducer.companyCurrencyData.data;
            this.setState({companyCurrencyData:cData[0]});
        }
        if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
            this.setState({
                recordId:props.recordId,
                recordtype:props.recordtype,
            },()=>{
                this.forceUpdate()
                //this.props.getOpportunitiesCompanyList(this.props.apolloClient.client, this.state.recordId);
                var req ={
                  id:props.recordId,
                  type:this.state.type,
                  recordtype:props.recordtype,
                }
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
        if (props.opportunityReducer.opportunityFieldData.data && props.opportunityReducer.opportunityFieldData.is_loaded) {
            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
        }
         
         
        if (props.settingReducer.relatedDataOpportunity.data) {
            props.settingReducer.relatedDataOpportunity.is_loaded = false
            this.setState({
                opportunityListArray:props.settingReducer.relatedDataOpportunity.data.data,
            },()=>this.forceUpdate());
        }

        if (props.settingReducer.opportunityRecList && props.settingReducer.opportunityRecList.data && props.settingReducer.opportunityRecList.data.data){
          let opportunityOptions = [];
          props.settingReducer.opportunityRecList.data.data.map((opt)=>{
            opportunityOptions.push({ label: opt.name, value: opt.opportunities_id});
          })
          this.setState({ allOpportunityList: opportunityOptions});
        }
         
    }

  toggleProject = () => {
      this.setState(oldStateProject => ({ isOpenedOpportunity: !oldStateProject.isOpenedOpportunity}));
      if(this.state.isOpenedOpportunity){
          this.setState({
              showAddOpportunity: false
          },()=>this.forceUpdate());
      }
  }

  /* START cmp dialoge */

    handleProjectDialogClose = () => {
        this.setState({ shouldOpenCreateOpportunityDialog: false, FullCreateOpportunityDialog: false, minimizeOpportunitytDialog: false });
    };

    handleOpportunityDialogMinimize = () => {
        this.setState(oldStateminimizeOpportunitytDialog => ({ minimizeOpportunitytDialog: !oldStateminimizeOpportunitytDialog.minimizeOpportunitytDialog }));
        this.setState({ FullCreateOpportunityDialog: false })
    };

    handleOpportunityDialogFull = () => {
        this.setState(oldStateFullCreateOpportunityDialog => ({ FullCreateOpportunityDialog: !oldStateFullCreateOpportunityDialog.FullCreateOpportunityDialog }));
        this.setState({ minimizeOpportunitytDialog: false })

    };
    
    /* END cmp dialoge */
    closeDrawerRight = (e) => {
        //e.preventDefault()
        this.setState({
          drawerOpenOpportunity: false,
          drawerOpenOpportunity: false,
        });
    };
    toggleAddOpportunity = () => {
        this.setState({ 
            isOpenedOpportunity: true,
            showAddOpportunity: !this.state.showAddOpportunity
        });
    }
    handleDrawerOpportunityRight = (opportunities_id) => {
      this.props.getPipelines(this.props.apolloClient.client);
      if (this.state.opportunities_id && (this.state.opportunities_id === opportunities_id)) { } else {
            this.setState({
                opportunities_id: opportunities_id,
                isDataLoading: true,
            }, () => {
                this.forceUpdate()
            })
        }

        setTimeout(() => {
            this.setState({
                drawerOpenOpportunity: true,
            }, () => {
                this.forceUpdate()
            })
        }, 2000);

    };
    handleOpportunityLaunch = (opportunities_id) => {
        this.props.history.push('/opportunities/opportunities/'+opportunities_id)
    };
    handleRemoveOpportunity = (opportunities_id) => {
          this.setState({
                removeOpportunityPopup: true,
                deleteOpportunityId: opportunities_id,
          }, () => {
              this.forceUpdate()
          })
        
    };
    toggleRemoveOpportunityPopup = () => {
          if(this.state.removeOpportunityPopup){
            this.setState({
                deleteOpportunityId: "",
            }, () => {
                this.forceUpdate()
            })

          }
          this.setState({
                removeOpportunityPopup: !this.state.removeOpportunityPopup,
          }, () => {
              this.forceUpdate()
          })
        
    };
    confirmremoveOpportunityPopup = () => {
          console.log("this.state.deleteOpportunityId", this.state.deleteOpportunityId);
        if(this.state.deleteOpportunityId){
          if(this.state.recordtype == "project"){
            var req = {
                opportunitiesid:parseInt(this.state.deleteOpportunityId),
                projectid:parseInt(this.state.recordId),
            }
            this.props.deleteProjectOpportunities(this.props.apolloClient.client,req)
          }
          if(this.state.recordtype == "contact"){
            var req = {
                contactid:parseInt(this.state.recordId),
                opportunitiesid:parseInt(this.state.deleteOpportunityId),
            }
            this.props.deleteOpportunitiesContact(this.props.apolloClient.client,req)
          }
          if(this.state.recordtype == "company"){
            var req = {
                companyid:parseInt(this.state.recordId),
                opportunitiesid:parseInt(this.state.deleteOpportunityId),
            }
            this.props.deleteOpportunitiesCompany(this.props.apolloClient.client,req)
          }

          this.setState({
              deleteOpportunityId: "",
              removeOpportunityPopup: false,
          }, () => {
              this.forceUpdate()
          })
        }
        
    };

    handleOpportunityChange = (val , newValue) => {
              console.log("this.state.recordtype", this.state.recordtype);
              
      if(newValue){
          var relateddata = {
              recordtype: this.state.recordtype,
              type: this.state.type,
              id: parseInt(this.state.recordId),
          }

          let id =  (newValue.__isNew__) ? "0" : newValue.value;
        
          if(id == "0"){
            this.setState({
              newOpportunityData: relateddata,
              shouldOpenCreateOpportunityDialog: true ,
              createNewOpportunityName: newValue.label 
            })
          }else{
            var isAlreadyAdded  = this.state.opportunityListArray.filter(function(element) {
                if(element.opportunities.id == id){
                  return element
                }
            });
            if(isAlreadyAdded.length > 0){
              this.setState({ showAddOpportunity: false});
              return
            }
            let data = {srno: "1", id: id, value: newValue.label};
            if(this.state.recordtype == "project"){
              var req = {
                opportunitiesdetail:data,
                projectid:parseInt(this.state.recordId),
              }
              this.props.addProjectOpportunities(this.props.apolloClient.client,req)
            }
            if(this.state.recordtype == "contact"){
              var req = {
                contactdetail:{id:parseInt(this.state.recordId)},
                opportunitiesid:data.id,

              }
              this.props.addNewOpportunitiesContact(this.props.apolloClient.client,req)
            }
            if(this.state.recordtype == "company"){
              var req = {
                companydetail:{id:parseInt(this.state.recordId)},
                opportunitiesid:parseInt(id),
              }
              this.props.addNewOpportunitiesCompany(this.props.apolloClient.client,req)
            }
          }
          this.setState({ showAddOpportunity: false});

      }
    };

  findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
  render() {
    let { drawerOpenOpportunity,isOpenedOpportunity } = this.state;
    /*STRAT opportunity Display*/
        let displayOpportunityList = []
        {
          this.state.opportunityListArray.map((item, key) => {
            if(item.opportunities.__opportunitiesdetail__.length >0){
                var cName = ""
                var pipelineName = ""
                var stageName = ""
                var statusName = ""
                var valueField = ""
                item.opportunities.__opportunitiesdetail__.map((data, key1) => {
                    if(data.__field__.id == "40"){//name
                        cName =  data.fieldvalue;
                    }
                    if(data.__field__.id == "41"){//pipeline
                      if(data.values.length > 0){
                        pipelineName = data.values[0].value;
                      }
                    }
                    if(data.__field__.id == "45"){//status
                      if(data.values.length > 0){
                        statusName = data.values[0].value;
                      }
                        
                    }
                    if(data.__field__.id == "50"){//value
                      if(data.values.length > 0){
                        valueField = this.state.companyCurrencyData.symbol + data.values[0].value;
                      }
                        
                    }
                    if(data.__field__.id == "47"){//stage
                      if(data.values.length > 0){
                        stageName = data.values[0].value;
                      }
                    }

                     
                })
                displayOpportunityList.push(
                         <div className="box_portlet mt-10 crm-calendar-event">
                                <div className="box_body px-10 py-20">
                                    <div className="lead_pl" style={{paddingLeft: '50px'}}>
                                        <div className="icon_left">
                                            <Fab
                                                  size="small"
                                                  aria-label="Task"
                                                  onClick={()=>this.handleDrawerOpportunityRight(item.opportunities.id)}
                                                  className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                                              >
                                                 
                                                <Icon className="activity_icon_normal">attach_money</Icon>
                                                  
                                              </Fab>
                                        </div>
                                        <a onClick={()=>this.handleDrawerOpportunityRight(item.opportunities.id)} target="_blank" className={"cursor_poiter"}>
                                        <Grid container justify="space-between">
                                            <h6 className="m-0 text_ellipsis" style={{width:'50%'}}>{cName}</h6>
                                            <span className="related-text">{valueField}</span>
                                        </Grid>
                                        </a>
                                        <span className="related-text">{pipelineName+": "}{stageName}{" / "+statusName}</span>
                                        <span className="related-text">&nbsp;</span>
                                        <div className="hover_show" style={{top:'-21px',right:'-7px'}}>
                                            <IconButton aria-label="Launch" size="small" onClick={()=>this.handleOpportunityLaunch(item.opportunities.id)}>
                                                <IconSmall style={{fontSize:'18px'}}>launch</IconSmall>
                                            </IconButton>
                                            <IconButton aria-label="Delete" size="small" onClick={()=>this.handleRemoveOpportunity(item.opportunities.id)}>
                                                <IconSmall style={{fontSize:'18px'}}>close</IconSmall>
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                         </div>                         
                )
            }
            
          })
        }
        /*END opportunities Display*/
    
    return (
      <Card elevation={3} className="p-16 mt-16" style={{overflow: 'initial'}}>
        <Grid container justify="space-between">
            <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleProject}>Opportunities ({displayOpportunityList.length}) <Icon className="vert-middle">{isOpenedOpportunity ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
            <IconButton aria-label="Add" size="small">
                <IconSmall onClick={()=>this.toggleAddOpportunity()} className={this.state.showAddOpportunity?"plusIconTransform":""}>add</IconSmall>
            </IconButton>
        </Grid>
        {isOpenedOpportunity &&
                      <>
                          {this.state.showAddOpportunity && 
                              <FormControl className="w-100 input_bx" style={{zIndex:10000}}>
                                  <SingleSelect
                                    SelectProps={{
                                      isCreatable: true,
                                      formatCreateLabel,
                                      isValidNewOption,
                                    }}
                                    placeholder="Add opportunity"
                                    options={this.state.allOpportunityList}
                                    onChange={this.handleOpportunityChange}
                                  />
                              </FormControl>

                          }
                          {(!this.state.showAddOpportunity &&  displayOpportunityList.length == 0) && 
                            <label className="text-hint">
                              <IconButton className="mr-8" component="span">
                                <Icon onClick={()=>this.toggleAddOpportunity()} >attach_money</Icon>
                              </IconButton>
                                Opportunities
                            </label>
                          }
                          {displayOpportunityList}

                      </>
                    }

                    {this.state.shouldOpenCreateOpportunityDialog && (
                        <CreateNewOpportunityDialog
                            handleClose={this.handleProjectDialogClose}
                            open={this.state.shouldOpenCreateOpportunityDialog}
                            handleMinimize={this.handleOpportunityDialogMinimize}
                            handleFull={this.handleOpportunityDialogFull}
                            minimizeScreen={this.state.minimizeOpportunitytDialog}
                            fullScreen={this.state.FullCreateOpportunityDialog}
                            name={this.state.createNewOpportunityName}
                            contactsid={this.state.recordtype == 'contact' ? this.state.recordId : '' }
                            companysid={this.state.recordtype == 'company' ? this.state.recordId : '' }
                            projectid={this.state.recordtype == 'project' ? this.state.recordId : '' }
                            allstates={this.state}
                            dataFromSidebar={this.state.newOpportunityData}
                        />
                    )}

                    <Dialog
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={this.state.removeOpportunityPopup}
                        onClose={this.toggleRemoveOpportunityPopup}
                        disableBackdropClick
                        disableEscapeKeyDown
                        aria-labelledby="ar-remove-dialog-title"
                    >
                        <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the associated opportunity ?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.toggleRemoveOpportunityPopup} color="primary">
                                Cancel
                                          </Button>
                            <Button type="Button" onClick={() => this.confirmremoveOpportunityPopup()} className="btn_Primary">
                                Ok
                                          </Button>

                        </DialogActions>
                    </Dialog>
                        <Drawer
                        width={"100px"}
                        variant="temporary"
                        anchor={"right"}
                        className="drawer_right"
                        open={drawerOpenOpportunity}
                        onClose={this.closeDrawerRight}
                        ModalProps={{
                            keepMounted: true
                        }}
                    >
                    {this.state.opportunities_id && <OpportunityProfileFrame opportunities_id={parseInt(this.state.opportunities_id)}  handleClose={this.closeDrawerRight} />}
                    </Drawer>
                    {this.state.isDataLoading &&
                      <Snackbar
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          open={this.state.isDataLoading}
                          TransitionComponent={Fade}
                          message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                          variant={'success'}
                      />
                    }
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  opportunityReducer: state.opportunityReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),
    getOpportunitiesList: (client) => { dispatch(actionCreators.getOpportunitiesList(client)) },
    addProjectOpportunities: (client,req) => { dispatch(actionCreators.addProjectOpportunities(client,req)) },
    deleteProjectOpportunities: (client,req) => { dispatch(actionCreators.deleteProjectOpportunities(client,req)) },
    getRecordRelatedData: (client,req) => { dispatch(actionCreators.getRecordRelatedData(client,req)) },
    addNewOpportunitiesContact: (client,req) => { dispatch(actionCreators.addNewOpportunitiesContact(client,req)) },
    deleteOpportunitiesContact: (client,req) => { dispatch(actionCreators.deleteOpportunitiesContact(client,req)) },
    addNewOpportunitiesCompany: (client,req) => { dispatch(actionCreators.addNewOpportunitiesCompany(client,req)) },
    deleteOpportunitiesCompany: (client,req) => { dispatch(actionCreators.deleteOpportunitiesCompany(client,req)) },
  }
}


export default (connect(mapStateToProps,mapDispatchToProps)(withRouter(Project)));
