
import React, { Component } from "react";
import { Checkbox, Typography, Card, Grid, Button } from "@material-ui/core";
import { Table } from 'antd';
import BusinessIcon from '@material-ui/icons/Business';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';
import { DropzoneArea } from 'material-ui-dropzone'

class UploadPeople extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        };
    }
    
    backToImport = () => {
        this.props.history.push('/settings/import-data/');
    }

    handleChange(files) {
        console.log(files,'files');
        
        this.setState({
            files: files
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="v_scroll crm-custom-importdata-settings">
                    <div className="mb-40">
                        <Typography className=" p-10" variant="h5" color="inherit">Import People - {this.props.match.params.id}</Typography>
                        <Typography className="text-hint p-10" variant="p">
                            Import data into Copper from Google Contacts, CSV files, or Excel files.
                        </Typography>
                    </div>
                    <Card className="p-20 mb-10 ">
                        <Typography className="font-weight-500 p-10" variant="p" color="inherit">Step 2: Choose your Excel file to import</Typography>
                        <Grid container spacing={2} className="mb-10 mt-30 import-buttons">
                            <Grid item md={8}>
                                <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}                                    
                                    maxFileSize={5000000}
                                    onChange={this.handleChange.bind(this)}
                                />
                            </Grid>
                            <Grid item md={4} className={'text-left'}>
                                <div><Typography className="font-weight-500 " variant="p" color="inherit">Need help formatting your Excel file?</Typography></div>
                                <div><Typography className="text-hint" variant="p">
                                    Download our <a href="#" className="default-template-link" >pre-formatted Excel template</a> and follow the formatting for the best results.
                                </Typography></div>
                            </Grid>
                        </Grid>
                        <Button onClick={this.backToImport} color="secondary" >Cancel</Button>
                    </Card>                    
                </div>
            </React.Fragment>
        );
    }
}

export default UploadPeople;
