import React, { Component } from "react";
import { Typography, Checkbox } from "@material-ui/core";
import { Table } from 'antd';


class AutoNotifications extends Component {
    state = {
        selection: [],
        checkbox_auto_name1:true,
        checkbox_auto_name2: true,
        checkbox_auto_name3: true,
        checkbox_auto_name4: true,
        checkbox_auto_name5: true,
    };
    handleCheckBoxChange = (event, name) => {
        event.persist();
        this.setState({ [name]: event.target.checked });
    };
    render() {
        const columns = [];
        const rows = [];
        const general_rows = [];

        const general_columns = [

            {
                title: (<Typography className="font-size-18 p-10" variant="p" color="inherit">Automatically follow records when:</Typography>),
                dataIndex: "name",
                className: "align_left",
                width: 300
            },
        ];
        
        general_rows.push (
            {
                name: (
                    <div className="ml-10">     
                        <Checkbox
                            checked={this.state.checkbox_auto_name1}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_auto_name1')}
                            value="checkbox_auto_name1"
                            color="primary"
                        />                      
                        <label className="crm-custom-field-label font-weight-500">I create a record</label>
                    </div>
                ),
            },
            {
                name: (
                    <div className="ml-10">
                        <Checkbox
                            checked={this.state.checkbox_auto_name2}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_auto_name2')}
                            value="checkbox_auto_name2"
                            color="primary"
                        />
                        <label className="crm-custom-field-label font-weight-500">I update a record</label>
                    </div>
                ),
            },
            {
                name: (
                    <div className="ml-10">
                        <Checkbox
                            checked={this.state.checkbox_auto_name3}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_auto_name3')}
                            value="checkbox_auto_name3"
                            color="primary"
                        />
                        <label className="crm-custom-field-label font-weight-500">I log activities on a record</label>
                    </div>
                ),
            },
            {
                name: (
                    <div className="ml-10">
                        <Checkbox
                            checked={this.state.checkbox_auto_name4}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_auto_name4')}
                            value="checkbox_auto_name4"
                            color="primary"
                        />
                        <label className="crm-custom-field-label font-weight-500">I assign someone to a record</label>
                    </div>
                ),
            },
            {
                name: (
                    <div className="ml-10">
                        <Checkbox
                            checked={this.state.checkbox_auto_name5}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_auto_name5')}
                            value="checkbox_auto_name5"
                            color="primary"
                        />
                        <label className="crm-custom-field-label font-weight-500">Someone assigns a record to me</label>
                    </div>
                ),
            },
        )
        


        return (
            <React.Fragment>
                <div className="table_width antd-tables">
                    <Table
                        bordered={false}
                        columns={general_columns}
                        pagination={false}
                        dataSource={general_rows}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default AutoNotifications;
