import React, { Component } from "react";
import { Switch, List, ListItem, Menu, Checkbox, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar,Tooltip } from "@material-ui/core";
import { default as MaterialGrid } from '@material-ui/core/Grid';
import DropdownDefault from "../Layout/DropdownDefault";
import { Link } from "react-router-dom";
import CreateNewProjectDialog from "./CreateNewProjectDialog";
import ProjectProfileFrame from "./ProjectProfileFrame";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import FontAwesome from "react-fontawesome";
import HiddenColumns from "../Common/HiddenColumns";
import { FilterComponent } from './FilterComponent'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import {
    Grid,
    DragDropProvider,
    Table,
    TableHeaderRow,
    Toolbar,
    ColumnChooser,
    VirtualTable,
    TableSelection,
    TableColumnVisibility,
    SearchPanel,
    TableFixedColumns,
    TableColumnReordering,
    PagingPanel,
    TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
    PagingState,
    CustomPaging
} from '@devexpress/dx-react-grid';

import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';


let hiddneColumns = [];

class ProjectsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            switchView: true,
            drawerflag: false,
            columns_array: [],
            searchText: '',
            deleteIds: [],
            currentPage: 0,
            pageSize: 10,
            column_name: "",
            sorttype: "",
            fieldId: 67,
            isDeleteConfirmDialoOpen: false,
            defaultCurrentpage: 1,
            totalCount: 0,
            projectListData: [],
            updateColumnOrder: true,
            defaultColumnOrder: ['name'],
            column_ids: [],
            isOpenedProjectList: true,
            isOpenedCustomFilters: false,
            isOpenedRange: false,
            shouldOpenCreateProjectDialog: false,
            FullCreateProjectDialog: false,
            minimizeProjectDialog: false,
            defaulSortingData: [
                { columnName: 'name', direction: 'asc' },
                { columnName: 'visibility', direction: 'desc' },
                { columnName: 'company', direction: 'desc' },
                { columnName: 'title', direction: 'desc' },
                { columnName: 'email', direction: 'desc' },
                { columnName: 'owner', direction: 'desc' },
                { columnName: 'phones', direction: 'desc' },
                { columnName: 'status', direction: 'desc' },
                // { columnName: 'statusduration', direction: 'desc' },
                // { columnName: 'statusupdatedate', direction: 'desc' },
                // { columnName: 'lastcontacted', direction: 'desc' },
                // { columnName: 'interactions', direction: 'desc' },
                // { columnName: 'inactivedays', direction: 'desc' },
            ],
            selection: [],
            projectLogs: [],
            filterObject: {},
            systemTagsList: [],
            systemCityListArray: [],
            systemStateListArray: [],
            systemZipcodeListArray: [],
            countryListArray: [],
            fieldDisplay: [],
            filterRangeArray: [],
            dropdownhide: [],
            filterSaveDialoge: false,
            fitertype: "1",
            filtername: "",
            recordtypeid: 5,
            filternameError: false,
            projectFilterListArray: [],
            displaySelectedFilterName: "All Project",
            filterUpdateId: "",
            allowStateUpdateFilter: true,
            filterdata: [],
            activityquery: "any",
            tagsquery: "any",
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
            usersListArray: [],
            AllProjectFilterKey: "",
            defaultHiddenColumnNames: [],
            columnWidths: [],
            isDataLoading: true,
            newSavedFilterName: "",
            masterActivityTypesList: [],
        };
    }

    componentDidMount() {
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let userprefparams = { userid: userData.id }
        //this.props.getActivityTypes(this.props.apolloClient.client);
        this.props.getUserSetting(this.props.apolloClient.client, userprefparams);
        //this.props.getCityList(this.props.apolloClient.client);
        this.props.getFilterListProject(this.props.apolloClient.client, 5);
        //this.props.getStateList(this.props.apolloClient.client);
        //this.props.getZipcodeList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        let val = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name,
                islimited: true
            },
            filterid: 1
        }
        this.getListing(val)
        // let params_customfield = { typeId:1 }
        // this.props.customFieldList(this.props.apolloClient.client, params_customfield);

    }
    getListing = (val) => {
        this.props.getProjectFilterList(this.props.apolloClient.client, val);
        this.props.getProjectListColumnOrder(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count  = dataList.filter(function(element) {
                if(element.is_disable == true){
                  return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }
        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;

            this.setState({ dateformat: format, timeZone: timezone })
        }

        if (props.projectReducer.projectFilterListArray.data && this.state.allowStateUpdateFilter || props.projectReducer.projectFilterListArray.is_loaded) {
            const projectFilterListArray = props.projectReducer.projectFilterListArray.data;
            props.projectReducer.projectFilterListArray.is_loaded = false
            this.setState({ projectFilterListArray: projectFilterListArray, allowStateUpdateFilter: false })

            var AllfilterIdKey = this.findWithAttr(projectFilterListArray, 'id', "21");
            if (AllfilterIdKey != -1) {
                this.setState({ AllProjectFilterKey: AllfilterIdKey })
            }


            //Aplying default filter selected  STRAT

            let defaultFilter;
            //checkdefault filter  in localStorage
            var localStorageFilterId = localStorage.getItem('selected_project_filter_id');

            if (localStorageFilterId) {
                var localId = this.findWithAttr(projectFilterListArray, 'id', localStorageFilterId);
                defaultFilter = projectFilterListArray[localId];
            } else {
                var defaultKeyStatus = this.findWithAttr(projectFilterListArray, 'isdefault', true);
                defaultFilter = projectFilterListArray[defaultKeyStatus];
                localStorage.setItem('selected_project_filter_id', defaultFilter.id);
            }
            //updateFilter name to display and menudisplay array set

            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            //when my project filter select
            if (defaultFilter.id == "22" && defaultFilter.filtername == "My Projects") {
                defaultFilter.filteroption = [{ fieldid: "69", name: "owner", type: "value", value: [udata.email] }]
            }
            //my open project
            if (defaultFilter.id == "23") {
                defaultFilter.filteroption = [
                    {
                        fieldid: "69", name: "owner", type: "value", value: [udata.email]
                    },
                    {
                        fieldid: "70", name: "status", type: "value", value: ["Open"]
                    }
                ]
            }

            this.setState({
                displaySelectedFilterName: this.state.newSavedFilterName ? this.state.newSavedFilterName : defaultFilter.filtername,
                fieldDisplay: [],
                dropdownhide: [],
            })

            if (defaultFilter.filteroption.length > 0) {
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    defaultFilter.filteroption.map((val, k) => {
                        
                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }

                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }
                        if (val.type == "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }
                        if (val.type == "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }
                        if (val.name == "zipcode" || val.name == "city" || val.name == "state" || val.name == "country") {//hideshow ENABLE state/city/country/state
                            dropdownhideShow.push(val.name)
                        } else {
                            dropdownhideShow.push(val.name)
                        }
                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                        newSavedFilterName: "",
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    this.handleFilterSubmit();
                });
            }
            //Aplying default filter selected  END
        }

        if (props.projectReducer.projectLogList && props.projectReducer.projectLogList.data && props.projectReducer.projectLogList.data.logdata) {
            this.setState({ projectLogs: props.projectReducer.projectLogList.data.logdata })
        }

        if (props.settingReducer.projectCustomFieldList.data) {
            const customFields = props.settingReducer.projectCustomFieldList.data;
            this.setState({ fieldsList: customFields })
        }
        if (props.settingReducer.systemCityListArray && props.settingReducer.systemCityListArray.data && this.state.systemCityListArray.length == 0) {
            this.setState({ systemCityListArray: props.settingReducer.systemCityListArray.data })
        }
        if (props.settingReducer.systemZipcodeListArray && props.settingReducer.systemZipcodeListArray.data && this.state.systemZipcodeListArray.length == 0) {
            this.setState({ systemZipcodeListArray: props.settingReducer.systemZipcodeListArray.data })
        }
        if (props.settingReducer.systemStateListArray && props.settingReducer.systemStateListArray.data && this.state.systemStateListArray.length == 0) {
            this.setState({ systemStateListArray: props.settingReducer.systemStateListArray.data })
        }
        if (props.settingReducer.countryListGql.data && this.state.countryListArray.length == 0) {
            const countryies = props.settingReducer.countryListGql.data;
            this.setState({ countryListArray: countryies })
        }

        if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
            const tagsData = props.settingReducer.systemTagsList.data;
            this.setState({ systemTagsList: tagsData })
        }

        if (props.projectReducer.projectFieldData.data && props.projectReducer.projectFieldData.is_loaded) {
            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
        }


        if (props.settingReducer.projectCustomFieldList.data) {
            let column_ids = this.state.column_ids;
            let columns_array = this.state.columns_array;
            let columnWidths = this.state.columnWidths;
            let defaultColumnOrder = this.state.defaultColumnOrder;
            let defaulSortingData = this.state.defaulSortingData;

            const customFields = props.settingReducer.projectCustomFieldList.data;
            
            customFields.map((field) => {
                if (field && (field.isdiactivated || field.is_default)) {
                    column_ids[field.fieldkey] = parseFloat(field.id);
                    if (this.findWithAttr(columns_array, 'name', field.fieldkey) == -1) {

                        // creating dynamic columns for table ------------------------
                        columns_array.push({ name: field.fieldkey, title: field.fieldlabel });
                        if (field.fieldkey != 'name')
                            defaultColumnOrder.push(field.fieldkey);

                        if (field.fieldkey == 'name') {
                            columnWidths.push(
                                { columnName: "name", width: 250 },
                            );
                        } else {
                            columnWidths.push(
                                { columnName: field.fieldkey, width: 180 },
                            );
                        }
                    }
                }
            });
            
            
            this.setState({ column_ids: column_ids, columnWidths: columnWidths, columns_array: columns_array, defaultColumnOrder: defaultColumnOrder, }, () => this.forceUpdate());

            if (this.state.drawerflag) {
                setTimeout(() => {
                    this.setState({ drawerOpen: true, drawerflag: false }, () => this.forceUpdate())
                }, 500);
            }


            if (props.projectReducer.projectListOrder && props.projectReducer.projectListOrder.data && props.projectReducer.projectListOrder.data.length > 0) {
                let ordernoList = [];
                let newcolumn_arr = [];
                let list = [];
                let hidddenCol = ['lastname'];
                // console.log(props.projectReducer.projectListOrder.data,'props.projectReducer.projectListOrder.data');
                // return
                props.projectReducer.projectListOrder.data.map((listOrder) => {
                    if (listOrder.field) {   
                        if (listOrder.field.fieldkey != "name") {                     
                            if (listOrder.isactive == false)
                                hidddenCol.push(listOrder.field.fieldkey);

                            ordernoList.push(listOrder.field.fieldkey);
                        }
                        if (this.state.columns_array) {
                            let key_col =  this.findWithAttr(this.state.columns_array, 'name', listOrder.field.fieldkey);
                            
                            if (this.state.columns_array[key_col] != undefined)
                                newcolumn_arr.push(this.state.columns_array[key_col]);
                        }
                    } else {
                        let temp_order = props.projectReducer.project_column_order_extra[0];
                        let projectcol = Object.keys(temp_order).find(key => temp_order[key] == listOrder.columnid);
                        
                        if (this.state.columns_array) {
                            let key_col1 = this.findWithAttr(this.state.columns_array, 'name', projectcol);
                            
                            if (this.state.columns_array[key_col1] != undefined)
                                newcolumn_arr.push(this.state.columns_array[key_col1]);
                        }
                        if (listOrder.isactive == false)
                            hidddenCol.push(projectcol);

                        ordernoList.push(projectcol)
                    }
                });
                hiddneColumns = hidddenCol;
                this.setState({ columns_array: newcolumn_arr });
                if (this.state.updateColumnOrder)
                    this.setState({ defaultColumnOrder: ordernoList, defaultHiddenColumnNames: hidddenCol, }, () => this.forceUpdate());
            }
        }
        if (props.projectReducer.projectList.data && props.projectReducer.projectList.data.data) {
            let totalRecord = props.projectReducer.projectList.data.totalrecord;
            this.setState({ totalCount: totalRecord });
            let listrows = [];
            let columns_array = this.state.columns_array;
            // // for displaying table rows dynamically-------------------
            props.projectReducer.projectList.data.data.map((projectdata, projectindex) => {
                let row_str = [];
                let thisVar = this;
                // console.log(props.projectReducer.projectList.data.data);
                // return
                
                Object.keys(projectdata).forEach(function (key) {
                    columns_array.map((colm) => {
                        if (!row_str[colm.name]) {
                            if (colm.name == "name") {//name field
                                // console.log(colm.name,'projectdata[colm.name]');
                                // return
                                let star_icon = projectdata['isfavourite'] ? "star" : "star_border";
                                let color_class = (projectdata[colm.name]) ? projectdata[colm.name].charAt(0).toLowerCase() : "";
                                let data = '-';
                                data = (
                                    <div className="white_space_nowrap">
                                        {!projectdata['image'] && <Avatar className={"avatar_circle color_" + color_class}>{color_class.toUpperCase()}</Avatar>}
                                        {projectdata['image'] && <Avatar className="avatar_circle color_a" alt="Lead image" src={projectdata['image']} />}

                                        <span className="font-weight-500 text_ellipsis">
                                            <Link to={"/project/projects/" + projectdata['project_id']}>{projectdata[colm.name]}</Link>
                                        </span>
                                        <IconButton className={'text-secondary'+' '+(star_icon == 'star'?'visibleStarIcon':'')}  onClick={(project, status) => thisVar.handleFavorite(projectdata['project_id'], projectdata['isfavourite'])} size="small">
                                            <Icon>{star_icon}</Icon>
                                        </IconButton>
                                        <IconButton size="small" className="user_info ml-4" color="primary" onClick={(e) => thisVar.handleDrawerRight(projectdata['project_id'])}>
                                            <Icon>remove_red_eye</Icon>
                                        </IconButton>
                                    </div>);

                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }
                            else if (colm.name == "owner") {
                                let data = '-';
                                if (projectdata[colm.name]) {
                                    if (typeof (projectdata[colm.name]) == "string") {
                                        data = projectdata[colm.name];
                                    }
                                    else {
                                        projectdata[colm.name].map((statusdata) => (
                                            data = (statusdata.srno == 1) ? statusdata.name : null
                                        ))
                                    }
                                }
                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }
                            else if (colm.name == "status" || colm.name == "visibility" || colm.name == "tags" || colm.name == "relatedto" || colm.name == "stage") {
                                let data = '-';
                                if (projectdata[colm.name]) {
                                    if (colm.name == "relatedto"){
                                        if(projectdata[colm.name].length > 0){
                                            data = projectdata[colm.name][0].value;
                                        }
                                    }
                                    if (colm.name == "status" || colm.name == "visibility" ) {
                                        projectdata[colm.name].map((statusdata) => (
                                            data = (statusdata.srno == 1) ? statusdata.value : null
                                        ))
                                    }
                                    if (colm.name == "tags") {
                                        let tag = [];
                                        projectdata[colm.name].map((statusdata) => {
                                            statusdata.value && tag.push(statusdata.value)
                                        })

                                        data = (tag.toString()) ? tag.toString() : null
                                    }
                                    if (colm.name == "stage") {
                                        if (projectdata[colm.name].length > 0) {
                                            data = projectdata[colm.name][0].value;
                                        }
                                    }
                                }
                                if (!row_str[colm.name])
                                    row_str[colm.name] = data;
                            }
                            
                            // else if (colm.name == "created") {
                            //     let data = '-';
                                
                            //     if (!row_str[colm.name])
                            //         row_str[colm.name] = data;
                            // }
                            
                            else {
                                let data = '-'
                                if (thisVar.state.fieldsList) {
                                    if (projectdata[colm.name]) {
                                        var key = thisVar.findWithAttr(thisVar.state.fieldsList, 'fieldkey', colm.name);
                                        if (key != -1) {
                                            if (thisVar.state.fieldsList[key].is_default == false && (thisVar.state.fieldsList[key].fieldtype.id == "4")) {
                                                projectdata[colm.name].map((statusdata) => (
                                                    data = (statusdata.srno == 1) ? statusdata.value : null
                                                ))
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "5") {
                                                console.log("data in checkbox ", projectdata[colm.name])
                                                let maindata = [];
                                                projectdata[colm.name].map((statusdata) => {
                                                    statusdata.value && maindata.push(statusdata.value)
                                                })
                                                data = (maindata.toString()) ? maindata.toString() : null
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "1" || thisVar.state.fieldsList[key].fieldtype.id == "2") {

                                                data = projectdata[colm.name] ? projectdata[colm.name] : null
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "6") {//
                                                data = projectdata[colm.name]
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "7") {//date field
                                                data = projectdata[colm.name] + "%"
                                            }
                                            //validating date fields
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "8") {//date field
                                                //data = moment.unix(projectdata[colm.name]).tz(thisVar.state.timeZone).format(thisVar.state.dateformat)
                                                data = moment(projectdata[colm.name]).format(thisVar.state.dateformat)
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "9") {
                                                data = projectdata[colm.name] ?
                                                    <a href={projectdata[colm.name]} className="text_link">{projectdata[colm.name]}</a>
                                                    : null
                                            }
                                            if (thisVar.state.fieldsList[key].fieldtype.id == "10") {
                                                data = projectdata[colm.name]
                                            }
                                            if (!row_str[colm.name])
                                                row_str[colm.name] = data;
                                        }
                                    }
                                }
                            }
                        }
                    });
                });
                let row_str_data = Object.assign({}, row_str);
                listrows.push(row_str_data)
                row_str = [];
            });
            // console.log(listrows,'listrows');
            
            
            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
            this.setState({ projectListData: listrows }, () => this.forceUpdate());
        }
        else {
            let listrows = [];
            this.setState({
                projectListData: listrows,
            }, () => {
                this.forceUpdate()

                setTimeout(() => {
                    this.setState({
                        isDataLoading: false,
                    })
                }, 3000);
            })
        }

    }

    

    onColumnWidthsChange = (widths) => {
        console.log(widths, 'widthswidthswidths');
    }

    handleChange = (event) => {
        if (event.target.name === 'switchView') {
            let path = `/project/projects-card`;
            this.props.history.push(path);
        }
        if (event.target.type == 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    handleChangeColumnOrder = (val) => {
        let arr_ids = [];
        let arr_orders = [];
        let arr_status = [];
        let columns = [];
        let hiddens = this.state.defaultHiddenColumnNames;

        val.map((col, key) => {

            if (col == 'statusupdatedate' || col == 'statusduration' || col == 'interactions' || col == 'inactivedays' || col == 'lastcontacted') {
                if (this.props.projectReducer.project_column_order_extra[0][col] != null) {
                    arr_ids.push(this.props.projectReducer.project_column_order_extra[0][col])
                    arr_orders.push(key + 1);
                    columns.push(col);

                    if (hiddens.indexOf(col) == -1)
                        arr_status.push(true)
                    else
                        arr_status.push(false)
                }
            }
            else {
                if (this.state.column_ids[col] != null) {
                    arr_ids.push(this.state.column_ids[col]);
                    arr_orders.push(key + 1);
                    columns.push(col);

                    if (hiddens.indexOf(col) == -1)
                        arr_status.push(true)
                    else
                        arr_status.push(false)
                }
            }
        });

        this.setState({ updateColumnOrder: false, defaultColumnOrder: columns });
        let changeColumnOrderparams = {
            fieldid: arr_ids,
            columnstatus: arr_status,
            orderno: arr_orders
        };

        this.props.updateProjectListColumnOrder(this.props.apolloClient.client, changeColumnOrderparams);

    }
    // handleChangeColumnHidden = (val) => {
    //     let arr_ids = [];
    //     let arr_orders = [];
    //     let arr_status = [];
    //     let columns = [];
    //     let colsOrder = this.state.defaultColumnOrder;
    //     console.log(val,'val');
        
    //     colsOrder.map((col, key) => {

    //         if (col == 'statusupdatedate' || col == 'statusduration' || col == 'interactions' || col == 'inactivedays' || col == 'lastcontacted') {
    //             if (this.props.projectReducer.project_column_order_extra[0][col] != null) {
    //                 arr_ids.push(this.props.projectReducer.project_column_order_extra[0][col])
    //                 arr_orders.push(key + 1);
    //                 columns.push(col);

    //                 if (val.indexOf(col) == -1)
    //                     arr_status.push(true)
    //                 else
    //                     arr_status.push(false)
    //             }
    //         }
    //         else {
    //             if (this.state.column_ids[col] != null) {
    //                 arr_ids.push(this.state.column_ids[col]);
    //                 arr_orders.push(key + 1);
    //                 columns.push(col);

    //                 if (val.indexOf(col) == -1)
    //                     arr_status.push(true)
    //                 else
    //                     arr_status.push(false)
    //             }
    //         }
    //     });
    //     hiddneColumns = val;
    //     this.setState({ updateColumnOrder: false, defaultHiddenColumnNames: val });
    //     let changeColumnOrderparams = {
    //         fieldid: arr_ids,
    //         columnstatus: arr_status,
    //         orderno: arr_orders
    //     };

    //     this.props.updateProjectListColumnOrder(this.props.apolloClient.client, changeColumnOrderparams);
    // }
    
    handleFavorite = (project_id, status) => {
        let setFavoriteParams = [];
        setFavoriteParams['favorite'] = { status: !status, projectid: project_id };
        setFavoriteParams['listval'] = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: this.state.currentPage + 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name,
                islimited: true
            },
            filterid: 1
        };


        this.props.addFavouriteProject(this.props.apolloClient.client, setFavoriteParams);
    }

    handleCurrentPage = (page) => {
        this.setState({
            currentPage: page,
            selection: [],
        })

        let params_for_pagechange = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: page + 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name,
                islimited: true
            },
            filterid: 1
        }
        this.getListing(params_for_pagechange);
    }
    handlePageSize = (size) => {

        this.setState({
            pageSize: size,
            currentPage: 0,
            selection: [],
        })

        let params_for_pagesize = {
            filterdata: this.state.filterdata,
            list: {
                limit: size,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name,
                islimited: true
            },
            filterid: 1
        }
        this.getListing(params_for_pagesize);
    }

    toggleProjectList = () => {
        this.setState(oldStateProjectList => ({ isOpenedProjectList: !oldStateProjectList.isOpenedProjectList }));
    }
    toggleCustomFilters = () => {
        this.setState(oldStateCustomFilters => ({ isOpenedCustomFilters: !oldStateCustomFilters.isOpenedCustomFilters }));
    }
    toggleRange = (e) => {
        e.preventDefault();
        this.setState(oldStateRange => ({ isOpenedRange: !oldStateRange.isOpenedRange }));
    }
    changeDefaultSorting = (val) => {
        let column = val[0].columnName;
        let direction = val[0].direction;

        let data = this.state.defaulSortingData;
        data = data.filter(sort => sort.columnName !== val[0].columnName).map(sort => {
            sort.direction = "desc"
            return sort;
        })
        data.push(val[0]);
        this.setState({
            defaulSortingData: data,
            sorttype: direction
        }, () => this.forceUpdate)

        if (column == 'statusupdatedate' || column == 'statusduration' || column == 'interactions' || column == 'inactivedays' || column == 'lastcontacted') {
            let params_for_sort = {
                filterdata: this.state.filterdata,
                list: {
                    limit: this.state.pageSize,
                    pageno: this.state.currentPage + 1,
                    searchtext: this.state.searchText,
                    sorttype: direction,
                    type: "sort",
                    fieldId: 0,
                    columnname: column,
                    islimited: true
                },
                filterid: 1
            }
            this.getListing(params_for_sort);
            this.setState({ fieldId: 0, column_name: column });
        } else if (this.state.column_ids[column]) {
            let params_for_sort = {
                filterdata: this.state.filterdata,
                list: {
                    limit: this.state.pageSize,
                    pageno: this.state.currentPage + 1,
                    searchtext: this.state.searchText,
                    sorttype: direction,
                    type: "sort",
                    fieldId: this.state.column_ids[column],
                    columnname: column,
                    islimited: true
                },
                filterid: 1
            }
            this.getListing(params_for_sort);
            this.setState({ fieldId: this.state.column_ids[column], column_name: column });
        }

    }

    changeSearching1 = (e) => {
        let params_for_search = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: 1,
                searchtext: e.target.value,
                sorttype: this.state.sorttype,
                type: "search",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name,
                islimited: true
            },
            filterid: 1
        }
        this.getListing(params_for_search);
        this.setState({ searchText: e.target.value });
    }


    handleProjectDialogClose = () => {
        this.setState({ shouldOpenCreateProjectDialog: false, FullCreateProjectDialog: false, minimizeProjectDialog: false });
    };
    handleProjectDialogMinimize = () => {
        this.setState(oldStateminimizeProjectDialog => ({ minimizeProjectDialog: !oldStateminimizeProjectDialog.minimizeProjectDialog }));
        this.setState({ FullCreateProjectDialog: false })
    };
    handleProjectDialogFull = () => {
        this.setState(oldStateFullCreateProjectDialog => ({ FullCreateProjectDialog: !oldStateFullCreateProjectDialog.FullCreateProjectDialog }));
        this.setState({ minimizeProjectDialog: false })

    };
    setSelection = (val) => {
        let selectedIds = [];

        if (this.props.projectReducer.projectList.data.data) {
            let list_data = this.props.projectReducer.projectList.data.data;
            val.map((key) => {
                selectedIds.push(parseFloat(list_data[key].project_id));
            });
        }
        this.setState({
            selection: val,
            deleteIds: selectedIds
        });
    }
    handleDrawerRight = (project_id) => {
        if (this.state.project_id && (this.state.project_id == project_id)) { } else {
            this.setState({
                project_id: project_id,
                isDataLoading: true,
            }, () => {
                this.forceUpdate()
            })
        }

        setTimeout(() => {
            this.setState({
                drawerOpen: true,
            }, () => {
                this.forceUpdate()
            })
        }, 2000);

    };
    closeDrawerRight = (e) => {
        // e.preventDefault()
        this.setState({ drawerOpen: false });
    };
    handleDeleteProject = () => {
        this.toggleDeleteDialog()
    }

    toggleDeleteDialog = () => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
    };
    confirmDeleteCustomField = () => {
        let deleteProjectParams = [];

        deleteProjectParams['deleteid'] = { projectid: this.state.deleteIds };
        deleteProjectParams['listval'] = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name,
                islimited: true
            },
            filterid: 1
        };

        this.props.deleteProject(this.props.apolloClient.client, deleteProjectParams);
        this.setState({
            selection: [],
            deleteIds: []
        });
        this.toggleDeleteDialog()
    };

    handleFollowedCheckbox = (val, name) => {
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }
    handleChangeFilter = (val, name) => {
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    };

    rangeClick = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "range", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }
    rangeFilter = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({ filterRangeArray })
    }

    rangeClickDate = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "date", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    rangeFilterDate = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({
            filterRangeArray
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                this.rangeClickDate(rname, rval.fieldid)
            }, 100);
        })
    }
    handleToggleFieldDisplay = (name) => {
        var exists = this.state.fieldDisplay.includes(name);
        var joined = [];
        let filterRangeArray = this.state.filterRangeArray;

        if (exists) {
            joined = this.state.fieldDisplay.filter((item) => item != name)
            if (filterRangeArray[name]) {
                //delete filterRangeArray[name];
            }
        } else {
            joined = this.state.fieldDisplay.concat(name);
            if (!filterRangeArray[name]) {
                filterRangeArray[name] = ["", ""];
            }
        }

        this.setState({ fieldDisplay: joined, filterRangeArray })
    }

    dropdownMenu = (fkey) => {
        var exists = this.state.dropdownhide.includes(fkey);
        var joined = [];
        if (exists) {
            joined = this.state.dropdownhide.filter((item) => item != fkey)
        } else {
            joined = this.state.dropdownhide.concat(fkey);
        }
        this.setState({ dropdownhide: joined })
    }

    handleFilterSubmit = () => {
        var allowToFilter = true
        let copyObj = JSON.parse(JSON.stringify(this.state.filterObject));
        var filterValuesArray = Object.keys(copyObj);
        var finalFilterData = filterValuesArray.map((k, i) => {
            let v = copyObj[k];

            if (v.value.length > 0) {
                var valuesArray = [];
                v.value.map((val, k) => {
                    if (v.type == "date") {
                        if (val == "Invalid Date") {
                            allowToFilter = false
                        } else if (val == "") {
                            valuesArray.push(val)
                        } else if (val == null) {
                            valuesArray.push("")
                        } else if (val != null) {
                            if(v.fieldid != "0"){
                                 val = moment(val).format('YYYY-MM-DD');
                            }else{
                                //val = moment(val).format("X");
                                val = moment(val).format('MM/DD/YYYY');
                            }
                            valuesArray.push(val)
                        }
                    }else if(v.type == "log"){
                        valuesArray.push(val.id);
                    } else if (v.fieldid == '69' && val.email) {
                        valuesArray.push(val.email)
                    } else if (v.fieldid != '69' && val.name) {
                        valuesArray.push(val.name)
                    } else if (val.optionvalue) {
                        valuesArray.push(val.optionvalue)
                    } else if (v.fieldid == "74" || v.type == "range") {//tag array and value
                        valuesArray.push(val);
                    } else {
                        valuesArray.push(val);
                    }
                })


                let checkBoxKey = -1
                if (this.state.fieldsList != undefined) {
                    checkBoxKey = this.findWithAttr(this.state.fieldsList, 'id', v.fieldid);
                }



                v.value = valuesArray;
                if (v.type == "followed") {//follow/not follow filter only value is true
                    if (v.value[0] == true) {
                        return v
                    }
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3" && v.value[0] == "1") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id != "3") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3") {
                } else {
                    return v
                }
            }
        })

        //filter null and undefined object 
        finalFilterData = finalFilterData.filter(function (element) {
            return element !== undefined || (element !== undefined && element.value.length != 0);
        });
        console.log("allowToFilter", allowToFilter);
        console.log("finalFilterData", finalFilterData);
        if (allowToFilter) {
            //saveFilter Data for save in future use.
            this.setState({
                filterdata: finalFilterData,
                isDataLoading: true,
            })

            let val = {
                filterdata: finalFilterData,
                list: {
                    limit: this.state.pageSize,
                    pageno: 1,
                    searchtext: this.state.searchText,
                    sorttype: this.state.sorttype,
                    type: "list",
                    fieldId: this.state.fieldId,
                    columnname: this.state.column_name,
                    islimited: true
                },
                filterid: 1
            }
            this.getListing(val)
        }
    }

    handleSavefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        if (this.state.filterdata && this.state.filterdata.length > 0) {
            let req = {
                filterdata: this.state.filterdata,
                fitertype: this.state.fitertype,
                filtername: this.state.filtername,
                recordtypeid: this.state.recordtypeid,
            }
            this.props.saveFilterDetailProject(this.props.apolloClient.client, req);
            this.handleSavefilterDialogeClose()
            this.setState({
                displaySelectedFilterName: this.state.filtername,
                newSavedFilterName: this.state.filtername,
            })
        } else {
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
        }
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
    }
    updateProjectFilter = (data) => {
        this.setState({
            filterSaveDialoge: true,
            filterUpdateId: data.id,
            filtername: data.filtername,
            fitertype: data.fitertype.toString(),
            oldFilterOption: data.filteroption,
        })
    }
    handleUpdatefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        let req = {
            filterdata: this.state.oldFilterOption,
            fitertype: this.state.fitertype,
            filtername: this.state.filtername,
            filterid: parseInt(this.state.filterUpdateId),
        }
        //return
        this.props.updateFilterDetailProject(this.props.apolloClient.client, req);
        let updateDataInState = this.findWithAttr(this.state.projectFilterListArray, 'id', this.state.filterUpdateId);
        if (updateDataInState !== -1) {
            this.state.projectFilterListArray[updateDataInState].filtername = this.state.filtername;
            this.state.projectFilterListArray[updateDataInState].fitertype = this.state.fitertype;
        }
        this.handleSavefilterDialogeClose()
    }
    updateFilterOptions = () => {
        let key = this.findWithAttr(this.props.projectReducer.projectFilterListArray.data, 'id', localStorage.getItem('selected_project_filter_id'));
        var data = ""
        if (key !== -1) {
            data = this.props.projectReducer.projectFilterListArray.data[key];
            if (this.state.filterdata && this.state.filterdata.length > 0) {
                let req = {
                    filterdata: this.state.filterdata,
                    fitertype: data.fitertype.toString(),
                    filtername: data.filtername,
                    filterid: parseInt(data.id),
                }
                this.props.updateFilterDetailProject(this.props.apolloClient.client, req);
                this.handleSavefilterDialogeClose()
            } else {
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
            }
            setTimeout(() => {
                this.setState({
                    allowStateUpdateFilter: true,
                })
            }, 1500);
        }
    }
    confirmDeleteFilter = () => {
        this.props.deleteFilterProject(this.props.apolloClient.client, parseInt(this.state.filterDeleteId));
        let removeFilterObjKey = this.findWithAttr(this.props.projectReducer.projectFilterListArray.data, 'id', this.state.filterDeleteId);
        if (removeFilterObjKey !== -1) {
            if (!this.props.projectReducer.projectFilterListArray.data[removeFilterObjKey].isdefault == true) {
                this.props.projectReducer.projectFilterListArray.data.splice(removeFilterObjKey, 1);
            }
        }
        this.filterRemoveDialoge()
    }
    filterRemoveDialoge = () => {
        this.setState({ isDeleteFilter: false, filterDeleteId: "", allowStateUpdateFilter: true })
    }
    handleSavefilterDialoge = () => {
        this.setState({ filterSaveDialoge: true })
    }
    handleSavefilterDialogeClose = () => {
        this.setState({
            filterSaveDialoge: false,
            filternameError: false,
            fitertype: "1",
            filterUpdateId: "",
            oldFilterOption: "",
            filtername: "",
        })
    }

    updateFilterDefaultProject = (data) => {
        this.props.updateFilterDefaultProject(this.props.apolloClient.client, parseInt(data.id));
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    handleFilterChange = (filterdata) => {
        console.log("filterdata", filterdata);
        let udata = JSON.parse(localStorage.getItem('user_data_session'))
        //when my project filter select
        if (filterdata.id == "23" && filterdata.filtername == "My Projects") {
            filterdata.filteroption = [{ fieldid: "69", name: "owner", type: "value", value: [udata.email] }]
        }
        //my open project
        if (filterdata.id == "22") {
            filterdata.filteroption = [
                {
                    fieldid: "69", name: "owner", type: "value", value: [udata.email]
                },
                {
                    fieldid: "70", name: "status", type: "value", value: ["Open"]
                }
            ]
        }
        this.setState({
            filterObject: {},
            filterRangeArray: [],
            fieldDisplay: [],
            dropdownhide: [],
        }, () => {
            this.forceUpdate()
            this.setState({ displaySelectedFilterName: filterdata.filtername, currentPage: 0 })
            if (filterdata.filteroption.length > 0) {
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    filterdata.filteroption.map((val, k) => {
                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }
                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }
                        
                        if (val.type == "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }

                        if (val.type == "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }

                        if (val.name == "zipcode" || val.name == "city" || val.name == "state" || val.name == "country") {//hideshow ENABLE state/city/country/state
                            dropdownhideShow.push(val.name)
                        } else {
                            dropdownhideShow.push(val.name)

                        }
                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    this.handleFilterSubmit();
                    localStorage.setItem('selected_project_filter_id', filterdata.id)
                });
            } else {
                let val = {
                    filterdata: [],
                    list: {
                        limit: this.state.pageSize,
                        pageno: 1,
                        searchtext: this.state.searchText,
                        sorttype: this.state.sorttype,
                        type: "list",
                        fieldId: this.state.fieldId,
                        columnname: this.state.column_name,
                        islimited: true
                    },
                    filterid: 1
                }
                localStorage.setItem('selected_project_filter_id', filterdata.id)
                this.handleFilterSubmit();
            }
        })
    }

    handlechangeActivityQuery = (event) => {
        this.setState({
            activityquery: event.target.value
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject['activitytype'] && this.state.filterObject['activitytype'].value.length > 0) {
                    this.state.filterObject['activitytype'].query = event.target.value;
                    this.handleFilterSubmit();
                }
            }, 1500);
        })
    }
    handlechangeTagsQuery = (event) => {
        this.setState({
            tagsquery: event.target.value
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject['tags'] && this.state.filterObject['tags'].value.length > 0) {
                    this.state.filterObject['tags'].query = event.target.value;
                    this.handleFilterSubmit();
                }
            }, 1500);
        })
    }
    handleClearFilterData = () => {
        this.setState({
            fieldDisplay: [],
            filterRangeArray: [],
            dropdownhide: [],
            filterObject: {},
            filterdata: [],
            activityquery: "any",
            tagsquery: "any",
        }, () => {
            setTimeout(() => {
                // if(this.state.filterObject['tags'] && this.state.filterObject['tags'].value.length > 0){
                //     this.state.filterObject['tags'].query = event.target.value;
                //     this.handleFilterSubmit();
                // }
            }, 1500);
        })
    }

    handleChangeColumnHidden = (val) =>{
        hiddneColumns = val;
        this.setState({ defaultHiddenColumnNames: hiddneColumns });
    }
    handleHiddensMain = (hiddens) => {
        
        
        let arr_ids = [];
        let arr_orders = [];
        let arr_status = [];
        let columns = [];
        hiddneColumns = hiddens;
        let colsOrder = this.state.defaultColumnOrder;

        colsOrder.map((col, key) => {

            if (col == 'statusupdatedate' || col == 'statusduration' || col == 'interactions' || col == 'inactivedays' || col == 'lastcontacted') {
                if (this.props.projectReducer.project_column_order_extra[0][col] != null) {
                    arr_ids.push(this.props.projectReducer.project_column_order_extra[0][col])
                    arr_orders.push(key + 1);
                    columns.push(col);

                    if (hiddens.indexOf(col) == -1)
                        arr_status.push(true)
                    else
                        arr_status.push(false)
                }
            }
            else {
                if (this.state.column_ids[col] != null) {
                    arr_ids.push(this.state.column_ids[col]);
                    arr_orders.push(key + 1);
                    columns.push(col);

                    if (hiddens.indexOf(col) == -1)
                        arr_status.push(true)
                    else
                        arr_status.push(false)
                }
            }
        });

        let changeColumnOrderparams = {
            fieldid: arr_ids,
            columnstatus: arr_status,
            orderno: arr_orders
        };
        
        this.setState({ updateColumnOrder: false, defaultHiddenColumnNames: hiddneColumns });
        this.props.updateProjectListColumnOrder(this.props.apolloClient.client, changeColumnOrderparams);
    }

    render() {
        let { projectLogs, defaultColumnOrder, pageSize, currentPage, totalCount, projectListData, isOpenedProjectList, isOpenedCustomFilters, isOpenedRange, shouldOpenCreateProjectDialog, minimizeProjectDialog, FullCreateProjectDialog, drawerOpen } = this.state;

        const tableColumnExtensions = [
            { columnName: 'name', width: 250 },
        ];

        const columns = this.state.columns_array;
        
        let defaultHiddenColumnNames = this.state.defaultHiddenColumnNames;
        
        const tableColumnVisibilityColumnExtensions = [
            { columnName: 'name', togglingEnabled: false },
        ];
        const leftColumns = [TableSelection.COLUMN_TYPE, 'name',];

        var localStorageFilter = localStorage.getItem('selected_project_filter_id');
        let updateSaveFilterBtnDisabled = (this.state.filterdata == undefined || !this.state.filterdata.length) ? ((this.state.oldFilterOption == undefined || !this.state.oldFilterOption.length) ? true : false) : false

        const toolbarTemplate = (params) => {
            let { switchView,searchText, pageSize, currentPage, totalCount, projectListData, isOpenedProjectList, isOpenedCustomFilters, isOpenedRange, shouldOpenCreateProjectDialog, minimizeProjectDialog, FullCreateProjectDialog, drawerOpen } = this.state;
            return (
                <div className="d-flex list_table_header">
                    <MaterialGrid container justify="space-between">
                        <MaterialGrid>
                            <MaterialGrid container justify="space-between">
                                <MaterialGrid>
                                    <h3 className="ml-16">Projects</h3>
                                </MaterialGrid>
                                <MaterialGrid item>
                                    <FormControlLabel
                                        value="cardview"
                                        control={<Switch color="primary" checked={switchView} name="switchView" onChange={this.handleChange} value="switchView" />}
                                        label="Listview"
                                        labelPlacement="start"
                                    />
                                </MaterialGrid> 
                                {this.state.selection.length ?
                                    <MaterialGrid className="flex flex-middle ml-10">
                                        {this.state.selection.length}  selected
                                         <IconButton aria-label="Email" size="small" className="ml-8">
                                            <Icon>email</Icon>
                                        </IconButton>
                                        {/* <IconButton aria-label="Edit" size="small" className="ml-8">
                                                <Icon>edit</Icon>
                                            </IconButton> */}
                                        <IconButton aria-label="Delete" size="small" onClick={() => this.handleDeleteProject()} className="ml-8">
                                            <Icon>delete</Icon>
                                        </IconButton>
                                        <DropdownDefault
                                            menuButton={
                                                <IconButton size="small" className="ml-8">
                                                    <Icon>more_vert</Icon>
                                                </IconButton>
                                            }
                                        >
                                            <MenuItem className="flex flex-middle">
                                                <Icon className="mr-16">call_merge</Icon> Merge projects
                                            </MenuItem>
                                            <MenuItem className="flex flex-middle">
                                                <Icon className="mr-16">file_download</Icon> Export List
                                            </MenuItem>
                                        </DropdownDefault>
                                    </MaterialGrid>
                                    :
                                    ''
                                }
                            </MaterialGrid>
                        </MaterialGrid>
                        <MaterialGrid>
                        </MaterialGrid>
                    </MaterialGrid>
                    <TextField
                        id="search-input"
                        placeholder="search"
                        defaultValue={searchText}
                        onChange={this.changeSearching1}
                        onKeyDown={this.keyPress}
                        autoFocus={searchText ? true : false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>search</Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {/* <IconButton><Icon>visibility_off</Icon></IconButton> */}
                    <HiddenColumns allData={this} columns={columns} />
                    {params.children}
                    <div className="d-inline-block ml-8">
                        <DropdownDefault
                            menuButton={
                                <Button variant="contained" color="primary" size="small">
                                    Actions
                                </Button>
                            }
                        >
                            <MenuItem className="flex flex-middle">
                                <Icon className="mr-16">format_list_numbered</Icon> Create custom fileds
                            </MenuItem>
                        </DropdownDefault>
                    </div>
                    <div className="d-inline-block ml-8">
                        <Button variant="contained" size="small">
                            Import
                        </Button>
                    </div>
                    <div className="d-inline-block ml-8 mr-16">
                        <Button variant="contained" color="secondary" onClick={() => this.setState({ shouldOpenCreateProjectDialog: true })} size="small" style={{ minWidth: 95 }}>
                            Create New
                        </Button>
                    </div>
                </div>
                
            );
        };
        
        return (
            <React.Fragment>
                <aside className="aside_left" style={{overflowY: 'scroll',overflow: 'auto'}}>
                    <Card elevation={3} className="pt-16">
                        <h5 onClick={this.toggleProjectList} className="cursor_poiter d-flex justify-content-between pl-16 pr-16">
                            <Tooltip title={this.state.displaySelectedFilterName }>
                                <div className="text_ellipsis " style={{maxWidth:'130px',fontSize:'16px',margin:'3px 0px 0px 0px',fontWeight:'500'}}>
                                    {this.state.displaySelectedFilterName}
                                </div>
                            </Tooltip>
                            ({projectListData.length}) <Icon className="vert-middle flex-end">{isOpenedProjectList ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                        </h5>
                        {isOpenedProjectList &&
                            <div className="filter_menu_custom">
                                <h6 className="mt-8 font-size-14 mb-0 pl-16 pr-16">Private Filters </h6>
                                <ul>
                                    {this.state.projectFilterListArray.length > 0 && this.state.projectFilterListArray.map((item, key) => {
                                        let star_icon = item.isdefault ? "star" : "star_border";
                                        if (item.fitertype == 1 && item.id != localStorage.getItem('selected_project_filter_id')) {
                                            return <li key={item.key}>
                                                <div onClick={() => this.handleFilterChange(item)}>{item.filtername}</div>
                                                <><IconButton className="text-secondary ml-4" size="small">
                                                    <Icon onClick={() => this.updateProjectFilter(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                </IconButton>
                                                    <IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: item.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                    </IconButton>
                                                    <IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.updateFilterDefaultProject(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                    </IconButton>
                                                </>
                                            </li>
                                        }
                                    }
                                    )}
                                </ul>
                                <h6 className="mt-8 font-size-14 mb-0 mt-16 pl-16 pr-16">Public Filters</h6>
                                <ul>
                                    {this.state.projectFilterListArray.length > 0 && this.state.projectFilterListArray.map((items, key) => {
                                        let star_icon = items.isdefault ? "star" : "star_border";
                                        if (items.fitertype == 0 && items.id != localStorage.getItem('selected_project_filter_id')) {
                                            return <li key={items.key}>
                                                <div onClick={() => this.handleFilterChange(items)}>{items.filtername}</div>
                                                {!["21", "22","23"].includes(items.id) &&
                                                    <><IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.updateProjectFilter(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                    </IconButton>
                                                        <IconButton className="text-secondary ml-4" size="small">
                                                            <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: items.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                        </IconButton></>
                                                }
                                                <IconButton className="text-secondary ml-4" size="small">
                                                    <Icon onClick={() => this.updateFilterDefaultProject(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                </IconButton>
                                            </li>
                                        }
                                    }
                                    )}
                                </ul>

                                <><Dialog
                                    fullWidth={true}
                                    maxWidth={'xs'}
                                    open={this.state.isDeleteFilter}
                                    onClose={this.filterRemoveDialoge}
                                    disableBackdropClick
                                    disableEscapeKeyDown
                                    aria-labelledby="ar-remove-dialog-title"
                                >
                                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to delete filter ?"}</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={this.filterRemoveDialoge} color="primary">Cancel</Button>
                                        <Button type="Button" onClick={() => this.confirmDeleteFilter()} className="btn_Primary">
                                            Ok
                                    </Button>
                                    </DialogActions>
                                </Dialog></>
                            </div>
                        }
                    </Card>
                    <Card elevation={3} className={`pt-16 pb-16`} style={{marginTop:'5px'}}>
                        <div className="ml-16 mr-16">
                            <Button fullWidth className="btn_Primary" size="small" onClick={this.toggleCustomFilters}><Icon>add</Icon> Add custom filters</Button>
                        </div>
                        {isOpenedCustomFilters &&
                            <React.Fragment>
                                <div className="v_scroll position-relative ml-16 mt-16 pr-16">
                                    <Link className="text_link" onClick={() => this.handleFilterChange(this.state.projectFilterListArray[this.state.AllProjectFilterKey])}>Clear All</Link>
                                    {FilterComponent(this)}
                                </div>
                                <div className="fixed-bottom" style={{position:'initial'}}>
                                    <Button type="button" className="btn_Primary  mb-16 px-12" size="small" onClick={() => this.handleSavefilterDialoge()}>Save Filter</Button>
                                    {localStorageFilter && !["21", "22","23"].includes(localStorageFilter) &&
                                        <Button type="button" className="btn_Primary  mb-16 px-12 ml-16" size="small" onClick={() => this.updateFilterOptions()}>Update Filter</Button>
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </Card>
                </aside>
                <Card elevation={3} className="fixed_layout">
                    <div className="leads_list_table">
                        <Grid rows={projectListData} columns={columns}>
                            <SelectionState
                                selection={this.state.selection}
                                onSelectionChange={this.setSelection}
                            />
                            <IntegratedSelection />
                            <VirtualTable />
                            {/* <SearchState defaultValue="" onValueChange={((val) => { this.changeSearching(val) })} /> */}
                            <IntegratedFiltering />
                            <SortingState
                                defaultSorting={this.state.defaulSortingData}
                                onSortingChange={(val => { this.changeDefaultSorting(val) })}
                                sorting={this.state.defaulSortingData}
                            />
                            {/* <IntegratedSorting /> */}
                            <DragDropProvider />
                            <PagingState
                                currentPage={currentPage}
                                pageSize={pageSize}
                                //defaultCurrentPage={defaultCurrentpage}
                                onCurrentPageChange={(page) => this.handleCurrentPage(page)}
                                onPageSizeChange={(size) => this.handlePageSize(size)}
                            />
                            <Table columnExtensions={tableColumnExtensions} />

                            <TableColumnResizing
                                defaultColumnWidths={this.state.columnWidths}
                                onColumnWidthsChange={this.onColumnWidthsChange}
                            />
                            <TableSelection showSelectAll highlightRow />
                            <TableColumnReordering
                                defaultOrder={defaultColumnOrder}
                                order={defaultColumnOrder}
                                onOrderChange={(val) => { this.handleChangeColumnOrder(val) }}
                            />
                            <TableHeaderRow showSortingControls />

                            <PagingPanel
                                // pageSizes={[2, 5, 10, 15]}
                                pageSizes={[10, 25, 50, 100]}
                            />
                            <CustomPaging
                                totalCount={totalCount}
                            />
                            <TableFixedColumns
                                leftColumns={leftColumns}
                            />
                            <TableColumnVisibility
                                defaultHiddenColumnNames={defaultHiddenColumnNames}
                                hiddenColumnNames={defaultHiddenColumnNames}
                                columnExtensions={tableColumnVisibilityColumnExtensions}
                                onHiddenColumnNamesChange={(val) => { this.handleChangeColumnHidden(val) }}
                            />
                            <Toolbar rootComponent={toolbarTemplate} />
                            {/* <SearchPanel  /> */}

                            {/* <ColumnChooser /> */}

                        </Grid>
                    </div>
                </Card>
                <Drawer
                    width={"100px"}
                    variant="temporary"
                    anchor={"right"}
                    className="drawer_right"
                    open={drawerOpen}
                    onClose={this.closeDrawerRight}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <ProjectProfileFrame project_id={this.state.project_id} projectLogs={projectLogs} handleClose={this.closeDrawerRight} />
                </Drawer>
                {shouldOpenCreateProjectDialog && (
                    <CreateNewProjectDialog
                        handleClose={this.handleProjectDialogClose}
                        open={shouldOpenCreateProjectDialog}
                        handleMinimize={this.handleProjectDialogMinimize}
                        handleFull={this.handleProjectDialogFull}
                        minimizeScreen={minimizeProjectDialog}
                        fullScreen={FullCreateProjectDialog}
                    />
                )}
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteConfirmDialoOpen}
                    onClose={this.toggleDeleteDialog}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove selected Projects ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteDialog} color="primary">
                            Cancel
                                      </Button>
                        <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">
                            Ok
                                      </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.filterSaveDialoge}
                    onClose={() => this.handleSavefilterDialogeClose()}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="delele-dialog-title"
                >
                    <DialogTitle id="">
                        {!this.state.filterUpdateId ? "Add a New Filter" : "Edit Filter"}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <ValidatorForm instantValidate={false} ref="form">
                            <FormControl error={this.state.filternameError} className="w-100 input_bx">
                                <InputLabel htmlFor="component-error">Name</InputLabel>
                                <Input
                                    type="text"
                                    id={'filtername'}
                                    label={'Filter Name'}
                                    className="w-100 input_bx"
                                    onChange={(event) => this.handleChange(event)}
                                    name={'filtername'}
                                    value={this.state.filtername}
                                />
                                {this.state.filternameError && <FormHelperText id="component-error-text">This field is required</FormHelperText>}
                            </FormControl>
                            <br /><br />
                            <div>
                                <span>visibility</span>
                                <br />
                                <RadioGroup name="fitertype" value={this.state.fitertype} onChange={(event) => this.handleChange(event)} row>
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio color="primary" />}
                                        label="Private"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        control={<Radio color="primary" />}
                                        label="Public"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </div>

                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        {updateSaveFilterBtnDisabled && <p className="lead-filter-error">Please select filter option to save filter.</p>}
                        <Button onClick={() => this.handleSavefilterDialogeClose()} color="primary">
                            Cancel
                        </Button>
                        {!this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={() => this.handleSavefilter()}>Save</Button>}
                        {this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={() => this.handleUpdatefilter()}>Update</Button>}
                    </DialogActions>
                </Dialog>
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectReducer: state.projectReducer,
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateProjectListColumnOrder: (client, request) => { dispatch(actions.updateProjectListColumnOrder(client, request)) },
        addFavouriteProject: (client, request) => { dispatch(actions.addFavouriteProject(client, request)) },
        deleteProject: (client, request) => { dispatch(actions.deleteProject(client, request)) },
        getProjectFilterList: (client, request) => { dispatch(actions.getProjectFilterList(client, request)) },
        getProjectListColumnOrder: (client) => { dispatch(actions.getProjectListColumnOrder(client)) },
        //getCityList: (client) => { dispatch(actions.getCityList(client)) },
        //getStateList: (client) => { dispatch(actions.getStateList(client)) },
        //getZipcodeList: (client) => { dispatch(actions.getZipcodeList(client)) },
        saveFilterDetailProject: (client, request) => { dispatch(actions.saveFilterDetailProject(client, request)) },
        getFilterListProject: (client, recordtypeid) => { dispatch(actions.getFilterListProject(client, recordtypeid)) },
        updateFilterDefaultProject: (client, filterid) => { dispatch(actions.updateFilterDefaultProject(client, filterid)) },
        updateFilterDetailProject: (client, req) => { dispatch(actions.updateFilterDetailProject(client, req)) },
        deleteFilterProject: (client, req) => { dispatch(actions.deleteFilterProject(client, req)) },
        getUserSetting: (client, data) => dispatch(actions.getUserSetting(client, data)),
        getUserList: (client) => dispatch(actions.getUserList(client)),
        //getActivityTypes    : (client) => dispatch(actions.getActivityTypes(client)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProjectsList);