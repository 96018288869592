import React, { Component } from "react";
import { Grid, Card } from "@material-ui/core";
import CompanyTab from "./CompanyTab";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import moment from 'moment';//moment
import 'moment-timezone';//timezone



class CompanyProfileCenter extends Component {
  state = {
    company_id: "",
    interaction: 0,
    lastcontacted: "--",
    inactivedays: 0,
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
  }

  componentDidMount() {
    this.setState({
      company_id: parseInt(this.props.company_id)
    })

    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userprefparams = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userprefparams);
  }
  componentWillReceiveProps(props) {
    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;

      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.companyReducer.companyFieldData && props.companyReducer.companyFieldData.data) {

      var fielddata = props.companyReducer.companyFieldData.data;
      if (fielddata[0].companydetail && fielddata[0].companydetail.length > 0) {
        let interaction = fielddata[0].companydetail[0].company.interactions ? fielddata[0].companydetail[0].company.interactions : 0
        let lastcontacted = fielddata[0].companydetail[0].company.lastcontacted ? moment.unix(fielddata[0].companydetail[0].company.lastcontacted).tz(this.state.timeZone).format(this.state.dateformat) : '--'
        let inactivedays = fielddata[0].companydetail[0].company.inactivedays ? fielddata[0].companydetail[0].company.inactivedays : 0

        this.setState({ interaction: interaction, lastcontacted: lastcontacted, inactivedays: inactivedays })
      }

    }
  }
  render() {
    return (
      <div className="lead_content">
        <Grid container spacing={2} className="text-center">
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.interaction}</span>
              <p className="m-0 text-gray">Interactions</p>
            </Card>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.lastcontacted}</span>
              <p className="m-0 text-gray">Last contacted</p>
            </Card>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.inactivedays}</span>
              <p className="m-0 text-gray">Inactive days</p>
            </Card>
          </Grid>
        </Grid>
        <CompanyTab company_id={this.state.company_id} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    companyReducer: state.companyReducer,
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
  };
};
const mapDispatchToProps = dispatch => {
  return {
    //getLeadLogList: (client, leadid) => dispatch(actions.getLeadLogList(client, leadid)),
    getUserSetting: (client, data) => dispatch(actions.getUserSetting(client, data)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyProfileCenter);
