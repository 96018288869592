import React, { Component } from "react";
import { Grid, Card } from "@material-ui/core";
import LeadTab from "./LeadTab";




class ProfileCenter extends Component {
  render() {
    return (
      <div className="lead_content">
        <Grid container spacing={2} className="text-center">
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">0</span>
              <p className="m-0 text-gray">Interactions</p>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">--</span>
              <p className="m-0 text-gray">Last contacted</p>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">Open</span>
              <p className="m-0 text-gray">Lead status</p>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">10</span>
              <p className="m-0 text-gray">Inactive days</p>
            </Card>
          </Grid>
        </Grid>
        <LeadTab />
      </div>
    );
  }
}

export default ProfileCenter;
