import React, { Component } from "react";
import {
  Dialog, IconButton, Button, Icon, Tooltip, Grid,
  FormControl, Select, InputLabel, Menu, MenuItem, List,
  ListItem, ListItemText, DialogActions,
  Chip, TextField, withStyles, Fab, Card
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';


const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.3rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);

class CreatelogDialog extends Component {
  state = {
    selectedDate: new Date(),
    logvaluetype: 'Phone Call',
    textvalue: '',
    selectedDate: new Date(),
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
  };
  
  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  render() {
   
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    console.log(this.props.logDatas,'this.props.logDatas');
      
    return (
        <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick disableEscapeKeyDown>
          <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">{this.props.logDatas.subject}</h4>
            <div className="d-flex">
              <Tooltip title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
                <IconButton onClick={handleMinimize}>
                  <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
                <IconButton onClick={handleFull}>
                  <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton onClick={handleClose}>
                  <Icon className="text-white">clear</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="dialog-body">
              <div className="flex-auto cursor_poiter">
                <Grid container className="mb-10 cursor_poiter" spacing={1}>
                  <Grid item>
                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                      {(this.props.logDatas.emailtype == 2) ?
                        <h5 className={"text-white m-0 font-weight-normal  avatar_circle color_" + this.props.logDatas.color}>{this.props.logDatas.color.toUpperCase()}</h5>
                        :
                        <h5 className={"text-white m-0 font-weight-normal  avatar_circle color_" + this.props.logDatas.name.charAt(0)}>{this.props.logDatas.name.charAt(0).toUpperCase()}</h5>
                      }
                    </Fab>
                  </Grid>
                  <Grid item className="mt-20">
                      {(this.props.logDatas.emailtype == 2) ?
                          <h5 className="mb-0">You to {this.props.logDatas.email}</h5>
                        :
                          <h5 className="mb-0">{this.props.logDatas.name} to You</h5>
                      }
                     
                  </Grid>
                </Grid>
              </div>
              <div className="mt-30">
                <h6>{this.props.logDatas.subject}</h6>
                <div dangerouslySetInnerHTML={{ __html: this.props.logDatas.body_html }} />
              </div>
          </div>
          <div className="dialog-action">
            <div className="mt-20">
              {(this.props.logDatas.attachment.length > 0) &&
                this.props.logDatas.attachment.map((attach) => (
                  <>
                    <span onClick={this.handleDownloadClick} className="crm-email-log-attachment">
                      <IconButton size="small">
                        <Icon>attach_file</Icon>
                      </IconButton>
                      {attach.file_name}
                      <IconButton size="small">
                        <Icon>arrow_drop_down</Icon>
                      </IconButton>
                    </span>
                    <Menu
                      id="long-menu"
                      anchorEl={this.state.Downloadoptions}
                      open={this.state.showDownloadoptions}
                      onClose={this.handleCloseDownload}
                    >
                      <MenuItem onClick={this.handleCloseDownload}>
                        <a href={attach.s3_file_name} target="__blank" className="text-center">
                          Download
                        </a>
                      </MenuItem>
                      <MenuItem onClick={this.handleCloseDownload}>
                        <span className="font-size-13 mr-10 mb-5">Relate To Lead</span>
                      </MenuItem>
                    </Menu>
                  </>
                ))
              }
            </div>
          </div>
        </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  leadReducer: state.leadReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    saveLeadLog: (client, request) => dispatch(actionCreators.saveLeadLog(client, request)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatelogDialog);
