import React, { Component } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Grid, InputLabel, MenuItem, FormControl, Select, Chip, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

class ProfileDetails extends Component {
  state = {
    firstName: "john",
    lastName: 'williamson',
    status: "10",
    createdDate: "09/12/2019",
    owner: "John",
    visibility: "Team",
    customfield1: "",
    customfield2: "",
    description: "",
    tags: [],
  };

  handleSubmit = event => {
    console.log("submitted");
    console.log(event);
  };

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    let {
      firstName,
      lastName,
      status,
      createdDate,
      owner,
      visibility,
      customfield1,
      customfield2,
      description,
      tags,
    } = this.state;
    return (
      <div className="v_scroll no_button position-relative label-size-14">
        <ValidatorForm
          ref="form"
          onSubmit={this.handleSubmit}
          onError={errors => null}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextValidator
                className="mb-16 w-100 input_bx"
                label="First name"
                onChange={this.handleChange}
                type="text"
                name="firstName"
                value={firstName}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
              <TextValidator
                className="mb-16 w-100 input_bx"
                label="Last name"
                onChange={this.handleChange}
                type="text"
                name="lastName"
                value={lastName}
              />
              <FormControl className="mb-16 w-100 input_bx">
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={status}
                  onChange={this.handleChange}
                >
                  <MenuItem selected value={10}>Open</MenuItem>
                  <MenuItem value={20}>Close</MenuItem>
                </Select>
              </FormControl>
              <TextValidator
                className="mb-16 w-100 input_bx"
                label="Created"
                onChange={this.handleChange}
                type="text"
                name="createdDate"
                value={createdDate}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
              <TextValidator
                className="mb-16 w-100 input_bx"
                label="Owner Name"
                onChange={this.handleChange}
                type="text"
                name="owner"
                value={owner}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
              <TextValidator
                className="mb-16 w-100 input_bx"
                label="Visibility"
                onChange={this.handleChange}
                type="text"
                name="visibility"
                value={visibility}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
              <TextValidator
                className="mb-16 w-100 input_bx"
                label="Custom field 1"
                onChange={this.handleChange}
                type="text"
                name="customfield1"
                value={customfield1}
              />
              <TextValidator
                className="mb-16 w-100 input_bx"
                label="Custom field 2"
                onChange={this.handleChange}
                type="text"
                name="customfield2"
                value={customfield2}
              />
              <TextValidator
                className="mb-16 w-100 input_bx"
                label="Description"
                onChange={this.handleChange}
                multiline
                name="description"
                value={description}
              />
              <Autocomplete
                multiple
                id="tags-filled"
                options={tags.map(option => option.title)}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Tags"
                    className="w-100 input_bx"
                  />
                )}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

export default ProfileDetails;
