import React, { Component } from "react";
import { Checkbox } from "@material-ui/core";
import { Table } from 'antd';

class RemindersNotification extends Component {
    state = {
        selection: [],
        checkbox_reminder_email1: true,
        checkbox_reminder_email2: true,
        checkbox_reminder_inapp1: true,
        checkbox_reminder_inapp2: true,
        checkbox_reminder_mobile1: true,
        checkbox_reminder_mobile2: true,
    };
    handleCheckBoxChange = (event, name) => {
        event.persist();
        this.setState({ [name]: event.target.checked });
    };
    render() {
        const columns = [];
        const rows = [];
        const general_rows = [];

        const general_columns = [

            {
                title: "",
                dataIndex: "name",
                className: "align_left",
                width: 300
            },
            {
                title: "Email",
                dataIndex: "email",
                // className: "align_left"
            },
            {
                title: "In App",
                dataIndex: "inapp",
                // className: "align_left"
            },
            {
                title: "Mobile",
                dataIndex: "mobile",
                // className: "align_left"
            },
            {
                title: "",
                dataIndex: "action",
            }
        ];
        
        general_rows.push (
            {
                name: (
                    <div className="ml-10">                           
                        <label className="crm-custom-field-label font-weight-500">Task is due</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_email1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_email1')}
                        value="checkbox_reminder_email1"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_inapp2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_inapp2')}
                        value="checkbox_reminder_inapp2"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_mobile1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_mobile1')}
                        value="checkbox_reminder_mobile1"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Task reminders</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_email2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_email2')}
                        value="checkbox_reminder_email2"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_inapp2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_inapp2')}
                        value="checkbox_reminder_inapp2"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_mobile2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_mobile2')}
                        value="checkbox_reminder_mobile2"
                        color="primary"
                    />
                </React.Fragment>),
            },
        )
        


        return (
            <React.Fragment>
                <div className="table_width antd-tables">
                    <Table
                        bordered={false}
                        columns={general_columns}
                        pagination={false}
                        dataSource={general_rows}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default RemindersNotification;
