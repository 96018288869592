import React, { Component } from "react";
import { IconButton, Grid, Card, Icon, withStyles } from "@material-ui/core";


const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);


class TaskProfileRight extends Component {
    state = {
        attachment: null,
        isOpenedTask: false,
        isOpenedFiles: false,
    };
    handleAttachmentSelection = event => {
        this.setState({
            attachment: event.target.files[0]
        });
    };
    toggleTask = () => {
        this.setState(oldStateTask => ({ isOpenedTask: !oldStateTask.isOpenedTask }));
    }
    toggleFiles = () => {
        this.setState(oldStateFiles => ({ isOpenedFiles: !oldStateFiles.isOpenedFiles }));
    }
    render() {
        let { isOpenedTask, isOpenedFiles } = this.state;
        return (
            <React.Fragment>
                <Card elevation={3} className="p-16">
                    <Grid container justify="space-between">
                        <h5 onClick={this.toggleTask} className="cursor_poiter flex-auto mb-0 pt-4">Tasks (1) <Icon className="vert-middle">{isOpenedTask ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
                        <IconButton aria-label="Add" size="small">
                            <IconSmall>add</IconSmall>
                        </IconButton>
                    </Grid>
                    {isOpenedTask &&
                        <div className="box_portlet mt-10">
                            <div className="box_body px-10 py-16">
                                <div className="lead_pl pr-30">
                                    <Icon className="icon_left">call</Icon>
                                    <Grid container justify="space-between">
                                        <h6 className="m-0">Call to test company</h6>
                                        <p className="m-0 font-size-13" style={{ lineHeight: 1.1 }}>10/12/2019</p>
                                    </Grid>
                                    <p className="mb-0 mt-4">Assigned person <span className="font-weight-500">Justin</span> </p>
                                    <div className="hover_show">
                                        <IconButton aria-label="Delete" size="small">
                                            <IconSmall>delete</IconSmall>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Card>
                <Card elevation={3} className="p-16 mt-16">
                    <Grid container justify="space-between">
                        <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleFiles}>Files (0) <Icon className="vert-middle">{isOpenedFiles ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
                        <IconButton aria-label="Add" size="small">
                            <IconSmall>add</IconSmall>
                        </IconButton>
                    </Grid>
                    {isOpenedFiles &&
                        <div className="mt-10">
                            <label htmlFor="attachment">
                                <IconButton className="mr-8" component="span">
                                    <Icon>attachment</Icon>
                                </IconButton>
                                add attachment
                            </label>
                            <input
                                onChange={this.handleAttachmentSelection}
                                className="d-none"
                                id="attachment"
                                type="file"
                            />
                        </div>
                    }
                </Card>
                <Card elevation={3} className="p-16 mt-16">
                    <h5>Calendar meeting (0)</h5>
                    <label className="text-hint">
                        <IconButton className="mr-8" component="span">
                            <Icon>event</Icon>
                        </IconButton>
                        add calendare meetings/events
                            </label>
                </Card>
            </React.Fragment>
        );
    }
}

export default TaskProfileRight;
