import React, { Component } from "react";
import { connect } from "react-redux";
import GoogleLogin from 'react-google-login';
import * as actionCreators from '../../store/actions/index';


// const responseGoogle = async (response) => {
//     console.log(response);
//   }


class ByGoogleLogin extends Component {
    componentDidMount() {
        //this.googleSDK();
        console.log('sfsfd');
    }
    handleGoogleAuthSucess = (response) => {
        console.log("dfgdds", response);
        console.log("dfgdds", response.profileObj.email);
        //const send_data = {'email':response.profileObj.email, 'name':response.profileObj.name}
        //this.props.onRegisterUser(send_data);
        this.props.onUserLogin(response);
    }
    handleGoogleAuthFailure = (response) => {
        console.log(response)
    }
    render (){
        return(
            <div>
                <GoogleLogin
                clientId="161942936141-fsq8al02npjinbf8vm1rr1lop0takre0.apps.googleusercontent.com"
                // render={renderProps => (
                //     <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                //   )}
                buttonText="Login"
                onSuccess={this.handleGoogleAuthSucess}
                onFailure={this.handleGoogleAuthFailure}
                offline={true}
                //responseType='code'
                uxMode='redirect'
                isSignedIn={true}
                //onRequest={loading}
                //offline={false}
                approvalPrompt="force"
                //responseType="id_token"
                redirectUri='http://localhost:3000/by-google-login'
                scope="https://www.googleapis.com/auth/drive https://mail.google.com https://www.googleapis.com/auth/calendar profile email openid"
                />

                <button onClick={this.handlebuttonClick}></button>
                
            </div>
        );
    }
};
const mapStateToProps = state => {
    //console.log("new props===.",state.ctr.counter)
    return{
        cntr:state.counterReducer.counter,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        //onRegisterUser:(response_data) => dispatch(actionCreators.registerUser(response_data))
        // onUserVerify:(response_data)=>dispatch(actionCreators.userVerify(response_data)),
        // onForgotPasswordRequest:(response_data)=>dispatch(actionCreators.forgotPasswordRequest(response_data)),
        // onForgotPassword:(response_data) => dispatch(actionCreators.forgotPassword(response_data)),
         onUserLogin:(response_data) => dispatch(actionCreators.userLogin(response_data))
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(ByGoogleLogin);
